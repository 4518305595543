import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';

const AddIcon = ({
  hideLabel,
  hideIcon,
  labelText,
  orientation,
  data,
  'data-test': dataTest,
  ...props
}) => (
  <FlexContainer
    flexDirection={orientation === 'row' ? 'row' : 'column'}
    alignItems="center"
  >
    <Icon
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data={data}
      hideIcon={hideIcon}
      data-test={dataTest}
      {...props}
    >
      <g id="UI" strokeWidth="1" fillRule="evenodd">
        <g
          id="Fitness-Plan"
          transform="translate(-319.000000, -596.000000)"
          strokeWidth="1.5"
        >
          <g id="Add-Icon-Green" transform="translate(320.000000, 597.000000)">
            <circle
              id="Oval-3"
              cx="12"
              cy="12"
              r="12"
              pink={props.pink}
              green={props.green}
              strokeWidth={props.bold && '2.5'}
            ></circle>
            <path
              d="M6,12 L18,12"
              id="Line-2"
              strokeLinecap="round"
              pink={props.pink}
              green={props.green}
              strokeWidth={props.bold && '2.5'}
            ></path>
            <path
              d="M12,6 L12,18"
              id="Line-2-Copy"
              strokeLinecap="round"
              pink={props.pink}
              green={props.green}
              strokeWidth={props.bold && '2.5'}
            ></path>
          </g>
        </g>
      </g>
    </Icon>
    {!hideLabel && (
      <Label
        orientation={orientation}
        pink={props.pink}
        green={props.green}
        labelColor={props.labelColor}
        labelWeight={props.labelWeight}
        hideIcon={hideIcon}
        data-test={`${dataTest}-mobile`}
        {...props}
      >
        {labelText || 'Add'}
      </Label>
    )}
  </FlexContainer>
);

const Icon = styled.svg`
  width: ${props => (props.small ? '16px' : '24px')};
  height: ${props => (props.small ? '16px' : '24px')};

  visibility: ${props => (props.hideIcon ? 'hidden' : 'visible')};
  & circle {
    fill: none;
    stroke: ${props =>
      props.pink
        ? colors.hlitetwo400
        : props.green
        ? colors.secondary500
        : colors.primary600};
  }

  & path {
    stroke: ${props =>
      props.pink
        ? colors.hlitetwo400
        : props.green
        ? colors.secondary500
        : colors.primary600};
  }

  &:hover {
    cursor: pointer;
  }

  &:hover circle {
    fill: ${props =>
      props.pink
        ? colors.hlitetwo100
        : props.green
        ? colors.secondary100
        : colors.primary050};
  }

  &:focus circle {
    fill: ${props =>
      props.pink
        ? colors.hlitetwo100
        : props.green
        ? colors.secondary100
        : colors.primary050};
  }

  &:active circle {
    fill: ${props =>
      props.pink
        ? colors.hlitetwo200
        : props.green
        ? colors.secondary200
        : colors.primary100};
  }

  &:disabled circle {
    fill: none;
    stroke: ${colors.primary300};
  }
  &:disabled path {
    fill: none;
    stroke: ${colors.primary300};
  }
`;

const Label = styled.p`
  font-size: ${props => (props.hideIcon ? '15px' : '13px')};
  line-height: 15px;
  font-weight: ${props => (props.labelWeight === 'normal' ? 400 : 500)};
  color: ${props =>
    props.labelColor
      ? props.labelColor
      : props.pink
      ? colors.hlitetwo400
      : props.green
      ? colors.secondary500
      : colors.primary600};
  margin: ${props => (props.orientation === 'row' ? '0 0 0 8px' : '8px 0 0 0')};
  margin-right: ${props => (props.hideIcon ? '24px' : '0')};

  ${Icon}:disabled & {
    color: ${colors.primary400};
  }

  &:hover {
    cursor: pointer;
  }
`;

export default AddIcon;
