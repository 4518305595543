import React, { useState } from 'react';
import { colors } from '../styleConstants';
import styled from 'styled-components';
import Select from 'react-select';
import FlexContainer from './FlexContainer';
import MenuItem from '@material-ui/core/MenuItem';

import DropdownArrowIcon from '../icons/DropdownArrowIcon';

const Dropdown = ({ options, label, error, defaultValue, ...props }) => {
  if (defaultValue) {
    defaultValue = {
      value: defaultValue,
      label: defaultValue,
    };
  }

  const [selected, setSelected] = useState(defaultValue || '');

  if (options) {
    if (typeof options[0] !== 'object') {
      options = options.map(option => {
        return { value: option, label: option };
      });
    } else {
      options = options.map(option => {
        return { value: option.value, label: option.label };
      });
    }
  }

  const handleChange = selected => {
    setSelected(selected);
  };

  return (
    <Container flexDirection="column" {...props}>
      {label && <Label>{label}</Label>}
      <StyledSelect
        value={selected}
        onChange={handleChange}
        options={options}
        classNamePrefix="react-dropdown"
        className="react-dropdown-container"
        placeholder="Select Item"
        small={props.small}
        error={error}
        components={{
          DropdownIndicator: () => <DropdownArrowIcon small={props.small} />,
          IndicatorSeparator: null,
          Option: ({ children, innerProps, innerRef }) => (
            <StyledMenuItem ref={innerRef} {...innerProps}>
              {children}
            </StyledMenuItem>
          ),
        }}
        {...props}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: ${props => (props.width ? props.width : props.small ? '50%' : '100%')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0px')};
  margin-bottom: ${props => (props.marginBottom ? props.marginLBottom : '0px')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0px')};
`;

const StyledSelect = styled(Select)`
  input::-webkit-contacts-auto-fill-button,
  input::-webkit-credentials-auto-fill-button {
    display: none !important;
  }
  overflow: visible;

  & .react-dropdown__control {
    border: ${props =>
      props.error ? '1px solid #ff7c0e' : `1px solid ${colors.primary400}`};
    border-radius: 2px;
    min-height: 25px;
    height: ${props => (props.small ? '29px' : '40px')};
    box-shadow: none;
  }

  & .react-dropdown__indicatorContainer {
    &:hover {
      cursor: pointer;
    }
  }

  & .react-dropdown__control--is-focused {
    border: 1px solid ${colors.secondary500};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    &:hover {
      border-color: ${colors.secondary500};
    }
  }

  & .react-dropdown__control--menu-is-open {
  }

  & .react-dropdown__placeholder {
    font-weight: 400;
    font-size: ${props => (props.small ? '15px' : '17px')};
    line-height: ${props => (props.small ? '18px' : '20px')};
    color: ${colors.primary500};
  }

  & .react-dropdown__value-container {
    padding: ${props => (props.small ? '0px 12px' : '2px 12px')};
  }

  & .react-dropdown__indicators {
    padding-right: 10px;
  }

  & .react-dropdown__value {
    font-weight: 400;
    font-size: ${props => (props.small ? '15px' : '17px')};
    line-height: ${props => (props.small ? '18px' : '20px')};
    color: ${colors.primary800};
  }

  & .react-dropdown__menu {
    border-radius: 2px;
    border: 1px solid ${colors.primary200};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
`;

const Label = styled.label`
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary600};
  margin-bottom: 8px;
`;

const StyledMenuItem = styled(MenuItem)`
  min-height: 32px;
  height: 32px;
  padding: 0 12px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: ${colors.primary800};

  &:hover {
    background-color: ${colors.primary100};
  }

  & .MuiTouchRipple-root {
    color: ${colors.primary050};
  }
`;

const ErrorMessage = styled.p`
  color: #ff7c0e;
  text-align: ${props => (props.center ? 'center' : 'right')};
  margin: ${props => (props.center ? '16px 0 0 0' : '4px 0 5px 0')};
  padding: 0px;
  font-size: 11px;
  line-height: 14px;
`;

export default Dropdown;
