import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';

import { isEmpty } from 'lodash';

import useFormValues from '../../hooks/useFormValues';
import useModal from '../../hooks/useModal';
import useToggles from '../../hooks/useToggles';
import ModalContainer from '../../sharedModals/ModalContainer';

import { getMealPlan } from '../../services/api/mealplan';
import MealPlanForm from '../mealPlan/MealPlanForm';

const MealPlanToAssign = ({ setPlanToDuplicate, ...props }) => {
  const initialValues = {
    planLength: 7,
    dayNum: {
      value: 1,
      label: `Day 1`,
    },
    recommended: {
      calories: 0,
      fat: 0,
      protein: 0,
      carbs: 0,
    },
  };
  const initialToggleValues = {
    active: false,
    dietType: {},
  };
  const { values, setValues, handleChange } = useFormValues(initialValues);
  const { toggleValues, handleToggle, setToggleValues } = useToggles(
    initialToggleValues
  );

  const [mealPlan, setMealPlan] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [foods, setFoods] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [filteredFoods, setFilteredFoods] = useState([]);
  const { open, launchModal, closeModal } = useModal(false);

  async function loadMealPlan(params) {
    try {
      const mealPlanId = params.mealPlan;
      const result = await getMealPlan(mealPlanId);
      if (result) {
        setMealPlan(result);
        setPlanToDuplicate(result._id);
      } else {
        throw new Error('Meal Plan could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded meal plan
  useEffect(() => {
    if (!isEmpty(mealPlan)) {
      setRecipes([...mealPlan.recipes]);
      setFoods([...mealPlan.foods]);

      setValues({
        ...values,
        type: mealPlan.type,
        planLength: mealPlan.planLength,
        recommendedCalories: mealPlan.recommendedCalories,
      });

      setToggleValues({
        active: mealPlan.active,
        dietType: mealPlan.tags.dietType.reduce(
          (val, cur) => (val[cur] = true),
          {}
        ),
      });
    }
  }, [mealPlan]);

  useEffect(() => {
    const filteredRec = recipes.filter(recipe => {
      return recipe.dayNum === values.dayNum.value;
    });
    const filteredFood = foods.filter(food => {
      return food.dayNum === values.dayNum.value;
    });
    setFilteredRecipes(filteredRec);
    setFilteredFoods(filteredFood);
  }, [recipes, foods, values.dayNum]);

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Meal Plan"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          hide: 'food',
          assignPlans: true,
        }}
        initialScreen="MealPlanSearch"
        handleClose={closeModal}
        addMealPlan={loadMealPlan}
        {...props}
      />
      <MealPlanForm
        values={values}
        handleChange={handleChange}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        filteredRecipes={filteredRecipes}
        filteredFoods={filteredFoods}
        foods={foods}
        recipes={recipes}
        viewOnly={true}
        formType="view"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default MealPlanToAssign;
