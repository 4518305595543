import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const mapState = state => ({
  isAdmin: state.currentUser.user.isAdmin,
});

const AdminRoute = props => {
  const { isAdmin } = useSelector(mapState);
  return isAdmin ? <Route {...props} /> : <Redirect to="/app" />;
};

export default AdminRoute;
