import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import { getImageURL } from '../services/api/api';
import { FavoriteBorder } from '@material-ui/icons';

const RecipeCard = ({
  recipe,
  setRecipe,
  setScreen,
  sliderCard,
  isMobile,
  ...props
}) => {
  const userIsAdmin = useSelector(state => state.currentUser.user.isAdmin);
  const favoriteRecipes = useSelector(
    state => state.currentUser.user.favoriteRecipes
  );
  const isFavorite = favoriteRecipes.includes(recipe._id);
  const version = recipe.version || '';

  const recipeName = userIsAdmin
    ? `${recipe.name} ${version}`
    : `${recipe?.name}`;

  const handleClick = e => {
    e.stopPropagation();
    setRecipe(recipe);
    setScreen('Recipe Page');
  };

  const imgUrl = (ratio = 1) => {
    const height = 90 * ratio;
    const mobileWidth = 150 * ratio;
    const width = 152 * ratio;
    return getImageURL(
      recipe.imageUrl,
      isMobile
        ? `resizing_type:fill/height:${height}/width:${mobileWidth}`
        : `height:${width}`
    );
  };

  if (isMobile) {
    // mobile version
    return (
      <MobileCardContainer
        alignItems="center"
        width={props?.width}
        onClick={handleClick}
        data-test="mealModal-recipeCard"
      >
        {isFavorite && <StyledFavoriteIcon data-test="recipeCard-favorite" />}
        <ImageContainer image={imgUrl()} image2x={imgUrl(2)}></ImageContainer>
        <RecipeNameContainer data-test="recipeCard-name">
          {recipeName}
        </RecipeNameContainer>
      </MobileCardContainer>
    );
  } else {
    // web version
    return (
      <CardContainer
        flexDirection="column"
        onClick={handleClick}
        data-test="mealModal-recipeCard"
        sliderCard={sliderCard}
      >
        {isFavorite && <StyledFavoriteIcon data-test="recipeCard-favorite" />}
        <TopContainer alignItems="center">
          <MealTitle data-test="recipeCard-name">{recipeName}</MealTitle>
        </TopContainer>
        {!recipe.active && (
          <InactiveContainer flexDirection="column">
            <Info data-test="recipeCard-inactive">Inactive</Info>
          </InactiveContainer>
        )}
        <BottomContainer image={imgUrl()} image2x={imgUrl(2)}></BottomContainer>
      </CardContainer>
    );
  }
};

const CardContainer = styled(FlexContainer)`
  height: 224px;
  width: 192px;
  min-width: 192px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1.5px solid ${colors.primary200};
  border-radius: 4px;
  position: relative;

  :hover {
    cursor: pointer;
    opacity: 0.8;
  }

  :active {
    opacity: 0.7;
  }
`;

const TopContainer = styled(FlexContainer)`
  height: 72px;
  padding: 0 16px;
`;

const BottomContainer = styled(FlexContainer)`
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-size: cover;
  height: 152px; /* Update image dimensions accordingly. */
  position: relative;
`;

const MealTitle = styled.h4`
  color: ${colors.primary800};
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

const StyledFavoriteIcon = styled(FavoriteBorder)`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 16px;
  height: 16px;
  color: ${colors.hlitetwo400};
`;

const InactiveContainer = styled(FlexContainer)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const Info = styled.p`
  color: ${colors.primary500};
  font-size: 13px;
  line-height: 8px;
  margin: 4px;
`;
const MobileCardContainer = styled(FlexContainer)`
  box-sizing: border-box;
  width: ${props => (props.width ? props.width : '100%')};
  border: 1.5px solid ${colors.primary200};
  border-radius: 4px;
  position: relative;
`;
const ImageContainer = styled(FlexContainer)`
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-size: cover;
  background-repeat: no-repeat;
  height: 90px;
  width: 40%;
  min-width: 40%;
`;
const RecipeNameContainer = styled.div`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 17px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 0 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
export default RecipeCard;
