import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';

import ExpertTipIcon from '../icons/ExpertTipIcon';

const ExpertTip = ({ tipTitle, tipText, ...props }) => {
  // Prevent the component from rendering if there is no tip to display.
  if (tipText === '' || tipText == null) {
    return null;
  }

  return (
    <Container
      flexDirection={props.flexDir || 'column'}
      alignItems={props.alignItems || 'auto'}
      flexShrink="0"
    >
      <TipHeader alignItems="center" {...props}>
        <ExpertTipIcon />
        <TipHeaderText>{tipTitle || 'Expert Tip'}</TipHeaderText>
      </TipHeader>
      <TipText>{tipText}</TipText>
    </Container>
  );
};

const Container = styled(FlexContainer)``;

const TipHeader = styled(FlexContainer)`
  margin-bottom: ${props => props.logoMargin || '16px'};

  > * {
    margin-right: 12px;
  }
`;

const TipHeaderText = styled.p`
  color: ${colors.secondary600};
  font-weight: bold;
  text-transform: uppercase;
`;

const TipText = styled.p`
  color: ${colors.primary700};
`;

export default ExpertTip;
