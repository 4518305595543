import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';
import { isEmpty } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useModal from '../../hooks/useModal';
import ExploreBannerForm from './ExploreBannerForm';
import ModalContainer from '../../sharedModals/ModalContainer';

import { getExploreBanner } from '../../services/api/campaigns';

const ViewExploreBanner = props => {
  const initialValues = {
    color: 'Grey',
    actionType: {
      value: 'link',
      label: 'External Link',
    },
  };
  const actionTypeMap = {
    link: 'External Link',
    addItem: 'Add Item To Plan',
    showItems: 'Show Items in Search',
  };
  const {
    values,
    setValues,
    handleChange,
    handleValueChanged,
    handleDateChanged,
  } = useFormValues(initialValues);

  const [imageUrl, setImageUrl] = useState();
  const [modalImageUrl, setModalImageUrl] = useState();
  const { open, launchModal, closeModal } = useModal(false);
  const [exploreBanner, setExploreBanner] = useState({});
  const [itemToAdd, setItemToAdd] = useState({});
  const [itemsToShow, setItemsToShow] = useState([]);

  async function loadExploreBanner(params) {
    try {
      const exploreBannerId = params.exploreBanner;
      const result = await getExploreBanner(exploreBannerId);
      if (result) {
        setExploreBanner(result);
      } else {
        throw new Error('Explore banner could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded exploreBanner
  useEffect(() => {
    if (!isEmpty(exploreBanner)) {
      setValues({
        name: exploreBanner.name,
        title: exploreBanner.title,
        textBody: exploreBanner.textBody,
        imageUrl: exploreBanner.imageUrl,
        modalImageUrl: exploreBanner?.modalImageUrl,
        modalHeaderTitle: exploreBanner?.modalHeaderTitle,
        color: exploreBanner.color,
        actionType: {
          value: exploreBanner.actionType,
          label: actionTypeMap[exploreBanner.actionType],
        },
        link: exploreBanner.link,
      });
      setItemToAdd(exploreBanner?.itemToAdd);
      setItemsToShow(exploreBanner?.itemsToShow);
      setImageUrl(exploreBanner.imageUrl);
      setModalImageUrl(exploreBanner?.modalImageUrl);
    }
  }, [exploreBanner]);

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Explore Banner"
        buttonSize="large"
        width="280px"
        handleClick={launchModal}
        data-test="exploreBanner-find"
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          showOnly: 'explore',
        }}
        initialScreen="Campaign Search"
        handleClose={closeModal}
        addExploreBanner={loadExploreBanner}
        {...props}
      />
      <ExploreBannerForm
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        modalImageUrl={modalImageUrl}
        setModalImageUrl={setModalImageUrl}
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        handleDateChanged={handleDateChanged}
        itemToAdd={itemToAdd}
        itemsToShow={itemsToShow}
        viewOnly={true}
        formType="view"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default ViewExploreBanner;
