import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import moment from 'moment';
import CloseIcon from '../icons/CloseIcon';
import ShrinkIcon from '../icons/ShrinkIcon';
import { getImageURL } from '../services/api/api';
import { getUserMealPlans } from '../services/api/mealplan';
import useReactRouter from 'use-react-router';
import { formatDate } from '../helpers/date';

const RecipeItem = ({
  recipe,
  userId,
  isSelected,
  isFiltered,
  handleClick,
  handleRemove,
}) => {
  const { history } = useReactRouter();
  const imageUrl = (recipe, ratio = 1) => {
    const width = 110 * ratio;
    const height = 80 * ratio;
    return getImageURL(
      recipe?.imageUrl,
      `resizing_type:fill/height:${height}/width:${width}`
    );
  };
  const handleRecipeLink = async () => {
    if (isSelected) {
      const mealPlan = await getUserMealPlans({
        query: {
          user: userId,
          startDate: formatDate(
            moment(recipe.date)
              .utc()
              .startOf('isoWeek')
          ),
          endDate: formatDate(
            moment(recipe.date)
              .utc()
              .endOf('isoWeek')
          ),
        },
      });

      const link = `/app/mealplan/${mealPlan[0]._id}/recipe/${recipe.instanceId}`;
      history.push(link);
    }
  };

  const handleRemoveClick = e => {
    e.stopPropagation();
    handleRemove(recipe.instanceId);
  };

  return (
    <RecipeItemContainer
      alignItems="center"
      flexDirection="column"
      isSelected={isSelected}
      isFiltered={isFiltered}
      onClick={!isSelected ? handleClick : undefined}
      data-test="recipeItem"
    >
      <Thumbnail
        imageUrl={imageUrl(recipe, 1)}
        imageUrl2x={imageUrl(recipe, 2)}
        isSelected={isSelected}
      >
        {isSelected ? (
          <StyledShrinkIcon
            handleClick={handleClick}
            data-test="recipeItem-collapse"
          />
        ) : (
          <StyledCloseIcon
            showCircle={true}
            color="pink"
            size="24px"
            handleClose={handleRemoveClick}
            data-test="recipeItem-remove"
          />
        )}
        {/* TODO: Add remove functionality */}
      </Thumbnail>
      <RecipeName
        isSelected={isSelected}
        onClick={handleRecipeLink}
        data-test="shoppingList-recipe-name"
      >
        {recipe?.name}
      </RecipeName>
      {isSelected && (
        <FlexContainer flexDirection="column">
          <Divider></Divider>
          <InfoContainer alignItems="center" justify="center">
            <FlexContainer alignItems="center">
              <Img src={`${process.env.PUBLIC_URL}/icons/event_note.svg`} />
              <RecipeInfo data-test="recipeItem-date">
                {moment(recipe.date)
                  .utc()
                  .format('M/D')}
              </RecipeInfo>
            </FlexContainer>
            <FlexContainer alignItems="center">
              <Img src={`${process.env.PUBLIC_URL}/icons/Leftover.svg`} />
              <RecipeInfo data-test="recipeItem-yield">
                {recipe.yield}
              </RecipeInfo>
            </FlexContainer>
          </InfoContainer>
        </FlexContainer>
      )}
    </RecipeItemContainer>
  );
};

const RecipeItemContainer = styled(FlexContainer)`
  border: ${props =>
    props.isSelected
      ? `1px solid ${colors.primary100}`
      : `1px solid ${colors.primary050}`};
  border-radius: ${props => (props.isSelected ? '8px' : '6px')};
  box-shadow: ${props =>
    props.isSelected ? '0px 5px 9px rgba(0, 0, 0, 0.15)' : 'initial'};
  opacity: ${props => (props.isFiltered ? '0.3' : 'initial')};
  height: ${props => (props.isSelected ? '191px' : '132px')};
  transition: height 0.3s, opacity 0.3s, box-shadow 0.3s, border 0.3s,
    border-radius 0.3s;
`;

const Thumbnail = styled.div`
  background-image: ${props => `url(${props.imageUrl})`};
  background-image: ${props =>
    `image-set(url(${props.imageUrl}) 1x, url(${props.imageUrl2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.imageUrl}) 1x, url(${props.imageUrl2x}) 2x )`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: ${props => (props.isSelected ? '6px' : '8px')};
  min-width: ${props => (props.isSelected ? '150px' : '80px')};
  min-height: ${props => (props.isSelected ? '110px' : '80px')};
  width: ${props => (props.isSelected ? '150px' : '80px')};
  height: ${props => (props.isSelected ? '110px' : '80px')};
  transition: min-width 0.3s, min-height 0.3s, width 0.3s, height 0.3s;
  position: relative;
`;

const RecipeName = styled.p`
  color: ${props =>
    props.isSelected ? colors.secondary600 : colors.primary600};
  font-size: ${props => (props.isSelected ? '16px' : '11px')};
  line-height: ${props => (props.isSelected ? '19px' : '14px')};
  text-align: center;
  padding: 4px 4px 0 4px;
  transition: font-size 0.3s, line-height 0.3s, -webkit-line-clamp 0.3s;
  display: -webkit-box;
  -webkit-line-clamp: ${props => (props.isSelected ? '2' : '3')};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Divider = styled.div`
  margin: 6px 0;
  width: 130px;
  border-bottom: 1px solid ${colors.primary100};
`;

const InfoContainer = styled(FlexContainer)`
  gap: 12px;
`;

const RecipeInfo = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: ${colors.secondary600};
`;

const Img = styled.img`
  margin-right: 4px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 4px;
  right: 4px;
`;

const StyledShrinkIcon = styled(ShrinkIcon)`
  position: absolute;
  top: 4px;
  right: 4px;
`;

export default RecipeItem;
