import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';

import { lowerCase } from 'lodash';

import AddIcon from '../../icons/AddIcon';
import Collapsible from 'react-collapsible';
import CollapseIcon from '../../icons/CollapseIcon';

import FitnessCard from './FitnessCard';

import useToggles from '../../hooks/useToggles';
import useModal from '../../hooks/useModal';

import ModalContainer from '../../sharedModals/ModalContainer';

const FitnessSection = ({
  items,
  sectionType,
  removeWorkout,
  removeActivity,
  date,
  variant,
  ...props
}) => {
  const { open, launchModal, closeModal, context } = useModal(false);

  const { toggleValues, handleToggle } = useToggles({ [sectionType]: true });

  const handleRemove = e => {
    if (e.target.dataset.type === 'workout') {
      const workoutId = e.target.dataset.name;
      removeWorkout(workoutId);
    } else if (e.target.dataset.type === 'activity') {
      const activityId = e.target.dataset.name;
      removeActivity(activityId);
    }
  };

  const hideIcon = sectionType === 'Workout' && items.length > 0;

  const itemsList = items.map((item, i) => {
    return (
      <WorkoutContainer key={i} alignItems="center">
        <FitnessCard
          itemToRender={item}
          handleRemove={handleRemove}
          variant={variant}
          {...props}
        />
      </WorkoutContainer>
    );
  });

  const triggerStyle = {
    position: 'absolute',
    top: '3px',
    right: 0,
  };

  return (
    <FitnessContainer flexDirection="column">
      <FlexContainer justify="space-between">
        <FitnessLabels alignItems="center">
          <FitnessTitle>
            {sectionType === 'Activity' ? 'Activities' : 'Workout'}
          </FitnessTitle>
        </FitnessLabels>
      </FlexContainer>
      <Collapsible
        overflowWhenOpen="visible"
        triggerStyle={triggerStyle}
        contentOuterClassName="contentOuter__no_padding"
        open={toggleValues[`${sectionType}`]}
        trigger={
          <CollapseIcon
            small
            onClick={handleToggle()}
            open={toggleValues[`${sectionType}`]}
            name={sectionType}
          />
        }
      >
        <WorkoutList
          flexDirection="column"
          data-test={`fitnessPlan-${sectionType}-list`}
        >
          {itemsList}
        </WorkoutList>
      </Collapsible>
      {!hideIcon && variant !== 'viewOnly' ? (
        <>
          <AddIcon
            labelText={`Add ${sectionType}`}
            data-name={sectionType}
            data-date={date}
            data-show-only={lowerCase(sectionType)}
            data-variant="admin"
            orientation="row"
            pink="true"
            onClick={launchModal}
            data-test={`fitnessPlan-${sectionType}-add`}
          />
          <ModalContainer
            open={open}
            context={context}
            handleClose={closeModal}
            initialScreen="Add Exercise"
            {...props}
          />
        </>
      ) : null}
    </FitnessContainer>
  );
};

const FitnessContainer = styled(FlexContainer)`
  position: relative;
`;

const WorkoutList = styled(FlexContainer)`
  margin-bottom: 8px;

  > * {
    margin-bottom: 8px;
  }
`;

const WorkoutContainer = styled(FlexContainer)`
  position: relative;
`;

const FitnessLabels = styled(FlexContainer)`
  margin-bottom: 16px;
`;

const FitnessTitle = styled.h4`
  text-transform: uppercase;
  color: ${colors.primary800};
  margin-right: 16px;
`;

export default FitnessSection;
