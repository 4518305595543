import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from '../helpers/utils';
import { trackEvent } from '../integrations/analytics';

import FlexContainer from '../elements/FlexContainer';
import MobileTopNav from '../universal/MobileTopNav';
import BannerSection from './BannerSection';
import OnDeck from './OnDeck';
import FitnessSection from './FitnessSection';
import MealsSection from './MealsSection';
import BlogFeed from './BlogFeed';
import CircleModule from './CircleModule';

import {
  addRecipeToMealPlan,
  addFoodToMealPlan,
} from '../store/actions/mealplan';
import {
  addWorkoutToFitnessPlan,
  addActivityToFitnessPlan,
} from '../store/actions/fitnessplan';
import { getExploreBanners } from '../services/api/campaigns';

const ExploreTab = props => {
  const { history } = useReactRouter();
  const dispatch = useDispatch();
  const userName = useSelector(state => state.currentUser.user.fullName);
  const mealPlan = useSelector(state => state.activeMealPlan);
  const fitnessPlan = useSelector(state => state.activeFitnessPlan);
  const subscriptionActive = useSelector(
    state => state.currentUser.user.isSubscriptionActive
  );

  const [activeBanners, setActiveBanners] = useState([]);
  const loadBanners = async () => {
    const activeExploreBanners = await getExploreBanners({
      query: { active: true },
    });
    setActiveBanners(activeExploreBanners);
  };

  useEffect(() => {
    if (!isMobile()) {
      history.push('/app');
    } else {
      loadBanners();
    }
  }, []);

  const meals = [
    {
      tabIndex: 0,
      meal: 'Breakfast',
    },
    {
      tabIndex: 1,
      meal: 'Lunch',
    },
    {
      tabIndex: 2,
      meal: 'Dinner',
    },
  ];

  async function addRecipeToUserMealPlan(params) {
    trackEvent(`Add Recipe (Explore Page)`, {
      recipe: params.recipe,
    });
    dispatch(addRecipeToMealPlan(mealPlan._id, params));
    history.push('/app/mealplan', { meal: params.mealType, date: params.date });
  }

  async function addFoodToUserMealPlan(params) {
    trackEvent(`Add Food (Explore Page)`, {
      food: params.food,
    });
    dispatch(addFoodToMealPlan(mealPlan._id, params));
    history.push('/app/mealplan', { meal: params.mealType, date: params.date });
  }

  async function addWorkoutToUserFitnessPlan(params) {
    trackEvent(`Add Workout (Explore Page)`, { workout: params.workout });
    dispatch(addWorkoutToFitnessPlan(fitnessPlan._id, params));
    history.push('/app/fitnessplan', { date: params.date });
  }

  async function addActivityToUserFitnessPlan(params) {
    trackEvent(`Add Activity (Explore Page)`, { activity: params.activity });
    dispatch(addActivityToFitnessPlan(fitnessPlan._id, params));
    history.push('/app/fitnessplan', { date: params.date });
  }

  useEffect(() => {
    const historyListener = history.listen(location => {
      if (history.action === 'PUSH') {
        // Ensure we navigate directly to the meal tab corresponding to the current meal
        localStorage.setItem('from_explore', 'yes');
        if (location.pathname === '/app/fitnessplan' && location?.state?.date) {
          localStorage.setItem(
            'fitnessplan_dateValue',
            JSON.stringify(location.state.date)
          );
        } else if (
          location.pathname === '/app/mealplan' &&
          location?.state?.date &&
          location?.state?.meal
        ) {
          const tabIndex = meals.findIndex(
            el => el.meal === location.state.meal
          );
          localStorage.setItem('mealplan_tabValue', tabIndex);
          localStorage.setItem(
            'mealplan_dateValue',
            JSON.stringify(location.state.date)
          );
        }
      }
    });

    return function cleanup() {
      historyListener();
    };
  }, [mealPlan, fitnessPlan]);

  return (
    <div style={{ overflowX: 'hidden ' }}>
      <MobileTopNav showGreeting={true} userName={userName} arrowsDisabled />
      <PageContainer flexDirection="column" data-test="explore-tab">
        <BannerSection
          activeBanners={activeBanners}
          addRecipe={addRecipeToUserMealPlan}
          addFood={addFoodToUserMealPlan}
          addWorkout={addWorkoutToUserFitnessPlan}
          addActivity={addActivityToUserFitnessPlan}
        />
        <OnDeck
          meals={meals}
          mealPlan={mealPlan}
          fitnessPlan={fitnessPlan}
          subscriptionActive={subscriptionActive}
        />
        <CircleModule />
        <MealsSection addRecipe={addRecipeToUserMealPlan} />
        <FitnessSection addWorkout={addWorkoutToUserFitnessPlan} />
        <BlogFeed />
      </PageContainer>
    </div>
  );
};

const PageContainer = styled(FlexContainer)`
  padding: 60px 15px 120px 15px;
  min-height: 100vh;
  gap: 24px;
`;

export default ExploreTab;
