import React from 'react';
import Banner from '../../explore/Banner';
import { Draggable } from 'react-beautiful-dnd';

const DraggableBanner = ({
  bannerId,
  index,
  title,
  name,
  textBody,
  imageUrl,
  color,
  actionType,
  ...props
}) => {
  return (
    <Draggable draggableId={bannerId} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          data-test={props['data-test']}
        >
          <Banner
            title={title}
            textBody={textBody}
            imageUrl={imageUrl}
            color={color}
            actionType={actionType}
          />
        </div>
      )}
    </Draggable>
  );
};

export default DraggableBanner;
