import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import Avatar from '../elements/Avatar';
import FlexContainer from '../elements/FlexContainer';
// import HeaderDropdownContainer from './HeaderDropdownContainer'; TODO: Post-Beta feature
import ProfileDropdown from './ProfileDropdown';
import CircleModal from './CircleModal';

import useAnchoredDropdown from '../hooks/useAnchoredDropdown';

const TopNav = ({ title, ...props }) => {
  const { open, anchorEl, handleClick, handleClose } = useAnchoredDropdown();

  const location = useLocation();
  const showCircle =
    location.pathname === '/app' || location.pathname === '/app/dashboard';

  // Circle
  const [circleOpen, setCircleOpen] = useState(false);
  const handleCircleClose = () => {
    setCircleOpen(false);
  };

  return (
    <Nav>
      <LeftNav alignItems="center">
        <PageTitle data-test="nav-title">{title || 'Page Title'}</PageTitle>
        <EditPreferences
          to="/app/profile/preferences"
          data-test="nav-prefs-link"
        >
          Edit Preferences
        </EditPreferences>
      </LeftNav>
      <RightNav>
        {/* TODO: Post-Beta feature
        https://app.clickup.com/t/8etm8n
        <NavSpacer1>
          <HeaderDropdownContainer type="notifications" />
        </NavSpacer1>
        <NavSpacer2>
          <HeaderDropdownContainer type="messages" />
        </NavSpacer2> */}

        {showCircle && (
          <CircleContainer
            alignItems="center"
            onClick={() => setCircleOpen(true)}
          >
            <Avatar src={`${process.env.PUBLIC_URL}/logo64.png`} />
            <Text>Access Nutriology Transformation Program</Text>
          </CircleContainer>
        )}
        <AvatarWrapper
          justify="center"
          alignItems="center"
          name="profileIcon"
          data-test="nav-avatar"
          onClick={handleClick}
        >
          <Avatar src={props.currentUser.avatarUrl} />
          <ProfileDropdown
            handleClose={handleClose}
            anchorEl={anchorEl}
            open={open}
            placement="bottom-end"
            name="profile"
          />
        </AvatarWrapper>
      </RightNav>
      <CircleModal open={circleOpen} handleClose={handleCircleClose} />
    </Nav>
  );
};

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  height: 64px;
  padding: 0 55px;
  border-bottom: 1px solid ${colors.primary200};
  position: relative;
  z-index: 5;
  flex-shrink: 0;
  background-color: white;
`;

const LeftNav = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
  align-items: center;
`;

const RightNav = styled.div`
  display: flex;
  position: relative;
  justify-content: space-around;
  align-items: center;
`;

const Text = styled.p`
  color: ${colors.secondary600};
  font-size: 12px;
  font-weight: 500;
`;

const CircleContainer = styled(FlexContainer)`
  border: 1px solid ${colors.secondary600};
  border-radius: 32px;
  padding: 0 12px 0 2px;
  width: fit-content;
  height: 38px;
  align-self: center;
  gap: 4px;
  margin-right: 16px;

  &:hover {
    background-color: ${colors.secondary600};
    cursor: pointer;
  }
  &:hover > ${Text} {
    color: #fff;
  }
`;

// TODO: Post-Beta feature
// const NavSpacer1 = styled(FlexContainer)`
//   margin-right: 8px;
//   position: relative;
// `;

// const NavSpacer2 = styled(FlexContainer)`
//   margin-right: 12px;
//   position: relative;
// `;

const AvatarWrapper = styled(FlexContainer)`
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  position: relative;

  &:hover,
  &:focus {
    background-color: ${colors.primary100};
    cursor: pointer;
  }

  &:active {
    background-color: ${colors.primary200};
  }
`;

const EditPreferences = styled(Link)`
  color: ${colors.secondary500};
  font-size: 13px;
  margin-left: 16px;
  background-color: ${colors.secondary050};
  padding: 4px 8px;

  &:hover {
    color: ${colors.secondary400};
    cursor: pointer;
  }

  &:active {
    color: ${colors.secondary300};
  }
`;

const PageTitle = styled.h3`
  color: ${colors.primary700};
`;

//font-family: "Raleway";
function mapStateToProps(state) {
  const { currentUser } = state;
  return { currentUser: currentUser.user };
}

export default connect(
  mapStateToProps,
  {}
)(TopNav);
