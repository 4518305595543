import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import {
  formatProtein,
  formatTotalCarbohydrate,
  formatTotalFat,
} from '../../helpers/nutrition';
import { PieChart } from 'react-minimal-pie-chart';
import { isMobile } from '../../helpers/utils';
import FractionInput from '../../elements/FractionInput';

const PlaceholderStats = ({
  recipe,
  editable,
  values = {},
  handleContentEditable,
  handleValueChanged,
}) => {
  const { nutrients } = recipe;
  const totalCalories = nutrients.calories;
  const protein = formatProtein(nutrients.proteins, false);
  const carbs = formatTotalCarbohydrate(nutrients.carbohydrates, false);
  const fat = formatTotalFat(nutrients.fat, false);

  const data = [
    {
      title: 'Protein',
      value: (protein * 4) / (totalCalories || 1),
      color: colors.pie1,
    },
    {
      title: 'Carbs',
      value: (carbs * 4) / (totalCalories || 1),
      color: colors.pie2,
    },
    {
      title: 'Fat',
      value: (fat * 9) / (totalCalories || 1),
      color: colors.pie3,
    },
  ];

  return (
    <Container alignItems="center" justify="center">
      {!isMobile() && (
        <StyledPieChart
          data={data}
          labelPosition="60"
          label={({ x, y, dx, dy, dataEntry }) =>
            `${Math.round(dataEntry.percentage)}%`
          }
          labelStyle={index => ({
            fill: '#fff',
            fontSize: '14px',
            fontWeight: 700,
          })}
        />
      )}
      <MacrosContainer>
        <FlexContainer flexDirection="column" alignItems="center" gap="6px">
          <MacroPill color={colors.pie1} justify="center">
            Protein
          </MacroPill>
          {editable ? (
            <FractionInput
              id="proteins"
              name="proteins"
              onValueChanged={handleValueChanged}
              placeholder={protein}
              value={values?.proteins || protein}
              minValue="0"
              width="50px"
              data-test="macro-protein-edit"
            />
          ) : (
            <MacroStat data-test="macro-protein">{protein}g</MacroStat>
          )}
        </FlexContainer>
        <FlexContainer flexDirection="column" alignItems="center" gap="6px">
          <MacroPill color={colors.pie2} justify="center">
            Carbs
          </MacroPill>
          {editable ? (
            <FractionInput
              id="carbohydrates"
              name="carbohydrates"
              onValueChanged={handleValueChanged}
              placeholder={carbs}
              value={values?.carbohydrates || carbs}
              minValue="0"
              width="50px"
              data-test="macro-carbs-edit"
            />
          ) : (
            <MacroStat data-test="macro-carbs">{carbs}g</MacroStat>
          )}
        </FlexContainer>
        <FlexContainer flexDirection="column" alignItems="center" gap="6px">
          <MacroPill color={colors.pie3} justify="center">
            Fat
          </MacroPill>
          {editable ? (
            <FractionInput
              id="fat"
              name="fat"
              onValueChanged={handleValueChanged}
              placeholder={fat}
              value={values?.fat || fat}
              minValue="0"
              width="50px"
              data-test="macro-fat-edit"
            />
          ) : (
            <MacroStat data-test="macro-fat">{fat}g</MacroStat>
          )}
        </FlexContainer>
      </MacrosContainer>
      <CaloriesBox flexDirection="column">
        <CalorieNumber
          contentEditable={editable}
          onInput={handleContentEditable}
          data-name="calories"
          data-value={values?.calories || totalCalories}
          data-type="numeric"
          data-test="macro-calories"
          onKeyDown={e => {
            if (!e.key.match(/[0-9]/g) && e.key !== 'Backspace') {
              e.preventDefault();
            }
          }}
          suppressContentEditableWarning={true}
        >
          {totalCalories}
        </CalorieNumber>
        <CalorieLabel>Calories</CalorieLabel>
      </CaloriesBox>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  border-radius: 4px;
  border: 1px solid ${colors.primary200};
  padding: 14px 24px;
  gap: 32px;
  margin: ${props => (isMobile() ? '0 16px 104px 16px' : 'initial')};
`;

const StyledPieChart = styled(PieChart)`
  width: 90px;
  height: 90px;

  @media (max-width: 1350px) {
    width: 75px;
    height: 75px;
  }

  @media (max-width: 1330px) {
    width: 65px;
    height: 65px;

    & > text {
      font-size: 16px !important;
    }
  }

  @media (max-width: 1300px) {
    width: 55px;
    height: 55px;

    & > text {
      display: none;
    }
  }

  @media (max-width: 1275px) {
    display: none;
  }
`;

const MacrosContainer = styled(FlexContainer)`
  gap: 28px;
`;

const MacroPill = styled(FlexContainer)`
  background-color: ${props => (props.color ? props.color : colors.primary100)};
  min-width: 30px;
  padding: 0 10px;
  border-radius: 31px;
  color: ${colors.secondary100};
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
`;

const MacroStat = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: ${colors.primary800};
`;

const CaloriesBox = styled(FlexContainer)`
  border-radius: 33.5px 13px 13px 13px;
  background-color: ${colors.secondary600};
  padding: 10px 13px;
  gap: 8px;
  height: fit-content;
`;

const CalorieNumber = styled.h5`
  color: ${props =>
    props.contentEditable ? colors.primary100 : colors.hliteone100};
  text-align: right;
  font-size: 24px;
  font-weight: 700;
  outline: 0px solid transparent;

  &:focus {
    outline: 0px solid transparent;
  }
`;

const CalorieLabel = styled.p`
  color: ${colors.hliteone100};
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
`;

export default PlaceholderStats;
