import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { default as MaterialButton } from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const MobileCollapseIcon = ({ toggleSelect, ...props }) => (
  <Container
    flexDirection="column"
    alignItems="center"
    onClick={toggleSelect}
    {...props}
  >
    {props.open ? (
      <Icon
        endIcon={<ExpandLessIcon style={{ color: '#FF6969' }} />}
        onClick={toggleSelect}
      >
        <Textstyle>close</Textstyle>
      </Icon>
    ) : (
      <Icon
        endIcon={<ExpandMoreIcon style={{ color: '#FF6969' }} />}
        onClick={toggleSelect}
      >
        <Textstyle>open</Textstyle>
      </Icon>
    )}
  </Container>
);

const Container = styled(FlexContainer)`
  position: relative;
`;

const Icon = styled(MaterialButton)`
  box-sizing: border-box;
  height: 26px;
  border-radius: 14px;
  background-color: #ffffff;
`;
const Textstyle = styled.div`
  color: #ff6969;
  font-family: 'Work Sans';
  font-size: 15px;
  font-weight: bold;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: right;
`;

export default MobileCollapseIcon;
