import { apiCall } from './api';
import { omit } from 'lodash';

export function getFoods(params) {
  return apiCall('get', `/food`, params);
}

export function searchFoods(params) {
  return apiCall('get', `/food/search/${params.query}`, params);
}

export function searchFoodsByEsha(params) {
  return apiCall('get', `/food/searchByEsha/${params.query}`, params);
}

export function searchUserFoods(params) {
  return apiCall('get', `/fooduser/search/${params.query}`, params);
}

export function getFood(id, params) {
  return apiCall('get', `/food/${id}`, params);
}

export function getFoodUsers(params) {
  params.query = {
    user: params.userId,
    foodAdmin: null,
  };
  return apiCall('get', `/fooduser/`, params);
}

export function getFoodUser(params) {
  params.query = {
    _id: params.foodId,
    user: params.userId,
  };

  return apiCall('get', `/fooduser`, omit(params, ['foodId'])).then(
    foodsUser => {
      return foodsUser[0];
    }
  );
}

export function addUserFood(params) {
  return apiCall('post', `/fooduser`, params);
}

export function createFood(params) {
  return apiCall('post', `/food`, params);
}

export function getUserFavoriteFoods(userId, foodIds, populateAdmin = false) {
  let params = {};
  params.populate = populateAdmin
    ? [
        {
          path: 'foodAdmin',
          populate: { path: 'foods.food' },
        },
      ]
    : [{ path: 'foods.food' }];
  params.query = {
    user: userId,
    foodAdmin: {
      $in: foodIds,
    },
  };
  return apiCall('get', `/fooduser`, params);
}

export function getUserCustomFavoriteFoods(userId, foodIds) {
  let params = {};
  params.populate = [{ path: 'foods.food' }];
  params.query = {
    user: userId,
    _id: {
      $in: foodIds,
    },
  };
  return apiCall('get', `/fooduser`, params);
}

export function editFood(id, params) {
  return apiCall('put', `/food/${id}`, params);
}

export async function getFoodReferences(id, params = {}) {
  const mealPlans = await apiCall('get', `/mealplan`, {
    query: { 'foods.food': id },
  });
  const recipes = await apiCall('get', `/recipe`, {
    query: { 'foods.food': id },
  });
  return [...mealPlans, ...recipes];
}

export function replaceFoodsOnMealPlan(mealPlan, oldFood, newFood) {
  return apiCall(
    'put',
    `/mealplan/${mealPlan}/replace_food/${oldFood}/${newFood}`
  );
}
