import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';

import PieChart from '../elements/PieChart';

const RecommendedMealPlan = ({
  recommended,
  recommendedCalories,
  ...props
}) => {
  const recommendedFat = recommended ? recommended.fat : 0;
  const recommendedProtein = recommended ? recommended.protein : 0;
  const recommendedCarbs = recommended ? recommended.carbs : 0;

  return (
    <Container flexDirection="column" alignItems="center">
      <TopContainer flexDirection="column">
        <RecommendedValue data-test="recStats-recommendedCalories">
          {recommendedCalories} calories per day
        </RecommendedValue>
      </TopContainer>
      <BottomContainer name="bottom" flexDirection="column">
        <DailyMacrosHeader>Recommended Daily Macros</DailyMacrosHeader>
        <StatsContainer justify="space-between" alignItems="center">
          <MacrosContainer flexDirection="column">
            <MacroItem>
              <MacroQuantity data-test="recStats-recommendedProtein">
                {recommendedProtein} g
              </MacroQuantity>
              <Macro color={colors.pie1}>Protein</Macro>
            </MacroItem>
            <MacroItem>
              <MacroQuantity data-test="recStats-recommendedCarbs">
                {recommendedCarbs} g
              </MacroQuantity>
              <Macro color={colors.pie2}>Carbs</Macro>
            </MacroItem>
            <MacroItem>
              <MacroQuantity data-test="recStats-recommendedFat">
                {recommendedFat} g
              </MacroQuantity>
              <Macro color={colors.pie3}>Fat</Macro>
            </MacroItem>
          </MacrosContainer>
          <GraphContainer>
            <PieChart
              protein={recommendedProtein}
              carbs={recommendedCarbs}
              fat={recommendedFat}
              totalCalories={recommendedCalories}
            />
          </GraphContainer>
        </StatsContainer>
      </BottomContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  position: relative;
  width: 100%;
`;

const TopContainer = styled(FlexContainer)`
  border-bottom: 0.5px solid ${colors.primary400};
  width: 100%;
  padding: 0 0 32px 0;
`;

const BottomContainer = styled(FlexContainer)`
  padding-top: 24px;
  width: 100%;
`;

const RecommendedValue = styled.h3`
  color: ${colors.primary700};
`;

const DailyMacrosHeader = styled.h6`
  color: ${colors.primary600};
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
`;

const StatsContainer = styled(FlexContainer)`
  margin-top: 16px;
`;

const MacrosContainer = styled(FlexContainer)``;

const GraphContainer = styled(FlexContainer)``;

const MacroItem = styled(FlexContainer)`
  color: ${colors.primary800};
  margin-bottom: 8px;
`;

const MacroQuantity = styled.h5`
  width: 44px;
  font-weight: 400;
`;

const Macro = styled.h5`
  font-weight: 400;
  position: relative;
  margin-left: 14px;

  ::before {
    content: ' ';
    position: absolute;
    margin-right: 6px;
    width: 8px;
    height: 8px;
    background-color: ${props => props.color};
    top: 33%;
    left: -14px;
  }
`;

export default RecommendedMealPlan;
