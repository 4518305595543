import { useState, useEffect } from 'react';
import { default as validate } from '../helpers/formValidation';

const useFormSubmit = (callback, values, setValues, formType) => {
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
    setIsSubmitting(false);
  }, [callback, errors, isSubmitting]);

  const handleSubmit = e => {
    e.preventDefault();
    setErrors(validate(values, formType));
    setIsSubmitting(true);
  };

  const resetErrors = () => {
    setErrors({});
  };

  return {
    handleSubmit,
    errors,
    resetErrors,
  };
};

export default useFormSubmit;
