import { apiCall } from './api';

export async function addExploreBanner(params) {
  try {
    if (params.image) {
      const imageData = new FormData();
      // only allow pngs
      imageData.append('png', true);
      imageData.append('jpg', false);
      imageData.append('pdf', false);
      imageData.append('gif', false);
      imageData.append('image', params.image);
      const imageResult = await apiCall('post', '/users/upload', imageData);

      params.imageUrl = imageResult.file;
    }
    if (params.modalImage) {
      const modalData = new FormData();
      modalData.append('image', params.modalImage);
      const modalResult = await apiCall('post', '/users/upload', modalData);
      params.modalImageUrl = modalResult.file;
    }
    return apiCall('post', `/explorebanner`, params);
  } catch (err) {
    console.error(err);
  }
}

export function getExploreBanner(id, params = {}) {
  return apiCall('get', `/explorebanner/${id}`, params);
}

export function getExploreBanners(params) {
  return apiCall('get', `/explorebanner`, params);
}

export async function editExploreBanner(id, params) {
  if (params.image) {
    const imageData = new FormData();
    // only allow pngs
    imageData.append('png', true);
    imageData.append('jpg', false);
    imageData.append('pdf', false);
    imageData.append('gif', false);
    imageData.append('image', params.image);
    const imageResult = await apiCall('post', '/users/upload', imageData);

    params.imageUrl = imageResult.file;
  }
  if (params.modalImage) {
    const modalData = new FormData();
    modalData.append('image', params.modalImage);
    const modalResult = await apiCall('post', '/users/upload', modalData);
    params.modalImageUrl = modalResult.file;
  }
  return apiCall('put', `/explorebanner/${id}`, params);
}

export function searchExploreBanners(params) {
  return apiCall('get', `/explorebanner/search/${params.query}`, params);
}
