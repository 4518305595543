import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ExploreSection from './ExploreSection';
import { getMealCategories } from '../helpers/exploreCategories';
import ModalContainer from '../sharedModals/ModalContainer';
import useModal from '../hooks/useModal';

const MealsSection = ({ addRecipe, props }) => {
  const { open, launchModal, closeModal } = useModal(false);
  const [category, setCategory] = useState({});
  const dietType = useSelector(
    state => state?.currentUser?.user?.foodPreferences.eatingPreference
  );

  const mealCategories = getMealCategories(dietType);

  const handleClick = category => {
    setCategory(category);
    launchModal();
  };

  return (
    <>
      <ExploreSection
        sectionName="Explore Meals and Snacks"
        items={mealCategories}
        handleClick={handleClick}
        data-test="explore-meals"
      />
      <ModalContainer
        addRecipe={addRecipe}
        open={open}
        category={category}
        handleClose={closeModal}
        initialScreen="Explore Modal"
        context={{ type: 'meal' }}
        {...props}
      />
    </>
  );
};

export default MealsSection;
