import React, { useRef } from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import RadioInput from '../elements/RadioInput';
import InfoIcon from '../icons/InfoIcon';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Box from '@material-ui/core/Box';
import Popper from '@material-ui/core/Popper';
import useAnchoredDropdown from '../hooks/useAnchoredDropdown';
import CheckBox from '../elements/CheckBox';

const DetailCard = ({
  imgSrc,
  title,
  isSelected,
  name,
  allowMultiple = false,
  extraInfo = '',
  handleSelect,
  ...props
}) => {
  const containerRef = useRef(null);
  const { open, handleClick, handleClose } = useAnchoredDropdown();

  const handleChange = e => {
    e.stopPropagation();
    handleSelect(e);
  };
  return (
    <div ref={containerRef}>
      <Container
        onClick={handleChange}
        name={name}
        isSelected={isSelected}
        flexDirection="column"
        justify="space-around"
        alignItems="center"
        data-test={props['data-test']}
        size={props.size}
      >
        <Img src={imgSrc} alt={title} size={props.size} />
        <Title size={props.size}>{title}</Title>
        {allowMultiple ? (
          <CheckBox
            checked={isSelected}
            onChange={handleChange}
            name={name}
            size="sm"
          />
        ) : (
          <RadioInput
            checked={isSelected}
            onChange={handleSelect}
            value={title}
            hideValue={true}
            name={name}
            spacing="0px"
          />
        )}
        {extraInfo && (
          <>
            <InfoIconContainer>
              <InfoIcon onClick={handleClick} />
            </InfoIconContainer>
            <Popper
              onClose={handleClose}
              anchorEl={containerRef.current}
              open={open}
              placement="right-start"
            >
              <ClickAwayListener onClickAway={handleClose}>
                <StyledBox>{extraInfo}</StyledBox>
              </ClickAwayListener>
            </Popper>
          </>
        )}
      </Container>
    </div>
  );
};

const Container = styled(FlexContainer)`
  width: ${props => (props.size === 'large' ? '160px' : '106px')};
  height: ${props => (props.size === 'large' ? '160px' : '112px')};
  background: ${props => (props.isSelected ? '#fcfffd' : 'initial')};
  border: ${props =>
    props.isSelected
      ? `1.5px solid ${colors.secondary600}`
      : `1.5px solid ${colors.primary100}`};
  box-shadow: 0px 8px 20px rgba(29, 29, 29, 0.1);
  border-radius: 6px;
  position: relative;
  padding: 14px 0;
`;

const Title = styled.h6`
  font-weight: 400;
  font-size: ${props => (props.size === 'large' ? '16px' : '14px')};
  line-height: 16px;
  text-align: center;
  color: ${colors.primary700};
`;
const InfoIconContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
`;

const StyledBox = styled(Box)`
  padding: 8px;
  margin: 0 12px;
  width: 100px;
  min-height: 44px;
  background: ${colors.secondary600};
  box-shadow: 0px 8px 20px rgba(29, 29, 29, 0.1);
  border-radius: 6px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  transform: translateY(-44px);
`;

const Img = styled.img`
  height: ${props => (props.size === 'large' ? '68px' : '48px')};
  width: ${props => (props.size === 'large' ? '68px' : '48px')};
`;

export default DetailCard;
