import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import Avatar from '@material-ui/core/Avatar';
import FlexContainer from '../elements/FlexContainer';
import Truncate from 'react-truncate';
import { getImageURL } from '../services/api/api';

const Chip = ({
  selected,
  label,
  avatar,
  name,
  testId,
  leftover,
  ...props
}) => {
  const imgUrl = (ratio = 1) => {
    const size = 32 * ratio;
    return getImageURL(
      avatar,
      `resizing_type:fill/height:${size}/width:${size}`
    );
  };

  // Necessary because leftover banner is positioned absolute and will overlap other chips otherwise
  const height = leftover ? '54px' : '38px';

  return (
    <FlexContainer
      style={{ height: height, width: '100%' }}
      alignItems="flex-end"
    >
      <ChipContainer leftover={leftover} {...props}>
        <Avatar alt={avatar} src={imgUrl()} srcSet={`${imgUrl(2)} 2x`} />
        <Label alt={label} data-test={testId ?? ''}>
          <Truncate>{label}</Truncate>
        </Label>
        {leftover && (
          <LabelContainer
            justify="center"
            alignItems="center"
            data-test="leftover-banner"
          >
            <LeftoverLabel>Leftovers</LeftoverLabel>
          </LabelContainer>
        )}
      </ChipContainer>
    </FlexContainer>
  );
};

const ChipContainer = styled(FlexContainer)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: ${props =>
    props.leftover
      ? `1px solid ${colors.secondary600}`
      : `1px solid ${colors.primary300}`};
  border-radius: ${props => (props.leftover ? '20px 0 20px 20px' : '20px')};
  height: 38px;
  flex-grow: 1;
  align-items: center;
  padding: 0px 4px;
  opacity: ${props => (props.logged ? '.35' : '1')};
  position: relative;

  & .MuiAvatar-root {
    width: 32px;
    height: 32px;
  }
`;

const Label = styled.div`
  color: ${colors.primary800};
  font-size: 15px;
  margin-left: 8px;
  text-align: left;
  width: 100%;
`;

const LabelContainer = styled(FlexContainer)`
  position: absolute;
  top: -16px;
  right: -1px;
  border-radius: 4px 4px 0px 0px;
  width: 64px;
  height: 16px;
  background-color: ${colors.secondary600};
`;

const LeftoverLabel = styled.p`
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
  color: #fff;
`;

export default Chip;
