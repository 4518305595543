import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import { connect } from 'react-redux';
import { trackEvent } from '../../integrations/analytics';

import Avatar from '@material-ui/core/Avatar';
import LogIcon from '../../icons/LogIcon';
import IntensitySet from '../../elements/IntensitySet';
import { setFitnessPlanActivityProperty } from '../../store/actions/fitnessplan';
import { getImageURL } from '../../services/api/api';
import RemoveIcon from '../../icons/RemoveIcon';
import { isMobile } from '../../helpers/utils';

const Activity = ({
  activity,
  fitnessPlan,
  removeActivity,
  setFitnessPlanActivityProperty,
  ...props
}) => {
  async function toggleLogged(event) {
    trackEvent(
      `Log Activity (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`,
      { activity: activity, logged: !activity.logged }
    );
    event.preventDefault();
    setFitnessPlanActivityProperty(
      fitnessPlan,
      activity,
      'logged',
      !activity.logged
    );
  }

  const handleRemove = e => {
    removeActivity(activity._id);
  };

  const activityImageURL = (ratio = 1) => {
    const width = 144 * ratio;
    const widthMobile = 375 * ratio;
    const height = 144 * ratio;
    const heightMobile = 242 * ratio;
    return isMobile()
      ? getImageURL(
          activity.activity.imageUrl,
          `resizing_type:fill/height:${heightMobile}/width:${widthMobile}`
        )
      : getImageURL(
          activity.activity.imageUrl,
          `resizing_type:fill/height:${height}/width:${width}`
        );
  };

  if (!isMobile()) {
    // Web version
    return (
      <CardContainer
        flexDirection="column"
        data-test="activity-card"
        id={props.id}
      >
        <TopContainer alignItems="center" justify="space-between">
          <LeftContainer
            alignItems="center"
            data-test="activity-info-container"
          >
            <StyledAvatar
              src={activityImageURL()}
              srcSet={`${activityImageURL(2)} 2x`}
            />
            <ActivityInfoContainer flexDirection="column">
              <ActivityHeader data-test="activity-card-name">
                {activity.activity.name}
              </ActivityHeader>
              <ActivityInfoRow alignItems="center">
                <ActivityInfo data-test="activity-card-caloriesBurned">
                  <span style={{ fontWeight: 700 }}>
                    {activity.activity.caloriesBurned}
                  </span>{' '}
                  calories
                </ActivityInfo>
                <ActivityInfo data-test="activity-card-duration">
                  <span style={{ fontWeight: 700 }}>
                    {activity.activity.duration}
                  </span>{' '}
                  mins
                </ActivityInfo>
              </ActivityInfoRow>
              <IntensityContainer>
                <ActivityInfo>Intensity: </ActivityInfo>
                <IntensitySet
                  intensity={activity.activity.intensity}
                  data-test="activity-card-intensity"
                />
              </IntensityContainer>
            </ActivityInfoContainer>
          </LeftContainer>
          <RightContainer>
            <IconsContainer alignItems="center">
              <LogIcon
                hideLabel
                logged={activity.logged}
                toggleSelect={toggleLogged}
                width="40px"
                height="40px"
                data-test="activity-card-log"
              />
              <RemoveIcon
                hideLabel
                toggleSelect={handleRemove}
                width="40px"
                height="40px"
                shrinkIcon={true}
                data-test="activity-card-remove"
              />
            </IconsContainer>
          </RightContainer>
        </TopContainer>
      </CardContainer>
    );
  } else {
    // Mobile version
    return (
      <MobileActivityContainer id={props.id} data-test="mobile-activity-card">
        <MobileImageContaitner>
          <ActivityImage
            src={activityImageURL()}
            srcSet={`${activityImageURL(2)} 2x`}
          ></ActivityImage>
          <ActivityLabel justify="center" alignItems="center">
            <LabelText>ACTIVITY</LabelText>
          </ActivityLabel>
        </MobileImageContaitner>
        <MobileCardContainer flexDirection="column">
          <MobileTopContainer
            alignItems="center"
            justify="space-between"
            flexDirection="column"
          >
            <MobileActivityInfoContainer
              flexDirection="column"
              alignItems="center"
            >
              <MobActivityHeader data-test="mobile-activity-name">
                {activity.activity.name}
              </MobActivityHeader>
              <ActivityInfoRow alignItems="center">
                <ActivityInfo data-test="mobile-activity-calories">
                  <span style={{ fontWeight: 700 }}>
                    {activity.activity.caloriesBurned}
                  </span>{' '}
                  calories
                </ActivityInfo>
                <ActivityInfo data-test="mobile-activity-duration">
                  <span style={{ fontWeight: 700 }}>
                    {activity.activity.duration}
                  </span>{' '}
                  mins
                </ActivityInfo>
              </ActivityInfoRow>
              <IntensityContainer>
                <ActivityInfo>Intensity: </ActivityInfo>
                <IntensitySet
                  intensity={activity.activity.intensity}
                  data-test="mobile-activity-intensity"
                />
              </IntensityContainer>
            </MobileActivityInfoContainer>
            <IconsContainer>
              <RemoveIcon
                toggleSelect={handleRemove}
                width="40px"
                height="40px"
                shrinkIcon={true}
                data-test="mobile-activity-remove"
              />
              <LogIcon
                logged={activity.logged}
                toggleSelect={toggleLogged}
                width="40px"
                height="40px"
                data-test="mobile-activity-log"
              />
            </IconsContainer>
          </MobileTopContainer>
        </MobileCardContainer>
      </MobileActivityContainer>
    );
  }
};

/** Mobile **/

const MobileActivityContainer = styled.div``;

const MobileImageContaitner = styled.div`
  position: relative;
`;

const MobileCardContainer = styled(FlexContainer)`
  border-bottom: 1px solid ${colors.primary200};
  border-radius: 4px;
  background-color: #fff;
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  min-height: 144px;
  position: relative;
`;

const ActivityImage = styled.img`
  height: 242px;
  width: 100%;
  flex-shrink: 0;
`;

const ActivityLabel = styled(FlexContainer)`
  height: 16px;
  width: 72px;
  top: 12px;
  left: -5px;
  border-radius: 8px;
  background-color: ${colors.hlitetwo400};
  color: #ffffff;
  position: absolute;
`;

const LabelText = styled.div`
  height: 14px;
  width: 56px;
  text-align: center;
  color: #ffffff;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 14px;
  margin: 1px 8px 1px 8px;
`;

const MobileTopContainer = styled(FlexContainer)`
  padding: 24px;
  gap: 18px;
`;

const MobileActivityInfoContainer = styled(FlexContainer)`
  gap: 8px;
`;

const IconsContainer = styled(FlexContainer)`
  gap: ${() => (isMobile() ? '36px' : '24px')};
`;

const MobActivityHeader = styled.h2`
  color: ${colors.primary800};
  font-weight: 700;
  text-align: center;
`;

/** Web **/

const CardContainer = styled(FlexContainer)`
  border: 1px solid ${colors.primary200};
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  min-height: 144px;
  min-width: 600px;
  position: relative;
`;

const StyledAvatar = styled(Avatar)`
  height: 144px; // Update image dimensions accordingly.
  width: 144px; // Update image dimensions accordingly.
`;

const TopContainer = styled(FlexContainer)`
  padding: 24px 24px 24px 32px;
  border-bottom: 0.5px solid ${colors.primary400};
`;

const LeftContainer = styled(FlexContainer)`
  height: 100%;
`;
const RightContainer = styled(FlexContainer)`
  height: 100%;
`;

const ActivityInfoContainer = styled(FlexContainer)`
  margin-left: 32px;
  gap: 8px;
`;

const ActivityInfoRow = styled(FlexContainer)`
  gap: 24px;
`;

const ActivityHeader = styled.h2`
  color: ${colors.primary800};
  font-weight: 700;
`;

const ActivityInfo = styled.h6`
  font-weight: 400;
  color: ${colors.primary800};
`;

const IntensityContainer = styled(FlexContainer)`
  > :first-child {
    margin-right: 8px;
  }
`;

export default connect(
  null,
  {
    setFitnessPlanActivityProperty,
  }
)(Activity);
