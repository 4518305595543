import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';

const BackIcon = ({ handleClick, color, hideCircle, ...props }) => {
  return hideCircle ? (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      color={color}
      onClick={handleClick}
    >
      <line
        x1="22.26"
        y1="12"
        x2="2.89"
        y2="12"
        strokeMiterlimit="10"
        strokeWidth="2px"
      />
      <line
        x1="10.28"
        y1="19.39"
        x2="2.19"
        y2="11.29"
        strokeMiterlimit="10"
        strokeWidth="2px"
      />
      <line
        x1="10.28"
        y1="4.61"
        x2="2.89"
        y2="12"
        strokeMiterlimit="10"
        strokeWidth="2px"
      />
    </Icon>
  ) : (
    <Circle
      justify="center"
      alignItems="center"
      onClick={handleClick}
      {...props}
    >
      <Icon
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        color={color}
      >
        <line
          x1="22.26"
          y1="12"
          x2="2.89"
          y2="12"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <line
          x1="10.28"
          y1="19.39"
          x2="2.19"
          y2="11.29"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <line
          x1="10.28"
          y1="4.61"
          x2="2.89"
          y2="12"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
      </Icon>
    </Circle>
  );
};

const Circle = styled(FlexContainer)`
  width: ${props => (props.width ? props.width : '32px')};
  height: ${props => (props.height ? props.height : '32px')};
  border-radius: ${props => (props.borderradius ? props.borderradius : '16px')};
  background-color: ${props =>
    props.backgroundcolor ? props.backgroundcolor : 'inherit'};
  &:hover {
    background-color: ${colors.primary100};
  }

  &:active {
    background-color: ${colors.primary200};
  }
`;

const Icon = styled.svg`
  width: 24px;
  height: 24px;

  & line {
    fill: #fff;
    stroke: ${props => props.color || colors.primary700};
  }

  &:hover {
    cursor: pointer;
  }
`;

export default BackIcon;
