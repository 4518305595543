import React, { useState, useEffect } from 'react';
import AlertStack from '../elements/AlertStack';

const Messages = ({ messages, type, testName }) => {
  const [open, setOpen] = useState(!!messages.length);

  useEffect(() => {
    if (messages.length) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [messages.length]);

  return (
    <AlertStack
      messages={messages}
      type={type}
      variant="filled"
      open={open}
      handleClose={() => setOpen(false)}
      autoHideDuration={10000}
      data-test={testName}
    />
  );
};

export default Messages;
