import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';

const ShoppingListIcon = propos => {
  return (
    <Icon
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 21"
    >
      <path d="M18.78,18.78H4.88V4.88h13.9Zm-12.9-1h11.9V5.88H5.88Z" />
      <polygon points="5.38 17.01 2.22 17.01 2.22 2.22 17.01 2.22 17.01 5.38 16.01 5.38 16.01 3.22 3.22 3.22 3.22 16.01 5.38 16.01 5.38 17.01" />
      <rect x="9.37" y="8.38" width="6.68" height="1" />
      <rect x="9.37" y="11.33" width="6.68" height="1" />
      <rect x="9.37" y="14.28" width="6.68" height="1" />
      <rect x="7.62" y="8.38" width="1" height="1" />
      <rect x="7.62" y="11.33" width="1" height="1" />
      <rect x="7.62" y="14.28" width="1" height="1" />
    </Icon>
  );
};

const Icon = styled.svg`
  width: 18px;
  height: 18px;
  & path {
    fill: ${colors.primary500};
  }
  & polygon {
    fill: ${colors.primary500};
  }
  & rect {
    fill: ${colors.primary500};
  }
`;

export default ShoppingListIcon;
