import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { getUser } from '../store/actions/general';
import {
  CardNumberElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {
  createSubscription,
  savePaymentInformation,
  syncSubscription,
} from '../services/api/payment';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { pick } from 'lodash';
import styled from 'styled-components';
import Button from '../elements/Button';
import CardDetails from './CardDetails';
import BillingDetails from './BillingDetails';
import Messages from '../universal/Messages';
import CircularProgress from '@material-ui/core/CircularProgress';
import CouponDetails from './CouponDetails';

const StripePaymentForm = ({
  price,
  currentUser,
  submitText,
  getUser,
  updatePayment,
}) => {
  const history = useHistory();

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [billingName, setBillingName] = useState(currentUser.fullName);
  const [billingEmail, setBillingEmail] = useState(currentUser.email);
  const [billingPostcode, setBillingPostcode] = useState(null);
  const [coupon, setCoupon] = useState(null);

  const [completedFields, setCompletedField] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    billingName: currentUser.fullName,
    billingEmail: currentUser.email,
    billingPostcode: false,
    // empty coupon is completed as a coupon is not mandatory.
    // However, as soon as a value is entered, it's not complete.
    // It should either be a valid coupon, or none at all.
    coupon: true,
  });
  const stripe = useStripe();
  const elements = useElements();

  const handleChange = async event => {
    // Listen for changes in form elements
    // Update the form completeness status
    setCompletedField({
      ...completedFields,
      ...{ [event.elementType]: event.complete },
    });
    if (event.elementType === 'billingName') {
      setBillingName(event.value);
    } else if (event.elementType === 'billingEmail') {
      setBillingEmail(event.value);
    } else if (event.elementType === 'billingPostcode') {
      setBillingPostcode(event.value);
    } else if (event.elementType === 'coupon') {
      setCoupon(event.value);
    }

    // and display any errors as the customer types their card details
    setError(event.error ? event.error.message : '');
  };

  const isFormComplete = () => {
    return (
      completedFields.cardNumber &&
      completedFields.cardExpiry &&
      completedFields.cardCvc &&
      completedFields.billingName &&
      completedFields.billingEmail &&
      completedFields.billingPostcode &&
      completedFields.coupon
    );
  };

  const handleSubmit = async event => {
    let subscriptionResponse = null;
    console.debug(`Price is set to :`);
    console.debug(price);

    event.preventDefault();
    setProcessing(true);
    setError(null);

    // If stripe isn't defined return early and
    // wait for the component to be properly initialized
    if (!stripe) {
      console.warning('Stripe not ready yet');
      return;
    }

    // Create the subscription object and collect the payment intent.
    // Only perform this step if no intent against this price has been
    // registered yet, and the subscription hasn't been created.
    if (!subscription && !updatePayment) {
      try {
        console.debug(
          `Creating subscription for ${price} and coupon: ${coupon}`
        );
        subscriptionResponse = await createSubscription(
          price.id,
          coupon?.coupon_id
        );
        setSubscription(subscriptionResponse);
        console.debug(`Subscription created:`);
        console.debug(subscriptionResponse);

        if (subscriptionResponse.clientSecret) {
          // When the subscription have a trial period, the steps bellow aren't required.
          // This is because no payment is required for a trial, and the secret,
          //or payment intent is only used to take a payment immediately.
          console.debug(
            `Saving client secret: ${subscriptionResponse.clientSecret}`
          );
          setClientSecret(subscriptionResponse.clientSecret);
        }
      } catch (e) {
        handleError(e);
        return;
      }
    }

    try {
      // IMPORTANT !
      // Only call the confirm payment method if there is a payment to be made.
      // Calling the confirmPayment if there is no payment upfront (if there is a trial or a 100% discount)
      // will NOT save the card details against the profile ! you need to call
      // capturePayment() instead;

      const paymentSource = await capturePaymentMethod();
      // If we use the form to just update the payment method, we won't have a price
      if (
        !price?.metadata?.trial &&
        !updatePayment &&
        subscriptionResponse.total !== 0
      ) {
        // Get the current client secret:
        // It can come from the subscription response if we just created one
        // Or the state if it's a re-submission
        const currentClientSecret =
          subscriptionResponse?.clientSecret || clientSecret;
        if (!(await confirmPayment(currentClientSecret, paymentSource))) {
          // Payment failed. Stop there.
          return;
        }
      }

      // Let's sync the user's subscription now the payment is complete:
      await syncSubscription();
      // Reload the user to ensure the state is updated with the subscription.
      const token = jwtDecode(localStorage.jwtToken);
      await getUser(token.id);

      setError(null);
      setSucceeded(true);

      if (updatePayment) {
        // Do a hard reload to make sure we fetch everything back from Stripe
        // This way, we ensure we show the user the most up to date values
        // and we can catch a silent error.
        console.debug(`Reloading the page to refresh data`);
        window.location.reload(false);
      } else {
        // Redirect to the intake page if it's a new payment.
        console.debug('Redirecting to the intake page');
        history.push('/questionnaire', {
          from: '/payment',
        });
      }
    } catch (e) {
      handleError(e);
    }
  };

  const handleError = error => {
    console.error(error);
    if (error?.message) {
      setError(error.message);
    } else {
      setError(
        'An error occurred while taking your payment, please contact suport@nutriology.io'
      );
    }
    setProcessing(false);
    setSucceeded(false);
  };

  // Make sure that if the customer change the price or apply a coupon,
  // we clear any payment intent we might have stored.
  // Otherwise we will charge the wrong amount !
  useEffect(() => {
    console.debug(`Parameters have changed, resetting the payment intent`);
    setClientSecret(null);
  }, [price, coupon]);

  const capturePaymentMethod = async () => {
    console.debug(`Capturing payment method`);
    // Capture the source and get a token to send to the backend API.
    // https://stripe.com/docs/api/sources/create
    const createSourceResponse = await stripe.createSource(
      elements.getElement(CardNumberElement),
      {
        type: 'card',
        currency: 'usd',
        usage: 'reusable',
        owner: {
          name: billingName,
          email: billingEmail,
          address: {
            postal_code: billingPostcode,
          },
        },
      }
    );

    console.debug(`Source created`);
    console.debug(createSourceResponse);

    if (createSourceResponse.error) {
      console.error(createSourceResponse);
      setError(`Payment failed ${createSourceResponse.error.message}`);
      setProcessing(false);
      return false;
    } else {
      console.debug(`Source sent, saving the card now...`);
      console.debug(createSourceResponse.source);

      await savePaymentInformation(createSourceResponse.source.id);
      return createSourceResponse.source;
    }
  };

  // eslint-disable-next-line
  const confirmPayment = async (paymentIntent, paymentSource) => {
    console.debug(paymentSource);
    try {
      // SCA : https://stripe.com/gb/payments/strong-customer-authentication
      // Use the parameter if defined, the state otherwise.
      if (!paymentIntent) {
        paymentIntent = clientSecret;
      }

      // There is a payment intent so we need to pay now
      console.debug(`Confirm payment using ${paymentIntent}`);
      const paymentResponse = await stripe.confirmCardPayment(paymentIntent, {
        payment_method: paymentSource.id,
        setup_future_usage: 'off_session',
        receipt_email: billingEmail,
      });

      if (paymentResponse.error) {
        console.error("Error while confirming today's payment");
        console.error(paymentResponse);
        setError(paymentResponse.error.message);
        setProcessing(false);
        setSucceeded(false);
        return false;
      } else {
        console.debug('paymentResponse: ', paymentResponse);
        setError(null);
        setProcessing(false);
        setSucceeded(true);
      }

      return true;
    } catch (error) {
      setError(
        'An error occurred while processing your payment, please contact support@nutriology.io'
      );
      console.error(
        'Error creating and setting default payment method:',
        error
      );
      return false;
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <BillingDetails
        onChange={handleChange}
        disabled={processing || succeeded}
        user={currentUser}
      />
      {/* No price means no payment taken so no coupon */}
      {price && (
        <CouponDetails
          onChange={handleChange}
          disabled={processing || succeeded}
          price={price}
        />
      )}

      <CardDetails onChange={handleChange} disabled={processing || succeeded} />

      <SubmitButton
        id="payment-submit"
        disabled={processing || !isFormComplete() || succeeded || !price}
        width="100%"
        buttonSize="large"
        buttonText={submitText}
        pink="true"
        handleClick={handleSubmit}
        startIcon={
          processing && (
            <CircularProgress
              size={24}
              color="inherit"
              data-test="loading-spinner"
            />
          )
        }
      ></SubmitButton>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <Messages messages={error} type="error" testName="payment-error" />
      )}
      {/*
      Show a success message upon completion.
      Do not state a payment success as we might not have taken any payment (trial, update)
      This message should be barely visible anyways as in any case as the popup will close.
      */}
      {succeeded && (
        <Messages
          messages="Please wait while we load your plans"
          type="success"
        />
      )}
    </form>
  );
};

const SubmitButton = styled(Button)`
  margin-top: 20px;
`;

function mapStateToProps(state) {
  const { currentUser } = state;
  const fields = ['_id', 'fullName', 'email'];
  const user = pick(currentUser.user, fields);
  return { currentUser: user };
}

export default connect(
  mapStateToProps,
  { getUser }
)(StripePaymentForm);
