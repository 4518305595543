import moment from 'moment';
import { formatDate } from '../../helpers/date';
import { apiCall } from '../../services/api/api';
import {
  SET_ACTIVE_FITNESS_PLAN,
  SET_SELECTED_FITNESS_PLAN,
  SET_TRACK,
} from '../actionTypes';
import { handleError, removeError } from './general';

const fitnessPlanPopulate = [
  {
    path: 'workouts.workout',
    populate: { path: 'exercises.exercise' },
  },
  {
    path: 'activities.activity',
  },
];

// Fitness Plan Actions
export function setActiveFitnessPlan(fitnessPlan) {
  return {
    type: SET_ACTIVE_FITNESS_PLAN,
    fitnessPlan,
  };
}

export function setSelectedFitnessPlan(fitnessPlan) {
  return {
    type: SET_SELECTED_FITNESS_PLAN,
    fitnessPlan,
  };
}

export function setTrack(track) {
  return {
    type: SET_TRACK,
    track,
  };
}

export function getActiveUserFitnessPlan(userId) {
  const date = new Date();
  const params = {
    query: {
      user: userId,
      startDate: formatDate(moment(date).startOf('isoWeek')),
      endDate: formatDate(moment(date).endOf('isoWeek')),
    },
    populate: fitnessPlanPopulate,
  };
  return dispatch => {
    return apiCall('get', `/fitnessplanuser`, params)
      .then(result => {
        const fitnessPlan = result[0];
        if (fitnessPlan) {
          // the user has a fitness plan already assigned for the current week
          dispatch(setActiveFitnessPlan(fitnessPlan));
          dispatch(removeError('fitnessplan'));
        } else {
          // the user does not have an assigned plan and needs a blank plan created
          dispatch(addUserFitnessPlan(userId, date));
        }
      })
      .catch(err => {
        handleError(dispatch, err, 'fitnessplan');
        throw err;
      });
  };
}

export function getUserFitnessPlanByDate(userId, date = new Date()) {
  const params = {
    query: {
      user: userId,
      startDate: formatDate(moment(date).startOf('isoWeek')),
      endDate: formatDate(moment(date).endOf('isoWeek')),
    },
    populate: fitnessPlanPopulate,
  };
  return dispatch => {
    return apiCall('get', `/fitnessplanuser`, params)
      .then(result => {
        const fitnessPlan = result[0];
        if (fitnessPlan) {
          // the user has a fitness plan already assigned for the given week
          dispatch(setSelectedFitnessPlan(fitnessPlan));
          dispatch(removeError('fitnessplan'));
        } else {
          // the user does not have an assigned plan and needs a blank plan created
          dispatch(addUserFitnessPlan(userId, date));
        }
      })
      .catch(err => {
        handleError(dispatch, err, 'fitnessplan');
        throw err;
      });
  };
}

export function getUserFitnessPlanById(id, params = {}) {
  params.populate = fitnessPlanPopulate;
  return dispatch => {
    return apiCall('get', `/fitnessplanuser/${id}`, params)
      .then(fitnessPlan => {
        dispatch(setSelectedFitnessPlan(fitnessPlan));
        dispatch(removeError('fitnessplan'));
      })
      .catch(err => {
        handleError(dispatch, err, 'fitnessplan');
        throw err;
      });
  };
}

export function addUserFitnessPlan(userId, date) {
  const payload = {
    startDate: formatDate(
      moment(date)
        .utc()
        .startOf('isoWeek')
    ),
    endDate: formatDate(
      moment(date)
        .utc()
        .endOf('isoWeek')
    ),
    planLength: 7,
    type: 'Weight Loss',
    level: 'Beginner',
    locationType: 'Home',
    gender: 'All',
  };
  return dispatch => {
    return apiCall('post', `/fitnessplanuser`, payload)
      .then(fitnessPlan => {
        dispatch(setSelectedFitnessPlan(fitnessPlan));
        if (
          fitnessPlan.startDate ===
          formatDate(moment(new Date()).startOf('isoWeek'))
        ) {
          dispatch(setActiveFitnessPlan(fitnessPlan));
        }
        dispatch(removeError('fitnessplan'));
      })
      .catch(err => {
        handleError(dispatch, err, 'fitnessplan');
        throw err;
      });
  };
}

export function addActivityToFitnessPlan(fitnessPlanId, params) {
  params.populate = fitnessPlanPopulate;
  return dispatch => {
    return apiCall(
      'put',
      `/fitnessplanuser/add_activity/${fitnessPlanId}`,
      params
    )
      .then(fitnessPlan => {
        dispatch(setSelectedFitnessPlan(fitnessPlan));
        if (
          fitnessPlan.startDate ===
          formatDate(moment(new Date()).startOf('isoWeek'))
        ) {
          dispatch(setActiveFitnessPlan(fitnessPlan));
        }
        dispatch(removeError('fitnessplan'));
      })
      .catch(err => {
        handleError(dispatch, err, 'fitnessplan');
        throw err;
      });
  };
}

export function addWorkoutToFitnessPlan(fitnessPlanId, params) {
  params.populate = fitnessPlanPopulate;
  return dispatch => {
    return apiCall(
      'put',
      `/fitnessplanuser/add_workout/${fitnessPlanId}`,
      params
    )
      .then(fitnessPlan => {
        dispatch(setSelectedFitnessPlan(fitnessPlan));
        if (
          fitnessPlan.startDate ===
          formatDate(moment(new Date()).startOf('isoWeek'))
        ) {
          dispatch(setActiveFitnessPlan(fitnessPlan));
        }
        dispatch(removeError('fitnessplan'));
      })
      .catch(err => {
        handleError(dispatch, err, 'fitnessplan');
        throw err;
      });
  };
}

export function updateUserFitnessPlan(fitnessPlanId, params) {
  params.populate = fitnessPlanPopulate;
  return dispatch => {
    return apiCall('put', `/fitnessplanuser/${fitnessPlanId}`, params)
      .then(fitnessPlan => {
        dispatch(setSelectedFitnessPlan(fitnessPlan));
        if (
          fitnessPlan.startDate ===
          formatDate(moment(new Date()).startOf('isoWeek'))
        ) {
          dispatch(setActiveFitnessPlan(fitnessPlan));
        }
        dispatch(removeError('fitnessplan'));
      })
      .catch(err => {
        handleError(dispatch, err, 'fitnessplan');
        throw err;
      });
  };
}

export function setFitnessPlanActivityProperty(
  fitnessPlan,
  activity,
  property,
  value,
  populate = false
) {
  console.info(
    `Setting activity property ${property} to ${value} for activity: `,
    activity
  );

  // Find the activity in the fitnessPlan
  fitnessPlan.activities.some(currentActivity => {
    if (currentActivity._id === activity._id) {
      switch (property) {
        case 'logged':
          currentActivity.logged = value ? new Date() : null;
          break;
        default:
          throw new Error(`Property ${property} not supported.`);
      }
      return true;
    }
    return false;
  });

  if (populate) {
    fitnessPlan.populate = fitnessPlanPopulate;
  }
  return updateUserFitnessPlan(fitnessPlan._id, fitnessPlan);
}

export function setFitnessPlanWorkoutProperty(
  fitnessPlan,
  workout,
  property,
  value,
  populate = false
) {
  console.log(
    `Setting workout property ${property} to ${value} for workout: `,
    workout
  );
  // Find the workout in the fitness plan
  fitnessPlan.workouts.some(currentWorkout => {
    if (currentWorkout._id === workout._id) {
      switch (property) {
        case 'allComplete':
          currentWorkout.allComplete = value ? new Date() : null;
          break;
        default:
          throw new Error(`Property ${property} not supported.`);
      }
      return true;
    }
    return false;
  });

  if (populate) {
    fitnessPlan.populate = fitnessPlanPopulate;
  }
  return updateUserFitnessPlan(fitnessPlan._id, fitnessPlan);
}

const trackPopulate = [
  {
    path: 'fitnessPlans.fitnessPlan',
  },
  {
    path: 'backTrack',
  },
  {
    path: 'remainTrack',
  },
  {
    path: 'nextTrack',
  },
  {
    path: 'nextLevel',
  },
];

export function getUserTrack(user, params = {}) {
  params.populate = trackPopulate;
  return dispatch => {
    return apiCall('get', `/track/${user.fitnessTrack}`, params)
      .then(track => {
        dispatch(setTrack(track));
        dispatch(removeError('fitnessplan'));
      })
      .catch(err => {
        handleError(dispatch, err, 'fitnessplan');
        throw err;
      });
  };
}

export function switchTrack(userId, trackId, params = {}) {
  params.populate = trackPopulate;
  return dispatch => {
    return apiCall('post', `/track/${trackId}/switch/${userId}`, params)
      .then(track => {
        dispatch(setTrack(track));
        dispatch(removeError('fitnessplan'));
      })
      .catch(err => {
        handleError(dispatch, err, 'fitnessplan');
        throw err;
      });
  };
}
