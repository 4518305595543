import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import WaterButton from '../elements/WaterButton';
import { useSelector } from 'react-redux';

const WaterTracker = ({ water, recordWater, ...props }) => {
  const gender = useSelector(state => state.currentUser.user.gender);
  const recommendedOz = gender === 'Male' ? 104 : 72;
  const [selected, setSelected] = useState('plus');

  return (
    <Container flexDirection="column" alignItems="center">
      <StatsText data-test="water-drank">
        {`${water?.amount} oz`}{' '}
        <Span data-test="water-recommended">/ {`${recommendedOz} oz`}</Span>
      </StatsText>
      <StyledProgress
        value={water?.amount}
        max={recommendedOz}
        color={colors.hliteone400}
        data-test="progress-bar"
      />
      <ButtonContainer>
        <WaterButton
          water={water}
          increment={selected === 'minus' ? -8 : 8}
          unit="oz"
          color={colors.hliteone400}
          recordWater={recordWater}
        />
        <WaterButton
          water={water}
          increment={selected === 'minus' ? -12 : 12}
          unit="oz"
          color={colors.hliteone400}
          recordWater={recordWater}
        />
      </ButtonContainer>
      <IncDecContainer alignSelf="flex-end">
        <CircleButton
          justify="center"
          alignItems="center"
          name="plus"
          selected={selected === 'plus'}
          onClick={() => setSelected('plus')}
          data-test="water-increment"
        >
          <Plus selected={selected === 'plus'}>+</Plus>
        </CircleButton>
        <CircleButton
          justify="center"
          alignItems="center"
          name="minus"
          selected={selected === 'minus'}
          onClick={() => setSelected('minus')}
          data-test="water-decrement"
        >
          <Minus selected={selected === 'minus'}>-</Minus>
        </CircleButton>
      </IncDecContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  gap: 14px;
`;

const StatsText = styled.h5`
  color: rgba(104, 104, 104, 0.54);
  font-size: 24px;
  font-weight: 600;
`;

const ButtonContainer = styled(FlexContainer)`
  gap: 12px;
`;

const Span = styled.span`
  color: #5694d4;
`;

const StyledProgress = styled.progress`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  border: none;
  border-radius: 3px;

  ::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 3px;
  }
  ::-webkit-progress-value {
    background-color: ${props => props.color};
    border-radius: 3px;
  }
  ::-moz-progress-bar {
    background-color: ${props => props.color};
    border-radius: 3px;
  }
`;

const IncDecContainer = styled(FlexContainer)``;

const Plus = styled.p`
  font-size: 20px;
  color: ${props => (props.selected ? '#fff' : '#000')};
  line-height: normal;
  font-weight: 500;
`;

const Minus = styled.p`
  font-size: 24px;
  color: ${props => (props.selected ? '#fff' : '#000')};
  line-height: 24px;
  height: 26px;
`;

const CircleButton = styled(FlexContainer)`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${props => (props.selected ? colors.hliteone400 : '#fff')};
  border: ${props => (props.selected ? 'none' : '1px solid #BDEFE3')};

  &:hover {
    cursor: pointer;
  }
`;

export default WaterTracker;
