import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import { Star, Loop } from '@material-ui/icons';

const TrackCard = ({
  track,
  handleClose,
  addTrack,
  setScreen,
  context,
  ...props
}) => {
  const trackType = context.trackType;
  const handleClick = e => {
    const trackId = track.id;
    const params = {
      track: trackId,
      context,
    };
    addTrack(params, trackType);
    handleClose();
  };

  return (
    <CardContainer
      flexDirection="column"
      alignItems="center"
      justify="center"
      onClick={handleClick}
      data-test="fitnessModal-trackCard"
    >
      <TitleContainer>
        <TrackTitle>{track.name}</TrackTitle>
      </TitleContainer>
      {!track.active && (
        <InactiveContainer flexDirection="column">
          <Info data-test="trackInfo-inactive">Inactive</Info>
        </InactiveContainer>
      )}
      {track.primary && (
        <IconContainer flexDirection="column">
          <Info data-test="trackInfo-primary">
            <Star />
          </Info>
        </IconContainer>
      )}
      {track.loop && (
        <IconContainer flexDirection="column">
          <Info data-test="trackInfo-loop">
            <Loop />
          </Info>
        </IconContainer>
      )}
      <InfoContainer flexDirection="column">
        <Info>{track.level}</Info>
        <Info>{`${track.locationType} / ${track.gender}`}</Info>
      </InfoContainer>
    </CardContainer>
  );
};

const CardContainer = styled(FlexContainer)`
  height: 124px;
  width: 192px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1.5px solid ${colors.primary200};
  border-radius: 4px;
  position: relative;

  :hover {
    cursor: pointer;
    opacity: 0.8;
  }

  :active {
    opacity: 0.7;
  }
`;

const TitleContainer = styled(FlexContainer)`
  padding: 0 16px;
  text-align: center;
`;

const TrackTitle = styled.h4`
  color: ${colors.primary800};
`;

const IconContainer = styled(FlexContainer)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const InactiveContainer = styled(FlexContainer)`
  position: absolute;
  top: 5px;
  left: 5px;
`;

const InfoContainer = styled(FlexContainer)`
  position: absolute;
  bottom: 5px;
  right: 5px;
`;

const Info = styled.p`
  color: ${colors.primary500};
  font-size: 13px;
  line-height: 8px;
  margin: 4px;
  text-align: right;
`;

export default TrackCard;
