import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import moment from 'moment';
import Dropdown from '../../elements/Dropdown';
import Button from '../../elements/Button';
import AlertStack from '../../elements/AlertStack';
import useFormValues from '../../hooks/useFormValues';

import UserSearch from './UserSearch';
import MealPlanToAssign from './MealPlanToAssign';
import FitnessPlanToAssign from './FitnessPlanToAssign';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  createUserMealPlan,
  deleteUserMealPlans,
  getUserMealPlans,
} from '../../services/api/mealplan';

import {
  createUserFitnessPlan,
  deleteUserFitnessPlans,
} from '../../services/api/fitnessPlan';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import useModal from '../../hooks/useModal';

const AssignPlan = ({ type, ...props }) => {
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();
  const [planToDuplicate, setPlanToDuplicate] = useState('');
  const [options, setOptions] = useState([]);
  const [assignedMealPlans, setAssignedMealPlans] = useState([]);
  const { open, launchModal, closeModal } = useModal(false);

  useEffect(() => {
    const weekOptions = [];
    for (let i = 0; i < 8; i++) {
      const week = moment()
        .utc()
        .add(i, 'week');
      const monday = week.utc().startOf('isoWeek');
      weekOptions.push({
        value: monday,
        label: `Week of ${monday.format('MMM Do YYYY')}`,
      });
    }

    setOptions([...weekOptions]);
  }, []);

  const initialValues = {
    week: {
      value: moment()
        .utc()
        .startOf('isoWeek'),
      label: `Week of ${moment()
        .utc()
        .startOf('isoWeek')
        .format('MMM Do YYYY')}`,
    },
  };

  const { values, setValues, handleDateChanged } = useFormValues(initialValues);

  const deleteExistingPlans = async () => {
    try {
      if (type === 'mealPlan') {
        await deleteUserMealPlans(user._id, values.week.value);
      }
      if (type === 'fitnessPlan') {
        await deleteUserFitnessPlans(user._id, values.week.value);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const assignUserPlan = () => {
    const params = {
      user: user._id,
      startDate: values.week.value,
      endDate: moment(values.week.value)
        .endOf('isoWeek')
        .startOf('day'),
    };

    setLoading(true);

    if (type === 'mealPlan') {
      createUserMealPlan(planToDuplicate, params)
        .then(result => {
          setStatus('success');
          setMessage(`New meal plan ${result.mealPlanAdmin.name} has been assigned to 
          user ${user.fullName} for ${values.week.label}`);
          setValues(initialValues);
          setPlanToDuplicate('');
          setUser(null);
        })
        .catch(err => {
          setStatus('error');
          if (err.errors && err.message) {
            setMessage(err.message);
          } else if (typeof err === 'string') {
            setMessage(err);
          } else {
            setMessage('Error encountered');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (type === 'fitnessPlan') {
      createUserFitnessPlan(planToDuplicate, params)
        .then(result => {
          setStatus('success');
          setMessage(`New fitness plan ${result.fitnessPlanAdmin.name} has been assigned to 
          user ${user.fullName} for ${values.week.label}`);
          setValues(initialValues);
          setPlanToDuplicate('');
          setUser(null);
        })
        .catch(err => {
          setStatus('error');
          if (err.errors && err.message) {
            setMessage(err.message);
          } else if (typeof err === 'string') {
            setMessage(err);
          } else {
            setMessage('Error encountered');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleClick = async () => {
    const params = {
      query: {
        user: user._id,
        startDate: values.week.value,
      },
    };
    const mealplans = await getUserMealPlans(params);
    if (mealplans.length > 0) {
      setAssignedMealPlans(mealplans);
      launchModal();
    } else {
      assignUserPlan();
    }
  };

  const confirmDelete = () => {
    closeModal();
    deleteExistingPlans();
    assignUserPlan();
  };

  return (
    <Container flexDirection="column">
      <UserSearch setUser={setUser} />
      {user && (
        <MealPlanContainer flexDirection="column">
          <Dropdown
            width="220px"
            options={options}
            label="Week To Assign Plan"
            value={{
              value: values.week.value,
              label: values.week.label,
            }}
            onChange={value =>
              handleDateChanged('week', value.value, value.label)
            }
          />
          {type === 'mealPlan' ? (
            <MealPlanToAssign setPlanToDuplicate={setPlanToDuplicate} />
          ) : type === 'fitnessPlan' ? (
            <FitnessPlanToAssign setPlanToDuplicate={setPlanToDuplicate} />
          ) : null}

          <Button
            type="submit"
            disabled={!values.week.value || !user || loading}
            buttonText={
              type === 'mealPlan'
                ? 'Assign User Meal Plan'
                : type === 'fitnessPlan'
                ? 'Assign User Fitness Plan'
                : 'Assign Plan'
            }
            buttonSize="large"
            handleClick={handleClick}
            startIcon={
              loading && <CircularProgress size={24} color="inherit" />
            }
          />
        </MealPlanContainer>
      )}
      {status === 'error' ? (
        <AlertStack
          messages={message}
          type="error"
          variant="filled"
          open={status === 'error'}
          handleClose={() => setStatus(null)}
          autoHideDuration={20000}
          data-test="assignment-message-error"
        />
      ) : status === 'success' ? (
        <AlertStack
          messages={message}
          type="success"
          variant="filled"
          open={status === 'success'}
          handleClose={() => setStatus('hidden')}
          autoHideDuration={20000}
          data-test="assignment-message-success"
        />
      ) : null}
      <ConfirmDeleteModal
        open={open}
        handleClose={closeModal}
        handleDelete={confirmDelete}
        mealPlans={assignedMealPlans}
        type={
          type === 'mealPlan'
            ? 'meal plan'
            : type === 'fitnessPlan'
            ? 'fitness plan'
            : 'plan'
        }
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

const MealPlanContainer = styled(FlexContainer)`
  > * {
    margin: 12px 0;
  }
`;

export default AssignPlan;
