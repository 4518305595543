import { apiCall } from './api';

export function getBillingInfo(params = {}) {
  return apiCall('get', `/billing`, params);
}

export function getCoupon(couponCode, priceId, params = {}) {
  return apiCall('get', `/coupon/${couponCode}/${priceId}`, params);
}

export function getInvoicesInfo(params = {}) {
  return apiCall('get', `/invoices`, params);
}

export function cancelSubscription(params = {}) {
  return apiCall('delete', `/subscription/cancel`, params);
}

export function reactivateSubscription(params = {}) {
  return apiCall('post', `/subscription/reactivate`, params);
}

export function setSubscription(id, params = {}) {
  return apiCall('post', `/subscription/set/${id}`, params);
}

export function getPrices(params = {}) {
  return apiCall('get', `/prices`, params);
}
