import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroller';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';

import RecipeCard from './RecipeCard';
import FoodCard from './FoodCard';
import MealPlanCard from './MealPlanCard';
import ExploreBannerCard from '../sharedModals/campaigns/ExploreBannerCard';

const CardsRow = ({
  title,
  type,
  items,
  wrap,
  setScreen,
  setItem,
  slider,
  isMobile,
  hasMore,
  ...props
}) => {
  const sliderRef = useRef(null);
  // For filtered recipe items, we need to know the length of the total number of recipes,
  // not just the filtered ones, so we know whether to load more. For other items, we can use
  // the length of the items.
  const itemsLength = props.unfilteredLength
    ? props.unfilteredLength
    : items.length;

  useEffect(() => {
    if (
      props?.unfilteredLength > 0 &&
      items.length !== props?.unfilteredLength
    ) {
      // Means we have filtered items
      if (hasMore && items.length === 0) {
        const page = props.unfilteredLength / props.pageSize;
        props.loadMoreItems(page);
      }
    }
  }, [items, itemsLength]);

  if (!itemsLength) {
    return null;
  }

  const handleClick = e => {
    setItem(items[e.target.dataset.idx]);
    setScreen(`${type} Page`);
  };

  const sliderPrev = () => {
    sliderRef.current.slickPrev();
  };

  const sliderNext = () => {
    sliderRef.current.slickNext();
  };

  const itemCards = items.map((item, i) => (
    <CardContainer key={i} wrap={wrap} justify="center" isMobile={isMobile}>
      {type === 'Recipe' ? (
        <RecipeCard
          setRecipe={setItem}
          setScreen={setScreen}
          recipe={item}
          onClick={handleClick}
          sliderCard={true}
          isMobile={isMobile}
        />
      ) : type === 'Food' ? (
        <FoodCard
          setFood={setItem}
          setScreen={setScreen}
          food={item}
          onClick={handleClick}
          isMobile={isMobile}
        />
      ) : type === 'Meal Plan' ? (
        <MealPlanCard
          setMealPlan={setItem}
          setScreen={setScreen}
          mealPlan={item}
          onClick={handleClick}
        />
      ) : type === 'Explore Banner' ? (
        <ExploreBannerCard exploreBanner={item} {...props} />
      ) : null}
    </CardContainer>
  ));

  const loader = (
    <Loader key={0} justify="center">
      <CircularProgress size={24} color="inherit" />
    </Loader>
  );

  const sliderSettings = {
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    swipe: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <Container flexDirection="column" wrap={wrap}>
      {title && <SectionTitle>{title}</SectionTitle>}
      {!slider && props.loadMoreItems && (
        <InfiniteScroll
          loadMore={props.loadMoreItems}
          hasMore={hasMore}
          loader={loader}
          useWindow={false}
          getScrollParent={() => props.scrollParent.current}
          initialLoad={false}
        >
          <RowContainer
            alignItems={!isMobile && 'center'}
            wrap={wrap || 'no-wrap'}
            alignContent={!isMobile && 'space-between'}
            flexDirection={isMobile && 'column'}
            isMobile={isMobile}
            data-test={`mealModal-${title}-cardRow`}
          >
            {itemCards}
          </RowContainer>
        </InfiniteScroll>
      )}
      {!slider && !props.loadMoreItems && (
        <RowContainer
          alignItems={!isMobile && 'center'}
          wrap={wrap || 'no-wrap'}
          alignContent={!isMobile && 'space-between'}
          flexDirection={isMobile && 'column'}
          data-test={`mealModal-${title}-cardRow`}
          isMobile={isMobile}
        >
          {itemCards}
        </RowContainer>
      )}
      {slider && (
        <SliderContainer
          flexDirection="row"
          alignItems="center"
          data-test={`mealModal-${title}-cardRow`}
        >
          <LeftButtonContainer>
            <IconButton onClick={sliderPrev}>
              <NavigateBeforeIcon />
            </IconButton>
          </LeftButtonContainer>
          <StyledSlider ref={sliderRef} {...sliderSettings}>
            {itemCards}
          </StyledSlider>
          <RightButtonContainer>
            <IconButton onClick={sliderNext}>
              <NavigateNextIcon />
            </IconButton>
          </RightButtonContainer>
        </SliderContainer>
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  > :first-child {
    margin-bottom: 24px;
  }
`;

const Loader = styled(FlexContainer)`
  color: ${colors.secondary500};
`;

const RowContainer = styled(FlexContainer)`
  > :not(:last-child) {
    margin-right: ${props => !props.isMobile && '16px'};
  }

  overflow-x: auto;
`;

const SliderContainer = styled(FlexContainer)`
  position: relative;
  justify-content: flex-start;
`;

const LeftButtonContainer = styled(FlexContainer)`
  position: absolute;
  left: -50px;
`;

const RightButtonContainer = styled(FlexContainer)`
  position: absolute;
  right: 50px;
`;

const CardContainer = styled(FlexContainer)`
  margin-bottom: ${props =>
    props.isMobile ? '15px' : props.wrap ? '24px' : '0'};
  display: flex !important; /* necessary due to slider css */
`;

const SectionTitle = styled.h6`
  font-weight: 400;
  color: ${colors.secondary500};
`;

const StyledSlider = styled(Slider)`
  width: 90%;
`;

export default CardsRow;
