import { apiCall } from './api';
import moment from 'moment';

const defaultStartDate = moment()
  .startOf('isoWeek')
  .format('YYYY-MM-DD');

const defaultEndDate = moment()
  .add(1, 'week')
  .endOf('isoWeek')
  .format('YYYY-MM-DD');

export function getShoppingLists(params = {}) {
  params.startDate = params.startDate
    ? params.startDate.format('YYYY-MM-DD')
    : defaultStartDate;
  params.endDate = params.endDate
    ? params.endDate.format('YYYY-MM-DD')
    : defaultEndDate;
  return apiCall('get', `/shoppingList`, params);
}

export function addShoppingList(params = {}) {
  return apiCall('post', `/shoppingList`, params);
}
