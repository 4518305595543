import React, { useState, useEffect, useRef } from 'react';
import { cloneDeep, pick, without } from 'lodash';
import { connect } from 'react-redux';
import { isMobile } from '../../helpers/utils';

import useFormValues from '../../hooks/useFormValues';
import useFormSubmit from '../../hooks/useFormSubmit';

import { addUserRecipe } from '../../services/api/recipe';
import { convertNutrients } from '../../helpers/units';

import CustomRecipeWeb from './CustomRecipeWeb';
import CustomRecipeMobile from './CustomRecipeMobile';
import { trackEvent } from '../../integrations/analytics';

const CustomRecipe = ({ setRecipe, setScreen, userId, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();
  const [message, setMessage] = useState('');
  const [imageUrl, setImageUrl] = useState();

  const initialValues = {
    nutrients: {},
    yield: 1,
  };
  const { values, setValues, handleChange, handleValueChanged } = useFormValues(
    initialValues
  );

  // Recipe Image
  const inputFile = useRef(null);

  const handleChangeImage = e => {
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    handleChange(e);
  };

  const handleImage = () => {
    inputFile.current.click();
  };

  // Manage quantity and units for ingredients
  const handleQuantityValueChange = (name, value) => {
    //hacky, but we don't have access to the event here
    const i = name.split('-')[1];
    const n = 'quantity';
    const updatedFoods = [...foods];
    updatedFoods[i][n] = parseFloat(value);
    setFoods(updatedFoods);
  };

  const handleUnitsChanged = (name, idx, value) => {
    const originalUnits = foods[idx]['measureUnit'];
    const updatedFoods = [...foods];
    updatedFoods[idx]['measureUnit'] = value;

    const updatedNutrients = convertNutrients(
      updatedFoods[idx]['nutrientsPerUnit'],
      updatedFoods[idx]['gramWeightPerUnit'],
      value,
      originalUnits
    );
    updatedFoods[idx]['nutrientsPerUnit'] = updatedNutrients;
    setFoods(updatedFoods);
  };

  const handleDeleteFood = item => {
    setFoods(without(foods, item));
  };

  // Ingredients
  const [ingredientsValue, setIngredientsValue] = useState([]);
  const [foods, setFoods] = useState([]);

  useEffect(() => {
    const foodsCopy = [...foods];
    ingredientsValue.forEach(ingredient => {
      foodsCopy.push({
        quantity: ingredient.amount || 1,
        measureUnit: ingredient.measureUnit,
        food: ingredient._id,
        name: ingredient.name,
        verboseName: ingredient.verboseName,
        gramWeight: ingredient.gramWeight,
        gramWeightPerUnit: ingredient.gramWeightPerUnit,
        nutrientsPerUnit: {
          calories: ingredient.nutrientsPerUnit.calories,
          proteins: ingredient.nutrientsPerUnit.proteins,
          carbohydrates: ingredient.nutrientsPerUnit.carbohydrates,
          fiber: ingredient.nutrientsPerUnit.fiber,
          totalSugar: ingredient.nutrientsPerUnit.totalSugar,
          addedSugar: ingredient.nutrientsPerUnit.addedSugar,
          fat: ingredient.nutrientsPerUnit.fat,
          saturatedFat: ingredient.nutrientsPerUnit.saturatedFat,
          transFat: ingredient.nutrientsPerUnit.transFat,
          cholesterol_mg: ingredient.nutrientsPerUnit.cholesterol_mg,
          vitaminD_mcg: ingredient.nutrientsPerUnit.vitaminD_mcg,
          calcium_mg: ingredient.nutrientsPerUnit.calcium_mg,
          potassium_mg: ingredient.nutrientsPerUnit.potassium_mg,
          sodium_mg: ingredient.nutrientsPerUnit.sodium_mg,
        },
      });
    });
    setFoods(foodsCopy);
  }, [ingredientsValue]);

  // Nutrients
  const [calculatedNutrients, setCalculatedNutrients] = useState({});

  useEffect(() => {
    const calculated = {};
    foods.forEach(food => {
      const nutrients = food.nutrientsPerUnit;
      const quantity = food.quantity / (values.yield || 1);
      for (const nutrient in nutrients) {
        calculated[nutrient] = calculated[nutrient]
          ? calculated[nutrient] + quantity * nutrients[nutrient]
          : quantity * nutrients[nutrient];
      }
    });
    setCalculatedNutrients(calculated);
  }, [foods, values.nutrients, values.yield]);

  // Instructions
  const instruction = { num: 1, step: '' };
  const [instructionsValue, setInstructionsValue] = useState([
    { ...instruction },
  ]);

  const addInstruction = () => {
    setInstructionsValue([
      ...instructionsValue,
      {
        num: instructionsValue[instructionsValue.length - 1].num + 1,
        step: '',
      },
    ]);
  };

  const removeInstruction = instruction => {
    setInstructionsValue(without(instructionsValue, instruction));
  };

  const handleInstructionChange = e => {
    const updatedInstructions = [...instructionsValue];
    updatedInstructions[e.target.dataset.idx][e.target.dataset.name] =
      e.target.value;
    setInstructionsValue(updatedInstructions);
  };

  const submitData = () => {
    trackEvent('Custom Recipe Created');
    let payload = cloneDeep(values);
    for (const nutrient in calculatedNutrients) {
      payload.nutrients[nutrient] = calculatedNutrients[nutrient];
    }
    payload.user = userId;
    payload.cookingInstructions = instructionsValue.map(
      (instruction, index) => {
        return {
          num: index + 1,
          step: instruction.step,
        };
      }
    );
    payload.foods = foods;
    payload.foods.forEach((food, i) => {
      payload.foods[i].quantity = payload.foods[i].quantity / payload.yield;
      payload.foods[i] = pick(food, [
        'quantity',
        'measureUnit',
        'food',
        'name',
      ]);
    });

    setLoading(true);

    addUserRecipe(payload) // create a new custom RecipeUser
      .then(result => {
        result ? setStatus('success') : setStatus('error');
        setMessage(`Recipe ${result._id} was created successfully!`);
        setRecipe(result);
        setLoading(false);
        setScreen('Recipe Page');
      })
      .catch(err => {
        console.error(err);
        if (payload.imageUrl) {
          values.imageUrl = payload.imageUrl;
        }
        setStatus('error');
        if (err.errors && err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
        setLoading(false);
      });
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin'
  );

  return isMobile() ? (
    <CustomRecipeMobile
      values={values}
      foods={foods}
      handleChange={handleChange}
      handleValueChanged={handleValueChanged}
      handleSubmit={handleSubmit}
      handleInstructionChange={handleInstructionChange}
      handleUnitsChanged={handleUnitsChanged}
      handleQuantityValueChange={handleQuantityValueChange}
      handleDeleteFood={handleDeleteFood}
      addInstruction={addInstruction}
      removeInstruction={removeInstruction}
      instructionsValue={instructionsValue}
      ingredientsValue={ingredientsValue}
      setIngredientsValue={setIngredientsValue}
      calculatedNutrients={calculatedNutrients}
      handleImage={handleImage}
      handleChangeImage={handleChangeImage}
      inputFile={inputFile}
      imageUrl={imageUrl}
      status={status}
      message={message}
      loading={loading}
    />
  ) : (
    <CustomRecipeWeb
      values={values}
      foods={foods}
      handleChange={handleChange}
      handleValueChanged={handleValueChanged}
      handleSubmit={handleSubmit}
      handleInstructionChange={handleInstructionChange}
      handleUnitsChanged={handleUnitsChanged}
      handleQuantityValueChange={handleQuantityValueChange}
      handleDeleteFood={handleDeleteFood}
      addInstruction={addInstruction}
      removeInstruction={removeInstruction}
      instructionsValue={instructionsValue}
      ingredientsValue={ingredientsValue}
      setIngredientsValue={setIngredientsValue}
      calculatedNutrients={calculatedNutrients}
      handleImage={handleImage}
      handleChangeImage={handleChangeImage}
      inputFile={inputFile}
      imageUrl={imageUrl}
      status={status}
      message={message}
      loading={loading}
    />
  );
};

function mapStateToProps(state) {
  const { currentUser } = state;

  return { userId: currentUser.user.id };
}

export default connect(mapStateToProps)(CustomRecipe);
