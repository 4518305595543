import React, { useState, useEffect } from 'react';
import { apiCall } from '../services/api/api';

const OAuthPage = props => {
  const [oAuthResponse, setOAuthResponse] = useState({
    loading: false,
    error: null,
    data: null,
  });

  useEffect(() => {
    const performOAuth = async () => {
      // Extract query parameters from the URL
      const queryParams = new URLSearchParams(window.location.search);
      const client_id = queryParams.get('client_id');
      const redirect_uri = queryParams.get('redirect_uri');
      const response_type = queryParams.get('response_type');
      const scope = queryParams.get('scope');

      const params = { client_id, response_type, scope };
      console.debug(`OAuth Parameters:`, params);
      try {
        setOAuthResponse(prevState => ({ ...prevState, loading: true }));
        const response = await apiCall('get', '/oauth/code', params);

        if (response.code) {
          const redirectUri = `${redirect_uri}?code=${encodeURIComponent(
            response.code
          )}`;
          console.debug(`SSO successful, redirecting to ${redirectUri}`);
          window.location.href = redirectUri;
        } else {
          console.error('OAuth code not found in the response');
          setOAuthResponse({
            loading: false,
            error: 'OAuth code not found in the response',
            data: response,
          });
        }
      } catch (error) {
        console.error(error);
        setOAuthResponse({
          loading: false,
          error: error.toString(),
          data: null,
        });
      }
    };
    performOAuth();
  }, []);

  return (
    <>
      {oAuthResponse.loading && <p>Loading...</p>}
      {oAuthResponse.error && <p>Error: {oAuthResponse.error}</p>}
      {oAuthResponse.data && <p>OAuth successful!</p>}
    </>
  );
};

export default OAuthPage;
