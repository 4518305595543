import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';

const FitnessPlanIcon = props => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
    <polyline
      points="0.1 8.68 4.17 8.68 6.36 16.77 7.92 1.11 10.46 12.36 12.07 4.75 13.49 8.72 17.9 8.72"
      strokeLinejoin="round"
    />
  </Icon>
);

const Icon = styled.svg`
  width: 18px;
  height: 18px;

  & polyline {
    fill: none;
    stroke: ${colors.primary500};
  }
`;

export default FitnessPlanIcon;
