import { apiCall } from './api';

export function createSubscription(priceId, couponId = null, params = {}) {
  params.subscriptionId = priceId;
  params.couponId = couponId;
  return apiCall('post', `/payment/create-subscription`, params);
}

export function savePaymentInformation(sourceID, params = {}) {
  params.sourceID = sourceID;
  return apiCall('post', `/payment/save`, params);
}

export function syncSubscription(params = {}) {
  return apiCall('post', `/subscription/sync`, params);
}
