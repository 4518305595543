import React from 'react';
import styled from 'styled-components';
import { colors, screenSize } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import RadioInput from '../elements/RadioInput';

const ValueHighlight = ({ highlight }) => (
  <Container>
    <Text length={highlight?.length}>{highlight}</Text>
  </Container>
);

const PaymentOption = ({ selected, price, id, selectPrice, freeTrial }) => {
  const { highlight, tagline } = price.metadata;

  return (
    <PlanContainer
      id={price.id}
      selected={selected}
      onClick={() => {
        selectPrice(price);
      }}
      data-test="price-plan"
    >
      <LeftColumn flexDirection="column" alignItems="center">
        <RadioInput
          onChange={() => {
            selectPrice(price);
          }}
          selected={selected}
          value={id}
          name="pricePlan"
          checked={selected}
          hideValue
        />
      </LeftColumn>
      <RightColumn flexDirection="column">
        <PlanHeader alignItems="center">
          <Title>{price.metadata.title}</Title>
          {freeTrial && <FreeTrial>{freeTrial}</FreeTrial>}
        </PlanHeader>
        <PriceContainer alignItems="center">
          {price.metadata.strikethrough && (
            <StrikethroughPrice>
              {price.metadata.strikethrough}
            </StrikethroughPrice>
          )}
          <Price>{price.metadata.description_line_1}</Price>
          {tagline && <Tagline>{tagline}</Tagline>}
        </PriceContainer>
        <HR />
        <BillingFrequency>{price.metadata.description_line_2}</BillingFrequency>
      </RightColumn>
      {highlight && <ValueHighlight highlight={highlight} />}
    </PlanContainer>
  );
};

const PlanContainer = styled(FlexContainer)`
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  border: 1px solid
    ${props => (props.selected ? colors.secondary600 : colors.primary200)};
  border-radius: 8px;
  background-color: #ffffff;
  height: 175px;
  padding: 20px;
  width: 540px;
  gap: 24px;

  &:hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  }

  &:focus {
    border: 1px solid ${colors.secondary600};
  }

  @media (max-width: ${screenSize.laptopS}) {
    width: 100%;
  }
`;

const LeftColumn = styled(FlexContainer)`
  justify-content: center;
`;

const RightColumn = styled(FlexContainer)`
  flex-grow: 1;
`;

const PlanHeader = styled(FlexContainer)`
  > * {
    margin-right: 12px;
  }
`;

const PriceContainer = styled(FlexContainer)`
  margin-top: 14px;
  gap: 12px;
`;

const Price = styled.p`
  color: ${colors.secondary600};
  font-size: 20px;
  font-weight: 700;
`;

const StrikethroughPrice = styled.p`
  color: ${colors.hlitetwo400};
  font-size: 20px;
  font-weight: 700;
  background: linear-gradient(
    to left top,
    transparent 47.75%,
    currentColor 49.5%,
    currentColor 50.5%,
    transparent 52.25%
  );
`;

const Title = styled.h4`
  color: ${colors.primary900};
  font-weight: bold;
  font-size: 18px;
`;

const FreeTrial = styled.h4`
  margin: 0;
  color: ${colors.secondary500};
`;

const HR = styled.hr`
  margin: 20px 0;
  color: ${colors.primary300};
  border: 0.5px solid ${colors.primary300};
`;

const BillingFrequency = styled.p`
  color: ${colors.primary600};
  font-size: 13px;
  line-height: 15px;
`;

const Tagline = styled(FlexContainer)`
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  color: ${colors.hlitetwo400};
`;

const Container = styled.div`
  height: 80px;
  width: 80px;
  position: absolute;
  top: 0;
  right: 0;
  background-image: url(${process.env.PUBLIC_URL}/PlanHighlight.svg);
`;

const Text = styled.p`
  color: #fff;
  transform: rotate(45deg);
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  width: ${props => (props?.length > 10 ? '44px' : '34px')};
  position: absolute;
  top: 11px;
  right: ${props => (props?.length > 10 ? '5px' : '7px')};
  line-height: 14px;
  overflow-wrap: break-word;
`;

export default PaymentOption;
