import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextInput from '../elements/TextInput';
import { colors } from '../styleConstants';
import useFormValues from '../hooks/useFormValues';
import Button from '../elements/Button';
import FlexContainer from '../elements/FlexContainer';
import { getCoupon } from '../services/api/billing';

const CouponField = ({
  handleCouponAdded,
  handleCouponChange,
  disabled,
  price,
  ...props
}) => {
  const { values, setValues, handleChange } = useFormValues();
  const [placeholder, setPlaceholder] = useState('Coupon');
  const [processing, setProcessing] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const removeCoupon = async () => {
    setCoupon(null);
    setValues({ couponCode: '' });
    setPlaceholder('Coupon');
    setProcessing(false);
  };

  const addCoupon = async () => {
    setProcessing(true);
    setErrorMessage('');
    try {
      const coupon = await getCoupon(values.couponCode, price.id);
      setCoupon(coupon);
      handleCouponAdded(coupon);
      console.debug(coupon);
    } catch (err) {
      setCoupon(null);
      // Err is the error coming from our API, err.error is coming from stripe.
      const errMessage = err.message ? err.message : err.error.message;
      console.error(errMessage);
      if (errMessage) {
        setErrorMessage(errMessage);
      } else {
        setErrorMessage(
          'An unexpected error occurred, please contact support@nutriology.io'
        );
      }
    } finally {
      setProcessing(false);
    }
  };

  const handleSubmit = async () => {
    // If we already have a coupon applied, just remove it
    if (coupon) {
      removeCoupon();
    } else {
      addCoupon();
    }
  };

  const handleBlur = async event => {
    // If the user forgot to apply the coupon, let's apply it for him.
    if (coupon) {
      return;
    }
    if (values.couponCode === undefined || values.couponCode === '') {
      setPlaceholder('Coupon');
    } else {
      addCoupon();
    }
  };

  useEffect(() => {
    // Fire the coupon code change so other component can listen and enable/disable the global form
    // Depending on the completeness of the state.
    // If the coupon code is undefined then the component is not ready
    if (values.couponCode === undefined) {
      return;
    }
    handleCouponChange(values.couponCode);
    if (values.couponCode === '') {
      setErrorMessage('');
    }
  }, [values.couponCode]);

  // If the price changes we need to ensure the coupon is still valid for the new subscription.
  useEffect(() => {
    console.debug(
      `Price has changed, revalidating the coupon against new price.`
    );
    if (values.couponCode !== '' && values.couponCode !== undefined) {
      addCoupon();
    }
  }, [price]);

  return (
    <>
      <CouponContainer>
        <CouponInput
          id="couponCode"
          name="couponCode"
          onChange={handleChange}
          value={values.couponCode || ''}
          placeholder={placeholder}
          onFocus={() => setPlaceholder('')}
          onBlur={handleBlur}
          border="none"
          disabled={disabled || coupon}
          style={{
            padding: '0',
          }}
          coupon={coupon}
        />
        {coupon && (
          <CouponMessage>{coupon.percent_off}%&nbsp;off&nbsp;!</CouponMessage>
        )}
      </CouponContainer>

      {errorMessage !== '' && <ErrorMessage>{errorMessage}</ErrorMessage>}

      {values.couponCode && values.couponCode !== '' && (
        <Button
          id={coupon ? 'remove-coupon' : 'apply-coupon'}
          data-test={coupon ? 'remove-coupon' : 'apply-coupon'}
          disabled={processing}
          width="100%"
          buttonSize="small"
          buttonText={coupon ? 'Remove my coupon' : 'Apply my coupon'}
          pink="true"
          handleClick={handleSubmit}
        ></Button>
      )}
    </>
  );
};

const ErrorMessage = styled.p`
  color: red;
`;

const CouponMessage = styled(FlexContainer)`
  padding: 13px;
  color: green;
  font-weight: bold;
  // Replicate the input disable look next to it
  background-color: ${colors.primary050};
`;

const CouponInput = styled(TextInput)`
  flex-shrink: 2;
  ${props => !props.coupon} {
    color: green;
  }
`;

const CouponContainer = styled(FlexContainer)``;

export default CouponField;
