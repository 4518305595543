import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import { trackTypes } from './defaults';
import {
  getTracks,
  getTrack,
  editTrack,
  swapUserTracks,
} from '../../services/api/track';
import EmptySlot from './EmptySlot';
import PlanSlot from './PlanSlot';
import AlertStack from '../../elements/AlertStack';
import { assignProgression } from './helpers';

const TrackMap = props => {
  const [tracks, setTracks] = useState([]);
  const [status, setStatus] = useState();
  const [message, setMessage] = useState('');

  async function loadTracks() {
    const params = {
      query: {
        $or: [{ primary: true }, { loop: true }],
      },
    };
    const tempTracks = await getTracks(params);
    setTracks(tempTracks);
  }

  async function addTrack(params) {
    try {
      const { trackType, primary, loop } = params?.context;
      const incomingTrack = await getTrack(params.track);
      // Prevent adding an invalid track to the slot
      if (
        incomingTrack.level === trackType.level &&
        incomingTrack.locationType === trackType.locationType &&
        incomingTrack.gender === trackType.gender
      ) {
        await assignProgression(incomingTrack._id, loop, primary, trackType);

        await loadTracks();
        setStatus('success');
        setMessage('');
      } else {
        setStatus('error');
        setMessage(
          'The level, location type, or gender on the track you selected does not match the current slot.'
        );
      }
    } catch (err) {
      console.error(err);
      setStatus('error');
      setMessage(`There was an error while adding the track: ${err.message}`);
    }
  }

  async function swapTrack(params) {
    try {
      const { trackType, currentTrack, primary, loop } = params?.context;
      const incomingTrack = await getTrack(params.track);
      // Prevent adding an invalid track to the slot
      if (
        incomingTrack.level === trackType.level &&
        incomingTrack.locationType === trackType.locationType &&
        incomingTrack.gender === trackType.gender
      ) {
        // If the track being added is already on the map, need to swap places
        if (incomingTrack.primary === true || incomingTrack.loop === true) {
          await assignProgression(
            currentTrack._id,
            incomingTrack?.loop,
            incomingTrack?.primary,
            trackType
          );
          // Otherwise, need to deactivate the track being swapped
        } else {
          await editTrack(currentTrack._id, {
            primary: false,
            loop: false,
            active: false,
          });
        }
        // In either case, update the incoming track
        await assignProgression(incomingTrack._id, loop, primary, trackType);
        // Find all users who had the currentTrack assigned and replace it with the incomingTrack
        swapUserTracks(currentTrack._id, incomingTrack._id, {
          timezoneOffset: new Date().getTimezoneOffset(),
        })
          .then(result => {
            console.log(result);
          })
          .catch(err => {
            console.error(err);
            setStatus('error');
            setMessage('There was an error while updating user fitness plans.');
          });

        await loadTracks();
        setStatus('success');
        setMessage('');
      } else {
        setStatus('error');
        setMessage(
          'The level, location type, or gender on the track you selected does not match the current slot.'
        );
      }
    } catch (err) {
      console.error(err);
      setStatus('error');
      setMessage(`There was an error while swapping the track: ${err.message}`);
    }
  }

  useEffect(() => {
    loadTracks();
  }, []);

  const categories = trackTypes.map((trackType, i) => {
    const primaryTrack = tracks.filter(track => {
      return (
        track.primary === true &&
        track.level === trackType.level &&
        track.locationType === trackType.locationType &&
        track.gender === trackType.gender
      );
    })[0]; // should only be one matching, but ensure only return one
    const loopTrack = tracks.filter(track => {
      return (
        track.loop === true &&
        track.level === trackType.level &&
        track.locationType === trackType.locationType &&
        track.gender === trackType.gender
      );
    })[0]; // should only be one matching, but ensure only return one

    return (
      <Category
        key={i}
        data-test={`trackCat-${trackType.level}-${trackType.locationType}-${trackType.gender}`}
      >
        <Header>{`${trackType.level} - ${trackType.locationType} - ${trackType.gender}`}</Header>
        <SubHead>Primary (New User)</SubHead>
        <TrackRow data-test="track-row-primary">
          {primaryTrack ? (
            <PlanSlot
              track={primaryTrack}
              primary={true}
              loop={false}
              trackType={trackType}
              swapTrack={swapTrack}
            />
          ) : (
            <EmptySlot
              primary={true}
              loop={false}
              trackType={trackType}
              addTrack={addTrack}
            />
          )}
        </TrackRow>
        <SubHead>Loop</SubHead>
        <TrackRow data-test="track-row-loop">
          {loopTrack ? (
            <PlanSlot
              track={loopTrack}
              primary={false}
              loop={true}
              trackType={trackType}
              swapTrack={swapTrack}
            />
          ) : (
            <EmptySlot
              primary={false}
              loop={true}
              trackType={trackType}
              addTrack={addTrack}
            />
          )}
        </TrackRow>
      </Category>
    );
  });

  return (
    <>
      {status === 'error' && (
        <AlertStack
          id="track-error"
          messages={message}
          type="error"
          variant="filled"
          open={status === 'error'}
          handleClose={() => setStatus(null)}
          autoHideDuration={20000}
          data-test="track-message-error"
        />
      )}
      {categories}
    </>
  );
};

const Category = styled(FlexContainer)`
  flex-direction: column;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid black;
`;

const Header = styled.h3`
  color: ${colors.primary800};
  margin-bottom: 16px;
`;

const SubHead = styled.h4`
  color: ${colors.primary500};
`;

const TrackRow = styled(FlexContainer)`
  padding: 12px 0;

  > * {
    margin-right: 24px;
  }
`;

export default TrackMap;
