import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';
import useFormValues from '../../hooks/useFormValues';
import useToggles from '../../hooks/useToggles';
import useModal from '../../hooks/useModal';
import ModalContainer from '../../sharedModals/ModalContainer';

import { getFood } from '../../services/api/food';
import FoodForm from './FoodForm';

const ViewFood = props => {
  const initialToggleValues = {
    categories: {},
  };

  const { toggleValues, handleToggle, setToggleValues } = useToggles(
    initialToggleValues
  );

  const initialValues = {
    name: '',
    verboseName: '',
    type: '',
    variety: '',
    amount: 1,
    measureUnit: '',
    gramWeight: 0,
    calories: 0,
    proteins: 0,
    carbohydrates: 0,
    fiber: 0,
    totalSugar: 0,
    addedSugar: 0,
    fat: 0,
    saturatedFat: 0,
    transFat: 0,
    cholesterol_mg: 0,
    vitaminD_mcg: 0,
    calcium_mg: 0,
    potassium_mg: 0,
    sodium_mg: 0,
    kind: 'Admin',
  };

  const { values, setValues, handleChange, handleValueChanged } = useFormValues(
    initialValues
  );

  const { open, launchModal, closeModal } = useModal(false);
  const [food, setFood] = useState({});
  const [eshaId, setEshaId] = useState('');

  async function loadFood(params) {
    try {
      const foodId = params.food;
      const result = await getFood(foodId);
      if (result) {
        setFood(result);
      } else {
        throw new Error('Food could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded food
  useEffect(() => {
    if (!isEmpty(food)) {
      const categories = {};
      const categoriesTmp = food.categories;

      categoriesTmp.forEach(category => (categories[category] = true));
      setToggleValues({ categories });
      if (food.esha) {
        setEshaId(food.esha);
      }

      setValues({
        name: food.name,
        verboseName: food.verboseName,
        type: food.type,
        variety: food.variety,
        amount: food.amount,
        measureUnit: food.measureUnit,
        gramWeight: food.gramWeight,
        calories: food.calories,
        proteins: food.proteins,
        carbohydrates: food.carbohydrates,
        fiber: food.fiber,
        totalSugar: food.totalSugar,
        addedSugar: food.addedSugar,
        fat: food.fat,
        saturatedFat: food.saturatedFat,
        transFat: food.transFat,
        cholesterol_mg: food.cholesterol_mg,
        vitaminD_mcg: food.vitaminD_mcg,
        calcium_mg: food.calcium_mg,
        potassium_mg: food.potassium_mg,
        sodium_mg: food.sodium_mg,
      });
    }
  }, [food]);

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Food"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
        data-test="food-find"
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          hide: 'recipe',
        }}
        initialScreen="Meal Search"
        handleClose={closeModal}
        addFood={loadFood}
        {...props}
      />
      <FoodForm
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        viewOnly={true}
        formType="view"
        eshaId={eshaId}
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default ViewFood;
