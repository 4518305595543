import React, { useState } from 'react';

import { cloneDeep, keys, pickBy } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useFormSubmit from '../../hooks/useFormSubmit';
import useToggles from '../../hooks/useToggles';

import ExerciseForm from './ExerciseForm';
import { addExercise } from '../../services/api/exercise';

const AddExercise = props => {
  const initialValues = {
    repUnits: 'Reps',
  };
  const initialToggleValues = {
    bodyWeight: false,
    active: false,
    level: {},
    location: {},
    specialtyFocus: {},
  };
  const { values, setValues, handleChange, handleValueChanged } = useFormValues(
    initialValues
  );

  const { toggleValues, handleToggle, setToggleValues } = useToggles(
    initialToggleValues
  );

  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const [imageUrl, setImageUrl] = useState();
  const [thumbnailUrl, setThumbnailUrl] = useState();

  const instruction = { num: '', step: '' };
  const [instructionsValue, setInstructionsValue] = useState([
    { ...instruction },
  ]);

  const submitData = () => {
    let payload = cloneDeep(values);
    payload.instructions = instructionsValue;
    payload.active = toggleValues.active;
    payload.bodyWeight = toggleValues.bodyWeight;
    payload.tags = {};
    payload.tags.level = keys(pickBy(toggleValues.level, Boolean));
    payload.tags.location = keys(pickBy(toggleValues.location, Boolean));
    payload.tags.specialtyFocus = keys(
      pickBy(toggleValues.specialtyFocus, Boolean)
    );

    setLoading(true);

    addExercise(payload)
      .then(result => {
        setStatus('success');
        setMessage(`Exercise "${result.name}" was created successfully!`);
        setValues(initialValues);
        setInstructionsValue([{ ...instruction }]);
        setToggleValues(initialToggleValues);
        setImageUrl(null);
        setThumbnailUrl(null);
      })
      .catch(err => {
        console.error(err);
        if (payload.imageUrl) {
          values.imageUrl = payload.imageUrl;
        }
        if (payload.thumbnailUrl) {
          values.thumbnailUrl = payload.thumbnailUrl;
        }
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin'
  );

  return (
    <ExerciseForm
      imageUrl={imageUrl}
      thumbnailUrl={thumbnailUrl}
      setImageUrl={setImageUrl}
      setThumbnailUrl={setThumbnailUrl}
      handleChange={handleChange}
      values={values}
      handleValueChanged={handleValueChanged}
      instructionsValue={instructionsValue}
      setInstructionsValue={setInstructionsValue}
      toggleValues={toggleValues}
      handleToggle={handleToggle}
      status={status}
      setStatus={setStatus}
      message={message}
      loading={loading}
      handleSubmit={handleSubmit}
      buttonText="Create Exercise"
    />
  );
};

export default AddExercise;
