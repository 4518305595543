import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';
import ModalContainer from '../../sharedModals/ModalContainer';
import useModal from '../../hooks/useModal';

const EmptySlot = ({ primary, loop, trackType, addTrack, ...props }) => {
  const { open, launchModal, closeModal } = useModal(false);

  return (
    <EmptyTrack
      justify="center"
      alignItems="center"
      data-test="track-emptySlot"
    >
      <Button
        buttonSize="small"
        buttonText="+ Add"
        pink="true"
        width="120px"
        handleClick={launchModal}
        data-test="track-find"
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          showOnly: 'track',
          primary: primary,
          loop: loop,
          trackType: trackType,
        }}
        initialScreen="Exercise Search"
        handleClose={closeModal}
        addTrack={addTrack}
        {...props}
      />
    </EmptyTrack>
  );
};

const EmptyTrack = styled(FlexContainer)`
  border: 1px dashed black;
  width: 560px;
  height: 100px;
`;

export default EmptySlot;
