import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import VerificationPage from '../authorization/VerificationPage';
import { verifyEmail } from '../store/actions/general';
import FlexContainer from '../elements/FlexContainer';
import CircularProgress from '@material-ui/core/CircularProgress';

const mapState = state => ({
  isVerified: state.currentUser.user.isVerified,
  isAuthenticated: state.currentUser.isAuthenticated,
  intakeComplete: state.currentUser.user.intakeComplete,
  isSubscribed: state.currentUser.user.isSubscribed,
});

const EmailVerificationRoute = ({ computedMatch: { params }, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {
    isVerified,
    intakeComplete,
    isAuthenticated,
    isSubscribed,
  } = useSelector(mapState);

  useEffect(() => {
    async function getToken() {
      try {
        const token = params.token;
        if (token) {
          // Need to show a loading screen while processing the token
          setLoading(true);
          dispatch(verifyEmail(token));
        }
      } catch (err) {
        console.error('Error while verifying your email:', err);
        const errMsg = err.error ? err.error.message : err.message;
        history.push('/please-verify', {
          message: errMsg,
        });
      }
    }
    getToken();
  }, [params.token]);

  useEffect(() => {
    if (isAuthenticated && isVerified && intakeComplete && isSubscribed) {
      setLoading(false);
      history.push('/app', {
        from: '/verify-email',
      });
    } else if (isAuthenticated && isVerified && !isSubscribed) {
      setLoading(false);
      history.push('/payment', {
        from: '/verify-email',
      });
    } else if (
      isAuthenticated &&
      isVerified &&
      isSubscribed &&
      !intakeComplete
    ) {
      setLoading(false);
      history.push('/questionnaire', {
        from: '/verify-email',
      });
    } else if (!isAuthenticated) {
      setLoading(false);
      history.push('/login', {
        from: '/verify-email',
      });
    }
  }, [isVerified, isAuthenticated]);

  return isVerified && intakeComplete && isAuthenticated ? (
    <Redirect to="/app" />
  ) : loading ? (
    <Loading />
  ) : !isVerified ? (
    <VerificationPage {...props} />
  ) : (
    <Redirect to="/login" />
  );
};

const Loading = props => {
  return (
    <FlexContainer
      justify="center"
      alignItems="center"
      style={{ width: '100%', height: '100%' }}
    >
      <CircularProgress size={64} color="inherit" data-test="loading-spinner" />
    </FlexContainer>
  );
};

export default EmailVerificationRoute;
