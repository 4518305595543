import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import CouponField from './CouponField';

const CouponDetails = ({ onChange, price }) => {
  const handleCouponAdded = coupon => {
    onChange({
      elementType: 'coupon',
      error: undefined,
      value: coupon,
      empty: false,
      complete: true,
    });
  };

  // Keep track of the in-between state where the coupon is not applied but yet something has been typed
  const handleCouponChange = couponCode => {
    if (couponCode !== '') {
      onChange({
        elementType: 'coupon',
        error: undefined,
        value: null,
        empty: false,
        complete: false,
      });
    } else {
      onChange({
        elementType: 'coupon',
        error: undefined,
        value: null,
        empty: true,
        complete: true,
      });
    }
  };

  return (
    <CouponDetailsContainer>
      <CouponField
        handleCouponAdded={handleCouponAdded}
        handleCouponChange={handleCouponChange}
        price={price}
      />
    </CouponDetailsContainer>
  );
};

const CouponDetailsContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  border: 1px solid ${colors.primary200};
  border-radius: 8px;
  background-color: #ffffff;
  margin: 0 0 15px 0;
  padding: 15px 20px;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
`;

export default CouponDetails;
