import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MobileTopNav from '../universal/MobileTopNav';
import ProfileCollapsible from './ProfileCollapsible';
import PaymentDisplay from './PaymentDisplay';

import InvoiceDetails from './InvoiceDetails';
import Subscription from './Subscription';
import { isMobile } from '../helpers/utils';
import useToggles from '../hooks/useToggles';

import {
  getBillingInfo,
  cancelSubscription,
  reactivateSubscription,
} from '../services/api/billing';

const UserProfileBilling = props => {
  const { toggleValues, handleToggle, setToggleValues } = useToggles();

  const initialInfo = {
    subscription: true,
    payment: isMobile() ? false : true,
    billing: isMobile() ? false : true,
  };

  useEffect(() => {
    setToggleValues(initialInfo);
  }, []);

  const [card, setCard] = useState({
    exp_month: null,
    exp_year: null,
    last4: 'xxxx',
    brand: 'other',
  });

  const [subscription, setSubscription] = useState({
    id: null,
    status: 'inactive',
    cancel_at_period_end: false,
    cancel_at: null,
    current_period_end: null,
  });

  const [price, setPrice] = useState({
    id: null,
    nickname: 'Loading...',
    unit_amount: 0,
    unit_amount_decimal: '0000',
    interval: 'month',
    interval_count: 1,
  });

  const fetchBillingInfo = async () => {
    const result = await getBillingInfo();
    if (result.billing) {
      setCard(result.billing.card);
      setPrice(result.billing.price);
      setSubscription(result.billing.subscription);
    }
  };

  useEffect(() => {
    console.debug('loading billing info');
    fetchBillingInfo();
  }, []);

  const handleCancelSubscription = async cancel => {
    try {
      if (cancel) {
        await cancelSubscription();
      } else {
        await reactivateSubscription({
          subscription: subscription.id,
        });
      }
      fetchBillingInfo();
    } catch (error) {
      console.error(error);
    }
  };

  if (isMobile()) {
    // Mobile view
    return (
      <BillingContainer>
        <MobileTopNav arrowsDisabled />
        <MobileContainer>
          <ProfileCollapsible
            title="Your Current Subscription"
            open={toggleValues.subscription}
            onClick={handleToggle()}
            name="subscription"
            children={<Subscription price={price} />}
          />
          <ProfileCollapsible
            title="Payment Method"
            open={toggleValues.payment}
            onClick={handleToggle()}
            name="payment"
            children={
              <PaymentDisplay
                card={card}
                subscription={subscription}
                cancelPlan={handleCancelSubscription}
              />
            }
          />
          <ProfileCollapsible
            title="Billing History"
            open={toggleValues.billing}
            onClick={handleToggle()}
            name="billing"
            children={<InvoiceDetails />}
          />
        </MobileContainer>
      </BillingContainer>
    );
  } else {
    return (
      <BillingContainer>
        <CollapsibleInfoContainer>
          <ProfileCollapsible
            title="Your Current Subscription"
            open={toggleValues.subscription}
            onClick={handleToggle()}
            name="subscription"
            children={<Subscription price={price} />}
          />
          <ProfileCollapsible
            title="Payment Method"
            open={toggleValues.payment}
            onClick={handleToggle()}
            name="payment"
            children={
              <PaymentDisplay
                card={card}
                subscription={subscription}
                cancelPlan={handleCancelSubscription}
              />
            }
          />
          <ProfileCollapsible
            title="Billing History"
            open={toggleValues.billing}
            onClick={handleToggle()}
            name="billing"
            children={<InvoiceDetails />}
          />
        </CollapsibleInfoContainer>
      </BillingContainer>
    );
  }
};

const BillingContainer = styled.div`
  padding-bottom: 24px;
`;

const MobileContainer = styled.div`
  margin: 64px 16px;
`;

const CollapsibleInfoContainer = styled.div`
  margin: 16px;

  > * {
    margin-bottom: 16px;
  }
`;

export default UserProfileBilling;
