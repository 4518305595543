import React, { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import { calculateMealStats } from '../mealPlan/calculateMealStats';
import { calculateWorkoutStats } from '../fitnessPlan/calculateWorkoutStats';
import { isMobile } from '../helpers/utils';
// import ExpertTip from '../universal/ExpertTip'; TODO: Post-Beta feature https://app.clickup.com/t/2mtgub6
import TodaysSummaryMeals from './TodaysSummaryMeals';
import TodaysSummaryFitness from './TodaysSummaryFitness';
import TodaysWorkout from './TodaysWorkout';
import TodaysMeals from './TodaysMeals';
import { connect } from 'react-redux';
import { formatDate } from '../helpers/date';
import {
  getUserMealPlanByDate,
  updateUserMealPlan,
  addRecipeToMealPlan,
  addFoodToMealPlan,
} from '../store/actions/mealplan';
import {
  getUserFitnessPlanByDate,
  addWorkoutToFitnessPlan,
  addActivityToFitnessPlan,
  updateUserFitnessPlan,
} from '../store/actions/fitnessplan';
import ErrorBoundary from '../services/ErrorBoundary';
import { trackEvent } from '../integrations/analytics';

const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

const UserDashboard = ({ mealPlan, fitnessPlan, ...props }) => {
  const today = new Date();
  const {
    recipes,
    foods,
    totalCalories,
    totalCaloriesComplete,
  } = calculateMealStats(mealPlan, today);

  const {
    workouts,
    activities,
    totalCaloriesBurned,
    totalCaloriesWorkout,
  } = calculateWorkoutStats(fitnessPlan, today);

  useEffect(() => {
    if (props.userId) {
      props.getUserFitnessPlanByDate(props.userId, today);
      props.getUserMealPlanByDate(props.userId, today);
    }
  }, [props.userId]);

  async function addFoodToUserMealPlan(params) {
    trackEvent('Add Food (Dashboard)', { food: params.food });
    await props.addFoodToMealPlan(mealPlan._id, params);
  }

  async function addRecipeToUserMealPlan(params) {
    trackEvent('Add Recipe (Dashboard)', { recipe: params.recipe });
    await props.addRecipeToMealPlan(mealPlan._id, params);
  }

  async function removeRecipeFromMealPlan(recipeId) {
    trackEvent('Remove Recipe (Dashboard)', { recipe: recipeId });
    const updatedMealPlan = cloneDeep(mealPlan);
    updatedMealPlan.recipes = updatedMealPlan.recipes.filter(
      r => r._id !== recipeId
    );
    await props.updateUserMealPlan(mealPlan._id, updatedMealPlan);
  }

  async function removeFoodFromMealPlan(foodId) {
    trackEvent('Remove Food (Dashboard)', { food: foodId });
    const updatedMealPlan = cloneDeep(mealPlan);
    updatedMealPlan.foods = updatedMealPlan.foods.filter(r => r._id !== foodId);
    await props.updateUserMealPlan(mealPlan._id, updatedMealPlan);
  }

  async function addWorkoutToUserFitnessPlan(params) {
    trackEvent('Add Workout (Dashboard)', { workout: params.workout });
    await props.addWorkoutToFitnessPlan(fitnessPlan._id, params);
  }

  async function addActivityToUserFitnessPlan(params) {
    trackEvent('Add Activity (Dashboard)', { activity: params.activity });
    await props.addActivityToFitnessPlan(fitnessPlan._id, params);
  }

  async function removeWorkoutFromFitnessPlan(workoutId) {
    trackEvent('Remove Workout (Dashboard)', { workout: workoutId });
    const updatedFitnessPlan = cloneDeep(fitnessPlan);
    updatedFitnessPlan.workouts = updatedFitnessPlan.workouts.filter(
      workout => workout._id !== workoutId
    );
    await props.updateUserFitnessPlan(fitnessPlan._id, updatedFitnessPlan);
  }

  async function removeActivityFromFitnessPlan(activityId) {
    trackEvent('Remove Activity (Dashboard)', { activity: activityId });
    const updatedFitnessPlan = cloneDeep(fitnessPlan);
    updatedFitnessPlan.activities = updatedFitnessPlan.activities.filter(
      activity => activity._id !== activityId
    );
    await props.updateUserFitnessPlan(fitnessPlan._id, updatedFitnessPlan);
  }

  async function recordWater(waterToUpdate, increment) {
    trackEvent(`Record Water (Dashboard)`);
    const updatedMealPlan = cloneDeep(mealPlan);
    const waterArr = updatedMealPlan.water.map(water =>
      water._id === waterToUpdate._id
        ? { ...water, amount: water.amount + increment }
        : water
    );
    updatedMealPlan.water = waterArr;
    await props.updateUserMealPlan(mealPlan._id, updatedMealPlan);
  }

  let water = { amount: 0, date: new Date(), unit: 'oz' };
  if (mealPlan?.water.length) {
    water = mealPlan?.water.filter(water => {
      return water.date === formatDate(new Date());
    })[0];
  }

  if (!isMobile())
    return (
      <PageContainer flexDirection="column">
        <WidgetsContainer
          alignItems="space-between"
          justify="center"
          wrap="wrap"
        >
          <Widget width="37%" height="190px">
            <ErrorBoundary>
              <TodaysSummaryMeals
                plannedCalories={totalCalories}
                currentCalories={totalCaloriesComplete}
                mealPlan={mealPlan}
                {...props}
              />
            </ErrorBoundary>
          </Widget>
          <Widget width="37%" height="190px">
            <ErrorBoundary>
              <TodaysSummaryFitness
                caloriesBurned={totalCaloriesBurned}
                workoutGoal={totalCaloriesWorkout}
                water={water}
                recordWater={recordWater}
                steps={12034} // TODO: Post-Beta feature - remove hard coded values
                stepsGoal={10000} // TODO:  https://app.clickup.com/t/8ethkc
                {...props}
              />
            </ErrorBoundary>
          </Widget>
          <Widget width="37%" height="500px">
            <ErrorBoundary>
              <TodaysMeals
                recipes={recipes || []}
                foods={foods}
                mealPlan={mealPlan}
                addRecipe={addRecipeToUserMealPlan}
                addFood={addFoodToUserMealPlan}
                removeRecipe={removeRecipeFromMealPlan}
                removeFood={removeFoodFromMealPlan}
              />
            </ErrorBoundary>
          </Widget>
          <Widget width="37%" height="500px">
            <ErrorBoundary>
              <TodaysWorkout
                workouts={workouts || []}
                activities={activities || []}
                fitnessPlan={fitnessPlan}
                totalCaloriesBurned={totalCaloriesBurned}
                addWorkout={addWorkoutToUserFitnessPlan}
                addActivity={addActivityToUserFitnessPlan}
                removeWorkout={removeWorkoutFromFitnessPlan}
                removeActivity={removeActivityFromFitnessPlan}
              />
            </ErrorBoundary>
          </Widget>
          {/* TODO: Post-Beta feature
          https://app.clickup.com/t/2mtgub6
          <Widget width="100%" height="50px">
            <TipContainer>
              <ExpertTip
                flexDir="row"
                alignItems="center"
                logoMargin="0px"
                tipTitle="Expert Tip of the Day"
              />
            </TipContainer>
          </Widget> */}
        </WidgetsContainer>
      </PageContainer>
    );
  else {
    return <Redirect to="/app" />;
  }
};

const PageContainer = styled(FlexContainer)`
  padding: 29px 15px;
  background-image: url(${fileStorage}/Background-full.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #eafff4;
  min-height: 100vh;
`;
const WidgetsContainer = styled(FlexContainer)`
  > * {
    margin-right: 16px;
  }
`;

const Widget = styled(FlexContainer)`
  padding: 40px;
  position: relative;
  min-width: 435px;
  max-width: 55%;
  flex-grow: 1;
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || '236px'};
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid ${colors.primary200};
  border-radius: 8px;
  margin-bottom: 24px;

  @media (max-width: 1322px) {
    width: 70%;
    max-width: 70%;
    justify-content: center;
  }
`;

// TODO: Post-Beta feature
// https://app.clickup.com/t/2mtgub6
// const TipContainer = styled(FlexContainer)`
//   ${'' /* margin-bottom: 44px; */}
// `;

function mapStateToProps(state) {
  const { currentUser, selectedMealPlan, selectedFitnessPlan } = state;
  return {
    userId: currentUser.user.id,
    userName: currentUser.user.fullName,
    mealPlan: selectedMealPlan,
    fitnessPlan: selectedFitnessPlan,
  };
}

export default connect(
  mapStateToProps,
  {
    updateUserMealPlan,
    getUserMealPlanByDate,
    addRecipeToMealPlan,
    addFoodToMealPlan,
    getUserFitnessPlanByDate,
    addWorkoutToFitnessPlan,
    addActivityToFitnessPlan,
    updateUserFitnessPlan,
  }
)(UserDashboard);
