import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const mapState = state => ({
  isAuthenticated: state.currentUser.isAuthenticated,
});

const PrivateRoute = props => {
  const { isAuthenticated } = useSelector(mapState);
  return isAuthenticated === true ? (
    <Route {...props} />
  ) : (
    <Redirect to="/login" />
  );
};

export default PrivateRoute;
