import { apiCall } from './api';

export function addActivity(params) {
  if (params.image && !params.imageUrl) {
    const paramsImage = new FormData();
    paramsImage.append('image', params.image);
    return apiCall('post', '/users/upload', paramsImage).then(result => {
      params.imageUrl = result.file;
      return apiCall('post', `/activity`, params);
    });
  }
  return apiCall('post', `/activity`, params);
}

export function editActivity(id, params) {
  if (params.image) {
    const paramsImage = new FormData();
    paramsImage.append('image', params.image);
    return apiCall('post', '/users/upload', paramsImage).then(result => {
      params.imageUrl = result.file;
      return apiCall('put', `/activity/${id}`, params);
    });
  }
  return apiCall('put', `/activity/${id}`, params);
}

export function getActivities(params) {
  return apiCall('get', `/activity`, params);
}

export function getActivity(id, params) {
  return apiCall('get', `/activity/${id}`, params);
}

export function searchActivities(params) {
  return apiCall('get', `/activity/search/${params.query}`, params);
}

export function getActivityReferences(id, params = {}) {
  params.query = { 'activities.activity': id };
  return apiCall('get', `/fitnessplan`, params);
}

export function replaceActivitiesOnFitnessPlan(
  fitnessPlan,
  oldActivity,
  newActivity
) {
  return apiCall(
    'put',
    `/fitnessplan/${fitnessPlan}/replace_activity/${oldActivity}/${newActivity}`
  );
}
