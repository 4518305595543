import React, { useState } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import RadioInput from '../../elements/RadioInput';
import { colors } from '../../styleConstants';

import AddRecipe from './AddRecipe';
import EditRecipe from './EditRecipe';
import ViewRecipe from './ViewRecipe';
import DuplicateRecipe from './DuplicateRecipe';
import DeleteRecipe from './DeleteRecipe';
import DefaultPlaceholder from './DefaultPlaceholder';

const AdminRecipe = props => {
  const [actionType, setActionType] = useState('create');

  const handleChange = e => {
    setActionType(e.target.value);
  };

  return (
    <Container flexDirection="column">
      <H2 data-test="admin-title-recipe">Recipe Admin</H2>
      <H3>Action Type</H3>
      <FlexContainer flexDirection="row" justify="space-around">
        <RadioInput
          checked={actionType === 'create'}
          value="create"
          label="Create"
          onChange={handleChange}
          name="recordType"
          data-test="recipe-radio-create"
        />
        <RadioInput
          checked={actionType === 'duplicate'}
          value="duplicate"
          label="Duplicate"
          onChange={handleChange}
          name="recordType"
          data-test="recipe-radio-duplicate"
        />
        <RadioInput
          checked={actionType === 'edit'}
          value="edit"
          label="Edit"
          onChange={handleChange}
          name="recordType"
          data-test="recipe-radio-edit"
        />
        <RadioInput
          checked={actionType === 'view'}
          value="view"
          label="View"
          onChange={handleChange}
          name="recordType"
          data-test="recipe-radio-view"
        />
        <RadioInput
          checked={actionType === 'delete'}
          value="delete"
          label="Delete"
          onChange={handleChange}
          name="recordType"
          data-test="recipe-radio-delete"
        />
        <RadioInput
          checked={actionType === 'placeholder'}
          value="placeholder"
          label="Placeholder"
          onChange={handleChange}
          name="recordType"
          data-test="recipe-radio-placeholder"
        />
      </FlexContainer>
      {actionType === 'create' ? (
        <AddRecipe />
      ) : actionType === 'duplicate' ? (
        <DuplicateRecipe />
      ) : actionType === 'edit' ? (
        <EditRecipe />
      ) : actionType === 'view' ? (
        <ViewRecipe />
      ) : actionType === 'delete' ? (
        <DeleteRecipe />
      ) : actionType === 'placeholder' ? (
        <DefaultPlaceholder />
      ) : null}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  max-width: 75%;
  margin-left: 25px;
  padding: 30px;
`;

const H2 = styled.h2`
  text-align: center;
  font-size: 28px;
  margin: 15px;
  color: ${colors.secondary600};
`;
const H3 = styled.h3`
  text-align: center;
  margin: 15px;
`;
export default AdminRecipe;
