import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import { ArrowBack, Close } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import FlexContainer from '../elements/FlexContainer';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IntensitySet from '../elements/IntensitySet';
import ExerciseModal from '../fitnessPlan/exercise/ExerciseModal';
import { getImageURL } from '../services/api/api';
import ClockIcon from '../icons/ClockIcon';
import Button from '../elements/Button';
import CheckmarkIcon from '../icons/CheckmarkIcon';

const Modal = ({ open, handleClose, item, toggleDone, remove }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [img, setImg] = useState({});

  const openModal = exerciseImg => {
    setImg(exerciseImg);
    setModalOpen(true);
  };

  const closeModalItem = () => {
    setModalOpen(false);
    setImg({});
  };

  const workoutImageURL = (ratio = 1) => {
    const width = 246 * ratio;
    const height = 151 * ratio;
    if (item.workout.imageUrl) {
      return getImageURL(
        item.workout.imageUrl,
        `resizing_type:fill/height:${height}/width:${width}`
      );
    } else {
      return `${process.env.PUBLIC_URL}/icons/WorkoutDefault${ratio}x.png`;
    }
  };

  const exercises = item.workout.exercises.map(exercise => {
    const units = exercise.exercise.repUnits;
    let foundSets = [];
    exercise.sets.forEach(set => {
      const existingSet = foundSets.find(
        foundSet => foundSet.repsNumber === set.repsNumber
      );
      if (existingSet) {
        existingSet.numSets++;
      } else {
        foundSets.push({ numSets: 1, repsNumber: set.repsNumber });
      }
    });
    let label = '';
    foundSets.forEach(
      foundSet =>
        (label += `${foundSet.numSets} x ${foundSet.repsNumber} ${units}, `)
    );
    if (label[label.length - 1] === ' ') {
      label = label.slice(0, -2);
    }
    return (
      <TR>
        <TD>
          <Text
            onClick={() => openModal(exercise.exercise.imageUrl)}
            data-test="planner-workoutModal-exerciseName"
          >
            {exercise.exercise.name}
          </Text>
        </TD>
        <TD>
          <SetText>{label}</SetText>
        </TD>
      </TR>
    );
  });

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      data-test="planner-workoutModal"
    >
      <DialogTitle disableTypography style={{ paddingBottom: 0 }}>
        {/* Action buttons */}
        <FlexContainer>
          <Tooltip title="Back">
            <IconButton
              aria-label="back"
              onClick={handleClose}
              style={{ marginLeft: -12 }}
              data-test="planner-workoutModal-back"
            >
              <ArrowBack />
            </IconButton>
          </Tooltip>

          {/* Align right */}
          <FlexContainer justify="flex-end" flexGrow="1">
            <Tooltip title="Close">
              <IconButton
                aria-label="close"
                onClick={handleClose}
                style={{ marginRight: -12 }}
                data-test="planner-workoutModal-close"
              >
                <Close />
              </IconButton>
            </Tooltip>
          </FlexContainer>
        </FlexContainer>
      </DialogTitle>

      <DialogContent>
        <Content>
          <FlexContainer flexDirection="column" margin="0 24px 0 0">
            <Image
              image={workoutImageURL()}
              image2x={workoutImageURL(2)}
              data-test="planner-workoutModal-image"
            />
          </FlexContainer>

          <FlexContainer flexDirection="column" margin="0 15px 0 0">
            <WorkoutTitle>Workout</WorkoutTitle>
            <Name data-test="planner-workoutModal-name">
              {item.workout.name}
            </Name>
            <FlexContainer alignItems="center" gap="8px">
              <ClockIcon />
              <Duration data-test="planner-workoutModal-duration">
                {item.workout.duration} minutes
              </Duration>
            </FlexContainer>
          </FlexContainer>
        </Content>
        <Table>
          <THead>
            <TR>
              <TH style={{ backgroundColor: colors.secondary400 }}>
                <CaloriesText data-test="planner-workoutModal-calsBurned">
                  {item.workout.caloriesBurned} calories
                </CaloriesText>
              </TH>
              <TH style={{ backgroundColor: colors.hliteone100 }}>
                <IntensityContainer alignItems="center" gap="14px">
                  <IntensityText>Intensity </IntensityText>
                  <IntensitySet
                    intensity={item.workout.intensity}
                    data-test="planner-workoutModal-intensity"
                  />
                </IntensityContainer>
              </TH>
            </TR>
          </THead>
          <TBody>{exercises}</TBody>
        </Table>
      </DialogContent>
      <BottomContainer justify="space-between" alignItems="center">
        <Delete onClick={remove} data-test="planner-workoutModal-delete">
          Delete
        </Delete>
        <LogButton
          type="submit"
          buttonText={item.allComplete ? 'Logged!' : 'Log as Completed'}
          onClick={toggleDone}
          logged={item.allComplete}
          startIcon={!item.allComplete && <CheckmarkIcon invert="true" />}
          data-test="planner-workoutModal-log"
        />
      </BottomContainer>
      <ExerciseModal open={modalOpen} img={img} handleClose={closeModalItem} />
    </Dialog>
  );
};

const Content = styled(FlexContainer)`
  padding: 0 16px 32px 16px;
`;

const Table = styled.table`
  width: calc(100% - 32px);
  border: 1px solid ${colors.primary200};
  border-radius: 4px;
  border-spacing: 0;
  padding-bottom: 12px;
  margin: 0 16px;
`;

const THead = styled.thead`
  height: 48px;
`;

const TBody = styled.tbody`
  width: 100%;

  > :first-child > :nth-child(1) {
    padding-top: 6px;
    border-top: 1px solid ${colors.primary200};
  }
  > :first-child > :nth-child(2) {
    padding-top: 6px;
    border-top: 1px solid ${colors.primary200};
  }
  > :nth-child(even) {
    background-color: ${colors.primary050};
  }
`;

const TH = styled.th`
  text-align: left;
  padding-left: 20px;
`;

const TR = styled.tr``;

const TD = styled.td`
  padding-left: 20px;
  height: 30px;
`;

const Name = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  color: ${colors.primary800};
  margin: 0 0 12px 0;
  text-transform: capitalize;
`;

const Duration = styled.p`
  color: ${colors.primary800};
  font-size: 13px;
`;

const Image = styled.div`
  width: 246px; // Update image dimensions accordingly.
  height: 151px; // Update image dimensions accordingly.
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 6px;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #000;

  &:hover {
    cursor: pointer;
    color: ${colors.primary500};
  }
`;

const SetText = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: #000;
`;

const CaloriesText = styled.p`
  color: ${colors.primary900};
  font-size: 15px;
  font-weight: 700;
`;

const IntensityContainer = styled(FlexContainer)`
  margin-top: 2px;
`;

const IntensityText = styled.p`
  color: ${colors.secondary600};
  font-size: 15px;
  font-weight: 500;
`;

const WorkoutTitle = styled.h4`
  color: ${colors.secondary600};
  font-size: 12px;
  font-weight: 700;
`;

const BottomContainer = styled(FlexContainer)`
  padding: 21px 40px;
`;

const Delete = styled.p`
  color: ${colors.hlitetwo400};
  font-size: 14px;
  font-weight: 700;
  line-height: normal;

  &:hover {
    cursor: pointer;
    color: ${colors.hlitetwo300};
  }
`;

const LogButton = styled(Button)`
  width: 210px;
  height: 40px;
  color: ${props => (props.logged ? colors.secondary300 : '#fff')};
  background: ${props => (props.logged ? '#fff' : colors.secondary400)};
  border-radius: 2px;
  border: ${props =>
    props.logged ? `1px solid ${colors.secondary300}` : 'none'};
  text-transform: none;
  font-weight: 400;

  &:hover {
    color: #fff;
    background: ${props =>
      props.logged ? colors.secondary300 : colors.secondary400};
    border: ${props =>
      props.logged ? `1px solid ${colors.secondary300}` : 'none'};
  }
`;

export default Modal;
