import React from 'react';

const ShrinkIcon = ({ handleClick, ...props }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    onClick={handleClick}
    {...props}
  >
    <circle cx="16" cy="16" r="16" fill="white" />
    <mask
      id="mask0_2203_103919"
      maskUnits="userSpaceOnUse"
      x="5"
      y="5"
      width="22"
      height="22"
    >
      <rect
        x="5.33337"
        y="5.3335"
        width="21.3333"
        height="21.3333"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_2203_103919)">
      <path
        d="M9.26667 24L8 22.7333L12.0889 18.6667H9.77778V16.8889H15.1111V22.2222H13.3333V19.9111L9.26667 24ZM16.8889 15.1111V9.77778H18.6667V12.0889L22.7333 8L24 9.26667L19.9111 13.3333H22.2222V15.1111H16.8889Z"
        fill="#16979A"
      />
    </g>
  </svg>
);

export default ShrinkIcon;
