import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import Button from '../elements/Button';

import useReactRouter from 'use-react-router';
import useModal from '../hooks/useModal';
import ModalContainer from '../sharedModals/ModalContainer';
import MealList from '../mealPlan/MealList';
import TextLink from '../elements/TextLink';
import { formatDate } from '../helpers/date';

const TodaysMeals = ({ recipes, foods, mealPlan, ...props }) => {
  const today = new Date();
  const { history } = useReactRouter();
  const { open, launchModal, closeModal, context } = useModal(false);

  return (
    <Container flexDirection="column">
      <ScrollContainer>
        <MealsLabel>Today's Meals</MealsLabel>
        <MealList
          variant="dashboard"
          recipes={recipes}
          foods={foods}
          {...props}
        />
      </ScrollContainer>
      <BottomContainer
        alignItems="center"
        justify="space-between"
        flexShrink="0"
      >
        <TextLink
          linkText="View Meal Plan"
          handleClick={() => history.push('/app/mealPlan')}
          data-test="dashboard-view-mealPlan"
        />
        <AddButton
          data-date={formatDate(today)}
          data-variant="dashboard"
          pink="true"
          buttonText="+ Add"
          handleClick={launchModal}
          data-test="dashboard-meal-add"
        />
      </BottomContainer>
      <ModalContainer
        open={open}
        context={context}
        handleClose={closeModal}
        initialScreen="Add Meal"
        {...props}
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  position: relative;

  ::before {
    content: '';
    pointer-events: none;
    position: absolute;
    bottom: 40px;
    z-index: 5;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    height: 20%;
    width: 95%;
  }
`;

const ScrollContainer = styled(FlexContainer)`
  overflow-y: scroll;
  width: 100%;
  position: relative;
`;

const BottomContainer = styled(FlexContainer)`
  width: 95%;
  padding-top: 18px;
`;

const AddButton = styled(Button)`
  width: 120px;
`;

const MealsLabel = styled.h6`
  color: ${colors.secondary500};
  text-transform: uppercase;
  font-weight: 400;
  position: absolute;
  top: 0px;
  left: 0px;
`;

export default TodaysMeals;
