import styled from 'styled-components';
import React from 'react';
import { colors } from '../styleConstants';
import { default as MaterialButton } from '@material-ui/core/Button';

const ExpandableButton = ({
  buttonText,
  buttonSize,
  handleClick,
  color,
  hoverColor,
  icon,
  ...props
}) => {
  return (
    <>
      {buttonSize === 'large' ? (
        <LargeButton
          variant="contained"
          fullWidth={true}
          size="large"
          onClick={handleClick}
          color={color}
          hovercolor={hoverColor}
          {...props}
        >
          {icon && icon}
          <Text>{buttonText}</Text>
        </LargeButton>
      ) : (
        <SmallButton
          variant="contained"
          size="small"
          onClick={handleClick}
          color={color}
          hovercolor={hoverColor}
          {...props}
        >
          {icon && icon}
          <Text>{buttonText}</Text>
        </SmallButton>
      )}
    </>
  );
};

const StyledButton = styled(MaterialButton)`
  color: white;
  background-color: ${props =>
    props.color ? props.color : colors.secondary500};
  text-align: center;
  text-transform: ${props =>
    props.fontcase === 'titleCase'
      ? 'capitalize'
      : props.fontcase === 'lowercase'
      ? 'lowercase'
      : 'uppercase'};
  letter-spacing: inherit;
  box-shadow: none;
  position: relative;

  overflow: hidden;
  transition: 0.6s ease-out;
  transition-delay: 0.2s;
  min-width: 28px;
  padding: 0px;

  &:hover {
    background-color: ${props =>
      props.hovercolor ? props.hovercolor : colors.secondary400};
    cursor: pointer;
    width: ${props => (props.width ? props.width : '170px')};
  }
`;

const LargeButton = styled(StyledButton)`
  line-height: 23px;
  font-size: ${props => (props.fontSize ? props.fontSize : '19px')};
  font-weight: 600;
  height: 40px;
  /* padding: 0 24px; */
`;

const SmallButton = styled(StyledButton)`
  display: inline-flex;
  font-size: ${props => (props.fontSize ? props.fontSize : '15px')};
  font-weight: 500;
  line-height: 18px;
  border-radius: 16px;
  height: 28px;
  /* padding: 0 16px; */
`;

const Text = styled.span`
  transition: 0.1s ease-in;
  max-width: 0;
  opacity: 0;

  max-height: 1em;
  white-space: nowrap;

  ${StyledButton}:hover & {
    max-width: 200px;

    margin-left: 8px;
    opacity: 1;
    min-width: fit-content;
  }
`;

export default ExpandableButton;
