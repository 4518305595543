import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { setDefaultUser, getUser } from './store/actions/general';
import { getIngredientMaps } from './store/actions/shoppinglist';
import jwtDecode from 'jwt-decode';

import './App.css';
import Questionnaire from './intake/Questionnaire';
import PaymentScreen from './intake/PaymentScreen';
import AppContainer from './routes/AppContainer';
import AppContainerRoute from './routes/AppContainerRoute';
import AuthRoute from './routes/AuthRoute';

import EmailVerificationRoute from './routes/EmailVerificationRoute';
import ForgotPassWrapper from './authorization/ForgotPassWrapper';
import PrivateRoute from './routes/PrivateRoute';
import QuestionnaireRoute from './routes/QuestionnaireRoute';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { identifyUser, trackPage } from './integrations/analytics';
import { isIOS, isInStandaloneMode } from './utils/appUtils';
import VerificationPage from './authorization/VerificationPage';
import OAuthPage from './authorization/OAuthPage';
import { isMobile } from './helpers/utils';
import PWAPrompt from './universal/PWAPrompt';
import Hotjar from '@hotjar/browser';
import SamplesContainer from './routes/SamplesContainer';

export const App = ({
  setDefaultUser,
  getUser,
  currentUser,
  getIngredientMaps,
}) => {
  const { isChecked, isAuthenticated } = currentUser;
  const {
    isVerified,
    hidePWAPrompt,
    isAdmin,
    subscriptionStatus,
    intakeComplete,
  } = currentUser.user;
  useEffect(() => {
    const initialize = () => {
      try {
        const token = jwtDecode(localStorage.jwtToken);
        getUser(token.id);
        getIngredientMaps();
      } catch (error) {
        setDefaultUser();
      }
    };

    /* this piece of code is required for url bar to shrink/collapse on mobile browsers */
    if (isMobile()) {
      const tags = document?.getElementsByTagName('html');
      tags[0].style.height = 'unset';
    }

    initialize();
    if (process.env.REACT_APP_ENV === 'production') {
      // Facebook pixel
      ReactPixel.init('261415902142030');
      // Hotjar setup
      const siteId = 3726305;
      const hotjarVersion = 6;
      Hotjar.init(siteId, hotjarVersion);
    }
  }, []);

  let location = useLocation();
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      ReactPixel.pageView();
      trackPage();
      if (
        location.pathname.includes('/app') &&
        currentUser?.user?.isAdmin !== true
      ) {
        ReactGA.set({ page: location.pathname }, ['appTracker']); // Update the user's current page
        ReactGA.pageview(location.pathname, ['appTracker']); // Record a pageview for the given page
      } else if (location.pathname.includes('verify-email')) {
        // don't worry about the token
        ReactGA.set({ page: '/verify-email' }, ['marketingTracker']); // Update the user's current page
        ReactGA.pageview('/verify-email', ['marketingTracker']); // Record a pageview for the given page
      } else {
        ReactGA.set({ page: location.pathname }, ['marketingTracker']); // Update the user's current page
        ReactGA.pageview(location.pathname, ['marketingTracker']); // Record a pageview for the given page
      }
    }
  }, [location]);

  useEffect(() => {
    if (
      process.env.REACT_APP_ENV === 'production' &&
      isChecked &&
      !currentUser.user.isAdmin
    ) {
      identifyUser(currentUser.user._id, {
        name: currentUser.user.fullName,
        email: currentUser.user.email,
        is_verified: currentUser.user.isVerified,
        created_at: currentUser.user.createdAt,
        birthdate: currentUser.user.birthDate,
        age: currentUser.user.age,
        gender: currentUser.user.gender,
        body_comp_goal: currentUser.user.detailPreferences.nutriologyGoal,
        recommended_calories: currentUser.user.recommendedMealPlanCalories,
        eating_preference: currentUser.user.foodPreferences.eatingPreference,
        workout_location: currentUser.user.fitnessPreferences.fitnessStyle,
        fitness_level: currentUser.user.detailPreferences.activityLevel,
        subscription_status: currentUser.user.subscriptionStatus,
      });
    }
  }, [isChecked]);

  // Determine whether to prompt user
  const [showPrompt, setShowPrompt] = useState(false);

  const shouldShowPrompt = async () => {
    if (
      isChecked &&
      !isAdmin &&
      isIOS() &&
      !isInStandaloneMode() &&
      !hidePWAPrompt &&
      isAuthenticated &&
      isVerified &&
      subscriptionStatus === 'active' &&
      intakeComplete
    ) {
      setShowPrompt(true);
    } else {
      setShowPrompt(false);
    }
  };

  useEffect(() => {
    shouldShowPrompt();
  }, [
    isChecked,
    isAdmin,
    isAuthenticated,
    isVerified,
    subscriptionStatus,
    hidePWAPrompt,
    intakeComplete,
  ]);

  return (
    <>
      {isChecked && (
        <Switch>
          <QuestionnaireRoute path="/questionnaire" component={Questionnaire} />
          <EmailVerificationRoute
            path="/please-verify"
            component={VerificationPage}
          />
          <EmailVerificationRoute
            path="/verify-email/:token"
            component={VerificationPage}
          />
          <PrivateRoute exact path="/payment" component={PaymentScreen} />
          <PrivateRoute exact path="/oauth" component={OAuthPage} />
          <AppContainerRoute path="/app" component={AppContainer} />
          <Route path="/reset/:token" component={ForgotPassWrapper} />
          <Route path="/samples" component={SamplesContainer} />
          <AuthRoute path="/" />
        </Switch>
      )}
      {showPrompt && <PWAPrompt open={true} />}
    </>
  );
};

function mapStateToProps(state) {
  const { currentUser } = state;
  return {
    currentUser,
  };
}

export default connect(
  mapStateToProps,
  { setDefaultUser, getUser, getIngredientMaps }
)(App);
