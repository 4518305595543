import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';

const ClockIcon = props => (
  <Icon
    viewBox="0 0 19 19"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Recipe-Page"
        transform="translate(-279.000000, -170.000000)"
        stroke={colors.primary800}
        strokeWidth="1.5"
      >
        <g id="Recipe-Time-icon" transform="translate(280.000000, 171.000000)">
          <circle id="Oval" fill="#FFFFFF" cx="8.5" cy="8.5" r="8.5"></circle>
          <polyline id="Path-4" points="9 3 9 8.76015232 12 11"></polyline>
        </g>
      </g>
    </g>
  </Icon>
);

const Icon = styled.svg`
  width: 19px;
  height: 19px;
`;

export default ClockIcon;
