import moment from 'moment';
import { apiCall } from '../../services/api/api';
import { SET_SHOPPING_LIST, SET_INGREDIENTMAPS } from '../actionTypes';
import { handleError, removeError } from './general';

const defaultStartDate = moment().format('YYYY-MM-DD');

const defaultEndDate = moment()
  .add(1, 'week')
  .format('YYYY-MM-DD');

// Shopping List Actions
export function setShoppingList(shoppingList) {
  return {
    type: SET_SHOPPING_LIST,
    shoppingList,
  };
}

export function getShoppingList(id, params = {}) {
  params.startDate = moment();
  params.endDate = moment().add(1, 'week');
  return dispatch => {
    dispatch(removeError('shoppinglist'));
    return apiCall('get', `/shoppingList/${id}`, params)
      .then(shoppingList => {
        dispatch(setShoppingList(shoppingList));
      })
      .catch(err => {
        handleError(dispatch, err, 'shoppinglist');
      });
  };
}

export function getShoppingLists(params = {}) {
  params.startDate = params.startDate
    ? params.startDate.format('YYYY-MM-DD')
    : defaultStartDate;
  params.endDate = params.endDate
    ? params.endDate.format('YYYY-MM-DD')
    : defaultEndDate;
  return dispatch => {
    //TODO: REMOVE
    dispatch(removeError('shoppinglist'));
    return apiCall('get', `/shoppingList`, params)
      .then(result => {
        const shoppingList = result;
        dispatch(setShoppingList(shoppingList));
      })
      .catch(err => {
        handleError(dispatch, err, 'shoppinglist');
      });
  };
}

export function getFilteredShoppingLists(params = {}) {
  params.startDate = params.startDate
    ? params.startDate.format('YYYY-MM-DD')
    : defaultStartDate;
  params.endDate = params.endDate
    ? params.endDate.format('YYYY-MM-DD')
    : defaultEndDate;
  return dispatch => {
    dispatch(removeError('shoppinglist'));
    return apiCall('get', `/shoppinglist/filter`, params)
      .then(result => {
        const shoppingList = result;
        dispatch(setShoppingList(shoppingList));
      })
      .catch(err => {
        handleError(dispatch, err, 'shoppinglist');
      });
  };
}

export function addFoodToShoppingList(id, params = {}) {
  params.startDate = params.startDate
    ? params.startDate.format('YYYY-MM-DD')
    : defaultStartDate;
  params.endDate = params.endDate
    ? params.endDate.format('YYYY-MM-DD')
    : defaultEndDate;
  return dispatch => {
    dispatch(removeError('shoppinglist'));
    return apiCall('put', `/shoppingList/add_food/${id}`, params)
      .then(shoppingList => {
        dispatch(setShoppingList(shoppingList));
      })
      .catch(err => {
        handleError(dispatch, err, 'shoppinglist');
      });
  };
}

export function removeFoodFromShoppingList(id, foodId, params = {}) {
  params.foodId = foodId;
  params.startDate = params.startDate
    ? params.startDate.format('YYYY-MM-DD')
    : defaultStartDate;
  params.endDate = params.endDate
    ? params.endDate.format('YYYY-MM-DD')
    : defaultEndDate;
  return dispatch => {
    dispatch(removeError('shoppinglist'));
    return apiCall('put', `/shoppingList/remove_food/${id}`, params)
      .then(shoppingList => {
        dispatch(setShoppingList(shoppingList));
      })
      .catch(err => {
        handleError(dispatch, err, 'shoppinglist');
      });
  };
}

export function removeRecipeFromShoppingList(id, recipeId, params = {}) {
  params.recipeId = recipeId;
  params.startDate = params.startDate
    ? params.startDate.format('YYYY-MM-DD')
    : defaultStartDate;
  params.endDate = params.endDate
    ? params.endDate.format('YYYY-MM-DD')
    : defaultEndDate;
  return dispatch => {
    return apiCall('put', `/shoppingList/remove_recipe/${id}`, params)
      .then(shoppingList => {
        dispatch(removeError('shoppinglist'));
        dispatch(setShoppingList(shoppingList));
      })
      .catch(err => {
        handleError(dispatch, err, 'shoppinglist');
      });
  };
}

export function setFoodCompleted(id, foodId, completed, params = {}) {
  params.foodId = foodId;
  params.completed = completed;
  params.startDate = params.startDate
    ? params.startDate.format('YYYY-MM-DD')
    : defaultStartDate;
  params.endDate = params.endDate
    ? params.endDate.format('YYYY-MM-DD')
    : defaultEndDate;
  return dispatch => {
    dispatch(removeError('shoppinglist'));
    return apiCall('put', `/shoppingList/set_complete/${id}`, params)
      .then(shoppingList => {
        dispatch(setShoppingList(shoppingList));
      })
      .catch(err => {
        handleError(dispatch, err, 'shoppinglist');
      });
  };
}

export function clearCompletedFoods(id, params = {}) {
  params.startDate = params.startDate
    ? params.startDate.format('YYYY-MM-DD')
    : defaultStartDate;
  params.endDate = params.endDate
    ? params.endDate.format('YYYY-MM-DD')
    : defaultEndDate;
  return dispatch => {
    dispatch(removeError('shoppinglist'));
    return apiCall('put', `/shoppingList/clear_completed/${id}`, params)
      .then(shoppingList => {
        dispatch(setShoppingList(shoppingList));
      })
      .catch(err => {
        handleError(dispatch, err, 'shoppinglist');
      });
  };
}

export function adjustFoodQuantity(id, foodId, value, params = {}) {
  params.foodId = foodId;
  params.value = value;
  params.startDate = params.startDate
    ? params.startDate.format('YYYY-MM-DD')
    : defaultStartDate;
  params.endDate = params.endDate
    ? params.endDate.format('YYYY-MM-DD')
    : defaultEndDate;
  return dispatch => {
    dispatch(removeError('shoppinglist'));
    return apiCall('put', `/shoppingList/adjust_quantity/${id}`, params)
      .then(shoppingList => {
        dispatch(setShoppingList(shoppingList));
      })
      .catch(err => {
        handleError(dispatch, err, 'shoppinglist');
      });
  };
}

// Ingredient Map Actions
export function getIngredientMaps() {
  return dispatch => {
    dispatch(removeError('shoppinglist'));
    return apiCall('get', '/ingredientmap')
      .then(maps => {
        dispatch(setIngredientMaps(maps));
      })
      .catch(err => {
        dispatch(setIngredientMaps([]));
        handleError(dispatch, err, 'shoppinglist');
      });
  };
}

export function setIngredientMaps(maps) {
  return {
    type: SET_INGREDIENTMAPS,
    maps,
  };
}
