import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import Button from '../elements/Button';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { connect } from 'react-redux';
import { logout } from '../store/actions/general';
import useReactRouter from 'use-react-router';

const ProfileDropdown = ({
  open,
  anchorEl,
  placement,
  handleClose,
  logout,
  ...props
}) => {
  const { history } = useReactRouter();

  const handleLogout = () => {
    logout(history);
  };

  const handleLink = path => {
    history.push(path);
  };

  return (
    <Card
      name="popper"
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      disablePortal={false}
      data-test="nav-profile-dropdown"
    >
      <ClickAwayListener onClickAway={handleClose}>
        <ListContainer flexDirection="column" alignItems="center">
          <OptionsList flexDirection="column">
            <OptionRow onClick={() => handleLink('/app/profile')}>
              <FlexContainer alignItems="center">
                <IconContainer>
                  <img
                    src={`${process.env.PUBLIC_URL}/icons/supervised_user_circle.svg`}
                    alt="Settings"
                  />
                </IconContainer>
                <Text>Settings</Text>
              </FlexContainer>
            </OptionRow>
            <StyledLink
              href="https://forms.clickup.com/f/2d9kx-365/SDBI1TTVHUC2PJ659Y"
              target="_blank"
              rel="noopener noreferrer"
            >
              <OptionRow>
                <FlexContainer alignItems="center">
                  <IconContainer>
                    <img
                      src={`${process.env.PUBLIC_URL}/icons/sticky_note_2.svg`}
                      alt="Feature Request"
                    />
                  </IconContainer>
                  <Text>Request a Feature</Text>
                </FlexContainer>
              </OptionRow>
            </StyledLink>
            <StyledLink
              href="https://forms.clickup.com/f/2d9kx-295/IRF24Y4HEZ2RMBFRV6"
              target="_blank"
              rel="noopener noreferrer"
            >
              <OptionRow>
                <FlexContainer alignItems="center">
                  <IconContainer>
                    <img
                      src={`${process.env.PUBLIC_URL}/icons/bug_report.svg`}
                      alt="Bug Report"
                    />
                  </IconContainer>
                  <Text>Report a Bug</Text>
                </FlexContainer>
              </OptionRow>
            </StyledLink>
            <StyledLink
              href="https://nutriology.io/user-support/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <OptionRow>
                <FlexContainer alignItems="center">
                  <IconContainer>
                    <img
                      src={`${process.env.PUBLIC_URL}/icons/help.svg`}
                      alt="Help"
                    />
                  </IconContainer>
                  <Text>Help & Support</Text>
                </FlexContainer>
              </OptionRow>
            </StyledLink>
          </OptionsList>
          <StyledButton
            buttonSize="large"
            buttonText="Log Out"
            pink="true"
            onClick={handleLogout}
            data-test="logout"
          />
        </ListContainer>
      </ClickAwayListener>
    </Card>
  );
};

const Card = styled(Popper)`
  border: 1px solid ${colors.primary200};
  border-radius: 2px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  z-index: 10;
  width: 264px;
  margin-top: 12px;
`;

const ListContainer = styled(FlexContainer)`
  width: calc(100% - 32px);
  padding: 8px 16px 16px 16px;
`;

const OptionsList = styled(FlexContainer)`
  width: 100%;
`;

const OptionRow = styled(FlexContainer)`
  height: 48px;
  border-bottom: 1px solid ${colors.primary100};
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const IconContainer = styled(FlexContainer)`
  width: 32px;
  height: 32px;
  border-radius: 18px;
  background-color: ${colors.primary050};
  border: 1px solid ${colors.primary200};
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

const Text = styled.h5`
  font-weight: 500;
  color: ${colors.primary800};
`;

const StyledButton = styled(Button)`
  font-size: 18px;
  margin-top: 16px;
  width: 100%;
  max-width: 354px;
`;
const StyledLink = styled.a`
  :visited {
    color: ${colors.primary200};
  }
`;
export default connect(
  null,
  { logout }
)(ProfileDropdown);
