import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { isEmpty } from 'lodash';

import WorkoutSection from './WorkoutSection';
import { isMobile } from '../../helpers/utils';

const WorkoutSimplified = ({ exercises, ...props }) => {
  const warmup = exercises.filter(
    exercise => exercise.workoutSequence === 'Warm Up'
  );
  const core = exercises.filter(
    exercise => exercise.workoutSequence === 'Core'
  );
  const strength = exercises.filter(
    exercise => exercise.workoutSequence === 'Strength'
  );
  const circuit = exercises.filter(
    exercise => exercise.workoutSequence === 'Circuit'
  );
  const conditioning = exercises.filter(
    exercise => exercise.workoutSequence === 'Conditioning'
  );
  const cooldown = exercises.filter(
    exercise => exercise.workoutSequence === 'Cool Down'
  );

  const workoutSections = (
    <>
      {!isEmpty(warmup) && (
        <WorkoutSection
          sectionId="warm-up"
          rest={3}
          exercises={warmup}
          {...props}
        />
      )}
      {!isEmpty(core) && (
        <WorkoutSection sectionId="core" rest={3} exercises={core} {...props} />
      )}
      {!isEmpty(strength) && (
        <WorkoutSection
          sectionId="strength"
          rest={3}
          exercises={strength}
          {...props}
        />
      )}
      {!isEmpty(circuit) && (
        <WorkoutSection
          sectionId="circuit"
          rest={3}
          exercises={circuit}
          {...props}
        />
      )}
      {!isEmpty(conditioning) && (
        <WorkoutSection
          sectionId="conditioning"
          rest={3}
          exercises={conditioning}
          {...props}
        />
      )}
      {!isEmpty(cooldown) && (
        <WorkoutSection
          sectionId="cool-down"
          rest={3}
          exercises={cooldown}
          {...props}
        />
      )}
    </>
  );
  if (isMobile()) {
    // mobile version
    return <MobileCardContainer>{workoutSections}</MobileCardContainer>;
  } else {
    // web version
    return (
      <CardContainer flexDirection="column">{workoutSections}</CardContainer>
    );
  }
};

const CardContainer = styled(FlexContainer)`
  background-color: #fff;
  min-height: 144px;
  min-width: 400px;
  width: 80%;
  position: relative;
`;
const MobileCardContainer = styled.div`
  margin-bottom: 64px;
`;
export default WorkoutSimplified;
