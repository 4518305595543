import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import { allUnits } from '../../helpers/units';

import TextInput from '../../elements/TextInput';
import Dropdown from '../../elements/Dropdown';
import CheckBox from '../../elements/CheckBox';
import FractionInput from '../../elements/FractionInput';
import AlertStack from '../../elements/AlertStack';
import Button from '../../elements/Button';

const FoodForm = ({
  handleChange,
  values,
  handleValueChanged,
  toggleValues,
  handleToggle,
  status,
  setStatus,
  message,
  loading,
  handleSubmit,
  viewOnly,
  buttonText,
  formType,
  eshaId,
  undoDelete,
  ...props
}) => {
  const categories = [
    'Alcoholic Drinks',
    'Asian',
    'Baking Ingredients',
    'Beverages',
    'Bread & Bakery',
    'Breakfast Foods',
    'Candy',
    'Canned Foods',
    "Children's",
    'Condiments & Sauces',
    'Dairy Products & Subs',
    'Desserts',
    'Dishes & Prepared Foods',
    'Dried Foods',
    'Dry Mixes',
    'Egg Products & Subs',
    'Ethnic Foods',
    'Fats Oils & Subs',
    'Fish & Seafood',
    'Food Additives',
    'Fresh/Raw Foods',
    'Frozen Foods',
    'Fruits',
    "Infant's",
    'Italian',
    'Legumes',
    'Meals',
    'Meat',
    'Meat Substitutes',
    'Mexican',
    'Nuts & Seeds',
    'Pasta',
    'Poultry',
    'Rice',
    'Sandwiches',
    'Snacks',
    'Special Dietary',
    'Sweeteners',
    'Vegetables',
    'Vitamins & Supplements',
  ];

  const categoryChecks = categories.map((cat, idx) => {
    return (
      <CheckBox
        key={idx}
        checked={toggleValues.categories[cat] || ''}
        label={cat}
        onChange={handleToggle('categories')}
        name={cat}
      />
    );
  });

  return (
    <Form onSubmit={handleSubmit}>
      <FieldSet disabled={viewOnly || false}>
        {eshaId && (
          <ESHAId data-test="food-eshaId">{`ESHA ID: ${eshaId}`}</ESHAId>
        )}
        <TextInput
          id="verboseName"
          name="verboseName"
          onChange={handleChange}
          value={values.verboseName}
          placeholder="Food Verbose Name"
          label="Food Verbose Name"
          onFocus={e => (e.target.placeholder = '')}
          onBlur={e => (e.target.placeholder = 'Food Verbose Name')}
          data-test="food-verboseName"
        />
        <TextInput
          id="foodName"
          name="name"
          onChange={handleChange}
          value={values.name}
          placeholder="Food Name"
          label="Food Name"
          onFocus={e => (e.target.placeholder = '')}
          onBlur={e => (e.target.placeholder = 'Food Name')}
          data-test="food-name"
        />
        <TextInput
          id="foodType"
          name="type"
          onChange={handleChange}
          value={values.type}
          placeholder="Food Type"
          label="Food Type"
          onFocus={e => (e.target.placeholder = '')}
          onBlur={e => (e.target.placeholder = 'Food Type')}
          data-test="food-type"
        />
        <TextInput
          id="variety"
          name="variety"
          onChange={handleChange}
          value={values.variety}
          placeholder="Variety"
          label="Variety"
          onFocus={e => (e.target.placeholder = '')}
          onBlur={e => (e.target.placeholder = 'Variety')}
          data-test="food-variety"
        />
        <AmountContainer>
          <FractionInput
            id="amount"
            name="amount"
            data-name="amount"
            label="Amount"
            value={values.amount || 1}
            minValue="0"
            onValueChanged={handleValueChanged}
            data-test="food-amount"
          />
          <Dropdown
            width="320px"
            options={allUnits || ['']}
            label="Measure Unit"
            value={{
              value: values.measureUnit,
              label: values.measureUnit,
            }}
            onChange={value =>
              handleValueChanged('measureUnit', value.value, 'string')
            }
            data-test="food-measureUnit"
          />
        </AmountContainer>
        <FractionInput
          id="gramWeight"
          name="gramWeight"
          onValueChanged={handleValueChanged}
          value={values.gramWeight}
          label="Gram Weight"
          units="grams"
          data-test="food-gramWeight"
        />
        <HR />
        <Heading>Categories</Heading>
        <CheckContainer flexDirection="column">{categoryChecks}</CheckContainer>
        <h3>Total Nutrients</h3>
        <p>
          Nutrient values should reflect the total for the amount specified
          above.
        </p>
        <NutrientsContainer wrap="wrap" flexDirection="column">
          <FractionInput
            label="Calories"
            name="calories"
            value={values.calories}
            onValueChanged={handleValueChanged}
            units="kCal"
            data-test="food-calories"
          />
          <FractionInput
            label="Total Fat"
            name="fat"
            value={values.fat}
            onValueChanged={handleValueChanged}
            units="g"
            data-test="food-fat"
          />
          <FractionInput
            label="Saturated Fat"
            name="saturatedFat"
            value={values.saturatedFat}
            onValueChanged={handleValueChanged}
            units="g"
            data-test="food-saturatedFat"
          />

          <FractionInput
            label="Trans Fat"
            name="transFat"
            value={values.transFat}
            onValueChanged={handleValueChanged}
            units="g"
            data-test="food-transFat"
          />
          <FractionInput
            label="Cholesterol"
            name="cholesterol_mg"
            value={values.cholesterol_mg}
            onValueChanged={handleValueChanged}
            units="mg"
            data-test="food-cholesterol"
          />
          <FractionInput
            label="Sodium"
            name="sodium_mg"
            value={values.sodium_mg}
            onValueChanged={handleValueChanged}
            units="mg"
            data-test="food-sodium"
          />
          <FractionInput
            label="Carbs"
            name="carbohydrates"
            value={values.carbohydrates}
            onValueChanged={handleValueChanged}
            units="g"
            data-test="food-carbohydrates"
          />
          <FractionInput
            label="Dietary Fiber"
            name="fiber"
            value={values.fiber}
            onValueChanged={handleValueChanged}
            units="g"
            data-test="food-fiber"
          />
          <FractionInput
            label="Total Sugars"
            name="totalSugar"
            value={values.totalSugar}
            onValueChanged={handleValueChanged}
            units="g"
            data-test="food-totalSugar"
          />
          <FractionInput
            label="Added Sugars"
            name="addedSugar"
            value={values.addedSugar}
            onValueChanged={handleValueChanged}
            units="g"
            data-test="food-addedSugar"
          />
          <FractionInput
            label="Protein"
            name="proteins"
            value={values.proteins}
            onValueChanged={handleValueChanged}
            units="g"
            data-test="food-protein"
          />
          <FractionInput
            label="Vitamin D"
            name="vitaminD_mcg"
            value={values.vitaminD_mcg}
            onValueChanged={handleValueChanged}
            units="mcg"
            data-test="food-vitaminD"
          />
          <FractionInput
            label="Calcium"
            name="calcium_mg"
            value={values.calcium_mg}
            onValueChanged={handleValueChanged}
            units="mg"
            data-test="food-calcium"
          />
          <FractionInput
            label="Potassium"
            name="potassium_mg"
            value={values.potassium_mg}
            onValueChanged={handleValueChanged}
            units="mg"
            data-test="food-potassium"
          />
        </NutrientsContainer>
      </FieldSet>
      {formType !== 'view' && (
        <Button
          type="submit"
          buttonText={buttonText || 'Create Food'}
          buttonSize="large"
          disabled={loading}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
          data-test="food-submit"
        />
      )}
      {status === 'error' ? (
        <AlertStack
          messages={message}
          type="error"
          variant="filled"
          open={status === 'error'}
          handleClose={() => setStatus(null)}
          autoHideDuration={20000}
          data-test="food-message-error"
        />
      ) : status === 'success' ? (
        <AlertStack
          messages={message}
          type="success"
          variant="filled"
          open={status === 'success'}
          handleClose={() => setStatus('hidden')}
          autoHideDuration={20000}
          data-test="food-message-success"
        />
      ) : null}
      {formType === 'delete' &&
        (status === 'success' || status === 'hidden') && (
          <Button
            buttonText="Undo"
            buttonSize="small"
            handleClick={undoDelete}
            pink="true"
            data-test="food-undoDelete"
          />
        )}
    </Form>
  );
};

const Form = styled.form`
  margin-top: 30px;

  > * {
    margin-bottom: 10px;
  }
`;

const FieldSet = styled.fieldset`
  > * {
    margin-bottom: 10px;
  }
`;

const CheckContainer = styled(FlexContainer)`
  max-height: 500px;
  flex-wrap: wrap;

  > * {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

const AmountContainer = styled(FlexContainer)`
  > * {
    margin-right: 12px;
  }
`;
const NutrientsContainer = styled(FlexContainer)`
  max-height: 350px;
  > * {
    margin-bottom: 6px;
  }
`;

const HR = styled.hr`
  margin: 15px 0;
`;

const Heading = styled.h2`
  color: ${colors.primary800};
  text-align: center;
`;

const ESHAId = styled.h3`
  color: ${colors.primary500};
`;

export default FoodForm;
