import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import { formatIngredientDisplay } from '../../helpers/utils';
import { isMobile, sentenceCase } from '../../helpers/utils';
import { capitalize } from 'lodash';

const RecipeDetails = ({ recipe, recipeYield, context, ...props }) => {
  const foods = recipe.foods;
  const instructions = recipe.cookingInstructions;

  const [ingredients, setIngredients] = useState([]);

  useEffect(() => {
    if (foods) {
      const foodTotals = foods.map(food => {
        return {
          _id: food.food._id,
          name: food.food.verboseName,
          quantity: food.quantity * recipeYield,
          measureUnit: food.measureUnit,
        };
      });
      setIngredients(foodTotals);
    }
  }, [foods]);

  const ingredientsList = ingredients.map(ingredient => {
    const { quantity, measureUnit } = formatIngredientDisplay(
      ingredient.quantity,
      ingredient.measureUnit
    );

    return (
      <IngredientRow key={ingredient._id} data-test="recDetails-ingredient">
        <IngredientQuantity>{`${quantity} ${measureUnit}`}</IngredientQuantity>
        <IngredientName>{capitalize(ingredient.name)}</IngredientName>
      </IngredientRow>
    );
  });

  const instructionsList = instructions.map(instruction => {
    return (
      <InstructionsStep
        key={instruction.num}
        alignItems="center"
        data-test="recDetails-instruction"
      >
        <StepContainer>
          <StepBubble justify="center" alignItems="center">
            <StepNum data-test="recDetails-instruction-num">
              {instruction.num}
            </StepNum>
          </StepBubble>
        </StepContainer>
        <InstructionText data-test="recDetails-instruction-step">
          {sentenceCase(instruction.step)}
        </InstructionText>
      </InstructionsStep>
    );
  });

  return (
    <RecipeDetailsContainer flexDirection="column" flexShrink="0">
      <RecipeSection flexDirection="column">
        <RecipeSectionHeader justify="space-between" alignItems="center">
          <RecipeSectionName>Ingredients</RecipeSectionName>
          <RecipeHeaderNote>Based on {recipeYield} servings</RecipeHeaderNote>
        </RecipeSectionHeader>
        <IngredientsList flexDirection="column">
          {ingredientsList}
        </IngredientsList>
      </RecipeSection>
      <RecipeSection flexDirection="column">
        <RecipeSectionHeader justify="space-between" alignItems="center">
          <RecipeSectionName>Instructions</RecipeSectionName>
        </RecipeSectionHeader>
        <InstructionsList flexDirection="column">
          {instructionsList}
        </InstructionsList>
      </RecipeSection>
    </RecipeDetailsContainer>
  );
};

const RecipeDetailsContainer = styled(FlexContainer)`
  gap: 40px;
`;

const RecipeSection = styled(FlexContainer)`
  width: 100%;
`;

const RecipeSectionHeader = styled(FlexContainer)`
  width: ${props => (isMobile() ? 'calc(100% - 48px)' : 'calc(100% - 16px)')};
  margin: ${props => (isMobile() ? '0 16px' : 'initial')};
  padding: 0 8px;
  height: 45px;
  background-color: ${colors.primary050};
`;

const RecipeSectionName = styled.h3`
  color: ${colors.primary800};
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
`;
const RecipeHeaderNote = styled.h4`
  color: ${colors.primary800};
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

const IngredientsList = styled(FlexContainer)`
  gap: 28px;
  padding: ${props => (isMobile() ? '28px 16px 0 24px' : '28px 0 0 8px')};
  text-decoration: none;
`;

const IngredientRow = styled(FlexContainer)``;

const IngredientQuantity = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${colors.primary800};
  width: 120px;
  min-width: 120px;
`;

const IngredientName = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${colors.primary800};
`;

const InstructionsList = styled(FlexContainer)`
  padding: ${props => (isMobile() ? '28px 16px 0 16px' : '28px 0 0 0')};
  gap: 28px;
`;

const InstructionsStep = styled(FlexContainer)``;

const StepContainer = styled(FlexContainer)`
  min-width: 50px;
`;

const StepNum = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${colors.primary800};
`;

const StepBubble = styled(FlexContainer)`
  width: 32px;
  height: 32px;
  background-color: ${colors.primary050};
  border-radius: 16px;
`;

const InstructionText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.primary800};
`;

export default RecipeDetails;
