import React from 'react';
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import FlexContainer from '../../elements/FlexContainer';
import AddIcon from '../../icons/AddIcon';
import { colors } from '../../styleConstants';

const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

const AddGenericDialog = ({ open, setScreen, handleClose }) => {
  const handleClick = e => {
    setScreen(e.currentTarget.attributes.name.value);
  };
  return (
    <BottomDrawer anchor={'bottom'} open={open} onClose={handleClose}>
      <FlexContainer
        alignItems="center"
        justify="space-evenly"
        data-test="mobileModal-generic"
      >
        <AddOption
          flexDirection="column"
          alignItems="center"
          justify="center"
          name="Add Meal Mobile"
          onClick={handleClick}
          data-test="add-nutrition"
        >
          <img
            src={`${fileStorage}/AddFood.png`}
            alt="Add Meal"
            height="47"
            width="47"
          />
          <Label>Meals</Label>
          <AddIcon small="true" pink="true" hideLabel />
        </AddOption>
        <AddOption
          flexDirection="column"
          alignItems="center"
          justify="center"
          name="Exercise Search" // TODO: Change to "Add Fitness" when more fitness options added
          onClick={handleClick}
          data-test="add-fitness"
        >
          <img
            src={`${fileStorage}/AddExercise.png`}
            alt="Add Exercise"
            height="47"
            width="47"
          />
          <Label>Fitness</Label>
          <AddIcon small="true" pink="true" hideLabel />
        </AddOption>
      </FlexContainer>
    </BottomDrawer>
  );
};
const BottomDrawer = styled(Drawer)`
  z-index: unset !important;
  .MuiDrawer-paperAnchorBottom {
    bottom: 80px;
    box-shadow: none;
    background-color: transparent;
  }
`;
const AddOption = styled(FlexContainer)`
  height: 120px;
  width: 120px;
  border-radius: 60px;
  background-color: #fff;
`;
const Label = styled.div`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 18.72px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 24.97px;
`;

export default AddGenericDialog;
