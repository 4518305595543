import React from 'react';

const CheckmarkIcon = ({ invert }) => (
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {/* <title>Check mark</title>
    <desc>Created with Sketch.</desc>
  <defs></defs> */}
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Homepage-V2"
        transform="translate(-134.000000, -946.000000)"
        stroke="#16B8BA"
        fill="#fff"
        stroke-width="1.5"
      >
        <g id="Group-5" transform="translate(135.000000, 947.000000)">
          <g id="Check-mark">
            <circle
              id="Oval"
              cx="10"
              cy="10"
              r="10"
              stroke={invert && '#fff'}
            ></circle>
            <polyline
              id="Path-3"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="5.83333333 10.479332 8.58121606 13.3333333 15 6.66666667"
            ></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CheckmarkIcon;
