import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';
import CardsRow from '../CardsRow';
import StaticSearchBar from '../../elements/StaticSearchBar';
import MobileTabs from '../../universal/MobileTabs';
import {
  FiltersButton,
  FiltersDialog,
  AppliedFilters,
} from '../../sharedModals/mobile/MobileFilters';

const SearchMobile = ({
  searchType,
  searchTerm,
  searchQuery,
  setSearchTerm,
  handleSearch,
  showOnly,
  context,
  clearAllFilters,
  setScreen,
  exercises,
  getExercises,
  setExercise,
  pageSizeExercises,
  activities,
  getActivities,
  pageSizeActivities,
  filteredWorkouts,
  handleToggle,
  toggleValues,
  collapseToggleValues,
  handleCollapseToggle,
  workouts,
  setWorkout,
  getWorkouts,
  fitnessPlans,
  setFitnessPlan,
  getFitnessPlans,
  tracks,
  getTracks,
  setSearchType,
  setSearchTypeSelected,
  hasMoreExercises,
  hasMoreActivities,
  hasMoreWorkouts,
  ...props
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [filtersDialogOpen, setFiltersDialogOpen] = useState(false);
  const [filteredResultCount, setFilteredResultCount] = useState(0);

  const handleSearchTypeChanged = e => {
    setSearchType(
      e.currentTarget.name === 'activities'
        ? 'activity'
        : e.currentTarget.name === 'workouts'
        ? 'workout'
        : e.currentTarget.name
    );
    setSearchTypeSelected(
      e.currentTarget.name === 'activities'
        ? 'activity'
        : e.currentTarget.name === 'workouts'
        ? 'workout'
        : e.currentTarget.name
    );
  };

  const handleSearchTypeChangedBasedOnIndex = name => {
    setSearchType(name);
    setSearchTypeSelected(name);
  };
  const handleChange = (event, newValue) => {
    handleSearchTypeChanged(event);
    setSelectedTabIndex(newValue);
  };

  const handleChangeIndex = index => {
    let name = '';
    switch (index) {
      case 0:
        name = 'workout';
        break;
      case 1:
        name = 'activity';
        break;
      case 2:
        name = 'exercise';
        break;
      default:
        name = 'exercise';
    }
    handleSearchTypeChangedBasedOnIndex(name);
    setSelectedTabIndex(index);
  };

  const getTabData = tabValue => (
    <TabContainer>
      <TabHeading alignItems="center" justify="space-between">
        <Subheading>{tabValue}</Subheading>
        {(tabValue === 'Workouts' || tabValue === 'Exercises') && (
          <FiltersButton
            setFiltersDialogOpen={setFiltersDialogOpen}
            toggleValues={toggleValues}
            itemType="fitness"
          />
        )}
      </TabHeading>
      <CardContainer>
        <AppliedFilters
          toggleValues={toggleValues}
          handleToggle={handleToggle}
          itemType="fitness"
        />
        {tabValue === 'Workouts' && workoutsTab()}
        {tabValue === 'Activities' && activitiesTab()}
        {tabValue === 'Exercises' && exercisesTab()}
      </CardContainer>
    </TabContainer>
  );

  const workoutsTab = () => (
    <>
      {(searchType === 'workout' || searchType === 'both') && (
        <CardsRow
          key={`workouts-${searchQuery}-${searchType}`}
          // title="Your Recipes"
          items={filteredWorkouts}
          unfilteredLength={workouts?.length}
          setScreen={setScreen}
          setItem={setWorkout}
          wrap="wrap"
          type="Workout"
          pageSize={pageSizeActivities}
          loadMoreItems={getWorkouts}
          scrollParent={props.scrollParent}
          context={context}
          hasMore={hasMoreWorkouts}
          isMobile
          {...props}
        />
      )}
    </>
  );
  const activitiesTab = () => (
    <>
      {searchType === 'activity' && (
        <CardsRow
          key={`activities-${searchQuery}-${searchType}`}
          // title="Your Foods"
          items={activities}
          setScreen={setScreen}
          wrap="wrap"
          type="Activity"
          pageSize={pageSizeActivities}
          loadMoreItems={getActivities}
          scrollParent={props.scrollParent}
          context={context}
          hasMore={hasMoreActivities}
          isMobile
          {...props}
        />
      )}
    </>
  );

  const exercisesTab = () => (
    <>
      {searchType === 'exercise' && (
        <CardsRow
          key={`exercises-${searchQuery}-${searchType}`}
          // title="Your Foods"
          items={exercises}
          setScreen={setScreen}
          setItem={setExercise}
          wrap="wrap"
          type="Exercise"
          pageSize={pageSizeExercises}
          loadMoreItems={getExercises}
          scrollParent={props.scrollParent}
          context={context}
          hasMore={hasMoreExercises}
          isMobile
          {...props}
        />
      )}
    </>
  );

  useEffect(() => {
    if (searchType === 'workout') {
      setFilteredResultCount(filteredWorkouts.length);
    }
  }, [filtersDialogOpen, selectedTabIndex, toggleValues]);

  const tabs =
    showOnly === 'workout'
      ? ['workouts']
      : showOnly === 'activity'
      ? ['activities']
      : showOnly === 'exercise'
      ? ['exercises']
      : showOnly === 'both'
      ? ['workouts', 'activities']
      : [];
  const children =
    showOnly === 'workout'
      ? [getTabData('Workouts')]
      : showOnly === 'activity'
      ? [getTabData('Activities')]
      : showOnly === 'exercise'
      ? [getTabData('Exercises')]
      : showOnly === 'both'
      ? [getTabData('Workouts'), getTabData('Activities')]
      : [];

  return (
    <div>
      <SearchBarContainer>
        <StaticSearchBar
          handleSearch={handleSearch}
          placeholder={
            searchType === 'exercise'
              ? 'Try Push Ups, etc'
              : searchType === 'workout'
              ? 'Try HIIT Circuit, etc'
              : searchType === 'activity'
              ? 'Try Running, etc'
              : ''
          }
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          data-test="fitness-search"
        />
      </SearchBarContainer>
      <MobileTabs
        value={selectedTabIndex}
        handleChange={handleChange}
        handleChangeIndex={handleChangeIndex}
        tabs={tabs}
        children={children}
      />
      <FiltersDialog
        itemType="fitness"
        open={filtersDialogOpen}
        setOpen={setFiltersDialogOpen}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        clearAllFilters={clearAllFilters}
        handleCollapseToggle={handleCollapseToggle}
        collapseToggleValues={collapseToggleValues}
        filteredResultCount={filteredResultCount}
      />
    </div>
  );
};

const SearchBarContainer = styled.div`
  padding: 20px 20px 25px 20px;
`;

const Subheading = styled.h3`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 19px;
  letter-spacing: 0;
  line-height: 23px;
`;
const TabHeading = styled(FlexContainer)`
  padding: 25px 20px;
`;
const TabContainer = styled.div`
  overflow: hidden;
`;
const CardContainer = styled.div`
  padding: 0 20px;
`;

export default SearchMobile;
