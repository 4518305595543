import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { isMobile } from '../../helpers/utils';
import { getRecentRecipes } from '../../services/api/recipe';

import DuplicateWeb from './DuplicateWeb';
import DuplicateMobile from './DuplicateMobile';

const Duplicate = ({ setRecipe, setScreen, ...props }) => {
  const userId = props.userId;
  const today = Date().now;

  const [recipes, setRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState({
    yesterday: [],
    twoDaysAgo: [],
    threeDaysAgo: [],
    fourDaysAgo: [],
    fiveDaysAgo: [],
  });

  useEffect(() => {
    const params = {
      userId: userId,
      date: today,
    };
    getRecentRecipes(params).then(results => {
      if (results) {
        setRecipes(results);
      } else {
        // TODO: Add a "no results found" error message
        // https://app.clickup.com/t/2ngapuv
      }
    });
  }, [userId, today]);

  useEffect(() => {
    const recipeDays = {
      yesterday: [],
      twoDaysAgo: [],
      threeDaysAgo: [],
      fourDaysAgo: [],
      fiveDaysAgo: [],
    };

    recipes.forEach(recipe => {
      const utcTime = moment()
        .utc()
        .subtract(new Date().getTimezoneOffset(), 'minutes');
      if (
        moment(recipe.date)
          .utc()
          .isSame(utcTime.subtract(1, 'days'), 'days')
      ) {
        recipeDays.yesterday.push(recipe.recipe);
      } else if (
        moment(recipe.date)
          .utc()
          .isSame(utcTime.subtract(2, 'days'), 'days')
      ) {
        recipeDays.twoDaysAgo.push(recipe.recipe);
      } else if (
        moment(recipe.date)
          .utc()
          .isSame(utcTime.subtract(3, 'days'), 'days')
      ) {
        recipeDays.threeDaysAgo.push(recipe.recipe);
      } else if (
        moment(recipe.date)
          .utc()
          .isSame(utcTime.subtract(4, 'days'), 'days')
      ) {
        recipeDays.fourDaysAgo.push(recipe.recipe);
      } else if (
        moment(recipe.date)
          .utc()
          .isSame(utcTime.subtract(5, 'days'), 'days')
      ) {
        recipeDays.fiveDaysAgo.push(recipe.recipe);
      }
    });
    setFilteredRecipes(recipeDays);
  }, [recipes]);

  if (isMobile()) {
    // mobile version
    return (
      <DuplicateMobile
        filteredRecipes={filteredRecipes}
        setScreen={setScreen}
        setRecipe={setRecipe}
        {...props}
      />
    );
  } else {
    // web version
    return (
      <DuplicateWeb
        filteredRecipes={filteredRecipes}
        setScreen={setScreen}
        setRecipe={setRecipe}
        {...props}
      />
    );
  }
};

function mapStateToProps(state) {
  const { currentUser } = state;
  return { userId: currentUser.user.id };
}

export default connect(mapStateToProps)(Duplicate);
