import React, { Component } from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    // Add some default error states
    this.state = {
      error: false,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    // Something happened to one of my children.
    // Add error to state
    this.setState({
      error: error,
      info: info,
    });
  }

  render() {
    if (this.state.error) {
      // Some error was thrown. Let's display something helpful to the user
      return (
        <ErrorContainer justify="center" alignItems="center">
          {/* Fallback component will be updated later */}
          <ErrorText>Something Went Wrong...</ErrorText>
        </ErrorContainer>
      );
    }
    // No errors were thrown. As you were.
    return this.props.children;
  }
}

const ErrorContainer = styled(FlexContainer)`
  width: 100%;
  height: 100%;
`;

const ErrorText = styled.p`
  font-size: 30px;
  text-align: center;
  color: ${colors.secondary600};
`;

export default ErrorBoundary;
