import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import CardsRow from '../CardsRow';
import MobileTabs from '../../universal/MobileTabs';
import { colors } from '../../styleConstants';

const DuplicateMobile = ({
  filteredRecipes,
  setScreen,
  setRecipe,
  searchTypeSelected,
  setSearchTypeSelected,
  context,
  ...props
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleChangeIndex = index => {
    setSearchTypeSelected(headings[index].toLowerCase());
    setSelectedTabIndex(index);
  };

  const handleChange = (event, newValue) => {
    setSearchTypeSelected(event.currentTarget.name);
    setSelectedTabIndex(newValue);
  };

  const getTabsData = () => {
    const dataAvailable = Object.fromEntries(
      Object.entries(filteredRecipes).filter(ar => ar[1].length)
    );
    const tabHeadings = Object.keys(dataAvailable).map(key => {
      switch (key) {
        case 'yesterday':
          return 'Yesterday';
        case 'twoDaysAgo':
          return moment()
            .subtract(2, 'days')
            .format('dddd');
        case 'threeDaysAgo':
          return moment()
            .subtract(3, 'days')
            .format('dddd');
        case 'fourDaysAgo':
          return moment()
            .subtract(4, 'days')
            .format('dddd');
        case 'fiveDaysAgo':
          return moment()
            .subtract(5, 'days')
            .format('dddd');
        default:
          return '';
      }
    });
    const content = Object.values(dataAvailable).map(data => (
      <TabDataContainer>
        <RecipeText>Recipes</RecipeText>
        <CardsRow
          title={''}
          items={data}
          setScreen={setScreen}
          setItem={setRecipe}
          type="Recipe"
          context={context}
          isMobile
        />
      </TabDataContainer>
    ));
    return {
      headings: tabHeadings,
      content,
    };
  };
  const { headings, content } = getTabsData();
  useEffect(() => {
    const headingsInLowerCase = headings.map(h => h.toLowerCase());
    const indexAfterReturningBack = headingsInLowerCase?.indexOf(
      searchTypeSelected || 'yesterday'
    );
    setSelectedTabIndex(
      indexAfterReturningBack >= 0 ? indexAfterReturningBack : 0
    );
  }, [searchTypeSelected, headings]);
  return (
    <Container>
      <MobileTabs
        value={selectedTabIndex}
        handleChange={handleChange}
        handleChangeIndex={handleChangeIndex}
        tabs={headings}
        children={content}
      />
    </Container>
  );
};

const Container = styled.div`
  padding-top: 20px;
`;
const TabDataContainer = styled.div`
  margin: 12px 20px 0px 20px;
`;
const RecipeText = styled.h3`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 19px;
  letter-spacing: 0;
  line-height: 23px;
  margin-bottom: 17px;
`;
export default DuplicateMobile;
