export const fitnessEquipments = [
  {
    label: 'None',
    name: 'none',
  },
  {
    label: 'Free weights',
    name: 'weights',
  },
  { label: 'Weight machines', name: 'machines' },
  {
    label: 'Cardio Equipment',
    name: 'cardio',
  },
  {
    label: 'Resistance bands',
    name: 'resistance',
  },
  {
    label: 'Small hand weights',
    name: 'smallWeights',
  },
];

export const eatingPrefsMap = [
  {
    label: 'I like everything',
    value: 'Any',
    extraInfo: 'I eat a variety of meat and vegetables',
    iconSrc: `${process.env.PUBLIC_URL}/icons/EatingPrefs/Everything.png`,
  },
  // {
  //   label: 'Flexitarian',
  //   value: 'Flexitarian',
  //   extraInfo:
  //     'I eat mostly vegetarian, but spice things up with occasional meat in my diet',
  //   iconSrc: `${process.env.PUBLIC_URL}/icons/EatingPrefs/Flexitarian.png`,
  // },
  {
    label: 'Pescatarian',
    value: 'Pescatarian',
    extraInfo: 'I do not eat meat but enjoy fish and shellfish',
    iconSrc: `${process.env.PUBLIC_URL}/icons/EatingPrefs/Pescatarian.png`,
  },
  {
    label: 'Vegetarian',
    value: 'Vegetarian',
    extraInfo:
      'I do not eat meat, poultry or fish, but enjoy eggs and dairy products',
    iconSrc: `${process.env.PUBLIC_URL}/icons/EatingPrefs/Vegetarian.png`,
  },
  {
    label: 'Vegan',
    value: 'Vegan',
    extraInfo: "I do not consume any animal products, I'm 100% plant-based",
    iconSrc: `${process.env.PUBLIC_URL}/icons/EatingPrefs/Vegan.png`,
  },
];

export const foodAvoidances = [
  {
    label: 'Gluten',
    name: 'gluten',
  },
  {
    label: 'Tree Nuts',
    name: 'treeNuts',
  },
  {
    label: 'Shellfish',
    name: 'shellfish',
  },
  {
    label: 'Dairy',
    name: 'dairy',
  },
  {
    label: 'Peanuts',
    name: 'peanuts',
  },
  {
    label: 'Beef',
    name: 'beef',
  },
  {
    label: 'Soy',
    name: 'soy',
  },
  {
    label: 'Sesame',
    name: 'sesame',
  },
  {
    label: 'Pork',
    name: 'pork',
  },
  {
    label: 'Eggs',
    name: 'eggs',
  },
  {
    label: 'Fish',
    name: 'fish',
  },
];

export const alcoholOptions = [
  //sample data
  '1-3 drinks per week',
  '4-6 drinks per week',
  '7+ drinks per week',
];
