export const recipes = [
  {
    nutrients: {
      calories: 487.4114880952381,
      fat: 13.911845357142857,
      saturatedFat: 2.3520014285714286,
      transFat: 0,
      cholesterol_mg: 94.92020089285714,
      sodium_mg: 297.1799925595238,
      carbohydrates: 53.520662083333335,
      fiber: 9.767229166666667,
      totalSugar: 21.89434333333333,
      addedSugar: 0,
      proteins: 42.10476297619048,
      vitaminD_mcg: 0,
      potassium_mg: 572.218125,
      calcium_mg: 152.30083333333334,
      iron_mg: 0,
    },
    tags: {
      dietType: ['paleo'],
      mealType: ['lunch', 'dinner'],
      cookingStyle: ['cookingForTwo', 'prepAhead', 'fiveFewer', 'salad'],
      primaryIngredient: ['chicken'],
      intolerances: ['treeNut', 'wheat'],
      prepTime: ['noCook', 'min15'],
    },
    active: true,
    servingSize: 1,
    yield: 2,
    isDeleted: false,
    __t: 'RecipeUser',
    _id: '65038dd35b15ef000d16b888',
    name: 'Chicken Avocado Salad',
    prepTime: 10,
    cookingInstructions: [
      {
        _id: '6489b501da70cd000d77b81a',
        num: 1,
        step:
          'Chop and prep the red onion, carrots, apple and cooked chicken.  You can bake the chicken breast in a 425 degree oven for 20 - 25 minutes, depending on the size.',
      },
      {
        _id: '6489b501da70cd000d77b819',
        num: 2,
        step:
          'Combine all the prepped vegetables, apple and chicken in a mixing bowl with the mixed greens and a sprinkle of salt and pepper and toss.',
      },
      {
        _id: '6489b501da70cd000d77b818',
        num: 3,
        step:
          'Plate half of the salad and top with the sliced avocado, walnuts and a drizzle of the balsamic vinegar.  Serve with warm pita bread.',
      },
      {
        _id: '6489b501da70cd000d77b817',
        num: 4,
        step:
          'Store the other half of the salad in the refrigerator and then top with the avocado, walnuts and balsamic vinegar when ready to eat.',
      },
    ],
    foods: [
      {
        quantity: 5,
        _id: '6489b501da70cd000d77b816',
        measureUnit: 'Ounce',
        food: {
          categories: ['Fresh/Raw Foods', 'Poultry'],
          isDeleted: false,
          _id: '5e2710c9f38d480018055374',
          name: 'chicken breast, raw, skinless',
          verboseName: 'raw chicken breast, skinless',
          type: 'chicken breast',
          variety: '',
          amount: 1,
          measureUnit: 'Serving',
          gramWeight: 112,
          calories: 120,
          proteins: 26,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 2,
          saturatedFat: 1,
          transFat: null,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 75,
          potassium_mg: null,
          sodium_mg: 75,
          updatedAt: '2020-12-01T06:39:46.585Z',
          vitaminD_mcg: null,
          esha: '45951',
          nutrientsPerUnit: {
            calories: 120,
            proteins: 26,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 2,
            saturatedFat: 1,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 75,
            cholesterol_mg: 75,
          },
          gramWeightPerUnit: 112,
          id: '5e2710c9f38d480018055374',
        },
        name: 'chicken breast, raw, skinless',
      },
      {
        quantity: 0.625,
        _id: '6489b501da70cd000d77b815',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
          ],
          isDeleted: false,
          _id: '5e2710c5f38d48001805257b',
          name: 'onion, red, fresh, chopped',
          verboseName: 'fresh red onion, chopped',
          type: 'onion',
          variety: '',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 80,
          calories: 32,
          proteins: 0.88,
          carbohydrates: 7.472,
          fiber: 1.36,
          totalSugar: 3.392,
          addedSugar: 0,
          fat: 0.08,
          saturatedFat: 0.0336,
          transFat: 0,
          __v: 0,
          calcium_mg: 18.4,
          cholesterol_mg: 0,
          potassium_mg: 116.8,
          sodium_mg: 3.2,
          updatedAt: '2020-12-01T06:39:10.673Z',
          vitaminD_mcg: 0,
          esha: '7498',
          nutrientsPerUnit: {
            calories: 64,
            proteins: 1.76,
            carbohydrates: 14.944,
            fiber: 2.72,
            totalSugar: 6.784,
            addedSugar: 0,
            fat: 0.16,
            saturatedFat: 0.0672,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 36.8,
            iron_mg: null,
            potassium_mg: 233.6,
            sodium_mg: 6.4,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 160,
          id: '5e2710c5f38d48001805257b',
        },
        name: 'onion, red, fresh, chopped',
      },
      {
        quantity: 0.625,
        _id: '6489b501da70cd000d77b814',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
          ],
          isDeleted: false,
          _id: '5e2710c5f38d4800180524be',
          name: 'carrots, fresh, chopped',
          verboseName: 'fresh carrots, chopped',
          type: 'carrots',
          variety: '',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 64,
          calories: 26.24,
          proteins: 0.5952,
          carbohydrates: 6.1312,
          fiber: 1.792,
          totalSugar: 3.0336,
          addedSugar: 0,
          fat: 0.1536,
          saturatedFat: 0.02368,
          transFat: 0,
          __v: 0,
          calcium_mg: 21.12,
          cholesterol_mg: 0,
          potassium_mg: 204.8,
          sodium_mg: 44.16,
          updatedAt: '2020-12-01T06:39:10.673Z',
          vitaminD_mcg: 0,
          esha: '6772',
          nutrientsPerUnit: {
            calories: 52.48,
            proteins: 1.1904,
            carbohydrates: 12.2624,
            fiber: 3.584,
            totalSugar: 6.0672,
            addedSugar: 0,
            fat: 0.3072,
            saturatedFat: 0.04736,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 42.24,
            iron_mg: null,
            potassium_mg: 409.6,
            sodium_mg: 88.32,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 128,
          id: '5e2710c5f38d4800180524be',
        },
        name: 'carrots, fresh, chopped',
      },
      {
        quantity: 0.625,
        _id: '6489b501da70cd000d77b813',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fruits',
            'Fruits',
          ],
          isDeleted: false,
          _id: '5e2710c6f38d480018052bfa',
          name: 'apple, fresh, chopped',
          verboseName: 'fresh apple, chopped',
          type: 'apple',
          variety: '',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 62.5,
          calories: 32.5,
          proteins: 0.1625,
          carbohydrates: 8.63125,
          fiber: 1.5,
          totalSugar: 6.49375,
          addedSugar: 0,
          fat: 0.10625,
          saturatedFat: 0.0175,
          transFat: 0,
          __v: 0,
          calcium_mg: 3.75,
          cholesterol_mg: 0,
          potassium_mg: 66.875,
          sodium_mg: 0.625,
          updatedAt: '2020-12-01T06:39:17.848Z',
          vitaminD_mcg: 0,
          esha: '14920',
          nutrientsPerUnit: {
            calories: 65,
            proteins: 0.325,
            carbohydrates: 17.2625,
            fiber: 3,
            totalSugar: 12.9875,
            addedSugar: 0,
            fat: 0.2125,
            saturatedFat: 0.035,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 7.5,
            iron_mg: null,
            potassium_mg: 133.75,
            sodium_mg: 1.25,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 125,
          id: '5e2710c6f38d480018052bfa',
        },
        name: 'apple, fresh, chopped',
      },
      {
        quantity: 2,
        _id: '6489b501da70cd000d77b812',
        measureUnit: 'Cup',
        food: {
          categories: ['Fresh/Raw Foods', 'Vegetables'],
          isDeleted: false,
          _id: '5e2710ccf38d48001805700a',
          name: 'salad mix, field greens',
          verboseName: 'field greens salad mix',
          type: 'salad mix',
          variety: '',
          amount: 1.5,
          measureUnit: 'Cup',
          gramWeight: 85,
          calories: 20,
          proteins: 1,
          carbohydrates: 4,
          fiber: 2,
          totalSugar: 1,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 40,
          cholesterol_mg: 0,
          potassium_mg: 0,
          sodium_mg: 20,
          updatedAt: '2020-12-01T06:40:07.417Z',
          vitaminD_mcg: null,
          esha: '69197',
          nutrientsPerUnit: {
            calories: 13.333333333333334,
            proteins: 0.6666666666666666,
            carbohydrates: 2.6666666666666665,
            fiber: 1.3333333333333333,
            totalSugar: 0.6666666666666666,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 26.666666666666668,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 13.333333333333334,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 56.666666666666664,
          id: '5e2710ccf38d48001805700a',
        },
        name: 'salad mix, field greens',
      },
      {
        quantity: 0.125,
        _id: '6489b501da70cd000d77b811',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Dried Foods',
            'Dried Foods',
            'Dried Foods',
            'Dried Foods',
            'Dried Foods',
            'Nuts & Seeds',
            'Nuts & Seeds',
            'Nuts & Seeds',
            'Nuts & Seeds',
            'Nuts & Seeds',
          ],
          isDeleted: false,
          _id: '5e2710c5f38d4800180521f5',
          name: 'walnuts, english, dried, chopped',
          verboseName: 'dried english walnuts, chopped',
          type: 'walnuts',
          variety: 'english',
          amount: 0.25,
          measureUnit: 'Cup',
          gramWeight: 29.25,
          calories: 191.295,
          proteins: 4.45477,
          carbohydrates: 4.01017,
          fiber: 1.95975,
          totalSugar: 0.76342,
          addedSugar: 0,
          fat: 19.07393,
          saturatedFat: 1.79185,
          transFat: 0,
          __v: 0,
          calcium_mg: 28.665,
          cholesterol_mg: 0,
          potassium_mg: 128.9925,
          sodium_mg: 0.585,
          updatedAt: '2020-12-01T06:39:10.672Z',
          vitaminD_mcg: 0,
          esha: '4556',
          nutrientsPerUnit: {
            calories: 765.18,
            proteins: 17.81908,
            carbohydrates: 16.04068,
            fiber: 7.839,
            totalSugar: 3.05368,
            addedSugar: 0,
            fat: 76.29572,
            saturatedFat: 7.1674,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 114.66,
            iron_mg: null,
            potassium_mg: 515.97,
            sodium_mg: 2.34,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 117,
          id: '5e2710c5f38d4800180521f5',
        },
        name: 'walnuts, english, dried, chopped',
      },
      {
        quantity: 0.5,
        _id: '6489b501da70cd000d77b810',
        measureUnit: 'Tablespoon',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Vegetables',
            'Vegetables',
            'Vegetables',
          ],
          isDeleted: false,
          _id: '5e2710c4f38d480018052134',
          name: 'avocado, fresh, sliced',
          verboseName: 'fresh avocado, sliced',
          type: 'avocado',
          variety: '',
          amount: 0.25,
          measureUnit: 'Cup',
          gramWeight: 36.5,
          calories: 58.4,
          proteins: 0.73,
          carbohydrates: 3.11345,
          fiber: 2.4455,
          totalSugar: 0.2409,
          addedSugar: 0,
          fat: 5.3509,
          saturatedFat: 0.77599,
          transFat: 0,
          __v: 0,
          calcium_mg: 4.38,
          cholesterol_mg: 0,
          potassium_mg: 177.025,
          sodium_mg: 2.555,
          updatedAt: '2020-12-01T06:38:57.458Z',
          vitaminD_mcg: 0,
          esha: '3658',
          nutrientsPerUnit: {
            calories: 233.6,
            proteins: 2.92,
            carbohydrates: 12.4538,
            fiber: 9.782,
            totalSugar: 0.9636,
            addedSugar: 0,
            fat: 21.4036,
            saturatedFat: 3.10396,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 17.52,
            iron_mg: null,
            potassium_mg: 708.1,
            sodium_mg: 10.22,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 146,
          id: '5e2710c4f38d480018052134',
        },
        name: 'avocado, fresh, sliced',
      },
      {
        quantity: 1.5,
        _id: '6489b501da70cd000d77b80f',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Condiments & Sauces'],
          isDeleted: false,
          _id: '5e2710d5f38d48001805db46',
          name: 'vinegar, balsamic',
          verboseName: 'balsamic vinegar',
          type: 'vinegar',
          variety: 'balsamic',
          amount: 1,
          measureUnit: 'Tablespoon',
          gramWeight: 16,
          calories: 15,
          proteins: 0,
          carbohydrates: 3,
          fiber: 0,
          totalSugar: 2,
          addedSugar: null,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: null,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 0,
          updatedAt: '2020-12-01T06:41:06.330Z',
          vitaminD_mcg: null,
          esha: '135405',
          nutrientsPerUnit: {
            calories: 15,
            proteins: 0,
            carbohydrates: 3,
            fiber: 0,
            totalSugar: 2,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 0,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 16,
          id: '5e2710d5f38d48001805db46',
        },
        name: 'vinegar, balsamic',
      },
      {
        quantity: 0.5,
        _id: '6489b501da70cd000d77b80e',
        measureUnit: 'Each',
        food: {
          categories: ['Bread & Bakery'],
          isDeleted: false,
          _id: '5e2710cbf38d480018056bc0',
          name: 'bread, pita, 100% whole wheat, Sahara, pocket',
          verboseName: 'Sahara 100% whole wheat pita bread, pocket',
          type: 'bread',
          variety: 'pita',
          amount: 1,
          measureUnit: 'Each',
          gramWeight: 57,
          calories: 140,
          proteins: 7,
          carbohydrates: 27,
          fiber: null,
          totalSugar: 2,
          addedSugar: null,
          fat: 1.5,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 60,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 230,
          updatedAt: '2020-12-01T06:40:02.928Z',
          vitaminD_mcg: null,
          esha: '66695',
          nutrientsPerUnit: {
            calories: 140,
            proteins: 7,
            carbohydrates: 27,
            fiber: 0,
            totalSugar: 2,
            addedSugar: 0,
            fat: 1.5,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 60,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 230,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 57,
          id: '5e2710cbf38d480018056bc0',
        },
        name: 'bread, pita, 100% whole wheat, Sahara, pocket',
      },
    ],
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1616161314892',
    updatedAt: '2023-09-14T22:48:51.334Z',
    recipeAdmin: '60212f30121da2000db724c8',
    user: '5e541600583a5d000da11e29',
    createdAt: '2023-09-14T22:48:51.334Z',
    __v: 0,
    custom: false,
    id: '65038dd35b15ef000d16b888',
  },
  {
    nutrients: {
      calories: 517.46,
      fat: 17.159200000000002,
      saturatedFat: 7.50345,
      transFat: 0.009000000000000001,
      cholesterol_mg: 32.5,
      sodium_mg: 210.12,
      carbohydrates: 55.098866666666666,
      fiber: 4.45,
      totalSugar: 34.1463,
      addedSugar: 12.7844,
      proteins: 41.102866666666664,
      vitaminD_mcg: 0,
      potassium_mg: 654.76,
      calcium_mg: 379.9133333333333,
      iron_mg: 0,
    },
    tags: {
      dietType: ['pescatarian', 'vegetarian'],
      mealType: ['breakfast', 'snack'],
      cookingStyle: ['prepAhead', 'toGo', 'fiveFewer'],
      primaryIngredient: ['fruit', 'grain'],
      intolerances: ['dairy', 'grains', 'peanut'],
      prepTime: ['min15', 'noCook'],
    },
    version: 'B',
    active: true,
    servingSize: 1,
    yield: 2,
    isDeleted: false,
    __t: 'RecipeAdmin',
    _id: '643261b5f88aae000d6ba182',
    name: 'Fruity Peanut Butter Yogurt Parfait',
    prepTime: 5,
    cookTime: 0,
    cookingInstructions: [
      {
        _id: '6496e36032bfe5000dee9412',
        num: 1,
        step:
          'Build the yogurt parfaits in 2 separate containers (for 2 servings).  Combine the oats, peanut butter, and maple syrup and mix into a thick sauce.    ',
      },
      {
        _id: '6496e36032bfe5000dee9411',
        num: 2,
        step:
          'Start by mixing the yogurt and protein powder in a separate bowl, adding a small amount of water to get desired consistency, if needed.    ',
      },
      {
        _id: '6496e36032bfe5000dee9410',
        num: 3,
        step:
          'Start with a half base of the mixed yogurt, then top with half of the sliced kiwi, blueberries, and cherries.    ',
      },
      {
        _id: '6496e36032bfe5000dee940f',
        num: 4,
        step:
          'Build the last layer on top with the other half of the mixed yogurt, then the rest of the fruit and the peanut butter oat sauce.    ',
      },
      {
        _id: '6496e36032bfe5000dee940e',
        num: 5,
        step: 'Store in the refrigerator covered for up to 2 days.  ',
      },
    ],
    foods: [
      {
        quantity: 1,
        _id: '6496e36032bfe5000dee940d',
        measureUnit: 'Cup',
        food: {
          categories: ['Dairy Products & Subs'],
          isDeleted: false,
          _id: '5e2710cbf38d480018056f3e',
          name: 'yogurt, greek, plain, traditional',
          verboseName: 'plain greek yogurt, traditional',
          type: 'yogurt',
          variety: 'greek',
          amount: 1,
          measureUnit: 'Cup',
          gramWeight: 225,
          calories: 190,
          proteins: 20,
          carbohydrates: 9,
          fiber: 0,
          totalSugar: 9,
          addedSugar: 0,
          fat: 9,
          saturatedFat: 6,
          transFat: 0,
          calcium_mg: 250,
          cholesterol_mg: 30,
          potassium_mg: 240,
          sodium_mg: 75,
          updatedAt: '2020-12-01T06:40:04.936Z',
          vitaminD_mcg: null,
          esha: '68169',
          nutrientsPerUnit: {
            calories: 190,
            proteins: 20,
            carbohydrates: 9,
            fiber: 0,
            totalSugar: 9,
            addedSugar: 0,
            fat: 9,
            saturatedFat: 6,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 250,
            iron_mg: null,
            potassium_mg: 240,
            sodium_mg: 75,
            cholesterol_mg: 30,
          },
          gramWeightPerUnit: 225,
          id: '5e2710cbf38d480018056f3e',
        },
        name: 'yogurt, greek, plain, traditional',
      },
      {
        quantity: 0.5,
        _id: '6496e36032bfe5000dee940c',
        measureUnit: 'Scoop',
        food: {
          categories: ['Vitamins & Supplements'],
          isDeleted: false,
          _id: '5e2710d4f38d48001805ca60',
          name: 'protein powder, vanilla, 100% whey isolate',
          verboseName: 'vanilla protein powder, 100% whey isolate',
          type: 'protein powder',
          variety: '',
          amount: 1,
          measureUnit: 'Scoop',
          gramWeight: 35,
          calories: 130,
          proteins: 30,
          carbohydrates: 2,
          fiber: 0,
          totalSugar: 1,
          addedSugar: null,
          fat: 0.5,
          saturatedFat: 0,
          transFat: 0,
          calcium_mg: 150,
          cholesterol_mg: 5,
          potassium_mg: 180,
          sodium_mg: 160,
          updatedAt: '2020-12-01T06:40:58.422Z',
          vitaminD_mcg: null,
          esha: '109913',
          nutrientsPerUnit: {
            calories: 130,
            proteins: 30,
            carbohydrates: 2,
            fiber: 0,
            totalSugar: 1,
            addedSugar: 0,
            fat: 0.5,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 150,
            iron_mg: null,
            potassium_mg: 180,
            sodium_mg: 160,
            cholesterol_mg: 5,
          },
          gramWeightPerUnit: 35,
          id: '5e2710d4f38d48001805ca60',
        },
        name: 'protein powder, vanilla, 100% whey isolate',
      },
      {
        quantity: 0.25,
        _id: '6496e36032bfe5000dee940b',
        measureUnit: 'Cup',
        food: {
          categories: ['Baking Ingredients', 'Breakfast Foods'],
          isDeleted: false,
          _id: '5e2710c8f38d48001805498f',
          name: 'oats, rolled, old fashioned, dry',
          verboseName: 'dry old fashioned rolled oats',
          type: 'oats',
          variety: 'rolled',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 40,
          calories: 150,
          proteins: 5,
          carbohydrates: 27,
          fiber: 4,
          totalSugar: 1,
          addedSugar: 0,
          fat: 3,
          saturatedFat: 0.5,
          transFat: 0,
          calcium_mg: 20,
          cholesterol_mg: 0,
          potassium_mg: 150,
          sodium_mg: 0,
          updatedAt: '2020-12-01T06:39:42.469Z',
          vitaminD_mcg: 0,
          esha: '38017',
          nutrientsPerUnit: {
            calories: 300,
            proteins: 10,
            carbohydrates: 54,
            fiber: 8,
            totalSugar: 2,
            addedSugar: 0,
            fat: 6,
            saturatedFat: 1,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 40,
            iron_mg: null,
            potassium_mg: 300,
            sodium_mg: 0,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 80,
          id: '5e2710c8f38d48001805498f',
        },
        name: 'oats, rolled, old fashioned, dry',
      },
      {
        quantity: 0.75,
        _id: '6496e36032bfe5000dee940a',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Nuts & Seeds'],
          isDeleted: false,
          _id: '5e2710c5f38d48001805221f',
          name: 'peanut butter, creamy',
          verboseName: 'creamy peanut butter',
          type: 'peanut butter',
          variety: 'creamy',
          amount: 2,
          measureUnit: 'Tablespoon',
          gramWeight: 32,
          calories: 191.36,
          proteins: 7.1072,
          carbohydrates: 7.1392,
          fiber: 1.6,
          totalSugar: 3.3568,
          addedSugar: 1.8464,
          fat: 16.4352,
          saturatedFat: 3.304,
          transFat: 0.024,
          calcium_mg: 15.68,
          cholesterol_mg: 0,
          potassium_mg: 178.56,
          sodium_mg: 136.32,
          updatedAt: '2020-12-01T06:39:10.672Z',
          vitaminD_mcg: 0,
          esha: '4627',
          nutrientsPerUnit: {
            calories: 95.68,
            proteins: 3.5536,
            carbohydrates: 3.5696,
            fiber: 0.8,
            totalSugar: 1.6784,
            addedSugar: 0.9232,
            fat: 8.2176,
            saturatedFat: 1.652,
            transFat: 0.012,
            vitaminD_mcg: 0,
            calcium_mg: 7.84,
            iron_mg: null,
            potassium_mg: 89.28,
            sodium_mg: 68.16,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 16,
          id: '5e2710c5f38d48001805221f',
        },
        name: 'peanut butter, creamy',
      },
      {
        quantity: 1,
        _id: '6496e36032bfe5000dee9409',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Sweeteners'],
          isDeleted: false,
          _id: '5e2710c7f38d48001805392c',
          name: 'syrup, maple',
          verboseName: 'maple syrup',
          type: 'syrup',
          variety: 'maple',
          amount: 0.25,
          measureUnit: 'Cup',
          gramWeight: 80,
          calories: 208,
          proteins: 0.032,
          carbohydrates: 53.632,
          fiber: 0,
          totalSugar: 48.368,
          addedSugar: 48.368,
          fat: 0.048,
          saturatedFat: 0.0056,
          transFat: 0,
          calcium_mg: 81.6,
          cholesterol_mg: 0,
          potassium_mg: 169.6,
          sodium_mg: 9.6,
          updatedAt: '2020-12-01T06:39:25.682Z',
          vitaminD_mcg: 0,
          esha: '25002',
          nutrientsPerUnit: {
            calories: 832,
            proteins: 0.128,
            carbohydrates: 214.528,
            fiber: 0,
            totalSugar: 193.472,
            addedSugar: 193.472,
            fat: 0.192,
            saturatedFat: 0.0224,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 326.4,
            iron_mg: null,
            potassium_mg: 678.4,
            sodium_mg: 38.4,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 320,
          id: '5e2710c7f38d48001805392c',
        },
        name: 'syrup, maple',
      },
      {
        quantity: 0.25,
        _id: '6496e36032bfe5000dee9408',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fruits',
            'Fruits',
            'Fruits',
          ],
          isDeleted: false,
          _id: '5e2710c7f38d480018053bc4',
          name: 'kiwi, fresh, sliced',
          verboseName: 'fresh kiwi, sliced',
          type: 'kiwi',
          variety: '',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 90,
          calories: 54.9,
          proteins: 1.026,
          carbohydrates: 13.194,
          fiber: 2.7,
          totalSugar: 8.091,
          addedSugar: 0,
          fat: 0.468,
          saturatedFat: 0.0261,
          transFat: 0,
          calcium_mg: 30.6,
          cholesterol_mg: 0,
          potassium_mg: 280.8,
          sodium_mg: 2.7,
          updatedAt: '2020-12-01T06:39:27.702Z',
          vitaminD_mcg: 0,
          esha: '27502',
          nutrientsPerUnit: {
            calories: 109.8,
            proteins: 2.052,
            carbohydrates: 26.388,
            fiber: 5.4,
            totalSugar: 16.182,
            addedSugar: 0,
            fat: 0.936,
            saturatedFat: 0.0522,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 61.2,
            iron_mg: null,
            potassium_mg: 561.6,
            sodium_mg: 5.4,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 180,
          id: '5e2710c7f38d480018053bc4',
        },
        name: 'kiwi, fresh, sliced',
      },
      {
        quantity: 0.25,
        _id: '6496e36032bfe5000dee9407',
        measureUnit: 'Cup',
        food: {
          categories: ['Fresh/Raw Foods', 'Fruits'],
          isDeleted: false,
          _id: '5e2710c9f38d480018054eb5',
          name: 'blueberries, fresh',
          verboseName: 'fresh blueberries',
          type: 'blueberries',
          variety: '',
          amount: 1,
          measureUnit: 'Cup',
          gramWeight: 148,
          calories: 85,
          proteins: 1,
          carbohydrates: 21,
          fiber: 0,
          totalSugar: 15,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          calcium_mg: 0,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 1,
          updatedAt: '2020-12-01T06:39:44.603Z',
          vitaminD_mcg: null,
          esha: '41954',
          nutrientsPerUnit: {
            calories: 85,
            proteins: 1,
            carbohydrates: 21,
            fiber: 0,
            totalSugar: 15,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 1,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 148,
          id: '5e2710c9f38d480018054eb5',
        },
        name: 'blueberries, fresh',
      },
      {
        quantity: 0.16666666666666666,
        _id: '6496e36032bfe5000dee9406',
        measureUnit: 'Cup',
        food: {
          categories: ['Fresh/Raw Foods', 'Fruits'],
          isDeleted: false,
          _id: '5e2710cdf38d4800180580b7',
          name: 'cherries, fresh',
          verboseName: 'fresh cherries',
          type: 'cherries',
          variety: '',
          amount: 1,
          measureUnit: 'Cup',
          gramWeight: 140,
          calories: 90,
          proteins: 1,
          carbohydrates: 22,
          fiber: 3,
          totalSugar: 18,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          calcium_mg: 20,
          cholesterol_mg: 0,
          potassium_mg: 0,
          sodium_mg: 0,
          updatedAt: '2020-12-01T06:40:14.962Z',
          vitaminD_mcg: null,
          esha: '78326',
          nutrientsPerUnit: {
            calories: 90,
            proteins: 1,
            carbohydrates: 22,
            fiber: 3,
            totalSugar: 18,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 20,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 0,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 140,
          id: '5e2710cdf38d4800180580b7',
        },
        name: 'cherries, fresh',
      },
    ],
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687578363976',
    updatedAt: '2023-06-24T12:36:48.263Z',
    score: 3,
    id: '643261b5f88aae000d6ba182',
  },
  {
    nutrients: {
      calories: 719.963125,
      fat: 16.42201875,
      saturatedFat: 2.1289437500000004,
      transFat: 0.0012656249999999998,
      cholesterol_mg: 121.905,
      sodium_mg: 957.609375,
      carbohydrates: 71.45393125,
      fiber: 4.6981874999999995,
      totalSugar: 4.87235625,
      addedSugar: 0,
      proteins: 73.98780624999999,
      vitaminD_mcg: 2.5515,
      potassium_mg: 2248.3181250000002,
      calcium_mg: 332.19312499999995,
      iron_mg: 0,
    },
    tags: {
      dietType: ['paleo', 'pescatarian'],
      mealType: ['lunch', 'dinner'],
      cookingStyle: ['fiveFewer'],
      primaryIngredient: ['fruit'],
      intolerances: ['fish', 'treeNut'],
      prepTime: ['min30'],
    },
    version: 'B',
    active: true,
    servingSize: 1,
    yield: 2,
    isDeleted: false,
    __t: 'RecipeAdmin',
    _id: '6273c52ec19d2f000df78d45',
    name: 'Lemon & Almond Crusted Cod with Beans and Wilted Greens',
    prepTime: 5,
    cookTime: 20,
    cookingInstructions: [
      {
        _id: '6273c52ec19d2f000df78d4a',
        num: 1,
        step:
          'Season the cod filets with a sprinkle of salt and pepper, zest and juice the lemon, and drain/rinse the cannellini beans.',
      },
      {
        _id: '6273c52ec19d2f000df78d49',
        num: 2,
        step:
          'Begin by pan cooking the fish with a drizzle of olive oil for 4 minutes per side, until cooked through.  Set aside and keep warm.    ',
      },
      {
        _id: '6273c52ec19d2f000df78d48',
        num: 3,
        step:
          'Next add the slivered almonds, panko, and lemon zest to the pan with a sprinkle of salt and toast for 1 minute, being careful not to burn then set aside.  This is the toasted topping for the fish.    ',
      },
      {
        _id: '6273c52ec19d2f000df78d47',
        num: 4,
        step:
          'Now add the cherry tomatoes, garlic, and thyme with a sprinkle of salt and pepper and sauté for 4 minutes, until the tomatoes are blistered but before they pop.  Then add the beans and vegetable stock with a sprinkle of salt and pepper and simmer for 5 minutes.  At the end, add the arugula, turn off the heat and let the arugula wilt slightly.    ',
      },
      {
        _id: '6273c52ec19d2f000df78d46',
        num: 5,
        step:
          'When ready to eat, plate the fish on top of the beans and vegetables and sprinkle over the toasted topping for the fish.  ',
      },
    ],
    foods: [
      {
        quantity: 10,
        _id: '6279d1c6f7db01000df307ea',
        measureUnit: 'Ounce-weight',
        food: {
          categories: ['Fresh/Raw Foods', 'Fish & Seafood'],
          isDeleted: false,
          _id: '5e2710c6f38d480018052e48',
          name: 'cod, atlantic, raw, fillet',
          verboseName: 'raw atlantic cod, fillet',
          type: 'cod',
          variety: 'atlantic',
          amount: 4,
          measureUnit: 'Ounce-weight',
          gramWeight: 113.4,
          calories: 92.988,
          proteins: 20.19654,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 0.75978,
          saturatedFat: 0.14855,
          transFat: null,
          __v: 0,
          calcium_mg: 18.144,
          cholesterol_mg: 48.762,
          potassium_mg: 468.342,
          sodium_mg: 61.236,
          updatedAt: '2020-12-01T06:39:21.561Z',
          vitaminD_mcg: 1.0206,
          esha: '17036',
          nutrientsPerUnit: {
            calories: 23.247,
            proteins: 5.049135,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 0.189945,
            saturatedFat: 0.0371375,
            transFat: 0,
            vitaminD_mcg: 0.25515,
            calcium_mg: 4.536,
            iron_mg: null,
            potassium_mg: 117.0855,
            sodium_mg: 15.309,
            cholesterol_mg: 12.1905,
          },
          gramWeightPerUnit: 28.35,
          id: '5e2710c6f38d480018052e48',
        },
        name: 'cod, atlantic, raw, fillet',
      },
      {
        quantity: 2,
        _id: '6279d1c6f7db01000df307e9',
        measureUnit: 'Each',
        food: {
          categories: ['Fresh/Raw Foods', 'Vegetables'],
          isDeleted: false,
          _id: '5e2710c7f38d480018053a46',
          name: 'garlic cloves, fresh',
          verboseName: 'fresh garlic cloves',
          type: 'garlic cloves',
          variety: '',
          amount: 1,
          measureUnit: 'Each',
          gramWeight: 3,
          calories: 4.47,
          proteins: 0.1908,
          carbohydrates: 0.9918,
          fiber: 0.063,
          totalSugar: 0.03,
          addedSugar: 0,
          fat: 0.015,
          saturatedFat: 0.00267,
          transFat: 0,
          __v: 0,
          calcium_mg: 5.43,
          cholesterol_mg: 0,
          potassium_mg: 12.03,
          sodium_mg: 0.51,
          updatedAt: '2020-12-01T06:39:27.702Z',
          vitaminD_mcg: 0,
          esha: '26005',
          nutrientsPerUnit: {
            calories: 4.47,
            proteins: 0.1908,
            carbohydrates: 0.9918,
            fiber: 0.063,
            totalSugar: 0.03,
            addedSugar: 0,
            fat: 0.015,
            saturatedFat: 0.00267,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 5.43,
            iron_mg: null,
            potassium_mg: 12.03,
            sodium_mg: 0.51,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 3,
          id: '5e2710c7f38d480018053a46',
        },
        name: 'garlic cloves, fresh',
      },
      {
        quantity: 0.75,
        _id: '6279d1c6f7db01000df307e8',
        measureUnit: 'Teaspoon',
        food: {
          categories: ['Condiments & Sauces', 'Condiments & Sauces'],
          isDeleted: false,
          _id: '5e2710c6f38d480018052c6e',
          name: 'thyme, dried, leaves',
          verboseName: 'dried thyme, leaves',
          type: 'thyme',
          variety: '',
          amount: 1,
          measureUnit: 'Teaspoon',
          gramWeight: 1,
          calories: 2.76,
          proteins: 0.0911,
          carbohydrates: 0.6394,
          fiber: 0.37,
          totalSugar: 0.0171,
          addedSugar: 0,
          fat: 0.0743,
          saturatedFat: 0.0273,
          transFat: 0,
          __v: 0,
          calcium_mg: 18.9,
          cholesterol_mg: 0,
          potassium_mg: 8.14,
          sodium_mg: 0.55,
          updatedAt: '2020-12-01T06:39:17.848Z',
          vitaminD_mcg: 0,
          esha: '15401',
          nutrientsPerUnit: {
            calories: 2.76,
            proteins: 0.0911,
            carbohydrates: 0.6394,
            fiber: 0.37,
            totalSugar: 0.0171,
            addedSugar: 0,
            fat: 0.0743,
            saturatedFat: 0.0273,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 18.9,
            iron_mg: null,
            potassium_mg: 8.14,
            sodium_mg: 0.55,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 1,
          id: '5e2710c6f38d480018052c6e',
        },
        name: 'thyme, dried, leaves',
      },
      {
        quantity: 0.75,
        _id: '6279d1c6f7db01000df307e7',
        measureUnit: 'Cup',
        food: {
          categories: ['Fresh/Raw Foods', 'Vegetables'],
          isDeleted: false,
          _id: '5e2710caf38d480018055f78',
          name: 'tomatoes, cherry, mixed, fresh, chopped',
          verboseName: 'fresh mixed cherry tomatoes, chopped',
          type: 'tomatoes',
          variety: 'cherry',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 74.5,
          calories: 15,
          proteins: 1,
          carbohydrates: 3,
          fiber: 1,
          totalSugar: 1,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 0,
          potassium_mg: 170,
          sodium_mg: 5,
          updatedAt: '2020-12-01T06:39:54.453Z',
          vitaminD_mcg: null,
          esha: '55694',
          nutrientsPerUnit: {
            calories: 30,
            proteins: 2,
            carbohydrates: 6,
            fiber: 2,
            totalSugar: 2,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 340,
            sodium_mg: 10,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 149,
          id: '5e2710caf38d480018055f78',
        },
        name: 'tomatoes, cherry, mixed, fresh, chopped',
      },
      {
        quantity: 1.25,
        _id: '6279d1c6f7db01000df307e6',
        measureUnit: 'Cup',
        food: {
          categories: ['Canned Foods', 'Legumes'],
          isDeleted: false,
          _id: '5e2710d6f38d48001805ea06',
          name: 'cannellini beans, canned',
          verboseName: 'canned cannellini beans',
          type: 'cannellini beans',
          variety: '',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 130,
          calories: 100,
          proteins: 7,
          carbohydrates: 19,
          fiber: null,
          totalSugar: 0,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 80,
          cholesterol_mg: 0,
          potassium_mg: 270,
          sodium_mg: 270,
          updatedAt: '2020-12-01T06:41:13.142Z',
          vitaminD_mcg: null,
          esha: '145854',
          nutrientsPerUnit: {
            calories: 200,
            proteins: 14,
            carbohydrates: 38,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 160,
            iron_mg: null,
            potassium_mg: 540,
            sodium_mg: 540,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 260,
          id: '5e2710d6f38d48001805ea06',
        },
        name: 'cannellini beans, canned',
      },
      {
        quantity: 0.375,
        _id: '6279d1c6f7db01000df307e5',
        measureUnit: 'Cup',
        food: {
          categories: ['Canned Foods', 'Dishes & Prepared Foods', 'Vegetables'],
          isDeleted: false,
          _id: '5e2710d1f38d48001805a8b8',
          name: 'stock, vegetable',
          verboseName: 'vegetable stock',
          type: 'stock',
          variety: 'vegetable',
          amount: 1,
          measureUnit: 'Cup',
          gramWeight: 240,
          calories: 15,
          proteins: 0,
          carbohydrates: 4,
          fiber: null,
          totalSugar: 3,
          addedSugar: null,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 260,
          updatedAt: '2020-12-01T06:40:36.490Z',
          vitaminD_mcg: null,
          esha: '97980',
          nutrientsPerUnit: {
            calories: 15,
            proteins: 0,
            carbohydrates: 4,
            fiber: 0,
            totalSugar: 3,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 260,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 240,
          id: '5e2710d1f38d48001805a8b8',
        },
        name: 'stock, vegetable',
      },
      {
        quantity: 0.75,
        _id: '6279d1c6f7db01000df307e4',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Vegetables',
            'Vegetables',
            'Vegetables',
          ],
          isDeleted: false,
          _id: '5e2710d6f38d48001805e7ea',
          name: 'arugula, fresh',
          verboseName: 'fresh arugula',
          type: 'arugula',
          variety: '',
          amount: 1,
          measureUnit: 'Cup',
          gramWeight: 20,
          calories: 5,
          proteins: 0.516,
          carbohydrates: 0.73,
          fiber: 0.32,
          totalSugar: 0.41,
          addedSugar: 0,
          fat: 0.132,
          saturatedFat: 0.0172,
          transFat: 0,
          __v: 0,
          calcium_mg: 32,
          cholesterol_mg: 0,
          potassium_mg: 73.8,
          sodium_mg: 5.4,
          updatedAt: '2020-12-01T06:41:11.333Z',
          vitaminD_mcg: 0,
          esha: '145221',
          nutrientsPerUnit: {
            calories: 5,
            proteins: 0.516,
            carbohydrates: 0.73,
            fiber: 0.32,
            totalSugar: 0.41,
            addedSugar: 0,
            fat: 0.132,
            saturatedFat: 0.0172,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 32,
            iron_mg: null,
            potassium_mg: 73.8,
            sodium_mg: 5.4,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 20,
          id: '5e2710d6f38d48001805e7ea',
        },
        name: 'arugula, fresh',
      },
      {
        quantity: 0.75,
        _id: '6279d1c6f7db01000df307e3',
        measureUnit: 'Each',
        food: {
          categories: ['Fresh/Raw Foods', 'Fruits'],
          isDeleted: false,
          _id: '5e2710caf38d48001805620b',
          name: 'lemon, fresh, organic',
          verboseName: 'fresh lemon, organic',
          type: 'lemon',
          variety: '',
          amount: 1,
          measureUnit: 'Each',
          gramWeight: 84,
          calories: 25,
          proteins: 1,
          carbohydrates: 6,
          fiber: 2,
          totalSugar: 2,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 20,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 0,
          updatedAt: '2020-12-01T06:39:56.450Z',
          vitaminD_mcg: null,
          esha: '58714',
          nutrientsPerUnit: {
            calories: 25,
            proteins: 1,
            carbohydrates: 6,
            fiber: 2,
            totalSugar: 2,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 20,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 0,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 84,
          id: '5e2710caf38d48001805620b',
        },
        name: 'lemon, fresh, organic',
      },
      {
        quantity: 1.25,
        _id: '6279d1c6f7db01000df307e2',
        measureUnit: 'Tablespoon',
        food: {
          categories: [
            'Nuts & Seeds',
            'Nuts & Seeds',
            'Nuts & Seeds',
            'Nuts & Seeds',
          ],
          isDeleted: false,
          _id: '5e2710c5f38d4800180521d0',
          name: 'almonds, slivered',
          verboseName: 'almonds, slivered',
          type: 'almonds',
          variety: '',
          amount: 0.25,
          measureUnit: 'Cup',
          gramWeight: 27,
          calories: 156.33,
          proteins: 5.7105,
          carbohydrates: 5.8185,
          fiber: 3.375,
          totalSugar: 1.1745,
          addedSugar: 0,
          fat: 13.4811,
          saturatedFat: 1.02654,
          transFat: 0.00405,
          __v: 0,
          calcium_mg: 72.63,
          cholesterol_mg: 0,
          potassium_mg: 197.91,
          sodium_mg: 0.27,
          updatedAt: '2020-12-01T06:39:10.672Z',
          vitaminD_mcg: 0,
          esha: '4503',
          nutrientsPerUnit: {
            calories: 625.32,
            proteins: 22.842,
            carbohydrates: 23.274,
            fiber: 13.5,
            totalSugar: 4.698,
            addedSugar: 0,
            fat: 53.9244,
            saturatedFat: 4.10616,
            transFat: 0.0162,
            vitaminD_mcg: 0,
            calcium_mg: 290.52,
            iron_mg: null,
            potassium_mg: 791.64,
            sodium_mg: 1.08,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 108,
          id: '5e2710c5f38d4800180521d0',
        },
        name: 'almonds, slivered',
      },
      {
        quantity: 1.5,
        _id: '6279d1c6f7db01000df307e1',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Baking Ingredients'],
          isDeleted: false,
          _id: '5e2710d2f38d48001805b5bc',
          name: 'bread crumbs, panko, original',
          verboseName: 'original panko bread crumbs',
          type: 'bread crumbs',
          variety: 'panko',
          amount: 0.25,
          measureUnit: 'Cup',
          gramWeight: 30,
          calories: 100,
          proteins: 3,
          carbohydrates: 23,
          fiber: null,
          totalSugar: 0,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 50,
          updatedAt: '2020-12-01T06:40:42.333Z',
          vitaminD_mcg: 0,
          esha: '102135',
          nutrientsPerUnit: {
            calories: 400,
            proteins: 12,
            carbohydrates: 92,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 200,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 120,
          id: '5e2710d2f38d48001805b5bc',
        },
        name: 'bread crumbs, panko, original',
      },
      {
        quantity: 0.75,
        _id: '6279d1c6f7db01000df307e0',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Fats Oils & Subs'],
          isDeleted: false,
          _id: '5e2710c5f38d4800180525ec',
          name: 'oil, olive',
          verboseName: 'olive oil',
          type: 'oil',
          variety: 'olive',
          amount: 1,
          measureUnit: 'Tablespoon',
          gramWeight: 13.5,
          calories: 119.34,
          proteins: 0,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 13.5,
          saturatedFat: 1.86408,
          transFat: null,
          __v: 0,
          calcium_mg: 0.135,
          cholesterol_mg: 0,
          potassium_mg: 0.135,
          sodium_mg: 0.27,
          updatedAt: '2020-12-01T06:39:14.244Z',
          vitaminD_mcg: 0,
          esha: '8008',
          nutrientsPerUnit: {
            calories: 119.34,
            proteins: 0,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 13.5,
            saturatedFat: 1.86408,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0.135,
            iron_mg: null,
            potassium_mg: 0.135,
            sodium_mg: 0.27,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 13.5,
          id: '5e2710c5f38d4800180525ec',
        },
        name: 'oil, olive',
      },
    ],
    __v: 0,
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1652150726139',
    id: '6273c52ec19d2f000df78d45',
  },
  {
    nutrients: {
      calories: 502.3808333333333,
      fat: 15.311986666666668,
      saturatedFat: 5.453936666666666,
      transFat: 0.1727,
      cholesterol_mg: 281.145,
      sodium_mg: 641.7254166666667,
      carbohydrates: 45.07554666666667,
      fiber: 8.434,
      totalSugar: 23.0688,
      addedSugar: 0,
      proteins: 41.742920000000005,
      vitaminD_mcg: 3.738,
      potassium_mg: 1084.3302083333333,
      calcium_mg: 207.40354166666665,
      iron_mg: 0,
    },
    tags: {
      dietType: ['ovoVegetarian', 'paleo', 'pescatarian', 'vegetarian'],
      mealType: ['breakfast'],
      cookingStyle: ['prepAhead', 'toGo', 'fiveFewer', 'prepFreeze'],
      primaryIngredient: ['egg'],
      intolerances: ['egg'],
      prepTime: ['min30'],
    },
    version: 'B',
    active: true,
    servingSize: 1,
    yield: 2,
    isDeleted: false,
    __t: 'RecipeAdmin',
    _id: '62659cdde19428000d2aa36c',
    name: 'Broccoli Cheddar Quiche',
    prepTime: 5,
    cookTime: 25,
    cookingInstructions: [
      {
        _id: '6425376f8e38c6000da64430',
        num: 1,
        step:
          'Preheat the oven to 425 degrees, then prep and finely chop the broccoli.    ',
      },
      {
        _id: '6425376f8e38c6000da6442f',
        num: 2,
        step:
          'Crack the eggs and pour the egg whites into a separate bowl along with the cheese, almond milk, finely chopped broccoli and a sprinkle of salt and pepper.    ',
      },
      {
        _id: '6425376f8e38c6000da6442e',
        num: 3,
        step:
          'Spray or swipe olive oil into a baking dish then pour in the egg and vegetable mixture.    ',
      },
      {
        _id: '6425376f8e38c6000da6442d',
        num: 4,
        step:
          'Bake for 25 minutes or until the eggs are firm in the center and serve with fresh sliced strawberries and banana.  ',
      },
    ],
    foods: [
      {
        quantity: 1,
        _id: '6425376f8e38c6000da6442c',
        measureUnit: 'Cup',
        food: {
          categories: ['Fresh/Raw Foods', 'Vegetables'],
          isDeleted: false,
          _id: '5e2710c5f38d4800180524b8',
          name: 'broccoli, fresh',
          verboseName: 'fresh broccoli',
          type: 'broccoli',
          variety: '',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 45.5,
          calories: 15.47,
          proteins: 1.2831,
          carbohydrates: 3.0212,
          fiber: 1.183,
          totalSugar: 0.7735,
          addedSugar: 0,
          fat: 0.16835,
          saturatedFat: 0.01774,
          transFat: 0,
          __v: 0,
          calcium_mg: 21.385,
          cholesterol_mg: 0,
          potassium_mg: 143.78,
          sodium_mg: 15.015,
          updatedAt: '2020-12-01T06:39:10.673Z',
          vitaminD_mcg: 0,
          esha: '6757',
          nutrientsPerUnit: {
            calories: 30.94,
            proteins: 2.5662,
            carbohydrates: 6.0424,
            fiber: 2.366,
            totalSugar: 1.547,
            addedSugar: 0,
            fat: 0.3367,
            saturatedFat: 0.03548,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 42.77,
            iron_mg: null,
            potassium_mg: 287.56,
            sodium_mg: 30.03,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 91,
          id: '5e2710c5f38d4800180524b8',
        },
        name: 'broccoli, fresh',
      },
      {
        quantity: 1.5,
        _id: '6425376f8e38c6000da6442b',
        measureUnit: 'Large',
        food: {
          categories: [
            'Egg Products & Subs',
            'Egg Products & Subs',
            'Egg Products & Subs',
            'Egg Products & Subs',
          ],
          isDeleted: false,
          _id: '5e2710c6f38d4800180534a6',
          name: 'egg, raw, large',
          verboseName: 'raw egg, large',
          type: 'egg',
          variety: '',
          amount: 1,
          measureUnit: 'Large',
          gramWeight: 50,
          calories: 70,
          proteins: 6,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 4,
          saturatedFat: 1,
          transFat: 0,
          __v: 0,
          calcium_mg: null,
          cholesterol_mg: 175,
          potassium_mg: null,
          sodium_mg: 60,
          updatedAt: '2020-12-01T06:39:23.677Z',
          vitaminD_mcg: 2,
          esha: '21111',
          nutrientsPerUnit: {
            calories: 70,
            proteins: 6,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 4,
            saturatedFat: 1,
            transFat: 0,
            vitaminD_mcg: 2,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 60,
            cholesterol_mg: 175,
          },
          gramWeightPerUnit: 50,
          id: '5e2710c6f38d4800180534a6',
        },
        name: 'egg, raw, large',
      },
      {
        quantity: 0.875,
        _id: '6425376f8e38c6000da6442a',
        measureUnit: 'Cup',
        food: {
          categories: ['Egg Products & Subs'],
          isDeleted: false,
          _id: '5e2710c6f38d4800180532ae',
          name: 'egg white, liquid',
          verboseName: 'liquid egg white',
          type: 'egg white',
          variety: '',
          amount: 3,
          measureUnit: 'Tablespoon',
          gramWeight: 46,
          calories: 25,
          proteins: 5,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 0,
          potassium_mg: 75,
          sodium_mg: 75,
          updatedAt: '2020-12-01T06:39:23.676Z',
          vitaminD_mcg: null,
          esha: '19584',
          nutrientsPerUnit: {
            calories: 8.333333333333334,
            proteins: 1.6666666666666667,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 25,
            sodium_mg: 25,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 15.333333333333334,
          id: '5e2710c6f38d4800180532ae',
        },
        name: 'egg white, liquid',
      },
      {
        quantity: 0.16666666666666666,
        _id: '6425376f8e38c6000da64429',
        measureUnit: 'Cup',
        food: {
          categories: ['Dairy Products & Subs', 'Dairy Products & Subs'],
          isDeleted: false,
          _id: '5e2710c4f38d480018051ead',
          name: 'cheese, cheddar, shredded',
          verboseName: 'cheddar cheese, shredded',
          type: 'cheese',
          variety: 'cheddar',
          amount: 0.25,
          measureUnit: 'Cup',
          gramWeight: 28.25,
          calories: 114.13,
          proteins: 6.46078,
          carbohydrates: 0.87292,
          fiber: 0,
          totalSugar: 0.1356,
          addedSugar: 0,
          fat: 9.41008,
          saturatedFat: 5.32993,
          transFat: 0.25905,
          __v: 0,
          calcium_mg: 200.575,
          cholesterol_mg: 27.9675,
          potassium_mg: 21.47,
          sodium_mg: 184.4725,
          updatedAt: '2020-12-01T06:38:57.457Z',
          vitaminD_mcg: 0.1695,
          esha: '1008',
          nutrientsPerUnit: {
            calories: 456.52,
            proteins: 25.84312,
            carbohydrates: 3.49168,
            fiber: 0,
            totalSugar: 0.5424,
            addedSugar: 0,
            fat: 37.64032,
            saturatedFat: 21.31972,
            transFat: 1.0362,
            vitaminD_mcg: 0.678,
            calcium_mg: 802.3,
            iron_mg: null,
            potassium_mg: 85.88,
            sodium_mg: 737.89,
            cholesterol_mg: 111.87,
          },
          gramWeightPerUnit: 113,
          id: '5e2710c4f38d480018051ead',
        },
        name: 'cheese, cheddar, shredded',
      },
      {
        quantity: 0.25,
        _id: '6425376f8e38c6000da64428',
        measureUnit: 'Cup',
        food: {
          categories: ['Beverages', 'Canned Foods', 'Dairy Products & Subs'],
          isDeleted: false,
          _id: '5e2710d4f38d48001805d051',
          name: 'almond milk, original, unsweetened',
          verboseName: 'original almond milk, unsweetened',
          type: 'almond milk',
          variety: '',
          amount: 1,
          measureUnit: 'Cup',
          gramWeight: 245,
          calories: 35,
          proteins: 1,
          carbohydrates: 2,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 2.5,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 20,
          cholesterol_mg: 0,
          potassium_mg: 40,
          sodium_mg: 190,
          updatedAt: '2020-12-01T06:41:00.636Z',
          vitaminD_mcg: 2.5,
          esha: '112111',
          nutrientsPerUnit: {
            calories: 35,
            proteins: 1,
            carbohydrates: 2,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 2.5,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 2.5,
            calcium_mg: 20,
            iron_mg: null,
            potassium_mg: 40,
            sodium_mg: 190,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 245,
          id: '5e2710d4f38d48001805d051',
        },
        name: 'almond milk, original, unsweetened',
      },
      {
        quantity: 1,
        _id: '6425376f8e38c6000da64427',
        measureUnit: 'Cup',
        food: {
          categories: ['Fresh/Raw Foods', 'Fruits'],
          isDeleted: false,
          _id: '5e2710ccf38d480018057384',
          name: 'strawberries, fresh',
          verboseName: 'fresh strawberries',
          type: 'strawberries',
          variety: '',
          amount: 1,
          measureUnit: 'Cup',
          gramWeight: 147,
          calories: 45,
          proteins: 1,
          carbohydrates: 11,
          fiber: 3,
          totalSugar: 7,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 20,
          cholesterol_mg: 0,
          potassium_mg: 0,
          sodium_mg: 0,
          updatedAt: '2020-12-01T06:40:07.418Z',
          vitaminD_mcg: null,
          esha: '71988',
          nutrientsPerUnit: {
            calories: 45,
            proteins: 1,
            carbohydrates: 11,
            fiber: 3,
            totalSugar: 7,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 20,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 0,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 147,
          id: '5e2710ccf38d480018057384',
        },
        name: 'strawberries, fresh',
      },
      {
        quantity: 1,
        _id: '6425376f8e38c6000da64426',
        measureUnit: 'Each',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fruits',
            'Fruits',
            'Fruits',
            'Fruits',
            'Fruits',
            'Fruits',
            'Fruits',
            'Fruits',
          ],
          isDeleted: false,
          _id: '5e2710c4f38d480018052060',
          name: 'banana, fresh, medium, 7" to 7 7/8"',
          verboseName: 'fresh banana, medium',
          type: 'banana',
          variety: '',
          amount: 1,
          measureUnit: 'Each',
          gramWeight: 118,
          calories: 105.02,
          proteins: 1.2862,
          carbohydrates: 26.9512,
          fiber: 3.068,
          totalSugar: 14.4314,
          addedSugar: 0,
          fat: 0.3894,
          saturatedFat: 0.13216,
          transFat: 0,
          __v: 0,
          calcium_mg: 5.9,
          cholesterol_mg: 0,
          potassium_mg: 422.44,
          sodium_mg: 1.18,
          updatedAt: '2020-12-01T06:38:57.458Z',
          vitaminD_mcg: 0,
          esha: '3020',
          nutrientsPerUnit: {
            calories: 105.02,
            proteins: 1.2862,
            carbohydrates: 26.9512,
            fiber: 3.068,
            totalSugar: 14.4314,
            addedSugar: 0,
            fat: 0.3894,
            saturatedFat: 0.13216,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 5.9,
            iron_mg: null,
            potassium_mg: 422.44,
            sodium_mg: 1.18,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 118,
          id: '5e2710c4f38d480018052060',
        },
        name: 'banana, fresh, medium, 7" to 7 7/8"',
      },
      {
        quantity: 0.125,
        _id: '6425376f8e38c6000da64425',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Fats Oils & Subs'],
          isDeleted: false,
          _id: '5e2710c5f38d4800180525ec',
          name: 'oil, olive',
          verboseName: 'olive oil',
          type: 'oil',
          variety: 'olive',
          amount: 1,
          measureUnit: 'Tablespoon',
          gramWeight: 13.5,
          calories: 119.34,
          proteins: 0,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 13.5,
          saturatedFat: 1.86408,
          transFat: null,
          __v: 0,
          calcium_mg: 0.135,
          cholesterol_mg: 0,
          potassium_mg: 0.135,
          sodium_mg: 0.27,
          updatedAt: '2020-12-01T06:39:14.244Z',
          vitaminD_mcg: 0,
          esha: '8008',
          nutrientsPerUnit: {
            calories: 119.34,
            proteins: 0,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 13.5,
            saturatedFat: 1.86408,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0.135,
            iron_mg: null,
            potassium_mg: 0.135,
            sodium_mg: 0.27,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 13.5,
          id: '5e2710c5f38d4800180525ec',
        },
        name: 'oil, olive',
      },
    ],
    __v: 0,
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1651766309735',
    id: '62659cdde19428000d2aa36c',
  },
  {
    nutrients: {
      calories: 317.44237562189056,
      fat: 9.6875,
      saturatedFat: 2.23301,
      transFat: 0,
      cholesterol_mg: 350,
      sodium_mg: 252.78250621890547,
      carbohydrates: 31.41044776119403,
      fiber: 6.4054726368159205,
      totalSugar: 8.619402985074627,
      addedSugar: 0,
      proteins: 21.405472636815922,
      vitaminD_mcg: 4,
      potassium_mg: 395.09150186567166,
      calcium_mg: 46.68354166666667,
      iron_mg: 0,
    },
    tags: {
      dietType: [
        'lactoVegetarian',
        'ovoVegetarian',
        'paleo',
        'pescatarian',
        'vegetarian',
      ],
      mealType: ['breakfast'],
      cookingStyle: ['prepAhead', 'toGo', 'fiveFewer', 'prepFreeze'],
      primaryIngredient: ['egg'],
      intolerances: ['egg'],
      prepTime: ['min30'],
    },
    version: 'A',
    active: true,
    servingSize: 1,
    yield: 2,
    isDeleted: false,
    __t: 'RecipeAdmin',
    _id: '6265a28c5a0391000d43183a',
    name: 'Baked Veggie Omelet',
    prepTime: 5,
    cookTime: 25,
    cookingInstructions: [
      {
        _id: '6425387f193c86000d3d64ed',
        num: 1,
        step:
          'Preheat the oven to 425 degrees, prep and chop the vegetables, and defrost the hashbrowns in the microwave for a minute to knock off the chill.    ',
      },
      {
        _id: '6425387f193c86000d3d64ec',
        num: 2,
        step:
          'Spray or swipe olive oil into a baking dish then crack the eggs and pour the egg whites into the baking dish and whisk together.     ',
      },
      {
        _id: '6425387f193c86000d3d64eb',
        num: 3,
        step:
          'Mix in the hashbrowns, spinach, tomato, and a sprinkle of salt and pepper.    ',
      },
      {
        _id: '6425387f193c86000d3d64ea',
        num: 4,
        step:
          'Bake for 25 minutes, until the eggs are firm and serve with fresh sliced strawberries.  ',
      },
    ],
    foods: [
      {
        quantity: 0.25,
        _id: '6425387f193c86000d3d64e9',
        measureUnit: 'Cup',
        food: {
          categories: ['Fresh/Raw Foods', 'Vegetables'],
          isDeleted: false,
          _id: '5e2710caf38d480018055f78',
          name: 'tomatoes, cherry, mixed, fresh, chopped',
          verboseName: 'fresh mixed cherry tomatoes, chopped',
          type: 'tomatoes',
          variety: 'cherry',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 74.5,
          calories: 15,
          proteins: 1,
          carbohydrates: 3,
          fiber: 1,
          totalSugar: 1,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 0,
          potassium_mg: 170,
          sodium_mg: 5,
          updatedAt: '2020-12-01T06:39:54.453Z',
          vitaminD_mcg: null,
          esha: '55694',
          nutrientsPerUnit: {
            calories: 30,
            proteins: 2,
            carbohydrates: 6,
            fiber: 2,
            totalSugar: 2,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 340,
            sodium_mg: 10,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 149,
          id: '5e2710caf38d480018055f78',
        },
        name: 'tomatoes, cherry, mixed, fresh, chopped',
      },
      {
        quantity: 0.75,
        _id: '6425387f193c86000d3d64e8',
        measureUnit: 'Cup',
        food: {
          categories: ['Dishes & Prepared Foods', 'Frozen Foods', 'Vegetables'],
          isDeleted: false,
          _id: '5e2710ccf38d480018057295',
          name: 'hash browns, frozen, diced',
          verboseName: 'frozen hash browns, diced',
          type: 'hash browns',
          variety: '',
          amount: 0.67,
          measureUnit: 'Cup',
          gramWeight: 85,
          calories: 70,
          proteins: 2,
          carbohydrates: 16,
          fiber: 2,
          totalSugar: 1,
          addedSugar: null,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 0,
          potassium_mg: 210,
          sodium_mg: 30,
          updatedAt: '2020-12-01T06:40:07.418Z',
          vitaminD_mcg: null,
          esha: '70611',
          nutrientsPerUnit: {
            calories: 104.4776119402985,
            proteins: 2.9850746268656714,
            carbohydrates: 23.88059701492537,
            fiber: 2.9850746268656714,
            totalSugar: 1.4925373134328357,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 313.4328358208955,
            sodium_mg: 44.776119402985074,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 126.86567164179104,
          id: '5e2710ccf38d480018057295',
        },
        name: 'hash browns, frozen, diced',
      },
      {
        quantity: 0.5,
        _id: '6425387f193c86000d3d64e7',
        measureUnit: 'Cup',
        food: {
          categories: ['Fresh/Raw Foods', 'Vegetables'],
          isDeleted: false,
          _id: '5e2710c5f38d4800180526ee',
          name: 'spinach, baby, fresh',
          verboseName: 'fresh baby spinach',
          type: 'spinach',
          variety: '',
          amount: 1.5,
          measureUnit: 'Cup',
          gramWeight: 85,
          calories: 20,
          proteins: 2,
          carbohydrates: 3,
          fiber: 2,
          totalSugar: 0,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 80,
          cholesterol_mg: 0,
          potassium_mg: 0,
          sodium_mg: 65,
          updatedAt: '2020-12-01T06:39:14.245Z',
          vitaminD_mcg: null,
          esha: '9317',
          nutrientsPerUnit: {
            calories: 13.333333333333334,
            proteins: 1.3333333333333333,
            carbohydrates: 2,
            fiber: 1.3333333333333333,
            totalSugar: 0,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 53.333333333333336,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 43.333333333333336,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 56.666666666666664,
          id: '5e2710c5f38d4800180526ee',
        },
        name: 'spinach, baby, fresh',
      },
      {
        quantity: 2,
        _id: '6425387f193c86000d3d64e6',
        measureUnit: 'Large',
        food: {
          categories: [
            'Egg Products & Subs',
            'Egg Products & Subs',
            'Egg Products & Subs',
            'Egg Products & Subs',
          ],
          isDeleted: false,
          _id: '5e2710c6f38d4800180534a6',
          name: 'egg, raw, large',
          verboseName: 'raw egg, large',
          type: 'egg',
          variety: '',
          amount: 1,
          measureUnit: 'Large',
          gramWeight: 50,
          calories: 70,
          proteins: 6,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 4,
          saturatedFat: 1,
          transFat: 0,
          __v: 0,
          calcium_mg: null,
          cholesterol_mg: 175,
          potassium_mg: null,
          sodium_mg: 60,
          updatedAt: '2020-12-01T06:39:23.677Z',
          vitaminD_mcg: 2,
          esha: '21111',
          nutrientsPerUnit: {
            calories: 70,
            proteins: 6,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 4,
            saturatedFat: 1,
            transFat: 0,
            vitaminD_mcg: 2,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 60,
            cholesterol_mg: 175,
          },
          gramWeightPerUnit: 50,
          id: '5e2710c6f38d4800180534a6',
        },
        name: 'egg, raw, large',
      },
      {
        quantity: 3,
        _id: '6425387f193c86000d3d64e5',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Egg Products & Subs'],
          isDeleted: false,
          _id: '5e2710c6f38d4800180532ae',
          name: 'egg white, liquid',
          verboseName: 'liquid egg white',
          type: 'egg white',
          variety: '',
          amount: 3,
          measureUnit: 'Tablespoon',
          gramWeight: 46,
          calories: 25,
          proteins: 5,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 0,
          potassium_mg: 75,
          sodium_mg: 75,
          updatedAt: '2020-12-01T06:39:23.676Z',
          vitaminD_mcg: null,
          esha: '19584',
          nutrientsPerUnit: {
            calories: 8.333333333333334,
            proteins: 1.6666666666666667,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 25,
            sodium_mg: 25,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 15.333333333333334,
          id: '5e2710c6f38d4800180532ae',
        },
        name: 'egg white, liquid',
      },
      {
        quantity: 1,
        _id: '6425387f193c86000d3d64e4',
        measureUnit: 'Cup',
        food: {
          categories: ['Fresh/Raw Foods', 'Fruits'],
          isDeleted: false,
          _id: '5e2710ccf38d480018057384',
          name: 'strawberries, fresh',
          verboseName: 'fresh strawberries',
          type: 'strawberries',
          variety: '',
          amount: 1,
          measureUnit: 'Cup',
          gramWeight: 147,
          calories: 45,
          proteins: 1,
          carbohydrates: 11,
          fiber: 3,
          totalSugar: 7,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 20,
          cholesterol_mg: 0,
          potassium_mg: 0,
          sodium_mg: 0,
          updatedAt: '2020-12-01T06:40:07.418Z',
          vitaminD_mcg: null,
          esha: '71988',
          nutrientsPerUnit: {
            calories: 45,
            proteins: 1,
            carbohydrates: 11,
            fiber: 3,
            totalSugar: 7,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 20,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 0,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 147,
          id: '5e2710ccf38d480018057384',
        },
        name: 'strawberries, fresh',
      },
      {
        quantity: 0.125,
        _id: '6425387f193c86000d3d64e3',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Fats Oils & Subs'],
          isDeleted: false,
          _id: '5e2710c5f38d4800180525ec',
          name: 'oil, olive',
          verboseName: 'olive oil',
          type: 'oil',
          variety: 'olive',
          amount: 1,
          measureUnit: 'Tablespoon',
          gramWeight: 13.5,
          calories: 119.34,
          proteins: 0,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 13.5,
          saturatedFat: 1.86408,
          transFat: null,
          __v: 0,
          calcium_mg: 0.135,
          cholesterol_mg: 0,
          potassium_mg: 0.135,
          sodium_mg: 0.27,
          updatedAt: '2020-12-01T06:39:14.244Z',
          vitaminD_mcg: 0,
          esha: '8008',
          nutrientsPerUnit: {
            calories: 119.34,
            proteins: 0,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 13.5,
            saturatedFat: 1.86408,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0.135,
            iron_mg: null,
            potassium_mg: 0.135,
            sodium_mg: 0.27,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 13.5,
          id: '5e2710c5f38d4800180525ec',
        },
        name: 'oil, olive',
      },
    ],
    __v: 0,
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1651766580004',
    id: '6265a28c5a0391000d43183a',
  },
  {
    nutrients: {
      calories: 688.1464444444445,
      fat: 17.239003544973546,
      saturatedFat: 2.82221,
      transFat: 0,
      cholesterol_mg: 96.25,
      sodium_mg: 157.91000000000003,
      carbohydrates: 72.00935888888888,
      fiber: 2.50932,
      totalSugar: 6.043687089947089,
      addedSugar: 0,
      proteins: 60.173304814814806,
      vitaminD_mcg: 0,
      potassium_mg: 235.611,
      calcium_mg: 71.097,
      iron_mg: 0,
    },
    tags: {
      dietType: [],
      mealType: ['lunch', 'dinner'],
      cookingStyle: ['cookingForTwo', 'fiveFewer', 'prepAhead', 'prepFreeze'],
      primaryIngredient: ['turkey', 'pasta'],
      intolerances: ['gluten'],
      prepTime: ['min30'],
    },
    version: 'B',
    active: true,
    servingSize: 1,
    yield: 2,
    isDeleted: false,
    __t: 'RecipeAdmin',
    _id: '601d5ace95e128000df65533',
    cookTime: 25,
    name: 'Spicy Turkey Pasta',
    prepTime: 10,
    cookingInstructions: [
      {
        _id: '601d5a8395e128000df6552a',
        num: 1,
        step:
          'Sauté the ground turkey in a pan over medium heat with a sprinkle of salt and pepper and a drizzle of olive oil for 15 minutes.  When the ground turkey is browned up, take out of the pan and set aside.',
      },
      {
        _id: '601d5a8395e128000df65529',
        num: 2,
        step:
          'Chop and prep the onion and broccoli rabe.  Bring a pot of water to a boil, sprinkle in some salt and cook the pasta for about 8 minutes, until slightly al dente.  Reserve some of the pasta water, then drain and drizzle with some olive oil to keep moist.',
      },
      {
        _id: '601d5a8395e128000df65528',
        num: 3,
        step:
          'Sauté the onion and broccoli rabe over medium heat with the garlic, crushed red pepper, a sprinkle of salt and pepper and a drizzle of olive oil for 5 minutes.  Add in the kale, cooked turkey and the pasta and sauté for another 3 minutes until the kale is slightly wilted.  Add some of the reserved pasta water, if needed, to keep the pan moist.',
      },
      {
        _id: '601d5a8395e128000df65527',
        num: 4,
        step:
          'Plate the pasta in bowls and sprinkle with some crushed red pepper.',
      },
    ],
    foods: [
      {
        quantity: 7,
        _id: '604f5f1756ce7f000d9e6f26',
        measureUnit: 'Ounce-weight',
        food: {
          categories: ['Fresh/Raw Foods', 'Poultry'],
          isDeleted: false,
          _id: '5e2710ccf38d480018057030',
          name: 'turkey breast, ground, raw',
          verboseName: 'raw ground turkey breast',
          type: 'turkey breast',
          variety: '',
          amount: 4,
          measureUnit: 'Ounce-weight',
          gramWeight: 113.4,
          calories: 120,
          proteins: 27,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 1,
          saturatedFat: 0.5,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 55,
          potassium_mg: null,
          sodium_mg: 80,
          updatedAt: '2020-12-01T06:40:07.417Z',
          vitaminD_mcg: null,
          esha: '69328',
          nutrientsPerUnit: {
            calories: 30,
            proteins: 6.75,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 0.25,
            saturatedFat: 0.125,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 20,
            cholesterol_mg: 13.75,
          },
          gramWeightPerUnit: 28.35,
          id: '5e2710ccf38d480018057030',
        },
        name: 'turkey breast, ground, raw',
      },
      {
        quantity: 0.375,
        _id: '604f5f1756ce7f000d9e6f25',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
          ],
          isDeleted: false,
          _id: '5e2710c5f38d48001805257c',
          name: 'onion, yellow, fresh, chopped',
          verboseName: 'fresh yellow onion, chopped',
          type: 'onion',
          variety: '',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 80,
          calories: 32,
          proteins: 0.88,
          carbohydrates: 7.472,
          fiber: 1.36,
          totalSugar: 3.392,
          addedSugar: 0,
          fat: 0.08,
          saturatedFat: 0.0336,
          transFat: 0,
          __v: 0,
          calcium_mg: 18.4,
          cholesterol_mg: 0,
          potassium_mg: 116.8,
          sodium_mg: 3.2,
          updatedAt: '2020-12-01T06:39:10.673Z',
          vitaminD_mcg: 0,
          esha: '7499',
          nutrientsPerUnit: {
            calories: 64,
            proteins: 1.76,
            carbohydrates: 14.944,
            fiber: 2.72,
            totalSugar: 6.784,
            addedSugar: 0,
            fat: 0.16,
            saturatedFat: 0.0672,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 36.8,
            iron_mg: null,
            potassium_mg: 233.6,
            sodium_mg: 6.4,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 160,
          id: '5e2710c5f38d48001805257c',
        },
        name: 'onion, yellow, fresh, chopped',
      },
      {
        quantity: 0.75,
        _id: '604f5f1756ce7f000d9e6f24',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Vegetables',
            'Vegetables',
            'Vegetables',
          ],
          isDeleted: false,
          _id: '5e2710cdf38d480018058176',
          name: 'broccoli rabe, fresh, chopped',
          verboseName: 'fresh broccoli rabe, chopped',
          type: 'broccoli rabe',
          variety: '',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 20,
          calories: 4.4,
          proteins: 0.634,
          carbohydrates: 0.57,
          fiber: 0.49968,
          totalSugar: 0.07032,
          addedSugar: 0,
          fat: 0.098,
          saturatedFat: 0.01,
          transFat: 0,
          __v: 0,
          calcium_mg: 21.6,
          cholesterol_mg: 0,
          potassium_mg: 39.2,
          sodium_mg: 6.6,
          updatedAt: '2020-12-01T06:40:14.962Z',
          vitaminD_mcg: 0,
          esha: '79111',
          nutrientsPerUnit: {
            calories: 8.8,
            proteins: 1.268,
            carbohydrates: 1.14,
            fiber: 0.99936,
            totalSugar: 0.14064,
            addedSugar: 0,
            fat: 0.196,
            saturatedFat: 0.02,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 43.2,
            iron_mg: null,
            potassium_mg: 78.4,
            sodium_mg: 13.2,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 40,
          id: '5e2710cdf38d480018058176',
        },
        name: 'broccoli rabe, fresh, chopped',
      },
      {
        quantity: 0.75,
        _id: '604f5f1756ce7f000d9e6f23',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Vegetables',
            'Vegetables',
          ],
          isDeleted: false,
          _id: '5e2710c5f38d480018052331',
          name: 'kale, fresh, chopped',
          verboseName: 'fresh kale, chopped',
          type: 'kale',
          variety: '',
          amount: 1,
          measureUnit: 'Cup',
          gramWeight: 16,
          calories: 7.84,
          proteins: 0.6848,
          carbohydrates: 1.4,
          fiber: 0.576,
          totalSugar: 0.3616,
          addedSugar: 0,
          fat: 0.1488,
          saturatedFat: 0.01456,
          transFat: 0,
          __v: 0,
          calcium_mg: 24,
          cholesterol_mg: 0,
          potassium_mg: 78.56,
          sodium_mg: 6.08,
          updatedAt: '2020-12-01T06:39:10.673Z',
          vitaminD_mcg: 0,
          esha: '5208',
          nutrientsPerUnit: {
            calories: 7.84,
            proteins: 0.6848,
            carbohydrates: 1.4,
            fiber: 0.576,
            totalSugar: 0.3616,
            addedSugar: 0,
            fat: 0.1488,
            saturatedFat: 0.01456,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 24,
            iron_mg: null,
            potassium_mg: 78.56,
            sodium_mg: 6.08,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 16,
          id: '5e2710c5f38d480018052331',
        },
        name: 'kale, fresh, chopped',
      },
      {
        quantity: 1,
        _id: '604f5f1756ce7f000d9e6f22',
        measureUnit: 'Each',
        food: {
          categories: ['Fresh/Raw Foods', 'Vegetables'],
          isDeleted: false,
          _id: '5e2710c7f38d480018053a46',
          name: 'garlic cloves, fresh',
          verboseName: 'fresh garlic cloves',
          type: 'garlic cloves',
          variety: '',
          amount: 1,
          measureUnit: 'Each',
          gramWeight: 3,
          calories: 4.47,
          proteins: 0.1908,
          carbohydrates: 0.9918,
          fiber: 0.063,
          totalSugar: 0.03,
          addedSugar: 0,
          fat: 0.015,
          saturatedFat: 0.00267,
          transFat: 0,
          __v: 0,
          calcium_mg: 5.43,
          cholesterol_mg: 0,
          potassium_mg: 12.03,
          sodium_mg: 0.51,
          updatedAt: '2020-12-01T06:39:27.702Z',
          vitaminD_mcg: 0,
          esha: '26005',
          nutrientsPerUnit: {
            calories: 4.47,
            proteins: 0.1908,
            carbohydrates: 0.9918,
            fiber: 0.063,
            totalSugar: 0.03,
            addedSugar: 0,
            fat: 0.015,
            saturatedFat: 0.00267,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 5.43,
            iron_mg: null,
            potassium_mg: 12.03,
            sodium_mg: 0.51,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 3,
          id: '5e2710c7f38d480018053a46',
        },
        name: 'garlic cloves, fresh',
      },
      {
        quantity: 0.5,
        _id: '604f5f1756ce7f000d9e6f21',
        measureUnit: 'Teaspoon',
        food: {
          categories: ['Condiments & Sauces', 'Condiments & Sauces'],
          isDeleted: false,
          _id: '5e2710c4f38d4800180521ac',
          name: 'chili peppers, red, crushed flakes',
          verboseName: 'red chili peppers, crushed flakes',
          type: 'chili peppers',
          variety: '',
          amount: 1,
          measureUnit: 'Teaspoon',
          gramWeight: 1.8,
          calories: 5.724,
          proteins: 0.21618,
          carbohydrates: 1.01934,
          fiber: 0.4896,
          totalSugar: 0.18612,
          addedSugar: 0,
          fat: 0.31086,
          saturatedFat: 0.05868,
          transFat: 0,
          __v: 0,
          calcium_mg: 2.664,
          cholesterol_mg: 0,
          potassium_mg: 36.252,
          sodium_mg: 0.54,
          updatedAt: '2020-12-01T06:38:57.459Z',
          vitaminD_mcg: 0,
          esha: '4330',
          nutrientsPerUnit: {
            calories: 5.724,
            proteins: 0.21618,
            carbohydrates: 1.01934,
            fiber: 0.4896,
            totalSugar: 0.18612,
            addedSugar: 0,
            fat: 0.31086,
            saturatedFat: 0.05868,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 2.664,
            iron_mg: null,
            potassium_mg: 36.252,
            sodium_mg: 0.54,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 1.8,
          id: '5e2710c4f38d4800180521ac',
        },
        name: 'chili peppers, red, crushed flakes',
      },
      {
        quantity: 3,
        _id: '604f5f1756ce7f000d9e6f20',
        measureUnit: 'Ounce',
        food: {
          categories: [
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
            'Pasta',
          ],
          isDeleted: false,
          _id: '5e2710caf38d480018056414',
          name: 'pasta, cavatappi, dry, enriched',
          verboseName: 'dry cavatappi pasta, enriched',
          type: 'pasta',
          variety: 'cavatappi',
          amount: 1,
          measureUnit: 'Dry Serving',
          gramWeight: 56.7,
          calories: 210,
          proteins: 7,
          carbohydrates: 42,
          fiber: null,
          totalSugar: 2,
          addedSugar: null,
          fat: 1,
          saturatedFat: null,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 0,
          updatedAt: '2020-12-01T06:40:01.236Z',
          vitaminD_mcg: 0,
          esha: '59858',
          nutrientsPerUnit: {
            calories: 210,
            proteins: 7,
            carbohydrates: 42,
            fiber: 0,
            totalSugar: 2,
            addedSugar: 0,
            fat: 1,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 0,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 56.7,
          id: '5e2710caf38d480018056414',
        },
        name: 'pasta, cavatappi, dry, enriched',
      },
      {
        quantity: 1,
        _id: '604f5f1756ce7f000d9e6f1f',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Fats Oils & Subs'],
          isDeleted: false,
          _id: '5e2710c5f38d4800180525ec',
          name: 'oil, olive',
          verboseName: 'olive oil',
          type: 'oil',
          variety: 'olive',
          amount: 1,
          measureUnit: 'Tablespoon',
          gramWeight: 13.5,
          calories: 119.34,
          proteins: 0,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 13.5,
          saturatedFat: 1.86408,
          transFat: null,
          __v: 0,
          calcium_mg: 0.135,
          cholesterol_mg: 0,
          potassium_mg: 0.135,
          sodium_mg: 0.27,
          updatedAt: '2020-12-01T06:39:14.244Z',
          vitaminD_mcg: 0,
          esha: '8008',
          nutrientsPerUnit: {
            calories: 119.34,
            proteins: 0,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 13.5,
            saturatedFat: 1.86408,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0.135,
            iron_mg: null,
            potassium_mg: 0.135,
            sodium_mg: 0.27,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 13.5,
          id: '5e2710c5f38d4800180525ec',
        },
        name: 'oil, olive',
      },
    ],
    __v: 0,
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1615814423005',
    id: '601d5ace95e128000df65533',
  },
  {
    nutrients: {
      calories: 717.5851600000001,
      fat: 27.0565296,
      saturatedFat: 6.111197560000001,
      transFat: 0.17034828000000002,
      cholesterol_mg: 299.74670999999995,
      sodium_mg: 2811.91661,
      carbohydrates: 93.6304286,
      fiber: 2.27875,
      totalSugar: 7.45565,
      addedSugar: 0,
      proteins: 68.00492559999999,
      vitaminD_mcg: 0.311546,
      potassium_mg: 365.98073000000005,
      calcium_mg: 496.32108999999997,
      iron_mg: 0,
    },
    tags: {
      dietType: ['pescatarian'],
      mealType: ['lunch', 'dinner'],
      cookingStyle: ['cookingForTwo', 'fiveFewer'],
      primaryIngredient: ['seafood', 'shrimp'],
      intolerances: ['shellfish', 'wheat', 'gluten'],
      prepTime: ['min15'],
    },
    version: 'B',
    active: true,
    servingSize: 1,
    yield: 2,
    isDeleted: false,
    __t: 'RecipeAdmin',
    _id: '6430be3e57de88000dfadf04',
    name: 'Shrimp Tacos',
    prepTime: 5,
    cookTime: 10,
    cookingInstructions: [
      {
        _id: '6496dbb632bfe5000dee8f3e',
        num: 1,
        step:
          'Rinse and pat the shrimp dry, then toss with the taco seasoning, a sprinkle of salt and pepper, and the juice from half the lime.    ',
      },
      {
        _id: '6496dbb632bfe5000dee8f3d',
        num: 2,
        step:
          'Begin by sautéing the shrimp in a hot pan with a drizzle of olive oil for 4 minutes, until the shrimp are light pink and seared.  Set aside and keep warm.    ',
      },
      {
        _id: '6496dbb632bfe5000dee8f3c',
        num: 3,
        step:
          'When ready to eat, assemble the tacos with a layer of shrimp followed by the slaw mix, cheese, cilantro, and avocado.  Serve with a slice of lime.  ',
      },
    ],
    foods: [
      {
        quantity: 8,
        _id: '6496dbb632bfe5000dee8f3b',
        measureUnit: 'Ounce-weight',
        food: {
          categories: ['Fresh/Raw Foods', 'Fish & Seafood'],
          isDeleted: false,
          _id: '5e2710c6f38d48001805321a',
          name: 'shrimp, raw, large',
          verboseName: 'raw shrimp, large',
          type: 'shrimp',
          variety: '',
          amount: 4,
          measureUnit: 'Each',
          gramWeight: 28,
          calories: 19.88,
          proteins: 3.8108,
          carbohydrates: 0.2548,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 0.2828,
          saturatedFat: 0.07308,
          transFat: 0.00504,
          __v: 0,
          calcium_mg: 15.12,
          cholesterol_mg: 35.28,
          potassium_mg: 31.64,
          sodium_mg: 158.48,
          updatedAt: '2020-12-01T06:39:23.676Z',
          vitaminD_mcg: 0.028,
          esha: '19032',
          nutrientsPerUnit: {
            calories: 4.97,
            proteins: 0.9527,
            carbohydrates: 0.0637,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 0.0707,
            saturatedFat: 0.01827,
            transFat: 0.00126,
            vitaminD_mcg: 0.007,
            calcium_mg: 3.78,
            iron_mg: null,
            potassium_mg: 7.91,
            sodium_mg: 39.62,
            cholesterol_mg: 8.82,
          },
          gramWeightPerUnit: 7,
          id: '5e2710c6f38d48001805321a',
        },
        name: 'shrimp, raw, large',
      },
      {
        quantity: 0.5,
        _id: '6496dbb632bfe5000dee8f3a',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Condiments & Sauces', 'Dry Mixes'],
          isDeleted: false,
          _id: '5e2710c7f38d480018053a6a',
          name: 'seasoning mix, taco',
          verboseName: 'taco seasoning mix',
          type: 'seasoning mix',
          variety: 'taco',
          amount: 2,
          measureUnit: 'Teaspoon',
          gramWeight: 5,
          calories: 15,
          proteins: 0,
          carbohydrates: 3,
          fiber: 0,
          totalSugar: 1,
          addedSugar: null,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: null,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 340,
          updatedAt: '2020-12-01T06:39:27.702Z',
          vitaminD_mcg: null,
          esha: '26092',
          nutrientsPerUnit: {
            calories: 7.5,
            proteins: 0,
            carbohydrates: 1.5,
            fiber: 0,
            totalSugar: 0.5,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 170,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 2.5,
          id: '5e2710c7f38d480018053a6a',
        },
        name: 'seasoning mix, taco',
      },
      {
        quantity: 4,
        _id: '6496dbb632bfe5000dee8f39',
        measureUnit: 'Each',
        food: {
          categories: ['Bread & Bakery'],
          isDeleted: false,
          _id: '5e2710ccf38d4800180576fa',
          name: 'tortilla, wrap, multigrain, soft, extra virgin olive oil',
          verboseName: 'multigrain wrap tortilla, soft, extra virgin olive oil',
          type: 'tortilla',
          variety: 'wrap',
          amount: 1,
          measureUnit: 'Each',
          gramWeight: 62,
          calories: 100,
          proteins: 8,
          carbohydrates: 20,
          fiber: null,
          totalSugar: 1,
          addedSugar: null,
          fat: 3.5,
          saturatedFat: 0.5,
          transFat: 0,
          __v: 0,
          calcium_mg: 60,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 290,
          updatedAt: '2020-12-01T06:40:09.338Z',
          vitaminD_mcg: null,
          esha: '73774',
          nutrientsPerUnit: {
            calories: 100,
            proteins: 8,
            carbohydrates: 20,
            fiber: 0,
            totalSugar: 1,
            addedSugar: 0,
            fat: 3.5,
            saturatedFat: 0.5,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 60,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 290,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 62,
          id: '5e2710ccf38d4800180576fa',
        },
        name: 'tortilla, wrap, multigrain, soft, extra virgin olive oil',
      },
      {
        quantity: 0.75,
        _id: '6496dbb632bfe5000dee8f38',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Dishes & Prepared Foods',
            'Fresh/Raw Foods',
            'Vegetables',
          ],
          isDeleted: false,
          _id: '5e2710cdf38d4800180580ae',
          name: 'salad mix, broccoli slaw, fresh',
          verboseName: 'fresh broccoli slaw salad mix',
          type: 'salad mix',
          variety: '',
          amount: 1,
          measureUnit: 'Cup',
          gramWeight: 85,
          calories: 25,
          proteins: 2,
          carbohydrates: 5,
          fiber: null,
          totalSugar: 2,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 40,
          cholesterol_mg: 0,
          potassium_mg: 0,
          sodium_mg: 25,
          updatedAt: '2020-12-01T06:40:14.962Z',
          vitaminD_mcg: null,
          esha: '78317',
          nutrientsPerUnit: {
            calories: 25,
            proteins: 2,
            carbohydrates: 5,
            fiber: 0,
            totalSugar: 2,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 40,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 25,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 85,
          id: '5e2710cdf38d4800180580ae',
        },
        name: 'salad mix, broccoli slaw, fresh',
      },
      {
        quantity: 2,
        _id: '6496dbb632bfe5000dee8f37',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Dairy Products & Subs', 'Dairy Products & Subs'],
          isDeleted: false,
          _id: '5e2710c4f38d480018051ead',
          name: 'cheese, cheddar, shredded',
          verboseName: 'cheddar cheese, shredded',
          type: 'cheese',
          variety: 'cheddar',
          amount: 0.25,
          measureUnit: 'Cup',
          gramWeight: 28.25,
          calories: 114.13,
          proteins: 6.46078,
          carbohydrates: 0.87292,
          fiber: 0,
          totalSugar: 0.1356,
          addedSugar: 0,
          fat: 9.41008,
          saturatedFat: 5.32993,
          transFat: 0.25905,
          __v: 0,
          calcium_mg: 200.575,
          cholesterol_mg: 27.9675,
          potassium_mg: 21.47,
          sodium_mg: 184.4725,
          updatedAt: '2020-12-01T06:38:57.457Z',
          vitaminD_mcg: 0.1695,
          esha: '1008',
          nutrientsPerUnit: {
            calories: 456.52,
            proteins: 25.84312,
            carbohydrates: 3.49168,
            fiber: 0,
            totalSugar: 0.5424,
            addedSugar: 0,
            fat: 37.64032,
            saturatedFat: 21.31972,
            transFat: 1.0362,
            vitaminD_mcg: 0.678,
            calcium_mg: 802.3,
            iron_mg: null,
            potassium_mg: 85.88,
            sodium_mg: 737.89,
            cholesterol_mg: 111.87,
          },
          gramWeightPerUnit: 113,
          id: '5e2710c4f38d480018051ead',
        },
        name: 'cheese, cheddar, shredded',
      },
      {
        quantity: 0.125,
        _id: '6496dbb632bfe5000dee8f36',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Condiments & Sauces',
            'Condiments & Sauces',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
          ],
          isDeleted: false,
          _id: '5e2710cff38d4800180599e1',
          name: 'cilantro, fresh, leaves',
          verboseName: 'fresh cilantro, leaves',
          type: 'cilantro',
          variety: '',
          amount: 1,
          measureUnit: 'Tablespoon',
          gramWeight: 1,
          calories: 0.23,
          proteins: 0.0213,
          carbohydrates: 0.0367,
          fiber: 0.028,
          totalSugar: 0.0087,
          addedSugar: 0,
          fat: 0.0052,
          saturatedFat: 0.00014,
          transFat: 0,
          __v: 0,
          calcium_mg: 0.67,
          cholesterol_mg: 0,
          potassium_mg: 5.21,
          sodium_mg: 0.46,
          updatedAt: '2020-12-01T06:40:29.319Z',
          vitaminD_mcg: 0,
          esha: '92175',
          nutrientsPerUnit: {
            calories: 0.23,
            proteins: 0.0213,
            carbohydrates: 0.0367,
            fiber: 0.028,
            totalSugar: 0.0087,
            addedSugar: 0,
            fat: 0.0052,
            saturatedFat: 0.00014,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0.67,
            iron_mg: null,
            potassium_mg: 5.21,
            sodium_mg: 0.46,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 1,
          id: '5e2710cff38d4800180599e1',
        },
        name: 'cilantro, fresh, leaves',
      },
      {
        quantity: 2,
        _id: '6496dbb632bfe5000dee8f35',
        measureUnit: 'Tablespoon',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Vegetables',
            'Vegetables',
            'Vegetables',
          ],
          isDeleted: false,
          _id: '5e2710c4f38d480018052134',
          name: 'avocado, fresh, sliced',
          verboseName: 'fresh avocado, sliced',
          type: 'avocado',
          variety: '',
          amount: 0.25,
          measureUnit: 'Cup',
          gramWeight: 36.5,
          calories: 58.4,
          proteins: 0.73,
          carbohydrates: 3.11345,
          fiber: 2.4455,
          totalSugar: 0.2409,
          addedSugar: 0,
          fat: 5.3509,
          saturatedFat: 0.77599,
          transFat: 0,
          __v: 0,
          calcium_mg: 4.38,
          cholesterol_mg: 0,
          potassium_mg: 177.025,
          sodium_mg: 2.555,
          updatedAt: '2020-12-01T06:38:57.458Z',
          vitaminD_mcg: 0,
          esha: '3658',
          nutrientsPerUnit: {
            calories: 233.6,
            proteins: 2.92,
            carbohydrates: 12.4538,
            fiber: 9.782,
            totalSugar: 0.9636,
            addedSugar: 0,
            fat: 21.4036,
            saturatedFat: 3.10396,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 17.52,
            iron_mg: null,
            potassium_mg: 708.1,
            sodium_mg: 10.22,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 146,
          id: '5e2710c4f38d480018052134',
        },
        name: 'avocado, fresh, sliced',
      },
      {
        quantity: 0.5,
        _id: '6496dbb632bfe5000dee8f34',
        measureUnit: 'Each',
        food: {
          categories: ['Fresh/Raw Foods', 'Fruits'],
          isDeleted: false,
          _id: '5e2710caf38d48001805620f',
          name: 'lime, fresh, organic',
          verboseName: 'fresh lime, organic',
          type: 'lime',
          variety: '',
          amount: 1,
          measureUnit: 'Each',
          gramWeight: 67,
          calories: 20,
          proteins: 0,
          carbohydrates: 7,
          fiber: 2,
          totalSugar: 2,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 0,
          updatedAt: '2020-12-01T06:39:56.450Z',
          vitaminD_mcg: null,
          esha: '58718',
          nutrientsPerUnit: {
            calories: 20,
            proteins: 0,
            carbohydrates: 7,
            fiber: 2,
            totalSugar: 2,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 0,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 67,
          id: '5e2710caf38d48001805620f',
        },
        name: 'lime, fresh, organic',
      },
      {
        quantity: 0.25,
        _id: '6496dbb632bfe5000dee8f33',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Fats Oils & Subs'],
          isDeleted: false,
          _id: '5e2710c5f38d4800180525ec',
          name: 'oil, olive',
          verboseName: 'olive oil',
          type: 'oil',
          variety: 'olive',
          amount: 1,
          measureUnit: 'Tablespoon',
          gramWeight: 13.5,
          calories: 119.34,
          proteins: 0,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 13.5,
          saturatedFat: 1.86408,
          transFat: null,
          __v: 0,
          calcium_mg: 0.135,
          cholesterol_mg: 0,
          potassium_mg: 0.135,
          sodium_mg: 0.27,
          updatedAt: '2020-12-01T06:39:14.244Z',
          vitaminD_mcg: 0,
          esha: '8008',
          nutrientsPerUnit: {
            calories: 119.34,
            proteins: 0,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 13.5,
            saturatedFat: 1.86408,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0.135,
            iron_mg: null,
            potassium_mg: 0.135,
            sodium_mg: 0.27,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 13.5,
          id: '5e2710c5f38d4800180525ec',
        },
        name: 'oil, olive',
      },
    ],
    __v: 0,
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687575970060',
    updatedAt: '2023-06-24T12:04:06.196Z',
    id: '6430be3e57de88000dfadf04',
  },
  {
    nutrients: {
      calories: 706.5925000000001,
      fat: 23.05143,
      saturatedFat: 2.3098633333333334,
      transFat: 0,
      cholesterol_mg: 0,
      sodium_mg: 1343.1665,
      carbohydrates: 78.73303166666668,
      fiber: 8.855208333333334,
      totalSugar: 23.077080000000002,
      addedSugar: 16,
      proteins: 51.395318333333336,
      vitaminD_mcg: 0,
      potassium_mg: 627.72625,
      calcium_mg: 590.2709166666666,
      iron_mg: 0,
    },
    tags: {
      dietType: ['vegan', 'vegetarian'],
      mealType: ['lunch', 'dinner'],
      cookingStyle: ['cookingForTwo'],
      primaryIngredient: ['tofuTempeh', 'pasta', 'vegRecipes'],
      intolerances: ['soy'],
      prepTime: ['min30'],
    },
    version: 'B',
    active: true,
    servingSize: 1,
    yield: 2,
    isDeleted: false,
    __t: 'RecipeAdmin',
    _id: '60268498d3603b000d49b12a',
    cookTime: 20,
    name: 'Grilled Tofu and Soba Noodle Bowl',
    prepTime: 15,
    cookingInstructions: [
      {
        _id: '602683b6d3603b000d49b11d',
        num: 1,
        step:
          'Drain and press the water out of the tofu and chop up into cubes.  Combine half of the ginger, soy sauce and agave in a bowl with the garlic and a sprinkle of salt and pepper and toss with the tofu cubes and let marinate for 15 minutes.',
      },
      {
        _id: '602683b6d3603b000d49b11c',
        num: 2,
        step:
          'Prep the vegetables by dicing the red bell pepper and bringing a pot of water to a boil.  Combine the other half of the ginger, soy sauce and agave with the olive oil and whisk together for the dressing.  ',
      },
      {
        _id: '602683b6d3603b000d49b11b',
        num: 3,
        step:
          'Pour the tofu and marinade into a foil lined baking dish and bake in a 400 degree oven for 15 minutes.  For the last 2 minutes crank up the broiler to sear the tofu.',
      },
      {
        _id: '602683b6d3603b000d49b11a',
        num: 4,
        step:
          'Once the water is at a boil start blanching the vegetables by dipping a colander into the boiling water or using a mesh strainer.  Start blanching the red bell pepper for 1 minute, then add in the frozen edamame for 45 seconds and finally add the spinach for the last 15 seconds.  Take out and shock in cool water to stop the cooking process.  Finish by cooking the soba noodles in the pot of boiling water for 8 minutes until tender, then drain and shock with cool water to stop the cooking process.',
      },
      {
        _id: '602683b6d3603b000d49b119',
        num: 5,
        step:
          'Now build the bowl by layering in the soba noodles, followed by the vegetables and finally the tofu.  Drizzle over the dressing and a sprinkle of sesame seeds and a squeeze of fresh lime.  This dish can be enjoyed at room temperature to allow the dressing to absorb throughout the dish.',
      },
    ],
    foods: [
      {
        quantity: 9,
        _id: '6054ad7d8c4b8f000dff1ba4',
        measureUnit: 'Ounce-weight',
        food: {
          categories: ['Meat Substitutes'],
          isDeleted: false,
          _id: '5e2710cbf38d480018056f0c',
          name: 'tofu, extra firm',
          verboseName: 'extra firm tofu',
          type: 'tofu',
          variety: 'extra firm',
          amount: 3,
          measureUnit: 'Ounce-weight',
          gramWeight: 85.05,
          calories: 90,
          proteins: 10,
          carbohydrates: 1,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 5,
          saturatedFat: 0.5,
          transFat: 0,
          __v: 0,
          calcium_mg: 150,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 5,
          updatedAt: '2020-12-01T06:40:04.936Z',
          vitaminD_mcg: null,
          esha: '68063',
          nutrientsPerUnit: {
            calories: 30,
            proteins: 3.3333333333333335,
            carbohydrates: 0.3333333333333333,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 1.6666666666666667,
            saturatedFat: 0.16666666666666666,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 50,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 1.6666666666666667,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 28.349999999999998,
          id: '5e2710cbf38d480018056f0c',
        },
        name: 'tofu, extra firm',
      },
      {
        quantity: 0.75,
        _id: '6054ad7d8c4b8f000dff1ba3',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Vegetables',
            'Vegetables',
            'Vegetables',
          ],
          isDeleted: false,
          _id: '5e2710c5f38d480018052303',
          name: 'bell pepper, red, fresh, chopped',
          verboseName: 'fresh red bell pepper, chopped',
          type: 'bell pepper',
          variety: '',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 74.5,
          calories: 23.095,
          proteins: 0.73755,
          carbohydrates: 4.49235,
          fiber: 1.49745,
          totalSugar: 2.9949,
          addedSugar: 0,
          fat: 0.2235,
          saturatedFat: 0.02012,
          transFat: 0,
          __v: 0,
          calcium_mg: 5.215,
          cholesterol_mg: 0,
          potassium_mg: 157.195,
          sodium_mg: 2.98,
          updatedAt: '2020-12-01T06:39:10.673Z',
          vitaminD_mcg: 0,
          esha: '5128',
          nutrientsPerUnit: {
            calories: 46.19,
            proteins: 1.4751,
            carbohydrates: 8.9847,
            fiber: 2.9949,
            totalSugar: 5.9898,
            addedSugar: 0,
            fat: 0.447,
            saturatedFat: 0.04024,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 10.43,
            iron_mg: null,
            potassium_mg: 314.39,
            sodium_mg: 5.96,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 149,
          id: '5e2710c5f38d480018052303',
        },
        name: 'bell pepper, red, fresh, chopped',
      },
      {
        quantity: 0.5,
        _id: '6054ad7d8c4b8f000dff1ba2',
        measureUnit: 'Cup',
        food: {
          categories: ['Frozen Foods', 'Legumes'],
          isDeleted: false,
          _id: '5e2710c5f38d480018052802',
          name: 'edamame, frozen',
          verboseName: 'frozen edamame',
          type: 'edamame',
          variety: '',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 59,
          calories: 64.31,
          proteins: 6.6198,
          carbohydrates: 4.4899,
          fiber: 2.832,
          totalSugar: 1.4632,
          addedSugar: 0,
          fat: 2.7907,
          saturatedFat: null,
          transFat: 0,
          __v: 0,
          calcium_mg: 35.4,
          cholesterol_mg: null,
          potassium_mg: 284.38,
          sodium_mg: 3.54,
          updatedAt: '2020-12-01T06:39:14.245Z',
          vitaminD_mcg: null,
          esha: '9904',
          nutrientsPerUnit: {
            calories: 128.62,
            proteins: 13.2396,
            carbohydrates: 8.9798,
            fiber: 5.664,
            totalSugar: 2.9264,
            addedSugar: 0,
            fat: 5.5814,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 70.8,
            iron_mg: null,
            potassium_mg: 568.76,
            sodium_mg: 7.08,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 118,
          id: '5e2710c5f38d480018052802',
        },
        name: 'edamame, frozen',
      },
      {
        quantity: 1.5,
        _id: '6054ad7d8c4b8f000dff1ba1',
        measureUnit: 'Cup',
        food: {
          categories: ['Fresh/Raw Foods', 'Vegetables'],
          isDeleted: false,
          _id: '5e2710c5f38d4800180526ee',
          name: 'spinach, baby, fresh',
          verboseName: 'fresh baby spinach',
          type: 'spinach',
          variety: '',
          amount: 1.5,
          measureUnit: 'Cup',
          gramWeight: 85,
          calories: 20,
          proteins: 2,
          carbohydrates: 3,
          fiber: 2,
          totalSugar: 0,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 80,
          cholesterol_mg: 0,
          potassium_mg: 0,
          sodium_mg: 65,
          updatedAt: '2020-12-01T06:39:14.245Z',
          vitaminD_mcg: null,
          esha: '9317',
          nutrientsPerUnit: {
            calories: 13.333333333333334,
            proteins: 1.3333333333333333,
            carbohydrates: 2,
            fiber: 1.3333333333333333,
            totalSugar: 0,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 53.333333333333336,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 43.333333333333336,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 56.666666666666664,
          id: '5e2710c5f38d4800180526ee',
        },
        name: 'spinach, baby, fresh',
      },
      {
        quantity: 1,
        _id: '6054ad7d8c4b8f000dff1ba0',
        measureUnit: 'Each',
        food: {
          categories: ['Fresh/Raw Foods', 'Vegetables'],
          isDeleted: false,
          _id: '5e2710c7f38d480018053a46',
          name: 'garlic cloves, fresh',
          verboseName: 'fresh garlic cloves',
          type: 'garlic cloves',
          variety: '',
          amount: 1,
          measureUnit: 'Each',
          gramWeight: 3,
          calories: 4.47,
          proteins: 0.1908,
          carbohydrates: 0.9918,
          fiber: 0.063,
          totalSugar: 0.03,
          addedSugar: 0,
          fat: 0.015,
          saturatedFat: 0.00267,
          transFat: 0,
          __v: 0,
          calcium_mg: 5.43,
          cholesterol_mg: 0,
          potassium_mg: 12.03,
          sodium_mg: 0.51,
          updatedAt: '2020-12-01T06:39:27.702Z',
          vitaminD_mcg: 0,
          esha: '26005',
          nutrientsPerUnit: {
            calories: 4.47,
            proteins: 0.1908,
            carbohydrates: 0.9918,
            fiber: 0.063,
            totalSugar: 0.03,
            addedSugar: 0,
            fat: 0.015,
            saturatedFat: 0.00267,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 5.43,
            iron_mg: null,
            potassium_mg: 12.03,
            sodium_mg: 0.51,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 3,
          id: '5e2710c7f38d480018053a46',
        },
        name: 'garlic cloves, fresh',
      },
      {
        quantity: 0.5,
        _id: '6054ad7d8c4b8f000dff1b9f',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Condiments & Sauces'],
          isDeleted: false,
          _id: '5e2710c7f38d480018053a53',
          name: 'ginger, ground',
          verboseName: 'ginger, ground',
          type: 'ginger',
          variety: '',
          amount: 1,
          measureUnit: 'Teaspoon',
          gramWeight: 1.8,
          calories: 6.03,
          proteins: 0.16164,
          carbohydrates: 1.28916,
          fiber: 0.2538,
          totalSugar: 0.06102,
          addedSugar: 0,
          fat: 0.07632,
          saturatedFat: 0.04678,
          transFat: 0,
          __v: 0,
          calcium_mg: 2.052,
          cholesterol_mg: 0,
          potassium_mg: 23.76,
          sodium_mg: 0.486,
          updatedAt: '2020-12-01T06:39:27.702Z',
          vitaminD_mcg: 0,
          esha: '26023',
          nutrientsPerUnit: {
            calories: 6.03,
            proteins: 0.16164,
            carbohydrates: 1.28916,
            fiber: 0.2538,
            totalSugar: 0.06102,
            addedSugar: 0,
            fat: 0.07632,
            saturatedFat: 0.04678,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 2.052,
            iron_mg: null,
            potassium_mg: 23.76,
            sodium_mg: 0.486,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 1.8,
          id: '5e2710c7f38d480018053a53',
        },
        name: 'ginger, ground',
      },
      {
        quantity: 2,
        _id: '6054ad7d8c4b8f000dff1b9e',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Condiments & Sauces'],
          isDeleted: false,
          _id: '5e2710caf38d480018055cf7',
          name: 'soy sauce, less sodium',
          verboseName: 'soy sauce, less sodium',
          type: 'soy sauce',
          variety: '',
          amount: 1,
          measureUnit: 'Tablespoon',
          gramWeight: 16,
          calories: 10,
          proteins: 1,
          carbohydrates: 1,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 575,
          updatedAt: '2020-12-01T06:39:54.453Z',
          vitaminD_mcg: null,
          esha: '53614',
          nutrientsPerUnit: {
            calories: 10,
            proteins: 1,
            carbohydrates: 1,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 575,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 16,
          id: '5e2710caf38d480018055cf7',
        },
        name: 'soy sauce, less sodium',
      },
      {
        quantity: 1,
        _id: '6054ad7d8c4b8f000dff1b9d',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Sweeteners', 'Sweeteners'],
          isDeleted: false,
          _id: '5e2710c9f38d480018055362',
          name: 'agave nectar, light',
          verboseName: 'light agave nectar',
          type: 'agave nectar',
          variety: '',
          amount: 1,
          measureUnit: 'Tablespoon',
          gramWeight: 21,
          calories: 60,
          proteins: 0,
          carbohydrates: 16,
          fiber: null,
          totalSugar: 16,
          addedSugar: 16,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: null,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 0,
          updatedAt: '2020-12-01T06:39:46.585Z',
          vitaminD_mcg: null,
          esha: '45901',
          nutrientsPerUnit: {
            calories: 60,
            proteins: 0,
            carbohydrates: 16,
            fiber: 0,
            totalSugar: 16,
            addedSugar: 16,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 0,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 21,
          id: '5e2710c9f38d480018055362',
        },
        name: 'agave nectar, light',
      },
      {
        quantity: 1.5,
        _id: '6054ad7d8c4b8f000dff1b9c',
        measureUnit: 'Cup',
        food: {
          categories: ['Pasta'],
          isDeleted: false,
          _id: '5e2710c8f38d4800180549c2',
          name: 'noodles, soba, cooked',
          verboseName: 'cooked soba noodles',
          type: 'noodles',
          variety: 'soba',
          amount: 1,
          measureUnit: 'Cup',
          gramWeight: 114,
          calories: 112.86,
          proteins: 5.7684,
          carbohydrates: 24.4416,
          fiber: null,
          totalSugar: 0,
          addedSugar: 0,
          fat: 0.114,
          saturatedFat: 0.02166,
          transFat: null,
          __v: 0,
          calcium_mg: 4.56,
          cholesterol_mg: 0,
          potassium_mg: 39.9,
          sodium_mg: 68.4,
          updatedAt: '2020-12-01T06:39:42.469Z',
          vitaminD_mcg: 0,
          esha: '38094',
          nutrientsPerUnit: {
            calories: 112.86,
            proteins: 5.7684,
            carbohydrates: 24.4416,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 0.114,
            saturatedFat: 0.02166,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 4.56,
            iron_mg: null,
            potassium_mg: 39.9,
            sodium_mg: 68.4,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 114,
          id: '5e2710c8f38d4800180549c2',
        },
        name: 'noodles, soba, cooked',
      },
      {
        quantity: 0.5,
        _id: '6054ad7d8c4b8f000dff1b9b',
        measureUnit: 'Each',
        food: {
          categories: ['Fresh/Raw Foods', 'Fruits'],
          isDeleted: false,
          _id: '5e2710caf38d48001805620f',
          name: 'lime, fresh, organic',
          verboseName: 'fresh lime, organic',
          type: 'lime',
          variety: '',
          amount: 1,
          measureUnit: 'Each',
          gramWeight: 67,
          calories: 20,
          proteins: 0,
          carbohydrates: 7,
          fiber: 2,
          totalSugar: 2,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 0,
          updatedAt: '2020-12-01T06:39:56.450Z',
          vitaminD_mcg: null,
          esha: '58718',
          nutrientsPerUnit: {
            calories: 20,
            proteins: 0,
            carbohydrates: 7,
            fiber: 2,
            totalSugar: 2,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 0,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 67,
          id: '5e2710caf38d48001805620f',
        },
        name: 'lime, fresh, organic',
      },
      {
        quantity: 0.25,
        _id: '6054ad7d8c4b8f000dff1b9a',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Nuts & Seeds'],
          isDeleted: false,
          _id: '5e2710cbf38d480018056fbd',
          name: 'sesame seeds',
          verboseName: 'sesame seeds',
          type: 'sesame seeds',
          variety: '',
          amount: 3,
          measureUnit: 'Tablespoon',
          gramWeight: 30,
          calories: 180,
          proteins: 7,
          carbohydrates: 5,
          fiber: 4,
          totalSugar: 0,
          addedSugar: 0,
          fat: 15,
          saturatedFat: 2.5,
          transFat: 0,
          __v: 0,
          calcium_mg: 20,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 15,
          updatedAt: '2020-12-01T06:40:04.936Z',
          vitaminD_mcg: null,
          esha: '68299',
          nutrientsPerUnit: {
            calories: 60,
            proteins: 2.3333333333333335,
            carbohydrates: 1.6666666666666667,
            fiber: 1.3333333333333333,
            totalSugar: 0,
            addedSugar: 0,
            fat: 5,
            saturatedFat: 0.8333333333333334,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 6.666666666666667,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 5,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 10,
          id: '5e2710cbf38d480018056fbd',
        },
        name: 'sesame seeds',
      },
      {
        quantity: 0.25,
        _id: '6054ad7d8c4b8f000dff1b99',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Fats Oils & Subs'],
          isDeleted: false,
          _id: '5e2710c5f38d4800180525ec',
          name: 'oil, olive',
          verboseName: 'olive oil',
          type: 'oil',
          variety: 'olive',
          amount: 1,
          measureUnit: 'Tablespoon',
          gramWeight: 13.5,
          calories: 119.34,
          proteins: 0,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 13.5,
          saturatedFat: 1.86408,
          transFat: null,
          __v: 0,
          calcium_mg: 0.135,
          cholesterol_mg: 0,
          potassium_mg: 0.135,
          sodium_mg: 0.27,
          updatedAt: '2020-12-01T06:39:14.244Z',
          vitaminD_mcg: 0,
          esha: '8008',
          nutrientsPerUnit: {
            calories: 119.34,
            proteins: 0,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 13.5,
            saturatedFat: 1.86408,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0.135,
            iron_mg: null,
            potassium_mg: 0.135,
            sodium_mg: 0.27,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 13.5,
          id: '5e2710c5f38d4800180525ec',
        },
        name: 'oil, olive',
      },
    ],
    __v: 0,
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1616162172765',
    id: '60268498d3603b000d49b12a',
  },
  {
    nutrients: {
      calories: 506.25,
      fat: 13.1875,
      saturatedFat: 8.458333333333334,
      transFat: 0,
      cholesterol_mg: 6.875,
      sodium_mg: 255.83333333333334,
      carbohydrates: 52.083333333333336,
      fiber: 6.75,
      totalSugar: 15.125,
      addedSugar: 0,
      proteins: 48.75,
      vitaminD_mcg: 2.0833333333333335,
      potassium_mg: 588,
      calcium_mg: 608.25,
      iron_mg: 0,
    },
    tags: {
      dietType: ['paleo'],
      mealType: ['beverage', 'snack'],
      cookingStyle: ['prepAhead', 'toGo', 'fiveFewer'],
      primaryIngredient: ['fruit', 'grain'],
      intolerances: ['grains', 'treeNut'],
      prepTime: ['min15', 'noCook'],
    },
    version: 'B',
    active: true,
    servingSize: 1,
    yield: 2,
    isDeleted: false,
    __t: 'RecipeAdmin',
    _id: '6272e8f401def1000dc42db6',
    name: 'Pina Colada Overnight Oats',
    prepTime: 5,
    cookTime: 0,
    cookingInstructions: [
      {
        _id: '64253f23193c86000d3d6513',
        num: 1,
        step:
          'In a mixing bowl, add the rolled oats, protein powder, and coconut milk with a pinch of salt and mix together.      ',
      },
      {
        _id: '64253f23193c86000d3d6512',
        num: 2,
        step:
          'Evenly distribute the mixture into 2 containers, seal with a lid, and place in the refrigerator for a minimum of 4 hours but preferably overnight to let the oats absorb all of the liquid.    ',
      },
      {
        _id: '64253f23193c86000d3d6511',
        num: 3,
        step:
          'In the morning, take out your overnight oats and give a quick stir. Then top with the pineapple and shredded coconut.  ',
      },
    ],
    foods: [
      {
        quantity: 0.625,
        _id: '64253f23193c86000d3d6510',
        measureUnit: 'Cup',
        food: {
          categories: ['Baking Ingredients', 'Breakfast Foods'],
          isDeleted: false,
          _id: '5e2710c8f38d48001805498f',
          name: 'oats, rolled, old fashioned, dry',
          verboseName: 'dry old fashioned rolled oats',
          type: 'oats',
          variety: 'rolled',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 40,
          calories: 150,
          proteins: 5,
          carbohydrates: 27,
          fiber: 4,
          totalSugar: 1,
          addedSugar: 0,
          fat: 3,
          saturatedFat: 0.5,
          transFat: 0,
          __v: 0,
          calcium_mg: 20,
          cholesterol_mg: 0,
          potassium_mg: 150,
          sodium_mg: 0,
          updatedAt: '2020-12-01T06:39:42.469Z',
          vitaminD_mcg: 0,
          esha: '38017',
          nutrientsPerUnit: {
            calories: 300,
            proteins: 10,
            carbohydrates: 54,
            fiber: 8,
            totalSugar: 2,
            addedSugar: 0,
            fat: 6,
            saturatedFat: 1,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 40,
            iron_mg: null,
            potassium_mg: 300,
            sodium_mg: 0,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 80,
          id: '5e2710c8f38d48001805498f',
        },
        name: 'oats, rolled, old fashioned, dry',
      },
      {
        quantity: 1.375,
        _id: '64253f23193c86000d3d650f',
        measureUnit: 'Scoop',
        food: {
          categories: ['Vitamins & Supplements'],
          isDeleted: false,
          _id: '5e2710d4f38d48001805ca60',
          name: 'protein powder, vanilla, 100% whey isolate',
          verboseName: 'vanilla protein powder, 100% whey isolate',
          type: 'protein powder',
          variety: '',
          amount: 1,
          measureUnit: 'Scoop',
          gramWeight: 35,
          calories: 130,
          proteins: 30,
          carbohydrates: 2,
          fiber: 0,
          totalSugar: 1,
          addedSugar: null,
          fat: 0.5,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 150,
          cholesterol_mg: 5,
          potassium_mg: 180,
          sodium_mg: 160,
          updatedAt: '2020-12-01T06:40:58.422Z',
          vitaminD_mcg: null,
          esha: '109913',
          nutrientsPerUnit: {
            calories: 130,
            proteins: 30,
            carbohydrates: 2,
            fiber: 0,
            totalSugar: 1,
            addedSugar: 0,
            fat: 0.5,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 150,
            iron_mg: null,
            potassium_mg: 180,
            sodium_mg: 160,
            cholesterol_mg: 5,
          },
          gramWeightPerUnit: 35,
          id: '5e2710d4f38d48001805ca60',
        },
        name: 'protein powder, vanilla, 100% whey isolate',
      },
      {
        quantity: 0.8333333333333334,
        _id: '64253f23193c86000d3d650e',
        measureUnit: 'Cup',
        food: {
          categories: ['Beverages', 'Dairy Products & Subs'],
          isDeleted: false,
          _id: '5e2710cef38d4800180587c3',
          name: 'coconut milk, refrigerated, unsweetened',
          verboseName: 'refrigerated coconut milk, unsweetened',
          type: 'coconut milk',
          variety: '',
          amount: 1,
          measureUnit: 'Cup',
          gramWeight: 240,
          calories: 45,
          proteins: 0,
          carbohydrates: 1,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 4.5,
          saturatedFat: 4,
          transFat: 0,
          __v: 0,
          calcium_mg: 450,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 40,
          updatedAt: '2020-12-01T06:40:18.817Z',
          vitaminD_mcg: 2.5,
          esha: '81605',
          nutrientsPerUnit: {
            calories: 45,
            proteins: 0,
            carbohydrates: 1,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 4.5,
            saturatedFat: 4,
            transFat: 0,
            vitaminD_mcg: 2.5,
            calcium_mg: 450,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 40,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 240,
          id: '5e2710cef38d4800180587c3',
        },
        name: 'coconut milk, refrigerated, unsweetened',
      },
      {
        quantity: 0.375,
        _id: '64253f23193c86000d3d650d',
        measureUnit: 'Cup',
        food: {
          categories: ['Canned Foods', 'Fruits'],
          isDeleted: false,
          _id: '5e2710cdf38d4800180580e8',
          name: 'pineapple, chunks',
          verboseName: 'pineapple, chunks',
          type: 'pineapple',
          variety: '',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 122,
          calories: 70,
          proteins: 1,
          carbohydrates: 17,
          fiber: 1,
          totalSugar: 16,
          addedSugar: null,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 0,
          potassium_mg: 150,
          sodium_mg: 0,
          updatedAt: '2020-12-01T06:40:14.962Z',
          vitaminD_mcg: null,
          esha: '78375',
          nutrientsPerUnit: {
            calories: 140,
            proteins: 2,
            carbohydrates: 34,
            fiber: 2,
            totalSugar: 32,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 300,
            sodium_mg: 0,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 244,
          id: '5e2710cdf38d4800180580e8',
        },
        name: 'pineapple, chunks',
      },
      {
        quantity: 1.5,
        _id: '64253f23193c86000d3d650c',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Nuts & Seeds'],
          isDeleted: false,
          _id: '5e2710d3f38d48001805be94',
          name: 'coconut, shredded, unsweetened',
          verboseName: 'coconut, shredded, unsweetened',
          type: 'coconut',
          variety: '',
          amount: 3,
          measureUnit: 'Tablespoon',
          gramWeight: 15,
          calories: 100,
          proteins: 1,
          carbohydrates: 4,
          fiber: 2,
          totalSugar: 1,
          addedSugar: 0,
          fat: 10,
          saturatedFat: 9,
          transFat: 0,
          __v: 0,
          calcium_mg: 4,
          cholesterol_mg: 0,
          potassium_mg: 81,
          sodium_mg: 5,
          updatedAt: '2020-12-01T06:40:50.108Z',
          vitaminD_mcg: 0,
          esha: '105600',
          nutrientsPerUnit: {
            calories: 33.333333333333336,
            proteins: 0.3333333333333333,
            carbohydrates: 1.3333333333333333,
            fiber: 0.6666666666666666,
            totalSugar: 0.3333333333333333,
            addedSugar: 0,
            fat: 3.3333333333333335,
            saturatedFat: 3,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 1.3333333333333333,
            iron_mg: null,
            potassium_mg: 27,
            sodium_mg: 1.6666666666666667,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 5,
          id: '5e2710d3f38d48001805be94',
        },
        name: 'coconut, shredded, unsweetened',
      },
    ],
    __v: 0,
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1651766903292',
    id: '6272e8f401def1000dc42db6',
  },
  {
    nutrients: {
      calories: 702.0558333333333,
      fat: 23.553874999999998,
      saturatedFat: 2.5985324999999997,
      transFat: 0,
      cholesterol_mg: 113.75,
      sodium_mg: 1347.7383333333332,
      carbohydrates: 81.31004166666666,
      fiber: 3.83875,
      totalSugar: 34.19266666666667,
      addedSugar: 0,
      proteins: 63.23975,
      vitaminD_mcg: 0,
      potassium_mg: 81.07124999999999,
      calcium_mg: 181.34625,
      iron_mg: 0,
    },
    tags: {
      dietType: ['paleo'],
      mealType: ['lunch', 'dinner'],
      cookingStyle: ['prepAhead', 'toGo', 'fiveFewer'],
      primaryIngredient: ['chicken'],
      intolerances: ['gluten', 'grains', 'wheat'],
      prepTime: ['min15'],
    },
    version: 'B',
    active: true,
    servingSize: 1,
    yield: 2,
    isDeleted: false,
    __t: 'RecipeAdmin',
    _id: '6272edcd01def1000dc42df1',
    name: 'Asian Chicken Wrap',
    prepTime: 5,
    cookTime: 10,
    cookingInstructions: [
      {
        _id: '64253f87193c86000d3d6555',
        num: 1,
        step:
          'Slice the chicken into strips and prep and chop the vegetables.    ',
      },
      {
        _id: '64253f87193c86000d3d6554',
        num: 2,
        step:
          'Begin by sauteing the chicken with a sprinkle of salt and pepper and a drizzle of olive oil and cook for 8 minutes, until browned.    ',
      },
      {
        _id: '64253f87193c86000d3d6553',
        num: 3,
        step:
          'In a small bowl, combine the chili sauce and sesame oil, then toss in the cooked chicken and stir to combine.    ',
      },
      {
        _id: '64253f87193c86000d3d6552',
        num: 4,
        step:
          'When ready to eat, assemble the wraps by adding the chicken and raw vegetables to the center, then wrap it up and slice in half.  Serve with orange slices.  ',
      },
    ],
    foods: [
      {
        quantity: 7,
        _id: '64253f87193c86000d3d6551',
        measureUnit: 'Ounce-weight',
        food: {
          categories: ['Fresh/Raw Foods', 'Poultry'],
          isDeleted: false,
          _id: '5e2710cbf38d480018056fc9',
          name: 'chicken breast, raw, skinless, boneless',
          verboseName: 'raw chicken breast, skinless, boneless',
          type: 'chicken breast',
          variety: '',
          amount: 4,
          measureUnit: 'Ounce-weight',
          gramWeight: 113.4,
          calories: 120,
          proteins: 26,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 1.5,
          saturatedFat: 0,
          transFat: null,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 65,
          potassium_mg: null,
          sodium_mg: 75,
          updatedAt: '2020-12-01T06:40:04.936Z',
          vitaminD_mcg: null,
          esha: '68651',
          nutrientsPerUnit: {
            calories: 30,
            proteins: 6.5,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 0.375,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 18.75,
            cholesterol_mg: 16.25,
          },
          gramWeightPerUnit: 28.35,
          id: '5e2710cbf38d480018056fc9',
        },
        name: 'chicken breast, raw, skinless, boneless',
      },
      {
        quantity: 0.16666666666666666,
        _id: '64253f87193c86000d3d6550',
        measureUnit: 'Cup',
        food: {
          categories: ['Condiments & Sauces'],
          isDeleted: false,
          _id: '5e2710d6f38d48001805e66b',
          name: 'chili sauce, sweet',
          verboseName: 'sweet chili sauce',
          type: 'chili sauce',
          variety: '',
          amount: 2,
          measureUnit: 'Tablespoon',
          gramWeight: 30,
          calories: 70,
          proteins: 0,
          carbohydrates: 17,
          fiber: 0,
          totalSugar: 14,
          addedSugar: null,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 460,
          updatedAt: '2020-12-01T06:41:11.332Z',
          vitaminD_mcg: null,
          esha: '144633',
          nutrientsPerUnit: {
            calories: 35,
            proteins: 0,
            carbohydrates: 8.5,
            fiber: 0,
            totalSugar: 7,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 230,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 15,
          id: '5e2710d6f38d48001805e66b',
        },
        name: 'chili sauce, sweet',
      },
      {
        quantity: 0.75,
        _id: '64253f87193c86000d3d654f',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Fats Oils & Subs'],
          isDeleted: false,
          _id: '5e2710c9f38d48001805514b',
          name: 'oil, sesame, toasted, unrefined',
          verboseName: 'toasted sesame oil, unrefined',
          type: 'oil',
          variety: 'sesame',
          amount: 1,
          measureUnit: 'Tablespoon',
          gramWeight: 14,
          calories: 120,
          proteins: 0,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 14,
          saturatedFat: 1.5,
          transFat: null,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: null,
          potassium_mg: 0,
          sodium_mg: null,
          updatedAt: '2020-12-01T06:39:46.585Z',
          vitaminD_mcg: 0,
          esha: '44814',
          nutrientsPerUnit: {
            calories: 120,
            proteins: 0,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 14,
            saturatedFat: 1.5,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 0,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 14,
          id: '5e2710c9f38d48001805514b',
        },
        name: 'oil, sesame, toasted, unrefined',
      },
      {
        quantity: 0.375,
        _id: '64253f87193c86000d3d654e',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Vegetables',
            'Vegetables',
            'Vegetables',
          ],
          isDeleted: false,
          _id: '5e2710c5f38d4800180522c6',
          name: 'cabbage, red, fresh, shredded',
          verboseName: 'fresh red cabbage, shredded',
          type: 'cabbage',
          variety: '',
          amount: 1,
          measureUnit: 'Cup',
          gramWeight: 70,
          calories: 21.7,
          proteins: 1.001,
          carbohydrates: 5.159,
          fiber: 1.47,
          totalSugar: 2.681,
          addedSugar: 0,
          fat: 0.112,
          saturatedFat: 0.0147,
          transFat: 0,
          __v: 0,
          calcium_mg: 31.5,
          cholesterol_mg: 0,
          potassium_mg: 170.1,
          sodium_mg: 18.9,
          updatedAt: '2020-12-01T06:39:10.672Z',
          vitaminD_mcg: 0,
          esha: '5042',
          nutrientsPerUnit: {
            calories: 21.7,
            proteins: 1.001,
            carbohydrates: 5.159,
            fiber: 1.47,
            totalSugar: 2.681,
            addedSugar: 0,
            fat: 0.112,
            saturatedFat: 0.0147,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 31.5,
            iron_mg: null,
            potassium_mg: 170.1,
            sodium_mg: 18.9,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 70,
          id: '5e2710c5f38d4800180522c6',
        },
        name: 'cabbage, red, fresh, shredded',
      },
      {
        quantity: 0.375,
        _id: '64253f87193c86000d3d654d',
        measureUnit: 'Cup',
        food: {
          categories: ['Fresh/Raw Foods', 'Vegetables'],
          isDeleted: false,
          _id: '5e2710cdf38d480018058108',
          name: 'carrots, fresh, shredded',
          verboseName: 'fresh carrots, shredded',
          type: 'carrots',
          variety: '',
          amount: 1.5,
          measureUnit: 'Cup',
          gramWeight: 85,
          calories: 35,
          proteins: 1,
          carbohydrates: 9,
          fiber: 2,
          totalSugar: 4,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 20,
          cholesterol_mg: 0,
          potassium_mg: 0,
          sodium_mg: 60,
          updatedAt: '2020-12-01T06:40:14.962Z',
          vitaminD_mcg: null,
          esha: '78407',
          nutrientsPerUnit: {
            calories: 23.333333333333332,
            proteins: 0.6666666666666666,
            carbohydrates: 6,
            fiber: 1.3333333333333333,
            totalSugar: 2.6666666666666665,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 13.333333333333334,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 40,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 56.666666666666664,
          id: '5e2710cdf38d480018058108',
        },
        name: 'carrots, fresh, shredded',
      },
      {
        quantity: 1,
        _id: '64253f87193c86000d3d654c',
        measureUnit: 'Tablespoon',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
          ],
          isDeleted: false,
          _id: '5e2710c5f38d4800180524ca',
          name: 'scallions, fresh, tops & bulb, chopped',
          verboseName: 'fresh scallions, tops & bulb, chopped',
          type: 'scallions',
          variety: '',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 50,
          calories: 16,
          proteins: 0.915,
          carbohydrates: 3.67,
          fiber: 1.3,
          totalSugar: 1.165,
          addedSugar: 0,
          fat: 0.095,
          saturatedFat: 0.016,
          transFat: 0,
          __v: 0,
          calcium_mg: 36,
          cholesterol_mg: 0,
          potassium_mg: 138,
          sodium_mg: 8,
          updatedAt: '2020-12-01T06:39:10.673Z',
          vitaminD_mcg: 0,
          esha: '6834',
          nutrientsPerUnit: {
            calories: 32,
            proteins: 1.83,
            carbohydrates: 7.34,
            fiber: 2.6,
            totalSugar: 2.33,
            addedSugar: 0,
            fat: 0.19,
            saturatedFat: 0.032,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 72,
            iron_mg: null,
            potassium_mg: 276,
            sodium_mg: 16,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 100,
          id: '5e2710c5f38d4800180524ca',
        },
        name: 'scallions, fresh, tops & bulb, chopped',
      },
      {
        quantity: 2,
        _id: '64253f87193c86000d3d654b',
        measureUnit: 'Each',
        food: {
          categories: ['Bread & Bakery'],
          isDeleted: false,
          _id: '5e2710ccf38d4800180576fa',
          name: 'tortilla, wrap, multigrain, soft, extra virgin olive oil',
          verboseName: 'multigrain wrap tortilla, soft, extra virgin olive oil',
          type: 'tortilla',
          variety: 'wrap',
          amount: 1,
          measureUnit: 'Each',
          gramWeight: 62,
          calories: 100,
          proteins: 8,
          carbohydrates: 20,
          fiber: null,
          totalSugar: 1,
          addedSugar: null,
          fat: 3.5,
          saturatedFat: 0.5,
          transFat: 0,
          __v: 0,
          calcium_mg: 60,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 290,
          updatedAt: '2020-12-01T06:40:09.338Z',
          vitaminD_mcg: null,
          esha: '73774',
          nutrientsPerUnit: {
            calories: 100,
            proteins: 8,
            carbohydrates: 20,
            fiber: 0,
            totalSugar: 1,
            addedSugar: 0,
            fat: 3.5,
            saturatedFat: 0.5,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 60,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 290,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 62,
          id: '5e2710ccf38d4800180576fa',
        },
        name: 'tortilla, wrap, multigrain, soft, extra virgin olive oil',
      },
      {
        quantity: 1,
        _id: '64253f87193c86000d3d654a',
        measureUnit: 'Each',
        food: {
          categories: ['Fresh/Raw Foods', 'Fruits'],
          isDeleted: false,
          _id: '5e2710ccf38d48001805737f',
          name: 'orange, fresh',
          verboseName: 'fresh orange',
          type: 'orange',
          variety: '',
          amount: 1,
          measureUnit: 'Each',
          gramWeight: 121,
          calories: 60,
          proteins: 1,
          carbohydrates: 14,
          fiber: 2.625,
          totalSugar: 11.375,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 40,
          cholesterol_mg: 0,
          potassium_mg: 0,
          sodium_mg: 0,
          updatedAt: '2020-12-01T06:40:07.418Z',
          vitaminD_mcg: null,
          esha: '71982',
          nutrientsPerUnit: {
            calories: 60,
            proteins: 1,
            carbohydrates: 14,
            fiber: 2.625,
            totalSugar: 11.375,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 40,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 0,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 121,
          id: '5e2710ccf38d48001805737f',
        },
        name: 'orange, fresh',
      },
      {
        quantity: 0.25,
        _id: '64253f87193c86000d3d6549',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Fats Oils & Subs'],
          isDeleted: false,
          _id: '5e2710c5f38d4800180525ec',
          name: 'oil, olive',
          verboseName: 'olive oil',
          type: 'oil',
          variety: 'olive',
          amount: 1,
          measureUnit: 'Tablespoon',
          gramWeight: 13.5,
          calories: 119.34,
          proteins: 0,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 13.5,
          saturatedFat: 1.86408,
          transFat: null,
          __v: 0,
          calcium_mg: 0.135,
          cholesterol_mg: 0,
          potassium_mg: 0.135,
          sodium_mg: 0.27,
          updatedAt: '2020-12-01T06:39:14.244Z',
          vitaminD_mcg: 0,
          esha: '8008',
          nutrientsPerUnit: {
            calories: 119.34,
            proteins: 0,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 13.5,
            saturatedFat: 1.86408,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0.135,
            iron_mg: null,
            potassium_mg: 0.135,
            sodium_mg: 0.27,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 13.5,
          id: '5e2710c5f38d4800180525ec',
        },
        name: 'oil, olive',
      },
    ],
    __v: 0,
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1651767174085',
    id: '6272edcd01def1000dc42df1',
  },
  {
    nutrients: {
      calories: 707.5255,
      fat: 22.389181666666666,
      saturatedFat: 3.1619891666666664,
      transFat: 0,
      cholesterol_mg: 69.58333333333333,
      sodium_mg: 1292.77,
      carbohydrates: 65.623215,
      fiber: 2.9044583333333334,
      totalSugar: 5.627056666666666,
      addedSugar: 0,
      proteins: 60.80578750000001,
      vitaminD_mcg: 0,
      potassium_mg: 259.0885833333333,
      calcium_mg: 292.35908333333333,
      iron_mg: 0,
    },
    tags: {
      dietType: [],
      mealType: ['lunch', 'dinner'],
      cookingStyle: ['cookingForTwo', 'prepAhead', 'toGo', 'fiveFewer'],
      primaryIngredient: ['turkey'],
      intolerances: ['gluten', 'wheat'],
      prepTime: ['min15', 'noCook'],
    },
    version: 'B',
    active: true,
    servingSize: 1,
    yield: 2,
    isDeleted: false,
    __t: 'RecipeAdmin',
    _id: '64324d06c2ed9f000ddb100a',
    name: 'Greek Turkey Pita Pockets',
    prepTime: 5,
    cookTime: 10,
    cookingInstructions: [
      {
        _id: '6496dfdd32bfe5000dee91e5',
        num: 1,
        step:
          'Prep and chop the ingredients and slice the turkey breast into strips.    ',
      },
      {
        _id: '6496dfdd32bfe5000dee91e4',
        num: 2,
        step:
          'Begin by sauteing the turkey breast strips in a hot pan with the garlic powder, oregano, a sprinkle of salt and pepper, and a drizzle of olive oil for 8 minutes, until the turkey is cooked and seared.    ',
      },
      {
        _id: '6496dfdd32bfe5000dee91e3',
        num: 3,
        step:
          'While the turkey is cooking, lightly toss the spinach, red onion, cucumbers, cherry tomatoes, and feta cheese with a sprinkle of salt and pepper and a drizzle of olive oil.  Then warm the pita in the oven.    ',
      },
      {
        _id: '6496dfdd32bfe5000dee91e2',
        num: 4,
        step:
          'When ready to eat, slice open the pita and stuff with the spinach salad, layer on the turkey and top with a dollop of hummus.  ',
      },
    ],
    foods: [
      {
        quantity: 5,
        _id: '6496dfdd32bfe5000dee91e1',
        measureUnit: 'Ounce-weight',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Poultry',
            'Poultry',
          ],
          isDeleted: false,
          _id: '5e2710caf38d480018055a9b',
          name: 'turkey breast cutlet, raw',
          verboseName: 'raw turkey breast cutlet',
          type: 'turkey breast cutlet',
          variety: '',
          amount: 4,
          measureUnit: 'Ounce-weight',
          gramWeight: 113.4,
          calories: 120,
          proteins: 28,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 0.5,
          saturatedFat: null,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 45,
          potassium_mg: null,
          sodium_mg: 100,
          updatedAt: '2020-12-01T06:39:52.738Z',
          vitaminD_mcg: null,
          esha: '51132',
          nutrientsPerUnit: {
            calories: 30,
            proteins: 7,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 0.125,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 25,
            cholesterol_mg: 11.25,
          },
          gramWeightPerUnit: 28.35,
          id: '5e2710caf38d480018055a9b',
        },
        name: 'turkey breast cutlet, raw',
      },
      {
        quantity: 0.16666666666666666,
        _id: '6496dfdd32bfe5000dee91e0',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
          ],
          isDeleted: false,
          _id: '5e2710c5f38d48001805257b',
          name: 'onion, red, fresh, chopped',
          verboseName: 'fresh red onion, chopped',
          type: 'onion',
          variety: '',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 80,
          calories: 32,
          proteins: 0.88,
          carbohydrates: 7.472,
          fiber: 1.36,
          totalSugar: 3.392,
          addedSugar: 0,
          fat: 0.08,
          saturatedFat: 0.0336,
          transFat: 0,
          __v: 0,
          calcium_mg: 18.4,
          cholesterol_mg: 0,
          potassium_mg: 116.8,
          sodium_mg: 3.2,
          updatedAt: '2020-12-01T06:39:10.673Z',
          vitaminD_mcg: 0,
          esha: '7498',
          nutrientsPerUnit: {
            calories: 64,
            proteins: 1.76,
            carbohydrates: 14.944,
            fiber: 2.72,
            totalSugar: 6.784,
            addedSugar: 0,
            fat: 0.16,
            saturatedFat: 0.0672,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 36.8,
            iron_mg: null,
            potassium_mg: 233.6,
            sodium_mg: 6.4,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 160,
          id: '5e2710c5f38d48001805257b',
        },
        name: 'onion, red, fresh, chopped',
      },
      {
        quantity: 0.375,
        _id: '6496dfdd32bfe5000dee91df',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Vegetables',
            'Vegetables',
            'Vegetables',
          ],
          isDeleted: false,
          _id: '5e2710c5f38d4800180525cf',
          name: 'cucumber, fresh, without skin, chopped',
          verboseName: 'fresh cucumber, without skin, chopped',
          type: 'cucumber',
          variety: '',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 66.5,
          calories: 7.98,
          proteins: 0.39235,
          carbohydrates: 1.4364,
          fiber: 0.4655,
          totalSugar: 0.9177,
          addedSugar: 0,
          fat: 0.1064,
          saturatedFat: 0.00865,
          transFat: 0,
          __v: 0,
          calcium_mg: 9.31,
          cholesterol_mg: 0,
          potassium_mg: 90.44,
          sodium_mg: 1.33,
          updatedAt: '2020-12-01T06:39:14.244Z',
          vitaminD_mcg: 0,
          esha: '7917',
          nutrientsPerUnit: {
            calories: 15.96,
            proteins: 0.7847,
            carbohydrates: 2.8728,
            fiber: 0.931,
            totalSugar: 1.8354,
            addedSugar: 0,
            fat: 0.2128,
            saturatedFat: 0.0173,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 18.62,
            iron_mg: null,
            potassium_mg: 180.88,
            sodium_mg: 2.66,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 133,
          id: '5e2710c5f38d4800180525cf',
        },
        name: 'cucumber, fresh, without skin, chopped',
      },
      {
        quantity: 0.375,
        _id: '6496dfdd32bfe5000dee91de',
        measureUnit: 'Cup',
        food: {
          categories: ['Fresh/Raw Foods', 'Vegetables'],
          isDeleted: false,
          _id: '5e2710caf38d480018055f78',
          name: 'tomatoes, cherry, mixed, fresh, chopped',
          verboseName: 'fresh mixed cherry tomatoes, chopped',
          type: 'tomatoes',
          variety: 'cherry',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 74.5,
          calories: 15,
          proteins: 1,
          carbohydrates: 3,
          fiber: 1,
          totalSugar: 1,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 0,
          potassium_mg: 170,
          sodium_mg: 5,
          updatedAt: '2020-12-01T06:39:54.453Z',
          vitaminD_mcg: null,
          esha: '55694',
          nutrientsPerUnit: {
            calories: 30,
            proteins: 2,
            carbohydrates: 6,
            fiber: 2,
            totalSugar: 2,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 340,
            sodium_mg: 10,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 149,
          id: '5e2710caf38d480018055f78',
        },
        name: 'tomatoes, cherry, mixed, fresh, chopped',
      },
      {
        quantity: 0.75,
        _id: '6496dfdd32bfe5000dee91dd',
        measureUnit: 'Cup',
        food: {
          categories: ['Fresh/Raw Foods', 'Vegetables'],
          isDeleted: false,
          _id: '5e2710c5f38d4800180526ee',
          name: 'spinach, baby, fresh',
          verboseName: 'fresh baby spinach',
          type: 'spinach',
          variety: '',
          amount: 1.5,
          measureUnit: 'Cup',
          gramWeight: 85,
          calories: 20,
          proteins: 2,
          carbohydrates: 3,
          fiber: 2,
          totalSugar: 0,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 80,
          cholesterol_mg: 0,
          potassium_mg: 0,
          sodium_mg: 65,
          updatedAt: '2020-12-01T06:39:14.245Z',
          vitaminD_mcg: null,
          esha: '9317',
          nutrientsPerUnit: {
            calories: 13.333333333333334,
            proteins: 1.3333333333333333,
            carbohydrates: 2,
            fiber: 1.3333333333333333,
            totalSugar: 0,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 53.333333333333336,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 43.333333333333336,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 56.666666666666664,
          id: '5e2710c5f38d4800180526ee',
        },
        name: 'spinach, baby, fresh',
      },
      {
        quantity: 0.5,
        _id: '6496dfdd32bfe5000dee91dc',
        measureUnit: 'Teaspoon',
        food: {
          categories: ['Condiments & Sauces'],
          isDeleted: false,
          _id: '5e2710c7f38d480018053a47',
          name: 'garlic powder',
          verboseName: 'garlic powder',
          type: 'garlic powder',
          variety: '',
          amount: 1,
          measureUnit: 'Teaspoon',
          gramWeight: 3.1,
          calories: 10.261,
          proteins: 0.51305,
          carbohydrates: 2.25463,
          fiber: 0.279,
          totalSugar: 0.07533,
          addedSugar: 0,
          fat: 0.02263,
          saturatedFat: 0.00772,
          transFat: 0,
          __v: 0,
          calcium_mg: 2.449,
          cholesterol_mg: 0,
          potassium_mg: 36.983,
          sodium_mg: 1.86,
          updatedAt: '2020-12-01T06:39:27.702Z',
          vitaminD_mcg: 0,
          esha: '26007',
          nutrientsPerUnit: {
            calories: 10.261,
            proteins: 0.51305,
            carbohydrates: 2.25463,
            fiber: 0.279,
            totalSugar: 0.07533,
            addedSugar: 0,
            fat: 0.02263,
            saturatedFat: 0.00772,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 2.449,
            iron_mg: null,
            potassium_mg: 36.983,
            sodium_mg: 1.86,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 3.1,
          id: '5e2710c7f38d480018053a47',
        },
        name: 'garlic powder',
      },
      {
        quantity: 0.5,
        _id: '6496dfdd32bfe5000dee91db',
        measureUnit: 'Teaspoon',
        food: {
          categories: ['Condiments & Sauces', 'Condiments & Sauces'],
          isDeleted: false,
          _id: '5e2710d0f38d480018059bd2',
          name: 'oregano, dried, leaves',
          verboseName: 'dried oregano, leaves',
          type: 'oregano',
          variety: '',
          amount: 1,
          measureUnit: 'Teaspoon',
          gramWeight: 1,
          calories: 2.65,
          proteins: 0.09,
          carbohydrates: 0.6892,
          fiber: 0.425,
          totalSugar: 0.0409,
          addedSugar: 0,
          fat: 0.0428,
          saturatedFat: 0.01551,
          transFat: 0,
          __v: 0,
          calcium_mg: 15.97,
          cholesterol_mg: 0,
          potassium_mg: 12.6,
          sodium_mg: 0.25,
          updatedAt: '2020-12-01T06:40:31.023Z',
          vitaminD_mcg: 0,
          esha: '93509',
          nutrientsPerUnit: {
            calories: 2.65,
            proteins: 0.09,
            carbohydrates: 0.6892,
            fiber: 0.425,
            totalSugar: 0.0409,
            addedSugar: 0,
            fat: 0.0428,
            saturatedFat: 0.01551,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 15.97,
            iron_mg: null,
            potassium_mg: 12.6,
            sodium_mg: 0.25,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 1,
          id: '5e2710d0f38d480018059bd2',
        },
        name: 'oregano, dried, leaves',
      },
      {
        quantity: 0.375,
        _id: '6496dfdd32bfe5000dee91da',
        measureUnit: 'Cup',
        food: {
          categories: ['Condiments & Sauces', 'Legumes'],
          isDeleted: false,
          _id: '5e2710c6f38d4800180532e0',
          name: 'hummus, traditional',
          verboseName: 'traditional hummus',
          type: 'hummus',
          variety: '',
          amount: 2,
          measureUnit: 'Tablespoon',
          gramWeight: 30,
          calories: 60,
          proteins: 2,
          carbohydrates: 5,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 4,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 20,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 110,
          updatedAt: '2020-12-01T06:39:23.676Z',
          vitaminD_mcg: null,
          esha: '19717',
          nutrientsPerUnit: {
            calories: 30,
            proteins: 1,
            carbohydrates: 2.5,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 2,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 10,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 55,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 15,
          id: '5e2710c6f38d4800180532e0',
        },
        name: 'hummus, traditional',
      },
      {
        quantity: 0.16666666666666666,
        _id: '6496dfdd32bfe5000dee91d9',
        measureUnit: 'Cup',
        food: {
          categories: ['Dairy Products & Subs'],
          isDeleted: false,
          _id: '5e2710c5f38d48001805297a',
          name: 'cheese, feta, traditional, crumbled, reduced fat',
          verboseName: 'traditional feta cheese, crumbled, reduced fat',
          type: 'cheese',
          variety: 'feta',
          amount: 2,
          measureUnit: 'Tablespoon',
          gramWeight: 28,
          calories: 70,
          proteins: 5,
          carbohydrates: 1,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 3,
          saturatedFat: 2,
          transFat: 0,
          __v: 0,
          calcium_mg: 60,
          cholesterol_mg: 10,
          potassium_mg: null,
          sodium_mg: 340,
          updatedAt: '2020-12-01T06:39:14.246Z',
          vitaminD_mcg: null,
          esha: '12628',
          nutrientsPerUnit: {
            calories: 35,
            proteins: 2.5,
            carbohydrates: 0.5,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 1.5,
            saturatedFat: 1,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 30,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 170,
            cholesterol_mg: 5,
          },
          gramWeightPerUnit: 14,
          id: '5e2710c5f38d48001805297a',
        },
        name: 'cheese, feta, traditional, crumbled, reduced fat',
      },
      {
        quantity: 1.5,
        _id: '6496dfdd32bfe5000dee91d8',
        measureUnit: 'Each',
        food: {
          categories: ['Bread & Bakery'],
          isDeleted: false,
          _id: '5e2710cbf38d480018056bc0',
          name: 'bread, pita, 100% whole wheat, Sahara, pocket',
          verboseName: 'Sahara 100% whole wheat pita bread, pocket',
          type: 'bread',
          variety: 'pita',
          amount: 1,
          measureUnit: 'Each',
          gramWeight: 57,
          calories: 140,
          proteins: 7,
          carbohydrates: 27,
          fiber: null,
          totalSugar: 2,
          addedSugar: null,
          fat: 1.5,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 60,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 230,
          updatedAt: '2020-12-01T06:40:02.928Z',
          vitaminD_mcg: null,
          esha: '66695',
          nutrientsPerUnit: {
            calories: 140,
            proteins: 7,
            carbohydrates: 27,
            fiber: 0,
            totalSugar: 2,
            addedSugar: 0,
            fat: 1.5,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 60,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 230,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 57,
          id: '5e2710cbf38d480018056bc0',
        },
        name: 'bread, pita, 100% whole wheat, Sahara, pocket',
      },
      {
        quantity: 0.25,
        _id: '6496dfdd32bfe5000dee91d7',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Fats Oils & Subs'],
          isDeleted: false,
          _id: '5e2710c5f38d4800180525ec',
          name: 'oil, olive',
          verboseName: 'olive oil',
          type: 'oil',
          variety: 'olive',
          amount: 1,
          measureUnit: 'Tablespoon',
          gramWeight: 13.5,
          calories: 119.34,
          proteins: 0,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 13.5,
          saturatedFat: 1.86408,
          transFat: null,
          __v: 0,
          calcium_mg: 0.135,
          cholesterol_mg: 0,
          potassium_mg: 0.135,
          sodium_mg: 0.27,
          updatedAt: '2020-12-01T06:39:14.244Z',
          vitaminD_mcg: 0,
          esha: '8008',
          nutrientsPerUnit: {
            calories: 119.34,
            proteins: 0,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 13.5,
            saturatedFat: 1.86408,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0.135,
            iron_mg: null,
            potassium_mg: 0.135,
            sodium_mg: 0.27,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 13.5,
          id: '5e2710c5f38d4800180525ec',
        },
        name: 'oil, olive',
      },
    ],
    __v: 0,
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687588875273',
    updatedAt: '2023-06-24T12:21:49.654Z',
    id: '64324d06c2ed9f000ddb100a',
  },
  {
    nutrients: {
      calories: 723.027,
      fat: 21.611605833333336,
      saturatedFat: 2.6514233333333332,
      transFat: 0,
      cholesterol_mg: 130,
      sodium_mg: 160.57708333333335,
      carbohydrates: 76.33781833333333,
      fiber: 9.2504225,
      totalSugar: 26.11492916666667,
      addedSugar: 0,
      proteins: 58.64045833333334,
      vitaminD_mcg: 0,
      potassium_mg: 680.5489166666667,
      calcium_mg: 27.1455,
      iron_mg: 0,
    },
    tags: {
      dietType: ['paleo'],
      mealType: ['lunch', 'dinner'],
      cookingStyle: ['fiveFewer', 'prepFreeze'],
      primaryIngredient: ['chicken', 'poultry'],
      intolerances: [],
      prepTime: ['min30'],
    },
    version: 'B',
    active: true,
    servingSize: 1,
    yield: 2,
    isDeleted: false,
    __t: 'RecipeAdmin',
    _id: '6272f0ae01def1000dc42e1d',
    name: 'Chicken with Caribbean Salsa',
    prepTime: 10,
    cookTime: 15,
    cookingInstructions: [
      {
        _id: '64253fc3193c86000d3d6580',
        num: 1,
        step:
          'Prep and chop the fruit and vegetables, into a fine dice, and cook the rice by bringing the rice, a 2:1 ratio of water and a pinch of salt to a boil.  Turn down to low and cook for 15 minutes.    ',
      },
      {
        _id: '64253fc3193c86000d3d657f',
        num: 2,
        step:
          'Season the chicken with the garlic powder and a sprinkle of salt and pepper and cook in a pan with a drizzle of olive oil, 5 minutes per side until golden brown and cooked through.  Then remove, squeeze a fresh lime over the chicken, and set aside.    ',
      },
      {
        _id: '64253fc3193c86000d3d657e',
        num: 3,
        step:
          'While the chicken is cooking, assemble the salsa with the diced mango, pineapple, red bell pepper, red onion, cilantro, and avocado.  Place all ingredients in a bowl and squeeze with fresh lime and season with a sprinkle of salt then mix well.    ',
      },
      {
        _id: '64253fc3193c86000d3d657d',
        num: 4,
        step:
          'When ready to eat, plate the chicken with the salsa and a scoop of rice. Serve with fresh lime.  ',
      },
    ],
    foods: [
      {
        quantity: 8,
        _id: '64253fc3193c86000d3d657c',
        measureUnit: 'Ounce-weight',
        food: {
          categories: ['Fresh/Raw Foods', 'Poultry'],
          isDeleted: false,
          _id: '5e2710cbf38d480018056fc9',
          name: 'chicken breast, raw, skinless, boneless',
          verboseName: 'raw chicken breast, skinless, boneless',
          type: 'chicken breast',
          variety: '',
          amount: 4,
          measureUnit: 'Ounce-weight',
          gramWeight: 113.4,
          calories: 120,
          proteins: 26,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 1.5,
          saturatedFat: 0,
          transFat: null,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 65,
          potassium_mg: null,
          sodium_mg: 75,
          updatedAt: '2020-12-01T06:40:04.936Z',
          vitaminD_mcg: null,
          esha: '68651',
          nutrientsPerUnit: {
            calories: 30,
            proteins: 6.5,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 0.375,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 18.75,
            cholesterol_mg: 16.25,
          },
          gramWeightPerUnit: 28.35,
          id: '5e2710cbf38d480018056fc9',
        },
        name: 'chicken breast, raw, skinless, boneless',
      },
      {
        quantity: 0.75,
        _id: '64253fc3193c86000d3d657b',
        measureUnit: 'Teaspoon',
        food: {
          categories: ['Condiments & Sauces'],
          isDeleted: false,
          _id: '5e2710c7f38d480018053a47',
          name: 'garlic powder',
          verboseName: 'garlic powder',
          type: 'garlic powder',
          variety: '',
          amount: 1,
          measureUnit: 'Teaspoon',
          gramWeight: 3.1,
          calories: 10.261,
          proteins: 0.51305,
          carbohydrates: 2.25463,
          fiber: 0.279,
          totalSugar: 0.07533,
          addedSugar: 0,
          fat: 0.02263,
          saturatedFat: 0.00772,
          transFat: 0,
          __v: 0,
          calcium_mg: 2.449,
          cholesterol_mg: 0,
          potassium_mg: 36.983,
          sodium_mg: 1.86,
          updatedAt: '2020-12-01T06:39:27.702Z',
          vitaminD_mcg: 0,
          esha: '26007',
          nutrientsPerUnit: {
            calories: 10.261,
            proteins: 0.51305,
            carbohydrates: 2.25463,
            fiber: 0.279,
            totalSugar: 0.07533,
            addedSugar: 0,
            fat: 0.02263,
            saturatedFat: 0.00772,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 2.449,
            iron_mg: null,
            potassium_mg: 36.983,
            sodium_mg: 1.86,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 3.1,
          id: '5e2710c7f38d480018053a47',
        },
        name: 'garlic powder',
      },
      {
        quantity: 0.375,
        _id: '64253fc3193c86000d3d657a',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fruits',
            'Fruits',
          ],
          isDeleted: false,
          _id: '5e2710c4f38d4800180520df',
          name: 'mango, fresh, pieces',
          verboseName: 'fresh mango, pieces',
          type: 'mango',
          variety: '',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 82.5,
          calories: 49.5,
          proteins: 0.6765,
          carbohydrates: 12.3585,
          fiber: 1.29578,
          totalSugar: 11.06272,
          addedSugar: 0,
          fat: 0.3135,
          saturatedFat: 0.0759,
          transFat: 0,
          __v: 0,
          calcium_mg: 9.075,
          cholesterol_mg: 0,
          potassium_mg: 138.6,
          sodium_mg: 0.825,
          updatedAt: '2020-12-01T06:38:57.458Z',
          vitaminD_mcg: 0,
          esha: '3220',
          nutrientsPerUnit: {
            calories: 99,
            proteins: 1.353,
            carbohydrates: 24.717,
            fiber: 2.59156,
            totalSugar: 22.12544,
            addedSugar: 0,
            fat: 0.627,
            saturatedFat: 0.1518,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 18.15,
            iron_mg: null,
            potassium_mg: 277.2,
            sodium_mg: 1.65,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 165,
          id: '5e2710c4f38d4800180520df',
        },
        name: 'mango, fresh, pieces',
      },
      {
        quantity: 0.375,
        _id: '64253fc3193c86000d3d6579',
        measureUnit: 'Cup',
        food: {
          categories: ['Canned Foods', 'Fruits'],
          isDeleted: false,
          _id: '5e2710cdf38d4800180580e8',
          name: 'pineapple, chunks',
          verboseName: 'pineapple, chunks',
          type: 'pineapple',
          variety: '',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 122,
          calories: 70,
          proteins: 1,
          carbohydrates: 17,
          fiber: 1,
          totalSugar: 16,
          addedSugar: null,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 0,
          potassium_mg: 150,
          sodium_mg: 0,
          updatedAt: '2020-12-01T06:40:14.962Z',
          vitaminD_mcg: null,
          esha: '78375',
          nutrientsPerUnit: {
            calories: 140,
            proteins: 2,
            carbohydrates: 34,
            fiber: 2,
            totalSugar: 32,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 300,
            sodium_mg: 0,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 244,
          id: '5e2710cdf38d4800180580e8',
        },
        name: 'pineapple, chunks',
      },
      {
        quantity: 0.16666666666666666,
        _id: '64253fc3193c86000d3d6578',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
            'Vegetables',
          ],
          isDeleted: false,
          _id: '5e2710c5f38d48001805257b',
          name: 'onion, red, fresh, chopped',
          verboseName: 'fresh red onion, chopped',
          type: 'onion',
          variety: '',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 80,
          calories: 32,
          proteins: 0.88,
          carbohydrates: 7.472,
          fiber: 1.36,
          totalSugar: 3.392,
          addedSugar: 0,
          fat: 0.08,
          saturatedFat: 0.0336,
          transFat: 0,
          __v: 0,
          calcium_mg: 18.4,
          cholesterol_mg: 0,
          potassium_mg: 116.8,
          sodium_mg: 3.2,
          updatedAt: '2020-12-01T06:39:10.673Z',
          vitaminD_mcg: 0,
          esha: '7498',
          nutrientsPerUnit: {
            calories: 64,
            proteins: 1.76,
            carbohydrates: 14.944,
            fiber: 2.72,
            totalSugar: 6.784,
            addedSugar: 0,
            fat: 0.16,
            saturatedFat: 0.0672,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 36.8,
            iron_mg: null,
            potassium_mg: 233.6,
            sodium_mg: 6.4,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 160,
          id: '5e2710c5f38d48001805257b',
        },
        name: 'onion, red, fresh, chopped',
      },
      {
        quantity: 0.375,
        _id: '64253fc3193c86000d3d6577',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Vegetables',
            'Vegetables',
            'Vegetables',
          ],
          isDeleted: false,
          _id: '5e2710c5f38d480018052303',
          name: 'bell pepper, red, fresh, chopped',
          verboseName: 'fresh red bell pepper, chopped',
          type: 'bell pepper',
          variety: '',
          amount: 0.5,
          measureUnit: 'Cup',
          gramWeight: 74.5,
          calories: 23.095,
          proteins: 0.73755,
          carbohydrates: 4.49235,
          fiber: 1.49745,
          totalSugar: 2.9949,
          addedSugar: 0,
          fat: 0.2235,
          saturatedFat: 0.02012,
          transFat: 0,
          __v: 0,
          calcium_mg: 5.215,
          cholesterol_mg: 0,
          potassium_mg: 157.195,
          sodium_mg: 2.98,
          updatedAt: '2020-12-01T06:39:10.673Z',
          vitaminD_mcg: 0,
          esha: '5128',
          nutrientsPerUnit: {
            calories: 46.19,
            proteins: 1.4751,
            carbohydrates: 8.9847,
            fiber: 2.9949,
            totalSugar: 5.9898,
            addedSugar: 0,
            fat: 0.447,
            saturatedFat: 0.04024,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 10.43,
            iron_mg: null,
            potassium_mg: 314.39,
            sodium_mg: 5.96,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 149,
          id: '5e2710c5f38d480018052303',
        },
        name: 'bell pepper, red, fresh, chopped',
      },
      {
        quantity: 0.16666666666666666,
        _id: '64253fc3193c86000d3d6576',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Condiments & Sauces',
            'Condiments & Sauces',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
          ],
          isDeleted: false,
          _id: '5e2710cff38d4800180599e1',
          name: 'cilantro, fresh, leaves',
          verboseName: 'fresh cilantro, leaves',
          type: 'cilantro',
          variety: '',
          amount: 1,
          measureUnit: 'Tablespoon',
          gramWeight: 1,
          calories: 0.23,
          proteins: 0.0213,
          carbohydrates: 0.0367,
          fiber: 0.028,
          totalSugar: 0.0087,
          addedSugar: 0,
          fat: 0.0052,
          saturatedFat: 0.00014,
          transFat: 0,
          __v: 0,
          calcium_mg: 0.67,
          cholesterol_mg: 0,
          potassium_mg: 5.21,
          sodium_mg: 0.46,
          updatedAt: '2020-12-01T06:40:29.319Z',
          vitaminD_mcg: 0,
          esha: '92175',
          nutrientsPerUnit: {
            calories: 0.23,
            proteins: 0.0213,
            carbohydrates: 0.0367,
            fiber: 0.028,
            totalSugar: 0.0087,
            addedSugar: 0,
            fat: 0.0052,
            saturatedFat: 0.00014,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0.67,
            iron_mg: null,
            potassium_mg: 5.21,
            sodium_mg: 0.46,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 1,
          id: '5e2710cff38d4800180599e1',
        },
        name: 'cilantro, fresh, leaves',
      },
      {
        quantity: 0.25,
        _id: '64253fc3193c86000d3d6575',
        measureUnit: 'Cup',
        food: {
          categories: ['Rice'],
          isDeleted: false,
          _id: '5e2710c9f38d48001805540a',
          name: 'rice, jasmine, dry',
          verboseName: 'dry jasmine rice',
          type: 'rice',
          variety: 'jasmine',
          amount: 0.25,
          measureUnit: 'Cup',
          gramWeight: 45,
          calories: 160,
          proteins: 3,
          carbohydrates: 35,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 0,
          updatedAt: '2020-12-01T06:39:46.586Z',
          vitaminD_mcg: null,
          esha: '46522',
          nutrientsPerUnit: {
            calories: 640,
            proteins: 12,
            carbohydrates: 140,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 0,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 180,
          id: '5e2710c9f38d48001805540a',
        },
        name: 'rice, jasmine, dry',
      },
      {
        quantity: 0.375,
        _id: '64253fc3193c86000d3d6574',
        measureUnit: 'Cup',
        food: {
          categories: [
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Fresh/Raw Foods',
            'Vegetables',
            'Vegetables',
            'Vegetables',
          ],
          isDeleted: false,
          _id: '5e2710c4f38d480018052134',
          name: 'avocado, fresh, sliced',
          verboseName: 'fresh avocado, sliced',
          type: 'avocado',
          variety: '',
          amount: 0.25,
          measureUnit: 'Cup',
          gramWeight: 36.5,
          calories: 58.4,
          proteins: 0.73,
          carbohydrates: 3.11345,
          fiber: 2.4455,
          totalSugar: 0.2409,
          addedSugar: 0,
          fat: 5.3509,
          saturatedFat: 0.77599,
          transFat: 0,
          __v: 0,
          calcium_mg: 4.38,
          cholesterol_mg: 0,
          potassium_mg: 177.025,
          sodium_mg: 2.555,
          updatedAt: '2020-12-01T06:38:57.458Z',
          vitaminD_mcg: 0,
          esha: '3658',
          nutrientsPerUnit: {
            calories: 233.6,
            proteins: 2.92,
            carbohydrates: 12.4538,
            fiber: 9.782,
            totalSugar: 0.9636,
            addedSugar: 0,
            fat: 21.4036,
            saturatedFat: 3.10396,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 17.52,
            iron_mg: null,
            potassium_mg: 708.1,
            sodium_mg: 10.22,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 146,
          id: '5e2710c4f38d480018052134',
        },
        name: 'avocado, fresh, sliced',
      },
      {
        quantity: 1,
        _id: '64253fc3193c86000d3d6573',
        measureUnit: 'Each',
        food: {
          categories: ['Fresh/Raw Foods', 'Fruits'],
          isDeleted: false,
          _id: '5e2710caf38d48001805620f',
          name: 'lime, fresh, organic',
          verboseName: 'fresh lime, organic',
          type: 'lime',
          variety: '',
          amount: 1,
          measureUnit: 'Each',
          gramWeight: 67,
          calories: 20,
          proteins: 0,
          carbohydrates: 7,
          fiber: 2,
          totalSugar: 2,
          addedSugar: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
          __v: 0,
          calcium_mg: 0,
          cholesterol_mg: 0,
          potassium_mg: null,
          sodium_mg: 0,
          updatedAt: '2020-12-01T06:39:56.450Z',
          vitaminD_mcg: null,
          esha: '58718',
          nutrientsPerUnit: {
            calories: 20,
            proteins: 0,
            carbohydrates: 7,
            fiber: 2,
            totalSugar: 2,
            addedSugar: 0,
            fat: 0,
            saturatedFat: 0,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0,
            iron_mg: null,
            potassium_mg: 0,
            sodium_mg: 0,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 67,
          id: '5e2710caf38d48001805620f',
        },
        name: 'lime, fresh, organic',
      },
      {
        quantity: 0.75,
        _id: '64253fc3193c86000d3d6572',
        measureUnit: 'Tablespoon',
        food: {
          categories: ['Fats Oils & Subs'],
          isDeleted: false,
          _id: '5e2710c5f38d4800180525ec',
          name: 'oil, olive',
          verboseName: 'olive oil',
          type: 'oil',
          variety: 'olive',
          amount: 1,
          measureUnit: 'Tablespoon',
          gramWeight: 13.5,
          calories: 119.34,
          proteins: 0,
          carbohydrates: 0,
          fiber: 0,
          totalSugar: 0,
          addedSugar: 0,
          fat: 13.5,
          saturatedFat: 1.86408,
          transFat: null,
          __v: 0,
          calcium_mg: 0.135,
          cholesterol_mg: 0,
          potassium_mg: 0.135,
          sodium_mg: 0.27,
          updatedAt: '2020-12-01T06:39:14.244Z',
          vitaminD_mcg: 0,
          esha: '8008',
          nutrientsPerUnit: {
            calories: 119.34,
            proteins: 0,
            carbohydrates: 0,
            fiber: 0,
            totalSugar: 0,
            addedSugar: 0,
            fat: 13.5,
            saturatedFat: 1.86408,
            transFat: 0,
            vitaminD_mcg: 0,
            calcium_mg: 0.135,
            iron_mg: null,
            potassium_mg: 0.135,
            sodium_mg: 0.27,
            cholesterol_mg: 0,
          },
          gramWeightPerUnit: 13.5,
          id: '5e2710c5f38d4800180525ec',
        },
        name: 'oil, olive',
      },
    ],
    __v: 0,
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1651767431383',
    id: '6272f0ae01def1000dc42e1d',
  },
];

export const workouts = [
  {
    restBetweenSets: {
      warmUp: 1,
      core: 1,
      strength: 1,
      circuit: 1,
      conditioning: 1,
      coolDown: 1,
    },
    tags: { level: ['beginner'], location: ['gym'], specialtyFocus: [] },
    numberOfWeeks: 4,
    active: true,
    gender: 'Men',
    isDeleted: false,
    __t: 'WorkoutAdmin',
    _id: '63cf50be76e693000d90761c',
    locationType: 'Gym',
    name: 'Foundational Movement II:  Developing Strength (gym)',
    workoutInfo: [
      {
        week: 1,
        _id: '63cf50be76e693000d907620',
        intensity: 3,
        duration: 24,
        caloriesBurned: 220,
      },
      {
        week: 2,
        _id: '63cf50be76e693000d90761f',
        intensity: 3,
        duration: 29,
        caloriesBurned: 268,
      },
      {
        week: 3,
        _id: '63cf50be76e693000d90761e',
        intensity: 3,
        duration: 33,
        caloriesBurned: 317,
      },
      {
        week: 4,
        _id: '63cf50be76e693000d90761d',
        intensity: 3,
        duration: 38,
        caloriesBurned: 358,
      },
    ],
    exercises: [
      {
        _id: '63cf50be76e693000d907694',
        workoutSequence: 'Warm Up',
        exercise: {
          tags: { level: [], location: [], specialtyFocus: [] },
          active: true,
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          instructions: [
            { _id: '639899dffe2fde000dea1450', num: null, step: '' },
          ],
          _id: '639899dffe2fde000dea144f',
          name: 'Quadruped Thoracic Rotation',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685006986658',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688866480936',
          updatedAt: '2023-07-09T01:34:41.718Z',
          id: '639899dffe2fde000dea144f',
        },
      },
      {
        _id: '63cf50be76e693000d90768f',
        workoutSequence: 'Warm Up',
        exercise: {
          tags: { level: [], location: [], specialtyFocus: [] },
          active: true,
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          instructions: [
            { _id: '63989b5aeb23b5000d326b05', num: null, step: '' },
          ],
          _id: '63989b5aeb23b5000d326b04',
          name: '3 Point Hamstring Stretch',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688866626540',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685008715653',
          updatedAt: '2023-07-09T01:37:07.335Z',
          id: '63989b5aeb23b5000d326b04',
        },
      },
      {
        _id: '63cf50be76e693000d90768a',
        workoutSequence: 'Warm Up',
        exercise: {
          tags: { level: [], location: [], specialtyFocus: [] },
          active: true,
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          instructions: [
            { _id: '63989b9ceb23b5000d326b09', num: null, step: '' },
          ],
          _id: '63989b9ceb23b5000d326b08',
          name: 'Body Weight Reverse Lunges',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1689333256705',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688873281646',
          updatedAt: '2023-07-14T11:14:35.712Z',
          id: '63989b9ceb23b5000d326b08',
        },
      },
      {
        _id: '63cf50be76e693000d90763a',
        workoutSequence: 'Core',
        exercise: {
          tags: { level: [], location: [], specialtyFocus: [] },
          active: true,
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          instructions: [
            { _id: '6398a017eb23b5000d326b75', num: null, step: '' },
          ],
          _id: '6398a017eb23b5000d326b74',
          name: 'Beast Lift Holds, 10 secs',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686900999509',
          updatedAt: '2023-06-28T12:17:43.381Z',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686931320049',
          id: '6398a017eb23b5000d326b74',
        },
      },
      {
        _id: '63cf50be76e693000d907630',
        workoutSequence: 'Core',
        exercise: {
          tags: { level: [], location: [], specialtyFocus: [] },
          active: true,
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          instructions: [
            { _id: '63989f77eb23b5000d326b5b', num: null, step: '' },
          ],
          _id: '63989f77eb23b5000d326b5a',
          name: 'Deadbugs, alternating',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685019544795',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688874317429',
          updatedAt: '2023-07-09T03:45:18.349Z',
          id: '63989f77eb23b5000d326b5a',
        },
      },
      {
        _id: '63cf50be76e693000d90767c',
        workoutSequence: 'Circuit',
        exercise: {
          tags: { level: [], location: [], specialtyFocus: [] },
          active: true,
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          instructions: [
            { _id: '6399d3504386ed000daa6233', num: null, step: '' },
          ],
          _id: '6399d3504386ed000daa6232',
          name: 'Elevated Push Ups',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686793129424',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686793151859',
          updatedAt: '2023-06-28T12:07:19.336Z',
          id: '6399d3504386ed000daa6232',
        },
      },
      {
        _id: '63cf50be76e693000d90766e',
        workoutSequence: 'Circuit',
        exercise: {
          tags: { level: [], location: [], specialtyFocus: [] },
          active: true,
          repUnits: 'Reps',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          instructions: [
            { _id: '6398a4eeeb23b5000d326c35', num: null, step: '' },
          ],
          _id: '6398a4eeeb23b5000d326c34',
          name: 'Suitcase Deadlift',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685236309513',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688900876670',
          updatedAt: '2023-07-09T11:07:57.531Z',
          id: '6398a4eeeb23b5000d326c34',
        },
      },
      {
        _id: '63cf50be76e693000d907660',
        workoutSequence: 'Circuit',
        exercise: {
          tags: { level: [], location: [], specialtyFocus: [] },
          active: true,
          repUnits: 'Reps Per Side',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          instructions: [
            { _id: '6398a391eb23b5000d326bf9', num: null, step: '' },
          ],
          _id: '6398a391eb23b5000d326bf8',
          name: 'Half Kneel Single Arm Shoulder Press',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685166175805',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688877540801',
          updatedAt: '2023-07-09T04:39:01.620Z',
          id: '6398a391eb23b5000d326bf8',
        },
      },
      {
        _id: '63cf50be76e693000d907652',
        workoutSequence: 'Circuit',
        exercise: {
          tags: { level: [], location: [], specialtyFocus: [] },
          active: true,
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          instructions: [
            { _id: '6398a42eeb23b5000d326c11', num: null, step: '' },
          ],
          _id: '6398a42eeb23b5000d326c10',
          name: 'Pull Up, with box assist',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685235685692',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686912126354',
          updatedAt: '2023-06-20T11:10:16.514Z',
          id: '6398a42eeb23b5000d326c10',
        },
      },
      {
        _id: '63cf50be76e693000d907644',
        workoutSequence: 'Circuit',
        exercise: {
          tags: { level: [], location: [], specialtyFocus: [] },
          active: true,
          repUnits: 'Reps Per Side',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          instructions: [
            { _id: '6398a52beb23b5000d326c43', num: null, step: '' },
          ],
          _id: '6398a52beb23b5000d326c42',
          name: 'Reverse Lunges, with 2 weights',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685236555263',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688900963068',
          updatedAt: '2023-07-09T11:09:23.950Z',
          id: '6398a52beb23b5000d326c42',
        },
      },
      {
        _id: '63cf50be76e693000d90762b',
        workoutSequence: 'Cool Down',
        exercise: {
          tags: { level: [], location: [], specialtyFocus: [] },
          active: true,
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          instructions: [
            { _id: '6399d1f04386ed000daa61fb', num: null, step: '' },
          ],
          _id: '6399d1f04386ed000daa61fa',
          name: 'Hands Behind Back Shoulder Stretch',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686559443180',
          updatedAt: '2023-06-20T22:22:20.679Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686923535859',
          id: '6399d1f04386ed000daa61fa',
        },
      },
      {
        _id: '63cf50be76e693000d907626',
        workoutSequence: 'Cool Down',
        exercise: {
          tags: { level: [], location: [], specialtyFocus: [] },
          active: true,
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          instructions: [
            { _id: '6399d1c04386ed000daa61f3', num: null, step: '' },
          ],
          _id: '6399d1c04386ed000daa61f2',
          name: 'Lat and Side Stretch',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686551939049',
          updatedAt: '2023-07-09T11:23:26.111Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901805313',
          id: '6399d1c04386ed000daa61f2',
        },
      },
      {
        _id: '63cf50be76e693000d907621',
        workoutSequence: 'Cool Down',
        exercise: {
          tags: { level: [], location: [], specialtyFocus: [] },
          active: true,
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          instructions: [
            { _id: '6399d2544386ed000daa620b', num: null, step: '' },
          ],
          _id: '6399d2544386ed000daa620a',
          name: 'Half Kneel Hip Flexor Stretch',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686701415875',
          updatedAt: '2023-07-09T11:25:03.502Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901902660',
          id: '6399d2544386ed000daa620a',
        },
      },
    ],
    updatedAt: '2023-07-24T23:19:20.807Z',
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687615553015',
    id: '63cf50be76e693000d90761c',
  },
  {
    _id: '63f9716e09c48f000dca10cd',
    restBetweenSets: {
      warmUp: 1,
      core: 1,
      strength: 1,
      circuit: 1,
      conditioning: 1,
      coolDown: 1,
    },
    numberOfWeeks: 4,
    active: true,
    gender: 'Men',
    isDeleted: false,
    __t: 'WorkoutAdmin',
    locationType: 'Gym',
    name: 'Power Strength I:  Leveraging the Core (gym)',
    workoutInfo: [
      {
        week: 1,
        _id: '63f9716e09c48f000dca10d1',
        intensity: 3,
        duration: 25,
        caloriesBurned: 239,
      },
      {
        week: 2,
        _id: '63f9716e09c48f000dca10d0',
        intensity: 3,
        duration: 31,
        caloriesBurned: 296,
      },
      {
        week: 3,
        _id: '63f9716e09c48f000dca10cf',
        intensity: 3,
        duration: 36,
        caloriesBurned: 353,
      },
      {
        week: 4,
        _id: '63f9716e09c48f000dca10ce',
        intensity: 3,
        duration: 41,
        caloriesBurned: 396,
      },
    ],
    exercises: [
      {
        _id: '63f9716e09c48f000dca114e',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989a8eeb23b5000d326af5',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Thread the Needle',
          instructions: [
            {
              _id: '63989a8eeb23b5000d326af6',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685007251624',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688866535667',
          updatedAt: '2023-07-09T01:35:36.475Z',
        },
      },
      {
        _id: '63f9716e09c48f000dca1149',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '639798bce262b2000d49686e',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Arm Circles, both directions',
          instructions: [
            {
              _id: '639798bce262b2000d49686f',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685006693746',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686645536214',
          updatedAt: '2023-06-19T21:52:45.781Z',
        },
      },
      {
        _id: '63f9716e09c48f000dca1144',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989c2eeb23b5000d326b10',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Single Leg Hamstring Scoop to Lateral Lunge',
          instructions: [
            {
              _id: '63989c2eeb23b5000d326b11',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685009013870',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688873438759',
          updatedAt: '2023-07-09T03:30:39.688Z',
        },
      },
      {
        _id: '63f9716e09c48f000dca10e6',
        workoutSequence: 'Core',
        exercise: {
          _id: '6398a15beb23b5000d326b8c',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Plank Leg Tap, alternating',
          instructions: [
            {
              _id: '6398a15beb23b5000d326b8d',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685097401598',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686903399839',
          updatedAt: '2023-06-19T23:49:05.529Z',
        },
      },
      {
        _id: '63f9716e09c48f000dca10dc',
        workoutSequence: 'Core',
        exercise: {
          _id: '6398a1edeb23b5000d326baa',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Single Leg Bridge with Knee Hug',
          instructions: [
            {
              _id: '6398a1edeb23b5000d326bab',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685098101325',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686904403674',
          updatedAt: '2023-06-20T10:08:48.214Z',
        },
      },
      {
        _id: '63f9716e09c48f000dca1136',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a597eb23b5000d326c5c',
          repUnits: 'Reps Per Side',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Single Leg Deadlift to Knee Raise',
          instructions: [
            {
              _id: '6398a597eb23b5000d326c5d',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686918840224',
          updatedAt: '2023-06-28T14:07:46.658Z',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687959888504',
        },
      },
      {
        _id: '63f9716e09c48f000dca1128',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a3ceeb23b5000d326c04',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Eccentric Pull Ups',
          instructions: [
            {
              _id: '6398a3ceeb23b5000d326c05',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686884258467',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-06-20T11:02:25.947Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686911648190',
        },
      },
      {
        _id: '63f9716e09c48f000dca111a',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a2b1eb23b5000d326bca',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Pike Up to Push Up',
          instructions: [
            {
              _id: '6398a2b1eb23b5000d326bcb',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685156373190',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688877129796',
          updatedAt: '2023-07-09T04:32:10.648Z',
        },
      },
      {
        _id: '63f9716e09c48f000dca110c',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '63a493e04ee3e5000dce1c8a',
          repUnits: 'Reps Per Side',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Reverse Lunge to Lateral Lunge',
          instructions: [
            {
              _id: '63a493e04ee3e5000dce1c8b',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685440835626',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686919011501',
          updatedAt: '2023-06-20T21:24:45.779Z',
        },
      },
      {
        _id: '63f9716e09c48f000dca10fe',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a610eb23b5000d326c72',
          repUnits: 'Reps',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Tricep Kickback to Back Fly',
          instructions: [
            {
              _id: '6398a610eb23b5000d326c73',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686336888069',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-09T11:12:43.385Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901162554',
        },
      },
      {
        _id: '63f9716e09c48f000dca10f0',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '63cc4a50c29263000d6607d0',
          repUnits: 'Reps',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Dumbbell Raise, palms up',
          instructions: [
            {
              _id: '63cc4a50c29263000d6607d1',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686926725818',
          updatedAt: '2023-06-28T12:54:50.861Z',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687575042255',
        },
      },
      {
        _id: '63f9716e09c48f000dca10d7',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d1b44386ed000daa61f0',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Arm Series Stretch',
          instructions: [
            {
              _id: '6399d1b44386ed000daa61f1',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686551627922',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-09T11:23:37.911Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901817012',
        },
      },
      {
        _id: '63f9716e09c48f000dca10d2',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d2254386ed000daa6204',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Leg Series Stretch',
          instructions: [
            {
              _id: '6399d2254386ed000daa6205',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686701183129',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-06-21T11:15:02.802Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686923733258',
        },
      },
    ],
    __v: 0,
    tags: {
      level: ['intermediate'],
      location: ['gym'],
      specialtyFocus: [],
    },
    updatedAt: '2023-07-25T11:48:51.650Z',
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1690239675387',
  },
  {
    _id: '64b6d425950bb1000dd59558',
    restBetweenSets: {
      warmUp: 1,
      core: 1,
      strength: 1,
      circuit: 1,
      conditioning: 1,
      coolDown: 1,
    },
    tags: {
      level: [],
      location: ['gym', 'home'],
      specialtyFocus: ['upperBody'],
    },
    numberOfWeeks: 1,
    active: true,
    gender: 'All',
    isDeleted: false,
    __t: 'WorkoutAdmin',
    name: 'Upper Body Series:  Workout 4',
    locationType: 'Home',
    workoutInfo: [
      {
        week: 1,
        _id: '64b6d365950bb1000dd59547',
        intensity: 3,
        duration: 25,
        caloriesBurned: 260,
      },
    ],
    exercises: [
      {
        _id: '64b7c9aff632de000d8bc4a4',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989a9deb23b5000d326af7',
          repUnits: 'Secs',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Foam Roll: Thoracic Spine',
          instructions: [
            {
              _id: '63989a9deb23b5000d326af8',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685007771071',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686700916466',
          updatedAt: '2023-06-19T22:19:39.010Z',
        },
      },
      {
        _id: '64b7c9aff632de000d8bc4a3',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989abfeb23b5000d326afb',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Chest Openers on Foam Roller',
          instructions: [
            {
              _id: '63989abfeb23b5000d326afc',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685008279681',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686815116575',
          updatedAt: '2023-06-19T22:24:46.762Z',
        },
      },
      {
        _id: '64b7c9aff632de000d8bc4a2',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '639899dffe2fde000dea144f',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Quadruped Thoracic Rotation',
          instructions: [
            {
              _id: '639899dffe2fde000dea1450',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685006986658',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688866480936',
          updatedAt: '2023-07-09T01:34:41.718Z',
        },
      },
      {
        _id: '64b7c9aff632de000d8bc4a0',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6284f95210c657000d3b5fb0',
          repUnits: 'Reps',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Dumbbell Front to Lateral Raise',
          instructions: [
            {
              _id: '6284f95210c657000d3b5fb1',
              num: null,
              step: '',
            },
          ],
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1652881746205',
          __v: 0,
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1655742312455',
          active: true,
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-06-21T12:30:05.511Z',
        },
      },
      {
        _id: '64b7c9aff632de000d8bc49f',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6399d36c4386ed000daa6236',
          repUnits: 'Reps Per Side',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Single Arm Bent Over Rows',
          instructions: [
            {
              _id: '6399d36c4386ed000daa6237',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686884685126',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-06-21T11:36:44.425Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686924341611',
        },
      },
      {
        _id: '64b7c9aff632de000d8bc49e',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a2dceb23b5000d326bd4',
          repUnits: 'Reps',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Alternating Chest Press, on floor',
          instructions: [
            {
              _id: '6398a2dceb23b5000d326bd5',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685156680129',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686905825171',
          updatedAt: '2023-06-20T10:34:48.985Z',
        },
      },
      {
        _id: '64b7c9aff632de000d8bc4a1',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a494eb23b5000d326c1e',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Band Bent Over Lat Pull Hold',
          instructions: [
            {
              _id: '6398a494eb23b5000d326c1f',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685235918565',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688893163092',
          updatedAt: '2023-07-09T08:59:23.900Z',
        },
      },
      {
        _id: '64b7c9aff632de000d8bc49d',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a5cbeb23b5000d326c68',
          repUnits: 'Reps',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Bicep Curl to Overhead Press',
          instructions: [
            {
              _id: '6398a5cbeb23b5000d326c69',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686335118653',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-09T11:11:39.240Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901098392',
        },
      },
      {
        _id: '64b7c9aff632de000d8bc49c',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d3234386ed000daa6228',
          repUnits: 'Secs',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Yoga Flow:  Cat to Cow',
          instructions: [
            {
              _id: '6399d3234386ed000daa6229',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686789728695',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686789738985',
          updatedAt: '2023-06-28T12:04:48.378Z',
        },
      },
      {
        _id: '64b7c9aff632de000d8bc49b',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d21c4386ed000daa6202',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Book Opener Stretch',
          instructions: [
            {
              _id: '6423d3a29b2f86000db7593b',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686701121010',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-06-21T11:13:15.220Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686923702886',
        },
      },
      {
        _id: '64b7c9aff632de000d8bc49a',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d1c04386ed000daa61f2',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Lat and Side Stretch',
          instructions: [
            {
              _id: '6399d1c04386ed000daa61f3',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686551939049',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-09T11:23:26.111Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901805313',
        },
      },
    ],
    createdAt: '2023-07-18T18:04:21.996Z',
    updatedAt: '2023-08-07T12:30:17.151Z',
    __v: 0,
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1689804184308',
  },
  {
    _id: '63f9545a09c48f000dca0e10',
    restBetweenSets: {
      warmUp: 1,
      core: 1,
      strength: 1,
      circuit: 1,
      conditioning: 1,
      coolDown: 1,
    },
    numberOfWeeks: 4,
    active: true,
    gender: 'Men',
    isDeleted: false,
    __t: 'WorkoutAdmin',
    locationType: 'Gym',
    name: 'Strength & Conditioning IV:  Powering the Circuits (gym)',
    workoutInfo: [
      {
        week: 1,
        _id: '63f9545a09c48f000dca0e14',
        intensity: 3,
        duration: 29,
        caloriesBurned: 258,
      },
      {
        week: 2,
        _id: '63f9545a09c48f000dca0e13',
        intensity: 3,
        duration: 35,
        caloriesBurned: 311,
      },
      {
        week: 3,
        _id: '63f9545a09c48f000dca0e12',
        intensity: 3,
        duration: 40,
        caloriesBurned: 365,
      },
      {
        week: 4,
        _id: '63f9545a09c48f000dca0e11',
        intensity: 3,
        duration: 46,
        caloriesBurned: 415,
      },
    ],
    exercises: [
      {
        _id: '63f9545a09c48f000dca0e96',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989a9deb23b5000d326af7',
          repUnits: 'Secs',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Foam Roll: Thoracic Spine',
          instructions: [
            {
              _id: '63989a9deb23b5000d326af8',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685007771071',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686700916466',
          updatedAt: '2023-06-19T22:19:39.010Z',
        },
      },
      {
        _id: '63f9545a09c48f000dca0e91',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '639899dffe2fde000dea144f',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Quadruped Thoracic Rotation',
          instructions: [
            {
              _id: '639899dffe2fde000dea1450',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685006986658',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688866480936',
          updatedAt: '2023-07-09T01:34:41.718Z',
        },
      },
      {
        _id: '63f9545a09c48f000dca0e8c',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989bf6eb23b5000d326b0a',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Body Weight Lateral Lunges',
          instructions: [
            {
              _id: '63989bf6eb23b5000d326b0b',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685008852541',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688873310227',
          updatedAt: '2023-07-09T03:28:31.073Z',
        },
      },
      {
        _id: '63f9545a09c48f000dca0e2e',
        workoutSequence: 'Core',
        exercise: {
          _id: '6398a20deb23b5000d326bb0',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Side Plank Hip Lifts',
          instructions: [
            {
              _id: '6398a20deb23b5000d326bb1',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686904554874',
          updatedAt: '2023-06-28T12:23:50.255Z',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687574018408',
        },
      },
      {
        _id: '63f9545a09c48f000dca0e24',
        workoutSequence: 'Core',
        exercise: {
          _id: '6398a04feb23b5000d326b80',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Deadbugs, same side',
          instructions: [
            {
              _id: '6398a04feb23b5000d326b81',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685097251340',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686902698346',
          updatedAt: '2023-06-19T23:44:48.249Z',
        },
      },
      {
        _id: '63f9545a09c48f000dca0e7e',
        workoutSequence: 'Strength',
        exercise: {
          _id: '6398a53deb23b5000d326c46',
          repUnits: 'Reps Per Side',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Lateral Lunges, with 2 weights',
          instructions: [
            {
              _id: '6398a53deb23b5000d326c47',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685236613725',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688900980824',
          updatedAt: '2023-07-09T11:09:41.731Z',
        },
      },
      {
        _id: '63f9545a09c48f000dca0e70',
        workoutSequence: 'Strength',
        exercise: {
          _id: '63cc4970c29263000d6607c4',
          repUnits: 'Reps',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Dumbbell Bent Over Row',
          instructions: [
            {
              _id: '63cc4970c29263000d6607c5',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686926509722',
          updatedAt: '2023-06-28T12:28:46.055Z',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687574713874',
        },
      },
      {
        _id: '63f9545a09c48f000dca0e62',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6399d0057bde01000db0c05c',
          repUnits: 'Reps Per Side',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Stir the Pot, on cable',
          instructions: [
            {
              _id: '6399d0057bde01000db0c05d',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686548040508',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-06-20T21:54:10.164Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686920513519',
        },
      },
      {
        _id: '63f9545a09c48f000dca0e54',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a270eb23b5000d326bc4',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Staggered Push Ups, alternating',
          instructions: [
            {
              _id: '6398a270eb23b5000d326bc5',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685156057791',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686905356843',
          updatedAt: '2023-06-20T10:27:50.799Z',
        },
      },
      {
        _id: '63f9545a09c48f000dca0e46',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a37feb23b5000d326bf4',
          repUnits: 'Reps',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Kneeling Pull Down Cable, palms in',
          instructions: [
            {
              _id: '6398a37feb23b5000d326bf5',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685166124905',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686911176949',
          updatedAt: '2023-06-20T10:54:24.759Z',
        },
      },
      {
        _id: '63f9545a09c48f000dca0e38',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6399d00e7bde01000db0c05e',
          repUnits: 'Secs',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Quick Step Backs',
          instructions: [
            {
              _id: '6399d00e7bde01000db0c05f',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686548106704',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-09T11:18:06.362Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901485479',
        },
      },
      {
        _id: '63f9545a09c48f000dca0e1f',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d1f04386ed000daa61fa',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Hands Behind Back Shoulder Stretch',
          instructions: [
            {
              _id: '6399d1f04386ed000daa61fb',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686559443180',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-06-20T22:22:20.679Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686923535859',
        },
      },
      {
        _id: '63f9545a09c48f000dca0e1a',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d1e84386ed000daa61f8',
          repUnits: 'Secs',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Childs Pose',
          instructions: [
            {
              _id: '6399d1e84386ed000daa61f9',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686552297439',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-06-20T22:21:36.585Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686923500334',
        },
      },
      {
        _id: '63f9545a09c48f000dca0e15',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d28f4386ed000daa6216',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Half Kneel Adductor Stretch',
          instructions: [
            {
              _id: '6399d28f4386ed000daa6217',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686702222061',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686702223671',
          updatedAt: '2023-06-21T11:20:42.193Z',
        },
      },
    ],
    __v: 0,
    tags: {
      level: ['intermediate'],
      location: ['gym'],
      specialtyFocus: [],
    },
    updatedAt: '2023-07-25T11:12:33.594Z',
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687661179016',
  },
  {
    _id: '64b6d3e8950bb1000dd59552',
    restBetweenSets: {
      warmUp: 1,
      core: 1,
      strength: 1,
      circuit: 1,
      conditioning: 1,
      coolDown: 1,
    },
    tags: {
      level: [],
      location: ['gym', 'home'],
      specialtyFocus: ['absCore'],
    },
    numberOfWeeks: 1,
    active: true,
    gender: 'All',
    isDeleted: false,
    __t: 'WorkoutAdmin',
    name: 'Core Series:  Workout 5',
    locationType: 'Home',
    workoutInfo: [
      {
        week: 1,
        _id: '64b6d365950bb1000dd59547',
        intensity: 3,
        duration: 25,
        caloriesBurned: 260,
      },
    ],
    exercises: [
      {
        _id: '64b70b97950bb1000dd59737',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989a8eeb23b5000d326af5',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Thread the Needle',
          instructions: [
            {
              _id: '63989a8eeb23b5000d326af6',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685007251624',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688866535667',
          updatedAt: '2023-07-09T01:35:36.475Z',
        },
      },
      {
        _id: '64b70b97950bb1000dd59736',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989c05eb23b5000d326b0c',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Hamstring Stretch to Reverse Lunges',
          instructions: [
            {
              _id: '63989c05eb23b5000d326b0d',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685008893109',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686815753290',
          updatedAt: '2023-06-30T11:49:18.451Z',
        },
      },
      {
        _id: '64b70b97950bb1000dd59735',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '63989f6feb23b5000d326b58',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Plank Shoulder Taps, alternating',
          instructions: [
            {
              _id: '63989f6feb23b5000d326b59',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685019465645',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686899391325',
          updatedAt: '2023-06-19T23:28:15.396Z',
        },
      },
      {
        _id: '64b70b97950bb1000dd59734',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '63989f80eb23b5000d326b5c',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Chest Lifts',
          instructions: [
            {
              _id: '63989f80eb23b5000d326b5d',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685019592495',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686899523551',
          updatedAt: '2023-06-28T11:44:14.507Z',
        },
      },
      {
        _id: '64b70b97950bb1000dd59733',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a180eb23b5000d326b94',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Glute Bridges with Marching',
          instructions: [
            {
              _id: '6424dfba8e38c6000da6434c',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1689878351703',
          updatedAt: '2023-07-20T18:39:12.252Z',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687573617873',
        },
      },
      {
        _id: '64b70b97950bb1000dd59732',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a032eb23b5000d326b7a',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Plank Knee Drop Holds, 10 secs',
          instructions: [
            {
              _id: '6398a032eb23b5000d326b7b',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685097095903',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686901223094',
          updatedAt: '2023-06-28T11:48:28.118Z',
        },
      },
      {
        _id: '64b70b97950bb1000dd59731',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a216eb23b5000d326bb2',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Side Plank with Leg Lift',
          instructions: [
            {
              _id: '6398a216eb23b5000d326bb3',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685154878108',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688876988743',
          updatedAt: '2023-07-09T04:29:49.601Z',
        },
      },
      {
        _id: '64b70b97950bb1000dd59730',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d4444386ed000daa624a',
          repUnits: 'Secs',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Yoga Flow:  Down to Up Dog',
          instructions: [
            {
              _id: '6399d4444386ed000daa624b',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-06-28T12:09:40.595Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1687508810234',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687574610556',
        },
      },
      {
        _id: '64b70b97950bb1000dd5972f',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d2074386ed000daa61fe',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Lat Stretch to Chest Stretch',
          instructions: [
            {
              _id: '64aa99002d8c22000dce6f6a',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686700986446',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-18T22:02:36.953Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1689160118662',
        },
      },
    ],
    createdAt: '2023-07-18T18:03:20.155Z',
    updatedAt: '2023-07-19T21:58:45.306Z',
    __v: 0,
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1689803924880',
  },
  {
    _id: '64b6d470950bb1000dd59560',
    restBetweenSets: {
      warmUp: 1,
      core: 1,
      strength: 1,
      circuit: 1,
      conditioning: 1,
      coolDown: 1,
    },
    tags: {
      level: [],
      location: ['gym', 'home'],
      specialtyFocus: ['lowerBody'],
    },
    numberOfWeeks: 1,
    active: true,
    gender: 'All',
    isDeleted: false,
    __t: 'WorkoutAdmin',
    name: 'Lower Body Series:  Workout 4',
    locationType: 'Home',
    workoutInfo: [
      {
        week: 1,
        _id: '64b6d365950bb1000dd59547',
        intensity: 3,
        duration: 25,
        caloriesBurned: 260,
      },
    ],
    exercises: [
      {
        _id: '64b7d614f632de000d8bc5dd',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '6399d43b4386ed000daa6248',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Yoga Flow:  Down to Up Dog',
          instructions: [
            {
              _id: '6399d43b4386ed000daa6249',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686924755825',
          updatedAt: '2023-06-28T12:09:26.715Z',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687574468786',
        },
      },
      {
        _id: '64b7d614f632de000d8bc5dc',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989e26eb23b5000d326b30',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Side to Side Squats',
          instructions: [
            {
              _id: '63989e26eb23b5000d326b31',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687572837217',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1689243744974',
          updatedAt: '2023-07-13T10:22:25.419Z',
        },
      },
      {
        _id: '64b7d614f632de000d8bc5db',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989b9ceb23b5000d326b08',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Body Weight Reverse Lunges',
          instructions: [
            {
              _id: '63989b9ceb23b5000d326b09',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1689333256705',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688873281646',
          updatedAt: '2023-07-14T11:14:35.712Z',
        },
      },
      {
        _id: '64b7d614f632de000d8bc5da',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a573eb23b5000d326c54',
          repUnits: 'Reps',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Pulse Squats, weight at shoulders',
          instructions: [
            {
              _id: '6398a573eb23b5000d326c55',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685365991066',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901030072',
          updatedAt: '2023-07-09T11:10:30.890Z',
        },
      },
      {
        _id: '64b7d614f632de000d8bc5d9',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a4cdeb23b5000d326c2a',
          repUnits: 'Reps',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Glute Bridges, with weight',
          instructions: [
            {
              _id: '6398a4cdeb23b5000d326c2b',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685236126493',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-09T09:03:22.862Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688893402011',
        },
      },
      {
        _id: '64b7d614f632de000d8bc5d8',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a553eb23b5000d326c4c',
          repUnits: 'Reps Per Side',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Lateral Split Squat, with 1 weight',
          instructions: [
            {
              _id: '6398a553eb23b5000d326c4d',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685236687426',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686917992186',
          updatedAt: '2023-06-20T11:52:59.929Z',
        },
      },
      {
        _id: '64b7d614f632de000d8bc5d7',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a4beeb23b5000d326c26',
          repUnits: 'Reps Per Side',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Staggered Deadlifts, 2 weights',
          instructions: [
            {
              _id: '6398a4beeb23b5000d326c27',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685236065297',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688893266597',
          updatedAt: '2023-07-09T09:01:07.510Z',
        },
      },
      {
        _id: '64b7d614f632de000d8bc5d6',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a58feb23b5000d326c5a',
          repUnits: 'Reps Per Side',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Reverse Lunge to Knee Raise',
          instructions: [
            {
              _id: '6398a58feb23b5000d326c5b',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685440746443',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901065105',
          updatedAt: '2023-07-09T11:11:05.979Z',
        },
      },
      {
        _id: '64b7d614f632de000d8bc5d5',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d2b84386ed000daa621c',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Hip Flexor Reach Back Stretch',
          instructions: [
            {
              _id: '6399d2b84386ed000daa621d',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686702671081',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688902009161',
          updatedAt: '2023-07-09T11:26:50.089Z',
        },
      },
      {
        _id: '64b7d614f632de000d8bc5d4',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d2754386ed000daa6210',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Adductor Stretch ',
          instructions: [
            {
              _id: '6399d2754386ed000daa6211',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686701706933',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901958098',
          updatedAt: '2023-07-09T11:25:58.930Z',
        },
      },
      {
        _id: '64b7d614f632de000d8bc5d3',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d26c4386ed000daa620e',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'IT Band Stretch',
          instructions: [
            {
              _id: '6399d26c4386ed000daa620f',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686701603192',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-09T11:25:41.909Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901941033',
        },
      },
    ],
    createdAt: '2023-07-18T18:05:36.814Z',
    updatedAt: '2023-08-07T12:28:23.555Z',
    __v: 0,
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1689804328066',
  },
  {
    _id: '63e6efb40544c9000d957452',
    restBetweenSets: {
      warmUp: 1,
      core: 1,
      strength: 1,
      circuit: 1,
      conditioning: 1,
      coolDown: 1,
    },
    numberOfWeeks: 4,
    active: true,
    gender: 'All',
    isDeleted: false,
    __t: 'WorkoutAdmin',
    locationType: 'Home',
    name: 'Strength & Conditioning I:  Increasing Endurance (home)',
    workoutInfo: [
      {
        week: 1,
        _id: '63e6efb40544c9000d957456',
        intensity: 3,
        duration: 29,
        caloriesBurned: 258,
      },
      {
        week: 2,
        _id: '63e6efb40544c9000d957455',
        intensity: 3,
        duration: 35,
        caloriesBurned: 311,
      },
      {
        week: 3,
        _id: '63e6efb40544c9000d957454',
        intensity: 3,
        duration: 40,
        caloriesBurned: 365,
      },
      {
        week: 4,
        _id: '63e6efb40544c9000d957453',
        intensity: 3,
        duration: 46,
        caloriesBurned: 415,
      },
    ],
    exercises: [
      {
        _id: '63e6efb40544c9000d9574d8',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989a8eeb23b5000d326af5',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Thread the Needle',
          instructions: [
            {
              _id: '63989a8eeb23b5000d326af6',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685007251624',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688866535667',
          updatedAt: '2023-07-09T01:35:36.475Z',
        },
      },
      {
        _id: '63e6efb40544c9000d9574d3',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989e85eb23b5000d326b38',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Step Back, Reach Back',
          instructions: [
            {
              _id: '63989e85eb23b5000d326b39',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685011007184',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688874163603',
          updatedAt: '2023-07-09T03:42:44.415Z',
        },
      },
      {
        _id: '63e6efb40544c9000d9574ce',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63dd8c8dcef5bd000de349a3',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Body Weight Squat',
          instructions: [
            {
              _id: '6423d2f19b2f86000db75938',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686925895673',
          updatedAt: '2023-06-21T12:11:32.748Z',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686934518765',
        },
      },
      {
        _id: '63e6efb40544c9000d957470',
        workoutSequence: 'Core',
        exercise: {
          _id: '63989f8beb23b5000d326b5e',
          repUnits: 'Secs',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Chest Lifts',
          instructions: [
            {
              _id: '63989f8beb23b5000d326b5f',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-06-28T11:44:43.354Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1687450462469',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687573175851',
        },
      },
      {
        _id: '63e6efb40544c9000d957466',
        workoutSequence: 'Core',
        exercise: {
          _id: '63989f93eb23b5000d326b60',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Feet Push Out',
          instructions: [
            {
              _id: '63989f93eb23b5000d326b61',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685095470755',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-06-28T11:45:49.362Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1687450514002',
        },
      },
      {
        _id: '63e6efb40544c9000d9574c0',
        workoutSequence: 'Strength',
        exercise: {
          _id: '6398a332eb23b5000d326be8',
          repUnits: 'Reps Per Side',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Tri Pod Single Arm Row',
          instructions: [
            {
              _id: '6398a332eb23b5000d326be9',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685165866193',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688877423776',
          updatedAt: '2023-07-09T04:37:04.675Z',
        },
      },
      {
        _id: '63e6efb40544c9000d9574b2',
        workoutSequence: 'Strength',
        exercise: {
          _id: '6398a533eb23b5000d326c44',
          repUnits: 'Reps Per Side',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Reverse Lunges, with 1 weight',
          instructions: [
            {
              _id: '6398a533eb23b5000d326c45',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685236584583',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686917614999',
          updatedAt: '2023-06-20T11:48:54.270Z',
        },
      },
      {
        _id: '63e6efb40544c9000d9574a4',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a573eb23b5000d326c54',
          repUnits: 'Reps',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Pulse Squats, weight at shoulders',
          instructions: [
            {
              _id: '6398a573eb23b5000d326c55',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685365991066',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901030072',
          updatedAt: '2023-07-09T11:10:30.890Z',
        },
      },
      {
        _id: '63e6efb40544c9000d957496',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a25eeb23b5000d326bc0',
          repUnits: 'Reps',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Standing Dumbbell Chest Fly',
          instructions: [
            {
              _id: '6398a25eeb23b5000d326bc1',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685155992221',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688877057392',
          updatedAt: '2023-07-09T04:30:58.240Z',
        },
      },
      {
        _id: '63e6efb40544c9000d957488',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a4feeb23b5000d326c38',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Body Weight Single Leg Deadlift',
          instructions: [
            {
              _id: '6398a4feeb23b5000d326c39',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685236371898',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688900913161',
          updatedAt: '2023-07-09T11:08:34.030Z',
        },
      },
      {
        _id: '63e6efb40544c9000d95747a',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6399d1854386ed000daa61e8',
          repUnits: 'Secs',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Punches, alternating',
          instructions: [
            {
              _id: '6399d1854386ed000daa61e9',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686551188244',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-09T11:23:00.439Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901779538',
        },
      },
      {
        _id: '63e6efb40544c9000d957461',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d3234386ed000daa6228',
          repUnits: 'Secs',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Yoga Flow:  Cat to Cow',
          instructions: [
            {
              _id: '6399d3234386ed000daa6229',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686789728695',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686789738985',
          updatedAt: '2023-06-28T12:04:48.378Z',
        },
      },
      {
        _id: '63e6efb40544c9000d95745c',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d4274386ed000daa6244',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Chest Door Frame Stretch',
          instructions: [
            {
              _id: '6399d4274386ed000daa6245',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688902129125',
          updatedAt: '2023-07-09T11:28:49.941Z',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687574406577',
        },
      },
      {
        _id: '63e6efb40544c9000d957457',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d25e4386ed000daa620c',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Standing Hip Flexor/Quad Stretch',
          instructions: [
            {
              _id: '6399d25e4386ed000daa620d',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686701519112',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-09T11:25:25.385Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901924532',
        },
      },
    ],
    __v: 0,
    tags: {
      level: ['beginner'],
      location: ['home'],
      specialtyFocus: [],
    },
    updatedAt: '2023-07-24T23:30:34.784Z',
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687660482219',
  },
  {
    _id: '6402ae301574e4000d393ca2',
    restBetweenSets: {
      warmUp: 1,
      core: 1,
      strength: 1,
      circuit: 1,
      conditioning: 1,
      coolDown: 1,
    },
    numberOfWeeks: 4,
    active: true,
    gender: 'All',
    isDeleted: false,
    __t: 'WorkoutAdmin',
    locationType: 'Home',
    name: 'Pure Core & Strength II:  Metabolic Burner (home)',
    workoutInfo: [
      {
        week: 1,
        _id: '6402ae301574e4000d393ca6',
        intensity: 3,
        duration: 24,
        caloriesBurned: 220,
      },
      {
        week: 2,
        _id: '6402ae301574e4000d393ca5',
        intensity: 3,
        duration: 29,
        caloriesBurned: 268,
      },
      {
        week: 3,
        _id: '6402ae301574e4000d393ca4',
        intensity: 3,
        duration: 33,
        caloriesBurned: 317,
      },
      {
        week: 4,
        _id: '6402ae301574e4000d393ca3',
        intensity: 3,
        duration: 38,
        caloriesBurned: 358,
      },
    ],
    exercises: [
      {
        _id: '6402ae301574e4000d393d1a',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989ad0eb23b5000d326afd',
          repUnits: 'Secs',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Foam Roll: Upper Body',
          instructions: [
            {
              _id: '63989ad0eb23b5000d326afe',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685008518211',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688866585956',
          updatedAt: '2023-07-09T01:36:26.761Z',
        },
      },
      {
        _id: '6402ae301574e4000d393d15',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '6398a4feeb23b5000d326c38',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Body Weight Single Leg Deadlift',
          instructions: [
            {
              _id: '6398a4feeb23b5000d326c39',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685236371898',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688900913161',
          updatedAt: '2023-07-09T11:08:34.030Z',
        },
      },
      {
        _id: '6402ae301574e4000d393d10',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989cefeb23b5000d326b1a',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Body Weight Lateral Split Squat',
          instructions: [
            {
              _id: '63989cefeb23b5000d326b1b',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686834863251',
          updatedAt: '2023-06-19T22:48:08.796Z',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686928458225',
        },
      },
      {
        _id: '6402ae301574e4000d393cc0',
        workoutSequence: 'Core',
        exercise: {
          _id: '6398a029eb23b5000d326b78',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Full Plank Knee Drops',
          instructions: [
            {
              _id: '6398a029eb23b5000d326b79',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685097034919',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686901152249',
          updatedAt: '2023-06-19T23:42:27.329Z',
        },
      },
      {
        _id: '6402ae301574e4000d393cb6',
        workoutSequence: 'Core',
        exercise: {
          _id: '6398a1abeb23b5000d326b9c',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Ceiling Stamp Glute Bridges',
          instructions: [
            {
              _id: '6398a1abeb23b5000d326b9d',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685097975937',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686903816600',
          updatedAt: '2023-06-20T10:00:37.184Z',
        },
      },
      {
        _id: '6402ae301574e4000d393d02',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a4f5eb23b5000d326c36',
          repUnits: 'Reps Per Side',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Single Leg Deadlift, 1 weight opposite side',
          instructions: [
            {
              _id: '6398a4f5eb23b5000d326c37',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685236336219',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688900895726',
          updatedAt: '2023-07-09T11:08:16.599Z',
        },
      },
      {
        _id: '6402ae301574e4000d393cf4',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '63c1eba2096ede000d9996d6',
          repUnits: 'Reps',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Lateral Shoulder Raise',
          instructions: [
            {
              _id: '63c1eba2096ede000d9996d7',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686926636889',
          updatedAt: '2023-06-21T12:24:59.210Z',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686935469144',
        },
      },
      {
        _id: '6402ae301574e4000d393ce6',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a49ceb23b5000d326c20',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Band Bent Over Eccentric Lat Pull',
          instructions: [
            {
              _id: '6398a49ceb23b5000d326c21',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685235945319',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688893189845',
          updatedAt: '2023-07-09T08:59:50.813Z',
        },
      },
      {
        _id: '6402ae301574e4000d393cd8',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a545eb23b5000d326c48',
          repUnits: 'Reps Per Side',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Lateral Lunges, with 1 weight',
          instructions: [
            {
              _id: '6398a545eb23b5000d326c49',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685236641686',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686917774231',
          updatedAt: '2023-06-20T11:50:39.906Z',
        },
      },
      {
        _id: '6402ae301574e4000d393cca',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6399cfdd7bde01000db0c054',
          repUnits: 'Reps Per Side',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Overhead Tricep Extensions, single arm',
          instructions: [
            {
              _id: '6399cfdd7bde01000db0c055',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686547691514',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-09T11:17:16.388Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901435454',
        },
      },
      {
        _id: '6402ae301574e4000d393cb1',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d1c04386ed000daa61f2',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Lat and Side Stretch',
          instructions: [
            {
              _id: '6399d1c04386ed000daa61f3',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686551939049',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-09T11:23:26.111Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901805313',
        },
      },
      {
        _id: '6402ae301574e4000d393cac',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d27e4386ed000daa6212',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Half Kneel Hamstring Stretch',
          instructions: [
            {
              _id: '6399d27e4386ed000daa6213',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686701836231',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686701838017',
          updatedAt: '2023-06-21T11:23:42.275Z',
        },
      },
      {
        _id: '6402ae301574e4000d393ca7',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d28f4386ed000daa6216',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Half Kneel Adductor Stretch',
          instructions: [
            {
              _id: '6399d28f4386ed000daa6217',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686702222061',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686702223671',
          updatedAt: '2023-06-21T11:20:42.193Z',
        },
      },
    ],
    __v: 0,
    tags: {
      level: ['intermediate'],
      location: ['home'],
      specialtyFocus: [],
    },
    updatedAt: '2023-07-25T10:54:48.588Z',
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687662422030',
  },
  {
    _id: '6403505395e57a000d7dd7e5',
    restBetweenSets: {
      warmUp: 1,
      core: 1,
      strength: 1,
      circuit: 1,
      conditioning: 1,
      coolDown: 1,
    },
    numberOfWeeks: 4,
    active: true,
    gender: 'All',
    isDeleted: false,
    __t: 'WorkoutAdmin',
    locationType: 'Home',
    name: 'Power Strength II:  Pushing the Power (home)',
    workoutInfo: [
      {
        week: 1,
        _id: '6403505395e57a000d7dd7e9',
        intensity: 3,
        duration: 25,
        caloriesBurned: 239,
      },
      {
        week: 2,
        _id: '6403505395e57a000d7dd7e8',
        intensity: 3,
        duration: 31,
        caloriesBurned: 296,
      },
      {
        week: 3,
        _id: '6403505395e57a000d7dd7e7',
        intensity: 3,
        duration: 36,
        caloriesBurned: 353,
      },
      {
        week: 4,
        _id: '6403505395e57a000d7dd7e6',
        intensity: 3,
        duration: 41,
        caloriesBurned: 396,
      },
    ],
    exercises: [
      {
        _id: '6403505395e57a000d7dd866',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989a9deb23b5000d326af7',
          repUnits: 'Secs',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Foam Roll: Thoracic Spine',
          instructions: [
            {
              _id: '63989a9deb23b5000d326af8',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685007771071',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686700916466',
          updatedAt: '2023-06-19T22:19:39.010Z',
        },
      },
      {
        _id: '6403505395e57a000d7dd861',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989b3feb23b5000d326b00',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Foam Roll: Hips',
          instructions: [
            {
              _id: '63989b3feb23b5000d326b01',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685008579148',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686815256102',
          updatedAt: '2023-06-19T22:27:33.600Z',
        },
      },
      {
        _id: '6403505395e57a000d7dd85c',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989c16eb23b5000d326b0e',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Single Leg Hamstring Scoop to Reverse Lunge',
          instructions: [
            {
              _id: '63989c16eb23b5000d326b0f',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685008936276',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688873357358',
          updatedAt: '2023-07-09T03:29:18.143Z',
        },
      },
      {
        _id: '6403505395e57a000d7dd7fe',
        workoutSequence: 'Core',
        exercise: {
          _id: '6398a032eb23b5000d326b7a',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Plank Knee Drop Holds, 10 secs',
          instructions: [
            {
              _id: '6398a032eb23b5000d326b7b',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685097095903',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686901223094',
          updatedAt: '2023-06-28T11:48:28.118Z',
        },
      },
      {
        _id: '6403505395e57a000d7dd7f4',
        workoutSequence: 'Core',
        exercise: {
          _id: '6398a1abeb23b5000d326b9c',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Ceiling Stamp Glute Bridges',
          instructions: [
            {
              _id: '6398a1abeb23b5000d326b9d',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685097975937',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686903816600',
          updatedAt: '2023-06-20T10:00:37.184Z',
        },
      },
      {
        _id: '6403505395e57a000d7dd84e',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a60aeb23b5000d326c70',
          repUnits: 'Reps Per Side',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Single Leg Deadlift to Row',
          instructions: [
            {
              _id: '6398a60aeb23b5000d326c71',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686336651391',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-09T11:12:29.096Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901148249',
        },
      },
      {
        _id: '6403505395e57a000d7dd840',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '63a33f4d41b884000d571456',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Single Arm Chest Fly, on band',
          instructions: [
            {
              _id: '63a33f4d41b884000d571457',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685165669823',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688877183748',
          updatedAt: '2023-07-09T04:33:04.591Z',
        },
      },
      {
        _id: '6403505395e57a000d7dd832',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a426eb23b5000d326c0e',
          repUnits: 'Reps',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Straight Arm Pull Over with Glute Bridge',
          instructions: [
            {
              _id: '6398a426eb23b5000d326c0f',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687574859676',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688892944528',
          updatedAt: '2023-07-09T08:55:45.384Z',
        },
      },
      {
        _id: '6403505395e57a000d7dd824',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a5c5eb23b5000d326c66',
          repUnits: 'Reps Per Side',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Reverse Lunge with Shoulder Press',
          instructions: [
            {
              _id: '6398a5c5eb23b5000d326c67',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685441366591',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686919465493',
          updatedAt: '2023-06-20T21:35:14.481Z',
        },
      },
      {
        _id: '6403505395e57a000d7dd816',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6398a64aeb23b5000d326c7e',
          repUnits: 'Reps',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Tricep Kickbacks, alternating',
          instructions: [
            {
              _id: '6398a64aeb23b5000d326c7f',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686547188211',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-09T11:16:07.370Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901366539',
        },
      },
      {
        _id: '6403505395e57a000d7dd808',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6399cfca7bde01000db0c050',
          repUnits: 'Secs',
          bodyWeight: false,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Farmers Carries',
          instructions: [
            {
              _id: '6399cfca7bde01000db0c051',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686547536396',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-09T11:16:40.396Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901399536',
        },
      },
      {
        _id: '6403505395e57a000d7dd7ef',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d1b44386ed000daa61f0',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Arm Series Stretch',
          instructions: [
            {
              _id: '6399d1b44386ed000daa61f1',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686551627922',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-09T11:23:37.911Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901817012',
        },
      },
      {
        _id: '6403505395e57a000d7dd7ea',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d2254386ed000daa6204',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Leg Series Stretch',
          instructions: [
            {
              _id: '6399d2254386ed000daa6205',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686701183129',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-06-21T11:15:02.802Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686923733258',
        },
      },
    ],
    __v: 0,
    tags: {
      level: ['intermediate'],
      location: ['home'],
      specialtyFocus: [],
    },
    updatedAt: '2023-07-25T12:07:39.474Z',
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1690240065127',
  },
  {
    _id: '64b123271854c1000d1b38e9',
    restBetweenSets: {
      warmUp: 1,
      core: 1,
      strength: 1,
      circuit: 1,
      conditioning: 1,
      coolDown: 1,
    },
    tags: {
      level: [],
      location: ['home', 'gym'],
      specialtyFocus: ['cardioHIIT'],
    },
    numberOfWeeks: 1,
    active: true,
    gender: 'All',
    isDeleted: false,
    __t: 'WorkoutAdmin',
    name: 'HIIT Series:  Workout 4',
    locationType: 'Home',
    workoutInfo: [
      {
        week: 1,
        _id: '64b122da1854c1000d1b38e4',
        intensity: 3,
        duration: 25,
        caloriesBurned: 260,
      },
    ],
    exercises: [
      {
        _id: '64b12d921854c1000d1b3a7f',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989b9ceb23b5000d326b08',
          repUnits: 'Reps Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Body Weight Reverse Lunges',
          instructions: [
            {
              _id: '63989b9ceb23b5000d326b09',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1689333256705',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688873281646',
          updatedAt: '2023-07-14T11:14:35.712Z',
        },
      },
      {
        _id: '64b12fb51854c1000d1b3a9f',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989de4eb23b5000d326b2a',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Body Weight Lateral Split Squat, alternating',
          instructions: [
            {
              _id: '63989de4eb23b5000d326b2b',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686894554315',
          updatedAt: '2023-06-28T12:12:53.458Z',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687572698707',
        },
      },
      {
        _id: '64b12fb51854c1000d1b3a9d',
        workoutSequence: 'Warm Up',
        exercise: {
          _id: '63989d07eb23b5000d326b1c',
          repUnits: 'Reps',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Toe Touches',
          instructions: [
            {
              _id: '63989d07eb23b5000d326b1d',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1685010241146',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686834937968',
          updatedAt: '2023-06-19T22:49:44.632Z',
        },
      },
      {
        _id: '64b12fb51854c1000d1b3a99',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6399d0cf4386ed000daa61d2',
          repUnits: 'Secs',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Low Side to Side Squats',
          instructions: [
            {
              _id: '6399d0cf4386ed000daa61d3',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686549218508',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-09T11:20:22.579Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901621727',
        },
      },
      {
        _id: '64b12fb51854c1000d1b3a95',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '63989efbeb23b5000d326b44',
          repUnits: 'Secs',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Front to Back Shuffle',
          instructions: [
            {
              _id: '63989efbeb23b5000d326b45',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686896795181',
          updatedAt: '2023-06-28T12:15:04.240Z',
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1687573063536',
        },
      },
      {
        _id: '64b12fb51854c1000d1b3a91',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6399d0934386ed000daa61c6',
          repUnits: 'Secs',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Jump Lunges',
          instructions: [
            {
              _id: '6399d0934386ed000daa61c7',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686548601879',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-06-20T21:59:43.321Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686921105257',
        },
      },
      {
        _id: '64b12fb51854c1000d1b3a8d',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6399d0b74386ed000daa61cc',
          repUnits: 'Secs',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Ski Mogels',
          instructions: [
            {
              _id: '6399d0b74386ed000daa61cd',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686548902253',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-09T11:19:36.791Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901575983',
        },
      },
      {
        _id: '64b12fb51854c1000d1b3a89',
        workoutSequence: 'Circuit',
        exercise: {
          _id: '6399d11d4386ed000daa61de',
          repUnits: 'Secs',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: '1 Hop Forward, 2 hops back',
          instructions: [
            {
              _id: '6399d11d4386ed000daa61df',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686550459900',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-07-09T11:22:00.052Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901719206',
        },
      },
      {
        _id: '64b12fb51854c1000d1b3a87',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d29f4386ed000daa6218',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Side Lying Hip Flexor/Quad Stretch',
          instructions: [
            {
              _id: '6399d29f4386ed000daa6219',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686702371917',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1688901993993',
          updatedAt: '2023-07-09T11:26:34.846Z',
        },
      },
      {
        _id: '64b12fb51854c1000d1b3a85',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d27e4386ed000daa6212',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Half Kneel Hamstring Stretch',
          instructions: [
            {
              _id: '6399d27e4386ed000daa6213',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686701836231',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686701838017',
          updatedAt: '2023-06-21T11:23:42.275Z',
        },
      },
      {
        _id: '64b12fb51854c1000d1b3a83',
        workoutSequence: 'Cool Down',
        exercise: {
          _id: '6399d2474386ed000daa6208',
          repUnits: 'Secs Per Side',
          bodyWeight: true,
          isDeleted: false,
          __t: 'ExerciseAdmin',
          name: 'Lying Figure Four',
          instructions: [
            {
              _id: '6399d2474386ed000daa6209',
              num: null,
              step: '',
            },
          ],
          __v: 0,
          active: true,
          imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1686701352111',
          tags: {
            level: [],
            location: [],
            specialtyFocus: [],
          },
          updatedAt: '2023-06-21T11:16:03.226Z',
          thumbnailUrl:
            'https://nutriologywebapp.s3.amazonaws.com/1686923826116',
        },
      },
    ],
    createdAt: '2023-07-14T10:27:51.259Z',
    updatedAt: '2023-07-19T22:00:45.106Z',
    __v: 0,
    imageUrl: 'https://nutriologywebapp.s3.amazonaws.com/1689804044533',
  },
];
