import React, { useMemo } from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';
import { fitnessTags } from '../../helpers/fitnessTags';
import { recipeTags } from '../../helpers/recipeTags';
import Collapsible from 'react-collapsible';
import FilterIcon from '../../icons/FilterIcon';
import ChipDeleteIcon from '../../icons/ChipDeleteIcon';
import CollapseIcon from '../../icons/CollapseIcon';
import Chip from '../../elements/Chip';
import Button from '../../elements/Button';
import { Badge } from '@material-ui/core';
import MobileDialog from '../../sharedModals/mobile/MobileDialog';
import MobileDialogHeader from '../../sharedModals/mobile/MobileDialogHeader';

// Common functions
const getAppliedFilters = (item, toggleValues) => {
  return item.tags
    .map(tag => {
      const appliedFilters = Object.entries(toggleValues[item.name])
        .filter(keyValue => keyValue[1])
        .map(tagKeyValueArray => tagKeyValueArray[0]);
      if (appliedFilters.includes(tag.name)) {
        return tag;
      }
      return undefined;
    })
    .filter(filteredItem => filteredItem);
};

export const FiltersButton = ({
  setFiltersDialogOpen,
  toggleValues,
  itemType,
}) => {
  const itemTags = itemType === 'fitness' ? fitnessTags : recipeTags;
  const getFilterText = () => {
    const numberOfFilters = itemTags
      .map(item => getAppliedFilters(item, toggleValues))
      .filter(i => i.length > 0)
      .flat();
    if (numberOfFilters.length > 0) {
      // if there are filters, change button label to 'refine' and return count to be displayed on badge
      return { label: 'Refine', count: numberOfFilters.length };
    } else {
      return { label: 'Filter', count: 0 };
    }
  };

  return (
    <MobileFilterContainer
      justifyContent="space-between"
      alignItems="center"
      onClick={() => setFiltersDialogOpen(true)}
      data-test="mobile-filters"
    >
      <FilterIcon containerStyle={{ padding: 5 }} />
      <StyledBadge badgeContent={getFilterText().count}>
        <FilterText>{getFilterText().label}</FilterText>
      </StyledBadge>
    </MobileFilterContainer>
  );
};

export const AppliedFilters = ({ toggleValues, handleToggle, itemType }) => {
  const itemTags = itemType === 'fitness' ? fitnessTags : recipeTags;
  return (
    <ChipScrollContainer flexDirection="row" wrap="nowrap">
      {itemTags.map(item =>
        getAppliedFilters(item, toggleValues).map((tag, tIndex) => {
          return (
            <Chip
              small="true"
              key={tIndex.toString()}
              selected={true}
              label={tag.label}
              handleDelete={e => handleToggle(item.name)(e)}
              toggleSelect={handleToggle(item.name)}
              name={tag.name}
              data-test={`${itemType}Search-${item.name}-${tag.name}`}
              deleteIcon={<ChipDeleteIcon name={tag.name} />}
            />
          );
        })
      )}
    </ChipScrollContainer>
  );
};

export const FiltersDialog = ({
  itemType,
  toggleValues,
  handleToggle,
  open,
  setOpen,
  clearAllFilters,
  handleCollapseToggle,
  collapseToggleValues,
  filteredResultCount,
}) => {
  const itemTags = itemType === 'fitness' ? fitnessTags : recipeTags;
  const formattedAppliedFilters = item => {
    const appliedFiltersLabel = getAppliedFilters(item, toggleValues).map(
      filter => filter.label
    );
    if (appliedFiltersLabel.length <= 3) {
      return appliedFiltersLabel.toString().replaceAll(',', ', ');
    } else {
      return (
        appliedFiltersLabel
          .slice(0, 3)
          .toString()
          .replaceAll(',', ', ') + ` +${appliedFiltersLabel.length - 3}`
      );
    }
  };

  const showClearBtn = useMemo(() => {
    for (let value of Object.values(toggleValues)) {
      for (let vKey of Object.keys(value)) {
        if (value[vKey]) {
          return true;
        }
      }
    }
    return false;
  }, [toggleValues]);

  return (
    <MobileDialog open={open} height="85%" onClose={() => setOpen(false)}>
      <MobileDialogHeader
        title="Filter Items"
        handleBackBtnClick={() => setOpen(false)}
        rightBtn={
          showClearBtn && (
            <FilterClearMobile
              onClick={clearAllFilters}
              data-test={`${itemType}Search-clearAllFilters`}
            >
              Clear all
            </FilterClearMobile>
          )
        }
      />
      <TagFilterContainer>
        {itemTags.map((item, index) => (
          <FilterContainer
            isMobile
            flexDirection="column"
            key={index.toString()}
          >
            <TagContainerMobile
              flexDirection="row"
              justify="space-between"
              alignItems="center"
              flexShrink="0"
              name={item.name}
              onClick={handleCollapseToggle()}
            >
              <Subheading name={item.name}>{item.label}</Subheading>
              <FiltersAndIconContainer
                flexDirection="row"
                alignItems="center"
                justify="space-between"
              >
                <FilterLabel>{formattedAppliedFilters(item)}</FilterLabel>

                <CollapseIcon
                  small
                  open={collapseToggleValues[`${item.name}`]}
                  name={item.name}
                  data-test={`collapse-${item.name}`}
                  width={12}
                  height={8}
                />
              </FiltersAndIconContainer>
            </TagContainerMobile>

            <Collapsible
              overflowWhenOpen="visible"
              contentOuterClassName="contentOuter__no_padding"
              open={collapseToggleValues[`${item.name}`]}
            >
              <ChipContainer flexDirection="row">
                {item.tags.map((tag, tIndex) => (
                  <Chip
                    key={tIndex.toString()}
                    selected={toggleValues[item.name][tag.name]}
                    label={tag.label}
                    handleDelete={
                      toggleValues[item.name][tag.name]
                        ? e => handleToggle(item.name)(e)
                        : undefined
                    }
                    toggleSelect={handleToggle(item.name)}
                    name={tag.name}
                    data-test={`${itemType}Search-${item.name}-${tag.name}`}
                    deleteIcon={
                      <ChipDeleteIcon
                        name={tag.name}
                        style={{
                          width: '24px !important',
                          height: '24px !important',
                        }}
                      />
                    }
                  />
                ))}
              </ChipContainer>
            </Collapsible>
          </FilterContainer>
        ))}
      </TagFilterContainer>
      <ButtonContainer justify="center">
        <Button
          width="240px"
          pink="true"
          buttonText={`see results (${filteredResultCount})`}
          buttonSize="large"
          handleClick={() => setOpen(false)}
        />
      </ButtonContainer>
    </MobileDialog>
  );
};

const ButtonContainer = styled(FlexContainer)`
  padding: 20px 0;
`;

const TagContainerMobile = styled(FlexContainer)`
  box-sizing: border-box;
  padding: 19px 20px;
  background-color: #ffffff;
`;

const FiltersAndIconContainer = styled(FlexContainer)`
  width: 50%;
`;

const FilterLabel = styled.h5`
  color: ${colors.secondary600};
  font-family: 'Work Sans';
  font-size: 10px;
  letter-spacing: 0;
  line-height: 11px;
  text-align: center;
`;

const ChipContainer = styled(FlexContainer)`
  flex-wrap: wrap;
  padding: 0px 20px 10px 20px;

  > * {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

const FilterClearMobile = styled.h4`
  color: ${colors.secondary600};
  font-family: 'Work Sans';
  font-size: 17px;
  letter-spacing: 0;
  line-height: 20px;
`;

const TagFilterContainer = styled(FlexContainer)`
  flex-direction: column;
`;

const FilterContainer = styled(FlexContainer)`
  margin-top: 15px;
  border-bottom: ${props =>
    props.isMobile ? `1.5px solid ${colors.primary300}` : undefined};
`;

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    background-color: ${colors.secondary500};
    color: #ffffff;
    font-family: 'Work Sans';
    font-size: 10px;
    letter-spacing: 0;
    line-height: 11px;
    text-align: center;
    margin-right: -8px;
  }
`;

const Subheading = styled.h3`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 19px;
  letter-spacing: 0;
  line-height: 23px;
`;

const MobileFilterContainer = styled(FlexContainer)`
  height: 32px;
  width: 95px;
  border-radius: 16px;
  background-color: ${colors.primary200};
`;
const FilterText = styled.div`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 15px;
  letter-spacing: 0;
  line-height: 24px;
`;

const ChipScrollContainer = styled(FlexContainer)`
  overflow-x: scroll;
  > * {
    margin-right: 8px;
    margin-bottom: 15px;
  }
`;
