import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';

import { isEmpty } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useModal from '../../hooks/useModal';
import ModalContainer from '../../sharedModals/ModalContainer';

import TrackForm from '../track/TrackForm';
import { getTrack } from '../../services/api/track';

const TrackToAssign = ({ setTrackToAssign, ...props }) => {
  const initialValues = {
    locationType: 'Gym',
    gender: 'All',
    level: 'Beginner',
  };

  const { values, setValues, handleChange, handleValueChanged } = useFormValues(
    initialValues
  );

  const { open, launchModal, closeModal } = useModal(false);

  const [fitnessPlans, setFitnessPlans] = useState([]);
  const [track, setTrack] = useState([]);
  const [nextTrack, setNextTrack] = useState([]);
  const [remainTrack, setRemainTrack] = useState([]);
  const [backTrack, setBackTrack] = useState([]);
  const [nextLevel, setNextLevel] = useState([]);

  async function loadTrack(params) {
    try {
      const trackId = params.track;
      const result = await getTrack(trackId);
      if (result) {
        setTrack(result);
        setTrackToAssign(result);
      } else {
        throw new Error('Track could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded fitness plan
  useEffect(() => {
    if (!isEmpty(track)) {
      setFitnessPlans([...track.fitnessPlans]);
      setNextTrack(track.nextTrack);
      setRemainTrack(track.remainTrack);
      setBackTrack(track.backTrack);
      setNextLevel(track.nextLevel);
      setValues({
        ...values,
        name: track.name,
        level: track.level,
        gender: track.gender,
        locationType: track.locationType,
      });
    }
  }, [track]);

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Track"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          showOnly: 'track',
        }}
        initialScreen="Exercise Search"
        handleClose={closeModal}
        addTrack={loadTrack}
        {...props}
      />
      <TrackForm
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        fitnessPlans={fitnessPlans}
        setFitnessPlans={setFitnessPlans}
        nextTrack={nextTrack}
        remainTrack={remainTrack}
        backTrack={backTrack}
        nextLevel={nextLevel}
        viewOnly={true}
        formType="view"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default TrackToAssign;
