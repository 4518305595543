import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import { isMobile } from '../helpers/utils';
import FlexContainer from '../elements/FlexContainer';
import Button from '../elements/Button';
import Dropdown from '../elements/Dropdown';
import FractionInput from '../elements/FractionInput';
import moment from 'moment';
import { formatDate } from '../helpers/date';
import useFormValues from '../hooks/useFormValues';
import IntensityIcon from '../icons/IntensityIcon';
import FoodNutritionContainer from '../mealPlan/food/FoodNutritionContainer';
import MobileDropdown from '../elements/MobileDropdown';
import MobileNumericInput from '../elements/MobileNumericInput';
import { formatCalories } from '../helpers/nutrition';
import { formatIngredientDisplay } from '../helpers/utils';

const FoodModalView = ({ food, context, handleClose, addFood, ...props }) => {
  const defaultMeal = context.meal || 'Breakfast';
  let defaultDate;
  let formattedDate;
  const dateOptions = [];

  /*
  If we launch this modal from an admin context (adding foods to
  a static meal plan), then we want to use the day number of the plan.
  It should be date-agnostic. If we are adding to a user's meal plan,
  we want to be able to select an actual date.
  */

  if (context.variant === 'admin') {
    defaultDate = context.date || 1;
    formattedDate = `Day ${defaultDate}`;

    for (let i = 0; i < 7; i++) {
      const dayNum = i + 1;
      dateOptions.push({ value: dayNum, label: `Day ${dayNum}` });
    }
  } else {
    if (context?.date) {
      defaultDate = context.date;
    } else {
      defaultDate = formatDate(moment());
    }
    formattedDate = moment(defaultDate)
      .utc()
      .format(isMobile() ? 'ddd, MMM Do' : 'dddd, MMMM Do');

    for (let i = 0; i < 7; i++) {
      let day = moment().add(i, 'days');
      let formattedDay = day.format(
        isMobile() ? 'ddd, MMM Do' : 'dddd, MMMM Do'
      );
      day = day.toDate();
      dateOptions.push({ value: day, label: formattedDay });
    }
  }

  const {
    values,
    handleChange,
    handleDateChanged,
    handleValueChanged,
  } = useFormValues({
    meal: defaultMeal,
    date: {
      value: defaultDate,
      label: formattedDate,
    },
    quantity: food.amount,
    measureUnit: food.measureUnit,
  });

  const handleSubmit = e => {
    e.stopPropagation();
    const foodId = food.id;

    const params = {
      mealType: values.meal,
      date: values.date.value,
      quantity: values.quantity,
      food: foodId,
      name: food.verboseName,
      measureUnit: food.measureUnit,
      category: context?.category,
      gramWeightPerUnit: food.gramWeightPerUnit,
    };
    addFood(params);

    handleClose();
  };
  const { measureUnit: formattedUnits } = formatIngredientDisplay(
    values.quantity,
    food.measureUnit
  );

  const submitText = context.type === 'swap' ? 'Swap Food' : 'Add Food';

  if (isMobile()) {
    // mobile food modal view
    return (
      <>
        <FoodContainer justify="center" alignItems="center">
          <div style={{ width: '100%' }}>
            <MobileFoodName data-test="foodModal-foodName">
              {food.verboseName}
            </MobileFoodName>
            <MobileInfoContainer justify="center">
              <InfoItem alignItems="center">
                <IntensityIcon
                  filled={true}
                  color={colors.secondary600}
                  width="22px"
                  height="22px"
                />
                <InfoColumn flexDirection="column">
                  <InfoLabel>Calories: </InfoLabel>
                  <Info data-test="foodModal-calories">
                    {formatCalories(
                      food.nutrientsPerUnit.calories * values.quantity
                    )}
                  </Info>
                </InfoColumn>
              </InfoItem>
            </MobileInfoContainer>
            <MobileDropdownsContainer
              flexDirection="column"
              alignItems="center"
            >
              <MealActionContainer flexDirection="column" justify="center">
                {!['swap', 'shoppinglist'].includes(context.type) && (
                  <>
                    <ActionContainer alignItems="center">
                      <ActionLabel>Meal:</ActionLabel>
                      <MobileDropdown
                        width="195px"
                        options={['Breakfast', 'Lunch', 'Dinner', 'Snack']}
                        value={{
                          value: values.meal,
                          label: values.meal,
                        }}
                        onChange={value =>
                          handleValueChanged('meal', value.value, 'string')
                        }
                        data-test="foodModal-mealSelect"
                      />
                    </ActionContainer>
                    <ActionContainer
                      alignItems="center"
                      data-test="foodPage-dateContainer"
                    >
                      <ActionLabel>Date:</ActionLabel>
                      <MobileDropdown
                        width="195px"
                        options={dateOptions}
                        value={{
                          value: values.date.value,
                          label: values.date.label,
                        }}
                        onChange={value =>
                          handleDateChanged('date', value.value, value.label)
                        }
                        data-test="foodModal-dateSelect"
                      />
                    </ActionContainer>
                  </>
                )}
                <ActionContainer
                  alignItems="center"
                  data-test="foodModal-quantityContainer"
                >
                  <ActionLabel>Quantity:</ActionLabel>
                  <FlexContainer alignItems="center" style={{ gap: '16px' }}>
                    <MobileNumericInput
                      name="quantity"
                      value={values.quantity || 1}
                      onValueChanged={handleValueChanged}
                      onChange={handleChange}
                      minValue="0"
                      units={food.measureUnit}
                      data-test="foodModal-quantity"
                    />
                    <UnitsLabel data-test="foodModal-units">
                      {formattedUnits}
                    </UnitsLabel>
                  </FlexContainer>
                </ActionContainer>
              </MealActionContainer>
            </MobileDropdownsContainer>
          </div>
        </FoodContainer>
        <NutrientContainer>
          <FoodNutritionContainer food={food} quantity={values.quantity} />
        </NutrientContainer>
        <AddButtonContainer justify="center" alignItems="center">
          <Button
            buttonSize="large"
            width="196px"
            buttonText={submitText}
            pink="true"
            onClick={handleSubmit}
            data-test="foodModal-submit"
          />
        </AddButtonContainer>
      </>
    );
  } else {
    // web food modal view
    return (
      <Container justify="space-between" wrap="wrap">
        <LeftColumn flexDirection="column">
          <FoodInfoContainer flexDirection="column">
            <FoodName
              data-test="foodModal-foodName"
              length={food.verboseName.length}
            >
              {food.verboseName}
            </FoodName>
            <FoodCaloriesContainer>
              <InfoItem alignItems="center">
                <IntensityIcon
                  filled={true}
                  color={colors.secondary600}
                  width="22px"
                  height="22px"
                />
                <InfoColumn flexDirection="column">
                  <InfoLabel>Calories: </InfoLabel>
                  <Info data-test="foodModal-calories">
                    {formatCalories(
                      food.nutrientsPerUnit.calories * values.quantity
                    )}
                  </Info>
                </InfoColumn>
              </InfoItem>
            </FoodCaloriesContainer>
            <MealActionContainer flexDirection="column" justify="center">
              {!['swap', 'shoppinglist'].includes(context.type) && (
                <>
                  <ActionContainer alignItems="center">
                    <ActionLabel>Meal:</ActionLabel>
                    <Dropdown
                      width="290px"
                      options={['Breakfast', 'Lunch', 'Dinner', 'Snack']}
                      value={{
                        value: values.meal,
                        label: values.meal,
                      }}
                      onChange={value =>
                        handleValueChanged('meal', value.value, 'string')
                      }
                      data-test="foodModal-mealSelect"
                    />
                  </ActionContainer>
                  <ActionContainer
                    alignItems="center"
                    data-test="foodPage-dateContainer"
                  >
                    <ActionLabel>Date:</ActionLabel>
                    <Dropdown
                      width="290px"
                      options={dateOptions}
                      value={{
                        value: values.date.value,
                        label: values.date.label,
                      }}
                      onChange={value =>
                        handleDateChanged('date', value.value, value.label)
                      }
                      data-test="foodModal-dateSelect"
                    />
                  </ActionContainer>
                </>
              )}

              <ActionContainer
                alignItems="center"
                data-test="foodModal-quantityContainer"
              >
                <ActionLabel>Quantity:</ActionLabel>
                <FlexContainer alignItems="center" style={{ gap: '16px' }}>
                  <FractionInput
                    name="quantity"
                    value={values.quantity || 1}
                    onValueChanged={handleValueChanged}
                    units={formattedUnits}
                    data-test="foodModal-quantity"
                  />
                </FlexContainer>
              </ActionContainer>
            </MealActionContainer>
          </FoodInfoContainer>
        </LeftColumn>
        <RightColumn flexDirection="column">
          <FoodNutritionContainer food={food} quantity={values.quantity} />
        </RightColumn>
        <AddButtonContainer justify="center" alignItems="center">
          <Button
            buttonSize="large"
            width="196px"
            buttonText={submitText}
            pink="true"
            onClick={handleSubmit}
            data-test="foodModal-submit"
          />
        </AddButtonContainer>
      </Container>
    );
  }
};

const Container = styled(FlexContainer)`
  width: 100%;
`;

const AddButtonContainer = styled(FlexContainer)`
  width: 100%;
  height: 72px;
  position: absolute;
  bottom: ${isMobile() ? '64px' : '0%'};
  left: 0%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.15);
`;

const LeftColumn = styled(FlexContainer)`
  flex-basis: 45%;
  min-width: 400px;
`;

const RightColumn = styled(FlexContainer)`
  flex-basis: 45%;
`;

const FoodInfoContainer = styled(FlexContainer)`
  margin: 56px 0;
`;

const FoodName = styled.h1`
  color: ${colors.primary800};
  margin-top: 16px;
  font-weight: 700;
  font-size: ${props => (props.length > 16 ? '52px' : '64px')};
  line-height: ${props => (props.length > 16 ? '58px' : '72px')};
  text-transform: capitalize;
`;

// mobile view

const FoodContainer = styled(FlexContainer)``;

const FoodCaloriesContainer = styled(FlexContainer)`
  margin: 28px 0;
  gap: 32px;
`;

const MobileFoodName = styled.h1`
  margin: 24px 30px 0px 30px;
  font-weight: 700;
  color: ${colors.primary800};
  line-height: 40px;
  text-align: center;
  text-transform: capitalize;
`;

const MobileInfoContainer = styled(FlexContainer)`
  margin: 24px 0;
  gap: 32px;
`;

const MobileDropdownsContainer = styled(FlexContainer)`
  > * {
    margin-bottom: 30px;
  }
`;

const NutrientContainer = styled.div`
  width: 100%;
  padding: 32px 0 104px 0;
`;

const InfoColumn = styled(FlexContainer)`
  gap: 4px;
`;

const Info = styled.p`
  color: ${colors.secondary600};
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;

const InfoLabel = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.primary800};
`;

const ActionContainer = styled(FlexContainer)`
  gap: 32px;
`;

const ActionLabel = styled.h4`
  color: ${colors.primary600};
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  width: 100px;
`;

const MealActionContainer = styled(FlexContainer)`
  width: ${props => (isMobile() ? 'calc(100% - 64px)' : 'calc(100% - 32px)')};
  background-color: ${colors.primary050};
  gap: 32px;
  padding: 16px;
  margin: ${props => (isMobile() ? '0 16px' : 'initial')};
`;

const UnitsLabel = styled.p`
  color: ${colors.primary800};
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`;

const InfoItem = styled(FlexContainer)`
  gap: 12px;
`;

export default FoodModalView;
