import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';

import CircularProgress from '@material-ui/core/CircularProgress';
import NumericInput from '../../elements/NumericInput';
import Button from '../../elements/Button';
import TextInput from '../../elements/TextInput';
import Dropdown from '../../elements/Dropdown';
import CheckBox from '../../elements/CheckBox';
import AlertStack from '../../elements/AlertStack';

import FitnessList from './FitnessList';

import { getWorkout } from '../../services/api/workout';
import { getActivity } from '../../services/api/activity';

const FitnessPlanForm = ({
  handleChange,
  values,
  handleValueChanged,
  handleDateChanged,
  toggleValues,
  handleToggle = () => () => {},
  filteredWorkouts,
  filteredActivities,
  activities,
  setActivities,
  workouts,
  setWorkouts,
  status,
  setStatus,
  message,
  loading,
  handleSubmit,
  viewOnly,
  buttonText,
  formType,
  undoDelete,
  ...props
}) => {
  const dayOptions = [];
  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  for (let i = 0; i < values.planLength; i++) {
    let dayNum = i + 1;
    dayOptions.push({ value: dayNum, label: days[dayNum - 1] });
  }

  async function addWorkoutToForm(params) {
    try {
      const result = await getWorkout(params.workout);
      const workout = {
        dayNum: parseInt(params.date),
        workout: result,
        week: 1,
        id: workouts && workouts.length + 1,
      };
      setWorkouts([...workouts, workout]);
    } catch (err) {
      console.error(err);
    }
  }

  async function removeWorkoutFromForm(workoutId) {
    try {
      const updatedWorkouts = workouts.filter(workout => {
        if (workout._id) {
          return workout._id !== workoutId;
        }
        return workout.id !== parseInt(workoutId);
      });
      setWorkouts(updatedWorkouts);
    } catch (err) {
      console.error(err);
    }
  }

  async function addActivityToForm(params) {
    try {
      const result = await getActivity(params.activity);
      const activity = {
        dayNum: parseInt(params.date),
        activity: result,
        id: activities && activities.length + 1,
      };
      setActivities([...activities, activity]);
    } catch (err) {
      console.error(err);
    }
  }

  async function removeActivityFromForm(activityId) {
    try {
      const updatedActivities = activities.filter(activity => {
        if (activity._id) {
          return activity._id !== activityId;
        }
        return activity.id !== parseInt(activityId);
      });
      setActivities(updatedActivities);
    } catch (err) {
      console.error(err);
    }
  }

  async function updateWorkoutWeek(workoutToUpdate, week) {
    try {
      const workoutIndex = workouts.findIndex(
        workout =>
          workoutToUpdate === workout._id || workoutToUpdate === workout.id
      );
      const workoutTmp = [...workouts];
      workoutTmp[workoutIndex].week = week;
      setWorkouts(workoutTmp);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FieldSet disabled={viewOnly || false}>
        <CheckBox
          checked={toggleValues.active || ''}
          label="Active"
          onChange={handleToggle()}
          name="active"
          data-test="fitnessPlan-active"
        />
        <TextInput
          id="fitnessPlanRef"
          name="planRef"
          onChange={handleChange}
          value={values.planRef || ''}
          placeholder="Fitness Plan Identifier"
          label="Fitness Plan Identifier"
          onFocus={e => (e.target.placeholder = '')}
          onBlur={e => (e.target.placeholder = 'Fitness Plan Identifier')}
          data-test="fitnessPlan-planRef"
        />
        <Dropdown
          width="430px"
          options={['Weight Loss', 'Weight Maintenance', 'Muscle Gain']}
          label="Fitness Plan Type"
          value={{
            value: values.type,
            label: values.type,
          }}
          onChange={value => handleValueChanged('type', value.value, 'string')}
          data-test="fitnessPlan-type"
        />
        <Dropdown
          width="430px"
          options={['Beginner', 'Intermediate', 'Advanced']}
          label="Level"
          value={{
            value: values.level,
            label: values.level,
          }}
          onChange={value => handleValueChanged('level', value.value, 'string')}
          data-test="fitnessPlan-level"
        />
        <Dropdown
          width="430px"
          options={['Gym', 'Home']}
          label="Primary Location/Setting"
          value={{
            value: values.locationType,
            label: values.locationType,
          }}
          onChange={value =>
            handleValueChanged('locationType', value.value, 'string')
          }
          data-test="fitnessPlan-locationType"
        />
        <Dropdown
          width="430px"
          options={['All', 'Men', 'Women']}
          label="Target Gender"
          value={{
            value: values.gender,
            label: values.gender,
          }}
          onChange={value =>
            handleValueChanged('gender', value.value, 'string')
          }
          data-test="fitnessPlan-gender"
        />
        <NumericInput
          id="planLength"
          name="planLength"
          value={values.planLength || 7}
          onValueChanged={handleValueChanged}
          onChange={handleChange}
          minValue="0"
          label="Plan Duration"
          units="days"
          data-test="fitnessPlan-planLength"
        />
        <HR />
        <Heading>Workouts</Heading>
        <FitnessListContainer flexDirection="column">
          <Dropdown
            width="220px"
            options={dayOptions}
            label="Fitness Plan Day:"
            value={{
              value: values.dayNum.value,
              label: values.dayNum.label,
            }}
            onChange={value =>
              handleDateChanged('dayNum', value.value, value.label)
            }
            data-test="fitnessPlan-daySelect"
          />
          <FitnessList
            workouts={filteredWorkouts}
            activities={filteredActivities}
            date={values.dayNum.value}
            variant="admin"
            addWorkout={addWorkoutToForm}
            removeWorkout={removeWorkoutFromForm}
            addActivity={addActivityToForm}
            removeActivity={removeActivityFromForm}
            updateWeek={updateWorkoutWeek}
          />
        </FitnessListContainer>
      </FieldSet>
      {formType !== 'view' && (
        <Button
          type="submit"
          buttonText={buttonText || 'Create Fitness Plan'}
          buttonSize="large"
          disabled={loading}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
          data-test="fitnessPlan-submit"
        />
      )}
      {status === 'error' ? (
        <AlertStack
          messages={message}
          type="error"
          variant="filled"
          open={status === 'error'}
          handleClose={() => setStatus(null)}
          autoHideDuration={20000}
          data-test="fitnessPlan-message-error"
        />
      ) : status === 'success' ? (
        <AlertStack
          messages={message}
          type="success"
          variant="filled"
          open={status === 'success'}
          handleClose={() => setStatus('hidden')}
          autoHideDuration={20000}
          data-test="fitnessPlan-message-success"
        />
      ) : null}
      {formType === 'delete' &&
        (status === 'success' || status === 'hidden') && (
          <Button
            buttonText="Undo"
            buttonSize="small"
            handleClick={undoDelete}
            pink="true"
            data-test="fitnessPlan-undoDelete"
          />
        )}
    </Form>
  );
};

const Form = styled.form`
  margin-top: 30px;
  > * {
    margin-bottom: 10px;
  }
`;

const FieldSet = styled.fieldset`
  > * {
    margin-bottom: 10px;
  }
`;

const FitnessListContainer = styled(FlexContainer)`
  max-width: 75%;
  min-width: 600px;

  > * {
    margin-bottom: 10px;
  }
`;

const HR = styled.hr`
  margin: 15px 0;
`;

const Heading = styled.h2`
  color: ${colors.primary800};
  text-align: center;
`;

export default FitnessPlanForm;
