export function logAppInfo() {
  const revision = process.env.REACT_APP_REVISION
    ? process.env.REACT_APP_REVISION
    : 'development';
  const shortRevision = revision.substring(0, 7);
  const today = Date();
  console.debug(`Nutriology revision: ${revision} (${shortRevision})`);
  console.debug(`NODE_ENV: ${process.env.NODE_ENV}`);
  console.debug(`APP_ENV: ${process.env.REACT_APP_ENV}`);
  console.debug(`Today is ${today}`);
}

export function isIOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document) ||
    navigator.userAgent.includes('iPhone')
  );
}

export function isInStandaloneMode() {
  return 'standalone' in window.navigator && window.navigator.standalone;
}
