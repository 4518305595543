import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import { getImageURL } from '../services/api/api';

const ExerciseCard = ({
  exercise,
  setExercise,
  setScreen,
  isMobile,
  ...props
}) => {
  const handleClick = e => {
    setExercise(exercise);
    setScreen('Exercise Page');
  };

  const exerciseURL = (ratio = 1) => {
    const height = 152 * ratio;
    return getImageURL(exercise.thumbnailUrl, `height:${height}`);
  };

  if (isMobile) {
    // mobile version
    return (
      <MobileCardContainer
        alignItems="center"
        onClick={handleClick}
        data-test="fitnessModal-exerciseCard"
      >
        <ImageContainer
          image={exerciseURL()}
          image2x={exerciseURL(2)}
        ></ImageContainer>
        <ExerciseNameContainer>{exercise.name}</ExerciseNameContainer>
      </MobileCardContainer>
    );
  } else {
    // web version
    return (
      <CardContainer
        flexDirection="column"
        onClick={handleClick}
        data-test="fitnessModal-exerciseCard"
      >
        <TopContainer alignItems="center">
          <ExerciseTitle>{exercise.name}</ExerciseTitle>
        </TopContainer>
        {!exercise.active && (
          <InactiveContainer flexDirection="column">
            <Info data-test="exerciseCard-inactive">Inactive</Info>
          </InactiveContainer>
        )}
        <BottomContainer
          image={exerciseURL()}
          image2x={exerciseURL(2)}
        ></BottomContainer>
      </CardContainer>
    );
  }
};

const CardContainer = styled(FlexContainer)`
  height: 224px;
  width: 192px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1.5px solid ${colors.primary200};
  border-radius: 4px;
  position: relative;

  :hover {
    cursor: pointer;
    opacity: 0.8;
  }

  :active {
    opacity: 0.7;
  }
`;

const TopContainer = styled(FlexContainer)`
  height: 72px;
  padding: 0 16px;
`;

const BottomContainer = styled(FlexContainer)`
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-size: cover;
  background-position: center;
  height: 152px; // Update image dimensions accordingly.
`;

const ExerciseTitle = styled.h4`
  color: ${colors.primary800};
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
`;

const MobileCardContainer = styled(FlexContainer)`
  box-sizing: border-box;
  width: 100%;
  border: 1.5px solid ${colors.primary200};
  border-radius: 4px;
`;
const ImageContainer = styled(FlexContainer)`
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 90px;
  width: 40%;
  min-width: 40%;
`;
const ExerciseNameContainer = styled.div`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 17px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 0 20px;
  width: auto;
`;

const Info = styled.p`
  color: ${colors.primary500};
  font-size: 13px;
  line-height: 8px;
  margin: 4px;
`;

const InactiveContainer = styled(FlexContainer)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export default ExerciseCard;
