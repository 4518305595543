import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from './FlexContainer';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getYear, getMonth, addYears } from 'date-fns';
import range from 'lodash/range';
import ArrowIcon from '../icons/ArrowIcon';
import Dropdown from '../elements/Dropdown';
import CalendarTodayTwoToneIcon from '@material-ui/icons/CalendarTodayTwoTone';

const DateInput = ({
  label,
  value,
  onValueChanged,
  onChange,
  error,
  ...props
}) => {
  const handleChange = date => {
    if (onValueChanged) {
      onValueChanged(props.name, date.toLocaleDateString('en-US'), 'date');
    } else if (onChange) {
      onChange(date);
    }
  };
  const years = range(getYear(addYears(new Date(), 1)), 1900);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <Container flexDirection="column" {...props}>
      {label && <Label>{label}</Label>}
      {parseInt(props.width, 10) > 160 && (
        <CalendarIconContainer>
          <CalendarTodayTwoToneIcon style={{ color: colors.secondary600 }} />
        </CalendarIconContainer>
      )}
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: '0 2rem',
              display: 'flex',
            }}
          >
            <div
              onClick={decreaseMonth}
              style={props.showMonthYearDropdown ? { marginTop: '6px' } : {}}
            >
              <ArrowIcon
                rotate="true"
                disabled={prevMonthButtonDisabled}
                data-test="datepicker-prevMonth"
              />
            </div>
            {props.showMonthYearDropdown ? (
              <>
                <Dropdown
                  style={{
                    margin: '0 10px',
                    fontSize: '18px',
                  }}
                  width="140px"
                  options={months}
                  value={{
                    value: months[getMonth(date)],
                    label: months[getMonth(date)],
                  }}
                  onChange={value => {
                    changeMonth(months.indexOf(value.value));
                  }}
                  data-test="datepicker-month"
                />
                <Dropdown
                  style={{
                    margin: '0 10px',
                    fontSize: '18px',
                  }}
                  width="93px"
                  options={years}
                  value={{ value: getYear(date), label: getYear(date) }}
                  defaultValue={getYear(date)}
                  onChange={value => {
                    if (value.value) {
                      changeYear(value.value);
                    }
                  }}
                  data-test="datepicker-year"
                />
              </>
            ) : (
              <h5
                className="react-datepicker__current-month"
                data-test="datepicker-currentMonth"
              >
                {months[getMonth(date)]}
              </h5>
            )}
            <div
              onClick={increaseMonth}
              style={props.showMonthYearDropdown ? { marginTop: '6px' } : {}}
            >
              <ArrowIcon
                disabled={nextMonthButtonDisabled}
                data-test="datepicker-nextMonth"
              />
            </div>
          </div>
        )}
        showPopperArrow={false}
        customInput={
          <Input
            type={props.type || 'text'}
            error={error}
            small={props.small}
            data-test="datepicker-input"
          />
        }
        dateFormat={props.dateFormat || 'MM / dd / yyyy'}
        selected={new Date(value)}
        onChange={handleChange}
        popperModifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: 'scrollParent',
          },
        }}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: ${props =>
    props.width ? props.width : props.small ? '161px' : '100%'};
  border-radius: 8px;
  position: relative;

  .react-datepicker {
    border-radius: 2px;
    border: 1px solid ${colors.primary200};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
  .react-datepicker-popper {
    z-index: 10;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__navigation {
    top: 26px;
  }
  .react-datepicker__navigation--previous {
    left: 34px;
  }
  .react-datepicker__navigation--next {
    right: 34px;
  }
  .react-datepicker__header {
    background-color: transparent;
    border-bottom: 0;
    padding-top: 24px;
    .react-datepicker__current-month {
      font-weight: 700;
      font-size: 15px;
      line-height: 23px;
      margin: auto;
      text-transform: uppercase;
      color: ${colors.primary800};
    }
    .react-datepicker__day-names {
      margin-top: 32px;
      margin-bottom: 16px;
      .react-datepicker__day-name {
        color: ${colors.primary400};
        margin: 0;
        width: 3rem;
        user-select: none;
      }
    }
  }
  .react-datepicker__month {
    margin: 1.5rem;
    .react-datepicker__day {
      width: 3rem;
      line-height: 3rem;
      margin: 0;
      border-radius: 100%;
      color: ${colors.primary800};
      user-select: none;
      &:hover {
        border-radius: 100%;
        background-color: ${colors.primary100};
      }
      &:active {
        background-color: ${colors.primary200};
      }
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
      background-color: ${colors.secondary300};
      color: ${colors.primary800};
    }
  }
`;

const Input = styled.input`
  box-sizing: border-box;
  height: ${props => (props.small ? '28px' : '41px')};
  width: 100%;
  border: ${props =>
    props.error ? '1px solid #ff7c0e' : `1px solid ${colors.primary400}`};
  background-color: #ffffff;
  margin: 0;
  padding: 0 12px;
  font-size: ${props => (props.small ? '15px' : '17px')};
  line-height: ${props => (props.small ? '18px' : '20px')};
  border-radius: 8px;

  &:focus {
    border: ${props =>
      props.error ? '1px solid #ff7c0e' : `1px solid ${colors.secondary500}`};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
  &:disabled {
    background-color: ${colors.primary050};
    color: ${colors.primary300};
    border: ${colors.primary300};
  }

  &::placeholder {
    color: ${colors.primary400};
  }
`;

const CalendarIconContainer = styled(FlexContainer)`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 5;
`;

const ErrorMessage = styled.p`
  color: #ff7c0e;
  text-align: ${props => (props.center ? 'center' : 'right')};
  margin: ${props => (props.center ? '16px 0 0 0' : '4px 0 5px 0')};
  padding: 0px;
  font-size: 11px;
  line-height: 14px;
`;

const Label = styled.label`
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary600};
  margin-bottom: 8px;
`;

export default DateInput;
