import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import CloseIcon from '../icons/CloseIcon';
import ProgressBadge from '../universal/ProgressBadge';
import MacroTable from '../mealPlan/MacroTable';
import { colors } from '../styleConstants';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { calculateMealStats } from '../mealPlan/calculateMealStats';
import { calculateWorkoutStats } from '../fitnessPlan/calculateWorkoutStats';
import { connect } from 'react-redux';

const MobileDashboardInfo = ({
  type,
  selectedDate,
  mealPlan,
  fitnessPlan,
  handleClose,
  ...props
}) => {
  const dateValue = selectedDate ?? new Date();

  if (type === 'meal') {
    const { totalCalories, totalCaloriesComplete } = calculateMealStats(
      mealPlan,
      dateValue
    );
    return (
      <DashboardContainer flexDirection="column" alignItems="center">
        <CloseIconContainer>
          <CloseIcon handleClose={handleClose} data-test="mobile-stats-close" />
        </CloseIconContainer>
        <DashboardHeading>{type} dashboard</DashboardHeading>
        <InfoContainer>
          <>
            <FlexContainer justify="space-around" alignItems="center">
              <ProgressBadge
                color="teal"
                size="small"
                current={totalCaloriesComplete}
                total={totalCalories}
              />
              <div>
                <Caption>Total Planned:</Caption>
                <Heading data-test="calories-planned">
                  {totalCalories} calories
                </Heading>
                <br />
                <Caption>Total Recommended:</Caption>
                <RecommendedCalories data-test="calories-recommended">
                  {mealPlan.recommendedCalories} calories
                </RecommendedCalories>
              </div>
            </FlexContainer>
            <StatsContainer>
              <MacroTable
                color="teal"
                mealPlan={mealPlan}
                barSize={props.barSize}
              />
            </StatsContainer>
          </>
        </InfoContainer>
      </DashboardContainer>
    );
  } else {
    const BorderLinearProgress = withStyles(theme => ({
      root: {
        height: 12,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor:
          theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
      },
      bar: {
        borderRadius: 6,
        backgroundColor: colors.secondary400,
      },
    }))(LinearProgress);

    const { totalCaloriesBurned, totalCaloriesWorkout } = calculateWorkoutStats(
      fitnessPlan,
      dateValue
    );

    const loggedCaloriesInPercentage =
      (totalCaloriesBurned / totalCaloriesWorkout) * 100;

    return (
      <DashboardContainer flexDirection="column" alignItems="center">
        <CloseIconContainer>
          <CloseIcon handleClose={handleClose} data-test="mobile-stats-close" />
        </CloseIconContainer>
        <DashboardHeading>{type} dashboard</DashboardHeading>
        <InfoContainer>
          <>
            <FlexContainer justify="space-between">
              <LoggedCaloriesLabel>Logged</LoggedCaloriesLabel>
              <PlannedCaloriesLabel>Planned</PlannedCaloriesLabel>
            </FlexContainer>
            <BorderLinearProgress
              variant="determinate"
              value={loggedCaloriesInPercentage}
            />
            <FlexContainer justify="space-between">
              <LoggedCaloriesValue data-test="workout-calories-burned">
                {totalCaloriesBurned}
                <LoggedCaloriesValueLabel>calories</LoggedCaloriesValueLabel>
              </LoggedCaloriesValue>
              <PlannedCaloriesValue data-test="workout-goal">
                {totalCaloriesWorkout}
                <PlannedCaloriesValueLabel>calories</PlannedCaloriesValueLabel>
              </PlannedCaloriesValue>
            </FlexContainer>
          </>
        </InfoContainer>
      </DashboardContainer>
    );
  }
};

const DashboardContainer = styled(FlexContainer)`
  margin: 21px;
`;

const CloseIconContainer = styled.div`
  align-self: flex-end;
`;
const DashboardHeading = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #484848;
`;

const Caption = styled.p`
  color: #808080;
  font-family: 'Work Sans';
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
`;
const Heading = styled.h5`
  color: ${colors.secondary500};
  font-family: 'Work Sans';
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
`;

const InfoContainer = styled.div`
  width: 100%;
  margin-top: 36px;
`;

const StatsContainer = styled.div`
  margin-top: 40px;
  padding: 0 24px;
`;

const RecommendedCalories = styled(Heading)`
  color: #a0a0a0;
`;
const LoggedCaloriesLabel = styled.p`
  color: ${colors.secondary500};
  font-family: 'Work Sans';
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  padding: 15px;
`;
const LoggedCaloriesValue = styled.span`
  color: ${colors.secondary500};
  font-family: 'Work Sans';
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0;
  padding: 15px;
`;
const PlannedCaloriesLabel = styled.p`
  color: #a0a0a0;
  font-family: 'Work Sans';
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  padding: 15px;
`;
const PlannedCaloriesValue = styled.span`
  color: #686868;
  font-family: 'Work Sans';
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0;
  padding: 15px;
`;
const LoggedCaloriesValueLabel = styled(LoggedCaloriesLabel)`
  padding: 0;
`;
const PlannedCaloriesValueLabel = styled(PlannedCaloriesLabel)`
  padding: 0;
`;

function mapStateToProps(state) {
  const { selectedMealPlan, activeFitnessPlan, selectedDate } = state;

  return {
    mealPlan: selectedMealPlan,
    fitnessPlan: activeFitnessPlan,
    selectedDate,
  };
}

export default connect(mapStateToProps)(MobileDashboardInfo);
