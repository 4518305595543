import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from './FlexContainer';
import DropdownArrowIcon from '../icons/DropdownArrowIcon';
import Fraction from 'fraction.js/fraction';
import { isMobile } from '../helpers/utils';

const NumericInput = ({
  label,
  value,
  onValueChanged,
  stepBy,
  units,
  unitColor,
  minValue,
  maxValue,
  relatedName,
  relatedValue,
  relatedValueMin,
  relatedValueMax,
  ...props
}) => {
  const node = useRef();
  const [focused, setFocused] = useState(false);
  const step = stepBy || 1;

  if (typeof value !== 'number' && value !== '') {
    value = new Fraction(value).valueOf();
  }

  const handleStepUp = () => {
    if (value % 1 !== 0) {
      value = parseInt(value, 10);
    }
    if (maxValue) {
      const maxValueNumber = parseInt(maxValue);
      if (value + step <= maxValueNumber) {
        onValueChanged(props.name, value + step);
      } else {
        if (relatedName && relatedValue) {
          const relatedValueNumber = parseInt(relatedValue) + 1;
          if (relatedValueNumber <= parseInt(relatedValueMax)) {
            onValueChanged(relatedName, relatedValueNumber);
          }
        }
        const minValueNumber = parseInt(minValue) || 0;
        onValueChanged(props.name, minValueNumber);
      }
    } else {
      onValueChanged(props.name, value + step);
    }
  };

  const handleStepDown = () => {
    if (value % 1 !== 0) {
      value = parseInt(value, 10);
    }
    if (minValue) {
      const minValueNumber = parseInt(minValue);
      if (value - step >= minValueNumber) {
        onValueChanged(props.name, value - step);
      } else {
        if (relatedName && relatedValue) {
          const relatedValueNumber = parseInt(relatedValue) - 1;
          if (relatedValueNumber >= parseInt(relatedValueMin)) {
            onValueChanged(relatedName, relatedValueNumber);
          }
        }
        const maxValueNumber = Number(maxValue) || 0;
        onValueChanged(props.name, maxValueNumber);
      }
    } else {
      onValueChanged(props.name, value - step);
    }
  };

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      setFocused(true);
      return;
    }
    // outside click
    setFocused(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  if (!isMobile())
    // Web view
    return (
      <div ref={node}>
        <Container flexDirection="column">
          {label && <Label>{label}</Label>}
          <ContentContainer alignItems="center" small={props.small}>
            <InputContainer value={value} disabled={props.disabled}>
              <Input
                type="number"
                step="any"
                value={value}
                focused={focused}
                {...props}
              />
              <StepUp
                focused={focused}
                justify="center"
                alignItems="center"
                onClick={handleStepUp}
                small={props.small}
                error={props.error}
                data-test={`${props['data-test']}-stepUp`}
              >
                <DropdownArrowIcon small rotate />
              </StepUp>

              <StepDown
                focused={focused}
                justify="center"
                alignItems="center"
                onClick={handleStepDown}
                small={props.small}
                error={props.error}
                data-test={`${props['data-test']}-stepDown`}
              >
                <DropdownArrowIcon small />
              </StepDown>
            </InputContainer>
            {units && (
              <Units unitColor={unitColor} data-test="numericInput-units">
                {units}
              </Units>
            )}
          </ContentContainer>
          {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
        </Container>
      </div>
    );
  else {
    // Mobile view
    return (
      <div ref={node}>
        <FormContainer value={value} disabled={props.disabled}>
          <DecreaseButton
            focused={focused}
            onClick={handleStepDown}
            data-test={`${props['data-test']}-stepDown`}
            alignItems="center"
            justify="center"
          >
            <MinusButton>-</MinusButton>
          </DecreaseButton>
          <Inputbox
            type={props.type || 'string'}
            step="any"
            focused={focused}
            alignItems="center"
            justify="space-around"
          >
            <InputMobile
              type={props.type || 'number'}
              step="any"
              value={value}
              focused={focused}
              {...props}
            />
            <MobileUnitLabel strLength={units?.length}>{units}</MobileUnitLabel>
          </Inputbox>
          <IncreaseButton
            focused={focused}
            onClick={handleStepUp}
            data-test={`${props['data-test']}-stepUp`}
            alignItems="center"
            justify="center"
          >
            <ButtonColor>+</ButtonColor>
          </IncreaseButton>
        </FormContainer>
      </div>
    );
  }
};

const Container = styled(FlexContainer)``;

const FormContainer = styled(FlexContainer)`
  text-align: center;
  height: 34px;
  width: 197px;
  margin-bottom: 4px;
`;

const IncreaseButton = styled(FlexContainer)`
  margin-top: 1px;
  width: 40px;
  height: 38px;
  background: #21ccbf;
  border-radius: 0 4px 4px 0;
`;

const DecreaseButton = styled(FlexContainer)`
  margin-top: 1px;
  width: 40px;
  height: 38px;
  background: #21ccbf;
  border-radius: 4px 0 0 4px;
`;

const Inputbox = styled(FlexContainer)`
  text-align: center;
  border: none;
  border-top: 2px solid #21ccbf;
  border-bottom: 2px solid #21ccbf;
  border-color: #21ccbf;
  margin-top: 1px;
  width: 130px;
  height: 34px;
  padding: 0 16px;
`;
const InputMobile = styled.input`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border: ${props => (props.error ? '1px solid #ff7c0e' : 'none')};
  font-size: ${props => (props.small ? '15px' : '17px')};
  line-height: ${props => (props.small ? '18px' : '20px')};
  font-family: 'Work Sans';
  color: ${colors.primary900};
  font-weight: bold;
  padding: 0;
`;

const MobileUnitLabel = styled.span`
  color: ${colors.primary600};
  font-family: 'Work Sans';
  font-size: ${props => (props.strLength > 8 ? '12px' : '17px')};
  line-height: ${props => (props.strLength > 8 ? '15px' : '20px')};
  text-align: center;
`;

const ButtonColor = styled.div`
  color: white;
  font-size: 32px;
  font-weight: 100;
`;

const MinusButton = styled(ButtonColor)`
  margin-top: -3px;
`;

const ContentContainer = styled(FlexContainer)`
  height: ${props => (props.small ? '29px' : '41px')};
`;

const Units = styled.h4`
  color: ${props =>
    props.unitColor === 'grey' ? colors.primary500 : colors.primary800};
  margin-left: 10.5px;
  user-select: none;
`;

const Stepper = styled(FlexContainer)`
  height: ${props => (props.small ? '13px' : '19px')};
  width: ${props => (props.small ? '18px' : '20px')};
  position: absolute;
  right: 0;
  visibility: hidden;
  z-index: 5;
  border-style: solid;
  border-width: 1px;
  border-color: ${colors.primary400};
  cursor: pointer;
  &:hover {
    background-color: ${colors.primary050};
  }
`;

const StepUp = styled(Stepper)`
  top: 0;
  border-top-right-radius: 2px;
  border-right-color: ${props =>
    props.error
      ? '#ff7c0e'
      : props =>
          props.focused ? `${colors.secondary500}` : `${colors.primary400}`};
  border-top-color: ${props =>
    props.error
      ? '#ff7c0e'
      : props =>
          props.focused ? `${colors.secondary500}` : `${colors.primary400}`};
`;

const StepDown = styled(Stepper)`
  bottom: 0;
  border-bottom-right-radius: 2px;
  border-right-color: ${props =>
    props.error
      ? '#ff7c0e'
      : props =>
          props.focused ? `${colors.secondary500}` : `${colors.primary400}`};
  border-bottom-color: ${props =>
    props.error
      ? '#ff7c0e'
      : props =>
          props.focused ? `${colors.secondary500}` : `${colors.primary400}`};
`;

const InputContainer = styled(FlexContainer)`
  display: inline-flex;
  position: relative;
  min-width: 68px;
  max-width: 116px;
  overflow: hidden;
  width: ${props => `${props.value.toString().length + 1.25}em`};
  height: 100%;
  &:hover {
    & ${Stepper} {
      visibility: ${props => (props.disabled ? 'hidden' : 'visible')};
    }
  }
`;

const Label = styled.label`
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary600};
  margin-bottom: 8px;
`;

const Input = styled.input`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border: ${props =>
    props.error
      ? '1px solid #ff7c0e'
      : props =>
          props.focused
            ? `1px solid ${colors.secondary500}`
            : `1px solid ${colors.primary400}`};
  background-color: #ffffff;
  padding: 0 12px;
  font-size: ${props => (props.small ? '15px' : '17px')};
  line-height: ${props => (props.small ? '18px' : '20px')};
  border-radius: 2px;
  &:focus {
    border: ${props =>
      props.error ? '1px solid #ff7c0e' : `1px solid ${colors.secondary500}`};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    ~ ${StepUp} {
      border-top: ${props =>
        props.error ? '1px solid #ff7c0e' : `1px solid ${colors.secondary500}`};
      border-right: ${props =>
        props.error ? '1px solid #ff7c0e' : `1px solid ${colors.secondary500}`};
    }
    ~ ${StepDown} {
      border-bottom: ${props =>
        props.error ? '1px solid #ff7c0e' : `1px solid ${colors.secondary500}`};
      border-right: ${props =>
        props.error ? '1px solid #ff7c0e' : `1px solid ${colors.secondary500}`};
    }
  }
  ${StepUp}:focus & {
    border: ${props =>
      props.error ? '1px solid #ff7c0e' : `1px solid ${colors.secondary500}`};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
  &:disabled {
    background-color: ${colors.primary050};
    color: ${colors.primary300};
    border: 1px solid ${colors.primary300};
  }
  &::placeholder {
    color: ${colors.primary400};
  }
`;

const ErrorMessage = styled.p`
  color: #ff7c0e;
  text-align: ${props => (props.center ? 'center' : 'right')};
  margin: ${props => (props.center ? '16px 0 0 0' : '4px 0 5px 0')};
  padding: 0px;
  font-size: 11px;
  line-height: 14px;
`;

export default NumericInput;
