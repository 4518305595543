import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';

import { omit, cloneDeep, isEmpty } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useFormSubmit from '../../hooks/useFormSubmit';
import useToggles from '../../hooks/useToggles';
import useModal from '../../hooks/useModal';
import ModalContainer from '../../sharedModals/ModalContainer';

import FitnessPlanForm from './FitnessPlanForm';
import { getFitnessPlan, addFitnessPlan } from '../../services/api/fitnessPlan';

const DuplicateFitnessPlan = props => {
  const initialValues = {
    locationType: 'Home',
    gender: 'All',
    dayNum: {
      value: 1,
      label: `Monday`,
    },
    type: 'Weight Loss',
    level: 'Beginner',
    planLength: 7,
  };

  const initialToggleValues = {
    active: false,
  };

  const {
    values,
    setValues,
    handleChange,
    handleDateChanged,
    handleValueChanged,
  } = useFormValues(initialValues);

  const { toggleValues, handleToggle, setToggleValues } = useToggles(
    initialToggleValues
  );

  const { open, launchModal, closeModal } = useModal(false);
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const [workouts, setWorkouts] = useState([]);
  const [activities, setActivities] = useState([]);

  const [filteredWorkouts, setFilteredWorkouts] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);

  const [fitnessPlan, setFitnessPlan] = useState({});

  async function loadFitnessPlan(params) {
    try {
      const fitnessPlanId = params.fitnessPlan;
      const result = await getFitnessPlan(fitnessPlanId);

      if (result) {
        setFitnessPlan(result);
      } else {
        throw new Error('Fitness Plan could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded fitness plan
  useEffect(() => {
    if (!isEmpty(fitnessPlan)) {
      setWorkouts([...fitnessPlan.workouts]);
      setActivities([...fitnessPlan.activities]);

      setValues({
        ...values,
        planRef: fitnessPlan.planRef,
        type: fitnessPlan.type,
        planLength: fitnessPlan.planLength,
        level: fitnessPlan.level,
        gender: fitnessPlan.gender,
        locationType: fitnessPlan.locationType,
      });

      setToggleValues({ active: fitnessPlan.active });
    }
  }, [fitnessPlan]);

  useEffect(() => {
    const filteredWorkout = workouts.filter(workout => {
      return workout.dayNum === values.dayNum.value;
    });
    const filteredActivity = activities.filter(activity => {
      return activity.dayNum === values.dayNum.value;
    });
    setFilteredWorkouts(filteredWorkout);
    setFilteredActivities(filteredActivity);
  }, [workouts, activities, values.dayNum]);

  const getDeletedRecordNames = () => {
    let workoutString = '';
    let activityString = '';
    if (workouts.some(workout => workout.workout.isDeleted)) {
      const deletedWorkouts = workouts
        .filter(workout => workout.workout.isDeleted)
        .map(workout => workout.workout.name);
      workoutString = deletedWorkouts.join(', ');
    }
    if (activities.some(activity => activity.activity.isDeleted)) {
      const deletedActivities = activities
        .filter(activity => activity.activity.isDeleted)
        .map(activity => activity.activity.name);
      activityString = deletedActivities.join(', ');
    }
    let recordString = '';
    if (workoutString.length && activityString.length) {
      recordString = workoutString.concat(', ', activityString);
    } else if (workoutString.length) {
      recordString = workoutString;
    } else {
      recordString = activityString;
    }

    if (recordString.length) {
      return recordString;
    } else {
      return false;
    }
  };

  const submitData = () => {
    setLoading(true);
    // Prevent saving if any workouts or activities have been deleted
    const deletedRecordNames = getDeletedRecordNames();
    if (deletedRecordNames) {
      setStatus('error');
      setMessage(
        `The following items are deleted and must be removed or replaced before saving: ${deletedRecordNames}`
      );
      setLoading(false);
      return;
    }

    let payload = cloneDeep(omit(values, ['dayNum']));
    const workoutRefs = workouts.map(workout => {
      return {
        name: workout.workout.name,
        dayNum: workout.dayNum,
        workout: workout.workout._id,
        week: workout.week,
      };
    });
    const activityRefs = activities.map(activity => {
      return {
        name: activity.activity.name,
        dayNum: activity.dayNum,
        activity: activity.activity._id,
      };
    });
    payload.workouts = workoutRefs;
    payload.activities = activityRefs;
    payload.active = toggleValues.active;

    addFitnessPlan(payload)
      .then(result => {
        setStatus('success');
        setMessage(`Fitness Plan "${result.name}" was created successfully!`);
        setValues(initialValues);
        setToggleValues(initialToggleValues);
        setWorkouts([]);
        setActivities([]);
      })
      .catch(err => {
        console.error(err);
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin'
  );

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Fitness Plan"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
        data-test="fitnessPlan-find"
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          showOnly: 'fitnessPlan',
        }}
        initialScreen="Exercise Search"
        handleClose={closeModal}
        addFitnessPlan={loadFitnessPlan}
        {...props}
      />
      <FitnessPlanForm
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        handleDateChanged={handleDateChanged}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        filteredWorkouts={filteredWorkouts}
        filteredActivities={filteredActivities}
        activities={activities}
        setActivities={setActivities}
        workouts={workouts}
        setWorkouts={setWorkouts}
        status={status}
        setStatus={setStatus}
        message={message}
        loading={loading}
        handleSubmit={handleSubmit}
        buttonText="Create Fitness Plan"
        viewOnly={isEmpty(fitnessPlan)}
        formType="create"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default DuplicateFitnessPlan;
