import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import { isMobile } from '../helpers/utils';
import { connect } from 'react-redux';
import FlexContainer from '../elements/FlexContainer';
import Button from '../elements/Button';
import Dropdown from '../elements/Dropdown';
import AddIcon from '../icons/AddIcon';
import IntensityIcon from '../icons/IntensityIcon';
import RemoveXIcon from '../icons/RemoveXIcon';
import moment from 'moment';
import { without } from 'lodash';
import { formatDate } from '../helpers/date';
import { formatCalories } from '../helpers/nutrition';
import { getImageURL } from '../services/api/api';
import { trackEvent } from '../integrations/analytics';

import useFormValues from '../hooks/useFormValues';

import RecipeDetails from '../mealPlan/recipe/RecipeDetails';
import RecipeNutritionContainer from '../mealPlan/recipe/RecipeNutritionContainer';
import MobileDropdown from '../elements/MobileDropdown';
import PlaceholderStats from '../mealPlan/recipe/PlaceholderStats';
import { addMultipleRecipesToMealPlan } from '../store/actions/mealplan';
import { getUserMealPlans } from '../services/api/mealplan';

const RecipeModalView = ({
  recipe,
  context = {},
  handleClose,
  addRecipe,
  mealPlan,
  addMultipleRecipesToMealPlan,
  userId,
  ...props
}) => {
  const defaultMeal = context?.meal ? context.meal : 'Breakfast';
  let defaultDate;
  let formattedDate;
  const dateOptions = [];
  /*
  If we launch this modal from an admin context (adding recipes to
  a static meal plan), then we want to use the day number of the plan.
  It should be date-agnostic. If we are adding to a user's meal plan,
  we want to be able to select an actual date.
  */

  if (context?.variant === 'admin') {
    defaultDate = context?.date || 1;
    formattedDate = `Day ${defaultDate}`;

    for (let i = 0; i < 7; i++) {
      const dayNum = i + 1;
      dateOptions.push({ value: dayNum, label: `Day ${dayNum}` });
    }
  } else {
    if (context?.date) {
      defaultDate = context.date;
    } else {
      defaultDate = formatDate(moment());
    }
    formattedDate = moment(defaultDate)
      .utc()
      .format(isMobile() ? 'ddd, MMM Do' : 'dddd, MMMM Do');

    for (let i = 0; i < 7; i++) {
      let day = moment().add(i, 'days');
      let formattedDay = day.format(
        isMobile() ? 'ddd, MMM Do' : 'dddd, MMMM Do'
      );
      day = day.toDate();
      dateOptions.push({ value: day, label: formattedDay });
    }
  }

  const { values, handleDateChanged, handleValueChanged } = useFormValues({
    meal: defaultMeal,
    date: {
      value: defaultDate,
      label: formattedDate,
    },
  });

  const [leftovers, setLeftovers] = useState([]);
  const [recipeYield, setRecipeYield] = useState(leftovers.length + 1);

  useEffect(() => {
    setRecipeYield(leftovers.length + 1);
  }, [leftovers]);

  const addLeftover = () => {
    trackEvent(
      `Add Leftover (Recipe Modal - ${isMobile() ? 'Mobile' : 'Web'})`
    );
    setLeftovers([
      ...leftovers,
      {
        meal: defaultMeal,
        leftover: true,
        date: {
          value: formatDate(
            moment(defaultDate).add(leftovers.length + 2, 'days')
          ),
          label: moment(defaultDate)
            .add(leftovers.length + 2, 'days')
            .utc()
            .format(isMobile() ? 'ddd, MMM Do' : 'dddd, MMMM Do'),
        },
      },
    ]);
  };

  const removeLeftover = leftover => {
    trackEvent(
      `Remove Leftover (Recipe Modal - ${isMobile() ? 'Mobile' : 'Web'})`
    );
    setLeftovers(without(leftovers, leftover));
  };

  const handleLeftoverChange = (idx, type, value) => {
    const updatedLeftovers = [...leftovers];
    updatedLeftovers[idx][type] = value;
    setLeftovers(updatedLeftovers);
  };

  const leftoverItems = leftovers.map((leftover, idx) => {
    if (isMobile()) {
      return (
        <div style={{ width: '100%' }} data-test="recModal-leftoverRow">
          <MobileMealActionContainer>
            <MobileMealLabel>Meal:</MobileMealLabel>
            <MobileDropdown
              width="195px"
              options={['Breakfast', 'Lunch', 'Dinner', 'Snack']}
              value={{
                value: leftovers[idx].meal,
                label: leftovers[idx].meal,
              }}
              onChange={value => handleLeftoverChange(idx, 'meal', value.value)}
              data-test={`leftover-mealSelect-${idx + 1}`}
            />
          </MobileMealActionContainer>
          <MobileMealActionContainer>
            <MobileMealLabel>Date:</MobileMealLabel>
            <MobileDropdown
              width="195px"
              options={dateOptions}
              value={{
                value: leftovers[idx].date.value,
                label: leftovers[idx].date.label,
              }}
              onChange={value =>
                handleLeftoverChange(idx, 'date', {
                  value: value.value,
                  label: value.label,
                })
              }
              data-test={`leftover-dateSelect-${idx + 1}`}
            />
          </MobileMealActionContainer>
          <MobileRemoveLeftoverContainer justify="center" alignItems="center">
            <RemoveXIcon
              onClick={() => removeLeftover(leftover)}
              color={colors.hlitetwo400}
              size="16px"
              data-test={`leftover-remove-${idx + 1}`}
            />
            <RemoveTitle onClick={() => removeLeftover(leftover)}>
              Remove
            </RemoveTitle>
          </MobileRemoveLeftoverContainer>
        </div>
      );
    } else {
      return (
        <LeftoverRow
          key={idx}
          alignItems="center"
          data-test="recModal-leftoverRow"
        >
          <Dropdown
            width="320px"
            options={['Breakfast', 'Lunch', 'Dinner', 'Snack']}
            label="Meal:"
            value={{
              value: leftovers[idx].meal,
              label: leftovers[idx].meal,
            }}
            onChange={value => handleLeftoverChange(idx, 'meal', value.value)}
            data-test={`leftover-mealSelect-${idx + 1}`}
          />
          <Dropdown
            width="320px"
            options={dateOptions}
            label="Date:"
            value={{
              value: leftovers[idx].date.value,
              label: leftovers[idx].date.label,
            }}
            onChange={value =>
              handleLeftoverChange(idx, 'date', {
                value: value.value,
                label: value.label,
              })
            }
            data-test={`leftover-dateSelect-${idx + 1}`}
          />
          <StyledRemoveXIcon
            onClick={() => removeLeftover(leftover)}
            color={colors.hlitetwo400}
            data-test={`leftover-remove-${idx + 1}`}
          />
        </LeftoverRow>
      );
    }
  });

  const handleSubmit = async e => {
    e.stopPropagation();
    let recipeId;
    if (recipe.__t === 'RecipeUser' && !recipe.custom) {
      recipeId = recipe.recipeAdmin;
    } else {
      recipeId = recipe.id;
    }

    const mainRecipe = {
      mealType: values.meal,
      date:
        context.variant === 'admin'
          ? values.date.value
          : formatDate(values.date.value),
      recipe: recipeId,
      yield: recipeYield,
    };

    // If we have leftovers, we need to submit multiple recipes to be created in the backend
    if (
      leftovers.length > 0 &&
      context.variant !== 'admin' &&
      context.variant !== 'swap'
    ) {
      const leftoverRecipes = leftovers.map(leftover => {
        return {
          mealType: leftover.meal,
          date: formatDate(leftover.date.value),
          recipe: recipeId,
          leftover: true,
        };
      });
      // If leftovers are added to next week, we need to add them separately to next
      // week's meal plan
      const leftoversThisWeek = leftoverRecipes.filter(leftover => {
        return (
          formatDate(leftover.date) <=
          formatDate(
            moment()
              .endOf('isoWeek')
              .startOf('day')
          )
        );
      });
      const leftoversNextWeek = leftoverRecipes.filter(
        leftover =>
          formatDate(leftover.date) > formatDate(moment().endOf('isoWeek'))
      );

      if (leftoversNextWeek.length > 0) {
        const nextWeekMealPlan = await getUserMealPlans({
          query: {
            user: userId,
            startDate: formatDate(
              moment()
                .add(1, 'week')
                .startOf('isoWeek')
            ),
            endDate: formatDate(
              moment()
                .add(1, 'week')
                .endOf('isoWeek')
            ),
          },
        });
        await addMultipleRecipesToMealPlan(
          nextWeekMealPlan[0]._id,
          leftoversNextWeek
        );
      }
      mainRecipe.yield = recipeYield;
      const recipes = [mainRecipe, ...leftoversThisWeek];
      await addMultipleRecipesToMealPlan(mealPlan._id, recipes);
    } else {
      await addRecipe(mainRecipe);
    }
    handleClose();
  };

  const recipeImageUrl = (ratio = 1) => {
    const height = 500 * ratio;
    const width = 550 * ratio;
    return getImageURL(
      recipe?.imageUrl,
      `resizing_type:fill/height:${height}/width:${width}`
    );
  };

  const prepTime = recipe?.prepTime ?? 0;
  const cookTime = recipe?.cookTime ?? 0;
  const totalTime = prepTime + cookTime;

  const submitText = context?.type === 'swap' ? 'Swap Recipe' : 'Add Recipe';

  if (isMobile()) {
    // mobile recipe modal view
    return (
      <>
        <MobileRecipeContainer>
          <div style={{ width: '100%' }}>
            <MobileRecipeImage
              image={recipeImageUrl()}
              image2x={recipeImageUrl(2)}
            />

            <MobileRecipeName data-test="recModal-recipeName">
              {recipe.name}
            </MobileRecipeName>
            {!recipe.placeholder && (
              <RecipeInfoContainer justify={isMobile() ? 'center' : 'inherit'}>
                {(prepTime > 0 || cookTime > 0) && (
                  <InfoItem alignItems="center">
                    <PrepIcon
                      src={`${process.env.PUBLIC_URL}/icons/Preparation.svg`}
                    />
                    <InfoColumn flexDirection="column">
                      <InfoLabel>Ready In: </InfoLabel>
                      <Info data-test="recModal-totalTime">
                        {totalTime} minutes
                      </Info>
                    </InfoColumn>
                  </InfoItem>
                )}
                {recipe?.nutrients?.calories > 0 && (
                  <InfoItem alignItems="center">
                    <IntensityIcon
                      filled={true}
                      color={colors.secondary600}
                      width="22px"
                      height="22px"
                    />
                    <InfoColumn flexDirection="column">
                      <InfoLabel>Calories: </InfoLabel>
                      <Info data-test="recModal-calories">
                        {formatCalories(recipe?.nutrients?.calories)}
                      </Info>
                    </InfoColumn>
                  </InfoItem>
                )}
              </RecipeInfoContainer>
            )}
            {context?.type !== 'swap' && (
              <MealActionContainer flexDirection="column" justify="center">
                <ActionContainer alignItems="center">
                  <ActionLabel>Meal:</ActionLabel>
                  <MobileDropdown
                    width="220px"
                    options={['Breakfast', 'Lunch', 'Dinner', 'Snack']}
                    value={{
                      value: values.meal,
                      label: values.meal,
                    }}
                    onChange={value =>
                      handleValueChanged('meal', value.value, 'string')
                    }
                    data-test="recModal-mealSelect"
                  />
                </ActionContainer>
                {context.variant !== 'dashboard' && (
                  <ActionContainer alignItems="center">
                    <ActionLabel>Date:</ActionLabel>
                    <MobileDropdown
                      width="220px"
                      options={dateOptions}
                      value={{
                        value: values.date.value,
                        label: values.date.label,
                      }}
                      onChange={value =>
                        handleDateChanged('date', value.value, value.label)
                      }
                      data-test="recModal-dateSelect"
                    />
                  </ActionContainer>
                )}
              </MealActionContainer>
            )}
            {!recipe.placeholder && (
              <LeftoverContainer flexDirection="column">
                {leftovers.length > 0 && (
                  <LeftoverTitle data-test="recModal-leftoverHeader">
                    Leftovers
                  </LeftoverTitle>
                )}
                {leftovers.length > 0 && leftoverItems}
                <FlexContainer alignSelf="center">
                  <AddIcon
                    labelText="Add Leftovers"
                    orientation="row"
                    pink="true"
                    onClick={addLeftover}
                    data-test="recModal-addLeftovers"
                  />
                </FlexContainer>
              </LeftoverContainer>
            )}
          </div>
        </MobileRecipeContainer>
        {recipe.placeholder ? (
          <FlexContainer
            flexDirection="column"
            gap="22px"
            style={{ position: 'relative', marginTop: 16 }}
          >
            <PlaceholderRecHeader>Recommended Nutrition</PlaceholderRecHeader>
            <PlaceholderStats recipe={recipe} editable={false} />
          </FlexContainer>
        ) : (
          <>
            <RecipeDetails
              recipe={recipe}
              recipeYield={recipe?.yield}
              context={{ ...context, modal: true }}
            />
            {recipe.source && (
              <MobileAttribution href={recipe.attrLink}>
                Recipe by {recipe.source}
              </MobileAttribution>
            )}
            <MobileNutrientContainer>
              <RecipeNutritionContainer
                nutrients={recipe.nutrients}
                totalCalories={recipe?.nutrients?.calories || 0}
              />
            </MobileNutrientContainer>
          </>
        )}
        <AddButtonContainer justify="center" alignItems="center">
          <Button
            buttonSize="large"
            width="196px"
            buttonText={submitText}
            pink="true"
            onClick={handleSubmit}
            data-test="recModal-submit"
          />
        </AddButtonContainer>
      </>
    );
  } else {
    // web recipe modal view
    return (
      <FlexContainer flexDirection="column">
        <TopContainer>
          <Column flexDirection="column">
            <RecipeImage image={recipeImageUrl()} image2x={recipeImageUrl(2)} />
          </Column>
          <Column flexDirection="column" gap="28px">
            <RecipeName
              data-test="recModal-recipeName"
              length={recipe.name.length}
            >
              {recipe.name}
            </RecipeName>
            {!recipe.placeholder && (
              <RecipeInfoContainer justify="inherit">
                {recipe?.nutrients?.calories > 0 && (
                  <InfoItem alignItems="center">
                    <IntensityIcon
                      filled={true}
                      color={colors.secondary600}
                      width="22px"
                      height="22px"
                    />
                    <InfoColumn flexDirection="column">
                      <InfoLabel>Calories: </InfoLabel>
                      <Info data-test="recModal-calories">
                        {formatCalories(recipe?.nutrients?.calories)}
                      </Info>
                    </InfoColumn>
                  </InfoItem>
                )}
                {(prepTime > 0 || cookTime > 0) && (
                  <InfoItem alignItems="center">
                    <PrepIcon
                      src={`${process.env.PUBLIC_URL}/icons/Preparation.svg`}
                    />
                    <InfoColumn flexDirection="column">
                      <InfoLabel>Ready In: </InfoLabel>
                      <Info data-test="recModal-totalTime">
                        {totalTime} minutes
                      </Info>
                    </InfoColumn>
                  </InfoItem>
                )}
                {prepTime > 0 && (
                  <InfoItem alignItems="center">
                    <PrepIcon
                      src={`${process.env.PUBLIC_URL}/icons/PrepTime.svg`}
                    />
                    <InfoColumn flexDirection="column">
                      <InfoLabel>Prep Time: </InfoLabel>
                      <Info data-test="recModal-prepTime">
                        {prepTime} minutes
                      </Info>
                    </InfoColumn>
                  </InfoItem>
                )}
                {cookTime > 0 && (
                  <InfoItem alignItems="center">
                    <PrepIcon
                      src={`${process.env.PUBLIC_URL}/icons/CookTime.svg`}
                    />
                    <InfoColumn flexDirection="column">
                      <InfoLabel>Cook Time: </InfoLabel>
                      <Info data-test="recModal-cookTime">
                        {cookTime} minutes
                      </Info>
                    </InfoColumn>
                  </InfoItem>
                )}
              </RecipeInfoContainer>
            )}
            {context.type !== 'swap' && (
              <MealActionContainer flexDirection="column" justify="center">
                <ActionContainer alignItems="center">
                  <ActionLabel>Meal:</ActionLabel>
                  <Dropdown
                    width="290px"
                    options={['Breakfast', 'Lunch', 'Dinner', 'Snack']}
                    value={{
                      value: values.meal,
                      label: values.meal,
                    }}
                    onChange={value =>
                      handleValueChanged('meal', value.value, 'string')
                    }
                    data-test="recModal-mealSelect"
                  />
                </ActionContainer>
                {context.variant !== 'dashboard' && (
                  <ActionContainer alignItems="center">
                    <ActionLabel>Date:</ActionLabel>
                    <Dropdown
                      width="290px"
                      options={dateOptions}
                      value={{
                        value: values.date.value,
                        label: values.date.label,
                      }}
                      onChange={value =>
                        handleDateChanged('date', value.value, value.label)
                      }
                      data-test="recModal-dateSelect"
                    />
                  </ActionContainer>
                )}
              </MealActionContainer>
            )}
            {recipe.placeholder && (
              <FlexContainer
                flexDirection="column"
                gap="22px"
                style={{ position: 'relative' }}
              >
                <PlaceholderRecHeader>
                  Recommended Nutrition
                </PlaceholderRecHeader>
                <PlaceholderStats recipe={recipe} editable={false} />
              </FlexContainer>
            )}
          </Column>
        </TopContainer>
        {!recipe.placeholder && (
          <BottomContainer flexDirection="column">
            <LeftoverContainer flexDirection="column">
              {leftovers.length > 0 && (
                <LeftoverTitle data-test="recModal-leftoverHeader">
                  Leftovers
                </LeftoverTitle>
              )}
              {leftovers.length > 0 && leftoverItems}
              <AddIcon
                labelText="Add Leftovers"
                orientation="row"
                pink="true"
                onClick={addLeftover}
                data-test="recModal-addLeftovers"
              />
            </LeftoverContainer>
            <RecipeDetails recipe={recipe} recipeYield={recipeYield} />
            <RecipeNutritionContainer
              nutrients={recipe.nutrients}
              totalCalories={recipe?.nutrients?.calories || 0}
            />
          </BottomContainer>
        )}
        {/* {recipe.expertTip && (
          <ExpertTip tipText={recipe.expertTip} flexShrink="0" />
        )} */}
        {recipe.source && (
          <Attribution href={recipe.attrLink} flexShrink="0">
            {recipe.source}
          </Attribution>
        )}
        <AddButtonContainer justify="center" alignItems="center">
          <Button
            buttonSize="large"
            width="196px"
            buttonText={submitText}
            pink="true"
            onClick={handleSubmit}
            data-test="recModal-submit"
          />
        </AddButtonContainer>
      </FlexContainer>
    );
  }
};

const TopContainer = styled(FlexContainer)``;

const BottomContainer = styled(FlexContainer)`
  margin-top: 32px;
  padding-bottom: 64px;
  gap: 40px;
`;

const Column = styled(FlexContainer)`
  flex-basis: 50%;
`;

const RecipeImage = styled.div`
  height: 500px;
  width: 550px;
  border-radius: 4px;
  margin-right: 24px;
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
`;

const AddButtonContainer = styled(FlexContainer)`
  width: 100%;
  height: 72px;
  position: absolute;
  bottom: ${isMobile() ? '64px' : '0%'};
  left: 0%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.15);
`;

const ActionContainer = styled(FlexContainer)`
  gap: 32px;
`;

const ActionLabel = styled.h4`
  color: ${colors.primary600};
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  width: 100px;
`;

const MealActionContainer = styled(FlexContainer)`
  width: ${props => (isMobile() ? 'calc(100% - 64px)' : 'calc(100% - 32px)')};
  background-color: ${colors.primary050};
  gap: 32px;
  padding: 16px;
  margin: ${props => (isMobile() ? '0 16px' : 'initial')};
  border-radius: ${props => (!isMobile() ? '4px' : 'initial')};
`;

const Attribution = styled.a`
  color: ${colors.primary400};
  margin-top: 44px;
  padding-bottom: 50px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: ${colors.primary300};
  }
`;

const LeftoverContainer = styled(FlexContainer)`
  gap: ${props => (isMobile() ? '0px' : '24px')};
  margin: ${props => (isMobile() ? '24px 0' : '0px')};
`;

const LeftoverRow = styled(FlexContainer)`
  gap: 22px;
`;

const LeftoverTitle = styled.h4`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.primary800};
  padding-left: ${props => (isMobile() ? '32px' : 'initial')};
  align-self: ${props => (isMobile() ? 'flex-start' : 'initial')};
`;

const StyledRemoveXIcon = styled(RemoveXIcon)`
  /* to center it in the middle of the dropdown, since the label complicates alignment */
  transform: translateY(11px);
`;

const RecipeName = styled.h1`
  color: ${colors.primary800};
  font-weight: 700;
  font-size: ${props => (props.length > 25 ? '48px' : '64px')};
  line-height: 72px;
`;

const RecipeInfoContainer = styled(FlexContainer)`
  margin-bottom: ${() => (isMobile() ? '24px' : '0')};
  gap: 32px;
`;

const InfoColumn = styled(FlexContainer)`
  gap: 4px;
`;

const Info = styled.p`
  color: ${colors.secondary600};
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;

const InfoLabel = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.primary800};
`;

const PrepIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const InfoItem = styled(FlexContainer)`
  gap: 12px;
`;

const PlaceholderRecHeader = styled.h4`
  color: ${colors.primary800};
  font-size: 24px;
  font-weight: 700;
  margin-top: 18px;
  margin-left: ${() => (isMobile() ? '16px' : 'initial')};
`;

// mobile
const MobileAttribution = styled.a`
  color: ${colors.primary400};
  margin-top: 44px;
  margin-left: 30px;
  text-decoration: none;
`;
const MobileRecipeImage = styled(FlexContainer)`
  height: 242px;
  width: 100%;
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  justify-content: center;
  text-align: center;
  position: relative;
`;
const MobileRecipeContainer = styled(FlexContainer)`
  justify-content: center;
`;
const MobileRecipeName = styled.h1`
  margin: 24px 30px 24px 30px;
  font-weight: 700;
  color: ${colors.primary800};
  line-height: 40px;
  text-align: center;
`;

const MobileMealLabel = styled.h4`
  height: 20px;
  width: 45px;
  color: ${colors.primary600};
  line-height: 20px;
  margin-top: 6px;
`;

const MobileMealActionContainer = styled.div`
  margin: 34px 30px 32px 30px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  max-width: 315px;
  width: 100%;
`;

const MobileNutrientContainer = styled.div`
  margin-top: 32px;
  padding-bottom: 104px;
`;

const MobileRemoveLeftoverContainer = styled(FlexContainer)`
  width: 100%;
  height: 24px;
  margin-top: -16px;
  margin-bottom: 24px;
  background-color: #fffbfb;
  gap: 8px;
`;

const RemoveTitle = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${colors.hlitetwo400};
`;

function mapStateToProps(state) {
  const { selectedMealPlan, currentUser } = state;
  return { mealPlan: selectedMealPlan, userId: currentUser.user._id };
}

export default connect(
  mapStateToProps,
  {
    addMultipleRecipesToMealPlan,
  }
)(RecipeModalView);
