import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const BottleBar = ({ value = 0, ...props }) => {
  const [percentage, setPercentage] = useState(value);

  useEffect(() => {
    setPercentage(value);
  }, [value]);

  return (
    <Container>
      <Mask>
        <Progress value={percentage} data-test="bottle-bar" />
      </Mask>
      <Icon
        width="115"
        height="192"
        viewBox="0 0 115 192"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="bottle">
            <path
              d="M77.6438 190.916H39.6611C32.6691 190.916 27.0002 185.247 27.0002 178.255L27.0002 93.563C27.0002 91.0435 28.0004 88.6284 29.7824 86.8496C33.492 83.1399 33.492 77.1292 29.7824 73.4195C28.0004 71.6375 27.0002 69.2224 27.0002 66.7061V61.8126C27.0002 47.5533 34.1251 34.2372 45.9915 26.3273V19.9968L71.3133 19.9968V26.3273C83.1798 34.234 90.3047 47.5501 90.3047 61.8095L90.3047 66.7061C90.3047 69.2256 89.3045 71.6407 87.5225 73.4195C83.8128 77.1292 83.8128 83.1399 87.5225 86.8496C89.3045 88.6316 90.3047 91.0466 90.3047 93.563L90.3047 178.255C90.3047 185.247 84.6358 190.916 77.6438 190.916Z"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />

            <path
              d="M69.204 1.00049L48.1015 1.00049C45.1895 1.00049 42.825 3.36491 42.825 6.27692V19.9918L74.4805 19.9918V6.27692C74.4805 3.36491 72.116 1.00049 69.204 1.00049Z"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </clipPath>
        </defs>
        <mask
          id="mask0_4417_80406"
          maskUnits="userSpaceOnUse"
          x="28"
          y="19"
          width="64"
          height="172"
        >
          <path
            d="M78.8962 190.908H40.9135C33.9215 190.908 28.2526 185.239 28.2526 178.247L28.2526 93.5554C28.2526 91.0359 29.2528 88.6208 31.0348 86.842C34.7445 83.1323 34.7445 77.1216 31.0348 73.4119C29.2528 71.6299 28.2526 69.2149 28.2526 66.6985V61.8051C28.2526 47.5457 35.3775 34.2296 47.244 26.3197V19.9893L72.5658 19.9893V26.3197C84.4322 34.2264 91.5571 47.5426 91.5571 61.8019L91.5571 66.6985C91.5571 69.218 90.5569 71.6331 88.7749 73.4119C85.0653 77.1216 85.0653 83.1323 88.7749 86.842C90.5569 88.624 91.5571 91.0391 91.5571 93.5554L91.5571 178.247C91.5571 185.239 85.8882 190.908 78.8962 190.908Z"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_4417_80406)">
          <mask id="path-2-inside-1_4417_80406" fill="white">
            <path d="M98 161L1 161V191L98 191V161Z" />
          </mask>
          <path
            d="M98 162.2L1 162.2V159.8L98 159.8V162.2Z"
            fill="black"
            mask="url(#path-2-inside-1_4417_80406)"
          />
          <mask id="path-4-inside-2_4417_80406" fill="white">
            <path d="M98 45L1 45V74L98 74V45Z" />
          </mask>
          <path
            d="M98 46.2L1 46.2V43.8L98 43.8V46.2Z"
            fill="black"
            mask="url(#path-4-inside-2_4417_80406)"
          />
          <mask id="path-6-inside-3_4417_80406" fill="white">
            <path d="M98 132L1 132V161L98 161V132Z" />
          </mask>
          <path
            d="M98 133.2L1 133.2V130.8L98 130.8V133.2Z"
            fill="black"
            mask="url(#path-6-inside-3_4417_80406)"
          />
          <mask id="path-8-inside-4_4417_80406" fill="white">
            <path d="M98 16L1 16V45L98 45V16Z" />
          </mask>
          <path
            d="M98 17.2L1 17.2V14.8L98 14.8V17.2Z"
            fill="black"
            mask="url(#path-8-inside-4_4417_80406)"
          />
          <mask id="path-10-inside-5_4417_80406" fill="white">
            <path d="M98 103L1 103V132L98 132V103Z" />
          </mask>
          <path
            d="M98 104.2L1 104.2V101.8L98 101.8V104.2Z"
            fill="black"
            mask="url(#path-10-inside-5_4417_80406)"
          />
          <mask id="path-12-inside-6_4417_80406" fill="white">
            <path d="M98 74L1 74V103L98 103V74Z" />
          </mask>
          <path
            d="M98 75.2L1 75.2V72.8L98 72.8V75.2Z"
            fill="black"
            mask="url(#path-12-inside-6_4417_80406)"
          />
        </g>
        <path
          d="M77.6438 190.916H39.6611C32.6691 190.916 27.0002 185.247 27.0002 178.255L27.0002 93.563C27.0002 91.0435 28.0004 88.6284 29.7824 86.8496C33.492 83.1399 33.492 77.1292 29.7824 73.4195C28.0004 71.6375 27.0002 69.2224 27.0002 66.7061V61.8126C27.0002 47.5533 34.1251 34.2372 45.9915 26.3273V19.9968L71.3133 19.9968V26.3273C83.1798 34.234 90.3047 47.5501 90.3047 61.8095L90.3047 66.7061C90.3047 69.2256 89.3045 71.6407 87.5225 73.4195C83.8128 77.1292 83.8128 83.1399 87.5225 86.8496C89.3045 88.6316 90.3047 91.0466 90.3047 93.563L90.3047 178.255C90.3047 185.247 84.6358 190.916 77.6438 190.916Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M69.204 1.00049L48.1015 1.00049C45.1895 1.00049 42.825 3.36491 42.825 6.27692V19.9918L74.4805 19.9918V6.27692C74.4805 3.36491 72.116 1.00049 69.204 1.00049Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="102"
          y1="161.25"
          x2="115"
          y2="161.25"
          stroke="black"
          strokeWidth="1.5"
        />
        <line
          x1="102"
          y1="131.25"
          x2="115"
          y2="131.25"
          stroke="black"
          strokeWidth="1.5"
        />
        <line
          x1="102"
          y1="103.25"
          x2="115"
          y2="103.25"
          stroke="black"
          strokeWidth="1.5"
        />
        <line
          x1="102"
          y1="75.25"
          x2="115"
          y2="75.25"
          stroke="black"
          strokeWidth="1.5"
        />
        <line
          x1="102"
          y1="47.25"
          x2="115"
          y2="47.25"
          stroke="black"
          strokeWidth="1.5"
        />
        <line
          y1="161.25"
          x2="13"
          y2="161.25"
          stroke="black"
          strokeWidth="1.5"
        />
        <line
          y1="131.25"
          x2="13"
          y2="131.25"
          stroke="black"
          strokeWidth="1.5"
        />
        <line
          y1="103.25"
          x2="13"
          y2="103.25"
          stroke="black"
          strokeWidth="1.5"
        />
        <line y1="75.25" x2="13" y2="75.25" stroke="black" strokeWidth="1.5" />
        <line y1="47.25" x2="13" y2="47.25" stroke="black" strokeWidth="1.5" />
      </Icon>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 192px;
  width: 115px;
`;

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  height: 100%;
  width: 100%;
  -webkit-clip-path: url(#bottle);
  clip-path: url(#bottle);
`;

const Progress = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background: #55c0f3;
  height: ${props => (props.value ? `${props.value}%` : '0%')};
  width: 100%;
  transition: height 0.5s ease-in-out;
`;

const Icon = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
`;

export default BottleBar;
