import { formatDate } from '../helpers/date';

export const calculateWorkoutStats = (fitnessPlan, dateValue) => {
  const workouts = fitnessPlan.workouts.filter(
    w => formatDate(dateValue) === formatDate(w.date)
  );

  const activities = fitnessPlan.activities.filter(
    a => formatDate(dateValue) === formatDate(a.date)
  );

  const totalCaloriesBurned = Math.round(
    activities
      .map(a => (a.logged ? a.activity.caloriesBurned : 0) || 0)
      .reduce((acc, cur) => acc + cur, 0) +
      workouts
        .map(w => (w.allComplete ? w.workout.caloriesBurned : 0) || 0)
        .reduce((acc, cur) => acc + cur, 0)
  );

  const totalCaloriesWorkout = Math.round(
    activities
      .map(a => a.activity.caloriesBurned || 0)
      .reduce((acc, cur) => acc + cur, 0) +
      workouts
        .map(w => w.workout.caloriesBurned || 0)
        .reduce((acc, cur) => acc + cur, 0)
  );

  return { workouts, activities, totalCaloriesBurned, totalCaloriesWorkout };
};
