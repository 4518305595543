import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';

const CollapseIconMedium = ({ toggleSelect, open, ...props }) => (
  <Circle
    justify="center"
    alignItems="center"
    onClick={toggleSelect}
    {...props}
  >
    <Icon
      viewBox="0 0 18 10"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      name="collapse"
      onClick={toggleSelect}
    >
      <g
        id="Style-Guide"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          id="Style-Guide---Elements"
          transform="translate(-198.000000, -3941.000000)"
          stroke="#686868"
          strokeWidth="2"
        >
          <RotateGroup open={open} id="Collapse-Arrow-Small">
            <Path
              d="M0.727272727,7.33333333 L8.03627318,0.633416253"
              id="Line-2"
            ></Path>
            <Path
              d="M8,7.33333333 L15.3090005,0.633416253"
              id="Line-2-Copy"
              transform="translate(11.636364, 4.000000) scale(-1, 1) translate(-11.636364, -4.000000) "
            ></Path>
          </RotateGroup>
        </g>
      </g>
    </Icon>
  </Circle>
);

const RotateGroup = styled.g`
  transition: transform 0.3s;
  transform: ${props =>
    props.open
      ? `translate(207px,3946px) translate(-207px,-3946px) translate(199px,3942px)`
      : 'translate(207px,3946px) rotate(-180deg) translate(-207px,-3946px) translate(199px,3942px)'};
`;

const Circle = styled(FlexContainer)`
  width: ${props => props.width || '40px'};
  height: ${props => props.height || '40px'};
  border-radius: ${props => props.height / 2 || '20px'};

  &:hover {
    background-color: ${colors.primary100};
    cursor: pointer;
  }

  &:active {
    background-color: ${colors.primary200};
  }
`;

const Path = styled.path`
  fill: none;
  stroke: ${colors.primary700};
`;

const Icon = styled.svg`
  width: 18px;
  height: 10px;

  &:hover {
    cursor: pointer;
  }

  &:hover ${Path} {
    stroke: ${colors.primary500};
  }

  &:disabled ${Path} {
    stroke: ${colors.primary300};
  }
`;
export default CollapseIconMedium;
