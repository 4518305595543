import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import { ArrowBack, Close } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import FlexContainer from '../elements/FlexContainer';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IntensitySet from '../elements/IntensitySet';
import { getImageURL } from '../services/api/api';
import ClockIcon from '../icons/ClockIcon';
import Button from '../elements/Button';
import CheckmarkIcon from '../icons/CheckmarkIcon';

const Modal = ({ open, handleClose, item, toggleDone, remove }) => {
  const activityImageURL = (ratio = 1) => {
    const width = 246 * ratio;
    const height = 151 * ratio;
    return getImageURL(
      item.activity.imageUrl,
      `resizing_type:fill/height:${height}/width:${width}`
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      data-test="planner-activityModal"
    >
      <DialogTitle disableTypography style={{ paddingBottom: 0 }}>
        {/* Action buttons */}
        <FlexContainer>
          <Tooltip title="Back">
            <IconButton
              aria-label="back"
              onClick={handleClose}
              data-test="planner-activityModal-back"
            >
              <ArrowBack />
            </IconButton>
          </Tooltip>

          {/* Align right */}
          <FlexContainer justify="flex-end" flexGrow="1">
            <Tooltip title="Close">
              <IconButton
                aria-label="close"
                onClick={handleClose}
                style={{ marginRight: -12 }}
                data-test="planner-activityModal-close"
              >
                <Close />
              </IconButton>
            </Tooltip>
          </FlexContainer>
        </FlexContainer>
      </DialogTitle>

      <DialogContent>
        <Content>
          <FlexContainer flexDirection="column" margin="0 24px 0 0">
            <Image
              image={activityImageURL()}
              image2x={activityImageURL(2)}
              data-test="planner-activityModal-image"
            />
          </FlexContainer>

          <FlexContainer flexDirection="column" margin="0 15px 0 0">
            <ActivityTitle>Activity</ActivityTitle>
            <Name data-test="planner-activityModal-name">
              {item.activity.name}
            </Name>
            <FlexContainer alignItems="center" gap="8px">
              <ClockIcon />
              <Duration data-test="planner-activityModal-duration">
                {item.activity.duration} minutes
              </Duration>
            </FlexContainer>
          </FlexContainer>
        </Content>
        <Table>
          <THead>
            <tr>
              <TH
                style={{ backgroundColor: colors.secondary400, width: '50%' }}
              >
                <CaloriesText data-test="planner-activityModal-calsBurned">
                  {item.activity.caloriesBurned} calories
                </CaloriesText>
              </TH>
              <TH style={{ backgroundColor: colors.hliteone100 }}>
                <IntensityContainer alignItems="center" gap="14px">
                  <IntensityText>Intensity </IntensityText>
                  <IntensitySet
                    intensity={item.activity.intensity}
                    data-test="planner-activityModal-intensity"
                  />
                </IntensityContainer>
              </TH>
            </tr>
          </THead>
        </Table>
        <BottomContainer justify="space-between" alignItems="center">
          <Delete onClick={remove} data-test="planner-activityModal-delete">
            Delete
          </Delete>
          <LogButton
            type="submit"
            buttonText={item.logged ? 'Logged!' : 'Log as Completed'}
            onClick={toggleDone}
            logged={item.logged}
            startIcon={!item.logged && <CheckmarkIcon invert="true" />}
            data-test="planner-activityModal-log"
          />
        </BottomContainer>
      </DialogContent>
    </Dialog>
  );
};

const Content = styled(FlexContainer)`
  padding: 0 16px 32px 16px;
`;

const Table = styled.table`
  width: calc(100% - 32px);
  border: 1px solid ${colors.primary200};
  border-radius: 4px;
  border-spacing: 0;
  margin: 0 16px;
`;

const TH = styled.th`
  text-align: left;
  padding-left: 20px;
`;

const THead = styled.thead`
  height: 48px;
`;

const Name = styled.h2`
  color: ${colors.primary800};
  margin: 16px 0 20px 0;
  text-transform: capitalize;
`;

const Duration = styled.p`
  color: ${colors.primary600};
  font-size: 13px;
`;

const Image = styled.div`
  width: 246px; // Update image dimensions accordingly.
  height: 151px; // Update image dimensions accordingly.
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 6px;
`;

const ActivityTitle = styled.h4`
  color: ${colors.secondary600};
  font-size: 12px;
  font-weight: 700;
`;

const IntensityContainer = styled(FlexContainer)`
  margin-top: 2px;
`;

const IntensityText = styled.p`
  color: ${colors.secondary600};
  font-size: 15px;
  font-weight: 500;
`;

const CaloriesText = styled.p`
  color: ${colors.primary900};
  font-size: 15px;
  font-weight: 700;
`;

const BottomContainer = styled(FlexContainer)`
  padding: 21px 16px;
`;

const Delete = styled.p`
  color: ${colors.hlitetwo400};
  font-size: 14px;
  font-weight: 700;
  line-height: normal;

  &:hover {
    cursor: pointer;
    color: ${colors.hlitetwo300};
  }
`;

const LogButton = styled(Button)`
  width: 210px;
  height: 40px;
  color: ${props => (props.logged ? colors.secondary300 : '#fff')};
  background: ${props => (props.logged ? '#fff' : colors.secondary400)};
  border-radius: 2px;
  border: ${props =>
    props.logged ? `1px solid ${colors.secondary300}` : 'none'};
  text-transform: none;
  font-weight: 400;

  &:hover {
    color: #fff;
    background: ${props =>
      props.logged ? colors.secondary300 : colors.secondary400};
    border: ${props =>
      props.logged ? `1px solid ${colors.secondary300}` : 'none'};
  }
`;

export default Modal;
