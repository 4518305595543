import React from 'react';

const DefaultProfilePicture = ({ onClick, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="132"
      height="132"
      fill="none"
      viewBox="0 0 132 132"
      onClick={onClick}
      {...props}
    >
      <circle
        cx="66"
        cy="66"
        r="65"
        fill="#F8F8F8"
        stroke="#E8E8E8"
        strokeWidth="2"
      ></circle>
      <circle cx="66" cy="66" r="27" fill="#F8F8F8"></circle>
      <path
        fill="#686868"
        d="M67.377 60.573c0-4.455-3.645-8.1-8.1-8.1s-8.1 3.645-8.1 8.1 3.645 8.1 8.1 8.1 8.1-3.645 8.1-8.1zm-8.1 2.7a2.708 2.708 0 01-2.7-2.7c0-1.485 1.215-2.7 2.7-2.7s2.7 1.215 2.7 2.7-1.215 2.7-2.7 2.7zm17.55 5.4c2.997 0 5.4-2.403 5.4-5.4 0-2.997-2.403-5.4-5.4-5.4-2.997 0-5.427 2.403-5.4 5.4 0 2.997 2.403 5.4 5.4 5.4zM66 39c-14.904 0-27 12.096-27 27s12.096 27 27 27 27-12.096 27-27-12.096-27-27-27zM49.395 79.797c1.836-1.458 6.129-2.997 9.882-2.997.174 0 .37.023.569.026.031.001.06-.018.07-.048.226-.592.511-1.187.858-1.775.861-1.46.198-3.63-1.497-3.63-3.51 0-9.153 1.215-12.771 3.861a21.287 21.287 0 01-2.106-9.261c0-11.907 9.693-21.6 21.6-21.6 11.907 0 21.6 9.693 21.6 21.6 0 3.24-.729 6.318-2.025 9.099-2.7-1.593-6.372-2.349-8.748-2.349-4.104 0-12.15 2.187-12.15 7.29v7.506a21.424 21.424 0 01-15.282-7.722z"
      ></path>
    </svg>
  );
};

export default DefaultProfilePicture;
