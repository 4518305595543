import React from 'react';
import styled from 'styled-components';

const ArrowBlue = props => (
  <Icon
    width="34px"
    height="34px"
    viewBox="0 0 34 34"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="ASSETS"
        transform="translate(-1207.000000, -635.000000)"
        stroke="#55C0F3"
        strokeWidth="2"
      >
        <g
          id="Arrow-Blue"
          transform="translate(1224.000000, 652.000000) rotate(-270.000000) translate(-1224.000000, -652.000000) translate(1208.000000, 636.000000)"
        >
          <circle id="Hover" cx="16" cy="16" r="16"></circle>
          <g
            id="Group-6"
            transform="translate(8.000000, 8.000000)"
            strokeLinecap="round"
          >
            <path
              d="M0.727272727,7.33333333 L8.03627318,0.633416253"
              id="Line-2"
            ></path>
            <path
              d="M8,7.33333333 L15.3090005,0.633416253"
              id="Line-2-Copy"
              transform="translate(11.636364, 4.000000) scale(-1, 1) translate(-11.636364, -4.000000) "
            ></path>
            <path d="M7.5,0.5 L7.5,16.5" id="Line"></path>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);

const Icon = styled.svg`
  width: ${props => props.width || 'inherit'};
  height: ${props => props.width || 'inherit'};
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

export default ArrowBlue;
