import React from 'react';
import styled from 'styled-components';
import useReactRouter from 'use-react-router';
import { Switch } from 'react-router';

import PrivateRoute from './PrivateRoute';
import AdminRoute from './AdminRoute';
import SideNav from '../universal/SideNav';
import TopNav from '../universal/TopNav';
import BottomNav from '../universal/BottomNav';
import UserProfileMain from '../profile/UserProfileMain';
import UserProfilePrefs from '../profile/UserProfilePrefs';
import UserProfileNotifs from '../profile/UserProfileNotifs';
import UserProfileBilling from '../profile/UserProfileBilling';

import UserDashboard from '../dashboard/UserDashboard';
import ExploreTab from '../explore/ExploreTab';
import UserMealPlan from '../mealPlan/UserMealPlan';
import UserFitnessPlan from '../fitnessPlan/UserFitnessPlan';
import UserPlanner from '../planner/UserPlanner';
import ShoppingList from '../shoppingList/ShoppingList';

import RecipePage from '../mealPlan/recipe/RecipePage';
import FoodPage from '../mealPlan/food/FoodPage';
import ExercisePage from '../fitnessPlan/exercise/ExercisePage';

import AdminRecipe from '../admin/recipe/AdminRecipe';
import AdminFood from '../admin/food/AdminFood';
import AdminMealPlan from '../admin/mealPlan/AdminMealPlan';
import AdminExercise from '../admin/exercise/AdminExercise';
import AdminActivity from '../admin/activity/AdminActivity';
import AdminWorkout from '../admin/workout/AdminWorkout';
import AdminFitnessPlan from '../admin/fitnessPlan/AdminFitnessPlan';
import AdminUser from '../admin/user/AdminUser';
import AdminTrack from '../admin/track/AdminTrack';
import AdminCampaigns from '../admin/campaigns/AdminCampaigns';
import { isMobile } from '../helpers/utils';
import { useSelector } from 'react-redux';
import Messages from '../universal/Messages';
import CirclePage from '../explore/CirclePage';

const AppContainer = () => {
  const { location } = useReactRouter();
  const profile = location.pathname.includes('/app/profile');
  const path = location.pathname;

  const getPageTitle = path => {
    // Default
    let title = 'Page Title';
    const home = isMobile() ? 'Explore' : 'Dashboard';
    const titleMap = new Map([
      ['/app/profile', 'Settings'],
      ['/app/profile/preferences', 'Settings'],
      ['/app/profile/notifications', 'Settings'],
      ['/app/profile/billing', 'Settings'],
      ['/app/mealplan', 'Meal Plan'],
      ['/app/fitnessplan', 'Fitness Plan'],
      ['/app/planner', 'Planner'],
      ['/app/shoppinglist', 'Shopping List'],
      [new RegExp(/^\/app\/mealplan\/.+\/recipe\/.+$/), 'Recipe'],
      [new RegExp(/^\/app\/mealplan\/.+\/food\/.+$/), 'Food'],
      [new RegExp(/^\/app\/fitnessplan\/.+\/exercise\/.+$/), 'Exercise'],
      [new RegExp(/^\/app\/admin\/.+$/), 'Admin Panel'],
      ['/app', home],
      ['/app/dashboard', 'Dashboard'],
      ['/app/explore', 'Explore'],
    ]);

    titleMap.forEach((value, key) => {
      if (key === path) {
        title = value;
      } else if (key instanceof RegExp && path.match(key)) {
        title = value;
      }
    });

    return title;
  };
  const { errors } = useSelector(state => ({ errors: state.errors }));
  return (
    <>
      <NavContainer>
        {isMobile() ? (
          <BottomNav title={getPageTitle(path)} path={path} />
        ) : (
          <SideNav profile={profile} />
        )}
      </NavContainer>

      <ContentContainer>
        {!isMobile() && <TopNav title={getPageTitle(path)} />}

        <ScrollContainer data-test="scroll-container">
          <Switch>
            <PrivateRoute
              exact
              path="/app"
              component={isMobile() ? ExploreTab : UserDashboard}
            />
            {/* Add below route to intake pathway */}
            <PrivateRoute
              exact
              path="/app/dashboard"
              component={UserDashboard}
            />
            <PrivateRoute exact path="/app/explore" component={ExploreTab} />
            <PrivateRoute
              exact
              path="/app/profile"
              component={UserProfileMain}
            />
            <PrivateRoute
              path="/app/profile/preferences"
              component={UserProfilePrefs}
            />
            <PrivateRoute
              path="/app/profile/notifications"
              component={UserProfileNotifs}
            />
            <PrivateRoute
              path="/app/profile/billing"
              component={UserProfileBilling}
            />
            <PrivateRoute path="/app/planner" component={UserPlanner} />
            <PrivateRoute
              path="/app/mealplan/:mealPlanId/recipe/:recipeId"
              component={RecipePage}
            />
            <PrivateRoute
              path="/app/mealplan/:mealPlanId/food/:foodId"
              component={FoodPage}
            />
            <PrivateRoute path="/app/mealplan" component={UserMealPlan} />
            <PrivateRoute
              path="/app/fitnessplan/:workoutId/exercise/:exerciseId"
              component={ExercisePage}
            />
            <PrivateRoute path="/app/fitnessplan" component={UserFitnessPlan} />
            <PrivateRoute path="/app/shoppinglist" component={ShoppingList} />
            <PrivateRoute
              path="/app/nutriology-transformation"
              component={CirclePage}
            />

            {/* add parameters */}
            <AdminRoute path="/app/admin/user" component={AdminUser} />
            <AdminRoute path="/app/admin/recipe" component={AdminRecipe} />

            <AdminRoute path="/app/admin/food" component={AdminFood} />
            <AdminRoute path="/app/admin/mealPlan" component={AdminMealPlan} />
            <AdminRoute path="/app/admin/exercise" component={AdminExercise} />
            <AdminRoute path="/app/admin/activity" component={AdminActivity} />
            <AdminRoute path="/app/admin/workout" component={AdminWorkout} />
            <AdminRoute
              path="/app/admin/fitnessPlan"
              component={AdminFitnessPlan}
            />
            <AdminRoute path="/app/admin/track" component={AdminTrack} />
            <AdminRoute
              path="/app/admin/campaigns"
              component={AdminCampaigns}
            />
          </Switch>
          {errors.length > 0 && (
            <Messages messages={errors} type="error" testName="error-message" />
          )}
        </ScrollContainer>
      </ContentContainer>
    </>
  );
};

const NavContainer = styled.div`
  height: 100%; /*allows both columns to span the full height of the browser window*/
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const ContentContainer = styled.div`
  height: 100%; /*allows both columns to span the full height of the browser window*/
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const ScrollContainer = styled.div`
  height: 100%;
`;

export default AppContainer;
