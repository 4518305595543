import jwt from 'jsonwebtoken';
import { apiCall } from '../../services/api/api';
import {
  SET_CURRENT_EMAIL,
  SET_CURRENT_USER,
  SET_SELECTED_DATE,
  ADD_ERROR,
  REMOVE_ERROR,
  SET_SELECTED_MEAL_TYPE,
  SET_SHOULD_ANIMATE_SET_RECORD,
  SET_CIRCLE_SLUG,
} from '../actionTypes';
import { DEFAULT_STATE_CURRENT_USER } from '../reducers/general';

export function handleError(dispatch, err, type) {
  console.error('Invoking the error handler with the following error:', err);
  if (err.error && err.error.message) {
    switch (err.error.message) {
      case 'Not Authorized':
        dispatch(logout());
        break;
      default:
        dispatch(addError({ message: err.error.message, type: type }));
    }
    return;
  }
  if (err.name === 'NetworkError') {
    console.error('There was a network error.');
    return dispatch(addError({ message: 'There was a network error.', type }));
  }
  if (err && err.message) {
    return dispatch(addError({ message: err.message, type }));
  }
  if (typeof err === 'string') {
    return dispatch(addError({ message: err, type }));
  }
  return dispatch(addError({ message: 'Error encountered', type }));
}

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user,
  };
}

export function setDefaultUser() {
  return dispatch => {
    dispatch(setCurrentUser(DEFAULT_STATE_CURRENT_USER.user));
  };
}

export function setCurrentEmail(email) {
  return {
    type: SET_CURRENT_EMAIL,
    email,
  };
}

export function logout() {
  return dispatch => {
    return apiCall('delete', `/users/logout`)
      .then(res => {
        localStorage.clear();
        dispatch(setCurrentUser(DEFAULT_STATE_CURRENT_USER.user));
      })
      .catch(err => {
        handleError(dispatch, err, 'user');
        throw err;
      });
  };
}

export function authUser(type, userData) {
  return dispatch => {
    dispatch(removeError('user'));
    return apiCall('post', `/users/${type}`, userData)
      .then(({ jwt, refresh_token, ...user }) => {
        localStorage.setItem('jwtToken', jwt);
        localStorage.setItem('jwtTokenRefresh', refresh_token);
        return dispatch(getUser(user.id));
      })
      .catch(err => {
        handleError(dispatch, err, 'user');
        throw err;
        // if (err.message === 'Network Error') {
        //   // Generic error - typically the API is down.
        //   return { error: { message: err.message } };
        // } else {
        //   // Properly formatted error from the API.
        //   return err;
        // }
      });
  };
}

export function updatePassword(resetToken, password) {
  const userData = {
    token: resetToken,
    password: password,
  };
  return dispatch => {
    return apiCall('put', `/users/reset`, userData)
      .then(data => {
        dispatch(removeError('user'));
        dispatch(addError({ message: data.message, type: 'user' }));
        return data;
      })
      .catch(err => {
        handleError(dispatch, err, 'user');
        throw err;
      });
  };
}

export function getUser(userId) {
  return dispatch => {
    return apiCall('get', `/users/${userId}`)
      .then(user => {
        dispatch(setCurrentUser(user));
        dispatch(removeError('user'));
      })
      .catch(err => {
        dispatch(setDefaultUser());
        handleError(dispatch, err, 'user');
      });
  };
}

export function updateUser(userData) {
  return dispatch => {
    const userId = userData.id;
    if (userData.avatar) {
      const formData = new FormData();
      for (let key in userData) {
        formData.append(key, userData[key]);
      }
      userData = formData;
    }
    return apiCall('put', `/users/${userId}`, userData)
      .then(user => {
        dispatch(setCurrentUser(user));
        dispatch(removeError('user'));
        return user;
      })
      .catch(err => {
        handleError(dispatch, err, 'user');
        throw err;
      });
  };
}

export function forgotPassword(email) {
  return dispatch => {
    const userData = { email: email };
    return apiCall('post', `/users/reset`, userData)
      .then(data => {
        dispatch(removeError('user'));
        return data;
      })
      .catch(err => {
        handleError(dispatch, err, 'user');
        throw err;
      });
  };
}

export function verifyEmail(token) {
  const decoded = jwt.decode(token);
  const { email } = decoded;
  return dispatch => {
    dispatch(setCurrentEmail(email));
    return apiCall('post', `/users/verify_email/${token}`)
      .then(({ id }) => {
        dispatch(getUser(id));
      })
      .catch(err => {
        handleError(dispatch, err, 'user');
      });
  };
}

export function resendVerificationEmail(email) {
  return dispatch => {
    dispatch(removeError('user'));
    const userData = { email: email };
    return apiCall('post', `/users/resend_verification_email`, userData)
      .then(data => {
        return data;
      })
      .catch(err => {
        handleError(dispatch, err, 'user');
        throw err;
      });
  };
}

export function setSelectedDate(date = new Date()) {
  return {
    type: SET_SELECTED_DATE,
    date,
  };
}

export function setSelectedMealType(mealType = 'Breakfast') {
  return {
    type: SET_SELECTED_MEAL_TYPE,
    mealType,
  };
}

export function setCircleSlug(slug) {
  return {
    type: SET_CIRCLE_SLUG,
    slug,
  };
}

export function getCircleSlug() {
  return dispatch => {
    dispatch(removeError('user'));
    return apiCall('get', `/circle_slug`, { name: 'circle' })
      .then(slug => {
        dispatch(setCircleSlug(slug));
      })
      .catch(err => {
        handleError(dispatch, err, 'user');
      });
  };
}

export function editCircleSlug(slug) {
  return dispatch => {
    dispatch(removeError('user'));
    return apiCall('put', `/circle_slug`, { name: 'circle', slug })
      .then(slug => {
        dispatch(setCircleSlug(slug));
      })
      .catch(err => {
        handleError(dispatch, err, 'user');
      });
  };
}

// Error Actions
export function clearErrors(errors) {
  return dispatch => {
    if (errors) {
      dispatch(removeError('user'));
    }
  };
}

export const addError = error => ({
  type: ADD_ERROR,
  error,
});

export const removeError = errorType => ({
  type: REMOVE_ERROR,
  errorType,
});

export function setShouldAnimateSetRecord(shouldAnimate) {
  return {
    type: SET_SHOULD_ANIMATE_SET_RECORD,
    shouldAnimate,
  };
}
