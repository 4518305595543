import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';

import useReactRouter from 'use-react-router';

import { getUserWorkout } from '../../services/api/workout';
import { connect } from 'react-redux';
import { pick } from 'lodash';
import { updateUser } from '../../store/actions/general';

import BackIcon from '../../icons/BackIcon';
// import ExpertTipIcon from '../../icons/ExpertTipIcon'; TODO: Post-Beta Feature

import ExerciseDetails from './ExerciseDetails';

const ExercisePage = ({ ...props }) => {
  const { history } = useReactRouter();
  const [workout, setWorkout] = useState(null);
  const { workoutId, exerciseId } = useParams();

  // get workout on load page
  useEffect(() => {
    getUserWorkout(workoutId)
      .then(setWorkout)
      .catch(error => {
        console.log(error);
      });
  }, [workoutId]);

  if (!workout) {
    return null;
  }

  // get recipe from url recipe ID
  const exercise = workout.exercises.find(exercise => {
    return exercise._id === exerciseId;
  });
  if (!exercise) {
    return null;
  }

  return (
    <>
      <Container justify="space-between">
        <LeftColumn flexDirection="column">
          <BackIcon handleClick={() => history.goBack()} />
          <ExerciseInfoContainer justify="space-between">
            <ExerciseInfoContainerLeft flexDirection="column">
              <ExerciseName>{exercise.exercise.name}</ExerciseName>
            </ExerciseInfoContainerLeft>
            <ExerciseInfoContainerRight flexDirection="column"></ExerciseInfoContainerRight>
          </ExerciseInfoContainer>
          <ExerciseDetailsContainer>
            <ExerciseDetails
              exercise={exercise.exercise}
              sets={exercise.sets}
            />
          </ExerciseDetailsContainer>
          {/* TODO: Post-Beta Feature
          https://app.clickup.com/t/2mtgub6
          <TipContainer flexDirection="column">
            <TipHeader alignItems="center">
              <ExpertTipIcon />
              <TipHeaderText>Expert Trainer Tip!</TipHeaderText>
            </TipHeader>
            <TipText>{exercise.exercise.expertTip}</TipText>
          </TipContainer> */}
        </LeftColumn>
        <RightColumn flexDirection="column">
          <ImageContainer alignItems="center">
            <Image src={exercise.exercise.imageUrl} />
          </ImageContainer>
          <ImageContainer alignItems="center"></ImageContainer>
        </RightColumn>
      </Container>
    </>
  );
};

const Container = styled(FlexContainer)`
  padding: 32px 55px;
`;

const ImageContainer = styled(FlexContainer)`
  position: relative;
  width: auto;
  height: content;
  margin-bottom: 32px;
`;

const Image = styled.img`
  width: 500px;
`;

const LeftColumn = styled(FlexContainer)`
  flex-basis: 40%;
  max-width: 45%;
  margin-right: 10px;
`;

const RightColumn = styled(FlexContainer)`
  flex-basis: 45%;
  margin-left: 10px;
`;

const ExerciseInfoContainer = styled(FlexContainer)`
  margin-top: 48px;
  padding-bottom: 32px;
  border-bottom: 1px solid ${colors.primary200};
`;

const ExerciseInfoContainerLeft = styled(FlexContainer)``;
const ExerciseInfoContainerRight = styled(FlexContainer)``;

const ExerciseDetailsContainer = styled(FlexContainer)`
  margin-top: 48px;
`;

const ExerciseName = styled.h1`
  color: ${colors.primary800};
  margin: 0 0 32px 0;
`;

// const ExerciseTimeContainer = styled(FlexContainer)``;
//
// const ExerciseTime = styled.p`
//   color: ${colors.secondary500};
//   margin-left: 8px;
// `;
//
//TODO: Post-Beta Feature
// const TipContainer = styled(FlexContainer)`
//   margin-top: 44px;
//   margin-bottom: 44px;
// `;

// const TipHeader = styled(FlexContainer)`
//   margin-bottom: 16px;

//   > * {
//     margin-right: 12px;
//   }
// `;

// const TipHeaderText = styled.p`
//   color: ${colors.secondary600};
//   font-weight: bold;
//   text-transform: uppercase;
// `;

// const TipText = styled.p`
//   color: ${colors.primary700};
// `;

function mapStateToProps(state) {
  const { currentUser } = state;
  const fields = ['id', 'favoriteWorkouts'];
  const user = pick(currentUser.user, fields);
  return { currentUser: user };
}

export default connect(
  mapStateToProps,
  { updateUser }
)(ExercisePage);
