import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import Avatar from '../elements/Avatar';
import { colors } from '../styleConstants';
import { useHistory } from 'react-router-dom';

const CircleModule = () => {
  const history = useHistory();
  return (
    <Container
      flexDirection="column"
      onClick={() => history.push('/app/nutriology-transformation')}
    >
      <BannerContent alignItems="center">
        <Avatar src={`${process.env.PUBLIC_URL}/logo64.png`} />
        <Text>Access your Nutriology Transformation Program Here!</Text>
      </BannerContent>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  box-sizing: border-box;
  border: 1px solid ${colors.primary100};
  border-radius: 12px;
  background-color: ${colors.secondary600};

  &:active {
    background-color: ${colors.secondary500};
  }
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #fff;
`;

const BannerContent = styled(FlexContainer)`
  padding: 16px;
  max-width: 100%;
  border-radius: 12px;
`;

export default CircleModule;
