import React from 'react';

function CameraIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        fill="#484848"
        d="M20.5 20h-16V8h4.05l1.83-2h5.12V4h-6L7.67 6H4.5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V9h-2v11zm-4-18v1.33c2.58 0 4.66 2.09 4.67 4.66h1.33a6.003 6.003 0 00-6-5.99zm0 2.67V6c1.11 0 1.99.89 2 1.99h1.33a3.327 3.327 0 00-3.33-3.32z"
      ></path>
      <path
        fill="#484848"
        d="M15.48 10.01c-.13-.09-.26-.18-.39-.26.14.08.27.17.39.26zM17.5 9a2.015 2.015 0 00-1.01-1.73C16.2 7.1 15.86 7 15.5 7V6h-5.12L8.55 8H4.5v12h16V9h-3zm-5 10c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"
        opacity="0.3"
      ></path>
      <path
        fill="#484848"
        d="M12.5 9c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0 8c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"
      ></path>
    </svg>
  );
}

export default CameraIcon;
