import React, { useState } from 'react';
import styled from 'styled-components';
import { Snackbar, Grow } from '@material-ui/core';
import { colors } from '../styleConstants';
import FlexContainer from './FlexContainer';
import {
  Close,
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
  QuestionAnswerOutlined,
  WarningOutlined,
} from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { isMobile } from '../helpers/utils';

const AlertMessage = ({
  message,
  open,
  handleClose,
  type,
  variant,
  ...props
}) => {
  const [alertOpen, setAlertOpen] = useState(open);

  // Ensure we account for scenarios where open state is managed
  // from outer component (e.g. admin panel) and when it is managed
  // internally (e.g. whenever errors come from Redux)
  const closeAlert = e => {
    if (handleClose) {
      handleClose();
    } else {
      setAlertOpen(false);
    }
  };

  return (
    <Grow in={alertOpen} timeout={500}>
      <AlertContainer
        justify="space-between"
        alignItems="center"
        type={type}
        variant={variant}
        data-test={props['data-test']}
      >
        <LeftContainer alignItems="center">
          {type === 'success' ? (
            <CheckCircleOutlineOutlined />
          ) : type === 'error' ? (
            <ErrorOutlineOutlined />
          ) : type === 'warning' ? (
            <WarningOutlined />
          ) : (
            <QuestionAnswerOutlined />
          )}
          <TextContainer flexDirection="column">
            <Header>
              {type === 'success'
                ? 'Success!'
                : type === 'error'
                ? 'Something went wrong!'
                : type === 'warning'
                ? 'Warning!'
                : 'Did you know?'}
            </Header>
            <Text>{message}</Text>
          </TextContainer>
        </LeftContainer>
        <IconButton
          onClick={closeAlert}
          disableRipple={true}
          disableFocusRipple={true}
          data-test="alert-close"
        >
          <StyledClose type={type} variant={variant} />
        </IconButton>
      </AlertContainer>
    </Grow>
  );
};

const AlertStack = ({
  messages: msgInput,
  open,
  handleClose,
  autoHideDuration,
  ...props
}) => {
  let messages = [];

  // Handles situations like in admin panel when error/message
  // management happens in component state vs Redux
  if (typeof msgInput === 'string') {
    messages = [{ message: msgInput }];
  } else {
    messages = msgInput;
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration || 10000}
      onClose={handleClose}
    >
      <FlexContainer
        flexDirection="column"
        alignItems="center"
        style={{ gap: 5, width: '100%' }}
      >
        {messages.map((message, index) => (
          <AlertMessage
            key={index}
            message={message.message}
            open={open}
            {...props}
          />
        ))}
      </FlexContainer>
    </Snackbar>
  );
};

const AlertContainer = styled(FlexContainer)`
  min-width: ${() => (isMobile() ? '85%' : '488px')};
  max-width: ${() => (isMobile() ? '88%' : '500px')};
  min-height: 40px;
  margin-bottom: ${() => (isMobile() ? '24px' : 0)};
  background-color: ${props =>
    props.variant === 'outlined'
      ? '#fff'
      : props.type === 'success'
      ? colors.secondary600
      : props.type === 'warning'
      ? '#ffb600'
      : props.type === 'error'
      ? colors.hlitetwo400
      : '#1f5673'};
  color: ${props =>
    props.variant === 'filled'
      ? '#fff'
      : props.type === 'success'
      ? colors.secondary600
      : props.type === 'warning'
      ? '#ffb600'
      : props.type === 'error'
      ? colors.hlitetwo400
      : '#1f5673'};
  box-shadow: 0px 8px 20px rgba(29, 29, 29, 0.1);
  border-radius: 16px;
  padding: 12px 16px 12px 16px;
  border: ${props =>
    props.variant === 'filled'
      ? 'none'
      : props.type === 'success'
      ? `1px solid ${colors.secondary600}`
      : props.type === 'warning'
      ? `1px solid #ffb600`
      : props.type === 'error'
      ? `1px solid ${colors.hlitetwo400}`
      : '1px solid #1f5673'};
  .MuiIconButton-root:hover {
    background-color: initial;
  }
`;

const StyledClose = styled(Close)`
  color: ${props =>
    props.variant === 'filled'
      ? '#fff'
      : props.type === 'success'
      ? colors.secondary600
      : props.type === 'warning'
      ? '#ffb600'
      : props.type === 'error'
      ? colors.hlitetwo400
      : '#1f5673'};
`;

const LeftContainer = styled(FlexContainer)`
  width: 90%;
  gap: 16px;
`;

const TextContainer = styled(FlexContainer)`
  gap: 6px;
`;

const Header = styled.h4`
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;

export default AlertStack;
