export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const SET_ACTIVE_MEAL_PLAN = 'SET_ACTIVE_MEAL_PLAN';
export const SET_ACTIVE_FITNESS_PLAN = 'SET_ACTIVE_FITNESS_PLAN';
export const SET_CIRCLE_SLUG = 'SET_CIRCLE_SLUG';
export const SET_CURRENT_EMAIL = 'SET_CURRENT_EMAIL';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_DEFAULT_PLACEHOLDER = 'SET_DEFAULT_PLACEHOLDER';
export const SET_INGREDIENTMAPS = 'SET_INGREDIENTMAPS';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const SET_SELECTED_FITNESS_PLAN = 'SET_SELECTED_FITNESS_PLAN';
export const SET_SELECTED_MEAL_PLAN = 'SET_SELECTED_MEAL_PLAN';
export const SET_SELECTED_MEAL_TYPE = 'SET_SELECTED_MEAL_TYPE';
export const SET_SHOPPING_LIST = 'SET_SHOPPING_LIST';
export const SET_SHOULD_ANIMATE_SET_RECORD = 'SET_SHOULD_ANIMATE_SET_RECORD';
export const SET_TRACK = 'SET_TRACK';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
