import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import { isMobile } from '../helpers/utils';

const FavoriteIcon = ({
  selected,
  toggleSelect,
  color,
  shrinkIcon,
  hideLabel,
  ...props
}) => (
  <FlexContainer flexDirection="column" alignItems="center">
    <Icon
      selected={selected}
      onClick={toggleSelect}
      isMobile={isMobile()}
      name="favorite"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      color={color}
      {...props}
    >
      <circle
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        cx="16"
        cy="16"
        r="14.78"
      />
      <path
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        style={shrinkIcon && { transform: 'scale(0.8) translate(4px, 4px)' }}
        d="M20.48,9.25c-2.24,0-3.27,1.19-4.3,3.25a.22.22,0,0,1-.18.11.24.24,0,0,1-.19-.11c-1-2.06-2.06-3.25-4.3-3.25a4.28,4.28,0,0,0-4.27,4.27c0,3.3,2.5,5.53,5.66,8.36,1,.9,2,1.83,3.1,2.87,1.05-1,2.09-2,3.1-2.87,3.16-2.83,5.66-5.07,5.66-8.36A4.28,4.28,0,0,0,20.48,9.25Z"
      />
    </Icon>
    {!hideLabel && <Label color={color}>Favorite</Label>}
  </FlexContainer>
);

const Icon = styled.svg`
  width: ${props => (props.width ? props.width : '32px')};
  height: ${props => (props.height ? props.height : '32px')};

  & circle {
    fill: ${props =>
      props.color === 'purple' && props.selected
        ? '#8976FF'
        : props.selected
        ? colors.hlitetwo400
        : '#fff'};
    stroke: ${props =>
      props.color === 'purple' ? '#8976FF' : colors.hlitetwo400};
  }

  & path {
    fill: ${props =>
      props.color === 'purple' && props.selected
        ? '#8976FF'
        : props.selected
        ? colors.hlitetwo400
        : '#fff'};
    stroke: ${props =>
      props.selected
        ? '#fff'
        : props.color === 'purple'
        ? '#8976FF'
        : colors.hlitetwo400};
    /* transform: scale(0.8); */
  }

  &:hover {
    cursor: pointer;
  }

  ${props =>
    !props.isMobile && props.color === 'purple'
      ? `&:hover circle {fill: #8976FF;}`
      : !props.isMobile
      ? `&:hover circle {fill: ${colors.hlitetwo400};}`
      : ''}

  ${props =>
    !props.isMobile && props.color === 'purple'
      ? `&:hover path {stroke: #fff;fill: #8976FF;}`
      : !props.isMobile
      ? `&:hover path {stroke: #fff;fill: ${colors.hlitetwo400};}`
      : ''}

  &:focus circle {
    fill: ${props =>
      props.color === 'purple' ? '#8976FF' : colors.hlitetwo400};
  }
  &:focus path {
    stroke: #fff;
    fill: ${props =>
      props.color === 'purple' ? '#8976FF' : colors.hlitetwo400};
  }

  &:active circle {
    fill: ${props =>
      props.color === 'purple' ? '#a79afb' : colors.hlitetwo300};
  }
  &:active path {
    stroke: #fff;
    fill: ${props =>
      props.color === 'purple' ? '#a79afb' : colors.hlitetwo300};
  }

  &:disabled circle {
    fill: #fff;
    stroke: ${colors.primary300};
  }
  &:disabled path {
    fill: #fff;
    stroke: ${colors.primary300};
  }
`;

const Label = styled.p`
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  color: ${props =>
    props.color === 'purple' ? colors.primary800 : colors.hlitetwo400};
  margin: 8px 0 0 0;

  ${Icon}:disabled & {
    color: ${colors.primary400};
  }
`;

export default FavoriteIcon;
