import React, { useState } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import RadioInput from '../../elements/RadioInput';
import { colors } from '../../styleConstants';

import AddTrack from './AddTrack';
import EditTrack from './EditTrack';
import ViewTrack from './ViewTrack';
import DuplicateTrack from './DuplicateTrack';
import DeleteTrack from './DeleteTrack';
import TrackMap from './TrackMap';

const AdminTrack = props => {
  const [actionType, setActionType] = useState('create');

  const handleChange = e => {
    setActionType(e.target.value);
  };

  return (
    <Container flexDirection="column">
      <H2 data-test="admin-title-track">Fitness Track Admin</H2>
      <H3>Action Type</H3>
      <FlexContainer flexDirection="row" justify="space-around">
        <RadioInput
          checked={actionType === 'create'}
          value="create"
          label="Create"
          onChange={handleChange}
          name="recordType"
          data-test="track-radio-create"
        />
        <RadioInput
          checked={actionType === 'duplicate'}
          value="duplicate"
          label="Duplicate"
          onChange={handleChange}
          name="recordType"
          data-test="track-radio-duplicate"
        />
        <RadioInput
          checked={actionType === 'edit'}
          value="edit"
          label="Edit"
          onChange={handleChange}
          name="recordType"
          data-test="track-radio-edit"
        />
        <RadioInput
          checked={actionType === 'view'}
          value="view"
          label="View"
          onChange={handleChange}
          name="recordType"
          data-test="track-radio-view"
        />
        <RadioInput
          checked={actionType === 'delete'}
          value="delete"
          label="Delete"
          onChange={handleChange}
          name="recordType"
          data-test="track-radio-delete"
        />
        <RadioInput
          checked={actionType === 'map'}
          value="map"
          label="Map"
          onChange={handleChange}
          name="recordType"
          data-test="track-radio-map"
        />
      </FlexContainer>
      {actionType === 'create' ? (
        <AddTrack />
      ) : actionType === 'duplicate' ? (
        <DuplicateTrack />
      ) : actionType === 'edit' ? (
        <EditTrack />
      ) : actionType === 'view' ? (
        <ViewTrack />
      ) : actionType === 'delete' ? (
        <DeleteTrack />
      ) : actionType === 'map' ? (
        <TrackMap />
      ) : null}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  max-width: 75%;
  margin-left: 25px;
  padding: 30px;
`;

const H2 = styled.h2`
  text-align: center;
  font-size: 28px;
  margin: 15px;
  color: ${colors.secondary600};
`;
const H3 = styled.h3`
  text-align: center;
  margin: 15px;
`;
export default AdminTrack;
