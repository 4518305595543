import React from 'react';
import styled from 'styled-components';
import FlexContainer from './FlexContainer';
import { colors } from '../styleConstants';
import Fraction from 'fraction.js/fraction';

const MobileNumericInput = ({
  label,
  value,
  onValueChanged,
  stepBy,
  units,
  unitColor,
  minValue,
  maxValue,
  relatedName,
  relatedValue,
  relatedValueMin,
  relatedValueMax,
  ...props
}) => {
  const step = stepBy || 1;

  if (typeof value !== 'number' && value !== '') {
    value = new Fraction(value).valueOf();
  }

  const handleStepUp = () => {
    if (value % 1 !== 0) {
      value = parseInt(value, 10);
    }
    if (maxValue) {
      const maxValueNumber = parseInt(maxValue);
      if (value + step <= maxValueNumber) {
        onValueChanged(props.name, value + step);
      } else {
        if (relatedName && relatedValue) {
          const relatedValueNumber = parseInt(relatedValue) + 1;
          if (relatedValueNumber <= parseInt(relatedValueMax)) {
            onValueChanged(relatedName, relatedValueNumber);
          }
        }
        const minValueNumber = parseInt(minValue) || 0;
        onValueChanged(props.name, minValueNumber);
      }
    } else {
      onValueChanged(props.name, value + step);
    }
  };

  const handleStepDown = () => {
    if (value % 1 !== 0) {
      value = parseInt(value, 10);
    }
    if (minValue) {
      const minValueNumber = parseInt(minValue);
      if (value - step >= minValueNumber) {
        onValueChanged(props.name, value - step);
      } else {
        if (relatedName && relatedValue) {
          const relatedValueNumber = parseInt(relatedValue) - 1;
          if (relatedValueNumber >= parseInt(relatedValueMin)) {
            onValueChanged(relatedName, relatedValueNumber);
          }
        }
        const maxValueNumber = Number(maxValue) || 0;
        onValueChanged(props.name, maxValueNumber);
      }
    } else {
      onValueChanged(props.name, value - step);
    }
  };

  return (
    <FormContainer
      value={value}
      disabled={props.disabled}
      justify="center"
      alignItems="center"
    >
      <Button
        onClick={() => !props.disabled && handleStepDown()}
        disabled={props.disabled}
        data-test={`${props['data-test']}-stepDown`}
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        <DecreaseIcon></DecreaseIcon>
      </Button>
      <Input
        type={props.type || 'number'}
        step="any"
        disabled={props.disabled}
        value={value}
        {...props}
      />
      <Button
        onClick={() => !props.disabled && handleStepUp()}
        disabled={props.disabled}
        justify="center"
        alignItems="center"
        alignContent="center"
        data-test={`${props['data-test']}-stepUp`}
      >
        <IncreaseIconY></IncreaseIconY>
        <IncreaseIconX></IncreaseIconX>
      </Button>
    </FormContainer>
  );
};

const FormContainer = styled(FlexContainer)`
  text-align: center;
  height: 24px;
  width: 100px;
`;

const Button = styled(FlexContainer)`
  margin: 0px;
  width: 24px;
  height: 24px;
  padding: 0px 6px;
  text-align: center;
  background: ${props =>
    props.disabled ? `${colors.primary400};` : `${colors.secondary500}`};
  color: white;
  border-radius: 4px;
  font-weight: 400;
  position: relative;
`;

const Input = styled.input`
  text-align: center;
  width: 42px;
  height: 20px;
  font-size: 17px;
  line-height: 20px;
  font-weight: bold;
  background-color: inherit;
  color: ${colors.primary900};
`;

const DecreaseIcon = styled.div`
  box-sizing: border-box;
  width: 12px;
  height: 1px;
  border: 1px solid #ffffff;
`;

const IncreaseIconX = styled.div`
  box-sizing: border-box;
  height: 1px;
  width: 12px;
  border: 1px solid #ffffff;
  transform: rotate(90deg);
  transform-origin: center;
`;
const IncreaseIconY = styled.div`
  box-sizing: border-box;
  height: 1px;
  width: 12px;
  border: 1px solid #ffffff;
  position: absolute;
`;

export default MobileNumericInput;
