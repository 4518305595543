import { defaults } from 'lodash';
import {
  SET_CURRENT_EMAIL,
  SET_CURRENT_USER,
  SET_CIRCLE_SLUG,
  ADD_ERROR,
  REMOVE_ERROR,
  SET_SELECTED_DATE,
  SET_SELECTED_MEAL_TYPE,
  SET_SHOULD_ANIMATE_SET_RECORD,
} from '../actionTypes';

const DEFAULT_STATE_ERRORS = [];
export const DEFAULT_STATE_CURRENT_USER = {
  isAuthenticated: false, // hopefully be true, when logged in
  user: {
    id: null,
    birthDate: null,
    email: '',
    name: {
      first: '',
      last: '',
    },
    fullName: '',
    gender: '',
    height: {
      feet: 5,
      inches: 8,
    },
    weight: 150,
    notificationPreferences: [],
    detailPreferences: {
      weightGoal: 150,
      nutriologyGoal: 'Lose body fat',
      activityLevel: 'Competent',
    },
    foodPreferences: {
      eatingStyle: '',
      eatingPreference: 'I like everything',
      alcoholPreference: '',
      foodAvoidances: [],
      likes: [],
      dislikes: [],
    },
    fitnessPreferences: {
      fitnessStyle: 'At the gym',
      equipments: [],
      injuries: [],
      exerciseLikes: [],
      exerciseDislikes: [],
    },
    favoriteRecipes: [],
    favoriteFoods: [],
    isVerified: false,
    isSubscribed: false,
    isSubscriptionActive: false,
  },
  isChecked: false, // will be updated when we check the JWT so it's to capture the intermediate state of a user
};

const DEFAULT_STATE_SELECTED_DATE = new Date();
const DEFAULT_STATE_SELECTED_MEAL_TYPE = 'Breakfast';
const DEFAULT_SHOULD_ANIMATE_SET_RECORD = true;
const DEFAULT_CIRCLE_SLUG = 'nutriology-transformation-apr-8-2024'; // fallback

export const currentUser = (state = DEFAULT_STATE_CURRENT_USER, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: action.user !== DEFAULT_STATE_CURRENT_USER.user,
        user: defaults(action.user, DEFAULT_STATE_CURRENT_USER.user),
        isChecked: true,
      };

    case SET_CURRENT_EMAIL:
      return {
        ...state,
        user: defaults({ email: action.email }, state.user),
      };
    default:
      return state;
  }
};

export const selectedDate = (state = DEFAULT_STATE_SELECTED_DATE, action) => {
  switch (action.type) {
    case SET_SELECTED_DATE:
      return action.date;

    default:
      return state;
  }
};

export const selectedMealType = (
  state = DEFAULT_STATE_SELECTED_MEAL_TYPE,
  action
) => {
  switch (action.type) {
    case SET_SELECTED_MEAL_TYPE:
      return action.mealType;
    default:
      return state;
  }
};

export const circleSlug = (state = DEFAULT_CIRCLE_SLUG, action) => {
  switch (action.type) {
    case SET_CIRCLE_SLUG:
      return action.slug;
    default:
      return state;
  }
};

export const errors = (state = DEFAULT_STATE_ERRORS, action) => {
  switch (action.type) {
    case ADD_ERROR:
      state.push(action.error);
      return state;
    case REMOVE_ERROR:
      return state.filter(error => {
        return error?.type !== action.errorType;
      });
    default:
      return state;
  }
};

export const shouldAnimateSetRecord = (
  state = DEFAULT_SHOULD_ANIMATE_SET_RECORD,
  action
) => {
  switch (action.type) {
    case SET_SHOULD_ANIMATE_SET_RECORD:
      return action.shouldAnimate;
    default:
      return state;
  }
};
