import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import useFormValues from '../hooks/useFormValues';

import TextInput from '../elements/TextInput';
import NumericInput from '../elements/NumericInput';
import Button from '../elements/Button';

const EatingOut = props => {
  const { values, handleChange, handleValueChanged } = useFormValues();
  return (
    <Container flexDirection="column">
      <Header>Add Placeholder Meal</Header>
      <FormContainer>
        <LeftContainer flexDirection="column">
          <TextInput
            label="Name"
            id="name"
            name="name"
            onChange={handleChange}
            width="488px"
            placeholder="Food Name"
            value={values.name || ''}
          />
          <ServingInfoContainer flexDirection="column">
            <ServingInfoRow justify="space-between" alignItems="center">
              <ServingInfoLabel>Serving size</ServingInfoLabel>
              <NumericInput
                name="servingSize"
                value={values.servingSize || 0}
                onValueChanged={handleValueChanged}
                onChange={handleChange}
                minValue="0"
              />
            </ServingInfoRow>
            <ServingInfoRow justify="space-between" alignItems="center">
              <ServingInfoLabel>Calories</ServingInfoLabel>
              <NumericInput
                name="calories"
                value={values.calories || 0}
                onValueChanged={handleValueChanged}
                onChange={handleChange}
                minValue="0"
              />
            </ServingInfoRow>
            <ServingInfoRow justify="space-between" alignItems="center">
              <ServingInfoLabel>Protein (g)</ServingInfoLabel>
              <NumericInput
                name="protein"
                value={values.protein || 0}
                onValueChanged={handleValueChanged}
                onChange={handleChange}
                minValue="0"
              />
            </ServingInfoRow>
            <ServingInfoRow justify="space-between" alignItems="center">
              <ServingInfoLabel>Carbohydrates (g)</ServingInfoLabel>
              <NumericInput
                name="carbs"
                value={values.carbs || 0}
                onValueChanged={handleValueChanged}
                onChange={handleChange}
                minValue="0"
              />
            </ServingInfoRow>
            <ServingInfoRow justify="space-between" alignItems="center">
              <ServingInfoLabel>Fat (g)</ServingInfoLabel>
              <NumericInput
                name="fat"
                value={values.fat || 0}
                onValueChanged={handleValueChanged}
                onChange={handleChange}
                minValue="0"
              />
            </ServingInfoRow>
          </ServingInfoContainer>
          <AdvNutInfoLabel>Add Advanced Nutritional Info</AdvNutInfoLabel>
          <ButtonContainer justify="flex-start" alignItems="flex-end">
            <Button
              pink
              width="320px"
              buttonText="Add Placeholder Meal"
              buttonSize="large"
              handleClick={() => console.log('need handleClick function')}
            />
          </ButtonContainer>
        </LeftContainer>
      </FormContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
  height: 100%;
`;
const FormContainer = styled(FlexContainer)``;
const ButtonContainer = styled(FlexContainer)`
  flex-basis: content;
`;

const LeftContainer = styled(FlexContainer)`
  margin-top: 48px;
  padding-right: 48px;

  > :first-child {
    margin-bottom: 48px;
  }
  > :nth-child(2) {
    margin-bottom: 32px;
  }
  > :nth-child(3) {
    margin-bottom: 64px;
  }
`;

const ServingInfoContainer = styled(FlexContainer)`
  > * {
    margin-bottom: 16px;
  }
`;
const ServingInfoRow = styled(FlexContainer)`
  width: 244px;
`;

const ServingInfoLabel = styled.h4`
  color: ${colors.primary800};
`;

const Header = styled.h2`
  color: ${colors.primary800};
`;

const AdvNutInfoLabel = styled.h4`
  color: ${colors.secondary500};
`;

export default EatingOut;
