import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import zxcvbn from 'zxcvbn';

const PasswordStrengthMeter = ({ password, ...props }) => {
  const testedResult = zxcvbn(password);
  const score = testedResult.score;

  let scoreLabel = '';
  let scoreColor = '';

  switch (score) {
    case 0:
      scoreLabel = 'Weak';
      scoreColor = 'red';
      break;
    case 1:
      scoreLabel = 'Weak';
      scoreColor = 'orange';
      break;
    case 2:
      scoreLabel = 'Fair';
      scoreColor = 'yellow';
      break;
    case 3:
      scoreLabel = 'Good';
      scoreColor = 'yellowgreen';
      break;
    case 4:
      scoreLabel = 'Strong';
      scoreColor = 'green';
      break;
    default:
      scoreLabel = 'Weak';
      scoreColor = 'red';
      break;
  }

  const renderScore = () => (
    <FlexContainer gap="12px" alignItems="center">
      <FlexContainer gap="1px" alignItems="center">
        {[0, 1, 2, 3, 4].map(val => (
          <StyledProgressDiv
            key={val}
            value={val}
            color={val <= score ? scoreColor : colors.primary100}
          />
        ))}
      </FlexContainer>
      <LabelMobile color={scoreColor}>{scoreLabel}</LabelMobile>
    </FlexContainer>
  );
  if (props.small && password) {
    return <div {...props}>{renderScore()}</div>;
  } else if (password) {
    return (
      <FlexContainer flexDirection="column">
        <StyledProgress value={score} max="4" color={scoreColor} />
        <Label>Password Strength: {scoreLabel}</Label>
      </FlexContainer>
    );
  }
  return <div></div>;
};

const StyledProgressDiv = styled.div`
  width: 10px;
  height: 4px;
  border-radius: 1px;
  background-color: ${props => (props.color ? props.color : colors.primary100)};
  margin-right: 2px;
`;

const LabelMobile = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: ${props => props.color};
  margin-bottom: 1px;
`;
const StyledProgress = styled.progress`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  border: none;
  border-radius: 3px;

  ::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 3px;
  }
  ::-webkit-progress-value {
    background-color: ${props => props.color};
    border-radius: 3px;
  }
  ::-moz-progress-bar {
    background-color: ${props => props.color};
    border-radius: 3px;
  }
`;

const Label = styled.label`
  margin-top: 12px;
  font-size: 15px;
  color: ${colors.primary500};
`;

export default PasswordStrengthMeter;
