import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';

import AddIcon from '../icons/AddIcon';
import RecipeCardLarge from './recipe/RecipeCardLarge';
import RecipeChipSmall from './recipe/RecipeChipSmall';
import FoodCardLarge from './food/FoodCardLarge';
import FoodChipSmall from './food/FoodChipSmall';
import Collapsible from 'react-collapsible';
import CollapseIcon from '../icons/CollapseIcon';
import CloseIcon from '../icons/CloseIcon';

import useToggles from '../hooks/useToggles';
import useModal from '../hooks/useModal';

import ModalContainer from '../sharedModals/ModalContainer';
import { formatCalories } from '../helpers/nutrition';

const Meal = ({
  mealTitle,
  recipes,
  foods,
  variant,
  date,
  removeRecipe,
  removeFood,
  ...props
}) => {
  const { open, launchModal, closeModal, context } = useModal(false);

  const { toggleValues, handleToggle } = useToggles({ [mealTitle]: true });

  const handleRemove = e => {
    if (e.target.dataset.type === 'recipe') {
      const recipeId = e.target.dataset.name;
      removeRecipe(recipeId);
    } else if (e.target.dataset.type === 'food') {
      const foodId = e.target.dataset.name;
      removeFood(foodId);
    }
  };

  const items = recipes.concat(foods).sort((a, b) => a.index - b.index);

  const itemsList = items.map((item, i) => {
    if (item.recipe) {
      const recipe = item;
      const recipeInstanceId = recipe._id ? recipe._id : recipe.id;

      if (variant === 'dashboard' || variant === 'admin') {
        return (
          <RecipeContainer key={recipe._id || i} alignItems="center">
            <RecipeChipSmall
              recipe={recipe}
              instanceId={recipeInstanceId}
              variant={variant}
              handleRemove={handleRemove}
              data-test="recipe-chip-small"
              {...props}
            />
          </RecipeContainer>
        );
      } else {
        return (
          <RecipeContainer
            key={recipe._id}
            alignItems="center"
            data-test="recipe-container"
          >
            <RecipeCardLarge key={recipe._id} recipe={recipe} {...props} />
            <StyledCloseIcon leftover={recipe?.leftover}>
              <CloseIcon
                size="24px"
                data-name={recipeInstanceId}
                data-type="recipe"
                handleClose={handleRemove}
                data-test="meal-card-delete"
              />
            </StyledCloseIcon>
          </RecipeContainer>
        );
      }
    }
    if (item.food) {
      const food = item;
      const foodInstanceId = food._id ? food._id : food.id;

      if (variant === 'dashboard' || variant === 'admin') {
        return (
          <RecipeContainer key={food._id || i} alignItems="center">
            <FoodChipSmall
              food={food}
              instanceId={foodInstanceId}
              variant={variant}
              handleRemove={handleRemove}
              {...props}
            />
          </RecipeContainer>
        );
      } else {
        return (
          <RecipeContainer
            key={food._id || i}
            alignItems="center"
            data-test="food-container"
          >
            <FoodCardLarge
              key={food._id}
              food={food}
              data-test="food-card-large"
              {...props}
            />
            <StyledCloseIcon>
              <CloseIcon
                size="24px"
                data-name={foodInstanceId}
                data-type="food"
                handleClose={handleRemove}
                data-test="meal-card-delete"
              />
            </StyledCloseIcon>
          </RecipeContainer>
        );
      }
    }
    return null;
  });

  const triggerStyle = {
    position: 'absolute',
    top: '3px',
    right: 0,
  };

  const recipeCalories = recipes.reduce((total, recipe) => {
    return total + formatCalories(recipe.recipe?.nutrients?.calories);
  }, 0);

  const foodCalories = foods.reduce((total, food) => {
    const calories = formatCalories(
      food.quantity * food.food.nutrientsPerUnit.calories
    );
    return total + calories;
  }, 0);

  const totalCalories = recipeCalories + foodCalories;

  return (
    <MealContainer flexDirection="column" flexShrink="0">
      <FlexContainer justify="space-between">
        <MealLabels alignItems="center">
          <MealTitle data-test={`meal-${mealTitle}-mealTitle`}>
            {mealTitle}
          </MealTitle>
          <Calories
            data-test={`meal-${mealTitle}-totalCalories`}
          >{`${totalCalories} calories`}</Calories>
        </MealLabels>
      </FlexContainer>
      <Collapsible
        overflowWhenOpen="visible"
        triggerStyle={triggerStyle}
        contentOuterClassName="contentOuter__no_padding"
        open={toggleValues[`${mealTitle}`]}
        trigger={
          <CollapseIcon
            small
            onClick={handleToggle()}
            open={toggleValues[`${mealTitle}`]}
            name={mealTitle}
            data-test={`collapse-${mealTitle}`}
          />
        }
      >
        <RecipeList
          flexDirection="column"
          data-test={`meal-${mealTitle}-recipeList`}
        >
          {itemsList}
        </RecipeList>
      </Collapsible>
      {(variant === 'mealPlan' || variant === 'admin') && (
        <>
          <AddIcon
            labelText="Add Food"
            data-meal={mealTitle}
            data-date={date}
            data-variant={variant}
            orientation="row"
            pink="true"
            onClick={launchModal}
            data-test={`meal-${mealTitle}-add`}
          />
          <ModalContainer
            open={open}
            context={context}
            handleClose={closeModal}
            initialScreen="Add Meal"
            {...props}
          />
        </>
      )}
    </MealContainer>
  );
};

const MealContainer = styled(FlexContainer)`
  position: relative;
`;

const RecipeList = styled(FlexContainer)`
  margin-bottom: 8px;

  > * {
    margin-bottom: 8px;
  }
`;

const RecipeContainer = styled(FlexContainer)`
  position: relative;
`;

const StyledCloseIcon = styled(FlexContainer)`
  position: absolute;
  top: ${props => (props.leftover ? '25px' : '3px')};
  right: 3px;
  z-index: -5;
  opacity: 0;
  transition: opacity 200ms ease-in-out 50ms;

  ${RecipeContainer}:hover & {
    z-index: 5;
    opacity: 1;
  }
`;

const MealLabels = styled(FlexContainer)`
  margin-bottom: 16px;
`;

const MealTitle = styled.h4`
  text-transform: uppercase;
  color: ${colors.primary800};
  margin-right: 16px;
`;

const Calories = styled.p`
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary500};
`;

export default Meal;
