import React, { useState } from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import useModal from '../hooks/useModal';
import UserPaymentMethodModalView from '../profileModals/UserPaymentMethodModalView';
import { isMobile } from '../helpers/utils';
import Button from '../elements/Button';
import CloseIcon from '../icons/CloseIcon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import MobileDialog from '../sharedModals/mobile/MobileDialog';
import Modal from '@material-ui/core/Modal';
import DialogContent from '@material-ui/core/DialogContent';

const PaymentDisplay = ({ card, subscription, cancelPlan, ...props }) => {
  const { open, launchModal, closeModal } = useModal(false);
  const [showCancel, setShowCancel] = useState(false);

  const handleEdit = () => {
    launchModal();
  };

  const handleCancel = () => {
    cancelPlan(!subscription.cancel_at_period_end);
    setShowCancel(false);
  };

  const closeCancelModal = () => {
    setShowCancel(false);
  };

  return (
    <>
      {card && (
        <StyledTable>
          <Header>
            <TableRow>
              <TableCell>Payment Method</TableCell>
              <TableCell>Account</TableCell>
              <TableCell>Exp Date</TableCell>
            </TableRow>
          </Header>
          <TableBody>
            <TableRow>
              <DataCardBrand data-test="payment-card-brand">
                {card.brand}
              </DataCardBrand>
              <Data data-test="payment-card-number">xxxx - {card.last4}</Data>
              <Data data-test="payment-card-exp">
                {card.exp_month}/{card.exp_year}
              </Data>
            </TableRow>
          </TableBody>
        </StyledTable>
      )}
      <FlexContainer flexDirection="column" alignItems="center">
        {subscription && (
          <NextBillingDetails justify="center">
            <NextBillingLabel data-test="payment-next-billing-label">
              {subscription?.cancel_at_period_end
                ? 'Your subscription will end :'
                : 'Next Billing: '}
            </NextBillingLabel>
            <NextBillingLabel fontWeight="700" data-test="payment-next-billing">
              {subscription?.cancel_at_period_end
                ? moment.unix(subscription.cancel_at).format('Do MMM YYYY')
                : moment
                    .unix(subscription.current_period_end)
                    .format('MMMM D, YYYY')}
            </NextBillingLabel>
          </NextBillingDetails>
        )}
        <ButtonGreen
          buttonText={
            card ? 'Edit/Change billing details' : 'Add billing details'
          }
          variant="outlined"
          width={isMobile() ? '100%' : '378px'}
          onClick={handleEdit}
          style={{ margin: '16px 0' }}
          data-test="payment-change-billing"
        />

        {subscription && (
          <CancelButton
            flexDirection="column"
            justify="center"
            alignItems="center"
            onClick={() => setShowCancel(true)}
            data-test="payment-cancel-subscription"
          >
            <ButtonLabel
              fontWeight="700"
              color="#FF6868"
              data-test="payment-undo-cancel"
            >
              {subscription?.cancel_at_period_end
                ? "Don't Cancel Subscription"
                : 'Cancel Subscription'}
              ?
            </ButtonLabel>
            {!subscription?.cancel_at_period_end && (
              <ButtonLabel color="#FF6868">
                If you cancel before this date, your credit card will not be
                charged.
              </ButtonLabel>
            )}
          </CancelButton>
        )}
      </FlexContainer>
      <UserPaymentMethodModalView
        open={open}
        handleClose={closeModal}
        subscription={subscription}
      />
      {isMobile() ? (
        <MobileDialog
          open={showCancel}
          handleClose={closeCancelModal}
          height="fit-content"
          small="small"
          data-test="mobile-modal"
        >
          <DialogContainer
            flexDirection="column"
            alignItems="center"
            data-test="mobile-profile-dialog"
          >
            <Puller />
            <TopContainer flexDirection="column" alignItems="center">
              <Heading>
                {subscription?.cancel_at_period_end
                  ? "Don't Cancel Subscription"
                  : 'Cancel Subscription'}
                ?
              </Heading>
              <Subheading style={{ marginTop: '8px' }}></Subheading>
            </TopContainer>
            <img
              src={`${process.env.PUBLIC_URL}/icons/cancelPlan.svg`}
              alt=""
            />
            <ButtonContainer flexDirection="column">
              <StyledButton
                buttonText={
                  subscription?.cancel_at_period_end
                    ? "Don't Cancel Subscription"
                    : 'Cancel Subscription'
                }
                pink="true"
                onClick={handleCancel}
                data-test="modal-cancel-subscription"
              />
              <StyledButton
                buttonText={
                  subscription?.cancel_at_period_end
                    ? 'Close'
                    : "I've changed my mind"
                }
                pink="true"
                variant="outlined"
                onClick={closeCancelModal}
                data-test="modal-dont-cancel"
              />
            </ButtonContainer>
          </DialogContainer>
        </MobileDialog>
      ) : (
        <div>
          <Modal open={showCancel} onClose={closeCancelModal}>
            <StyledDialogContent>
              <WebModalContent
                justify="space-evenly"
                flexDirection="column"
                alignItems="center"
                data-test="modal-cancel"
              >
                <StyledCloseIcon handleClose={closeCancelModal} />
                <TopContainer flexDirection="column" alignItems="center">
                  <Heading>
                    {subscription?.cancel_at_period_end
                      ? "Don't Cancel Subscription"
                      : 'Cancel Subscription'}
                    ?
                  </Heading>
                  <Subheading style={{ marginTop: '8px' }}>
                    This will cancel all your custom meal and fitness plans. You
                    will still be able to use basic features of the app.
                  </Subheading>
                </TopContainer>
                <img
                  src={`${process.env.PUBLIC_URL}/icons/cancelPlan.svg`}
                  alt=""
                />
                <ButtonContainer justify="center">
                  <StyledButton
                    buttonText={
                      subscription?.cancel_at_period_end
                        ? 'Close'
                        : "I've changed my mind"
                    }
                    pink="true"
                    variant="outlined"
                    width="233px"
                    onClick={closeCancelModal}
                    data-test="modal-dont-cancel"
                  />
                  <StyledButton
                    buttonText={
                      subscription?.cancel_at_period_end
                        ? "Don't Cancel Subscription"
                        : 'Cancel Subscription'
                    }
                    pink="true"
                    width="233px"
                    onClick={handleCancel}
                    data-test="modal-cancel-subscription"
                  />
                </ButtonContainer>
              </WebModalContent>
            </StyledDialogContent>
          </Modal>
        </div>
      )}
    </>
  );
};

const StyledTable = styled(Table)`
  max-width: ${props => (!isMobile() ? '75%' : null)};
`;

const Header = styled(TableHead)`
  & .MuiTableCell-head {
    font-family: 'Work Sans';
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.primary800};
  }
`;

const Data = styled(TableCell)`
  font-family: 'Work Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.primary600};
  white-space: pre;
`;

const DataCardBrand = styled(Data)`
  text-transform: capitalize;
`;

const NextBillingDetails = styled(FlexContainer)`
  margin: 16px 0 8px 0;
  gap: 8px;
  width: ${props => (!isMobile() ? '234px' : 'initial')};
`;

const NextBillingLabel = styled.div`
  font-weight: ${props => props.fontWeight || 400};
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${colors.primary600};
`;

const StyledButton = styled(Button)`
  height: 45px;
  border-radius: 2px;
  text-transform: none;
`;

const ButtonGreen = styled(StyledButton)`
  border: 1px solid ${colors.secondary600};
  color: ${colors.secondary600};
`;

const ButtonLabel = styled.span`
  font-weight: ${props => props.fontWeight || 400};
  font-size: 16px;
  line-height: 19px;
  color: ${props => props.color || colors.secondary600};
  text-align: center;
`;

const CancelButton = styled(FlexContainer)`
  padding: 16px;
  background: #fffbfb;
  border-radius: 16px;
  gap: 8px;
  width: ${props => (!isMobile() ? '345px' : 'initial')};

  &:hover {
    cursor: pointer;
  }
`;

const DialogContainer = styled(FlexContainer)`
  height: 100%;
  background-color: #fbfbfb;
  background-image: url(${process.env.PUBLIC_URL}/Bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 48px 24px 24px 24px;
  margin: 0 16px;
  gap: 24px;
`;

const Puller = styled.div`
  width: 46px;
  height: 3px;
  background-color: ${colors.primary100};
  border-radius: 16px;
  position: absolute;
  top: 8px;
`;

const TopContainer = styled.div`
  /* padding: 36px 0 51px 0; */
  gap: 8px;
`;

const Heading = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: ${colors.primary800};
`;

const Subheading = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${props => props.color || colors.primary800};
  margin-top: ${props => props.marginTop || 0};
`;

const ButtonContainer = styled(FlexContainer)`
  margin-top: 24px;
  width: 100%;
  gap: ${props => (isMobile() ? '8px' : '24px')};
`;

const StyledDialogContent = styled(DialogContent)`
  width: 800px;
  height: 360px;
  padding: 24px 0;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const WebModalContent = styled(FlexContainer)`
  position: relative;
  height: 100%;
  padding: 0 54px;
  background-color: white;
  border-radius: 4px;
  outline: 0;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export default PaymentDisplay;
