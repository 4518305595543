import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import ArrowBlue from '../icons/ArrowBlue';
import TrackPyramid from '../icons/TrackPyramid';

import useModal from '../hooks/useModal';
import SwitchPlanModal from './SwitchPlanModal';

const mapState = state => ({
  currentTrack: state.userTrack,
  user: state.currentUser.user,
});

const CurrentPlanCard = props => {
  const { open, launchModal, closeModal } = useModal(false);
  const { currentTrack, user } = useSelector(mapState);
  const level =
    currentTrack.level === 'Advanced' ? 'Intermediate' : currentTrack.level;
  return (
    <CardContainer alignItems="flex-start">
      <TrackPyramid
        colorScheme="blue"
        level={level}
        data-test="currentTrack-pyramid"
      />
      <FlexContainer flexDirection="column" justify="space-between">
        <TopContainer flexDirection="column" justify="center">
          <Label>Current Fitness Plan Level:</Label>
          <PlanName data-test="currentTrack-name">{level}</PlanName>
        </TopContainer>
        <BottomContainer alignItems="center">
          <ChangeLevel onClick={launchModal}>Change Level</ChangeLevel>
          <ArrowBlue
            width="24px"
            onClick={launchModal}
            data-test="fitnessPlan-changePlan"
          />
        </BottomContainer>
        <SwitchPlanModal
          open={open}
          handleClose={closeModal}
          userId={user._id}
          {...props}
        />
      </FlexContainer>
    </CardContainer>
  );
};

const CardContainer = styled(FlexContainer)`
  > * {
    margin-right: 16px;
  }
`;

const TopContainer = styled(FlexContainer)`
  flex-basis: 75%;
`;

const BottomContainer = styled(FlexContainer)`
  margin-top: 12px;
  > * {
    margin-right: 8px;
  }
`;

const Label = styled.h6`
  font-weight: 400;
  font-size: 11px;
  line-height: 0;
  color: ${colors.hliteone500};
  margin-bottom: 4px;
`;

const PlanName = styled.h3`
  color: ${colors.hliteone500};
  font-weight: 600;
  text-transform: uppercase;
  line-height: 30px;
`;

const ChangeLevel = styled.h6`
  color: ${colors.hliteone400};
  font-size: 13px;
  line-height: 12px;
  font-weight: 500;
`;

export default CurrentPlanCard;
