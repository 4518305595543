import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import WaterButton from '../elements/WaterButton';
import BottleBar from '../icons/BottleBar';
import { useSelector } from 'react-redux';

const MobileWaterTracker = ({ water, recordWater, ...props }) => {
  const gender = useSelector(state => state.currentUser.user.gender);
  const recommendedOz = gender === 'Male' ? 104 : 72;
  const [selected, setSelected] = useState('plus');

  return (
    <Container flexDirection="column" alignItems="center">
      <FlexContainer
        flexDirection="column"
        alignItems="center"
        style={{ gap: 12 }}
      >
        <Header>Daily Water Tracker</Header>
        <StatsText data-test="water-drank">
          {`${water.amount} oz`}{' '}
          <Span data-test="water-recommended">/ {`${recommendedOz} oz`}</Span>
        </StatsText>
      </FlexContainer>
      <BottleContainer alignItems="center" justify="center">
        <BottleBar value={(water.amount / recommendedOz) * 100 || 0} />
      </BottleContainer>
      <BottomContainer
        flexDirection="column"
        justify="center"
        alignItems="center"
      >
        <IncDecContainer alignSelf="flex-end">
          <CircleButton
            justify="center"
            alignItems="center"
            name="plus"
            selected={selected === 'plus'}
            onClick={() => setSelected('plus')}
            data-test="water-increment"
          >
            <Plus selected={selected === 'plus'}>+</Plus>
          </CircleButton>
          <CircleButton
            justify="center"
            alignItems="center"
            name="minus"
            selected={selected === 'minus'}
            onClick={() => setSelected('minus')}
            data-test="water-decrement"
          >
            <Minus selected={selected === 'minus'}>-</Minus>
          </CircleButton>
        </IncDecContainer>
        <ButtonContainer>
          <WaterButton
            water={water}
            increment={selected === 'minus' ? -8 : 8}
            unit="oz"
            color={colors.hliteone400}
            recordWater={recordWater}
          />
          <WaterButton
            water={water}
            increment={selected === 'minus' ? -12 : 12}
            unit="oz"
            color={colors.hliteone400}
            recordWater={recordWater}
          />
        </ButtonContainer>
      </BottomContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 24px 0 0 0;
  gap: 32px;
  background-color: #eafff4;
  background-image: url(${process.env.PUBLIC_URL}/WaterBkgnd.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

const Header = styled.h3`
  font-size: 20px;
  font-family: Work Sans;
  font-weight: 700;
  color: ${colors.primary800};
`;

const StatsText = styled.h5`
  color: rgba(104, 104, 104, 0.54);
  font-size: 24px;
  font-weight: 600;
`;

const IncDecContainer = styled(FlexContainer)`
  width: 100px;
  position: absolute;
  right: 12px;
  top: -22.5px;
`;

const Plus = styled.p`
  font-size: 24px;
  color: ${props => (props.selected ? '#fff' : '#000')};
  line-height: normal;
  font-weight: 500;
`;

const Minus = styled.p`
  font-size: 28px;
  color: ${props => (props.selected ? '#fff' : '#000')};
  line-height: 28px;
  height: 32px;
`;

const CircleButton = styled(FlexContainer)`
  width: 45px;
  height: 45px;
  border-radius: 22.5px;
  background-color: ${props => (props.selected ? colors.hliteone400 : '#fff')};
  border: ${props => (props.selected ? 'none' : '1px solid #BDEFE3')};
`;

const BottleContainer = styled(FlexContainer)`
  width: 166px;
  height: 260px;
  flex-shrink: 0;
  border-radius: 37px;
  border: 1px solid ${colors.primary200};
  background: rgba(255, 255, 255, 0.99);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
`;

const BottomContainer = styled(FlexContainer)`
  gap: 20px;
  width: 100%;
  height: 120px;
  align-self: flex-end;
  padding-bottom: 24px;
  position: relative;
  background-color: ${colors.hliteone100};
  border-top-left-radius: 23px;
  border-top-right-radius: 23px;
  border-top: 1px solid #bdefe3;
`;

const ButtonContainer = styled(FlexContainer)`
  margin-top: 24px;
  gap: 12px;
`;

const Span = styled.span`
  color: #55c0f3;
`;

export default MobileWaterTracker;
