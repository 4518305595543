import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from './FlexContainer';
import Avatar from '@material-ui/core/Avatar';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

const AddRecipeImageMobile = props => {
  return (
    <Container {...props}>
      <AvatarBox src={props.src} variant="square" height={props?.height}>
        <PhotoCameraIconBox />
      </AvatarBox>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  position: relative;
`;

const PhotoCameraIconBox = styled(PhotoCameraIcon)`
  width: 70px;
  height: 70px;
`;

const AvatarBox = styled(Avatar)`
  width: 100%;
  height: ${props => (props.height ? props.height : '230px')};
  background-color: ${colors.primary100};
`;

export default AddRecipeImageMobile;
