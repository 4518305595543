export const levels = ['Beginner', 'Intermediate', 'Advanced'];

export const locationTypes = ['Gym', 'Home'];

export const genders = ['All', 'Men', 'Women'];

export const trackTypes = [
  {
    level: 'Beginner',
    locationType: 'Home',
    gender: 'All',
  },
  {
    level: 'Beginner',
    locationType: 'Gym',
    gender: 'Women',
  },
  {
    level: 'Beginner',
    locationType: 'Gym',
    gender: 'Men',
  },
  {
    level: 'Intermediate',
    locationType: 'Home',
    gender: 'All',
  },
  {
    level: 'Intermediate',
    locationType: 'Gym',
    gender: 'Women',
  },
  {
    level: 'Intermediate',
    locationType: 'Gym',
    gender: 'Men',
  },
  {
    level: 'Advanced',
    locationType: 'Home',
    gender: 'All',
  },
  {
    level: 'Advanced',
    locationType: 'Gym',
    gender: 'Women',
  },
  {
    level: 'Advanced',
    locationType: 'Gym',
    gender: 'Men',
  },
];
