import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';

import { isEmpty } from 'lodash';

import useFormValues from '../../hooks/useFormValues';
import useModal from '../../hooks/useModal';
import useToggles from '../../hooks/useToggles';
import ModalContainer from '../../sharedModals/ModalContainer';

import MealPlanForm from './MealPlanForm';
import { editMealPlan, getMealPlan } from '../../services/api/mealplan';

const DeleteMealPlan = props => {
  const initialValues = {
    type: 'Weight Loss',
    planLength: 7,
    dayNum: {
      value: 1,
      label: 'Monday',
    },
    recommendedCalories: 1800,
  };

  const initialToggleValues = {
    active: false,
    dietType: {},
  };

  const {
    values,
    setValues,
    handleChange,
    handleDateChanged,
    handleValueChanged,
  } = useFormValues(initialValues);

  const { toggleValues, handleToggle, setToggleValues } = useToggles(
    initialToggleValues
  );

  const [mealPlan, setMealPlan] = useState([]);
  const [prevMealPlan, setPrevMealPlan] = useState({});
  const [recipes, setRecipes] = useState([]);
  const [foods, setFoods] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [filteredFoods, setFilteredFoods] = useState([]);
  const [status, setStatus] = useState();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { open, launchModal, closeModal } = useModal(false);

  async function loadMealPlan(params) {
    try {
      setMessage('');
      const mealPlanId = params.mealPlan;
      const result = await getMealPlan(mealPlanId);
      if (result) {
        setMealPlan(result);
      } else {
        throw new Error('Meal Plan could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded meal plan
  useEffect(() => {
    if (!isEmpty(mealPlan)) {
      setRecipes([...mealPlan.recipes]);
      setFoods([...mealPlan.foods]);

      setValues({
        ...values,
        type: mealPlan.type,
        planLength: mealPlan.planLength,
        recommendedCalories: mealPlan.recommendedCalories,
      });
      const toggleTmp = {
        dietType: {},
        active: mealPlan.active,
      };

      const tags = mealPlan.tags;
      for (let tagType in tags) {
        tags[tagType].forEach(tagVal => {
          toggleTmp[tagType][tagVal] = true;
        });
      }
      setToggleValues(toggleTmp);
    }
  }, [mealPlan]);

  useEffect(() => {
    const filteredRec = recipes.filter(recipe => {
      return recipe.dayNum === values.dayNum.value;
    });
    const filteredFood = foods.filter(food => {
      return food.dayNum === values.dayNum.value;
    });
    setFilteredRecipes(filteredRec);
    setFilteredFoods(filteredFood);
  }, [recipes, foods, values.dayNum]);

  const deleteItem = e => {
    setLoading(true);
    e.preventDefault();

    const mealPlanId = mealPlan._id;
    let payload = {
      isDeleted: true,
    };

    editMealPlan(mealPlanId, payload)
      .then(result => {
        setPrevMealPlan(mealPlanId);
        setStatus('success');
        setMessage(`Meal Plan "${result.name}" was deleted successfully!`);
        setValues(initialValues);
        setToggleValues(initialToggleValues);
        setRecipes([]);
        setFoods([]);
        setMealPlan([]);
      })
      .catch(err => {
        console.error(err);
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const undoDelete = e => {
    e.preventDefault();
    let payload = {
      isDeleted: false,
    };
    editMealPlan(prevMealPlan, payload)
      .then(result => {
        setStatus('');
        setMessage('');
        loadMealPlan({
          mealPlan: prevMealPlan,
        });
      })
      .catch(err => {
        console.error(err);
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Meal Plan"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
        data-test="mealPlan-find"
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          hide: 'food',
        }}
        initialScreen="MealPlanSearch"
        handleClose={closeModal}
        addMealPlan={loadMealPlan}
        {...props}
      />
      <MealPlanForm
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        handleDateChanged={handleDateChanged}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        filteredRecipes={filteredRecipes}
        filteredFoods={filteredFoods}
        foods={foods}
        recipes={recipes}
        setRecipes={setRecipes}
        setFoods={setFoods}
        status={status}
        setStatus={setStatus}
        message={message}
        loading={loading}
        handleSubmit={deleteItem}
        undoDelete={undoDelete}
        viewOnly={true}
        buttonText={'Delete Meal Plan'}
        formType="delete"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default DeleteMealPlan;
