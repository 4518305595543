import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import { isEmpty } from 'lodash';
import IntensitySet from '../elements/IntensitySet';
import LogIcon from '../icons/LogIcon';
import CollapseIconMedium from '../icons/CollapseIconMedium';
import Collapsible from 'react-collapsible';
import MobileCollapseIcon from '../icons/MobileCollapseIcon';
import { trackEvent } from '../integrations/analytics';
import RemoveIcon from '../icons/RemoveIcon';
import Avatar from '@material-ui/core/Avatar';

import WorkoutSection from './WorkoutSection';

import { setFitnessPlanWorkoutProperty } from '../store/actions/fitnessplan';
import { toggleAllComplete } from '../services/api/workout';
import { isMobile, formatAttrLink } from '../helpers/utils';
import { getImageURL } from '../services/api/api';

const WorkoutCard = ({
  fitnessPlan,
  workout,
  removeWorkout,
  date,
  setFitnessPlanWorkoutProperty,
  ...props
}) => {
  const trigger = useRef(null);

  const [open, setOpen] = useState(isMobile() ? false : true);

  const handleToggle = e => {
    setOpen(!open);
  };

  const handleTrigger = () => {
    if (!open && isMobile()) {
      // Only tracking on mobile because workout card defaults to expanded on web
      trackEvent('Expanded Workout (Mobile)');
    }
    trigger.current.click();
    setOpen(!open);
  };

  const handleRemove = e => {
    removeWorkout(workout._id);
  };

  async function toggleWorkoutComplete() {
    const params = {
      allComplete: workout.allComplete,
    };
    await toggleAllComplete(workout.workout._id, params);

    setFitnessPlanWorkoutProperty(
      fitnessPlan,
      workout,
      'allComplete',
      !workout.allComplete
    );
  }

  const handleLog = e => {
    trackEvent(
      `Logged Workout All Complete (Fitness Plan - ${
        isMobile() ? 'Mobile' : 'Web'
      })`,
      { workout: workout, logged: !workout.allComplete }
    );
    toggleWorkoutComplete();
  };

  const exercises = workout.workout.exercises || [];
  const warmup = exercises.filter(
    exercise => exercise.workoutSequence === 'Warm Up'
  );
  const core = exercises.filter(
    exercise => exercise.workoutSequence === 'Core'
  );
  const strength = exercises.filter(
    exercise => exercise.workoutSequence === 'Strength'
  );
  const circuit = exercises.filter(
    exercise => exercise.workoutSequence === 'Circuit'
  );
  const conditioning = exercises.filter(
    exercise => exercise.workoutSequence === 'Conditioning'
  );
  const cooldown = exercises.filter(
    exercise => exercise.workoutSequence === 'Cool Down'
  );

  // Determine if the logged state of all sets is different than the current workout state
  const evalAllLogged = () => {
    if (exercises.length) {
      let allSets = [];
      exercises.forEach(exercise => (allSets = [...allSets, ...exercise.sets]));
      if (allSets.length) {
        // If every set is now logged but the current workout state is not all complete
        // then we need to update the workout to indicate it is all complete
        if (allSets.every(set => set.logged) && !workout.allComplete) {
          setFitnessPlanWorkoutProperty(
            fitnessPlan,
            workout,
            'allComplete',
            true
          );
          // If some sets are not logged but the current workout is all complete
          // then we need to update the workout to indicate it is not all complete
        } else if (allSets.some(set => !set.logged) && workout.allComplete) {
          setFitnessPlanWorkoutProperty(
            fitnessPlan,
            workout,
            'allComplete',
            false
          );
        }
      }
    }
  };

  useEffect(() => {
    evalAllLogged();
  }, [exercises]);

  const workoutImgUrl = (ratio = 1) => {
    const width = 144 * ratio;
    const widthMobile = 375 * ratio;
    const height = 144 * ratio;
    const heightMobile = 242 * ratio;
    if (workout.workout.imageUrl) {
      return getImageURL(
        workout.workout.imageUrl,
        `resizing_type:fill/height:${
          isMobile() ? heightMobile : height
        }/width:${isMobile() ? widthMobile : width}`
      );
    } else {
      return `${process.env.PUBLIC_URL}/icons/WorkoutDefault${ratio}x.png`;
    }
  };

  if (!isMobile()) {
    // Web version
    return (
      <CardContainer flexDirection="column" data-test="workout-card">
        <TopContainer alignItems="stretch" justify="space-between">
          <LeftContainer alignItems="center">
            <StyledAvatar
              src={workoutImgUrl()}
              srcSet={`${workoutImgUrl(2)} 2x`}
            />
            <WorkoutInfoContainer flexDirection="column">
              <WorkoutHeader data-test="workout-card-name">
                {workout.workout.name}
              </WorkoutHeader>
              <Row alignItems="center">
                <WorkoutInfo data-test="workout-card-calories">
                  <span style={{ fontWeight: 700 }}>
                    {workout.workout.caloriesBurned}
                  </span>{' '}
                  calories
                </WorkoutInfo>
                <WorkoutInfo data-test="workout-card-duration">
                  <span style={{ fontWeight: 700 }}>
                    {workout.workout.duration}
                  </span>{' '}
                  mins
                </WorkoutInfo>
              </Row>
              <IntensityContainer>
                <WorkoutInfo>Intensity: </WorkoutInfo>
                <IntensitySet
                  intensity={workout.workout.intensity}
                  data-test="workout-card-intensity"
                />
              </IntensityContainer>
              {workout?.workout?.source && (
                <Attribution
                  href={formatAttrLink(workout?.workout?.attrLink)}
                  data-test="workout-card-source"
                >
                  Workout by {workout.workout.source}
                </Attribution>
              )}
            </WorkoutInfoContainer>
          </LeftContainer>
          <RightContainer flexDirection="column" justify="flex-end">
            <IconsContainer alignItems="center">
              <LogIcon
                hideLabel
                logged={workout.allComplete}
                toggleSelect={handleLog}
                width="40px"
                height="40px"
                data-test="workout-card-log"
              />
              <RemoveIcon
                hideLabel
                toggleSelect={handleRemove}
                width="40px"
                height="40px"
                shrinkIcon={true}
                data-name={workout._id || ''}
                data-test="workout-card-remove"
              />
            </IconsContainer>
            <MobileCollapseIcon
              onClick={handleTrigger}
              open={open}
              data-test="mobile-workout-collapseTrigger"
            />
          </RightContainer>
        </TopContainer>
        <Collapsible
          overflowWhenOpen="visible"
          triggerStyle={triggerStyle}
          contentOuterClassName="contentOuter__no_padding"
          open={open}
          trigger={
            <input
              hidden
              ref={trigger}
              type="button"
              name={workout.workout.name}
              onClick={handleToggle}
              data-test="workout-card-trigger"
            />
          }
        >
          <BottomContainer flexDirection="column">
            {!isEmpty(warmup) && (
              <WorkoutSection
                sectionTitle="Warm Up"
                rest={workout.workout.restBetweenSets.warmUp || 1}
                exercises={warmup}
                workout={workout.workout}
                date={date}
                {...props}
              />
            )}
            {!isEmpty(core) && (
              <WorkoutSection
                sectionTitle="Core"
                rest={workout.workout.restBetweenSets.core || 1}
                exercises={core}
                workout={workout.workout}
                date={date}
                {...props}
              />
            )}
            {!isEmpty(strength) && (
              <WorkoutSection
                sectionTitle="Strength"
                rest={workout.workout.restBetweenSets.strength || 1}
                exercises={strength}
                workout={workout.workout}
                date={date}
                {...props}
              />
            )}
            {!isEmpty(circuit) && (
              <WorkoutSection
                sectionTitle="Circuit"
                rest={workout.workout.restBetweenSets.circuit || 1}
                exercises={circuit}
                workout={workout.workout}
                date={date}
                {...props}
              />
            )}
            {!isEmpty(conditioning) && (
              <WorkoutSection
                sectionTitle="Conditioning"
                rest={workout.workout.restBetweenSets.conditioning || 1}
                exercises={conditioning}
                workout={workout.workout}
                date={date}
                {...props}
              />
            )}
            {!isEmpty(cooldown) && (
              <WorkoutSection
                sectionTitle="Cool Down"
                rest={workout.workout.restBetweenSets.coolDown || 1}
                exercises={cooldown}
                workout={workout.workout}
                date={date}
                {...props}
              />
            )}
            <BottomRow justify="flex-end" alignItems="center" open={open}>
              <FlexContainer>
                <CollapseIconMedium onClick={handleTrigger} open={open} />
              </FlexContainer>
            </BottomRow>
          </BottomContainer>
        </Collapsible>
      </CardContainer>
    );
  } else {
    // Mobile version
    return (
      <>
        <WorkoutContainer data-test="mobile-workout-card">
          <Collapsible
            overflowWhenOpen="visible"
            triggerStyle={triggerStyle}
            contentOuterClassName="contentOuter__no_padding"
            open={!open}
            trigger={
              <input
                hidden
                ref={trigger}
                type="button"
                name={workout.workout.name}
                onClick={handleToggle}
              />
            }
          >
            <ImageContainter>
              <WorkoutImage
                imageUrl={workoutImgUrl(1)}
                imageUrl2x={workoutImgUrl(2)}
                data-test="mobile-workout-image"
              />
              <WorkoutLabel justify="center">
                <LabelText>WORKOUT</LabelText>
              </WorkoutLabel>
            </ImageContainter>
          </Collapsible>
          <MobCardContainer flexDirection="column">
            <MobTopContainer
              alignItems="center"
              justify="space-between"
              flexDirection="column"
              open={open}
            >
              <MobileWorkoutInfoContainer
                flexDirection="column"
                alignItems="center"
              >
                <MobWorkoutHeader data-test="mobile-workout-name">
                  {workout.workout.name}
                </MobWorkoutHeader>
                <Row alignItems="center">
                  <WorkoutInfo data-test="mobile-workout-calories">
                    <span style={{ fontWeight: 700 }}>
                      {workout.workout.caloriesBurned}
                    </span>{' '}
                    calories
                  </WorkoutInfo>
                  <WorkoutInfo data-test="mobile-workout-duration">
                    <span style={{ fontWeight: 700 }}>
                      {workout.workout.duration}
                    </span>{' '}
                    mins
                  </WorkoutInfo>
                </Row>
                <IntensityContainer>
                  <WorkoutInfo>Intensity: </WorkoutInfo>
                  <IntensitySet
                    intensity={workout.workout.intensity}
                    data-test="mobile-workout-intensity"
                  />
                </IntensityContainer>
                {workout?.workout?.source && (
                  <Attribution
                    href={formatAttrLink(workout?.workout?.attrLink)}
                    data-test="mobile-workout-source"
                  >
                    Workout by {workout.workout.source}
                  </Attribution>
                )}
              </MobileWorkoutInfoContainer>
              <IconsContainer>
                <RemoveIcon
                  toggleSelect={handleRemove}
                  width="40px"
                  height="40px"
                  shrinkIcon={true}
                  data-test="mobile-workout-remove"
                />
                <LogIcon
                  logged={workout.allComplete}
                  toggleSelect={handleLog}
                  width="40px"
                  height="40px"
                  data-test="mobile-workout-log"
                />
              </IconsContainer>
              <MobileCollapseIcon
                onClick={handleTrigger}
                open={open}
                data-test="mobile-workout-collapseTrigger"
              />
            </MobTopContainer>
            <Collapsible
              overflowWhenOpen="visible"
              triggerStyle={triggerStyle}
              contentOuterClassName="contentOuter__no_padding"
              open={open}
              trigger={
                <input
                  hidden
                  ref={trigger}
                  type="button"
                  name={workout.workout.name}
                  onClick={handleToggle}
                />
              }
            >
              <BottomContainer flexDirection="column">
                {!isEmpty(warmup) && (
                  <WorkoutSection
                    sectionTitle="Warm Up"
                    rest={workout.workout.restBetweenSets.warmUp || 1}
                    exercises={warmup}
                    workout={workout.workout}
                    date={date}
                    {...props}
                  />
                )}
                {!isEmpty(core) && (
                  <WorkoutSection
                    sectionTitle="Core"
                    rest={workout.workout.restBetweenSets.core || 1}
                    exercises={core}
                    workout={workout.workout}
                    date={date}
                    {...props}
                  />
                )}
                {!isEmpty(strength) && (
                  <WorkoutSection
                    sectionTitle="Strength"
                    rest={workout.workout.restBetweenSets.strength || 1}
                    exercises={strength}
                    workout={workout.workout}
                    date={date}
                    {...props}
                  />
                )}
                {!isEmpty(circuit) && (
                  <WorkoutSection
                    sectionTitle="Circuit"
                    rest={workout.workout.restBetweenSets.circuit || 1}
                    exercises={circuit}
                    workout={workout.workout}
                    date={date}
                    {...props}
                  />
                )}
                {!isEmpty(conditioning) && (
                  <WorkoutSection
                    sectionTitle="Conditioning"
                    rest={workout.workout.restBetweenSets.conditioning || 1}
                    exercises={conditioning}
                    workout={workout.workout}
                    date={date}
                    {...props}
                  />
                )}
                {!isEmpty(cooldown) && (
                  <WorkoutSection
                    sectionTitle="Cool Down"
                    rest={workout.workout.restBetweenSets.coolDown || 1}
                    exercises={cooldown}
                    workout={workout.workout}
                    date={date}
                    {...props}
                  />
                )}
              </BottomContainer>
            </Collapsible>
          </MobCardContainer>
        </WorkoutContainer>
      </>
    );
  }
};

/** Web **/

const CardContainer = styled(FlexContainer)`
  border: 1px solid ${colors.primary200};
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  min-height: 144px;
  min-width: 600px;
  position: relative;
`;

const TopContainer = styled(FlexContainer)`
  padding: 32px;
`;

const BottomContainer = styled(FlexContainer)`
  padding-bottom: 48px;
`;

const BottomRow = styled(FlexContainer)`
  padding: 0 16px 16px 32px;
  position: ${props => (props.open ? 'absolute' : 'relative')};
  bottom: 0;
  right: 0;
  width: calc(100% - 48px);
`;

const LeftContainer = styled(FlexContainer)`
  height: 100%;
`;
const RightContainer = styled(FlexContainer)`
  height: 116px;
  align-items: flex-end;
  justify-content: end;
  gap: 24px;
`;

const WorkoutInfoContainer = styled(FlexContainer)`
  margin-left: 32px;
  margin-right: 12px;
  gap: 8px;
`;

const Row = styled(FlexContainer)`
  gap: 24px;
`;

const WorkoutHeader = styled.h2`
  color: ${colors.primary800};
  font-weight: 700;
`;

const WorkoutInfo = styled.h6`
  font-weight: 400;
  color: ${colors.primary800};
`;

const IntensityContainer = styled(FlexContainer)`
  > :first-child {
    margin-right: 8px;
  }
`;

const Attribution = styled.a`
  color: ${colors.primary400};
  text-decoration: none;
  font-size: 14px;
`;

const triggerStyle = {
  display: 'none',
};

const StyledAvatar = styled(Avatar)`
  height: 144px; // Update image dimensions accordingly.
  width: 144px; // Update image dimensions accordingly.
`;

/** Mobile **/

const WorkoutContainer = styled.div`
  overflow-x: hidden;
`;

const ImageContainter = styled.div`
  position: relative;
`;

const IconsContainer = styled(FlexContainer)`
  gap: ${() => (isMobile() ? '36px' : '24px')};
`;

const WorkoutImage = styled.img`
  height: 242px;
  width: 100%;
  background-image: ${props => `url(${props.imageUrl})`};
  background-image: ${props =>
    `image-set(url(${props.imageUrl}) 1x, url(${props.imageUrl2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.imageUrl}) 1x, url(${props.imageUrl2x}) 2x )`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  opacity: 1;
  border: none;
`;

const WorkoutLabel = styled(FlexContainer)`
  height: 16px;
  width: 72px;
  top: 12px;
  left: -5px;
  border-radius: 8px;
  background-color: ${colors.hlitetwo400};
  position: absolute;
`;

const LabelText = styled.div`
  height: 14px;
  width: 56px;
  text-align: center;
  color: #ffffff;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 14px;
  margin: 1px 8px 1px 8px;
`;

const MobCardContainer = styled(FlexContainer)`
  border-bottom: 1px solid ${colors.primary300};
  border-radius: 4px;
  background-color: #fff;
  min-height: 184px;
  position: relative;
`;

const MobTopContainer = styled(FlexContainer)`
  padding: 24px;
  gap: 18px;
`;

const MobWorkoutHeader = styled.h2`
  color: ${colors.primary800};
  letter-spacing: 0;
  font-weight: 700;
  text-align: center;
`;

const MobileWorkoutInfoContainer = styled(FlexContainer)`
  gap: 8px;
`;

export default connect(
  null,
  {
    setFitnessPlanWorkoutProperty,
  }
)(WorkoutCard);
