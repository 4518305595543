import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setSelectedDate } from '../store/actions/general';
import { getUserFitnessPlanByDate } from '../store/actions/fitnessplan';
import { getUserMealPlanByDate } from '../store/actions/mealplan';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from './FlexContainer';
import { IconButton } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import moment from 'moment';

const DateNavigator = ({
  date,
  setDate,
  setSelectedDate,
  getUserMealPlanByDate,
  getUserFitnessPlanByDate,
  userId,
  ...props
}) => {
  const showPrevDay = () => {
    const dt = new Date(date);
    dt.setDate(date.getDate() - 1);
    setDate(dt);
  };

  const showNextDay = () => {
    const dt = new Date(date);
    dt.setDate(date.getDate() + 1);
    setDate(dt);
  };

  useEffect(() => {
    setSelectedDate(date);
  }, [date]);

  useEffect(() => {
    getUserFitnessPlanByDate(userId, date);
    getUserMealPlanByDate(userId, date);
  }, [date, userId]);

  const momentDate = moment(date);
  const showPrevButton =
    new Date().getTime() - date.getTime() < 7 * 24 * 60 * 60 * 1000;
  const showNextButton =
    date.getTime() - new Date().getTime() < 6 * 24 * 60 * 60 * 1000;

  return (
    <FlexContainer alignItems="center">
      {showPrevButton && (
        <IconButton data-test="datenavigator-prev-day" onClick={showPrevDay}>
          <NavigateBeforeIcon />
        </IconButton>
      )}
      <DateText data-test="datenavigator-text">
        {momentDate.format('MMM D')}
      </DateText>
      {showNextButton && (
        <IconButton data-test="datenavigator-next-day" onClick={showNextDay}>
          <NavigateNextIcon />
        </IconButton>
      )}
    </FlexContainer>
  );
};

const DateText = styled(FlexContainer)`
  width: 100px;
  color: ${colors.primary800};
  text-transform: uppercase;
  border: 1px solid ${colors.primary400};
  padding: 10px;
  font-size: 17px;
  line-height: 20px;
  border-radius: 2px;
  justify-content: center;
`;

function mapStateToProps(state) {
  const { currentUser } = state;

  return {
    userId: currentUser.user.id,
  };
}

export default connect(
  mapStateToProps,
  { setSelectedDate, getUserMealPlanByDate, getUserFitnessPlanByDate }
)(DateNavigator);
