import React, { useState, useEffect } from 'react';
import MobileTopNav from '../universal/MobileTopNav';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import { upperFirst } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { formatCalories } from '../helpers/nutrition';
import { pick } from 'lodash';
import {
  setMealPlanRecipeProperty,
  setMealPlanFoodProperty,
  swapMealPlanItem,
} from '../store/actions/mealplan';
import { updateUser } from '../store/actions/general';
import useModal from '../hooks/useModal';
import ModalContainer from '../sharedModals/ModalContainer';
import FlexContainer from '../elements/FlexContainer';
import FavoriteIcon from '../icons/FavoriteIcon';
import SwapIcon from '../icons/SwapIcon';
import RemoveIcon from '../icons/RemoveIcon';
import LogIcon from '../icons/LogIcon';
import { colors } from '../styleConstants';
import { getImageURL } from '../services/api/api';
import MobileTabs from '../universal/MobileTabs';
import { setSelectedMealType } from '../store/actions/general';
import { connect } from 'react-redux';
import { trackEvent } from '../integrations/analytics';
import WaterIcon from '../icons/WaterIcon';
import MobileWaterTracker from './MobileWaterTracker';
import MobileDialog from '../sharedModals/mobile/MobileDialog';

const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

const UserMealPlanMobile = ({
  mealPlan,
  recipes,
  foods,
  totalCalories,
  dateValue,
  setDateValue,
  setSelectedMealType,
  history,
  initialTabIndex,
  setMealPlanRecipeProperty,
  setMealPlanFoodProperty,
  water,
  recordWater,
  removeRecipe,
  removeFood,
  swapMealPlanItem,
  currentUser,
  updateUser,
  userId,
}) => {
  const [selectedMealTypeIndex, setSelectedMealTypeIndex] = useState(
    initialTabIndex
  );
  const [waterDialogOpen, setWaterDialogOpen] = useState(false);
  const [itemToReplace, setItemToReplace] = useState(null);
  const { open, launchModal, closeModal } = useModal(false);
  const momentDateValue = moment(dateValue);
  let dateText = '';
  // check for today
  if (momentDateValue.isSame(moment(), 'day')) dateText = 'Today, ';
  // check for tomorrow
  if (momentDateValue.isSame(moment().add(1, 'd'), 'day'))
    dateText = 'Tomorrow, ';
  if (momentDateValue.isSame(moment().subtract(1, 'd'), 'day'))
    dateText = 'Yesterday, ';

  useEffect(() => {
    setSelectedMealTypeIndex(initialTabIndex);
  }, [initialTabIndex]);

  useEffect(() => {
    const historyListener = history.listen(location => {
      if (
        history.action === 'PUSH' &&
        (location.pathname.match(
          new RegExp(/^\/app\/mealplan\/.+\/recipe\/.+$/)
        ) ||
          location.pathname.match(
            new RegExp(/^\/app\/mealplan\/.+\/food\/.+$/)
          ))
      ) {
        localStorage.setItem('mealplan_tabValue', selectedMealTypeIndex);
      }
    });

    return function cleanup() {
      historyListener();
    };
  }, [selectedMealTypeIndex]);

  const handleChange = (event, newValue) => {
    setSelectedMealType(['Breakfast', 'Lunch', 'Dinner', 'Snack'][newValue]);
    setSelectedMealTypeIndex(newValue);
  };

  const handleChangeIndex = index => {
    setSelectedMealType(['Breakfast', 'Lunch', 'Dinner', 'Snack'][index]);
    setSelectedMealTypeIndex(index);
  };

  const handleSwapClick = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    setItemToReplace(item);
    launchModal();
  };

  const handleLink = (link, item) => {
    trackEvent('Clicked Recipe or Food Name (Meal Plan - Mobile)', {
      item: item._id,
    });
    history.push(link);
  };

  const handleWaterClick = () => {
    setWaterDialogOpen(!waterDialogOpen);
  };

  const toggleEaten = async (event, item) => {
    event.preventDefault();
    event.stopPropagation();
    if (item.hasOwnProperty('recipe')) {
      trackEvent('Logged Recipe (Meal Plan - Mobile)', {
        recipe: item,
        eaten: !item.eaten,
      });
      setMealPlanRecipeProperty(mealPlan, item, 'eaten', !item.eaten);
    } else {
      trackEvent('Logged Food (Meal Plan - Mobile)', {
        food: item,
        eaten: !item.eaten,
      });
      setMealPlanFoodProperty(mealPlan, item, 'eaten', !item.eaten);
    }
  };

  const toggleFavorite = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    let userData = {};
    if (item.recipe) {
      let newRecipes = [...currentUser.favoriteRecipes];

      // In most cases the recipe ID to add to the favorite is going to be the recipe admin ID
      // But sometimes, there won't be any (if it's a custom recipe for example)
      const favoriteRecipe = item.recipe.recipeAdmin
        ? item.recipe.recipeAdmin
        : item.recipe._id;

      if (currentUser.favoriteRecipes.includes(favoriteRecipe)) {
        // The recipe is already present, we remove it.
        trackEvent('Unfavorite Recipe (Planner)', {
          recipeId: favoriteRecipe,
          recipeName: item.recipe.name,
        });
        newRecipes.splice(newRecipes.indexOf(favoriteRecipe), 1);
      } else {
        // The recipe is not present we add it to the list.
        trackEvent('Favorite Recipe (Planner)', {
          recipeId: favoriteRecipe,
          recipeName: item.recipe.name,
        });
        newRecipes.push(favoriteRecipe);
      }

      userData = {
        id: userId,
        favoriteRecipes: newRecipes,
      };
      updateUser(userData);
    } else {
      let newFoods = [...currentUser.favoriteFoods];

      // In most cases the food ID to add to the favorite is going to be the food admin ID
      // But sometimes, there won't be any (if it's a custom food for example)
      const favoriteFood = item.food.foodAdmin
        ? item.food.foodAdmin
        : item.food._id;

      if (currentUser.favoriteFoods.includes(favoriteFood)) {
        // The food is already present, we remove it.
        trackEvent('Unfavorite Food (Planner)', {
          foodId: favoriteFood,
          foodName: item.food.verboseName,
        });
        newFoods.splice(newFoods.indexOf(favoriteFood), 1);
      } else {
        // The food is not present we add it to the list.
        trackEvent('Favorite Food (Planner)', {
          foodId: favoriteFood,
          foodName: item.food.verboseName,
        });
        newFoods.push(favoriteFood);
      }

      userData = {
        id: userId,
        favoriteFoods: newFoods,
      };
      updateUser(userData);
    }
  };

  const removeItem = (e, item) => {
    e.stopPropagation();
    if (item.hasOwnProperty('recipe')) {
      removeRecipe(item._id);
    } else {
      removeFood(item._id);
    }
  };

  const renderItems = meal => {
    const filteredRecipes = recipes?.filter(recipe => recipe.mealType === meal);
    const filteredFoods = foods?.filter(food => food.mealType === meal);

    const totalMealCalories =
      filteredRecipes?.reduce((total, recipe) => {
        return total + formatCalories(recipe.recipe?.nutrients?.calories);
      }, 0) +
      filteredFoods?.reduce((total, food) => {
        return (
          total +
            formatCalories(
              food.food.nutrientsPerUnit.calories * food.quantity
            ) || 0
        );
      }, 0);

    const imageUrl = (item, ratio = 1) => {
      const width = window.screen.width * ratio || 375 * ratio;
      const height = 242 * ratio;
      if (item?.recipe?.imageUrl) {
        return getImageURL(
          item.recipe.imageUrl,
          `resizing_type:fill/height:${height}/width:${width}`
        );
      } else if (item?.food?.imageUrl) {
        return getImageURL(
          item.food.imageUrl,
          `resizing_type:fill/height:${height}/width:${width}`
        );
      }
      return getImageURL(
        `${fileStorage}/Nutriology_Symbol_375x242.png`,
        `resizing_type:fill/height:${height}/width:${width}`
      );
    };
    const allItems = filteredRecipes
      ?.concat(filteredFoods)
      ?.sort((a, b) => a.index - b.index);

    return (
      <MealTab key={filteredRecipes?.[0]}>
        <MealHeading
          justify="space-between"
          alignItems="center"
          data-test="mobile-meal-heading"
        >
          <MealTitle>{meal}</MealTitle>
          <WaterIcon
            color={colors.hliteone400}
            onClick={handleWaterClick}
            data-test="launch-water-modal"
          />
        </MealHeading>
        {allItems?.map(item => {
          const favorited = item.hasOwnProperty('recipe')
            ? currentUser.favoriteRecipes.includes(item.recipe._id) ||
              currentUser.favoriteRecipes.includes(item.recipe.recipeAdmin)
            : currentUser.favoriteFoods.includes(item.food._id) ||
              currentUser.favoriteFoods.includes(item.food.foodAdmin);

          const swapItemsHandler = async params => {
            console.log(itemToReplace);
            const newItem = params.recipe ? params.recipe : params.food;
            const quantity = params.quantity ? params.quantity : 1;
            const destId = itemToReplace._id;
            closeModal();
            trackEvent('Swapped Item (Meal Plan Mobile)', {
              oldItem: itemToReplace,
              newItem: newItem,
            });
            swapMealPlanItem(mealPlan.id, newItem, destId, quantity);
          };

          return (
            <Card
              key={item._id}
              style={{ position: 'relative' }}
              onClick={() =>
                handleLink(
                  item.hasOwnProperty('recipe')
                    ? `/app/mealplan/${mealPlan._id}/recipe/${item._id}`
                    : `/app/mealplan/${mealPlan._id}/food/${item._id}`,
                  item
                )
              }
              data-test="mobile-meal-card"
            >
              {item?.leftover && (
                <LeftoverLabel
                  justify="center"
                  alignItems="center"
                  data-test="leftover-banner"
                >
                  <Img src={`${process.env.PUBLIC_URL}/icons/Microwave.svg`} />
                  <LeftoverLabelText>Leftover</LeftoverLabelText>
                </LeftoverLabel>
              )}
              <CustomCardMedia
                image={imageUrl(item)}
                image2x={imageUrl(item, 2)}
                title={
                  item.hasOwnProperty('recipe')
                    ? item.recipe.name
                    : item.food.verboseName
                }
              />
              <CustomCardActions>
                <FlexContainer
                  flexDirection="column"
                  gap="16px"
                  style={{ width: '100%' }}
                >
                  <IconsContainer justify="center" alignItems="center">
                    <FavoriteIcon
                      toggleSelect={e => toggleFavorite(e, item)}
                      selected={favorited}
                      color="purple"
                      width="40px"
                      height="40px"
                      shrinkIcon={true}
                      data-test="mobile-meal-favorite"
                    />
                    <SwapIcon
                      toggleSelect={e => handleSwapClick(e, item)}
                      width="40px"
                      height="40px"
                      color="teal"
                      shrinkIcon={true}
                      data-test="mobile-meal-swap"
                    />
                    <RemoveIcon
                      toggleSelect={e => removeItem(e, item)}
                      width="40px"
                      height="40px"
                      shrinkIcon={true}
                      data-test="mobile-meal-remove"
                    />
                    <LogIcon
                      logged={item.eaten}
                      toggleSelect={e => toggleEaten(e, item)}
                      width="40px"
                      height="40px"
                      data-test="mobile-meal-log"
                    />
                  </IconsContainer>
                  <RecipeName data-test="mobile-meal-name">
                    {item.hasOwnProperty('recipe')
                      ? item.recipe.name
                      : upperFirst(item.food.verboseName)}
                  </RecipeName>
                  <RecipeCalories data-test="mobile-meal-calories">
                    <span style={{ fontWeight: 700 }}>
                      {formatCalories(
                        item.hasOwnProperty('recipe')
                          ? item.recipe.nutrients.calories
                          : item.food.nutrientsPerUnit.calories * item.quantity
                      )}{' '}
                    </span>
                    calories
                  </RecipeCalories>
                </FlexContainer>
              </CustomCardActions>
              <ModalContainer
                addRecipe={swapItemsHandler}
                addFood={swapItemsHandler}
                open={open}
                context={{
                  type: 'swap',
                }}
                handleClose={closeModal}
                initialScreen="Add Meal Mobile"
              />
            </Card>
          );
        })}
        <MealAndTotalCaloriesContainer
          flexDirection="column"
          justify="center"
          alignItems="center"
        >
          <TotalMealCalories>
            {meal}:{' '}
            <span data-test="total-meal-calories">
              {totalMealCalories} calories
            </span>
          </TotalMealCalories>
          <TotalCalories>
            <DailyTotalCalories>Daily Total: </DailyTotalCalories>
            <span data-test="total-day-calories">{totalCalories} calories</span>
          </TotalCalories>
        </MealAndTotalCaloriesContainer>
        <MobileDialog
          open={waterDialogOpen}
          handleClose={() => setWaterDialogOpen(false)}
          height="fit-content"
          small="small"
          data-test="mobile-water-tracker"
        >
          <MobileWaterTracker
            handleClose={() => setWaterDialogOpen(false)}
            water={water}
            recordWater={recordWater}
          />
        </MobileDialog>
      </MealTab>
    );
  };
  return (
    <div>
      <MobileTopNav
        dateText={dateText}
        dateValue={dateValue}
        setDateValue={setDateValue}
      />
      <PageContainer>
        <MobileTabs
          value={selectedMealTypeIndex}
          handleChange={handleChange}
          handleChangeIndex={handleChangeIndex}
          tabs={['Breakfast', 'Lunch', 'Dinner', 'Snack']}
          children={[
            renderItems('Breakfast'),
            renderItems('Lunch'),
            renderItems('Dinner'),
            renderItems('Snack'),
          ]}
          data-test="mobile-meal-tabs"
        />
      </PageContainer>
    </div>
  );
};

const PageContainer = styled.div`
  margin-top: 44px;
`;
const MealHeading = styled(FlexContainer)`
  padding: 16px 30px;
`;

const MealTitle = styled.h2`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0;
`;

const CustomCardMedia = styled.div`
  height: 0;
  padding-top: 56.25%;
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-position: center;
`;
const CustomCardActions = styled(CardActions)`
  margin: 17px 30px 48px 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const RecipeName = styled.h2`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
const RecipeCalories = styled.p`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 13px;
  letter-spacing: 0;
  margin-top: -12px;
  text-align: center;
`;
const TotalMealCalories = styled.p`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 15px;
  letter-spacing: 0;
`;
const TotalCalories = styled.p`
  color: ${colors.secondary500};
  font-family: 'Work Sans';
  font-size: 15px;
  font-weight: bold;
`;
const MealTab = styled.div`
  margin-bottom: 48px;
`;
const MealAndTotalCaloriesContainer = styled(FlexContainer)`
  padding-top: 16px;
  padding-bottom: 36px;
`;
const DailyTotalCalories = styled.span`
  color: ${colors.primary800};
`;

const LeftoverLabel = styled(FlexContainer)`
  position: absolute;
  top: 14px;
  right: 14px;
  border-radius: 4px;
  width: 130px;
  height: 34px;
  background-color: ${colors.secondary600};
`;

const LeftoverLabelText = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #fff;
`;

const Img = styled.img`
  margin-right: 8px;
`;

const IconsContainer = styled(FlexContainer)`
  gap: 36px;
`;

function mapStateToProps(state) {
  const { currentUser } = state;
  const fields = ['id', 'favoriteFoods', 'favoriteRecipes'];
  const user = pick(currentUser.user, fields);
  return {
    currentUser: user,
    userId: user.id,
  };
}

export default connect(
  mapStateToProps,
  {
    setSelectedMealType,
    setMealPlanRecipeProperty,
    setMealPlanFoodProperty,
    swapMealPlanItem,
    updateUser,
  }
)(UserMealPlanMobile);
