// source : https://www.fda.gov/food/new-nutrition-facts-label/daily-value-new-nutrition-and-supplement-facts-labels#1
const recommended = {
  calcium: 1300, // 1300 mg
  iron: 18, // 18 mg
  potasium: 4700, // 4700 mg
  vitaminD: 20, // 20 mcg
};

export const formatCalcium = value => {
  // <2% is zero
  // <=10%, nearest 2%
  // >10%, <=50%, nearest 5%
  // >50%, nearest 10%
  if (!value) {
    return '0%';
  }
  let percent = 0;
  const absPercent = (value / recommended.calcium) * 100;
  if (absPercent < 2) {
    percent = 0;
  } else if (absPercent <= 10) {
    percent = Math.round(absPercent / 2) * 2;
  } else if (absPercent <= 50) {
    percent = Math.round(absPercent / 5) * 5;
  } else {
    percent = Math.round(absPercent / 10) * 10;
  }
  return `${percent}%`;
};

export const formatCalories = value => {
  // <5 cal is zero
  // <=50, nearest 5 cal
  // > 50, nearest 10 cal
  if (!value) {
    return 0;
  }
  if (value < 5) {
    return 0;
  } else if (value <= 50) {
    return Math.round(value / 5) * 5;
  } else {
    return Math.round(value / 10) * 10;
  }
};

export const formatTotalCarbohydrate = (value, returnUnit = true) => {
  // <.5 g is zero
  // <1, is "Less than 1 g"
  // >= 1, nearest 1 g
  if (!value) {
    return returnUnit ? '0g' : 0;
  } else if (value < 0.5) {
    return returnUnit ? '0g' : 0;
  } else if (value < 1) {
    return returnUnit ? 'Less than 1g' : 0;
  } else {
    return returnUnit ? `${Math.round(value)}g` : Math.round(value);
  }
};

export const formatCholesterol = value => {
  // <2 mg is zero
  // 2-5 is "Less than 5mg"
  // >5, nearest 5 mg
  if (!value) {
    return '0mg';
  }
  if (value < 2) {
    return '0mg';
  } else if (value < 5) {
    return 'Less than 5mg';
  } else {
    return `${Math.round(value / 5) * 5}mg`;
  }
};

export const formatDietaryFiber = value => {
  // <.5 g is zero
  // <1, is "Less than 1 g"
  // >= 1, nearest 1 g
  if (!value) {
    return '0g';
  } else if (value < 0.5) {
    return '0g';
  } else if (value < 1) {
    return 'Less than 1g';
  } else {
    return `${Math.round(value)}g`;
  }
};

export const formatIron = value => {
  // <2% is zero
  // <=10%, nearest 2%
  // >10%, <=50%, nearest 5%
  // >50%, nearest 10%
  let percent = 0;
  if (!value) {
    return '0%';
  }
  const absPercent = (value / recommended.iron) * 100;
  if (absPercent < 2) {
    percent = 0;
  } else if (absPercent <= 10) {
    percent = Math.round(absPercent / 2) * 2;
  } else if (absPercent <= 50) {
    percent = Math.round(absPercent / 5) * 5;
  } else {
    percent = Math.round(absPercent / 10) * 10;
  }
  return `${percent}%`;
};

export const formatProtein = (value, returnUnit = true) => {
  if (value < 0 || !value) {
    return returnUnit ? '0g' : 0;
  }
  const rounded = Math.round(value);
  return returnUnit ? `${rounded}g` : rounded;
};

export const formatTotalSugars = value => {
  // <.5 g is zero
  // <1, is "Less than 1g"
  // >= 1, nearest 1 g
  if (!value) {
    return '0g';
  } else if (value < 0.5) {
    return '0g';
  } else if (value < 1) {
    return 'Less than 1g';
  } else {
    return `${Math.round(value)}g`;
  }
};

export const formatAddedSugars = value => {
  // <.5 g is zero
  // <1, is "Less than 1g"
  // >= 1, nearest 1 g
  if (!value) {
    return '0g';
  } else if (value < 0.5) {
    return '0g';
  } else if (value < 1) {
    return 'Less than 1g';
  } else {
    return `${Math.round(value)}g`;
  }
};

export const formatSaturatedFat = value => {
  // <.5 g is zero
  // <5, nearest .5 g
  // >= 5, nearest 1 g
  if (!value) {
    return '0g';
  }
  let displayValue = 0;
  if (value < 0.5) {
    displayValue = 0;
  } else if (value < 5) {
    displayValue = Math.round(value / 0.5) * 0.5;
  } else {
    displayValue = Math.round(value);
  }
  return `${displayValue}g`;
};

export const formatSodium = value => {
  // <5 mg, is zero
  // 5-140 mg, nearest 5 mg
  // >140 mg, nearest 10 mg
  if (!value) {
    return '0mg';
  }
  let displayValue = 0;
  if (value < 5) {
    displayValue = 0;
  } else if (value < 140) {
    displayValue = Math.round(value / 5) * 5;
  } else {
    displayValue = Math.round(value / 10) * 10;
  }
  return `${displayValue}mg`;
};

export const formatTotalFat = (value, returnUnit = true) => {
  // <.5 g is zero
  // <5, nearest .5 g
  // >= 5, nearest 1 g
  if (value < 0 || !value) {
    return returnUnit ? '0g' : 0;
  }
  let displayValue = 0;
  if (value < 0.5) {
    displayValue = 0;
  } else if (value < 5) {
    displayValue = Math.round(value / 0.5) * 0.5;
  } else {
    displayValue = Math.round(value);
  }
  return returnUnit ? `${displayValue}g` : displayValue;
};

export const formatTransFat = value => {
  if (value < 0 || !value) {
    return '0g';
  }
  const rounded = Math.round(value);
  return `${rounded}g`;
};

export const formatVitaminD = value => {
  // <2% is zero
  // <=10%, nearest 2%
  // >10%, <=50%, nearest 5%
  // >50%, nearest 10%
  if (!value) {
    return '0%';
  }
  let percent = 0;
  const absPercent = (value / recommended.vitaminD) * 100;
  if (absPercent < 2) {
    percent = 0;
  } else if (absPercent <= 10) {
    percent = Math.round(absPercent / 2) * 2;
  } else if (absPercent <= 50) {
    percent = Math.round(absPercent / 5) * 5;
  } else {
    percent = Math.round(absPercent / 10) * 10;
  }
  return `${percent}%`;
};

export const formatPotassium = value => {
  // <2% is zero
  // <=10%, nearest 2%
  // >10%, <=50%, nearest 5%
  // >50%, nearest 10%
  let percent = 0;
  if (!value) {
    return '0%';
  }
  const absPercent = (value / recommended.potasium) * 100;
  if (absPercent < 2) {
    percent = 0;
  } else if (absPercent <= 10) {
    percent = Math.round(absPercent / 2) * 2;
  } else if (absPercent <= 50) {
    percent = Math.round(absPercent / 5) * 5;
  } else {
    percent = Math.round(absPercent / 10) * 10;
  }
  return `${percent}%`;
};
