import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '../icons/CloseIcon';
import ProfilePhoto from '../elements/ProfilePhoto';
import { isMobile } from '../helpers/utils';
import { colors } from '../styleConstants';
import DialogContent from '@material-ui/core/DialogContent';
import MobileDialog from '../sharedModals/mobile/MobileDialog';
import DefaultProfilePicture from '../icons/DefaultProfilePicture';
import { connect } from 'react-redux';
import { updateUser } from '../store/actions/general';
import { pick } from 'lodash';
import useFormValues from '../hooks/useFormValues';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '../elements/Button';
import AlertStack from '../elements/AlertStack';

const UserPhotoUpload = ({ open, handleClose, ...props }) => {
  const inputFile = useRef();
  const [avatarUrl, setAvatarUrl] = useState(props.currentUser.avatarUrl);
  const [isActionTextDisabled, setIsActionTextDisabled] = useState(false);
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [removeImgLoading, setRemoveImgLoading] = useState(false);

  const { values, setValues, handleChange } = useFormValues(props.currentUser);

  useEffect(() => {
    setValues(props.currentUser);
  }, [props.currentUser]);

  useEffect(() => {
    if (open) {
      setIsActionTextDisabled(!avatarUrl);
      if (!avatarUrl) {
        setRemoveImgLoading(true);
      } else {
        setLoading(true);
      }
      props
        .updateUser(
          !avatarUrl ? { ...values, avatarUrl: '', avatar: null } : values
        )
        .then(result => {
          setStatus('success');
          setMessage(null);
          setLoading(false);
          setRemoveImgLoading(false);
          handleClose();
        })
        .catch(err => {
          setStatus('error');
          setMessage('There was a problem updating your profile.');
          setLoading(false);
          setRemoveImgLoading(false);
          console.error(err);
        });
    }
  }, [avatarUrl]);

  const handleChangeAvatar = async e => {
    e.preventDefault();
    if (e.target.files[0]) {
      let reader = new FileReader();
      const imageFile = e.target.files[0];
      reader.onloadend = () => {
        const image = reader.result;
        setAvatarUrl(image);
      };
      reader.readAsDataURL(imageFile);
      setAvatarUrl(URL.createObjectURL(e.target.files[0]));
      handleChange(e);
    }
  };

  const handleUploadPicture = e => {
    e.stopPropagation();
    e.preventDefault();
    inputFile.current.click();
  };

  const handleRemovePicture = e => {
    e.preventDefault();
    setAvatarUrl('');
  };

  if (isMobile()) {
    // Mobile view
    return (
      <MobileDialog
        open={open}
        handleClose={handleClose}
        height="fit-content"
        minheight="65%"
        small="small"
        data-test="mobile-modal"
      >
        <DialogContainer flexDirection="column" alignItems="center">
          <Puller />
          <TopContainer flexDirection="column" alignItems="center">
            <Heading>Edit Profile Photo</Heading>
            <input
              hidden
              type="file"
              accept="image/*"
              onChange={handleChangeAvatar}
              ref={inputFile}
              onClick={e => e.stopPropagation()}
              name="avatar"
              data-test="avatar-modal-input"
            />
            {avatarUrl ? (
              <AvatarFieldGroup name="left">
                <ProfilePhoto
                  src={avatarUrl}
                  onClick={handleUploadPicture}
                  data-test="avatar-modal-photo"
                />
              </AvatarFieldGroup>
            ) : (
              <DefaultProfilePicture onClick={handleUploadPicture} />
            )}
          </TopContainer>
          <ButtonContainer>
            <StyledButton
              handleClick={handleUploadPicture}
              buttonText={'upload new photo'}
              pink="true"
              startIcon={
                loading && <CircularProgress size={16} color="inherit" />
              }
              data-test="avatar-modal-button"
            />
            <StyledButton
              handleClick={handleRemovePicture}
              type="submit"
              pink="true"
              variant="outlined"
              buttonText={'remove image'}
              disabled={isActionTextDisabled}
              startIcon={
                removeImgLoading && (
                  <CircularProgress size={16} color="inherit" />
                )
              }
              data-test="avatar-modal-actionText"
            />
            {status === 'error' && (
              <AlertStack
                messages={message}
                type="error"
                variant="filled"
                open={status === 'error'}
                handleClose={() => setStatus('hidden')}
                autoHideDuration={10000}
                data-test="upload-error-message"
              />
            )}
          </ButtonContainer>
        </DialogContainer>
      </MobileDialog>
    );
  }
  return (
    // Web View
    <div>
      <Modal open={open} onClose={handleClose}>
        <StyledDialogContent>
          <Container
            justify="center"
            flexDirection="column"
            alignItems="center"
          >
            <StyledCloseIcon handleClose={handleClose} />
            <Heading>Edit Profile Photo</Heading>
            <input
              hidden
              type="file"
              accept="image/*"
              onChange={handleChangeAvatar}
              ref={inputFile}
              onClick={e => e.stopPropagation()}
              name="avatar"
              data-test="avatar-modal-input"
            />
            {avatarUrl ? (
              <AvatarFieldGroup name="left">
                <ProfilePhoto
                  src={avatarUrl}
                  onClick={handleUploadPicture}
                  data-test="avatar-modal-photo"
                />
              </AvatarFieldGroup>
            ) : (
              <StyledDefaultPhoto onClick={handleUploadPicture} />
            )}
            <ButtonContainer>
              <StyledButton
                handleClick={handleUploadPicture}
                buttonText={'upload new photo'}
                pink="true"
                startIcon={
                  loading && <CircularProgress size={16} color="inherit" />
                }
                data-test="avatar-modal-button"
              />
              <StyledButton
                handleClick={handleRemovePicture}
                type="submit"
                pink="true"
                variant="outlined"
                buttonText={'remove image'}
                disabled={isActionTextDisabled}
                startIcon={
                  removeImgLoading && (
                    <CircularProgress size={16} color="inherit" />
                  )
                }
                data-test="avatar-modal-actionText"
              />
              {status === 'error' && (
                <AlertStack
                  messages={message}
                  type="error"
                  variant="filled"
                  open={status === 'error'}
                  handleClose={() => setStatus('hidden')}
                  autoHideDuration={10000}
                  data-test="upload-error-message"
                />
              )}
            </ButtonContainer>
          </Container>
        </StyledDialogContent>
      </Modal>
    </div>
  );
};

const StyledDialogContent = styled(DialogContent)`
  width: 372px;
  height: 485px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const Container = styled(FlexContainer)`
  position: relative;
  height: 100%;
  padding: 0 54px;
  background-color: white;
  border-radius: 4px;
  outline: 0;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const AvatarFieldGroup = styled(FlexContainer)`
  width: ${props => (isMobile() ? '144px' : '160px')};
  height: ${props => (isMobile() ? '144px' : '160px')};
  cursor: pointer;
  border-radius: 8px;
`;

const StyledDefaultPhoto = styled(DefaultProfilePicture)`
  width: 160px;
  height: 160px;
  cursor: pointer;
`;

/* Mobile styles */

const DialogContainer = styled(FlexContainer)`
  height: 100%;
  position: relative;
  padding: 16px;
  background-color: #fbfbfb;
  background-image: url(${process.env.PUBLIC_URL}/Bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
`;
const Puller = styled.div`
  width: 46px;
  height: 3px;
  background-color: ${colors.primary100};
  border-radius: 16px;
  position: absolute;
  top: 8px;
`;
const TopContainer = styled(FlexContainer)`
  padding-top: 32px;
`;

const Heading = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: ${colors.primary800};
  width: 167px;
  margin-bottom: 16px;
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > * {
    margin-top: 8px;
  }
`;
const StyledButton = styled(Button)`
  height: 40px;
  border-radius: 2px;

  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  &:hover {
    cursor: pointer;
  }
`;

function mapStateToProps(state) {
  const { currentUser } = state;
  const fields = ['id', 'avatarUrl'];
  const user = pick(currentUser.user, fields);
  return { currentUser: user };
}

export default connect(
  mapStateToProps,
  { updateUser }
)(UserPhotoUpload);
