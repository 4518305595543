import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import { default as MaterialButton } from '@material-ui/core/Button';
import WaterIcon from '../icons/WaterIcon';
import { isMobile } from '../helpers/utils';

const WaterButton = ({
  color,
  increment,
  unit,
  water,
  recordWater,
  ...props
}) => {
  const iconColor =
    color === colors.secondary500 ? colors.secondary600 : '#45B0E3';
  const hoverColor =
    color === colors.secondary500 ? colors.secondary400 : colors.hliteone300;

  const handleClick = () => {
    recordWater(water, increment);
  };

  return (
    <StyledButton
      bgcolor={color}
      hovercolor={hoverColor}
      onClick={handleClick}
      data-test={`add-water-${Math.abs(increment)}`}
    >
      <WaterIcon color={iconColor} size={isMobile() ? 'large' : 'small'} />
      <Text data-test="water-increment-value">{`${increment} ${unit}`}</Text>
    </StyledButton>
  );
};

const StyledButton = styled(MaterialButton)`
  width: ${() => (isMobile() ? '148px' : '120px')};
  height: ${() => (isMobile() ? '60px' : '48px')};
  padding: ${() => (isMobile() ? '6px 4px' : '6px')};
  gap: 16px;
  border-radius: 48px;
  background-color: ${props =>
    props.bgcolor ? props.bgcolor : colors.hliteone400};
  justify-content: flex-start;
  text-transform: none;

  &:hover {
    background-color: ${props =>
      props.bgcolor === colors.secondary500 ? colors.secondary600 : '#45B0E3'};
  }
`;

const Text = styled.p`
  font-size: ${() => (isMobile() ? '22px' : '16px')};
  line-height: ${() => (isMobile() ? '24px' : '18px')};
  color: #fff;
  margin-left: 8px;
`;

export default WaterButton;
