import React from 'react';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import RecipeSample from '../samples/RecipeSample';
import WorkoutSample from '../samples/WorkoutSample';

const SamplesContainer = () => {
  return (
    <ContentContainer>
      <ScrollContainer data-test="scroll-container">
        <Switch>
          <Route
            path="/samples/recipe/:id"
            render={({ match }) => <RecipeSample recipeId={match.params.id} />}
          />
          <Route
            path="/samples/workout/:id"
            render={({ match }) => (
              <WorkoutSample workoutId={match.params.id} />
            )}
          />
        </Switch>
      </ScrollContainer>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  height: 100%; /*allows both columns to span the full height of the browser window*/
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const ScrollContainer = styled.div`
  height: 100%;
`;

export default SamplesContainer;
