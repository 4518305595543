import React, { useState, useEffect } from 'react';
import { capitalize, uniqWith, isEqual, filter } from 'lodash';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import MobileDialog from '../sharedModals/mobile/MobileDialog';
import MobileDialogHeader from '../sharedModals/mobile/MobileDialogHeader';
import StaticSearchBar from '../elements/StaticSearchBar';
import Button from '../elements/Button';
import Chip from '../elements/Chip';
import ChipDeleteIcon from '../icons/ChipDeleteIcon';
import { searchFoods } from '../services/api/food';
import { getHighlightedText } from '../helpers/utils';

const MobileSearchModal = ({
  open,
  title,
  initialSearchTerm,
  handleClose,
  value,
  setValue,
  ...props
}) => {
  const [modalValue, setModalValue] = useState([]);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [options, setOptions] = useState([]);

  const handleSearch = async (searchValue = searchTerm) => {
    const params = {
      query: searchValue,
      limit: 200,
    };
    const results = await searchFoods(params);
    setOptions(results);
    if (searchTerm !== searchValue) {
      // ensure correct terms are highlighted
      setSearchTerm(searchValue);
    }
  };

  useEffect(() => {
    if (initialSearchTerm) {
      handleSearch();
    }
  }, []);

  const handleSelectIngredient = (event, newValue) => {
    if (options.length > 0) {
      setModalValue(uniqWith([...modalValue, newValue], isEqual));
    }
  };

  const handleDelete = item => {
    setModalValue(filter(modalValue, v => v.id !== item.id));
  };

  const handleAccept = () => {
    setValue(modalValue); // replaces previous values
    // the idea behind this is the modal only cares about the values in
    // its current session. The page that calls the modal can manage
    // separate state to drive its UI elements.
    setModalValue([]);
    handleClose();
  };

  const optionsList = options.map(option => {
    return (
      <OptionLabel
        key={option._id}
        onClick={e => handleSelectIngredient(e, option)}
        data-test="searchModal-ingredient"
      >
        {getHighlightedText(capitalize(option.verboseName), searchTerm)}
      </OptionLabel>
    );
  });

  return (
    <MobileDialog
      open={open}
      height={'85%'}
      onClose={handleClose}
      data-test="mobile-searchModal"
    >
      <MobileDialogHeader title={title} handleBackBtnClick={handleClose} />
      <ContentContainer flexDirection="column">
        <SearchBarContainer flexDirection="column">
          <Subheading>Search Ingredients</Subheading>
          <StaticSearchBar
            handleSearch={handleSearch}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            data-test="searchModal-ingSearch"
          />
        </SearchBarContainer>
        <ChipContainer flexDirection="row" wrap="wrap">
          {modalValue.map((option, index) => (
            <Chip
              key={index.toString()}
              selected={true}
              label={props.getOptionLabel(option)}
              handleDelete={() => handleDelete(option)}
              data-test="searchModal-ingChip"
              small="true"
              deleteIcon={<ChipDeleteIcon style={{ padding: 4 }} />}
            />
          ))}
        </ChipContainer>
        <OptionsContainer flexDirection="column">
          {optionsList}
        </OptionsContainer>
        <ButtonContainer justify="center" alignItems="center">
          <Button
            width="240px"
            pink="true"
            buttonText="Add Ingredients"
            buttonSize="large"
            handleClick={handleAccept}
            data-test="searchModal-add-ingredients"
          />
        </ButtonContainer>
      </ContentContainer>
    </MobileDialog>
  );
};

const SearchBarContainer = styled(FlexContainer)`
  width: 100%;
  margin-top: 24px;
`;

const ContentContainer = styled(FlexContainer)`
  height: 90%;
  padding: 0 20px;
`;

const ChipContainer = styled(FlexContainer)`
  padding-top: 16px;

  > * {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;
const ButtonContainer = styled(FlexContainer)`
  width: 100%;
  height: 72px;
  position: absolute;
  bottom: 64px;
  left: 0%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.15);
`;
const OptionsContainer = styled(FlexContainer)`
  overflow-y: scroll;
  height: 75%;
  > * {
    border-bottom: 1px solid ${colors.primary300};
    padding: 14px 0;
  }
`;
const OptionLabel = styled.h4`
  color: ${colors.primary600};
`;
const Subheading = styled.h3`
  color: ${colors.primary800};
  margin-bottom: 16px;
`;

export default MobileSearchModal;
