import React, { useState } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';
import AlertStack from '../../elements/AlertStack';

import UserSearch from './UserSearch';
import CircularProgress from '@material-ui/core/CircularProgress';

import { manualAssignTrack } from '../../services/api/track';
import TrackToAssign from './TrackToAssign';

const AssignTrack = ({ type, ...props }) => {
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();
  const [trackToAssign, setTrackToAssign] = useState({ _id: '' });

  const assignTrack = () => {
    setLoading(true);

    manualAssignTrack(user._id, trackToAssign._id)
      .then(result => {
        setStatus('success');
        setMessage(
          `Track ${trackToAssign.name} has been assigned to user ${user.fullName}`
        );
        setTrackToAssign({ _id: '' });
        setUser(null);
      })
      .catch(err => {
        setStatus('error');
        if (err.errors && err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClick = e => {
    assignTrack();
  };

  return (
    <Container flexDirection="column">
      <UserSearch setUser={setUser} />
      {user && (
        <TrackContainer flexDirection="column">
          <TrackToAssign setTrackToAssign={setTrackToAssign} />

          <Button
            type="submit"
            disabled={!user || loading}
            buttonText="Assign Track"
            buttonSize="large"
            handleClick={handleClick}
            startIcon={
              loading && <CircularProgress size={24} color="inherit" />
            }
          />
        </TrackContainer>
      )}
      {status === 'error' ? (
        <AlertStack
          messages={message}
          type="error"
          variant="filled"
          open={status === 'error'}
          handleClose={() => setStatus(null)}
          autoHideDuration={20000}
          data-test="track-message-error"
        />
      ) : status === 'success' ? (
        <AlertStack
          messages={message}
          type="success"
          variant="filled"
          open={status === 'success'}
          handleClose={() => setStatus(null)}
          autoHideDuration={20000}
          data-test="track-message-success"
        />
      ) : null}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

const TrackContainer = styled(FlexContainer)`
  > * {
    margin: 12px 0;
  }
`;

export default AssignTrack;
