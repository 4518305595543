import React from 'react';
import { isSafari } from '../helpers/generateCsv';

const GraphLabel = ({ x, y, dx, dy, text, child, ...props }) =>
  isSafari() ? (
    <g>
      <circle r="10" cx={dx + 50} cy={dy + 49} fill="#fff" />
      <text
        x={x}
        y={y + 1}
        dx={dx}
        dy={dy}
        fill="#484848"
        alignmentBaseline="middle"
        textAnchor="middle"
        style={{
          fontSize: '7px',
          fontWeight: 'bold',
          color: '#484848',
        }}
      >
        {text}
      </text>
    </g>
  ) : (
    <g {...props}>
      <defs>
        <filter
          id="rounded-corners"
          x="-30%"
          width="160%"
          y="-50%"
          height="200%"
        >
          <feFlood floodColor="#FFF" />
          <feGaussianBlur stdDeviation="2" />
          <feComponentTransfer>
            <feFuncA type="table" tableValues="0 0 0 1" />
          </feComponentTransfer>

          <feComponentTransfer>
            <feFuncA type="table" tableValues="0 1 1 1 1 1 1 1" />
          </feComponentTransfer>
          <feComposite operator="over" in="SourceGraphic" />
        </filter>
      </defs>

      <text
        filter="url(#rounded-corners)"
        x={x}
        y={y}
        dx={dx}
        dy={dy}
        fill="#484848"
        alignmentBaseline="middle"
        textAnchor="middle"
        style={{
          fontSize: '7px',
          fontWeight: 'bold',
          color: '#484848',
        }}
      >
        {text}
      </text>
    </g>
  );

export default GraphLabel;
