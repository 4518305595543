import React from 'react';

function AddIconGreen() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke="#16B8BA" strokeWidth="2.5" transform="translate(-166 -789)">
          <g transform="translate(0 92)">
            <g transform="translate(0 687)">
              <g transform="translate(168 12)">
                <circle cx="20" cy="20" r="20" fill="#FFF"></circle>
                <path strokeLinecap="round" d="M10 20L30 20"></path>
                <path strokeLinecap="round" d="M20 10L20 30"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AddIconGreen;
