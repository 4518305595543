import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import { isMobile } from '../../helpers/utils';

import Exercise from './Exercise';
import { getWorkoutSequenceTitle } from '../../helpers/workout';

const WorkoutSection = ({
  sectionId,
  rest,
  exercises,
  removeExercise,
  variant,
  ...props
}) => {
  const exerciseList = exercises.map((exercise, index) => (
    <Exercise key={exercise._id} exercise={exercise} {...props} />
  ));

  return (
    <Section flexDirection="column">
      {isMobile() ? (
        <MobileSectionTitle>
          {getWorkoutSequenceTitle(sectionId)}
        </MobileSectionTitle>
      ) : (
        <SectionTitle>{getWorkoutSequenceTitle(sectionId)}</SectionTitle>
      )}
      <ExerciseContainer
        flexDirection="column"
        data-test="workoutModal-exerciseList"
      >
        {exerciseList}
      </ExerciseContainer>
    </Section>
  );
};

const Section = styled(FlexContainer)`
  border-top: ${isMobile() ? 'none' : `0.5px solid ${colors.primary400}`};
  padding: ${isMobile() ? '20px 0 0 0' : '16px 0 16px 0'};
`;

const SectionTitle = styled.h4`
  color: ${colors.primary600};
  text-transform: uppercase;
`;

const ExerciseContainer = styled(FlexContainer)`
  > * {
    width: 100%;
    background-color: white;
  }
`;
const MobileSectionTitle = styled.h6`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;
export default WorkoutSection;
