import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import { Kitchen } from '@material-ui/icons';

const LeftoverIcon = ({ selected, toggleSelect, ...props }) => (
  <Container>
    <Icon
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      selected={selected}
      onClick={toggleSelect}
      data-test="toggle-leftover"
    >
      <g id="UI" strokeWidth="1" fillRule="evenodd">
        <g
          id="Fitness-Plan"
          transform="translate(-319.000000, -596.000000)"
          strokeWidth="1.5"
        >
          <g id="Add-Icon-Green" transform="translate(320.000000, 597.000000)">
            <circle id="Oval-3" cx="12" cy="12" r="12"></circle>
          </g>
        </g>
      </g>
    </Icon>
    <StyledKitchen selected={selected} />
  </Container>
);

const Container = styled.div`
  position: relative;
  width: 26px;
  height: 26px;
`;

const Icon = styled.svg`
  width: 26px;
  height: 26px;
  position: relative;

  & circle {
    fill: ${props => (props.selected ? colors.secondary500 : '#fff')};
    stroke: ${colors.secondary500};
  }

  &:hover {
    cursor: pointer;
  }

  &:hover circle {
    fill: ${props =>
      props.selected ? colors.secondary500 : colors.secondary100};
  }

  &:focus circle {
    fill: ${colors.secondary100};
  }

  &:active circle {
    fill: ${colors.secondary500};
  }

  &:disabled circle {
    fill: none;
    stroke: ${colors.primary300};
  }
  &:disabled path {
    fill: none;
    stroke: ${colors.primary300};
  }
`;

const StyledKitchen = styled(Kitchen)`
  position: absolute;
  top: 1px;
  left: 3px;
  width: 75%;
  color: ${props => (props.selected ? '#fff' : colors.secondary500)};
  pointer-events: none;

  ${Container}:active & {
    color: #fff;
  }
`;

export default LeftoverIcon;
