import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';

const DropdownArrowIcon = ({ small, rotate, ...props }) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    small={small}
    color={props.color}
    iconRotate={rotate}
  >
    <path d="M2 8l10 10 10-10z" />
    {/* <path d="M0 0h24v24H0z" fill="none"/> */}
  </Icon>
);

//d="M7 10l5 5 5-5z"
const Icon = styled.svg`
  width: ${props => (props.small ? '13px' : '20px')};
  height: ${props => (props.small ? '13px' : '20px')};
  text-align: center;
  vertical-align: middle;

  transform: ${props => (props.iconRotate ? 'rotate(180deg)' : 'inherit')};

  & path {
    fill: ${props =>
      props.color === 'teal' ? colors.secondary400 : colors.primary300};
  }

  &:hover {
    cursor: pointer;
  }
`;

export default DropdownArrowIcon;
