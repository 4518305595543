import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';

import { isEmpty } from 'lodash';

import useFormValues from '../../hooks/useFormValues';
import useToggles from '../../hooks/useToggles';
import useModal from '../../hooks/useModal';

import ModalContainer from '../../sharedModals/ModalContainer';
import RecipeForm from './RecipeForm';

import { getRecipe } from '../../services/api/recipe';
import { convertNutrients } from '../../helpers/units';

const ViewRecipe = props => {
  const initialValues = {
    nutrients: {},
  };
  const initialToggleValues = {
    active: false,
    placeholder: false,
    dietType: {},
    intolerances: {},
    mealType: {},
    cookingStyle: {},
    primaryIngredient: {},
    prepTime: {},
  };

  const { values, setValues } = useFormValues(initialValues);
  const { toggleValues, handleToggle, setToggleValues } = useToggles(
    initialToggleValues
  );
  const [ingredientsValue, setIngredientsValue] = useState([]);
  const [foods, setFoods] = useState([]);
  const [calculatedNutrients, setCalculatedNutrients] = useState({});
  const [imageUrl, setImageUrl] = useState();
  const instruction = { num: '', step: '' };
  const [instructionsValue, setInstructionsValue] = useState([
    { ...instruction },
  ]);
  const { open, launchModal, closeModal } = useModal(false);
  const [recipe, setRecipe] = useState({});

  async function loadRecipe(params) {
    try {
      const recipeId = params.recipe;
      const result = await getRecipe(recipeId);
      if (result) {
        setRecipe(result);
      } else {
        throw new Error('Recipe could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded recipe
  useEffect(() => {
    if (!isEmpty(recipe)) {
      setValues({
        attrLink: recipe.attrLink,
        cookTime: recipe.cookTime,
        expertTip: recipe.expertTip,
        imageUrl: recipe.imageUrl,
        name: recipe.name,
        nutrients: recipe.nutrients,
        prepTime: recipe.prepTime,
        servingUnit: recipe.servingUnit,
        servingWeight: recipe.servingWeight,
        source: recipe.source,
        servingSize: recipe.servingSize,
        yield: recipe.yield,
        version: recipe.version || 'N/A',
      });

      const toggleTmp = {
        active: recipe.active,
        placeholder: recipe.placeholder,
        dietType: {},
        intolerances: {},
        mealType: {},
        cookingStyle: {},
        primaryIngredient: {},
        prepTime: {},
      };

      const tags = recipe.tags;
      for (let tagType in tags) {
        tags[tagType].forEach(tagVal => {
          toggleTmp[tagType][tagVal] = true;
        });
      }
      setToggleValues(toggleTmp);
      setImageUrl(recipe.imageUrl);
      setInstructionsValue([...recipe.cookingInstructions]);
      const foodsCopy = [];
      recipe.foods.forEach(food => {
        const quantity = food.quantity * recipe.yield;
        const convertedNutrients = convertNutrients(
          food.food.nutrientsPerUnit,
          food.food.gramWeightPerUnit,
          food.measureUnit,
          food.food.measureUnit
        );

        foodsCopy.push({
          quantity: quantity,
          measureUnit: food.measureUnit,
          food: food.food.id,
          name: food.food.name,
          verboseName: food.food.verboseName,
          gramWeight: food.food.gramWeight,
          gramWeightPerUnit: food.food.gramWeightPerUnit,
          nutrientsPerUnit: convertedNutrients,
          isDeleted: food.food.isDeleted,
        });
      });
      setFoods(foodsCopy);
    }
  }, [recipe]);

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Recipe"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
        data-test="recipe-find"
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          hide: 'food',
        }}
        initialScreen="Meal Search"
        handleClose={closeModal}
        addRecipe={loadRecipe}
        {...props}
      />
      <RecipeForm
        imageUrl={imageUrl}
        values={values}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        ingredientsValue={ingredientsValue}
        setIngredientsValue={setIngredientsValue}
        instructionsValue={instructionsValue}
        foods={foods}
        calculatedNutrients={calculatedNutrients}
        setCalculatedNutrients={setCalculatedNutrients}
        viewOnly={true}
        formType="view"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default ViewRecipe;
