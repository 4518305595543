import { SET_INGREDIENTMAPS, SET_SHOPPING_LIST } from '../actionTypes';

const DEFAULT_STATE_SHOPPING_LIST = {
  id: null,
  user: '',
  recipeOverrides: [],
  foodOverrides: [],
  shoppingList: [],
  shoppingListRecipes: [],
};

const DEFAULT_STATE_INGREDIENTMAPS = [];

export const shoppingList = (state = DEFAULT_STATE_SHOPPING_LIST, action) => {
  switch (action.type) {
    case SET_SHOPPING_LIST:
      return {
        ...state,
        ...action.shoppingList,
      };

    default:
      return state;
  }
};

export const ingredientMaps = (
  state = DEFAULT_STATE_INGREDIENTMAPS,
  action
) => {
  switch (action.type) {
    case SET_INGREDIENTMAPS:
      return [...action.maps];
    default:
      return state;
  }
};
