import rootReducer from './rootReducer';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

export function configureStore() {
  const composeEnhancers = composeWithDevTools({
    trace: false, // Change to true if you need to trace origin of Redux action
    // traceLimit: 25,
  });
  const store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(thunk)
      // other store enhancers if any
    )
  );

  return store;
}
