import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';

import { isEmpty } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useModal from '../../hooks/useModal';
import useToggles from '../../hooks/useToggles';
import ModalContainer from '../../sharedModals/ModalContainer';

import FitnessPlanForm from '../fitnessPlan/FitnessPlanForm';
import { getFitnessPlan } from '../../services/api/fitnessPlan';

const FitnessPlanToAssign = ({ setPlanToDuplicate, ...props }) => {
  const initialValues = {
    locationType: 'Gym',
    gender: 'All',
    dayNum: {
      value: 1,
      label: `Monday`,
    },
    type: 'Weight Loss',
    level: 'Beginner',
    planLength: 7,
  };

  const initialToggleValues = {
    active: false,
  };

  const {
    values,
    setValues,
    handleChange,
    handleDateChanged,
    handleValueChanged,
  } = useFormValues(initialValues);

  const { toggleValues, handleToggle, setToggleValues } = useToggles(
    initialToggleValues
  );

  const { open, launchModal, closeModal } = useModal(false);

  const [workouts, setWorkouts] = useState([]);
  const [activities, setActivities] = useState([]);

  const [filteredWorkouts, setFilteredWorkouts] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);

  const [fitnessPlan, setFitnessPlan] = useState({});

  async function loadFitnessPlan(params) {
    try {
      const fitnessPlanId = params.fitnessPlan;
      const result = await getFitnessPlan(fitnessPlanId);
      if (result) {
        setFitnessPlan(result);
        setPlanToDuplicate(result._id);
      } else {
        throw new Error('Fitness Plan could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded fitness plan
  useEffect(() => {
    if (!isEmpty(fitnessPlan)) {
      setWorkouts([...fitnessPlan.workouts]);
      setActivities([...fitnessPlan.activities]);

      setValues({
        ...values,
        type: fitnessPlan.type,
        planLength: fitnessPlan.planLength,
        level: fitnessPlan.level,
        gender: fitnessPlan.gender,
        locationType: fitnessPlan.locationType,
      });
      setToggleValues({ active: fitnessPlan.active });
    }
  }, [fitnessPlan]);

  useEffect(() => {
    const filteredWorkout = workouts.filter(workout => {
      return workout.dayNum === values.dayNum.value;
    });
    const filteredActivity = activities.filter(activity => {
      return activity.dayNum === values.dayNum.value;
    });
    setFilteredWorkouts(filteredWorkout);
    setFilteredActivities(filteredActivity);
  }, [workouts, activities, values.dayNum]);

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Fitness Plan"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          showOnly: 'fitnessPlan',
        }}
        initialScreen="Exercise Search"
        handleClose={closeModal}
        addFitnessPlan={loadFitnessPlan}
        {...props}
      />
      <FitnessPlanForm
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        handleDateChanged={handleDateChanged}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        filteredWorkouts={filteredWorkouts}
        filteredActivities={filteredActivities}
        activities={activities}
        setActivities={setActivities}
        workouts={workouts}
        setWorkouts={setWorkouts}
        viewOnly={true}
        formType="view"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default FitnessPlanToAssign;
