import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';

import Exercise from './exercise/Exercise';
import AddIcon from '../icons/AddIcon';

import ModalContainer from '../sharedModals/ModalContainer';
import useModal from '../hooks/useModal';
import { isMobile } from '../helpers/utils';

const WorkoutSection = ({
  sectionTitle,
  rest,
  exercises,
  date,
  workout,
  variant,
  removeExercise,
  ...props
}) => {
  const { open, launchModal, closeModal, context } = useModal(false);

  const getDescriptionTextForSection = sectionTitle => {
    switch (sectionTitle) {
      case 'Warm Up':
        return 'Complete at your own pace';
      case 'Cool Down':
        return 'Relax and complete at your own pace';
      default:
        return 'Complete reps for each exercise in the sequence, then repeat';
    }
  };

  const handleRemove = exerciseId => {
    removeExercise(workout, exerciseId);
  };

  const exerciseList = exercises.map((exercise, i) => (
    <Exercise
      key={exercise._id}
      exercise={exercise.exercise}
      exerciseId={exercise._id}
      workout={workout}
      handleRemove={handleRemove}
      sets={exercise.sets || []}
      previousSet={exercise?.previousSet}
      date={date}
      exerciseIndex={i}
      exercisesLength={exercises.length}
      {...props}
    />
  ));

  if (!isMobile()) {
    // Web version
    return (
      <Section flexDirection="column">
        <HeaderRow justify="space-between" alignItems="center">
          <FlexContainer flexDirection="column" style={{ gap: 6 }}>
            <SectionTitle data-test="workout-card-sectionTitle">
              {sectionTitle}
            </SectionTitle>
            <SectionDescription>
              {getDescriptionTextForSection(sectionTitle)}
            </SectionDescription>
          </FlexContainer>

          <RestInfo flexDirection="column" justify="space-between">
            Rest between sets:
            <RestInfoValue
              data-test={`workout-${sectionTitle.split(' ').join('')}-rest`}
            >
              {rest} mins
            </RestInfoValue>
          </RestInfo>
        </HeaderRow>
        <ExerciseContainer
          flexDirection="column"
          data-test={`workout-${sectionTitle.split(' ').join('')}-exerciseList`}
        >
          {exerciseList}
        </ExerciseContainer>
        <BottomRow justify="center" alignItems="center">
          <StyledAddIcon
            labelText="Add Exercise"
            data-sequence={sectionTitle}
            data-date={date}
            data-workout={workout._id}
            data-variant={variant}
            data-show-only="exercise"
            orientation="row"
            pink="true"
            onClick={launchModal}
            data-test={`workout-${sectionTitle
              .split(' ')
              .join('')}-addExercise`}
          />
          <ModalContainer
            open={open}
            context={context}
            handleClose={closeModal}
            initialScreen="Add Exercise"
            {...props}
          />
        </BottomRow>
      </Section>
    );
  } else {
    // Mobile version
    return (
      <MobSection flexDirection="column">
        <HeaderRow justify="space-between" alignItems="flex-start">
          <MobTitleDescContainer flexDirection="column">
            <SectionTitle data-test="mobile-workout-sectionTitle">
              {sectionTitle}
            </SectionTitle>
            <SectionDescription>
              {getDescriptionTextForSection(sectionTitle)}
            </SectionDescription>
          </MobTitleDescContainer>
          <MobRestInfo flexDirection="column" justify="space-between">
            Rest:
            <RestInfoValue
              data-test={`workout-${sectionTitle.split(' ').join('')}-rest`}
            >
              {rest} mins
            </RestInfoValue>
          </MobRestInfo>
        </HeaderRow>
        <ExerciseContainer
          flexDirection="column"
          data-test={`workout-${sectionTitle.split(' ').join('')}-exerciseList`}
        >
          {exerciseList}
        </ExerciseContainer>
        <MobBottomRow justify="center" alignItems="center">
          <StyledAddIcon
            labelText="Add Exercise"
            data-sequence={sectionTitle}
            data-date={date}
            data-workout={workout._id}
            data-variant={variant}
            data-show-only="exercise"
            orientation="row"
            pink="true"
            hideIcon="true"
            onClick={launchModal}
            data-test={`workout-${sectionTitle
              .split(' ')
              .join('')}-addExercise`}
          />
          {open && (
            <ModalContainer
              open={open}
              context={context}
              handleClose={closeModal}
              initialScreen="Exercise Search"
              {...props}
            />
          )}
        </MobBottomRow>
      </MobSection>
    );
  }
};

/** Mobile **/
const MobTitleDescContainer = styled(FlexContainer)`
  max-width: 60%;
  gap: 6px;
`;

const MobSection = styled(FlexContainer)`
  border-top: 0.5px solid ${colors.primary400};
  padding: 31px 0 16px 0;
`;

const MobBottomRow = styled(FlexContainer)`
  width: 100%;
  margin-top: 24px;
`;

const MobRestInfo = styled(FlexContainer)`
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary400};
  padding-right: 16px;
  gap: 6px;
`;

/** Web**/

const Section = styled(FlexContainer)`
  border-top: 0.5px solid ${colors.primary400};
  padding: 16px 0 16px 0;
`;

const HeaderRow = styled(FlexContainer)`
  width: ${() => (isMobile() ? '90%' : 'calc(100% - 74px)')};
  padding-right: ${() => (isMobile() ? 'initial' : '36px')};
  padding-left: ${() => (isMobile() ? '30px' : '40px')};
`;
const BottomRow = styled(FlexContainer)`
  width: 100%;
  margin-top: 16px;
`;

const StyledAddIcon = styled(AddIcon)``;

const SectionTitle = styled.h4`
  color: ${colors.primary600};
  text-transform: uppercase;
  font-weight: bold;
`;

const SectionDescription = styled.p`
  font-size: 13px;
  line-height: 18px;
  color: ${colors.primary600};
`;

const RestInfo = styled(FlexContainer)`
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary400};
  gap: 6px;
`;

const ExerciseContainer = styled(FlexContainer)`
  > * {
    width: 100%;
  }
  padding-top: ${() => (isMobile() ? '32px' : 'initial')};
  gap: ${() => (isMobile() ? '32px' : 'initial')};
`;

const RestInfoValue = styled.div`
  color: ${colors.primary600};
`;

export default WorkoutSection;
