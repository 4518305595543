import { combineReducers } from 'redux';
import {
  currentUser,
  errors,
  selectedDate,
  selectedMealType,
  shouldAnimateSetRecord,
  circleSlug,
} from './reducers/general';

import {
  activeMealPlan,
  selectedMealPlan,
  defaultPlaceholder,
} from './reducers/mealplan';

import {
  activeFitnessPlan,
  selectedFitnessPlan,
  userTrack,
} from './reducers/fitnessplan';

import { shoppingList, ingredientMaps } from './reducers/shoppinglist';

const rootReducer = combineReducers({
  currentUser,
  circleSlug,
  errors,
  ingredientMaps,
  activeMealPlan,
  selectedMealPlan,
  activeFitnessPlan,
  selectedFitnessPlan,
  selectedDate,
  selectedMealType,
  defaultPlaceholder,
  shoppingList,
  shouldAnimateSetRecord,
  userTrack,
});

export default rootReducer;
