import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Dialog, Slide } from '@material-ui/core';
import { colors } from '../../styleConstants';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MobileDialog = ({ open, handleClose, children, ...props }) => (
  <CustomDialog
    open={open}
    onClose={handleClose}
    TransitionComponent={Transition}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
    small={props.small}
    {...props}
  >
    {children}
  </CustomDialog>
);

const CustomDialog = styled(Dialog)`
  z-index: unset !important;

  .MuiDialog-paper {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    height: ${props => props.height || '90%'};
    max-height: ${props => props.maxheight || undefined};
    min-height: ${props => props.minheight || undefined};
    border: 1px solid ${colors.primary200};
    border-radius: 25px 25px 0 0;
    background-color: #ffffff;
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.3);
    padding-bottom: 64px;
  }

  .MuiDialog-scrollPaper {
    align-items: ${props => (props.small ? 'flex-end' : 'center')};
  }
`;
export default MobileDialog;
