import React, { useEffect } from 'react';
import { pickBy, keys, reduce } from 'lodash';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import CheckBox from '../elements/CheckBox';
import Button from '../elements/Button';
import useToggles from '../hooks/useToggles';
import { connect } from 'react-redux';
import { updateUser } from '../store/actions/general';

const NotifOptions = props => {
  const { handleToggle, setToggleValues, toggleValues } = useToggles(
    props.notificationPreferences
  );

  useEffect(() => {
    setToggleValues(props.notificationPreferences);
  }, [props.notificationPreferences, setToggleValues]);

  const handleSubmit = () => {
    props.updateUser({
      id: props.userId,
      notificationPreferences: keys(pickBy(toggleValues, Boolean)),
    });
  };

  return (
    <FlexContainer flexDirection="column">
      <Option>
        <CheckBox
          checked={toggleValues.newPromos || false}
          value="newPromos"
          label="I would like to receive notifications about new promotions."
          onChange={handleToggle()}
          name="newPromos"
        />
      </Option>
      <Option>
        <CheckBox
          checked={toggleValues.mobileNotifs || false}
          value="mobileNotifs"
          label="I would like to receive mobile notifications."
          onChange={handleToggle()}
          name="mobileNotifs"
        />
      </Option>
      <Option>
        <CheckBox
          checked={toggleValues.emailNotifs || false}
          value="emailNotifs"
          label="I would like to receive email notifications."
          onChange={handleToggle()}
          name="emailNotifs"
        />
      </Option>
      <Option>
        <CheckBox
          checked={toggleValues.partnerPromos || false}
          value="partnerPromos"
          label="I would like to receive promotions from partners."
          onChange={handleToggle()}
          name="partnerPromos"
        />
      </Option>
      <ButtonContainer>
        <Button
          buttonText="Save Changes"
          buttonSize="small"
          handleClick={handleSubmit}
        />
      </ButtonContainer>
    </FlexContainer>
  );
};

const Option = styled(FlexContainer)`
  margin-bottom: 24px;
  align-items: center;
`;

const ButtonContainer = styled(FlexContainer)`
  flex-basis: content;
  float: left;
  margin-top: 24px;
`;

function mapStateToProps(state) {
  const { currentUser } = state;
  const notificationPreferences = reduce(
    currentUser.user.notificationPreferences,
    (result, value) => {
      return (result[value] = true), result; /* eslint-disable-line */
    },
    {}
  );

  return {
    userId: currentUser.user.id,
    notificationPreferences,
  };
}

export default connect(
  mapStateToProps,
  { updateUser }
)(NotifOptions);
