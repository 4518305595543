import React, { useEffect } from 'react';
import useToggles from '../hooks/useToggles';
import MobileTopNav from '../universal/MobileTopNav';
import styled from 'styled-components';
import ProfileForm from './ProfileForm';
import MyDetailsForm from './MyDetailsForm';
import ProfileCollapsible from './ProfileCollapsible';
import { isMobile } from '../helpers/utils';

const UserProfileMain = props => {
  const { toggleValues, handleToggle, setToggleValues } = useToggles();

  const initialInfo = {
    basicInfo: true,
    myDetails: isMobile() ? false : true,
  };

  useEffect(() => {
    setToggleValues(initialInfo);
  }, []);

  if (isMobile()) {
    // Mobile view
    return (
      <ProfileContainer>
        <MobileTopNav arrowsDisabled />
        <MobileCollapsibleInfoContainer>
          <ProfileCollapsible
            title="Basic Information"
            open={toggleValues.basicInfo}
            onClick={handleToggle()}
            name="basicInfo"
            children={<ProfileForm />}
          />
          <ProfileCollapsible
            title="My Details"
            open={toggleValues.myDetails}
            onClick={handleToggle()}
            name="myDetails"
            children={<MyDetailsForm />}
          />
        </MobileCollapsibleInfoContainer>
      </ProfileContainer>
    );
  } else {
    // Web view
    return (
      <ProfileContainer>
        <CollapsibleInfoContainer>
          <ProfileCollapsible
            title="Basic Information"
            open={toggleValues.basicInfo}
            onClick={handleToggle()}
            name="basicInfo"
            children={<ProfileForm />}
          />
          <ProfileCollapsible
            title="My Details"
            open={toggleValues.myDetails}
            onClick={handleToggle()}
            name="myDetails"
            children={<MyDetailsForm />}
          />
        </CollapsibleInfoContainer>
      </ProfileContainer>
    );
  }
};

const ProfileContainer = styled.div`
  padding-bottom: 24px;
`;

const MobileCollapsibleInfoContainer = styled.div`
  margin: 64px 16px;
`;

const CollapsibleInfoContainer = styled.div`
  margin: 16px;

  > * {
    margin-bottom: 16px;
  }
`;

export default UserProfileMain;
