import React, { useRef } from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';

import { without } from 'lodash';

import CircularProgress from '@material-ui/core/CircularProgress';
import TextInput from '../../elements/TextInput';
import TextBox from '../../elements/TextBox';
import RemoveXIcon from '../../icons/RemoveXIcon';
import CheckBox from '../../elements/CheckBox';
import AddImage from '../../elements/AddImage';
import Button from '../../elements/Button';
import Dropdown from '../../elements/Dropdown';
import AddIcon from '../../icons/AddIcon';
import AlertStack from '../../elements/AlertStack';
import { fitnessTags } from '../../helpers/fitnessTags';

const ExerciseForm = ({
  imageUrl,
  thumbnailUrl,
  setImageUrl,
  setThumbnailUrl,
  handleChange,
  values,
  handleValueChanged,
  instructionsValue,
  setInstructionsValue,
  toggleValues,
  handleToggle,
  status,
  setStatus,
  message,
  loading,
  handleSubmit,
  viewOnly,
  buttonText,
  formType,
  undoDelete,
  ...props
}) => {
  // Exercise Image

  const inputFile = useRef(null);
  const inputFileThumbnail = useRef(null);

  const handleChangeImage = e => {
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    handleChange(e);
  };

  const handleChangeThumbnail = e => {
    setThumbnailUrl(URL.createObjectURL(e.target.files[0]));
    handleChange(e);
  };

  const handleImage = () => {
    inputFile.current.click();
  };

  const handleThumbnail = () => {
    inputFileThumbnail.current.click();
  };

  // Instructions
  const instruction = { num: '', step: '' };
  const addInstruction = () => {
    setInstructionsValue([...instructionsValue, { ...instruction }]);
  };
  const removeInstruction = instruction => {
    setInstructionsValue(without(instructionsValue, instruction));
  };

  const handleInstructionChange = e => {
    const updatedInstructions = [...instructionsValue];
    updatedInstructions[e.target.dataset.idx]['num'] =
      parseInt(e.target.dataset.idx) + 1;
    updatedInstructions[e.target.dataset.idx][e.target.dataset.name] =
      e.target.value;
    setInstructionsValue(updatedInstructions);
  };

  const instructions = instructionsValue.map((instruction, index) => {
    const stepNum = `step-${index}`;
    return (
      <FlexContainer key={stepNum} alignItems="center">
        <TextBox
          id={stepNum}
          name={stepNum}
          data-name="step"
          data-idx={index}
          onChange={handleInstructionChange}
          value={instructionsValue[index].step || ''}
          label={`Step #${index + 1}`}
          defaultHeight="50px"
          data-test="exercise-instruction"
        />
        <StyledRemoveXIcon
          onClick={() => removeInstruction(instruction)}
          data-test="exercise-instruction-remove"
        />
      </FlexContainer>
    );
  });

  return (
    <Form onSubmit={handleSubmit}>
      <FieldSet disabled={viewOnly || false}>
        <CheckBox
          checked={toggleValues.active || ''}
          label="Active"
          onChange={handleToggle()}
          name="active"
          data-test="exercise-active"
        />
        <ImageLabel>Exercise Page Image (GIF-friendly)</ImageLabel>
        <input
          hidden
          ref={inputFile}
          type="file"
          name="image"
          onChange={handleChangeImage}
        />
        <AddImage src={imageUrl} onClick={handleImage} />
        <ImageLabel>Thumbnail Image (No GIFs!)</ImageLabel>
        <input
          hidden
          ref={inputFileThumbnail}
          type="file"
          name="thumbnail"
          onChange={handleChangeThumbnail}
        />
        <AddImage src={thumbnailUrl} onClick={handleThumbnail} />
        <TextInput
          id="exerciseName"
          name="name"
          onChange={handleChange}
          value={values.name || ''}
          placeholder="Exercise Name"
          label="Exercise Name"
          onFocus={e => (e.target.placeholder = '')}
          onBlur={e => (e.target.placeholder = 'Exercise Name')}
          data-test="exercise-name"
        />
        <Dropdown
          width="320px"
          options={[
            'Reps',
            'Reps Per Side',
            'Secs',
            'Mins',
            'Secs Per Side',
            'Mins Per Side',
          ]}
          label="Exercise Units"
          value={{
            value: values.repUnits,
            label: values.repUnits,
          }}
          onChange={value =>
            handleValueChanged('repUnits', value.value, 'string')
          }
          data-test="exercise-units"
        />
        <CheckBox
          checked={toggleValues.bodyWeight || ''}
          label="Body Weight?"
          onChange={handleToggle()}
          name="bodyWeight"
          data-test="exercise-bodyWeight"
        />
        <TextBox
          id="expertTip"
          name="expertTip"
          onChange={handleChange}
          value={values.expertTip || ''}
          placeholder="Expert Tip"
          label="Expert Tip"
          onFocus={e => (e.target.placeholder = '')}
          onBlur={e => (e.target.placeholder = 'Expert Tip')}
          data-test="exercise-expertTip"
        />
        <HR />
        <Heading>Instructions</Heading>
        <InstructionsContainer
          flexDirection="column"
          data-test="exercise-instructionsList"
        >
          {instructions}
          <AddIcon
            labelText="Add Instruction"
            orientation="row"
            pink="true"
            onClick={addInstruction}
            data-test="exercise-addInstruction"
          />
        </InstructionsContainer>
        <HR />
        <Heading>Tags</Heading>
        {fitnessTags.map((tagGroup, index) => (
          <FlexContainer
            key={index.toString()}
            flexDirection="column"
            flexShrink="0"
          >
            <Tag>{tagGroup.label}</Tag>
            <CheckContainer flexDirection="column">
              {tagGroup.tags.map((tag, tIndex) => (
                <CheckBox
                  key={tIndex.toString()}
                  checked={toggleValues[tagGroup.name][tag.name] || ''}
                  label={tag.label}
                  onChange={handleToggle(tagGroup.name)}
                  name={tag.name}
                  data-test={`exercise-${tagGroup.name}-${tag.name}`}
                />
              ))}
            </CheckContainer>
          </FlexContainer>
        ))}
      </FieldSet>
      {formType !== 'view' && (
        <Button
          type="submit"
          buttonText={buttonText || 'Create Exercise'}
          buttonSize="large"
          disabled={loading}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
          data-test="exercise-submit"
        />
      )}
      {status === 'error' ? (
        <AlertStack
          messages={message}
          type="error"
          variant="filled"
          open={status === 'error'}
          handleClose={() => setStatus(null)}
          autoHideDuration={20000}
          data-test="exercise-message-error"
        />
      ) : status === 'success' ? (
        <AlertStack
          messages={message}
          type="success"
          variant="filled"
          open={status === 'success'}
          handleClose={() => setStatus('hidden')}
          autoHideDuration={20000}
          data-test="exercise-message-success"
        />
      ) : null}
      {formType === 'delete' &&
        (status === 'success' || status === 'hidden') && (
          <Button
            buttonText="Undo"
            buttonSize="small"
            handleClick={undoDelete}
            pink="true"
            data-test="exercise-undoDelete"
          />
        )}
    </Form>
  );
};

const InstructionsContainer = styled(FlexContainer)`
  > * {
    margin-bottom: 12px;
  }
`;

const Form = styled.form`
  margin-top: 30px;
  > * {
    margin-bottom: 10px;
  }
`;

const FieldSet = styled.fieldset`
  > * {
    margin-bottom: 10px;
  }
`;

const StyledRemoveXIcon = styled(RemoveXIcon)`
  padding: 20px 0 0 12px;
`;

const HR = styled.hr`
  margin: 15px 0;
`;

const Heading = styled.h2`
  color: ${colors.primary800};
  text-align: center;
`;

const ImageLabel = styled.h4`
  color: ${colors.secondary600};
`;

const CheckContainer = styled(FlexContainer)`
  max-height: 275px;
  flex-wrap: wrap;

  > * {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;
const Tag = styled.h3`
  color: ${colors.secondary500};
  margin-bottom: 15px;
`;

export default ExerciseForm;
