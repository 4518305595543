import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';

import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ProgressProvider from './ProgressProvider';

const ProgressBadge = ({ color, size, ...props }) => {
  const valueEnd = (props.current * 100) / props.total;
  const left = Math.round(props.total - props.current);
  const eaten = Math.round(props.total - left);
  return (
    <ProgressBarContainer size={size}>
      <ProgressProvider valueStart={0} valueEnd={valueEnd}>
        {value => (
          <CircularProgressbarWithChildren
            value={value}
            styles={buildStyles({
              pathColor:
                color === 'teal' ? colors.secondary500 : colors.hliteone400,
              trailColor: `${colors.primary100}`,
            })}
          >
            <BadgeContainer flexDirection="column" alignItems="center">
              <BadgeNumber color={color} data-test="progressWheel-eatenValue">
                {eaten}
              </BadgeNumber>
              <BadgeText color={color}>Eaten</BadgeText>
              <BadgeNumber
                color={colors.primary300}
                data-test="progressWheel-leftValue"
              >
                {left}
              </BadgeNumber>
              <BadgeText>Left</BadgeText>
            </BadgeContainer>
          </CircularProgressbarWithChildren>
        )}
      </ProgressProvider>
    </ProgressBarContainer>
  );
};

const ProgressBarContainer = styled(FlexContainer)`
  height: ${props => (props.size === 'small' ? '132px' : '152px')};
  width: ${props => (props.size === 'small' ? '132px' : '152px')};
`;

const BadgeContainer = styled(FlexContainer)`
  align-self: center;
  max-width: 50%;
`;

const BadgeNumber = styled.h2`
  color: ${props =>
    props.color === 'teal'
      ? colors.secondary500
      : props.color === 'blue'
      ? colors.hliteone400
      : colors.primary300};
  text-align: center;
`;

const BadgeText = styled.p`
  color: ${props =>
    props.color === 'teal'
      ? colors.secondary500
      : props.color === 'blue'
      ? colors.hliteone400
      : colors.primary300};
  font-size: 13px;
  line-height: 15px;
  text-align: center;
`;

export default ProgressBadge;
