import axios from 'axios';
import qs from 'qs';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const API_PATH =
  process.env.REACT_APP_API_URL || 'http://localhost:8080/api/v1';

function getAccessToken() {
  return localStorage.getItem('jwtToken');
}
function getRefreshAccessToken() {
  return localStorage.getItem('jwtTokenRefresh');
}

export function getImageURL(url, options = '') {
  url = encodeURIComponent(url);
  return `${API_PATH}/img/${options}?src=${url}`;
}

axios.defaults.withCredentials = true;

// Function that will be called to refresh authorization
const refreshAuthLogic = failedRequest =>
  axios
    .post(`${API_PATH}/users/refresh`, {
      refresh_token: getRefreshAccessToken(),
    })
    .then(tokenRefreshResponse => {
      localStorage.setItem('jwtToken', tokenRefreshResponse.data.jwt);
      localStorage.setItem(
        'jwtTokenRefresh',
        tokenRefreshResponse.data.refresh_token
      );
      failedRequest.response.config.headers[
        'Authorization'
      ] = `Bearer ${tokenRefreshResponse.data.token}`;
      return Promise.resolve();
    });

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axios, refreshAuthLogic);

axios.interceptors.request.use(request => {
  request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
  return request;
});

export async function apiCall(method, path, data) {
  // To see all the requests going to the api uncomment this:
  // console.log(`API call to ${method} ${path}`, data);
  try {
    let url = `${API_PATH}${path}`.split('%').join('%25');
    if (method.toLowerCase() === 'get') {
      if (data && data.query) {
        data.query = JSON.stringify(data.query);
      }
      if (data && data.populate) {
        data.populate = JSON.stringify(data.populate);
      }
      const payloadString = qs.stringify(data);
      url = `${url}?${payloadString}`;
    }
    if (method.toLowerCase() === 'put' || method.toLowerCase() === 'post') {
      let dataURL = {};
      if (data && data.populate) {
        dataURL.populate = JSON.stringify(data.populate);
      }
      url = `${url}?${qs.stringify(dataURL)}`;
    }
    if (method.toLowerCase() === 'delete') {
      if (data && data.query) {
        data.query = JSON.stringify(data.query);
      }
      const payloadString = qs.stringify(data);
      url = `${url}?${payloadString}`;
    }
    const res = await axios[method.toLowerCase()](url, data);
    return res.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      // If the request is cancelled it's not an error.
      console.log(
        `Request for ${method} ${path} canceled, reason:`,
        err.message
      );
      throw new Error(`Axio Request Cancelled : ${err.message}`);
    } else {
      if (err.response && err.response.data) {
        throw err.response.data;
      }
      throw err;
    }
  }
}
