import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';

import IntensityIcon from '../icons/IntensityIcon';

const IntensitySet = ({ intensity, ...props }) => {
  const intensityArr = [];

  for (let i = 0; i < 5; i++) {
    if (i < intensity) {
      intensityArr.push('dark');
    } else {
      intensityArr.push('light');
    }
  }

  const iconArr = intensityArr.map((icon, index) => {
    if (icon === 'dark') {
      return (
        <IntensityIcon
          key={index}
          filled
          data-test="intensity-filled"
          color={colors.secondary600}
        />
      );
    } else {
      return <IntensityIcon key={index} />;
    }
  });

  return <IntensityContainer {...props}>{iconArr}</IntensityContainer>;
};

const IntensityContainer = styled(FlexContainer)`
  ${'' /* :not(:last-child) {
    margin-right:
  } */}
`;

export default IntensitySet;
