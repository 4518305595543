//change these to descriptive names like "primary" and "secondary"
export const colors = {
  primary050: '#f8f8f8', //primary, grey
  primary100: '#e8e8e8',
  primary200: '#d8d8d8',
  primary300: '#d0d0d0',
  primary400: '#b8b8b8',
  primary500: '#a0a0a0',
  primary600: '#808080',
  primary700: '#686868',
  primary800: '#484848',
  primary900: '#282828',
  secondary050: '#f7fbfb', //secondary, green/teal
  secondary100: '#eafff4',
  secondary200: '#bdf9ea',
  secondary300: '#5eebe1',
  secondary400: '#21ccbf',
  secondary500: '#16b8ba',
  secondary600: '#16979a',
  hliteone100: '#f1fbff', //hliteone, blue
  hliteone200: '#a7e3ff',
  hliteone300: '#78d3ff',
  hliteone400: '#55c0f3',
  hliteone500: '#0795DA',
  hlitetwo050: '#FFFBFB',
  hlitetwo100: '#fffef8', //hlitetwo, pink
  hlitetwo200: '#ffb7b7',
  hlitetwo300: '#ff9292',
  hlitetwo400: '#ff6969',
  error: '#FF6868',
  pie1: '#16979A',
  pie2: '#FFB600',
  pie3: '#FF6868',
};

export const screenSize = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '430px',
  tablet: '768px',
  laptopS: '1024px',
  laptopM: '1224px',
  laptopL: '1440px',
  desktop: '2560px',
};
