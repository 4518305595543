import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import moment from 'moment';
import MealList from './MealList';
import TodayStats from './TodayStats';
import RecommendedMealPlan from './RecommendedMealPlan';
import WaterTracker from './WaterTracker';
import { calculateMealStats } from './calculateMealStats';
import TextLink from '../elements/TextLink';
import StatsBox from '../universal/StatsBox';
// import WeeklyReportBox from '../universal/WeeklyReportBox'; TODO: Post-Beta feature
import { connect } from 'react-redux';
import { setSelectedDate } from '../store/actions/general';
import { formatDate } from '../helpers/date';
import {
  addRecipeToMealPlan,
  addFoodToMealPlan,
  updateUserMealPlan,
  setUserMealPlanAllEaten,
} from '../store/actions/mealplan';
import DateNavigator from '../elements/DateNavigator';
import UserMealPlanMobile from './UserMealPlanMobile';
import { isMobile } from '../helpers/utils';
import { trackEvent } from '../integrations/analytics';

const UserMealPlan = ({
  history,
  setSelectedDate,
  selectedDate,
  mealPlan,
  activeMealPlan,
  setUserMealPlanAllEaten,
  addFoodToMealPlan,
  addRecipeToMealPlan,
  updateUserMealPlan,
  ...props
}) => {
  const [dateValue, setDateValue] = useState(selectedDate ?? new Date());
  const [initialTabIndex, setInitialTabIndex] = useState(0);

  const {
    recipes,
    foods,
    totalCalories,
    totalCaloriesComplete,
  } = calculateMealStats(mealPlan, dateValue);

  function setMealPlanAllEaten(params) {
    trackEvent(
      `Toggle All Eaten (Meal Plan - ${isMobile() ? 'Mobile' : 'Web'})`
    );
    setUserMealPlanAllEaten(mealPlan, dateValue);
  }

  async function addRecipeToUserMealPlan(params) {
    trackEvent(`Add Recipe (Meal Plan - ${isMobile() ? 'Mobile' : 'Web'})`, {
      recipe: params.recipe,
    });
    addRecipeToMealPlan(mealPlan._id, params);
  }

  async function addFoodToUserMealPlan(params) {
    trackEvent(`Add Food (Meal Plan - ${isMobile() ? 'Mobile' : 'Web'})`, {
      food: params.food,
    });
    addFoodToMealPlan(mealPlan._id, params);
  }

  async function removeRecipeFromMealPlan(recipeId) {
    trackEvent(`Remove Recipe (Meal Plan - ${isMobile() ? 'Mobile' : 'Web'})`, {
      recipe: recipeId,
    });
    const updatedMealPlan = cloneDeep(mealPlan);
    updatedMealPlan.recipes = updatedMealPlan.recipes.filter(
      r => r._id !== recipeId
    );
    await updateUserMealPlan(mealPlan._id, updatedMealPlan);
  }

  async function removeFoodFromMealPlan(foodId) {
    trackEvent(`Remove Food (Meal Plan - ${isMobile() ? 'Mobile' : 'Web'})`, {
      food: foodId,
    });
    const updatedMealPlan = cloneDeep(mealPlan);
    updatedMealPlan.foods = updatedMealPlan.foods.filter(r => r._id !== foodId);
    await updateUserMealPlan(mealPlan._id, updatedMealPlan);
  }

  async function recordWater(waterToUpdate, increment) {
    trackEvent(`Record Water (Meal Plan - ${isMobile() ? 'Mobile' : 'Web'})`);
    const updatedMealPlan = cloneDeep(mealPlan);
    const waterArr = updatedMealPlan.water.map(water =>
      water._id === waterToUpdate._id
        ? { ...water, amount: water.amount + increment }
        : water
    );
    updatedMealPlan.water = waterArr;
    await updateUserMealPlan(mealPlan._id, updatedMealPlan);
  }

  useEffect(() => {
    const historyListener = history.listen(location => {
      if (history.action === 'PUSH') {
        localStorage.setItem(
          'mealplan_dateValue',
          JSON.stringify(formatDate(dateValue))
        );
      }
    });
    return function cleanup() {
      historyListener();
    };
  }, [dateValue]);

  useEffect(() => {
    const backwards = localStorage.getItem('mealplan_goBack');
    const fromExplore = localStorage.getItem('from_explore');
    // If navigating back to this page (e.g. from recipe page) or we are arriving here from the Explore tab,
    // we need to default to the correct meal selected for mobile contexts and return to the date selected
    // prior to navigating away
    if (backwards === 'yes' || fromExplore === 'yes') {
      localStorage.setItem('mealplan_goBack', 'no');
      const dValue = localStorage.getItem('mealplan_dateValue');
      if (dValue) {
        // adjust for time zone
        let newDate = new Date(JSON.parse(dValue));
        newDate.setDate(newDate.getUTCDate());
        setDateValue(newDate);
        setSelectedDate(formatDate(dValue));
      }
      const tabValue = localStorage.getItem('mealplan_tabValue');
      if (tabValue) {
        setInitialTabIndex(tabValue);
        localStorage.removeItem('mealplan_tabValue');
        localStorage.removeItem('from_explore');
      }
    }
  }, []);

  const momentDateValue = moment(dateValue);
  let dateText = '';
  // check for today
  if (momentDateValue.isSame(moment(), 'day')) dateText = 'Today ';
  // check for tomorrow
  if (momentDateValue.isSame(moment().add(1, 'd'), 'day'))
    dateText = 'Tomorrow ';

  let water = { amount: 0, date: dateValue, unit: 'oz' };
  if (mealPlan?.water.length) {
    water = mealPlan?.water.filter(water => {
      return water.date === formatDate(dateValue);
    })[0];
  }

  if (isMobile())
    return (
      <UserMealPlanMobile
        mealPlan={mealPlan}
        recipes={recipes}
        foods={foods}
        totalCalories={totalCalories}
        dateValue={dateValue}
        setDateValue={setDateValue}
        history={history}
        initialTabIndex={parseInt(initialTabIndex)}
        water={water}
        recordWater={recordWater}
        removeRecipe={removeRecipeFromMealPlan}
        removeFood={removeFoodFromMealPlan}
      />
    );
  return (
    <PageContainer justify="space-between">
      <LeftContainer flexDirection="column">
        <DateContainer alignItems="center" justify="space-between">
          <DateDisplayContainer>
            <DateDisplay data-test="mealPlan-date-display">
              {dateText}
              <DateToday>{momentDateValue.format('MMMM Do')}</DateToday>
            </DateDisplay>
          </DateDisplayContainer>
          <TextLink
            linkText="Log All As Eaten"
            size="small"
            onClick={setMealPlanAllEaten}
            data-test="mealPlan-logAll"
          />
        </DateContainer>
        <MealList
          foods={foods}
          mealPlan={mealPlan}
          recipes={recipes || []}
          date={formatDate(dateValue)}
          variant="mealPlan"
          addRecipe={addRecipeToUserMealPlan}
          addFood={addFoodToUserMealPlan}
          removeRecipe={removeRecipeFromMealPlan}
          removeFood={removeFoodFromMealPlan}
        />
      </LeftContainer>
      <RightContainer flexDirection="column">
        <DateContainer alignItems="center" justify="flex-end">
          <DateNavigator date={dateValue} setDate={setDateValue} />
        </DateContainer>
        <StatsBox>
          <TodayStats
            mealPlan={mealPlan}
            plannedCalories={totalCalories}
            currentCalories={totalCaloriesComplete}
          />
        </StatsBox>
        <StatsBox header="Recommended Meal Plan">
          <RecommendedMealPlan
            recommended={mealPlan.recommended}
            recommendedCalories={mealPlan.recommendedCalories}
          />
        </StatsBox>
        <StatsBox header="Daily Water Tracker" center="true">
          <WaterTracker water={water} recordWater={recordWater} />
        </StatsBox>
        {/* TODO: Post-Beta feature
        https://app.clickup.com/t/8etjmm
        <StatsBox>
          <WeeklyReportBox />
        </StatsBox> */}
      </RightContainer>
    </PageContainer>
  );
};

const PageContainer = styled(FlexContainer)`
  padding: 0 55px;
  position: relative;
`;

const DateContainer = styled(FlexContainer)`
  height: 77px;
`;

const DateToday = styled.span`
  color: ${colors.primary600};
  font-weight: 500;
`;

const DateDisplayContainer = styled(FlexContainer)``;

const DateDisplay = styled.p`
  color: ${colors.primary800};
  text-transform: uppercase;
  font-weight: bold;
`;

const LeftContainer = styled(FlexContainer)`
  flex-basis: 50%;
  margin-right: 28px;
`;

const RightContainer = styled(FlexContainer)`
  flex-basis: 330px;
  min-width: 320px;
  margin-left: 28px;
`;

function mapStateToProps(state) {
  const { currentUser, selectedMealPlan, activeMealPlan, selectedDate } = state;
  return {
    userId: currentUser.user.id,
    mealPlan: selectedMealPlan,
    activeMealPlan,
    selectedDate,
  };
}

export default connect(
  mapStateToProps,
  {
    setSelectedDate,
    setUserMealPlanAllEaten,
    addFoodToMealPlan,
    addRecipeToMealPlan,
    updateUserMealPlan,
  }
)(UserMealPlan);
