import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';

const mapState = state => ({
  isAuthenticated: state.currentUser.isAuthenticated,
  intakeComplete: state.currentUser.user.intakeComplete,
  isSubscribed: state.currentUser.user.isSubscribed,
});

const QuestionnaireRoute = props => {
  const { isAuthenticated, intakeComplete, isSubscribed } = useSelector(
    mapState
  );

  return isAuthenticated && intakeComplete && isSubscribed ? (
    <Redirect to="/app" />
  ) : isAuthenticated && isSubscribed && !intakeComplete ? (
    <PrivateRoute {...props} />
  ) : (
    <Redirect to="/login" />
  );
};

export default QuestionnaireRoute;
