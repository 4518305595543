import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';

import FitnessSection from './FitnessSection';

const FitnessList = ({ workouts, activities, ...props }) => {
  return (
    <FitnessContainer flexDirection="column">
      <FitnessSection items={workouts} sectionType="Workout" {...props} />
      <FitnessSection items={activities} sectionType="Activity" {...props} />
    </FitnessContainer>
  );
};

const FitnessContainer = styled(FlexContainer)`
  width: 100%;

  > * {
    padding-top: 8px;
    border-top: 1px solid ${colors.primary200};
    margin-bottom: 24px;
  }
`;

export default FitnessList;
