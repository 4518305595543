import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';

import { isEmpty } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useToggles from '../../hooks/useToggles';

import useModal from '../../hooks/useModal';
import ModalContainer from '../../sharedModals/ModalContainer';

import ExerciseForm from './ExerciseForm';
import { getExercise } from '../../services/api/exercise';

const ViewExercise = props => {
  const initialValues = {
    repUnits: 'Reps',
  };
  const initialToggleValues = {
    bodyWeight: false,
    active: true,
    level: {},
    location: {},
    specialtyFocus: {},
  };
  const { values, setValues, handleChange, handleValueChanged } = useFormValues(
    initialValues
  );

  const { toggleValues, handleToggle, setToggleValues } = useToggles(
    initialToggleValues
  );

  const [imageUrl, setImageUrl] = useState();
  const [thumbnailUrl, setThumbnailUrl] = useState();

  const instruction = { num: '', step: '' };
  const [instructionsValue, setInstructionsValue] = useState([
    { ...instruction },
  ]);

  const { open, launchModal, closeModal } = useModal(false);
  const [exercise, setExercise] = useState({});

  async function loadExercise(params) {
    try {
      const exerciseId = params.exercise;
      const result = await getExercise(exerciseId);
      if (result) {
        setExercise(result);
      } else {
        throw new Error('Exercise could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded exercise
  useEffect(() => {
    if (!isEmpty(exercise)) {
      setValues({
        name: exercise.name,
        repUnits: exercise.repUnits,
        imageUrl: exercise.imageUrl,
        thumbnailUrl: exercise.thumbnailUrl,
        expertTip: exercise.expertTip,
      });
      const toggleTmp = {
        bodyWeight: exercise.bodyWeight,
        active: exercise.active,
        level: {},
        location: {},
        specialtyFocus: {},
      };

      const tags = exercise.tags;
      for (let tagType in tags) {
        tags[tagType].forEach(tagVal => {
          toggleTmp[tagType][tagVal] = true;
        });
      }
      setToggleValues(toggleTmp);
      setInstructionsValue([...exercise.instructions]);
      setImageUrl(exercise.imageUrl);
      setThumbnailUrl(exercise.thumbnailUrl);
    }
  }, [exercise]);

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Exercise"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
        data-test="exercise-find"
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          showOnly: 'exercise',
        }}
        initialScreen="Exercise Search"
        handleClose={closeModal}
        addExercise={loadExercise}
        {...props}
      />
      <ExerciseForm
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        thumbnailUrl={thumbnailUrl}
        setThumbnailUrl={setThumbnailUrl}
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        instructionsValue={instructionsValue}
        setInstructionsValue={setInstructionsValue}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        viewOnly={true}
        formType="view"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default ViewExercise;
