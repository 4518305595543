export const getFormTitle = pathname => {
  return pathname === '/signup'
    ? 'Create Your Account'
    : pathname === '/reset'
    ? 'Reset Your Password'
    : pathname.match('.*/reset/.*')
    ? 'Update Your Password'
    : 'Welcome Back!';
};

export const getButtonText = pathname => {
  return pathname === '/signup'
    ? 'Sign Up Now'
    : pathname === '/reset'
    ? 'Reset Password'
    : pathname.match('.*/reset/.*')
    ? 'Update Password'
    : 'Log In';
};

export const getCTADescription = pathname => {
  return pathname === '/signup'
    ? 'Already have an account?'
    : pathname.includes('/reset')
    ? 'Remembered your password?'
    : 'Need an account?';
};

export const getLinkText = pathname => {
  return pathname === '/signup' || pathname.includes('/reset')
    ? 'Log in now'
    : 'Sign up now';
};

export const getLinkTo = pathname => {
  return pathname === '/signup' || pathname.includes('/reset')
    ? '/login'
    : '/signup';
};

export const getDataTest = pathname => {
  return pathname === '/signup' || pathname.includes('/reset')
    ? 'home-link-to-login'
    : 'home-link-to-signup';
};

export const getCTAText = pathname => {
  return pathname === '/signup' || pathname.includes('/reset')
    ? 'Before you begin your journey please take a few seconds to create your personal account with Nutriology.'
    : 'You’re just a few seconds away from accessing your Nutriology Transformation Plan';
};
