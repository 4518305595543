import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import { getImageURL } from '../services/api/api';
const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

const SectionItem = ({
  itemName,
  itemImage,
  itemLink,
  handleClick,
  ...props
}) => {
  const imgUrl = (ratio = 1) => {
    const width = 133 * ratio;
    const height = 140 * ratio;
    if (itemImage) {
      return getImageURL(
        itemImage,
        `resizing_type:fill/height:${height}/width:${width}`
      );
    } else {
      return getImageURL(
        `${fileStorage}/Nutriology_Symbol_467x334.png`,
        `resizing_type:fill/width:${width}`
      );
    }
  };
  return (
    <ItemContainer
      flexDirection="column"
      alignItems="center"
      onClick={handleClick}
      data-test="section-item"
    >
      <ItemImage image={imgUrl(1)} image2x={imgUrl(2)} />
      <ItemWrapper>
        <ItemName data-test="section-item-name">{itemName}</ItemName>
      </ItemWrapper>
    </ItemContainer>
  );
};

const ItemContainer = styled(FlexContainer)`
  width: 133px;
  min-width: 133px;
  padding: 0px 0px 8px;
  gap: 8px;
  border: 1px solid #f8f8f8;
  border-radius: 6px;
`;

const ItemImage = styled.div`
  width: 100%;
  height: 140px;
  background: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-size: cover;
  background-position: center;
  border-radius: 6px;
`;

// We need to wrap the item name into a div to add the padding there.
// Otherwise the extra padding will reveal text that has been "clipped"
// by -webkit-line-clamp: 3;
const ItemWrapper = styled.div`
  padding: 4px;
  border-radius: 4px;
  background: ${colors.primary050};
`;

const ItemName = styled.p`
  width: fit-content;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.primary700};
  background: ${colors.primary050};
  padding: 0px;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

export default SectionItem;
