import React, { useEffect, useState } from 'react';
import AuthPage from './AuthPage';
import { Redirect } from 'react-router-dom';
import { apiCall } from '../services/api/api';
import { useDispatch } from 'react-redux';

const ForgotPassWrapper = ({ match: { params }, ...props }) => {
  const dispatch = useDispatch();
  const [resetToken, setResetToken] = useState('');

  useEffect(() => {
    async function getToken() {
      try {
        const token = params.token;
        const tokenReset = await apiCall('post', `/users/reset/${token}`);
        setResetToken(tokenReset.token);
      } catch (err) {
        dispatch({ type: 'ADD_ERROR', error: 'Reset token expired' });
        return <Redirect to="/signup" />;
      }
    }
    getToken();
  }, [dispatch, params.token]);

  return <AuthPage resetToken={resetToken} {...props} />;
};

export default ForgotPassWrapper;
