import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isMobile } from '../helpers/utils';
import moment from 'moment';
import useFormValues from '../hooks/useFormValues';
import {
  getShoppingLists,
  addFoodToShoppingList as addFood,
  removeFoodFromShoppingList as removeFood,
  removeRecipeFromShoppingList as removeRecipe,
  clearCompletedFoods,
  setFoodCompleted as setComplete,
  adjustFoodQuantity,
  getFilteredShoppingLists,
} from '../store/actions/shoppinglist';
import ShoppingListWeb from './ShoppingListWeb';
import ShoppingListMobile from './ShoppingListMobile';
import { trackEvent } from '../integrations/analytics';

const ShoppingList = ({
  history,
  shoppingList,
  shoppingListId,
  recipes,
  ingredientMaps,
  getShoppingLists,
  addFood,
  removeFood,
  removeRecipe,
  clearCompletedFoods,
  setComplete,
  adjustFoodQuantity,
  getFilteredShoppingLists,
  ...props
}) => {
  const initialValues = {
    dateFrom: moment().startOf('day'),
    dateTo: moment()
      .add(7, 'days')
      .startOf('day'),
  };
  const { values, handleChange, handleValueChanged, setValues } = useFormValues(
    initialValues
  );

  // Load user's shopping list and their meal plans for the selected date range.
  useEffect(() => {
    getShoppingLists({
      startDate: moment(values.dateFrom),
      endDate: moment(values.dateTo),
    });
  }, [values.dateFrom, values.dateTo]);

  // Complete a food
  async function setFoodCompleted(foodId, completed) {
    trackEvent(
      `Set Food Complete (Shopping List - ${isMobile() ? 'Mobile' : 'Web'})`
    );
    setComplete(shoppingListId, foodId, completed, {
      startDate: moment(values.dateFrom),
      endDate: moment(values.dateTo),
    });
  }

  // Add a food
  async function addFoodToShoppingList(params) {
    trackEvent(`Add Food (Shopping List - ${isMobile() ? 'Mobile' : 'Web'})`);
    addFood(shoppingListId, {
      ...params,
      startDate: moment(values.dateFrom),
      endDate: moment(values.dateTo),
    });
  }

  // Remove a food
  async function removeFoodFromShoppingList(foodId) {
    trackEvent(
      `Remove Food (Shopping List - ${isMobile() ? 'Mobile' : 'Web'})`
    );
    removeFood(shoppingListId, foodId, {
      startDate: moment(values.dateFrom),
      endDate: moment(values.dateTo),
    });
  }

  // Remove a recipe
  async function removeRecipeFromShoppingList(recipeId) {
    trackEvent(
      `Remove Recipe (Shopping List - ${isMobile() ? 'Mobile' : 'Web'})`
    );
    removeRecipe(shoppingListId, recipeId, {
      startDate: moment(values.dateFrom),
      endDate: moment(values.dateTo),
    });
  }

  // Clear completed
  async function clearCompleted() {
    trackEvent(
      `Clear Completed (Shopping List - ${isMobile() ? 'Mobile' : 'Web'})`
    );
    clearCompletedFoods(shoppingListId, {
      startDate: moment(values.dateFrom),
      endDate: moment(values.dateTo),
    });
  }

  // Adjust quantity
  async function adjustQuantity(foodId, value) {
    trackEvent(
      `Adjust Food Quantity (Shopping List - ${isMobile() ? 'Mobile' : 'Web'})`
    );
    adjustFoodQuantity(shoppingListId, foodId, value, {
      startDate: moment(values.dateFrom),
      endDate: moment(values.dateTo),
    });
  }

  // Filter ingredients when clicking a recipe
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  const handleRecipeClick = (e, recipe) => {
    e.preventDefault();
    if (selectedRecipe?.instanceId === recipe?.instanceId) {
      setSelectedRecipe(null);
      getShoppingLists({
        startDate: moment(values.dateFrom),
        endDate: moment(values.dateTo),
      });
    } else {
      trackEvent(
        `Filter List by Recipe (Shopping List - ${
          isMobile() ? 'Mobile' : 'Web'
        })`
      );
      setSelectedRecipe(recipe);
      getFilteredShoppingLists({
        startDate: moment(values.dateFrom),
        endDate: moment(values.dateTo),
        recipeId: recipe.instanceId,
      });
    }
  };

  // Ensure we reload the full unfiltered shopping list when navigating away from the page
  useEffect(() => {
    const historyListener = history.listen(location => {
      if (history.action === 'PUSH') {
        getShoppingLists({
          startDate: moment(values.dateFrom),
          endDate: moment(values.dateTo),
        });
      }
    });

    return function cleanup() {
      historyListener();
    };
  }, []);

  const categories = [
    'produce',
    'grocery',
    'protein',
    'dairy',
    'frozen',
    'other',
  ];

  if (!isMobile()) {
    // Web view
    return (
      <ShoppingListWeb
        shoppingList={shoppingList}
        recipes={recipes}
        addFoodToShoppingList={addFoodToShoppingList}
        removeFoodFromShoppingList={removeFoodFromShoppingList}
        removeRecipeFromShoppingList={removeRecipeFromShoppingList}
        adjustQuantity={adjustQuantity}
        setFoodCompleted={setFoodCompleted}
        clearCompleted={clearCompleted}
        values={values}
        handleChange={handleChange}
        handleValueChanged={handleValueChanged}
        selectedRecipe={selectedRecipe}
        handleRecipeClick={handleRecipeClick}
        categories={categories}
        userId={props.userId}
      />
    );
  } else {
    // Mobile view
    return (
      <ShoppingListMobile
        shoppingList={shoppingList}
        recipes={recipes}
        addFoodToShoppingList={addFoodToShoppingList}
        removeFoodFromShoppingList={removeFoodFromShoppingList}
        removeRecipeFromShoppingList={removeRecipeFromShoppingList}
        adjustQuantity={adjustQuantity}
        setFoodCompleted={setFoodCompleted}
        clearCompleted={clearCompleted}
        values={values}
        setValues={setValues}
        selectedRecipe={selectedRecipe}
        handleRecipeClick={handleRecipeClick}
        categories={categories}
        userId={props.userId}
      />
    );
  }
};

function mapStateToProps(state) {
  const { currentUser, ingredientMaps, shoppingList } = state;
  return {
    userId: currentUser.user.id,
    ingredientMaps,
    shoppingList: shoppingList.shoppingList,
    shoppingListId: shoppingList._id,
    recipes: shoppingList.shoppingListRecipes,
  };
}

export default connect(
  mapStateToProps,
  {
    getShoppingLists,
    addFood,
    removeFood,
    clearCompletedFoods,
    setComplete,
    adjustFoodQuantity,
    getFilteredShoppingLists,
    removeRecipe,
  }
)(ShoppingList);
