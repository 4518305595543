import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import { without } from 'lodash';

import Collapsible from 'react-collapsible';
import CollapseIconMedium from '../../icons/CollapseIconMedium';
import RemoveXIcon from '../../icons/RemoveXIcon';
import useToggles from '../../hooks/useToggles';

import SetSection from './SetSection';
import { getDisplayWeight } from '../../helpers/exercise';
import nextId from 'react-id-generator';

const Exercise = ({
  exercise,
  exerciseId,
  handleRemove,
  updateSets,
  numberOfWeeks,
  exerciseIndex,
  exercisesLength,
  ...props
}) => {
  // open/close collapsible section
  const { toggleValues, handleToggle } = useToggles({
    [exercise.exercise.name]: true,
  });
  let displayBottomLine = exerciseIndex + 1 === exercisesLength ? true : false;

  const sets = exercise.sets.map(set => ({ ...set, setId: nextId() })) || [];

  // Manage Sets

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    const n = name.split('-')[0];
    const id = name.split('-')[1];
    const i = sets.findIndex(set => set.setId === id);
    if (i !== -1) {
      const updatedSets = [...sets];
      updatedSets[i][n] = parseFloat(value);
      updateSets(exerciseId, updatedSets);
    }
  };

  const handleValueChanged = (name, value) => {
    const n = name.split('-')[0];
    const id = name.split('-')[1];
    const i = sets.findIndex(set => set.setId === id);
    if (i !== -1) {
      const updatedSets = [...sets];
      updatedSets[i][n] = parseFloat(value);
      updateSets(exerciseId, updatedSets);
    }
  };

  const addSet = newSet => {
    const updatedSets = [...sets, newSet];
    updateSets(exerciseId, updatedSets);
  };

  const removeSet = setToRemove => {
    const updatedSets = without(sets, setToRemove);
    updateSets(exerciseId, updatedSets);
  };

  const displayWeight = getDisplayWeight(exercise.exercise);

  const setSections = [];

  for (let i = 0; i < numberOfWeeks; i++) {
    let filteredSets = sets.filter(set => set.week === i + 1);

    setSections.push(
      <SetSection
        key={i}
        weekNum={i + 1}
        sets={filteredSets}
        displayWeight={displayWeight}
        addSet={addSet}
        removeSet={removeSet}
        handleChange={handleChange}
        handleValueChanged={handleValueChanged}
        repUnits={exercise.exercise.repUnits}
      />
    );
  }

  return (
    <FlexContainer
      flexDirection="column"
      style={{ position: 'relative' }}
      data-test="admin-exercise-row"
    >
      <HR />
      <ExerciseInfoContainer justify="space-between">
        <LeftContainer alignItems="center">
          <Thumbnail image={exercise.exercise.thumbnailUrl} />
          <ExerciseName deleted={exercise.exercise.isDeleted}>
            {exercise.exercise.name}
          </ExerciseName>
        </LeftContainer>
        <RightContainer alignItems="center">
          {/* <CollapseIconMedium /> */}
          {!props.viewOnly && (
            <StyledRemoveXIcon data-id={exerciseId} onClick={handleRemove} />
          )}
        </RightContainer>
      </ExerciseInfoContainer>
      <Collapsible
        overflowWhenOpen="visible"
        triggerStyle={triggerStyle}
        contentOuterClassName="contentOuter__no_padding"
        open={toggleValues[`${exercise.exercise.name}`]}
        trigger={
          <CollapseIconMedium
            small
            onClick={handleToggle()}
            open={toggleValues[`${exercise.exercise.name}`]}
            name={exercise.exercise.name}
          />
        }
      >
        {!props.viewOnly && setSections}
      </Collapsible>
      <BottomHR displayBottomLine={displayBottomLine} />
    </FlexContainer>
  );
};

const ExerciseInfoContainer = styled(FlexContainer)`
  ${'' /* width: 100%; */}
  position: relative;
  margin-left: 40px;
`;

const HR = styled.hr`
  border: none;
  height: 1px;
  width: 600px;
  margin-top: 8px;
  margin-bottom: 12px;
  background-color: ${colors.primary200};
`;

const BottomHR = styled.hr`
  border: none;
  height: 1px;
  width: 600px;
  margin-top: 8px;
  margin-bottom: 12px;
  background-color: ${colors.primary200};
  display: ${props => (props.displayBottomLine ? 'block' : 'none')};
`;

const StyledRemoveXIcon = styled(RemoveXIcon)``;

// add thumbnail field to exercise?
const Thumbnail = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
  width: 66px;
  height: 44px;
`;

const ExerciseName = styled.h4`
  color: ${props => (props.deleted ? 'red' : `${colors.primary800}`)};
`;

const LeftContainer = styled(FlexContainer)`
  > * {
    margin-right: 24px;
  }
`;
const RightContainer = styled(FlexContainer)`
  padding: 16px;

  > :first-child {
    margin-right: 16px;
  }
`;

const triggerStyle = {
  position: 'absolute',
  top: '25px',
  right: '64px',
};

export default Exercise;
