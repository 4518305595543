import React, { useState, useEffect } from 'react';
import { isMobile } from '../../helpers/utils';
import { trackEvent } from '../../integrations/analytics';
import { capitalize } from 'lodash';

import {
  searchExercises,
  getExercises as getAllExercises,
} from '../../services/api/exercise';
import {
  searchActivities,
  getActivities as getAllActivities,
} from '../../services/api/activity';
import {
  searchWorkouts,
  getWorkouts as getAllWorkouts,
} from '../../services/api/workout';
import {
  searchFitnessPlans,
  getFitnessPlans as getAllFitnessPlans,
} from '../../services/api/fitnessPlan';
import {
  searchTracks,
  getTracks as getAllTracks,
} from '../../services/api/track';
import { useSelector } from 'react-redux';
import useToggles from '../../hooks/useToggles';
import { fitnessTags } from '../../helpers/fitnessTags';
import SearchMobile from './SearchMobile';
import SearchWeb from './SearchWeb';

const Search = ({
  setScreen,
  setExercise,
  setFitnessPlan,
  setWorkout,
  context,
  setSearchTerm,
  searchTerm,
  setSearchTypeSelected,
  searchTypeSelected,
  ...props
}) => {
  const sortByName = (a, b) => b?.name - a?.name;
  const showOnly = context.showOnly;
  const pageSizeExercises = 20;
  const pageSizeActivities = 20;
  const pageSizeWorkouts = 20;
  const pageSizeFitnessPlans = 20;
  const pageSizeTracks = 20;
  const initialType =
    showOnly === 'exercise'
      ? 'exercise'
      : showOnly === 'workout'
      ? 'workout'
      : showOnly === 'both'
      ? 'both'
      : showOnly === 'fitnessPlan'
      ? 'fitnessPlan'
      : showOnly === 'track'
      ? 'track'
      : 'activity';

  const [searchType, setSearchType] = useState(initialType);
  const [exercises, setExercises] = useState([]);
  const [activities, setActivities] = useState([]);
  const [workouts, setWorkouts] = useState([]);
  const [fitnessPlans, setFitnessPlans] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [hasMoreExercises, setHasMoreExercises] = useState(false);
  const [hasMoreActivities, setHasMoreActivities] = useState(false);
  const [hasMoreWorkouts, setHasMoreWorkouts] = useState(false);
  const [hasMoreFitnessPlans, setHasMoreFitnessPlans] = useState(false);
  const [hasMoreTracks, setHasMoreTracks] = useState(false);

  const user = useSelector(state => state.currentUser.user);
  const userIsAdmin = user.isAdmin;

  const initialToggleValues = {},
    initialCollapseToggleValues = {};

  fitnessTags.forEach(tag => {
    initialToggleValues[tag.name] = {};
    initialCollapseToggleValues[tag.name] = false;
  });

  const {
    toggleValues: collapseToggleValues,
    handleToggle: handleCollapseToggle,
  } = useToggles(initialCollapseToggleValues);

  const { toggleValues, setToggleValues, handleToggle } = useToggles(
    initialToggleValues
  );

  const getExercises = page => {
    let searchFn;
    let params = {
      limit: pageSizeExercises,
      skip: pageSizeExercises * page,
    };
    if (searchQuery === '') {
      searchFn = getAllExercises;
    } else {
      params.query = searchQuery;
      searchFn = searchExercises;
    }

    searchFn(params)
      .then(results => {
        if (results.length === 0 || results.length < pageSizeExercises) {
          setHasMoreExercises(false);
        } else {
          setHasMoreExercises(true);
        }
        const filtered = userIsAdmin
          ? results
          : results.filter(item => item.active);
        if (page === 0) {
          setExercises(filtered.sort(sortByName));
        } else {
          setExercises(exercises.concat(filtered).sort(sortByName));
        }
      })
      .catch(err => {
        setHasMoreExercises(false);
        setExercises([]);
      });
  };

  const getActivities = page => {
    let searchFn;
    let params = {
      limit: pageSizeActivities,
      skip: pageSizeActivities * page,
    };
    if (searchQuery === '') {
      searchFn = getAllActivities;
    } else {
      params.query = searchQuery;
      searchFn = searchActivities;
    }

    searchFn(params)
      .then(results => {
        if (results.length === 0 || results.length < pageSizeActivities) {
          setHasMoreActivities(false);
        } else {
          setHasMoreActivities(true);
        }
        if (page === 0) {
          setActivities(results.sort(sortByName));
        } else {
          setActivities(activities.concat(results).sort(sortByName));
        }
      })
      .catch(err => {
        setHasMoreActivities(false);
        setActivities([]);
      });
  };

  const getWorkouts = page => {
    let searchFn;
    let params = {
      limit: pageSizeWorkouts,
      skip: pageSizeWorkouts * page,
    };
    if (searchQuery === '') {
      if (context.variant !== 'admin') {
        params.query = {
          gender: user.gender === 'Male' ? ['Men', 'All'] : ['Women', 'All'],
        };
      }
      searchFn = getAllWorkouts;
    } else {
      params.term = searchQuery;
      searchFn = searchWorkouts;
    }
    searchFn(params)
      .then(results => {
        if (results.length === 0 || results.length < pageSizeWorkouts) {
          setHasMoreWorkouts(false);
        } else {
          setHasMoreWorkouts(true);
        }
        const filtered = userIsAdmin
          ? results
          : results.filter(item => item.active);
        if (page === 0) {
          setWorkouts(filtered.sort(sortByName));
        } else {
          setWorkouts(workouts.concat(filtered).sort(sortByName));
        }
      })
      .catch(err => {
        setWorkouts([]);
        setHasMoreWorkouts(false);
      });
  };

  const getFitnessPlans = page => {
    let searchFn;
    let params = {
      limit: pageSizeFitnessPlans,
      skip: pageSizeFitnessPlans * page,
    };
    if (searchQuery === '') {
      searchFn = getAllFitnessPlans;
    } else {
      params.term = searchQuery;
      searchFn = searchFitnessPlans;
    }

    searchFn(params)
      .then(results => {
        if (results.length === 0 || results.length < pageSizeFitnessPlans) {
          setHasMoreFitnessPlans(false);
        } else {
          setHasMoreFitnessPlans(true);
        }
        if (page === 0) {
          setFitnessPlans(results.sort(sortByName));
        } else {
          setFitnessPlans(fitnessPlans.concat(results).sort(sortByName));
        }
      })
      .catch(err => {
        setFitnessPlans([]);
        setHasMoreFitnessPlans(false);
      });
  };

  const getTracks = page => {
    let searchFn;
    const trackType = context.trackType;
    let params = {
      limit: pageSizeTracks,
      skip: pageSizeTracks * page,
    };
    if (searchQuery === '') {
      params.query =
        {
          level: trackType?.level,
          locationType: trackType?.locationType,
          gender: trackType?.gender,
        } || {};
      searchFn = getAllTracks;
    } else {
      params.term = searchQuery;
      searchFn = searchTracks;
    }

    searchFn(params)
      .then(results => {
        if (results.length === 0 || results.length < pageSizeTracks) {
          setHasMoreTracks(false);
        } else {
          setHasMoreTracks(true);
        }
        if (page === 0) {
          setTracks(results.sort(sortByName));
        } else {
          setTracks(tracks.concat(results).sort(sortByName));
        }
      })
      .catch(err => {
        setTracks([]);
        setHasMoreTracks(false);
      });
  };

  const handleSearch = inputValue => {
    trackEvent(
      `Searched ${capitalize(searchType)} (${isMobile() ? 'Mobile' : 'Web'})`
    );
    setSearchQuery(inputValue);
    setSearchTerm(inputValue);
  };

  const handleChange = e => {
    setSearchType(e.target.value);
    setSearchTypeSelected(e.target.value);
  };

  useEffect(() => {
    if (searchTerm) {
      setSearchQuery(searchTerm);
    }
    if (searchTypeSelected) {
      setSearchType(searchTypeSelected);
    }

    switch (searchType) {
      case 'exercise':
        getExercises(0);
        break;
      case 'activity':
        getActivities(0);
        break;
      case 'workout':
        getWorkouts(0);
        break;
      case 'fitnessPlan':
        getFitnessPlans(0);
        break;
      case 'track':
        getTracks(0);
        break;
      case 'both':
        getWorkouts(0);
        getActivities(0);
        break;
      default:
        getExercises(0);
    }
  }, [searchType, searchQuery]);

  // Filters
  const filteredWorkouts = workouts.filter(item => {
    if (!item.tags) {
      return false;
    }
    for (let [key, value] of Object.entries(toggleValues)) {
      for (let vKey of Object.keys(value)) {
        if (value[vKey]) {
          if (!item?.tags[key].includes(vKey)) {
            return false;
          }
        }
      }
    }
    return true;
  });

  const clearAllFilters = () => {
    setToggleValues(initialToggleValues);
  };

  if (isMobile()) {
    // mobile version
    return (
      <SearchMobile
        searchType={searchType}
        searchTerm={searchTerm}
        searchQuery={searchQuery}
        setSearchTerm={setSearchTerm}
        handleSearch={handleSearch}
        handleChange={handleChange}
        showOnly={showOnly}
        context={context}
        clearAllFilters={clearAllFilters}
        filteredWorkouts={filteredWorkouts}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        collapseToggleValues={collapseToggleValues}
        handleCollapseToggle={handleCollapseToggle}
        setScreen={setScreen}
        exercises={exercises}
        getExercises={getExercises}
        setExercise={setExercise}
        pageSizeExercises={pageSizeExercises}
        activities={activities}
        getActivities={getActivities}
        pageSizeActivities={pageSizeActivities}
        workouts={workouts}
        setWorkout={setWorkout}
        getWorkouts={getWorkouts}
        fitnessPlans={fitnessPlans}
        setFitnessPlan={setFitnessPlan}
        getFitnessPlans={getFitnessPlans}
        tracks={tracks}
        getTracks={getTracks}
        setSearchType={setSearchType}
        setSearchTypeSelected={setSearchTypeSelected}
        hasMoreExercises={hasMoreExercises}
        hasMoreActivities={hasMoreActivities}
        hasMoreWorkouts={hasMoreWorkouts}
        {...props}
      />
    );
  } else {
    // web version
    return (
      <SearchWeb
        searchType={searchType}
        searchTerm={searchTerm}
        searchQuery={searchQuery}
        setSearchTerm={setSearchTerm}
        handleSearch={handleSearch}
        handleChange={handleChange}
        showOnly={showOnly}
        context={context}
        clearAllFilters={clearAllFilters}
        filteredWorkouts={filteredWorkouts}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        collapseToggleValues={collapseToggleValues}
        handleCollapseToggle={handleCollapseToggle}
        setScreen={setScreen}
        exercises={exercises}
        getExercises={getExercises}
        setExercise={setExercise}
        pageSizeExercises={pageSizeExercises}
        activities={activities}
        getActivities={getActivities}
        pageSizeActivities={pageSizeActivities}
        workouts={workouts}
        getWorkouts={getWorkouts}
        setWorkout={setWorkout}
        fitnessPlans={fitnessPlans}
        setFitnessPlan={setFitnessPlan}
        getFitnessPlans={getFitnessPlans}
        tracks={tracks}
        getTracks={getTracks}
        hasMoreExercises={hasMoreExercises}
        hasMoreActivities={hasMoreActivities}
        hasMoreWorkouts={hasMoreWorkouts}
        hasMoreFitnessPlans={hasMoreFitnessPlans}
        hasMoreTracks={hasMoreTracks}
        {...props}
      />
    );
  }
};

export default Search;
