import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import moment from 'moment';
import CloseIcon from '../icons/CloseIcon';
import RemoveXIcon from '../icons/RemoveXIcon';
import ExpandableButton from '../elements/ExpandableButton';
import FilterIcon from '../icons/FilterIcon';
import { getImageURL } from '../services/api/api';
import Tooltip from '@material-ui/core/Tooltip';
import { getUserMealPlans } from '../services/api/mealplan';
import useReactRouter from 'use-react-router';
import { formatDate } from '../helpers/date';

const RecipeRow = ({
  recipe,
  userId,
  isSelected,
  isFiltered,
  handleClick,
  handleRemove,
}) => {
  const { history } = useReactRouter();
  const imageUrl = (recipe, ratio = 1) => {
    const width = 110 * ratio;
    const height = 80 * ratio;
    return getImageURL(
      recipe?.imageUrl,
      `resizing_type:fill/height:${height}/width:${width}`
    );
  };

  const handleRecipeLink = async () => {
    const mealPlan = await getUserMealPlans({
      query: {
        user: userId,
        startDate: formatDate(
          moment(recipe.date)
            .utc()
            .startOf('isoWeek')
        ),
        endDate: formatDate(
          moment(recipe.date)
            .utc()
            .endOf('isoWeek')
        ),
      },
    });

    const link = `/app/mealplan/${mealPlan[0]._id}/recipe/${recipe.instanceId}`;
    history.push(link);
  };

  return (
    <RecipeRowContainer
      alignItems="center"
      isSelected={isSelected}
      isFiltered={isFiltered}
      data-test="recipeRow"
    >
      <Thumbnail
        imageUrl={imageUrl(recipe, 1)}
        imageUrl2x={imageUrl(recipe, 2)}
      >
        <StyledCloseIcon
          handleClose={() => handleRemove(recipe.instanceId)}
          showCircle={true}
          color="pink"
          size="24px"
          data-test="recipeRow-remove"
        />
        {/* TODO: Add remove functionality */}
      </Thumbnail>

      <RecipeContainer flexDirection="column">
        <RecipeName
          onClick={handleRecipeLink}
          data-test="shoppingList-recipe-name"
        >
          {recipe?.name}
        </RecipeName>
        <InfoContainer flexDirection="row" alignItems="center">
          <Tooltip title="Meal Date" placement="top-start">
            <FlexContainer alignItems="center">
              <Img src={`${process.env.PUBLIC_URL}/icons/event_note.svg`} />
              <RecipeInfo style={{ width: '80px' }} data-test="recipeRow-date">
                {moment(recipe.date)
                  .utc()
                  .format('ddd, MMM D')}
              </RecipeInfo>
            </FlexContainer>
          </Tooltip>
          <Tooltip title="Servings" placement="top-end">
            <FlexContainer alignItems="center">
              <Img src={`${process.env.PUBLIC_URL}/icons/Leftover.svg`} />
              <RecipeInfo data-test="recipeRow-yield">
                {recipe.yield}
              </RecipeInfo>{' '}
            </FlexContainer>
          </Tooltip>
        </InfoContainer>
      </RecipeContainer>
      <ButtonContainer justify="flex-end">
        <StyledExpandableButton
          buttonText={isSelected ? 'Remove Filter' : 'Filter List'}
          buttonSize="small"
          width="130px"
          fontcase="titleCase"
          fontSize="14px"
          handleClick={handleClick}
          data-test="recipeRow-button"
          icon={
            isSelected ? (
              <StyledRemoveXIcon color="#fff" hideCircle={true} />
            ) : (
              <FilterIcon
                hideCircle={true}
                color="#fff"
                containerStyle={{
                  width: '20px',
                  height: '20px',
                  position: 'absolute',
                  top: '4px',
                  left: '4px',
                }}
              />
            )
          }
        />
      </ButtonContainer>
    </RecipeRowContainer>
  );
};

const RecipeRowContainer = styled(FlexContainer)`
  margin-bottom: 16px;
  border: ${props =>
    props.isSelected ? `1px solid ${colors.primary100}` : 'initial'};
  border-radius: ${props => (props.isSelected ? '8px' : 'initial')};
  box-shadow: ${props =>
    props.isSelected ? '0px 5px 9px rgba(0, 0, 0, 0.15)' : 'initial'};
  opacity: ${props => (props.isFiltered ? '0.3' : 'initial')};
  gap: 24px;
  padding-right: 8px;
`;

const Thumbnail = styled.div`
  background-image: ${props => `url(${props.imageUrl})`};
  background-image: ${props =>
    `image-set(url(${props.imageUrl}) 1x, url(${props.imageUrl2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.imageUrl}) 1x, url(${props.imageUrl2x}) 2x )`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  min-width: 110px;
  width: 110px;
  height: 80px;
  position: relative;
`;

const RecipeName = styled.p`
  color: ${colors.primary900};
  font-size: 14px;
  line-height: 16px;

  &:hover {
    color: ${colors.secondary600};
    text-decoration: underline;
    cursor: pointer;
  }
`;

const InfoContainer = styled(FlexContainer)``;

const RecipeContainer = styled(FlexContainer)`
  width: 40%;
  gap: 8px;
`;

const ButtonContainer = styled(FlexContainer)`
  min-width: 120px;
`;

const RecipeInfo = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: ${colors.secondary600};
`;

const Img = styled.img`
  margin-right: 4px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 4px;
  right: 4px;

  visibility: hidden;

  ${Thumbnail}:hover & {
    visibility: visible;
  }
`;

const StyledExpandableButton = styled(ExpandableButton)``;

const StyledRemoveXIcon = styled(RemoveXIcon)`
  position: absolute;
  top: 1.5px;
  left: 1px;

  ${StyledExpandableButton}:hover & {
    top: 2px;
    left: 0;
    transform: translateX(-1.5px) scale(0.8);
  }
`;

export default RecipeRow;
