import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

const CardDetails = ({ disabled, onChange, ...props }) => {
  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '20px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const expiryStyle = {
    style: {
      base: {
        width: '100%',
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const cardNumberOption = {
    placeholder: 'Card number',
    showIcon: true,
    iconStyle: 'solid',
    disabled: disabled,
    style: cardStyle,
  };

  const expiryOption = {
    style: expiryStyle,
  };

  return (
    <CardDetailsContainer data-test="card-number">
      <CardNumberElement
        options={cardNumberOption}
        style={cardStyle}
        onChange={onChange}
      />
      <HR />
      <CardExpiryElementContainer data-test="card-expiry">
        <CardExpiryElement
          options={{
            style: expiryOption,
          }}
          onChange={onChange}
        />
      </CardExpiryElementContainer>

      <CardCvcElementContainer data-test="card-cvc">
        <CardCvcElement onChange={onChange} />
      </CardCvcElementContainer>
      <Break />
      <StripeIcon
        src={`${process.env.PUBLIC_URL}/icons/Powered_by_Stripe.svg`}
      />
    </CardDetailsContainer>
  );
};

const CardDetailsContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  border: 1px solid ${colors.primary200};
  border-radius: 8px;
  background-color: #ffffff;
  margin: 0;
  padding: 15px 20px;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  overflow: auto;
`;

const HR = styled.hr`
  margin: 16px 0;
  color: ${colors.primary200};
  border-top: 1px solid ${colors.primary200};
  border-bottom: none;
  border-left: none;
  border-right: none;
`;

const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`;

const CardExpiryElementContainer = styled(FlexContainer)`
  float: left;
  width: 160px;
  height: 30px;
`;

const CardCvcElementContainer = styled(FlexContainer)`
  float: left;
  width: 60px;
  height: 30px;
`;

const StripeIcon = styled.img`
  width: 110px;
  float: right;
`;

export default CardDetails;
