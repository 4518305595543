import { Userpilot } from 'userpilot';

// This is an abstraction layer to ensure if we swap out our analytics platform in the future
// we can simply swap platforms here.

export const initializeAnalytics = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    Userpilot.initialize('NX-cafcde7d');
  }
};

export const identifyUser = (userId, userInfo) => {
  if (process.env.REACT_APP_ENV === 'production') {
    Userpilot.identify(userId, userInfo);
  }
};

export const trackEvent = (eventName, eventInfo) => {
  if (process.env.REACT_APP_ENV === 'production') {
    Userpilot.track(eventName, eventInfo);
  }
};

export const trackPage = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    Userpilot.reload();
  }
};
