import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';
import { cloneDeep, isEmpty, omit } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useModal from '../../hooks/useModal';
import useFormSubmit from '../../hooks/useFormSubmit';
import ExploreBannerForm from './ExploreBannerForm';
import ModalContainer from '../../sharedModals/ModalContainer';

import {
  editExploreBanner,
  getExploreBanner,
} from '../../services/api/campaigns';

const EditExploreBanner = props => {
  const initialValues = {
    color: 'Grey',
    actionType: {
      value: 'link',
      label: 'External Link',
    },
  };
  const actionTypeMap = {
    link: 'External Link',
    addItem: 'Add Item To Plan',
    showItems: 'Show Items in Search',
  };
  const {
    values,
    setValues,
    handleChange,
    handleValueChanged,
    handleDateChanged,
  } = useFormValues(initialValues);

  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const [imageUrl, setImageUrl] = useState();
  const [modalImageUrl, setModalImageUrl] = useState();
  const { open, launchModal, closeModal } = useModal(false);
  const [exploreBanner, setExploreBanner] = useState({});
  const [itemToAdd, setItemToAdd] = useState({});
  const [itemsToShow, setItemsToShow] = useState([]);

  async function loadExploreBanner(params) {
    try {
      setMessage('');
      const exploreBannerId = params.exploreBanner;
      const result = await getExploreBanner(exploreBannerId);
      if (result) {
        setExploreBanner(result);
      } else {
        throw new Error('Explore banner could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded exploreBanner
  useEffect(() => {
    if (!isEmpty(exploreBanner)) {
      setValues({
        name: exploreBanner.name,
        title: exploreBanner.title,
        textBody: exploreBanner.textBody,
        imageUrl: exploreBanner.imageUrl,
        modalImageUrl: exploreBanner?.modalImageUrl,
        modalHeaderTitle: exploreBanner?.modalHeaderTitle,
        color: exploreBanner.color,
        actionType: {
          value: exploreBanner.actionType,
          label: actionTypeMap[exploreBanner.actionType],
        },
        link: exploreBanner.link,
      });
      setItemToAdd(exploreBanner?.itemToAdd);
      setItemsToShow(exploreBanner?.itemsToShow);
      setImageUrl(exploreBanner.imageUrl);
      setModalImageUrl(exploreBanner?.modalImageUrl);
    }
  }, [exploreBanner]);

  const getIdAndType = item => {
    let itemType = '';
    let itemId = '';
    if (item.hasOwnProperty('recipe')) {
      itemType = 'recipe';
      itemId = item.recipe;
    } else if (item.hasOwnProperty('food')) {
      itemType = 'food';
      itemId = item.food;
    } else if (item.hasOwnProperty('workout')) {
      itemType = 'workout';
      itemId = item.workout;
    } else if (item.hasOwnProperty('activity')) {
      itemType = 'activity';
      itemId = item.activity;
    }
    return { itemId, itemType };
  };

  const handleAddItem = async item => {
    const { itemId, itemType } = getIdAndType(item);
    setItemToAdd({ itemId, itemType });
  };

  const handleAddItemsToShow = item => {
    const { itemId, itemType } = getIdAndType(item);
    setItemsToShow([...itemsToShow, { itemId, itemType }]);
  };

  const clearAddItem = () => {
    setItemToAdd({});
  };

  const clearItemsToShow = () => {
    setItemsToShow([]);
  };

  const submitData = () => {
    let payload = cloneDeep(omit(values, ['actionType']));
    payload.actionType = values.actionType.value;
    if (!isEmpty(itemToAdd)) {
      payload.itemToAdd = itemToAdd;
    }
    if (!isEmpty(itemsToShow)) {
      payload.itemsToShow = itemsToShow;
    }

    setLoading(true);

    editExploreBanner(exploreBanner._id, payload)
      .then(result => {
        setStatus('success');
        setMessage(
          `Explore Banner "${result.name}" was modified successfully!`
        );
        setValues(initialValues);
        setImageUrl(null);
        setModalImageUrl(null);
        setItemToAdd({});
        setItemsToShow([]);
      })
      .catch(err => {
        console.error(err);
        if (payload.imageUrl) {
          values.imageUrl = payload.imageUrl;
        }
        if (payload.modalImageUrl) {
          values.modalImageUrl = payload.modalImageUrl;
        }
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin'
  );

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Explore Banner"
        buttonSize="large"
        width="280px"
        handleClick={launchModal}
        data-test="exploreBanner-find"
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          showOnly: 'explore',
        }}
        initialScreen="Campaign Search"
        handleClose={closeModal}
        addExploreBanner={loadExploreBanner}
        {...props}
      />
      <ExploreBannerForm
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        modalImageUrl={modalImageUrl}
        setModalImageUrl={setModalImageUrl}
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        handleDateChanged={handleDateChanged}
        status={status}
        setStatus={setStatus}
        message={message}
        loading={loading}
        handleSubmit={handleSubmit}
        handleAddItem={handleAddItem}
        itemToAdd={itemToAdd}
        handleAddItemsToShow={handleAddItemsToShow}
        itemsToShow={itemsToShow}
        clearAddItem={clearAddItem}
        clearItemsToShow={clearItemsToShow}
        viewOnly={isEmpty(exploreBanner)}
        buttonText="Edit Explore Banner"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default EditExploreBanner;
