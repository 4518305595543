import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import { uniqueId } from 'lodash';

import CircularProgress from '@material-ui/core/CircularProgress';
import Dropdown from '../../elements/Dropdown';
import NumericInput from '../../elements/NumericInput';
import Button from '../../elements/Button';
import MealList from '../../mealPlan/MealList';
import CheckBox from '../../elements/CheckBox';
import AlertStack from '../../elements/AlertStack';

import { getRecipe } from '../../services/api/recipe';
import { getFood } from '../../services/api/food';

const MealPlanForm = ({
  handleChange = () => {},
  values,
  handleValueChanged,
  handleDateChanged,
  toggleValues,
  handleToggle,
  filteredRecipes,
  filteredFoods,
  foods,
  setFoods,
  recipes,
  setRecipes,
  status,
  setStatus,
  message,
  loading,
  handleSubmit,
  viewOnly,
  buttonText,
  formType,
  undoDelete,
  ...props
}) => {
  const dayOptions = [];
  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  for (let i = 0; i < values.planLength; i++) {
    let dayNum = i + 1;
    dayOptions.push({ value: dayNum, label: days[dayNum - 1] });
  }

  const calorieOptions = [1400, 1600, 1800, 2000, 2200, 2400, 2600, 2800, 3000];

  async function addRecipeToForm(params) {
    const result = await getRecipe(params.recipe);
    const recipe = {
      mealType: params.mealType,
      dayNum: parseInt(params.date),
      recipe: result,
      id: uniqueId(),
      leftover: false,
    };
    setRecipes([...recipes, recipe]);
  }

  async function removeRecipeFromForm(recipeId) {
    try {
      const updatedRecipes = recipes.filter(recipe => {
        if (recipe._id) {
          return recipe._id !== recipeId;
        }
        return recipe.id !== recipeId;
      });
      setRecipes(updatedRecipes);
    } catch (err) {
      console.error(err);
    }
  }

  async function toggleLeftoverRecipe(leftover) {
    try {
      const idx = recipes.findIndex(recipe => {
        if (recipe._id) {
          return recipe._id === leftover._id;
        }
        return recipe.id === leftover.id;
      });
      if (idx !== -1) {
        const recipeTmp = [...recipes];
        recipeTmp[idx].leftover = !leftover.leftover;
        setRecipes(recipeTmp);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function addFoodToForm(params) {
    const result = await getFood(params.food);
    let quantityTemp = parseInt(params.quantity);
    if (!quantityTemp) {
      quantityTemp = parseFloat(params.quantity);
    }
    const food = {
      mealType: params.mealType,
      dayNum: parseInt(params.date),
      quantity: quantityTemp,
      food: result,
      id: foods && foods.length + 1,
    };
    setFoods([...foods, food]);
  }

  async function removeFoodFromForm(foodId) {
    try {
      const updatedFoods = foods.filter(food => {
        if (food._id) {
          return food._id !== foodId;
        }
        return food.id !== parseInt(foodId, 10);
      });
      setFoods(updatedFoods);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FieldSet disabled={viewOnly || false}>
        <CheckBox
          checked={toggleValues.active || ''}
          label="Active"
          onChange={handleToggle()}
          name="active"
          data-test="mealPlan-active"
        />
        <Dropdown
          width="430px"
          options={['Weight Loss', 'Weight Maintenance', 'Muscle Gain']}
          label="Meal Plan Type"
          value={{
            value: values.type,
            label: values.type,
          }}
          onChange={value => handleValueChanged('type', value.value, 'string')}
          data-test="mealPlan-type"
        />
        <NumericInput
          id="planLength"
          name="planLength"
          value={values.planLength || 7}
          onValueChanged={handleValueChanged}
          onChange={handleChange}
          minValue="0"
          label="Plan Duration"
          units="days"
          data-test="mealPlan-planLength"
        />
        <MacroSection flexDirection="column">
          <NutrientsContainer wrap="wrap" flexDirection="row">
            <Dropdown
              width="220px"
              options={calorieOptions}
              label="Recommended Calories"
              value={{
                value: values.recommendedCalories,
                label: values.recommendedCalories,
              }}
              onChange={value =>
                handleValueChanged(
                  'recommendedCalories',
                  value.value,
                  value.label
                )
              }
              data-test="mealPlan-recommendedCalories"
            />
          </NutrientsContainer>
        </MacroSection>
        <HR />
        <Heading>Recipes</Heading>
        <MealListContainer flexDirection="column">
          <Dropdown
            width="220px"
            options={dayOptions}
            label="Meal Plan Day:"
            value={{
              value: values.dayNum.value,
              label: values.dayNum.label,
            }}
            onChange={value =>
              handleDateChanged('dayNum', value.value, value.label)
            }
            data-test="mealPlan-daySelect"
          />
          <MealList
            recipes={filteredRecipes}
            foods={filteredFoods}
            date={values.dayNum.value}
            variant="admin"
            addRecipe={addRecipeToForm}
            removeRecipe={removeRecipeFromForm}
            addFood={addFoodToForm}
            removeFood={removeFoodFromForm}
            toggleLeftover={toggleLeftoverRecipe}
          />
        </MealListContainer>
        <HR />
        <Heading>Tags</Heading>
        <Tag>Diet Type</Tag>
        <CheckContainer flexDirection="column">
          <CheckBox
            checked={toggleValues.dietType.any || ''}
            label="Any"
            onChange={handleToggle('dietType')}
            name="any"
          />
          <CheckBox
            checked={toggleValues.dietType.vegetarian || ''}
            label="Vegetarian"
            onChange={handleToggle('dietType')}
            name="vegetarian"
          />
          <CheckBox
            checked={toggleValues.dietType.pescatarian || ''}
            label="Pescetarian"
            onChange={handleToggle('dietType')}
            name="pescatarian"
          />
          <CheckBox
            checked={toggleValues.dietType.vegan || ''}
            label="Vegan"
            onChange={handleToggle('dietType')}
            name="vegan"
          />
        </CheckContainer>
      </FieldSet>
      {formType !== 'view' && (
        <Button
          type="submit"
          buttonText={buttonText || 'Create Fitness Plan'}
          buttonSize="large"
          disabled={loading}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
          data-test="mealPlan-submit"
        />
      )}
      {status === 'error' ? (
        <AlertStack
          messages={message}
          type="error"
          variant="filled"
          open={status === 'error'}
          handleClose={() => setStatus(null)}
          autoHideDuration={20000}
          data-test="mealPlan-message-error"
        />
      ) : status === 'success' ? (
        <AlertStack
          messages={message}
          type="success"
          variant="filled"
          open={status === 'success'}
          handleClose={() => setStatus('hidden')}
          autoHideDuration={20000}
          data-test="mealPlan-message-success"
        />
      ) : null}
      {formType === 'delete' &&
        (status === 'success' || status === 'hidden') && (
          <Button
            buttonText="Undo"
            buttonSize="small"
            handleClick={undoDelete}
            pink="true"
            data-test="mealPlan-undoDelete"
          />
        )}
    </Form>
  );
};

const Form = styled.form`
  margin-top: 30px;
  > * {
    margin-bottom: 10px;
  }
`;

const FieldSet = styled.fieldset`
  > * {
    margin-bottom: 10px;
  }
`;

const MacroSection = styled(FlexContainer)`
  margin: 24px 0;

  > * {
    margin-bottom: 8px;
  }
`;

const NutrientsContainer = styled(FlexContainer)`
  max-height: 350px;
  > * {
    margin-right: 26px;
  }
`;

const MealListContainer = styled(FlexContainer)`
  max-width: 75%;

  > * {
    margin-bottom: 10px;
  }
`;

const HR = styled.hr`
  margin: 15px 0;
`;

const Heading = styled.h2`
  color: ${colors.primary800};
  text-align: center;
`;

const CheckContainer = styled(FlexContainer)`
  max-height: 275px;
  flex-wrap: wrap;

  > * {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;
const Tag = styled.h3`
  color: ${colors.secondary500};
  margin-bottom: 15px;
`;

export default MealPlanForm;
