import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import BackIcon from '../icons/BackIcon';
import useReactRouter from 'use-react-router';
import IntensityIcon from '../icons/IntensityIcon';
import FavoriteIcon from '../icons/FavoriteIcon';
import SwapIcon from '../icons/SwapIcon';
import RemoveIcon from '../icons/RemoveIcon';
import LogIcon from '../icons/LogIcon';
import TopNav from './TopNav';
import NotFound from './NotFound';

import RecipeDetails from '../mealPlan/recipe/RecipeDetails';
import SampleNutrition from './SampleNutrition';
import RecipeNutritionContainer from '../mealPlan/recipe/RecipeNutritionContainer';
import { getImageURL } from '../services/api/api';
import { isMobile, formatAttrLink } from '../helpers/utils';
import { formatCalories } from '../helpers/nutrition';
import { recipes } from './samples';

const RecipeSample = ({ recipeId }) => {
  const { history } = useReactRouter();

  const handleBack = () => {
    history.goBack();
  };

  const recipeImageUrl = (ratio = 1) => {
    const height = 500 * ratio;
    const width = 550 * ratio;
    return getImageURL(
      recipe?.imageUrl,
      `resizing_type:fill/height:${height}/width:${width}`
    );
  };

  const defaultState = {
    name: '',
    nutrients: {},
    cookingInstructions: [],
    ingredients: [],
  };

  const [recipe, setRecipe] = useState(defaultState);

  useEffect(() => {
    const matchingRecipe = recipes?.find(
      sampleRecipe => sampleRecipe?._id === recipeId
    );
    if (matchingRecipe) {
      setRecipe(matchingRecipe);
    } else {
      setRecipe(null);
    }
  }, []);

  const prepTime = recipe?.prepTime ?? 0;
  const cookTime = recipe?.cookTime ?? 0;
  const totalTime = prepTime + cookTime;

  if (!isMobile())
    // Web view
    return (
      <div>
        <TopNav history={history} handleBack={handleBack} />
        {recipe ? (
          <div style={{ maxWidth: 1300 }}>
            <TopContainer>
              <Column flexDirection="column" style={{ maxWidth: 620 }}>
                <RecipeImage
                  image={recipeImageUrl()}
                  image2x={recipeImageUrl(2)}
                >
                  <BackIconContainer>
                    <BackIcon
                      handleClick={handleBack}
                      backgroundcolor="#fff"
                      width="40px"
                      height="40px"
                      borderradius="20px"
                      data-test="recipePage-back"
                    />
                  </BackIconContainer>
                </RecipeImage>
              </Column>
              <Column flexDirection="column">
                <RecipeName length={recipe?.name?.length}>
                  {recipe?.name || ''}
                </RecipeName>
                <IconsContainer>
                  <FavoriteIcon
                    toggleSelect={() => history.push('/signup?referrer=email')}
                    selected={false}
                    color="purple"
                    width="40px"
                    height="40px"
                    shrinkIcon={true}
                    data-test="recipePage-favorite"
                  />
                  <SwapIcon
                    toggleSelect={() => history.push('/signup?referrer=email')}
                    width="40px"
                    height="40px"
                    color="teal"
                    shrinkIcon={true}
                    data-test="recipePage-swap"
                  />
                  <RemoveIcon
                    toggleSelect={() => history.push('/signup?referrer=email')}
                    width="40px"
                    height="40px"
                    shrinkIcon={true}
                    data-test="recipePage-remove"
                  />
                  <LogIcon
                    logged={false}
                    toggleSelect={() => history.push('/signup?referrer=email')}
                    width="40px"
                    height="40px"
                    data-test="recipePage-logIcon"
                  />
                </IconsContainer>
                <RecipeInfoContainer>
                  {prepTime > 0 && (
                    <InfoItem alignItems="center">
                      <PrepIcon
                        src={`${process.env.PUBLIC_URL}/icons/PrepTime.svg`}
                      />
                      <InfoColumn flexDirection="column">
                        <InfoLabel>Prep Time: </InfoLabel>
                        <Info data-test="recModal-prepTime">
                          {prepTime} minutes
                        </Info>
                      </InfoColumn>
                    </InfoItem>
                  )}
                  {cookTime > 0 && (
                    <InfoItem alignItems="center">
                      <PrepIcon
                        src={`${process.env.PUBLIC_URL}/icons/CookTime.svg`}
                      />
                      <InfoColumn flexDirection="column">
                        <InfoLabel>Cook Time: </InfoLabel>
                        <Info data-test="recModal-cookTime">
                          {cookTime} minutes
                        </Info>
                      </InfoColumn>
                    </InfoItem>
                  )}
                  <InfoItem alignItems="center">
                    <PrepIcon
                      src={`${process.env.PUBLIC_URL}/icons/Preparation.svg`}
                    />
                    <InfoColumn flexDirection="column">
                      <InfoLabel>Ready In: </InfoLabel>
                      <Info data-test="recDetails-totalTime">
                        {totalTime} minutes
                      </Info>
                    </InfoColumn>
                  </InfoItem>
                  {recipe?.nutrients?.calories > 0 && (
                    <InfoItem alignItems="center">
                      <IntensityIcon
                        filled={true}
                        color={colors.secondary600}
                        width="22px"
                        height="22px"
                      />
                      <InfoColumn flexDirection="column">
                        <InfoLabel>Calories: </InfoLabel>
                        <Info data-test="recipePage-calories">
                          {formatCalories(recipe?.nutrients?.calories)}
                        </Info>
                      </InfoColumn>
                    </InfoItem>
                  )}
                </RecipeInfoContainer>
                <SampleNutrition
                  nutrients={recipe?.nutrients}
                  totalCalories={recipe?.nutrients?.calories}
                />
              </Column>
            </TopContainer>

            <BottomContainer flexDirection="column">
              <RecipeDetails recipe={recipe} recipeYield={recipe?.yield} />
            </BottomContainer>
          </div>
        ) : (
          <NotFound />
        )}
      </div>
    );
  else
    return (
      // Mobile view
      <div>
        <TopNav handleBack={handleBack} history={history} />
        {recipe ? (
          <>
            <RecipeContainer>
              <div style={{ width: '100%' }}>
                <ImageContainter>
                  <RecipeMobileImage
                    image={recipeImageUrl()}
                    image2x={recipeImageUrl(2)}
                  ></RecipeMobileImage>
                </ImageContainter>
                <RecipeMName data-test="recipePage-recipeName">
                  {recipe?.name}
                </RecipeMName>
                <MobileIconContainer justify="center" alignItems="center">
                  <FavoriteIcon
                    toggleSelect={() => history.push('/signup?referrer=email')}
                    selected={false}
                    color="purple"
                    width="40px"
                    height="40px"
                    shrinkIcon={true}
                    data-test="recipePage-favorite"
                  />
                  <SwapIcon
                    toggleSelect={() => history.push('/signup?referrer=email')}
                    width="40px"
                    height="40px"
                    color="teal"
                    shrinkIcon={true}
                    data-test="recipePage-swap"
                  />
                  <RemoveIcon
                    toggleSelect={() => history.push('/signup?referrer=email')}
                    width="40px"
                    height="40px"
                    shrinkIcon={true}
                    data-test="recipePage-remove"
                  />
                  <LogIcon
                    logged={false}
                    toggleSelect={() => history.push('/signup?referrer=email')}
                    width="40px"
                    height="40px"
                    data-test="recipePage-logIcon"
                  />
                </MobileIconContainer>
                <RecipeInfoContainer
                  justify={isMobile() ? 'center' : 'inherit'}
                >
                  <InfoItem alignItems="center">
                    <PrepIcon
                      src={`${process.env.PUBLIC_URL}/icons/Preparation.svg`}
                    />
                    <InfoColumn flexDirection="column">
                      <InfoLabel>Ready In: </InfoLabel>
                      <Info data-test="recDetails-totalTime">
                        {totalTime} minutes
                      </Info>
                    </InfoColumn>
                  </InfoItem>
                  {recipe?.nutrients?.calories > 0 && (
                    <InfoItem alignItems="center">
                      <IntensityIcon
                        filled={true}
                        color={colors.secondary600}
                        width="22px"
                        height="22px"
                      />
                      <InfoColumn flexDirection="column">
                        <InfoLabel>Calories: </InfoLabel>
                        <Info data-test="recipePage-calories">
                          {formatCalories(recipe?.nutrients?.calories)}
                        </Info>
                      </InfoColumn>
                    </InfoItem>
                  )}
                </RecipeInfoContainer>
              </div>
            </RecipeContainer>
            <RecipeDetails
              recipe={recipe}
              recipeYield={recipe?.yield || recipe?.yield}
            />
            {recipe?.source && (
              <Attribution href={formatAttrLink(recipe?.recipe?.attrLink)}>
                Recipe by {recipe?.source}
              </Attribution>
            )}
            <NutrientContainer>
              <RecipeNutritionContainer
                nutrients={recipe?.nutrients}
                totalCalories={recipe?.nutrients?.calories}
              />
            </NutrientContainer>
          </>
        ) : (
          <NotFound />
        )}
      </div>
    );
};

/****
 *
 * Web case
 *
 */

const RecipeImage = styled.div`
  height: 500px; // Update image dimensions accordingly.
  width: 550px; // Update image dimensions accordingly.
  border-radius: 4px;
  margin-right: 24px;
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
  position: relative;

  @media (max-width: 1280px) {
    height: 450px;
    width: 500px;
  }
`;

const Attribution = styled.a`
  color: ${colors.primary400};
  margin-top: 44px;
  margin-left: 30px;
  text-decoration: none;
`;

const BackIconContainer = styled(FlexContainer)`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const TopContainer = styled(FlexContainer)`
  padding: 24px;
`;
const BottomContainer = styled(FlexContainer)`
  padding: 0 24px 64px 24px;
  gap: 40px;
`;
const Column = styled(FlexContainer)``;

const RecipeName = styled.h1`
  color: ${colors.primary800};
  font-weight: 700;
  font-size: ${props => (props.length > 16 ? '52px' : '64px')};
  line-height: ${props => (props.length > 16 ? '58px' : '72px')};
`;

/***
 *
 * Mobile
 *
 */
const RecipeMobileImage = styled.div`
  height: 242px;
  width: 100%;
  margin-top: 44px;
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  justify-content: center;
  text-align: center;
  position: relative;
`;

const RecipeContainer = styled(FlexContainer)`
  justify-content: center;
  margin-bottom: 32px;
`;
const ImageContainter = styled.div`
  position: relative;
`;

const MobileIconContainer = styled(FlexContainer)`
  margin-top: 24px;
  gap: 36px;
`;

const RecipeMName = styled.h1`
  margin: 24px 30px 0px 30px;
  font-weight: 700;
  color: ${colors.primary800};
  line-height: 40px;
  text-align: center;
`;

const NutrientContainer = styled.div`
  width: 100%;
  padding: 32px 0 104px 0;
`;

const RecipeInfoContainer = styled(FlexContainer)`
  margin: ${() => (isMobile() ? '28px 0 12px 0' : '28px 0 48px 0')};
  gap: 32px;
`;

const IconsContainer = styled(FlexContainer)`
  gap: 36px;
  margin-top: 24px;
`;

const InfoColumn = styled(FlexContainer)`
  gap: 4px;
`;

const Info = styled.p`
  color: ${colors.secondary600};
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;

const InfoLabel = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.primary800};
`;

const PrepIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const InfoItem = styled(FlexContainer)`
  gap: 12px;
`;

export default RecipeSample;
