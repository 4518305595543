import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import { getImageURL } from '../services/api/api';
import { formatDate } from '../helpers/date';
import moment from 'moment';

const ActivityCard = ({
  activity,
  setScreen,
  handleClose,
  addActivity,
  context,
  isMobile,
  ...props
}) => {
  const handleClick = e => {
    const activityId = activity._id;
    const params = {
      activity: activityId,
      date: context?.date ?? formatDate(moment()),
    };

    addActivity(params);
    handleClose();
  };

  const activityURL = (ratio = 1) => {
    const height = 152 * ratio;
    return getImageURL(activity.imageUrl, `height:${height}`);
  };

  if (isMobile) {
    // mobile version
    return (
      <MobileCardContainer
        alignItems="center"
        onClick={handleClick}
        data-test="fitnessModal-activityCard"
      >
        <ImageContainer
          image={activityURL()}
          image2x={activityURL(2)}
        ></ImageContainer>
        <ActivityNameContainer>{activity.name}</ActivityNameContainer>
      </MobileCardContainer>
    );
  } else {
    // web version
    return (
      <CardContainer
        flexDirection="column"
        onClick={handleClick}
        data-test="fitnessModal-activityCard"
      >
        <TopContainer alignItems="center">
          <ActivityTitle>{activity.name}</ActivityTitle>
        </TopContainer>
        <BottomContainer
          image={activityURL()}
          image2x={activityURL(2)}
        ></BottomContainer>
      </CardContainer>
    );
  }
};

const CardContainer = styled(FlexContainer)`
  height: 224px;
  width: 192px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1.5px solid ${colors.primary200};
  border-radius: 4px;

  :hover {
    cursor: pointer;
    opacity: 0.8;
  }

  :active {
    opacity: 0.7;
  }
`;

const TopContainer = styled(FlexContainer)`
  height: 72px;
  padding: 0 16px;
`;

const BottomContainer = styled(FlexContainer)`
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-size: cover;
  background-position: center;
  height: 152px; // Update image dimensions accordingly.
`;

const ActivityTitle = styled.h4`
  color: ${colors.primary800};
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
`;

const MobileCardContainer = styled(FlexContainer)`
  box-sizing: border-box;
  width: 100%;
  border: 1.5px solid ${colors.primary200};
  border-radius: 4px;
`;
const ImageContainer = styled(FlexContainer)`
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-size: cover;
  background-repeat: no-repeat;
  height: 90px;
  width: 40%;
  min-width: 40%;
`;
const ActivityNameContainer = styled.div`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 17px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 0 20px;
  width: auto;
`;

export default ActivityCard;
