import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';

import CardsRow from '../CardsRow';
import StaticSearchBar from '../../elements/StaticSearchBar';

const FavoritesWeb = ({
  searchQuery,
  handleSearch,
  favoriteRecipes,
  customFavoriteRecipes,
  pageSizeFavoriteRecipes,
  getFavoriteRecipes,
  favoriteFoods,
  customFavoriteFoods,
  pageSizeFavoriteFoods,
  getFavoriteFoods,
  setScreen,
  setRecipe,
  setFood,
  context,
  hasMoreRecipes,
  hasMoreFoods,
  hasMoreCustomRecipes,
  hasMoreCustomFoods,
  ...props
}) => {
  return (
    <FlexContainer flexDirection="column">
      <SearchArea flexDirection="column">
        <Title>Search Favorites</Title>
        <StaticSearchBar
          handleSearch={handleSearch}
          placeholder="Try Sesame Chicken, etc"
          autoFocus
          data-test="favorites-search"
        />
      </SearchArea>
      <RecommendedContainer flexDirection="column">
        <Header>My Favorite Recipes</Header>
        <RowsContainer wrap="wrap">
          <CardsRow
            numCards={9}
            title="Your Recipes"
            items={customFavoriteRecipes}
            setScreen={setScreen}
            setItem={setRecipe}
            wrap="wrap"
            type="Recipe"
            pageSize={pageSizeFavoriteRecipes}
            loadMoreItems={getFavoriteRecipes}
            scrollParent={props.scrollParent}
            hasMore={hasMoreCustomRecipes}
            context={context}
          />
          <CardsRow
            numCards={9}
            title="Recipes"
            items={favoriteRecipes}
            setScreen={setScreen}
            setItem={setRecipe}
            wrap="wrap"
            type="Recipe"
            pageSize={pageSizeFavoriteRecipes}
            loadMoreItems={getFavoriteRecipes}
            scrollParent={props.scrollParent}
            hasMore={hasMoreRecipes}
            context={context}
          />
        </RowsContainer>
        <Header>My Favorite Foods</Header>
        <RowsContainer wrap="wrap" flexDirection="column">
          <CardsRow
            numCards={9}
            title="Your Foods"
            items={customFavoriteFoods}
            setScreen={setScreen}
            setItem={setFood}
            wrap="wrap"
            type="Food"
            pageSize={pageSizeFavoriteFoods}
            loadMoreItems={getFavoriteFoods}
            scrollParent={props.scrollParent}
            hasMore={hasMoreCustomFoods}
            context={context}
          />
          <CardsRow
            numCards={9}
            title="Foods"
            items={favoriteFoods}
            setScreen={setScreen}
            setItem={setFood}
            wrap="wrap"
            type="Food"
            pageSize={pageSizeFavoriteFoods}
            loadMoreItems={getFavoriteFoods}
            scrollParent={props.scrollParent}
            hasMore={hasMoreFoods}
            context={context}
          />
        </RowsContainer>
      </RecommendedContainer>
    </FlexContainer>
  );
};

const Title = styled.h5`
  color: ${colors.primary700};
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 16px;
`;

const SearchArea = styled(FlexContainer)`
  width: fit-content;
  margin-bottom: 64px;
`;

const RecommendedContainer = styled(FlexContainer)``;
const RowsContainer = styled(FlexContainer)`
  margin-top: 24px;
`;

const Header = styled.h2`
  color: ${colors.primary800};
`;

export default FavoritesWeb;
