import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import {
  formatCalcium,
  formatCholesterol,
  formatDietaryFiber,
  formatIron,
  formatProtein,
  formatSaturatedFat,
  formatSodium,
  formatTotalCarbohydrate,
  formatTotalFat,
  formatTotalSugars,
  formatTransFat,
  formatVitaminD,
  formatPotassium,
} from '../helpers/nutrition';
import GraphLabel from '../icons/GraphLabel';
import { PieChart } from 'react-minimal-pie-chart';
import { isMobile } from '../helpers/utils';

const SampleNutrition = ({ nutrients, totalCalories, ...props }) => {
  const data = [
    {
      title: 'Protein',
      value:
        (formatProtein(nutrients?.proteins, false) * 4) / (totalCalories || 1),
      color: colors.pie1,
    },
    {
      title: 'Carbs',
      value:
        (formatTotalCarbohydrate(nutrients?.carbohydrates, false) * 4) /
        (totalCalories || 1),
      color: colors.pie2,
    },
    {
      title: 'Fat',
      value: (formatTotalFat(nutrients?.fat, false) * 9) / (totalCalories || 1),
      color: colors.pie3,
    },
  ];
  return (
    <Container flexDirection="column" flexShrink="0">
      <RecipeSectionHeader justify="space-between" alignItems="center">
        <RecipeSectionName>Nutrition</RecipeSectionName>
        <RecipeHeaderNote>Per serving</RecipeHeaderNote>
      </RecipeSectionHeader>
      <InfoContainer
        alignItems="center"
        wrap="nowrap"
        justify={isMobile() && 'center'}
      >
        <StatsContainer alignItems="center">
          <PieChart
            data={data}
            style={{
              height: '150px',
              width: '150px',
            }}
            labelPosition="60"
            label={({ x, y, dx, dy, dataEntry }) => {
              return (
                <GraphLabel
                  x={x}
                  y={y}
                  dx={dx}
                  dy={dy}
                  dominantBaseline="central"
                  textAnchor="middle"
                  text={
                    dataEntry.percentage > 0 &&
                    `${Math.round(dataEntry.percentage)}%`
                  }
                />
              );
            }}
          />
          <MacrosContainer flexDirection="column">
            <MacroItem>
              <MacroQuantity data-test="recipePage-protein">
                {formatProtein(nutrients?.proteins)}
              </MacroQuantity>
              <Macro color={colors.pie1}>Protein</Macro>
            </MacroItem>
            <MacroItem>
              <MacroQuantity data-test="recipePage-carbs">
                {formatTotalCarbohydrate(nutrients?.carbohydrates)}
              </MacroQuantity>
              <Macro color={colors.pie2}>Carbs</Macro>
            </MacroItem>
            <MacroItem>
              <MacroQuantity data-test="recipePage-fat">
                {formatTotalFat(nutrients?.fat)}
              </MacroQuantity>
              <Macro color={colors.pie3}>Fat</Macro>
            </MacroItem>
          </MacrosContainer>
        </StatsContainer>
        <DailyValuesContainer flexDirection="column" wrap="wrap">
          <DailyValueItem>
            <DvQuantity data-test="recipePage-satFat">
              {formatSaturatedFat(nutrients?.saturatedFat)}
            </DvQuantity>
            <DailyValue>Saturated Fat</DailyValue>
          </DailyValueItem>
          <DailyValueItem>
            <DvQuantity data-test="recipePage-transFat">
              {formatTransFat(nutrients?.transFat)}
            </DvQuantity>
            <DailyValue>Trans Fat</DailyValue>
          </DailyValueItem>
          <DailyValueItem>
            <DvQuantity data-test="recipePage-cholesterol">
              {formatCholesterol(nutrients?.cholesterol_mg)}
            </DvQuantity>
            <DailyValue>Cholesterol</DailyValue>
          </DailyValueItem>
          <DailyValueItem>
            <DvQuantity data-test="recipePage-sodium">
              {formatSodium(nutrients?.sodium_mg)}
            </DvQuantity>
            <DailyValue>Sodium</DailyValue>
          </DailyValueItem>
          <DailyValueItem>
            <DvQuantity data-test="recipePage-fiber">
              {formatDietaryFiber(nutrients?.fiber)}
            </DvQuantity>
            <DailyValue>Dietary Fibers</DailyValue>
          </DailyValueItem>
          <DailyValueItem>
            <DvQuantity data-test="recipePage-sugar">
              {formatTotalSugars(nutrients?.totalSugar)}
            </DvQuantity>
            <DailyValue>Sugar</DailyValue>
          </DailyValueItem>
          <DailyValueItem>
            <DvQuantity data-test="recipePage-vitD">
              {formatVitaminD(nutrients?.vitaminD_mcg)}
            </DvQuantity>
            <DailyValue>Vitamin D</DailyValue>
          </DailyValueItem>
          <DailyValueItem>
            <DvQuantity data-test="recipePage-calcium">
              {formatCalcium(nutrients?.calcium_mg)}
            </DvQuantity>
            <DailyValue>Calcium</DailyValue>
          </DailyValueItem>
          <DailyValueItem>
            <DvQuantity data-test="recipePage-potassium">
              {formatPotassium(nutrients?.potassium_mg)}
            </DvQuantity>
            <DailyValue>Potassium</DailyValue>
          </DailyValueItem>
          <DailyValueItem>
            <DvQuantity data-test="recipePage-iron">
              {formatIron(nutrients?.iron_mg)}
            </DvQuantity>
            <DailyValue>Iron</DailyValue>
          </DailyValueItem>
        </DailyValuesContainer>
      </InfoContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  width: 100%;
`;

const RecipeSectionHeader = styled(FlexContainer)`
  width: ${props => (isMobile() ? 'calc(100% - 48px)' : 'calc(100% - 16px)')};
  margin: ${props => (isMobile() ? '0 16px' : 'initial')};
  padding: 0 8px;
  height: 45px;
  background-color: ${colors.primary050};
`;

const RecipeSectionName = styled.h3`
  color: ${colors.primary800};
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
`;
const RecipeHeaderNote = styled.h4`
  color: ${colors.primary800};
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

const InfoContainer = styled(FlexContainer)`
  width: 100%;
  padding-top: 24px;
  margin-bottom: 32px;
  gap: 50px;
  padding-right: 18px;
`;

const StatsContainer = styled(FlexContainer)`
  gap: 32px;
`;

const DailyValuesContainer = styled(FlexContainer)`
  max-height: ${props => (isMobile() ? '185px' : '170px')};
  width: ${props => (isMobile() ? '90%' : '300px')};
  gap: 24px;
`;

const DailyValueItem = styled(FlexContainer)`
  width: 160px;
`;

const DailyValue = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.primary800};
`;
const DvQuantity = styled(DailyValue)`
  width: ${props => (isMobile() ? '70px' : '55px')};
  font-weight: 700;
`;

const MacrosContainer = styled(FlexContainer)``;

const MacroItem = styled(FlexContainer)`
  color: ${colors.primary800};
  margin-bottom: 16px;
`;

const MacroQuantity = styled.h5`
  width: 55px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;

const Macro = styled.h5`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  position: relative;
  margin-left: 14px;

  ::before {
    content: ' ';
    position: absolute;
    margin-right: 8px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: ${props => props.color};
    top: 30%;
    left: -14px;
  }
`;

export default SampleNutrition;
