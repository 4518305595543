import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthPage from '../authorization/AuthPage';
import { isMobile } from '../helpers/utils';
import { useSelector } from 'react-redux';

const AuthRoute = props => {
  const mapState = state => ({
    isAuthenticated: state.currentUser.isAuthenticated,
  });
  const { isAuthenticated } = useSelector(mapState);
  return (
    <Route
      {...props}
      render={props =>
        isAuthenticated && localStorage.jwtToken && isMobile() ? (
          <Redirect
            to={{
              pathname: '/app/explore',
              state: { from: props.location },
            }}
          />
        ) : isAuthenticated && localStorage.jwtToken ? (
          <Redirect
            to={{
              pathname: '/app',
              state: { from: props.location },
            }}
          />
        ) : (
          <AuthPage {...props} />
        )
      }
    />
  );
};

export default AuthRoute;
