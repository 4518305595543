import React, { useState } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import UserInfo from './UserInfo';
import UserSearch from './UserSearch';

const FindUsers = props => {
  const [user, setUser] = useState(null);

  return (
    <Container wrap="wrap">
      <UserSearch setUser={setUser} type="advanced" />
      {user && <UserInfo user={user} />}
    </Container>
  );
};

const Container = styled(FlexContainer)``;

export default FindUsers;
