import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';

const StatsBox = ({ header, children, ...props }) => {
  return (
    <Container flexDirection="column">
      {header && <Header center={props.center}>{header}</Header>}
      {children}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  min-height: 50px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid ${colors.primary200};
  padding: 32px;
  margin-bottom: 16px;
  position: relative;
`;

const Header = styled.h6`
  font-weight: 400;
  font-size: 13px;
  color: ${colors.hliteone500};
  text-transform: uppercase;
  margin-bottom: 8px;
  text-align: ${props => (props.center ? 'center' : 'initial')};
`;

export default StatsBox;
