import React from 'react';
import styled from 'styled-components';

export const TermsAndConditions = () => (
  <DocContainer>
    The website and app nutriology.io (hereinafter “platform”) is owned and
    operated by Nutriology, a Virginia Limited Liability Company (hereinafter
    “we” “us” “our”). Your use of our platform constitutes acceptance of the
    terms, and you acknowledge you have read them. You also understand that we
    may update these terms from time to time, and will provide notice via email
    of any material changes made to the Terms & Conditions.
    <ol>
      <MainListItem>Purpose</MainListItem>
      <ol type="A">
        <li>
          The purpose of Platform is solely to provide educational information;
          any content on this platform or provided as a result of your decision
          to opt-in to our email list has been created solely for the purpose of
          education and for informational purposes only. By visiting Platform,
          you agree and understand that this content is made available to you as
          a self-help tool only. Nothing on this platform or distributed via
          email is intended to take the place of a consultation with a
          physician, dietician, nutritionist, counselor, medical professional of
          any kind, or other professional.
        </li>
        <li>
          Nutriology is not a doctor, therapist, counselor, dietician, or other
          professional; User agrees he or she will consult with his or her
          personal doctor or other professional prior to or in addition to
          utilizing information found on this platform, and such information
          will not substitute for any consultation with User’s own professional.
          The information contained on Platform or via emails, or available
          through our products, programs, and services is not intended to be a
          substitute for any professional, personal advice, nor is anything
          contained herein designed to provide you with a medical diagnosis,
          treatment, or other medical services.
        </li>
      </ol>
      <MainListItem>Platform Use</MainListItem>
      <ol type="A">
        <li>
          To access or use our Platform, you must be 18 years or older and have
          the requisite mental capacity to enter into these Terms & Conditions.
          By using this Platform, you represent that you are at least 18 years
          old, and that you agree to be bound by these terms and conditions. Any
          use of this platform by anyone under 18 constitutes an unauthorized
          use and a violation of these terms and conditions; we do not authorize
          use of this Platform by anyone under 18.
        </li>
        <li>
          Please note these Terms & Conditions may be subject to changes or
          updates at any time, and Platform may not provide notice of such
          changes or updates. We reserve our right to make any changes or
          updates at any time, and the requirement is on the user to routinely
          check these Terms & Conditions for updates. By continuing to use our
          Platform and the content we produce, you confirm you agree to be bound
          by the Terms & Conditions as they appear, whether or not you have read
          them. If you are not in agreement with these Terms & Conditions,
          please do not use our Platform or any of the Content that appears
          thereon.
        </li>
        <li>
          If you purchase a Program, Product, or Service from us, you will also
          enter into one or more separate agreements with us, and are subject to
          the terms outlined in the Terms of Use associated with the program
          purchased, which shall prevail in the event of a conflict or issue.
          All online purchases through us carry additional Terms of Use relating
          to the product being purchased, with your purchase of the product or
          service constituting acceptance and agreement to adhere to these
          additional terms. All private coaching packages require signature of
          an additional Agreement outlining the terms and guidelines therein as
          well.
        </li>
      </ol>
      <MainListItem> Voluntary Participation</MainListItem>
      <ol type="A">
        <li>
          By choosing to visit this site and read the information provided, you
          understand and agree that you are voluntarily choosing to read,
          implement and/or participate in the use of Platform and any
          information contained herein, and are solely responsible for any
          outcomes or results (positive or negative.) We cannot be responsible
          for any action you may choose to take regarding the information
          provided, and you acknowledge and agree that we are not responsible
          nor liable to you should you sustain any financial harm, physical
          injuries or any negative ramifications. The information contained on
          Platform is intended as general information only; we cannot know your
          individual situation, and do not claim to know what may or may not
          work for your personal situation. As such, you agree that any
          decisions you make to implement or follow anything you find on
          Platform are wholly your own.
        </li>
      </ol>
      <MainListItem>Information You Provide</MainListItem>
      <ol type="A">
        <li>
          In order to gain access to our email list, you may be required to
          provide information about yourself, including your name, email
          address, and other personal information. In order to purchase products
          or services, you will also be required to submit payment information
          and a billing address, and may be asked to create a username and
          password to gain access to your purchased materials. Please note you
          are responsible for keeping track of your username and password, and
          understand that you bear the consequences should you choose to share
          this confidential information with anyone.
        </li>
        <li>
          You acknowledge and understand that any information provided to us is
          done so on a purely voluntary basis. By choosing to provide us with
          this information, you agree and represent that any information
          provided to us through the Platform or a third-party payment processor
          will be accurate and current, and belong to you. You understand you
          may not hold yourself out as someone else or use anyone else’s
          information, and agree to bear the consequences should you use anyone
          else’s information as your own.
        </li>
        <li>
          You also agree that you are to remain financially responsible for any
          purchases made through our Platform by you, or by another person
          acting on your behalf, regardless of the information provided at
          checkout. Should information become available at a later date
          confirming you performed unauthorized use of a credit card or other
          payment information belonging to someone other than yourself, you
          understand and agree that you alone remain financially responsible for
          purchases made through our Platform.
        </li>
      </ol>
      <MainListItem>Use of Free Materials or Content</MainListItem>
      <ol type="A">
        <li>
          At times you may be offered a free download or printable, as a gift or
          opt-in offer, in exchange for your name and email address. Should you
          choose to download or print this material, you understand this is to
          be used for your personal, non-commercial use only, and is not to be
          copied, edited, distributed, or otherwise shared in any way other than
          in its original form. Should you choose to share the material, you
          agree and understand that you will provide Nutriology credit, will not
          hold the material to be your own, or otherwise attempt to make any
          sort of gain (financial or otherwise), from our materials.
        </li>
      </ol>
      <MainListItem>Your Conduct</MainListItem>
      <ol type="A">
        <li>
          Should you choose to post anything on our Platform, social media
          pages, webinars, or otherwise, you are hereby agreeing and
          acknowledging you will not post anything that could harm us or another
          user, or include anything defamatory, harmful, hurtful, or otherwise
          upsetting. You understand that if you make the decision to post
          content that constitutes cyber bulling, your comments will be removed
          immediately, and we reserve our right to take action against you to
          the full extent of applicable laws.
        </li>
        <li>
          You may use our Platform for lawful purposes only, and agree you will
          not post, comment, or otherwise transmit any content which infringes
          the rights of another, and agree to hold us harmless should you do so.
          You also agree and understand you are not to post any content which
          would constitute a criminal offense, use the Platform or content for
          fraudulent or unlawful purposes, create civil liability, is repugnant,
          constitutes a violation of another’s intellectual property rights, is
          vulgar or obscene in any way, or is otherwise objectionable. You agree
          that we are the sole decider in whether content you post is
          objectionable, and have the unilateral right to remove any content you
          post, without explanation or ramifications. You also understand that
          we may be obligated to take further legal action, based upon
          information you post, and reserve our right to do so. Should a third
          party choose to take legal action against you, as a result of
          something you posted on our Platform, you agree to hold us harmless
          and fully indemnify us of any legal ramifications or actions.
        </li>
      </ol>
      <MainListItem>
        Licensee / Licensor Rights: Our Limited License to You
      </MainListItem>
      <ol type="A">
        <li>
          Your ability to view Content on our Platform grants you a limited,
          revocable, non-transferrable license to use the information available
          to you for your personal, non-commercial use only. Should you decide
          to purchase a product, package, or service from our Platform, you will
          also be granted a limited license to use the information contained
          therein, as outlined in the Terms of Use or Client Agreement
          associated with each product or service available for purchase.
        </li>
        <li>
          As a licensee, you understand and agree that you will not:
          <ol type="i">
            <li>
              Copy, edit, distribute, duplicate or steal any information on our
              Platform, or any Content therein, including that which has been
              posted by a third party, whether or not you have purchased the
              information.
            </li>
            <li>
              Use, post, distribute, copy, steal or otherwise use any portion of
              our Platform, including content or products, without express,
              written permission provided by Nutriology and understand that any
              such use may constitute infringement, which may give rise to a
              cause of action against you.
            </li>
            <li>
              Hold any of our Content out to be your own, and understand that
              doing so constitutes stealing and is a violation of our
              intellectual property rights.
            </li>
            <li>
              Share purchased materials, information, content with others who
              have not purchased them.
            </li>
            <li>
              Use any portion of our Platform, including all Content,
              information, and purchased materials, in any commercial manner
              such that you make, may make, or intend to make a profit from it.
            </li>
            <li>
              You further acknowledge and understand that any such actions
              including but not limited to those outlined above will likely
              constitutes infringement of our work, and a violation of these
              Terms and Conditions and United States Federal laws, and we
              reserve the right to prosecute this infringement to the full
              extent of the law.
            </li>
            <li>
              Any requests for written permission to use any content posted on
              our Platform shall be made before you wish to use any such
              content, and may be made by sending an email with your written
              request to info@nutriology.io.
            </li>
          </ol>
        </li>
      </ol>
      <MainListItem>
        Licensee / Licensor Rights: Your License to Us
      </MainListItem>
      <ol type="A">
        <li>
          You may be able to post your original content to our Platform,
          including but not limited to blog comments, testimonials, or photos.
          You may also have the opportunity to respond to generated emails with
          comments or feedback, comment on a social media live stream, webinar,
          or any other medium where content is distributed in a manner that
          allows for participation or feedback. By posting any sort of
          information, you represent you are the owner of anything you post, and
          are at least 18 years of age.
        </li>
        <li>
          When you submit or post any such information, you also agree and
          understand that you are assigning and/or licensing us and anyone else
          authorized by us, the irrevocable, unlimited, royalty-free, perpetual,
          non-exclusive, unrestrictive, worldwide license to use, distribute,
          sell, copy, edit, exploit, or otherwise publicly disseminate any
          information or content you post, in whole or in part, with or without
          identifying you as the author of the original post or comment. If we
          so choose to identify you, you also grant us the right to identify you
          as the author of any comments, posts, or the like by name, email
          address, screen name, or any other reasonable manner of
          identification.
        </li>
        <li>
          You agree you are wholly assigning any intellectual property ownership
          rights to us by your action and decision to post on our Platform, or
          otherwise provide us with content, with access to our Platform
          constituting good and valuable consideration in exchange for these
          intellectual property rights. You understand that should any of your
          comments or posts be used, in whole or in part, you are not entitled
          to any payment and will not request any form of payment or favorable
          treatment in return.
        </li>
        <li>
          Testimonials: Our Platform may feature testimonials from clients, in
          order to provide readers with comments, feedback, and information from
          others' experiences with our services, platform and products. While
          all information, photos, and quotes used are from actual clients,
          sharing their real, honest opinions of our platform and services,
          these testimonials are not to be considered as a guarantee that
          current or future clients will experience the same results, or a
          guarantee that all clients will have the same experience. You
          understand and agree that by reading a featured testimonial on our
          Platform, you do not expect the same results, and understand this
          information is not a guarantee. You also understand and agree that the
          individuals featured may have different medical history and health
          status, and what worked for them may not work for you; you agree to
          use common sense, and consult your personal medical provider before
          beginning any program we offer, and/or before implementing any
          information found on Platform.
        </li>
      </ol>
      <MainListItem>Intellectual Property Rights</MainListItem>
      <ol type="A">
        <li>
          This platform contains original work that has been created with
          creativity, originality, dedication, care, detail, planning, and
          creative thinking. This collection of work is considered intellectual
          property created and owned by Nutriology and is protected under
          Federal Intellectual Property Laws, which prevents unauthorized use of
          our materials. These materials may include, but are not limited to:
          trademarked and copyrighted material, as well as any and all original
          works on our Platform and within the content provided, including but
          not limited to platform design, layout, photographs, graphics, words,
          content, information, documents, data, our logo, artwork, color
          scheme, branding and/or placement of same on Platform, to the extent
          protectable, and any and all other information accessible through this
          Platform, which constitutes proprietary information. This protection
          also includes any and all content that appears on the Platform,
          including blog posts, program names, and any other text whether or not
          authored by us. All proprietary information and content hereinafter
          identified as (“Content”).
        </li>
      </ol>
      <MainListItem>Accuracy</MainListItem>
      <ol type="A">
        <li>
          Although we have spent considerable time and effort in creating the
          products on nutriology.io and the content provided herein, you
          understand and acknowledge that we are not responsible nor liable for
          any errors, omissions, or liability as a result of any loss or damages
          incurred as a direct or indirect result of your use of Platform
          content or our products. You also understand there may be inadvertent
          typographical errors or inaccuracies. By your use of this platform,
          you acknowledge and understand this information, and agree you have
          chosen/will choose to utilize our Platform and/or our products
          voluntarily. You agree that we are not responsible for the accuracy of
          our Platform, or for any errors or omissions that may occur on the
          site or in our products.
        </li>
        <li>
          You understand your obligation to provide only authentic, accurate
          information to us, including your name, email address, and payment
          information, should you choose to purchase a product. You understand
          and agree that should any information provided prove inaccurate, and
          any issues or damages arise from your giving us false or inaccurate
          information, you may be liable for any subsequent damages that occur
          as a result.
        </li>
      </ol>
      <MainListItem>Testimonials</MainListItem>
      <ol type="A">
        <li>
          Platform may feature testimonials from clients in order to provide
          readers with additional comments from others’ experiences with
          Platform, Nutriology, and products or services offered. While all
          information, photos, and quotes used are from actual clients, sharing
          their real, honest opinions of our platform and services, these
          testimonials are not to be considered as a guarantee that current or
          future clients will experience the same results, or a guarantee that
          all clients will have the same experience. You understand and agree
          that by reading a featured testimonial on our Platform, you do not
          expect the same results, and understand this information is not a
          guarantee.
        </li>
      </ol>
      <MainListItem>Affiliates and Endorsements</MainListItem>
      <ol type="A">
        <li>
          We may choose to partner with, promote, become an affiliate of, or
          otherwise engage in a joint venture with another individual or company
          whom we feel aligns with our products or services. You understand that
          should an affiliate or joint venture program be featured on our
          Platform, we may receive financial compensation or other payment as a
          result. Please note we will only feature or promote coaches,
          businesses, or products that we whole heartedly agree with and believe
          in, and genuinely believe will help our audience.
        </li>
        <li>
          You understand and agree that you must use your own judgement with
          respect to determining whether any promotion of another product is
          right for you and your business. Our decision to promote, suggest, or
          reference another service indicates nothing more than an
          acknowledgement that we respect or appreciate the business, person, or
          service. Your decision to use or purchase from such a promotion is
          yours alone, and you understand we have no involvement in your
          decision, nor shall we have any liability should you purchase from a
          promoted product and become unsatisfied. You agree and understand we
          have no liability, and you will hold us harmless should this occur.
        </li>
        <li>
          References to other coaches, information, events, services, products,
          opinions, or companies on our Platform, blog, or emails is meant
          purely as a way to share information, not as an endorsement or
          suggestion that you purchase or use whatever is being mentioned. We
          are not responsible for any information, content, emails, products,
          programs, or services of any other person, business, or entity that
          may be referenced on our Platform.
        </li>
      </ol>
      <MainListItem>Online Purchases</MainListItem>
      <ol type="A">
        <li>
          You understand that should you elect to make a payment through our
          platform, information obtained during your purchase, including name,
          address, credit card information, method of payment, billing
          information, and other personally identifiable information may be
          collected by us, as well as our payment processing company.
        </li>
        <li>
          You also understand and agree that any information provided by you is
          true and accurate, to be relied upon by us and our team in processing
          payment and delivering our products to you. Should your payment fail
          to process, we reserve the right to withhold the purchased product
          from you unless and until payment is properly rendered.
        </li>
        <li>
          Limitations of Liability: We will take reasonable precautions and
          measures to keep this information private. While we will attempt to
          monitor and resist any third party hacking or third party ability to
          gain access to confidential information held by us, you agree and
          understand we are not liable for any unauthorized access to or use of
          your information or property, regardless of negligence, failures,
          tort, breach of implied or express contract, or any other causes of
          action or legal theories of liability, even if such theories could
          have been foreseeable or preventable, or if we were made aware of such
          a possibility. Our limitations of liability extend to the fullest
          possible extent permitted by law, and in no event shall total
          liability exceed $500 to any one person or collective plaintiffs.
        </li>
        <li>
          You also acknowledge and agree that we have no responsibility or
          liability for policies of third-party payment processing companies we
          select and use to facilitate purchases through our Platform. When you
          make a purchase on our Platform, please note you may also be subject
          to the terms and conditions, policies, and/or guidelines of the
          payment processing company, in addition to ours. For more information,
          we encourage you to visit the platform of the payment processing
          company, and read their policies and terms and conditions as well. You
          agree to release us as well as the payment processing company we
          choose from any damages you incur as a result of this process, and
          agree not to file or assert any claims against us or the payment
          processing company arising from your purchase of a product through our
          Platform.
        </li>
      </ol>
      <MainListItem>Technology Disclaimer</MainListItem>
      <ol type="A">
        <li>
          By using our Platform, you understand and agree that Nutriology makes
          no guarantees or warranties regarding the condition of Platform,
          including functionality, existence of viruses or other components that
          may harm users’ computers, uninterrupted use, constant access and
          availability, and the like.
        </li>
        <li>
          We will make every effort to make our Platform available to you at all
          times; however, from time to time the Platform may be down for
          maintenance, repairs, or any other reason, known or unknown to you,
          and you agree and understand that this may occur without explanation.
          Should this occur, you understand, agree, and acknowledge we are not
          liable for any damages, losses, interruption in your business, or
          other inconvenience sustained by you as a direct or indirect result of
          the unavailability of our Platform.
        </li>
      </ol>
      <MainListItem>Limitation of Liability</MainListItem>
      <ol type="A">
        <li>
          You understand and agree that your participation in using our platform
          and/or adding yourself to our email list is wholly voluntary, and you
          are solely and personally responsible for your actions, choices, and
          any results therein. You understand there are sometimes unknown risks
          and circumstances that may arise during or following use of our
          Platform or products, that cannot be foreseen or anticipated, but may
          influence or affect your business or you as an individual. You
          understand and agree that any suggestion or recommendation of a
          product, service, coach, or otherwise through our Platform is purely
          information – any decision to act upon these suggestions is to be
          taken by you, at your own risk, without any liability on the part of
          Nutriology. You agree to accept all risks herein.
        </li>
        <li>
          Your use of this Platform constitutes an agreement and acceptance that
          you will absolve Nutriology as well anyone acting as an agent,
          consultant, affiliate, guest blogger, joint venture partner, employee,
          staff, team member, or anyone affiliated with Us in any way of any
          liability for any loss, damage, injury, or litigation that you or any
          other person may incur from direct or indirect use of the information,
          content, or products found on our Platform or via materials requested
          through email.
        </li>
        <li>
          You understand and agree that We are not to be held liable for any
          type of direct or indirect damages arising out of your use of our
          Platform, any information contained herein, or any products or
          services purchased therefrom, including but not limited to general,
          specific, incidental, consequential, punitive, or special damages. You
          also agree that we are not liable or responsible in any way for any
          loss incurred by you or your business, including revenues, clients,
          business, goodwill, income, anticipated income, predicted income,
          sales numbers, loss of a sale, data, nor any computer failure,
          computer virus obtained by use of our Platform, technical glitch or
          failure, defect or delay, or any other similar issue. You agree that
          your decision to use our Platform is wholly at your own risk and
          voluntarily chosen by you, and any ramifications resulting therefrom
          are yours alone.
        </li>
      </ol>
      <MainListItem>Indemnification</MainListItem>
      <ol type="A">
        <li>
          You agree at all times to defend, fully indemnify and hold harmless
          Nutriology and any affiliates, agents, team members or other party
          associated with it from any causes of action, damages, losses, costs,
          expenses incurred as a result of your use of our Platform or any
          products or services contained therein, as well as any third party
          claims of any kind (including attorney’s fees) arising from your
          actions in relation to our Platform or any breach by you of any such
          conditions outlined herein. Should we be required to defend ourselves
          in any action directly or indirectly involving you, or an action where
          we decide your participation or assistance would benefit our defense,
          you agree to participate and provide any evidence, documents,
          testimony, or other information deemed useful by us, free of charge.
        </li>
        <li>
          We will attempt to monitor comments and posts made by third parties
          and users as often as possible. Should you, as a user of our Platform,
          see anything objectionable or offensive posted by a third party, you
          agree to (1) notify us of the material, and (2) agree not to take any
          action against us based upon the content posted by the third party.
          You understand we cannot be responsible for material posted by a user
          without our control and agree to release us of any and all claims
          arising therefrom.
        </li>
        <li>
          Should you choose to utilize information offered on our platform,
          whether free or for purchase, you understand that we are not liable to
          any party, for any damages – whether direct, indirect, consequential,
          foreseeable, incidental or otherwise – stemming or perceived to stem
          from use of or reliance upon any information contained or found on our
          Platform, or from products or services purchased therefrom. You also
          understand and agree that we are not liable for any damages incurring
          as a result of your reliance or use of information on our Platform
          written by a third party, whether endorsed or not by us, and you agree
          to release us from any and all claims stemming from, or perceived to
          stem from, reliance on information contained on our Platform.
        </li>
      </ol>
      <MainListItem>Warranties</MainListItem>
      <ol type="A">
        <li>
          You understand and agree that we make no warranties, express or
          implied, and hereby renounce any such warranties, guarantees, or
          representations with respect to any portion of our Platform, the
          content herein, content distributed through email lists, social media,
          via webinars, or that which is made available through purchase via our
          membership site. By use of the Platform, you agree and understand that
          use of content and information found herein is to be taken “as is” and
          used at your own risk, with no guarantees, representations, or
          warranties regarding fitness for particular purpose, accuracy, or
          otherwise.
        </li>
      </ol>
      <MainListItem>Release of Claims</MainListItem>
      <ol type="A">
        <li>
          You also agree that under no circumstances will we be liable to any
          party for any type of damages resulting or claiming to result from any
          use of or reliance on our Platform or any information or Content found
          therein, and you hereby release us from any and all claims whether
          known now or discovered in the future.
        </li>
      </ol>
      <MainListItem>Dispute Resolution</MainListItem>
      <ol type="A">
        <li>
          These Terms and Conditions shall be governed by the laws of the state
          of Virginia. Should any dispute arise, you agree to resolve any claim
          or controversy arising out of or relating to these Terms and
          Conditions by Arbitration and/or a suitable Alternative Dispute
          Resolution in Fairfax County, Virginia regardless of your location,
          and agree to be bound by the decision(s) of the selected Mediator. You
          also agree to participate in good faith in the mediation process, with
          failure to do so creating our right to pursue any other available
          legal remedies, including but not limited to alternate forms of
          dispute resolution or litigation.
        </li>
        <li>
          Should an arbitrator determine any portion of these Terms and
          Conditions is invalid or otherwise unenforceable, you agree all
          remaining portions of these Terms and Conditions shall remain valid
          and unaffected by the removal of any portion of these Terms and
          Conditions.
        </li>
        <li>
          These Terms and Conditions constitute the entire agreement between us
          with respect to your use of our Platform, content, and products, and
          supersedes any other agreement, with the exception of any separate
          agreements, including Terms of Use, entered into by virtue of your
          decision to purchase any products available on our Platform. Nothing
          on our Platform nor any communications between us is to be construed
          as a waiver of any of the above, nor shall we have waived any portion
          of these Terms and Conditions absent express, written information by
          us expressly stating otherwise.
        </li>
        <li>
          You understand and acknowledge you will likely be in violation of
          these Intellectual Property laws should you copy, repost, alter,
          publish, sell, assist others in selling, manipulate, distribute, or in
          any way exploit any of the content or intellectual property on the
          Platform, without our express written consent. If such behavior is
          discovered or suspected, we reserve the right to immediately revoke
          your access to our Platform, as well as any program or materials you
          may have purchased, without refund, and reserve the right to prosecute
          any actionable infringement or misuse to the full extent of the law.
        </li>
      </ol>
      <MainListItem>Termination</MainListItem>
      <ol type="A">
        <li>
          You agree and understand we have the right to refuse or immediately
          terminate your access to our Platform at any time, for any reason,
          with or without notice. If you made any purchases and we determine you
          are entitled to receive or allowed continued use of the purchased
          information, we will make this information available to you.
        </li>
      </ol>
    </ol>
    If you have any questions regarding the Terms & Conditions, or would like to
    remove or update the information you have provided, please contact us at
    info@nutriology.io.
  </DocContainer>
);

export const PrivacyPolicy = () => (
  <DocContainer>
    The website and app nutriology.io (hereinafter “platform”) is owned by
    Nutriology, a Virginia Limited Liability Company (hereinafter “we” “us”).
    You, as a visitor and/or use of our platform, agree to the following Privacy
    Policy, and your use of our site constitutes your acceptance to be bound by
    the terms. Your use of our platform, and any information that you contribute
    or provide to us is subject to this Privacy Policy, with an effective date
    August 2017. This also includes any information we may collect, use,
    request, or that which you provide to us voluntarily in exchange for
    information or an offering on our platform. Your use of our platform
    constitutes acceptance of the terms, and you acknowledge you have read them.
    You also understand that we may update these terms from time to time, and
    will provide notice via email of any material changes made to this Privacy
    Policy.
    <ol>
      <MainListItem>Personal Data - Collection and Use</MainListItem>
      <ol type="A">
        <li>
          In order to gain access to portions of our platform, or access to our
          products, we may request personal information from you, that you enter
          voluntarily and manually, including but not limited to your name, date
          of birth, email address, mailing address, billing information,
          username and password, nutrition and fitness preferences, height,
          weight, and/or any other personally identifying information. This may
          also include any comments, images, or videos you make or share on our
          blogs, social media pages, or any other online forum currently
          available, or made available in the future. You understand that your
          decision to provide any information to us is voluntary, and you are
          doing so of your own free will, to gain access to a portion of our
          platform or a product we are offering. By providing this information
          to us, you understand and agree that we may use and store such
          information to send emails, bill credit cards in exchange for programs
          or products purchased or utilize comments for marketing purposes.
        </li>
        <li>
          We may use or send standard “cookies” to identify your browser from
          time to time. We do not include any personally identifiable
          information in cookies, and will not employ any other mechanisms
          (other than those discussed above) to capture data on our platform. We
          may use both session cookies (which expire once you close your
          browser) and persistent cookies (which stay on your computer until you
          delete them). You can accept or decline cookies using your web browser
          settings. If you choose to disable cookies, some areas of Platform may
          not work properly or at all.  Platform does not respond to Do Not
          Track signals sent by your browser.
        </li>
      </ol>
      <MainListItem>Emails</MainListItem>
      <ol type="A">
        <li>
          You understand that by “opting in” to our email list, you are
          informing us that you want to receive periodic email updates and/or
          newsletters from us. You also understand that this is a voluntary
          service provided to you at your request. You also understand that
          should you enter an invalid email address, we have the right to
          immediately remove you from our list, and all further access to
          information.
        </li>
        <li>
          <b>Opt-Out:</b> You also understand and acknowledge you have the right
          to unsubscribe from our list at any point, by clicking the
          “unsubscribe” link at the bottom of any email we send to you. Once
          unsubscribed, we will remove you from our list, and you should not
          receive any further emails from us, absent a technical glitch.
        </li>
      </ol>
      <MainListItem>Information Sharing</MainListItem>
      <ol type="A">
        <li>
          Your information is stored through a data management system, and it is
          important to us to take appropriate measures to ensure your
          information is kept confidential. You understand that there are
          limited purposes where we will share your confidential information,
          including with those who are providing technical support for our
          platform, or those who are members of our team. Any parties who will
          have access to your information will keep such information
          confidential, and will never share with any unrelated parties to the
          best of our abilities. Information provided by you will never be
          shared, sold, or given to any other company or person without your
          consent, other than what is required to complete a purchase or request
          you have made, unless required to do so by law.
        </li>
        <li>
          We may also record some or all information to help create a better
          user experience. We may use information such as your IP address to
          help diagnose technical problems with servers or our platform, and to
          determine which portions of our platform receive the most traffic, to
          understand which content is the most useful to our visitors. Your IP
          address will not personally identify you, and may be used in such
          limited purposes as outlined above, in order to improve user
          experience.
        </li>
        <li>
          Please be aware we may disclose information provided if we are
          required to do so by law. This information may also be shared as a
          result of the sale of Nutriology or any branch of the company, as well
          as with any joint venture partners or affiliate marketers, should we
          deem it necessary to share such information. We will always take all
          reasonable measures to protect and safeguard your information.
        </li>
        <li>
          This platform, and the servers and parties which made this platform
          available on a global scale, are located within and operate within the
          United States. The internet laws of the United States and Virginia
          govern any and all matters relating to this Platform, including
          content. Any information you choose to provide through this platform,
          including subscribing to a newsletter, opting-in to receive a free
          gift, or purchasing something will be transferred to the United States
          for processing. By visiting our Platform, you acknowledge this
          information, and by submitting any personal information onto our
          Platform, you hereby authorize this transfer and processing of
          information.
        </li>
      </ol>
      <MainListItem>Information Protection & Security</MainListItem>
      <ol type="A">
        <li>
          While our team does our best to protect the information we collect,
          and prevent any misuse, unauthorized access, or disclosure, you
          understand that we cannot be responsible should an unauthorized third
          party obtain access to our information, without our consent. Should
          our platform be tampered with, you understand that your personal
          information may be accessed by these unauthorized parties. You agree
          that we are not responsible for any such actions, and acknowledge you
          will hold us harmless should your information become intercepted in
          this way without our knowledge, permission, or consent, which includes
          a release of any and all claims related to use of such information by
          such an unauthorized party.
        </li>
      </ol>
      <MainListItem>Limitations</MainListItem>
      <ol type="A">
        <li>
          We may provide links to other platforms on our platform, from time to
          time. Unless otherwise stated, this Privacy Policy only covers
          information that we collect from you on our platform, which is
          expressly controlled by us. You understand that should you click on a
          link and be taken to another platform, this Privacy Policy does not
          attach, and we have no control, responsibility, or liability for
          content, activities, or policies of any other company, platform, or
          individual. You acknowledge and agree that we are not responsible for
          the privacy policies or practices of third parties, and that you
          understand you will need to review the privacy policy of each
          individual platform to confirm their specific policies.
        </li>
      </ol>
      <MainListItem>COPPA Compliance</MainListItem>
      <ol type="A">
        <li>
          You must be 18 years old or older to gain access to our platform, and
          must have the requisite mental capacity to enter into this Privacy
          Policy.  This platform is not intended for those under 18, in
          compliance with COPPA (Children’s Online Privacy Protection Act.), and
          we do not knowingly collect or request any information from or market
          to children under the age of 18.
        </li>
      </ol>
    </ol>
    If you have any questions regarding this Privacy Policy, or would like to
    remove or update the information you have provided, please contact us at
    info@nutriology.io.
  </DocContainer>
);

const DocContainer = styled.div`
  line-height: 1.5;

  ol li::marker {
    font-weight: bold;
  }
  ol {
    padding-left: 24px;
  }
  ol > ol {
    padding-left: 0px;
  }
`;

const MainListItem = styled.li`
  font-weight: bold;
  text-decoration: underline;

  margin: 20px 0;
`;
