import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import NotifOptions from './NotifOptions';
import TextLink from '../elements/TextLink';

const UserProfileNotifs = props => {
  const handleClick = () => {
    console.log('This will do something eventually');
  };

  return (
    <PageContainer>
      <LeftContainer>
        <PrivacyPolicy>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamcolaboris nisi ut aliquip
          <span>
            <TextLink handleClick={handleClick} linkText="Read More" />
          </span>
          .
        </PrivacyPolicy>
      </LeftContainer>
      <RightContainer>
        <NotifOptions />
      </RightContainer>
    </PageContainer>
  );
};

const PageContainer = styled(FlexContainer)`
  padding-top: 64px;
`;

const LeftContainer = styled(FlexContainer)`
  padding-left: 55px;
  flex-basis: 320px;
`;

const RightContainer = styled(FlexContainer)`
  margin-left: 127px;
`;

const PrivacyPolicy = styled.p`
  color: ${colors.primary700};
  margin: 0;
`;

export default UserProfileNotifs;
