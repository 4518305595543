import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import { isEmpty } from 'lodash';
import BackIcon from '../icons/BackIcon';
import useReactRouter from 'use-react-router';
import TopNav from './TopNav';
import SampleWorkoutSection from './SampleWorkoutSection';
import IntensitySet from '../elements/IntensitySet';
import NotFound from './NotFound';

import { getImageURL } from '../services/api/api';
import { isMobile, formatAttrLink } from '../helpers/utils';
import { workouts } from './samples';

const WorkoutSample = ({ workoutId }) => {
  const { history } = useReactRouter();

  const handleBack = () => {
    history.goBack();
  };

  const workoutImgUrl = (ratio = 1) => {
    const width = 500 * ratio;
    const widthMobile = 375 * ratio;
    const height = 334 * ratio;
    const heightMobile = 242 * ratio;
    return getImageURL(
      workout?.imageUrl,
      `resizing_type:fill/height:${isMobile() ? heightMobile : height}/width:${
        isMobile() ? widthMobile : width
      }`
    );
  };

  const defaultState = { name: '', workoutInfo: [] };
  const [workout, setWorkout] = useState(defaultState);

  useEffect(() => {
    const matchingWorkout = workouts?.find(
      sampleWorkout => sampleWorkout?._id === workoutId
    );
    if (matchingWorkout) {
      setWorkout(matchingWorkout);
    } else {
      setWorkout(null);
    }
  }, []);

  const caloriesBurned = workout?.workoutInfo[0]?.caloriesBurned;
  const duration = workout?.workoutInfo[0]?.duration;
  const intensity = workout?.workoutInfo[0]?.intensity;

  const exercises = workout?.exercises || [];
  const warmup = exercises.filter(
    exercise => exercise.workoutSequence === 'Warm Up'
  );
  const core = exercises.filter(
    exercise => exercise.workoutSequence === 'Core'
  );
  const strength = exercises.filter(
    exercise => exercise.workoutSequence === 'Strength'
  );
  const circuit = exercises.filter(
    exercise => exercise.workoutSequence === 'Circuit'
  );
  const conditioning = exercises.filter(
    exercise => exercise.workoutSequence === 'Conditioning'
  );
  const cooldown = exercises.filter(
    exercise => exercise.workoutSequence === 'Cool Down'
  );

  if (!isMobile()) {
    // Web version
    return (
      <div>
        <TopNav history={history} handleBack={handleBack} />
        {workout ? (
          <FlexContainer flexDirection="column" alignItems="center">
            <CardContainer flexDirection="column" data-test="workout-card">
              <TopContainer alignItems="stretch" justify="space-between">
                <LeftContainer alignItems="center">
                  <WorkoutImage
                    image={workoutImgUrl()}
                    image2x={`${workoutImgUrl(2)} 2x`}
                  >
                    <BackIconContainer>
                      <BackIcon
                        handleClick={handleBack}
                        backgroundcolor="#fff"
                        width="40px"
                        height="40px"
                        borderradius="20px"
                        data-test="workoutPage-back"
                      />
                    </BackIconContainer>
                  </WorkoutImage>
                  <WorkoutInfoContainer flexDirection="column">
                    <WorkoutHeader data-test="workout-card-name">
                      {workout?.name}
                    </WorkoutHeader>
                    <FlexContainer flexDirection="column" gap="8px">
                      <Row alignItems="center">
                        <WorkoutInfo data-test="workout-card-calories">
                          <span style={{ fontWeight: 700 }}>
                            {caloriesBurned}
                          </span>{' '}
                          calories
                        </WorkoutInfo>
                        <WorkoutInfo data-test="workout-card-duration">
                          <span style={{ fontWeight: 700 }}>{duration}</span>{' '}
                          mins
                        </WorkoutInfo>
                      </Row>
                      <IntensityContainer>
                        <WorkoutInfo>Intensity: </WorkoutInfo>
                        <IntensitySet
                          intensity={intensity}
                          data-test="workout-card-intensity"
                        />
                      </IntensityContainer>
                    </FlexContainer>
                    {workout?.source && (
                      <Attribution
                        href={formatAttrLink(workout?.attrLink)}
                        data-test="workout-card-source"
                      >
                        Workout by {workout?.source}
                      </Attribution>
                    )}
                  </WorkoutInfoContainer>
                </LeftContainer>
              </TopContainer>

              <BottomContainer flexDirection="column">
                {!isEmpty(warmup) && (
                  <SampleWorkoutSection
                    sectionTitle="Warm Up"
                    rest={workout?.restBetweenSets.warmUp || 1}
                    exercises={warmup}
                    workout={workout}
                  />
                )}
                {!isEmpty(core) && (
                  <SampleWorkoutSection
                    sectionTitle="Core"
                    rest={workout?.restBetweenSets.core || 1}
                    exercises={core}
                    workout={workout}
                  />
                )}
                {!isEmpty(strength) && (
                  <SampleWorkoutSection
                    sectionTitle="Strength"
                    rest={workout?.restBetweenSets.strength || 1}
                    exercises={strength}
                    workout={workout}
                  />
                )}
                {!isEmpty(circuit) && (
                  <SampleWorkoutSection
                    sectionTitle="Circuit"
                    rest={workout?.restBetweenSets.circuit || 1}
                    exercises={circuit}
                    workout={workout}
                  />
                )}
                {!isEmpty(conditioning) && (
                  <SampleWorkoutSection
                    sectionTitle="Conditioning"
                    rest={workout?.restBetweenSets.conditioning || 1}
                    exercises={conditioning}
                    workout={workout}
                  />
                )}
                {!isEmpty(cooldown) && (
                  <SampleWorkoutSection
                    sectionTitle="Cool Down"
                    rest={workout?.restBetweenSets.coolDown || 1}
                    exercises={cooldown}
                    workout={workout}
                  />
                )}
              </BottomContainer>
            </CardContainer>
          </FlexContainer>
        ) : (
          <NotFound />
        )}
      </div>
    );
  } else {
    // Mobile version
    return (
      <>
        <TopNav handleBack={handleBack} history={history} />
        {workout ? (
          <WorkoutContainer data-test="mobile-workout-card">
            <ImageContainter>
              <MobileImage
                image={workoutImgUrl()}
                image2x={`${workoutImgUrl(2)} 2x`}
                data-test="mobile-workout-image"
              />
            </ImageContainter>
            <MobCardContainer flexDirection="column">
              <MobTopContainer
                alignItems="center"
                justify="space-between"
                flexDirection="column"
              >
                <MobileWorkoutInfoContainer
                  flexDirection="column"
                  alignItems="center"
                >
                  <MobWorkoutHeader data-test="mobile-workout-name">
                    {workout?.name}
                  </MobWorkoutHeader>
                  <Row alignItems="center">
                    <WorkoutInfo data-test="mobile-workout-calories">
                      <span style={{ fontWeight: 700 }}>{caloriesBurned}</span>{' '}
                      calories
                    </WorkoutInfo>
                    <WorkoutInfo data-test="mobile-workout-duration">
                      <span style={{ fontWeight: 700 }}>{duration}</span> mins
                    </WorkoutInfo>
                  </Row>
                  <IntensityContainer>
                    <WorkoutInfo>Intensity: </WorkoutInfo>
                    <IntensitySet
                      intensity={intensity}
                      data-test="mobile-workout-intensity"
                    />
                  </IntensityContainer>
                  {workout?.source && (
                    <Attribution
                      href={formatAttrLink(workout?.attrLink)}
                      data-test="mobile-workout-source"
                    >
                      Workout by {workout?.source}
                    </Attribution>
                  )}
                </MobileWorkoutInfoContainer>
              </MobTopContainer>
              <BottomContainer flexDirection="column">
                {!isEmpty(warmup) && (
                  <SampleWorkoutSection
                    sectionTitle="Warm Up"
                    rest={workout?.restBetweenSets.warmUp || 1}
                    exercises={warmup}
                    workout={workout}
                  />
                )}
                {!isEmpty(core) && (
                  <SampleWorkoutSection
                    sectionTitle="Core"
                    rest={workout?.restBetweenSets.core || 1}
                    exercises={core}
                    workout={workout}
                  />
                )}
                {!isEmpty(strength) && (
                  <SampleWorkoutSection
                    sectionTitle="Strength"
                    rest={workout?.restBetweenSets.strength || 1}
                    exercises={strength}
                    workout={workout}
                  />
                )}
                {!isEmpty(circuit) && (
                  <SampleWorkoutSection
                    sectionTitle="Circuit"
                    rest={workout?.restBetweenSets.circuit || 1}
                    exercises={circuit}
                    workout={workout}
                  />
                )}
                {!isEmpty(conditioning) && (
                  <SampleWorkoutSection
                    sectionTitle="Conditioning"
                    rest={workout?.restBetweenSets.conditioning || 1}
                    exercises={conditioning}
                    workout={workout}
                  />
                )}
                {!isEmpty(cooldown) && (
                  <SampleWorkoutSection
                    sectionTitle="Cool Down"
                    rest={workout?.restBetweenSets.coolDown || 1}
                    exercises={cooldown}
                    workout={workout}
                  />
                )}
              </BottomContainer>
            </MobCardContainer>
          </WorkoutContainer>
        ) : (
          <NotFound />
        )}
      </>
    );
  }
};

/** Web **/

const CardContainer = styled(FlexContainer)`
  margin: 28px 0;
  border: 1px solid ${colors.primary200};
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  min-height: 144px;
  min-width: 600px;
  max-width: 900px;
  position: relative;
`;

const TopContainer = styled(FlexContainer)`
  padding: 32px;
`;

const BottomContainer = styled(FlexContainer)`
  padding-bottom: 48px;
`;

const LeftContainer = styled(FlexContainer)`
  height: 100%;
`;

const WorkoutInfoContainer = styled(FlexContainer)`
  margin-left: 32px;
  margin-right: 12px;
  gap: 24px;
`;

const Row = styled(FlexContainer)`
  gap: 24px;
`;

const WorkoutHeader = styled.h2`
  color: ${colors.primary800};
  font-weight: 700;
  font-size: 32px;
  line-height: normal;
`;

const WorkoutInfo = styled.h6`
  font-weight: 400;
  color: ${colors.primary800};
`;

const IntensityContainer = styled(FlexContainer)`
  > :first-child {
    margin-right: 8px;
  }
`;

const Attribution = styled.a`
  color: ${colors.primary400};
  text-decoration: none;
  font-size: 14px;
`;

const BackIconContainer = styled(FlexContainer)`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const WorkoutImage = styled.div`
  height: 334px; // Update image dimensions accordingly.
  width: 500px; // Update image dimensions accordingly.
  border-radius: 4px;
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  position: relative;
`;

/** Mobile **/

const MobileImage = styled.div`
  height: 242px; // Update image dimensions accordingly.
  width: 100%; // Update image dimensions accordingly.
  border-radius: 4px;
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  position: relative;
  margin-top: 44px;
  opacity: 1;
  border: none;
`;

const WorkoutContainer = styled.div`
  overflow-x: hidden;
`;

const ImageContainter = styled.div`
  position: relative;
`;

const MobCardContainer = styled(FlexContainer)`
  border-bottom: 1px solid ${colors.primary300};
  border-radius: 4px;
  background-color: #fff;
  min-height: 184px;
  position: relative;
`;

const MobTopContainer = styled(FlexContainer)`
  padding: 24px;
  gap: 18px;
`;

const MobWorkoutHeader = styled.h2`
  color: ${colors.primary800};
  letter-spacing: 0;
  font-weight: 700;
  text-align: center;
`;

const MobileWorkoutInfoContainer = styled(FlexContainer)`
  gap: 8px;
`;

export default WorkoutSample;
