import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '../../elements/Button';
import { colors } from '../../styleConstants';
import TextInput from '../../elements/TextInput';
import FlexContainer from '../../elements/FlexContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import useFormSubmit from '../../hooks/useFormSubmit';
import { useHistory } from 'react-router-dom';
import { updatePassword, forgotPassword } from '../../store/actions/general';
import { useDispatch, useSelector } from 'react-redux';
import AlertStack from '../../elements/AlertStack';
import { isMobile } from '../../helpers/utils';

const ResetForm = ({
  handleChange,
  values,
  setValues,
  getButtonState,
  resetToken,
  ...props
}) => {
  const [errorOpen, setErrorOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const reduxErrors = useSelector(state => state.errors);

  const submitData = async () => {
    setLoading(true);
    if (resetToken) {
      dispatch(updatePassword(resetToken, values.password))
        .then(result => {
          setLoading(false);
          history.push('/login', {
            message: result.message || 'Password updated',
          });
        })
        .catch(err => {
          console.error(err);
          setLoading(false);
          setErrorOpen(true);
        });
    } else {
      dispatch(forgotPassword(values.email))
        .then(result => {
          setLoading(false);
          history.push('/login', {
            message:
              result.message ||
              'If an account exists for the email provided, you will receive an email to reset your password',
          });
        })
        .catch(err => {
          console.error(err);
          setLoading(false);
          setErrorOpen(true);
        });
    }
  };
  let { handleSubmit, errors } = useFormSubmit(submitData, values, setValues);

  const fields = resetToken ? (
    <>
      <TextInput
        id="password"
        name="password"
        onChange={handleChange}
        type="password"
        value={values.password || ''}
        placeholder="Password"
        label="Password"
        onFocus={e => (e.target.placeholder = '')}
        onBlur={e => (e.target.placeholder = 'Password')}
        error={errors.password}
        disabled={loading}
        flat="true"
        theme={isMobile() ? 'light' : 'dark'}
        data-test="reset-password"
      />
      <TextInput
        id="password2"
        name="password2"
        onChange={handleChange}
        type="password"
        value={values.password2 || ''}
        placeholder="Confirm Password"
        label="Confirm Password"
        onFocus={e => (e.target.placeholder = '')}
        onBlur={e => (e.target.placeholder = 'Confirm Password')}
        error={errors.password}
        disabled={loading}
        flat="true"
        theme={isMobile() ? 'light' : 'dark'}
        data-test="reset-password2"
      />
    </>
  ) : (
    <>
      <TextInput
        id="email"
        name="email"
        onChange={handleChange}
        value={values.email || ''}
        placeholder="Email"
        label="Email"
        onFocus={e => (e.target.placeholder = '')}
        onBlur={e => (e.target.placeholder = 'Email')}
        error={errors.email}
        disabled={loading}
        flat="true"
        theme={isMobile() ? 'light' : 'dark'}
        data-test="reset-email"
      />
    </>
  );

  return (
    <Container flexDirection="column">
      {fields}
      <StyledButton
        type="submit"
        buttonText={resetToken ? 'Update Password' : 'Send Reset Link'}
        buttonSize="large"
        pink="true"
        backgrounddark="true"
        disabled={loading}
        onClick={handleSubmit}
        startIcon={
          loading && (
            <CircularProgress
              size={24}
              color="inherit"
              data-test="loading-spinner"
            />
          )
        }
        data-test="auth-submit"
      />
      <FlexContainer style={{ gap: 4 }} justify="center">
        <LinkText>Remembered your password?</LinkText>
        <StyledLink to="/login" data-test="link-to-login">
          Log in now
        </StyledLink>
      </FlexContainer>
      {reduxErrors.length > 0 && (
        <AlertStack
          messages={reduxErrors}
          type="error"
          variant={isMobile() ? 'filled' : 'outlined'}
          open={errorOpen}
          handleClose={() => setErrorOpen(false)}
          autoHideDuration={null}
          data-test="auth-submit-error"
        />
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  gap: 32px;
  width: 100%;
`;

const LinkText = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${() => (isMobile() ? colors.primary800 : colors.primary050)};
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  text-decoration: underline;
  color: ${() => (isMobile() ? colors.secondary600 : colors.primary050)};
`;

const StyledButton = styled(Button)`
  height: ${() => (isMobile() ? '51px' : null)};
  text-transform: ${() => (isMobile() ? 'inherit' : null)};
  font-weight: ${() => (isMobile() ? '700' : null)};
  font-size: ${() => (isMobile() ? '17x' : null)};
  line-height: ${() => (isMobile() ? '20px' : null)};
`;

export default ResetForm;
