import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import { isMobile } from '../helpers/utils';
import FlexContainer from '../elements/FlexContainer';
import Avatar from '@material-ui/core/Avatar';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DefaultProfilePicture from '../icons/DefaultProfilePicture';

const ProfilePhoto = props => {
  return (
    <Container {...props}>
      {props.src ? <AvatarBox src={props.src} /> : <StyledDefaultPhoto />}
      {!isMobile() && (
        <EditBox>
          <FlexContainer flexDirection="column" alignItems="center">
            <EditIconBox />
            <EditText>Edit Photo</EditText>
          </FlexContainer>
        </EditBox>
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  position: relative;
`;

const EditIconBox = styled(EditTwoToneIcon)`
  margin-bottom: 15px;
  border: 2px solid white;
  border-radius: 100%;
  padding: 5px;
  background-color: white;
  color: black;
`;

const EditBox = styled(Avatar)`
  width: 144px;
  height: 144px;
  position: absolute;
  cursor: pointer;
  background-color: ${colors.primary600};
  visibility: hidden;
  &:hover {
    visibility: visible;
  }
`;

const EditText = styled.h4`
  font-size: 18px;
`;

const AvatarBox = styled(Avatar)`
  width: 144px;
  height: 144px;
  cursor: pointer;

  &:hover + ${EditBox} {
    visibility: visible;
  }
`;

const StyledDefaultPhoto = styled(DefaultProfilePicture)`
  width: 144px;
  height: 144px;
  cursor: pointer;

  &:hover + ${EditBox} {
    visibility: visible;
  }
`;

export default ProfilePhoto;
