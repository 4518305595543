import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import Chip from './Chip';
import AddIcon from '../icons/AddIcon';
import ModalContainer from '../sharedModals/ModalContainer';
import useModal from '../hooks/useModal';
import { upperFirst } from 'lodash';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { getFormattedDate, isPastDate } from '../helpers/date';

const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

const Meal = ({ mealTitle, date, recipes, foods, editable, ...props }) => {
  const { open, launchModal, closeModal, context } = useModal(false);

  const chips = recipes
    .concat(foods)
    .sort((a, b) => a.index - b.index)
    .map(item => {
      const type = item.recipe ? 'recipe' : 'food';
      const subItem = item.recipe ? item.recipe : item.food;
      const label = item.recipe
        ? subItem.name
        : upperFirst(subItem.verboseName);
      const avatar = item.recipe
        ? subItem.imageUrl
        : `${fileStorage}/Nutriology_Symbol_375x242.png`;
      return (
        <Draggable
          key={item._id}
          draggableId={`${type}|${item._id}|${item.date}`}
          index={item.index}
          isDragDisabled={!editable}
        >
          {(provided, snapshot) => (
            <ChipContainer
              onClick={() => {
                if (editable) {
                  props.openModal(item);
                }
              }}
              ref={provided.innerRef}
              data-type={type}
              data-test={editable ? 'editable-meal-chip' : 'disabled-meal-chip'}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Chip
                logged={item.eaten}
                key={item._id}
                label={label}
                avatar={avatar}
                leftover={item?.leftover}
                testId={`chip-mealplan-${getFormattedDate(date)}`}
              />
            </ChipContainer>
          )}
        </Draggable>
      );
    });

  const droppableId = `${date}|${mealTitle}`;

  return (
    <MealContainer flexDirection="column" data-test="planner-meal">
      <FlexContainer alignItems="center">
        <MealTitle data-test="planner-mealTitle">{mealTitle}</MealTitle>
        <IconContainer justify="flex-end">
          <AddIcon
            small="true"
            hideLabel
            green="true"
            onClick={e => {
              if (editable) {
                launchModal(e);
              }
            }}
            data-meal={mealTitle}
            data-date={date}
            data-variant="mealPlan"
            data-test="planner-add-meal"
          />
          <ModalContainer
            open={open}
            context={context}
            handleClose={closeModal}
            initialScreen="Add Meal"
            {...props}
          />
        </IconContainer>
      </FlexContainer>
      <Droppable
        droppableId={droppableId}
        type="MEAL"
        isDropDisabled={isPastDate(date)}
      >
        {(provided, snapshot) => (
          <ChipsContainer
            ref={provided.innerRef}
            {...provided.droppableProps}
            data-test="meal-chip-droppable"
          >
            {chips}
            {provided.placeholder}
          </ChipsContainer>
        )}
      </Droppable>
    </MealContainer>
  );
};

const MealContainer = styled(FlexContainer)`
  padding-bottom: 17px;
`;

const IconContainer = styled(FlexContainer)`
  flex-grow: 1;
  margin-right: 5px;
`;

const MealTitle = styled.p`
  color: ${colors.secondary600};
  text-transform: uppercase;
  font-size: 13px;
  margin-top: 3px;
  margin-left: 7px;
  text-align: left;
`;

const ChipsContainer = styled.div`
  min-height: 5px;
  margin: 0 5px;
  > * {
    margin: 4px 0;
  }
`;

const ChipContainer = styled.div`
  background-color: white;
  border-radius: 200px;
`;

export default Meal;
