import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MealPlanIcon from '../icons/MealPlanIcon';
import FitnessPlanIcon from '../icons/FitnessPlanIcon';
import DashboardIcon from '../icons/DashboardIcon';
import AddIconGreen from '../icons/AddIconGreen';
import ShoppingListIcon from '../icons/ShoppingListIcon';
import useReactRouter from 'use-react-router';
import AppBar from '@material-ui/core/AppBar';
import styled from 'styled-components';
import ModalContainer from '../sharedModals/ModalContainer';
import useModal from '../hooks/useModal';
import { formatDate } from '../helpers/date';
import { trackEvent } from '../integrations/analytics';
import {
  addFoodToMealPlan,
  addRecipeToMealPlan,
} from '../store/actions/mealplan';
import {
  addWorkoutToFitnessPlan,
  addActivityToFitnessPlan,
} from '../store/actions/fitnessplan';

const path = [
  '/app/explore',
  '/app/mealplan',
  '/app',
  '/app/fitnessplan',
  '/app/shoppinglist',
];

const BottomNav = ({
  title: selectedTabTitle,
  path: addressUrl,
  selectedDate,
  selectedMealType,
  mealPlan,
  fitnessPlan,
  addFoodToMealPlan,
  addRecipeToMealPlan,
  addWorkoutToFitnessPlan,
  addActivityToFitnessPlan,
  ...props
}) => {
  const { history, location } = useReactRouter();
  const { open, context, launchModal, closeModal } = useModal(false);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    if (selectedTabTitle === 'Meal Plan') setSelectedTabIndex(1);
    if (selectedTabTitle === 'Fitness Plan') setSelectedTabIndex(3);
    if (selectedTabTitle === 'Shopping List') setSelectedTabIndex(4);
  }, []);

  const addFoodToUserMealPlan = params => {
    trackEvent(`Add Food (${selectedTabTitle} - Mobile)`, {
      food: params.food,
    });
    addFoodToMealPlan(mealPlan._id, params);
  };

  const addRecipeToUserMealPlan = params => {
    trackEvent(`Add Recipe (${selectedTabTitle} - Mobile)`, {
      recipe: params.recipe,
    });
    addRecipeToMealPlan(mealPlan._id, params);
  };

  const addWorkoutToUserFitnessPlan = params => {
    trackEvent(`Add Workout (${selectedTabTitle} - Mobile)`, {
      workout: params.workout,
    });
    addWorkoutToFitnessPlan(fitnessPlan._id, params);
  };

  const addActivityToUserFitnessPlan = params => {
    trackEvent(`Add Activity (${selectedTabTitle} - Mobile)`, {
      activity: params.activity,
    });
    addActivityToFitnessPlan(fitnessPlan._id, params);
  };

  const handleCloseModal = () => {
    if (location.pathname.includes('/mealplan')) {
      closeModal();
      history.push('/app/mealplan');
    } else {
      closeModal();
    }
  };

  return (
    <StyledAppBar>
      <StyledBottomNavigation
        value={selectedTabIndex}
        onChange={(event, newValue) => {
          setSelectedTabIndex(newValue);
          closeModal();
          if (newValue === 2) return;
          history.push(path[newValue]);
        }}
        showLabels
      >
        <BottomNavigationAction
          label="Explore"
          icon={<DashboardIcon />}
          data-test="bottomNav-home"
        />
        <BottomNavigationAction
          label="Meals"
          icon={<MealPlanIcon />}
          data-test="bottomNav-meals"
        />
        <BottomNavigationAction
          label=""
          icon={
            <div style={{ transform: open ? 'rotate(45deg)' : 'none' }}>
              <AddIconGreen />
            </div>
          }
          onClick={e => {
            if (open) {
              closeModal();
            } else {
              launchModal(e);
            }
          }}
          data-date={formatDate(selectedDate)}
          data-variant={addressUrl.slice(5) || 'dashboard'}
          data-meal={selectedMealType}
          data-show-only="both"
          data-hide={selectedTabTitle === 'Shopping List' && 'recipe'}
          data-type={selectedTabTitle === 'Shopping List' && 'shoppinglist'}
          data-test="bottomNav-add"
        />
        <BottomNavigationAction
          label="Fitness"
          icon={<FitnessPlanIcon />}
          data-test="bottomNav-fitness"
        />
        <BottomNavigationAction
          label="Shopping"
          icon={<ShoppingListIcon />}
          data-test="bottomNav-shopping"
        />
      </StyledBottomNavigation>

      {open && (
        <ModalContainer
          context={context}
          open={open}
          handleClose={handleCloseModal}
          initialScreen={
            selectedTabTitle === 'Explore'
              ? 'Add Generic'
              : selectedTabTitle === 'Meal Plan' ||
                selectedTabTitle === 'Recipe' ||
                selectedTabTitle === 'Food'
              ? 'Add Meal Mobile'
              : selectedTabTitle === 'Shopping List'
              ? 'Meal Search'
              : 'Exercise Search' // TODO: When more fitness options added, make this go to 'Add Fitness'
            // https://app.clickup.com/t/1mzumqk
          }
          addFood={addFoodToUserMealPlan}
          addRecipe={addRecipeToUserMealPlan}
          addWorkout={addWorkoutToUserFitnessPlan}
          addActivity={addActivityToUserFitnessPlan}
        />
      )}
    </StyledAppBar>
  );
};

const StyledAppBar = styled(AppBar)`
  top: auto;
  bottom: 0;
`;

const StyledBottomNavigation = styled(BottomNavigation)`
  position: sticky;
  bottom: 0;
  width: 100vw;
  height: 64px;
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.08);
`;

function mapStateToProps(state) {
  const {
    selectedDate,
    selectedMealType,
    selectedMealPlan,
    selectedFitnessPlan,
  } = state;
  return {
    selectedDate,
    selectedMealType,
    mealPlan: selectedMealPlan,
    fitnessPlan: selectedFitnessPlan,
  };
}

export default connect(
  mapStateToProps,
  {
    addFoodToMealPlan,
    addRecipeToMealPlan,
    addWorkoutToFitnessPlan,
    addActivityToFitnessPlan,
  }
)(BottomNav);
