import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import { isMobile } from '../../helpers/utils';

const Exercise = ({ exercise, ...props }) => {
  if (isMobile()) {
    // Mobile version
    return (
      <FlexContainer flexDirection="column">
        <ExerciseInfoContainerMobile alignItems="center">
          <Thumbnail
            data-test="workoutModal-thumbnail"
            image={exercise.exercise.thumbnailUrl}
          />
          <ExerciseNameMobile data-test="workoutModal-exerciseName">
            {exercise.exercise.name}
          </ExerciseNameMobile>
        </ExerciseInfoContainerMobile>
      </FlexContainer>
    );
  }
  // Web version
  return (
    <FlexContainer flexDirection="column" style={{ position: 'relative' }}>
      <ExerciseInfoContainer justify="space-between">
        <LeftContainer alignItems="center">
          <Thumbnail
            data-test="workoutModal-thumbnail"
            image={exercise.exercise.thumbnailUrl}
          />
          <ExerciseName data-test="workoutModal-exerciseName">
            {exercise.exercise.name}
          </ExerciseName>
        </LeftContainer>
      </ExerciseInfoContainer>
    </FlexContainer>
  );
};

const ExerciseInfoContainer = styled(FlexContainer)`
  position: relative;
  margin-left: 40px;
  margin-top: 20px;
`;

const Thumbnail = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: ${isMobile() ? '0' : '4px'};
  width: ${isMobile() ? '76px' : '66px'};
  height: ${isMobile() ? '57px' : '44px'};
`;

const ExerciseName = styled.h4`
  color: ${colors.primary800};
`;

const LeftContainer = styled(FlexContainer)`
  > * {
    margin-right: 24px;
  }
`;
const ExerciseInfoContainerMobile = styled(FlexContainer)`
  box-shadow: 0 1px 0 0 #eeebeb;
  margin-bottom: 1px;
`;
const ExerciseNameMobile = styled.h4`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 17px;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 12px;
`;
export default Exercise;
