import {
  SET_ACTIVE_MEAL_PLAN,
  SET_SELECTED_MEAL_PLAN,
  SET_DEFAULT_PLACEHOLDER,
} from '../actionTypes';

const DEFAULT_STATE_MEAL_PLAN = {
  id: null,
  type: 'Weight Loss',
  planLength: 7,
  recommendedCalories: 1800,
  active: true,
  recipes: [],
  foods: [],
  water: [],
};

const DEFAULT_PLACEHOLDER_RECIPE = {
  _id: '5f44f79445e7ef000d60fb5c',
  __t: 'RecipeAdmin',
  imageUrl:
    'https://nutriology-dev.s3.eu-west-2.amazonaws.com/PlaceholderImage.png',
  tags: {
    dietType: [],
    mealType: [],
    cookingStyle: [],
    primaryIngredient: [''],
    intolerances: [],
    prepTime: [],
  },
  servingSize: 1,
  yield: 1,
  version: 'N/A',
  nutrients: {
    calories: 700,
    fat: 10,
    saturatedFat: 0,
    carbohydrates: 20,
    proteins: 12,
    fiber: 0,
    totalSugar: 0,
    addedSugar: 0,
    transFat: 0,
    cholesterol_mg: 0,
    vitaminD_mcg: 0,
    calcium_mg: 0,
    potassium_mg: 0,
    sodium_mg: 0,
  },
  name: 'Enjoy a Meal Out!',
  placeholder: true,
  placeholderDefault: true,
  cookingInstructions: [],
  foods: [],
};

export const activeMealPlan = (state = DEFAULT_STATE_MEAL_PLAN, action) => {
  switch (action.type) {
    case SET_ACTIVE_MEAL_PLAN:
      return {
        ...state,
        ...action.mealPlan,
      };

    default:
      return state;
  }
};
// Selected meal plan allows us to distinguish when a user is updating a meal plan in the future,
// e.g. next week, from the current active meal plan, which should not change.
export const selectedMealPlan = (state = DEFAULT_STATE_MEAL_PLAN, action) => {
  switch (action.type) {
    case SET_SELECTED_MEAL_PLAN:
      return {
        ...state,
        ...action.mealPlan,
      };

    default:
      return state;
  }
};

export const defaultPlaceholder = (
  state = DEFAULT_PLACEHOLDER_RECIPE,
  action
) => {
  switch (action.type) {
    case SET_DEFAULT_PLACEHOLDER:
      return {
        ...state,
        ...action.placeholder,
      };

    default:
      return state;
  }
};
