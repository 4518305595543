import React, { useState } from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import { getImageURL } from '../services/api/api';
import { isMobile } from '../helpers/utils';
import ExerciseModal from '../fitnessPlan/exercise/ExerciseModal';

const SampleWorkoutSection = ({
  sectionTitle,
  rest,
  exercises,
  workout,
  variant,
  removeExercise,
  ...props
}) => {
  const getDescriptionTextForSection = sectionTitle => {
    switch (sectionTitle) {
      case 'Warm Up':
        return 'Complete at your own pace';
      case 'Cool Down':
        return 'Relax and complete at your own pace';
      default:
        return 'Complete reps for each exercise in the sequence, then repeat';
    }
  };

  const handleRemove = exerciseId => {
    removeExercise(workout, exerciseId);
  };

  const exerciseList = exercises.map((exercise, i) => (
    <Exercise
      key={exercise._id}
      exercise={exercise.exercise}
      exerciseId={exercise._id}
      workout={workout}
      handleRemove={handleRemove}
      sets={exercise.sets || []}
      previousSet={exercise?.previousSet}
      exerciseIndex={i}
      exercisesLength={exercises.length}
      {...props}
    />
  ));

  if (!isMobile()) {
    // Web version
    return (
      <Section flexDirection="column">
        <HeaderRow justify="space-between" alignItems="center">
          <FlexContainer flexDirection="column" style={{ gap: 6 }}>
            <SectionTitle data-test="workout-card-sectionTitle">
              {sectionTitle}
            </SectionTitle>
            <SectionDescription>
              {getDescriptionTextForSection(sectionTitle)}
            </SectionDescription>
          </FlexContainer>

          <RestInfo flexDirection="column" justify="space-between">
            Rest between sets:
            <RestInfoValue
              data-test={`workout-${sectionTitle.split(' ').join('')}-rest`}
            >
              {rest} mins
            </RestInfoValue>
          </RestInfo>
        </HeaderRow>
        <ExerciseContainer
          flexDirection="column"
          data-test={`workout-${sectionTitle.split(' ').join('')}-exerciseList`}
        >
          {exerciseList}
        </ExerciseContainer>
      </Section>
    );
  } else {
    // Mobile version
    return (
      <MobSection flexDirection="column">
        <HeaderRow justify="space-between" alignItems="flex-start">
          <MobTitleDescContainer flexDirection="column">
            <SectionTitle data-test="mobile-workout-sectionTitle">
              {sectionTitle}
            </SectionTitle>
            <SectionDescription>
              {getDescriptionTextForSection(sectionTitle)}
            </SectionDescription>
          </MobTitleDescContainer>
          <MobRestInfo flexDirection="column" justify="space-between">
            Rest:
            <RestInfoValue
              data-test={`workout-${sectionTitle.split(' ').join('')}-rest`}
            >
              {rest} mins
            </RestInfoValue>
          </MobRestInfo>
        </HeaderRow>
        <ExerciseContainer
          flexDirection="column"
          data-test={`workout-${sectionTitle.split(' ').join('')}-exerciseList`}
        >
          {exerciseList}
        </ExerciseContainer>
      </MobSection>
    );
  }
};

/** Mobile **/
const MobTitleDescContainer = styled(FlexContainer)`
  max-width: 60%;
  gap: 6px;
`;

const MobSection = styled(FlexContainer)`
  border-top: 0.5px solid ${colors.primary400};
  padding: 31px 0 16px 0;
`;

const MobRestInfo = styled(FlexContainer)`
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary400};
  padding-right: 16px;
  gap: 6px;
`;

/** Web**/

const Section = styled(FlexContainer)`
  border-top: 0.5px solid ${colors.primary400};
  padding: 16px 0 16px 0;
`;

const HeaderRow = styled(FlexContainer)`
  width: ${() => (isMobile() ? '90%' : 'calc(100% - 74px)')};
  padding-right: ${() => (isMobile() ? 'initial' : '36px')};
  padding-left: ${() => (isMobile() ? '30px' : '40px')};
`;

const SectionTitle = styled.h4`
  color: ${colors.primary600};
  text-transform: uppercase;
  font-weight: bold;
`;

const SectionDescription = styled.p`
  font-size: 13px;
  line-height: 18px;
  color: ${colors.primary600};
`;

const RestInfo = styled(FlexContainer)`
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary400};
  gap: 6px;
`;

const ExerciseContainer = styled(FlexContainer)`
  > * {
    width: 100%;
  }
  padding-top: ${() => (isMobile() ? '32px' : 'initial')};
  gap: ${() => (isMobile() ? '32px' : 'initial')};
`;

const RestInfoValue = styled.div`
  color: ${colors.primary600};
`;

const Exercise = ({
  exercise,
  sets,
  exerciseId,
  workout,
  exerciseIndex,
  exercisesLength,
  ...props
}) => {
  const exerciseURL = (ratio = 1) => {
    const width = 96 * ratio;
    const widthMobile = 120 * ratio;
    const height = 64 * ratio;
    const heightMobile = 84 * ratio;
    return isMobile()
      ? getImageURL(
          exercise.thumbnailUrl,
          `resizing_type:fill/height:${heightMobile}/width:${widthMobile}`
        )
      : getImageURL(
          exercise.thumbnailUrl,
          `resizing_type:fill/height:${height}/width:${width}`
        );
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [img, setImg] = useState({});

  const openModal = exerciseImg => {
    setImg(exerciseImg);
    setModalOpen(true);
  };

  const closeModalItem = () => {
    setModalOpen(false);
    setImg({});
  };

  if (isMobile()) {
    return (
      <ExerciseRow
        flexDirection="column"
        style={{ position: 'relative', width: '100%' }}
        data-test="exerciseRow"
      >
        <ExerciseInfoContainer justify="space-between">
          <LeftContainer alignItems="center">
            <MobileThumbnail
              image={exerciseURL()}
              image2x={exerciseURL(2)}
              onClick={() => openModal(exercise.imageUrl)}
              data-test="exerciseRow-thumbnail"
            />
            <MobileExerciseName
              onClick={() => openModal(exercise.imageUrl)}
              data-test="exerciseRow-name"
            >
              {exercise.name}
            </MobileExerciseName>
          </LeftContainer>
        </ExerciseInfoContainer>
        <ExerciseModal
          open={modalOpen}
          img={img}
          handleClose={closeModalItem}
        />
      </ExerciseRow>
    );
  } else {
    return (
      <FlexContainer
        flexDirection="column"
        style={{ position: 'relative' }}
        data-test="exerciseRow"
      >
        <HR />
        <ExerciseInfoContainer justify="space-between">
          <LeftContainer alignItems="center">
            <Thumbnail
              image={exerciseURL()}
              image2x={exerciseURL(2)}
              onClick={() => openModal(exercise.imageUrl)}
              data-test="exerciseRow-thumbnail"
            />
            <ExerciseName
              onClick={() => openModal(exercise.imageUrl)}
              data-test="exerciseRow-name"
            >
              {exercise.name}
            </ExerciseName>
          </LeftContainer>
        </ExerciseInfoContainer>
        <ExerciseModal
          open={modalOpen}
          img={img}
          name={exercise.name}
          handleClose={closeModalItem}
        />
      </FlexContainer>
    );
  }
};

const ExerciseInfoContainer = styled(FlexContainer)`
  position: relative;
  margin-left: ${() => (isMobile() ? '30px' : '40px')};
`;

const HR = styled.hr`
  border: none;
  height: 1px;
  width: calc(100% - 80px);
  margin: 16px 40px;
  background-color: ${colors.primary200};
`;

const LeftContainer = styled(FlexContainer)`
  gap: 18px;
`;
const Thumbnail = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  width: 96px; // Update image dimensions accordingly.
  height: 64px; // Update image dimensions accordingly.

  &:hover {
    cursor: pointer;
  }
`;

const ExerciseName = styled.h3`
  color: ${colors.primary700};

  &:hover {
    cursor: pointer;
    color: ${colors.primary500};
  }
`;

const ExerciseRow = styled(FlexContainer)`
  position: relative;
  width: 100%;
`;

const MobileThumbnail = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  width: 120px; // Update image dimensions accordingly.
  height: 84px; // Update image dimensions accordingly.
  min-width: 120px;

  &:hover {
    cursor: pointer;
  }
`;

const MobileExerciseName = styled.h3`
  color: ${colors.primary700};
  padding-right: 48px;
  font-size: 16px;

  &:hover {
    cursor: pointer;
    color: ${colors.primary500};
  }
`;

export default SampleWorkoutSection;
