import moment from 'moment';

/**
 * Format the given date in a ISO 8601 UTC date time format with the time set to 0
 * @param dateInput
 * @param convert boolean Whether or not to take into consideration the timezone
 * @returns the date formatted.
 */
export const formatDate = (dateInput = new Date(), convert = true) => {
  // In rare cases where we get a date parameter in string format
  // and convert it into a date object, this would skip a day.
  // This happens when there is a timezone difference with the client.
  // To prevent this we make sure the date is converted to utc
  // before stripping the timezone data.

  // We are given an Date object as input
  // TODO: Remove after investigation
  if (typeof dateInput === 'object' && !convert) {
    // Convert the date in UTC without changing the timezone:
    let newDate = new Date(dateInput);
    newDate.setUTCFullYear(dateInput.getFullYear());
    newDate.setUTCMonth(dateInput.getMonth());
    newDate.setUTCDate(dateInput.getDate());
    dateInput = newDate.toISOString();
  }
  let dateOutput = moment(dateInput).parseZone();
  return dateOutput.format('YYYY-MM-DD[T00:00:00.000Z]');
};

export const getFormattedDate = (dateInput = new Date()) =>
  moment(dateInput)
    .parseZone()
    .format('YYYY-MM-DD');

export const isPastDate = date => {
  const today = moment()
    .parseZone()
    .format('YYYY-MM-DD');
  const mDate = moment(date)
    .parseZone()
    .format('YYYY-MM-DD');
  return today > mDate;
};

export const getMonthFromString = mon => {
  return new Date(Date.parse(mon + ' 1, 2021')).getMonth() + 1;
};
