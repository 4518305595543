import React from 'react';
import styled from 'styled-components';
import { isMobile } from '../helpers/utils';
import { Link } from 'react-router-dom';
import { colors, screenSize } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import QuestionnaireProgress from './QuestionnaireProgress';

const Nav = ({ step }) => {
  return (
    <Navbar>
      <Container>
        <Title to="/app">Nutriology</Title>
        {!isMobile() && <QuestionnaireProgress step={step} />}
      </Container>
    </Navbar>
  );
};

const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  height: 64px;
  padding: 0 55px;
  border-bottom: 1px solid ${colors.primary200};
  position: relative;
  z-index: 5;
  flex-shrink: 0;
  background-color: white;

  @media (max-width: ${screenSize.mobileL}) {
    padding: 0 20px;
  }
`;

const Container = styled(FlexContainer)`
  min-width: 960px;
  max-width: 960px;
  margin: auto;

  @media (max-width: ${screenSize.laptopL}) {
    min-width: 100%;
    justify-content: center;
  }
`;

const Title = styled(Link)`
  color: ${colors.secondary600};
  font-size: 20.5px;
  font-weight: 900;
  letter-spacing: 0.73px;
  line-height: 32px;
  text-transform: uppercase;
`;

export default Nav;
