import React, { useState } from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '../icons/CloseIcon';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import DialogContent from '@material-ui/core/DialogContent';
import StripePaymentForm from '../payment/StripePaymentForm';

const ModalContainer = ({
  open,
  handleClose,
  handleChange,
  handleSubmit,
  subscription,
  ...props
}) => {
  const [stripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
  );

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <StyledDialogContent>
          <Container justify="center">
            <NavBar justify="space-between" alignItems="center">
              <CloseRight>
                <CloseIcon
                  handleClose={handleClose}
                  data-test="stripe-modal-close"
                />
              </CloseRight>
            </NavBar>
            <ModalBody
              justify="center"
              alignItems="center"
              data-test="stripe-form"
            >
              <Elements stripe={stripePromise}>
                <StripePaymentForm
                  price={null}
                  subscription={subscription}
                  submitText="Update details"
                  updatePayment={true}
                />
              </Elements>
            </ModalBody>
          </Container>
        </StyledDialogContent>
      </Modal>
    </div>
  );
};

const StyledDialogContent = styled(DialogContent)`
  height: 550px;
  width: 350px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  background-color: white;
  border-radius: 4px;
  outline: 0;
`;
const ModalBody = styled(FlexContainer)`
  width: 100%;
  padding-bottom: 50px;
`;

const NavBar = styled(FlexContainer)`
  height: 58px;
  flex-shrink: 0;
  padding: 0 16px;
`;

const CloseRight = styled(FlexContainer)`
  right: 12px;
  position: absolute;
`;

export default ModalContainer;
