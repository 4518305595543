import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import { formatCalories } from '../helpers/nutrition';

import Meal from './Meal';
import ErrorBoundary from '../services/ErrorBoundary';

const MealList = ({ recipes, foods, variant, date, ...props }) => {
  const breakfastRecipes = recipes.filter(
    recipe => recipe.mealType === 'Breakfast'
  );
  const lunchRecipes = recipes.filter(recipe => recipe.mealType === 'Lunch');
  const dinnerRecipes = recipes.filter(recipe => recipe.mealType === 'Dinner');
  const snackRecipes = recipes.filter(recipe => recipe.mealType === 'Snack');

  const breakfastFoods = foods.filter(food => food.mealType === 'Breakfast');
  const lunchFoods = foods.filter(food => food.mealType === 'Lunch');
  const dinnerFoods = foods.filter(food => food.mealType === 'Dinner');
  const snackFoods = foods.filter(food => food.mealType === 'Snack');

  const recipeCalories = recipes.reduce((total, recipe) => {
    if (recipe.recipe) {
      return total + formatCalories(recipe.recipe?.nutrients?.calories);
    } else {
      return total;
    }
  }, 0);

  const foodCalories = Math.round(
    foods.reduce((total, food) => {
      if (food.food) {
        const calories = formatCalories(
          food.quantity * food.food.nutrientsPerUnit.calories
        );
        return total + calories;
      } else {
        return total;
      }
    }, 0)
  );

  const recipeProtein = Math.round(
    recipes.reduce((total, recipe) => {
      if (recipe.recipe && recipe.recipe.nutrients) {
        return total + recipe.recipe.nutrients.proteins;
      } else {
        return total;
      }
    }, 0)
  );

  const foodProtein = Math.round(
    foods.reduce((total, food) => {
      if (food.food) {
        const protein = food.quantity * food.food.nutrientsPerUnit.proteins;
        return total + protein;
      } else {
        return total;
      }
    }, 0)
  );

  const recipeFat = Math.round(
    recipes.reduce((total, recipe) => {
      if (recipe.recipe && recipe.recipe.nutrients) {
        return total + recipe.recipe.nutrients.fat;
      } else {
        return total;
      }
    }, 0)
  );

  const foodFat = Math.round(
    foods.reduce((total, food) => {
      if (food.food) {
        const fat = food.quantity * food.food.nutrientsPerUnit.fat;
        return total + fat;
      } else {
        return total;
      }
    }, 0)
  );

  const recipeCarbs = Math.round(
    recipes.reduce((total, recipe) => {
      if (recipe.recipe && recipe.recipe.nutrients) {
        return total + recipe.recipe.nutrients.carbohydrates;
      } else {
        return total;
      }
    }, 0)
  );

  const foodCarbs = Math.round(
    foods.reduce((total, food) => {
      if (food.food) {
        const carbs = food.quantity * food.food.nutrientsPerUnit.carbohydrates;
        return total + carbs;
      } else {
        return total;
      }
    }, 0)
  );

  const totalCalories = recipeCalories + foodCalories;
  const totalProtein = recipeProtein + foodProtein;
  const totalFat = recipeFat + foodFat;
  const totalCarbs = recipeCarbs + foodCarbs;

  const totalCaloriesContainer =
    variant === 'dashboard' ? (
      <TotalsContainer justify="flex-end" variant={variant}>
        <TotalCaloriesLabel variant={variant}>
          Total Calories:
        </TotalCaloriesLabel>
        <TotalCaloriesNumber
          variant={variant}
          data-test="mealList-totalCalories"
        >
          {totalCalories}
        </TotalCaloriesNumber>
      </TotalsContainer>
    ) : (
      <TotalsContainer justify="space-between" wrap="wrap" variant={variant}>
        <Row justify="space-between">
          <TotalItem>
            <TotalCaloriesLabel variant={variant}>
              Total Calories:
            </TotalCaloriesLabel>
            <TotalCaloriesNumber
              variant={variant}
              data-test="mealList-totalCalories"
            >
              {totalCalories}
            </TotalCaloriesNumber>
          </TotalItem>
          <TotalItem>
            <TotalCaloriesLabel variant={variant}>
              Total Protein:
            </TotalCaloriesLabel>
            <TotalCaloriesNumber
              variant={variant}
              data-test="mealList-totalProtein"
            >
              {totalProtein}
            </TotalCaloriesNumber>
          </TotalItem>
        </Row>
        <Row justify="space-between">
          <TotalItem>
            <TotalCaloriesLabel variant={variant}>
              Total Carbs:
            </TotalCaloriesLabel>
            <TotalCaloriesNumber
              variant={variant}
              data-test="mealList-totalCarbs"
            >
              {totalCarbs}
            </TotalCaloriesNumber>
          </TotalItem>
          <TotalItem>
            <TotalCaloriesLabel variant={variant}>
              Total Fat:
            </TotalCaloriesLabel>
            <TotalCaloriesNumber
              variant={variant}
              data-test="mealList-totalFat"
            >
              {totalFat}
            </TotalCaloriesNumber>
          </TotalItem>
        </Row>
      </TotalsContainer>
    );

  return (
    <ErrorBoundary>
      <MealsContainer variant={variant} flexDirection="column">
        {variant === 'dashboard' && totalCaloriesContainer}
        <Meal
          mealTitle="Breakfast"
          date={date}
          recipes={breakfastRecipes}
          foods={breakfastFoods}
          variant={variant}
          {...props}
        />
        <Meal
          mealTitle="Lunch"
          date={date}
          recipes={lunchRecipes}
          foods={lunchFoods}
          variant={variant}
          {...props}
        />
        <Meal
          mealTitle="Dinner"
          date={date}
          recipes={dinnerRecipes}
          foods={dinnerFoods}
          variant={variant}
          {...props}
        />
        <Meal
          mealTitle="Snack"
          date={date}
          recipes={snackRecipes}
          foods={snackFoods}
          variant={variant}
          {...props}
        />
        {(variant === 'mealPlan' || variant === 'admin') &&
          totalCaloriesContainer}
      </MealsContainer>
    </ErrorBoundary>
  );
};

const MealsContainer = styled(FlexContainer)`
  width: ${props => (props.variant === 'dashboard' ? '95%' : '100%')};

  > * {
    padding-top: 8px;
    border-top: 1px solid ${colors.primary200};
    margin-bottom: 24px;
  }
`;

const TotalsContainer = styled(FlexContainer)`
  padding-top: ${props => (props.variant === 'dashboard' ? '0' : '8px')};

  border-top: ${props =>
    props.variant === 'dashboard' ? 'none' : `1px solid ${colors.primary200}`};
`;

const TotalItem = styled(FlexContainer)``;

const TotalCaloriesLabel = styled.h4`
  color: ${colors.primary500};
  margin-right: 8px;
  font-size: ${props => (props.variant === 'dashboard' ? '13px' : '17px')};
  line-height: ${props => (props.variant === 'dashboard' ? '15px' : '20px')};
`;
const TotalCaloriesNumber = styled.h4`
  color: ${colors.primary800};

  font-size: ${props => (props.variant === 'dashboard' ? '13px' : '17px')};
  line-height: ${props => (props.variant === 'dashboard' ? '15px' : '20px')};
`;

const Row = styled(FlexContainer)`
  margin-bottom: 6px;
  width: 100%;
  > * {
    margin-right: 8px;
  }
`;

export default MealList;
