import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';
import TextInput from '../../elements/TextInput';
import Button from '../../elements/Button';
import RadioInput from '../../elements/RadioInput';
import useModal from '../../hooks/useModal';
import AddImage from '../../elements/AddImage';
import ModalContainer from '../../sharedModals/ModalContainer';
import { getActivity } from '../../services/api/activity';
import { getRecipe } from '../../services/api/recipe';
import { getWorkout } from '../../services/api/workout';
import { getFood } from '../../services/api/food';
import { getImageURL } from '../../services/api/api';
import { isEmpty } from 'lodash';

const ActionTypeSection = ({
  values,
  handleChange,
  handleAddItem,
  handleAddItemsToShow,
  itemToAdd,
  itemsToShow,
  clearItemsToShow,
  clearAddItem,
  modalImageUrl,
  setModalImageUrl,
  ...props
}) => {
  const actionType = values.actionType.value;
  const [planType, setPlanType] = useState('mealPlan');
  const [itemsType, setItemsType] = useState('recipes');
  const [item, setItem] = useState({});
  const [itemList, setItemList] = useState([]);
  const { open, launchModal, closeModal } = useModal(false);

  const handlePlanTypeChange = e => {
    setPlanType(e.target.value);
  };

  const handleItemsTypeChange = e => {
    setItemsType(e.target.value);
  };

  // Load full item from the ID returned by the modal so we can display it
  const getItem = async (itemId, itemType) => {
    let itemToDisplay = {};
    if (itemType === 'recipe') {
      itemToDisplay = await getRecipe(itemId);
    } else if (itemType === 'food') {
      itemToDisplay = await getFood(itemId);
    } else if (itemType === 'workout') {
      itemToDisplay = await getWorkout(itemId);
    } else if (itemType === 'activity') {
      itemToDisplay = await getActivity(itemId);
    }
    return itemToDisplay;
  };

  const setItemToAdd = async () => {
    const itemToDisplay = await getItem(itemToAdd.itemId, itemToAdd.itemType);
    setItem(itemToDisplay);
  };

  const setItemsToShow = async () => {
    const itemsTmp = [];
    for (let itemToShow of itemsToShow) {
      const itemToDisplay = await getItem(
        itemToShow.itemId,
        itemToShow.itemType
      );
      itemsTmp.push(itemToDisplay);
    }
    setItemList(itemsTmp);
  };

  useEffect(() => {
    if (!isEmpty(itemToAdd)) {
      setItemToAdd();
    }
  }, [itemToAdd]);

  useEffect(() => {
    if (itemsToShow?.length) {
      setItemsToShow();
    }
  }, [itemsToShow]);

  // Ensure we clear items if we switch between item types
  useEffect(() => {
    if (itemsToShow?.length) {
      clearItemsToShow();
      setItemList([]);
    }
  }, [itemsType]);

  // Ensure we clear item to add if we switch between meal/fitness
  useEffect(() => {
    if (!isEmpty(itemToAdd)) {
      clearAddItem();
      setItem({});
    }
  }, [planType]);

  // Handle the header image for the search modal when action type is "Show Items"
  const modalHeaderImageFile = useRef(null);

  const handleChangeModalImage = e => {
    setModalImageUrl(URL.createObjectURL(e.target.files[0]));
    handleChange(e);
  };

  const handleModalImage = () => {
    modalHeaderImageFile.current.click();
  };

  const imageURL = (image, ratio = 1) => {
    const height = 214 * ratio;
    if (planType === 'fitnessPlan' && !image) {
      return `${process.env.PUBLIC_URL}/icons/WorkoutDefault${ratio}x.png`;
    }
    return getImageURL(image, `height:${height}`);
  };

  const mappedItems = itemList.map(itemToShow => {
    return (
      <ItemRow alignItems="center" key={itemToShow._id} data-test="item-row">
        <Thumbnail
          image={imageURL(itemToShow.imageUrl, 1)}
          image2x={imageURL(itemToShow.imageUrl, 2)}
        />
        <ItemName data-test="item-name">{itemToShow.name}</ItemName>
      </ItemRow>
    );
  });

  if (actionType === 'link') {
    return (
      <Container flexDirection="column">
        <TextInput
          id="link"
          name="link"
          type="url"
          pattern="https://.*"
          size="30"
          onChange={handleChange}
          value={values.link || ''}
          placeholder="https://www.example.com"
          label="External Link"
          onFocus={e => (e.target.placeholder = '')}
          onBlur={e => (e.target.placeholder = 'External Link')}
          data-test="exploreBanner-actionType-link"
        />
      </Container>
    );
  } else if (actionType === 'addItem') {
    return (
      <Container>
        <LeftContainer flexDirection="column">
          <RadioContainer flexDirection="column">
            <RadioInput
              checked={planType === 'mealPlan'}
              value="mealPlan"
              label="Meal Plan"
              onChange={handlePlanTypeChange}
              name="recordType"
              data-test="exploreBanner-planType-mealPlan"
            />
            <RadioInput
              checked={planType === 'fitnessPlan'}
              value="fitnessPlan"
              label="Fitness Plan"
              onChange={handlePlanTypeChange}
              name="recordType"
              data-test="exploreBanner-planType-fitnessPlan"
            />
          </RadioContainer>
          {planType === 'mealPlan' && (
            <>
              <Button
                buttonText="Find Meal Items"
                buttonSize="large"
                width="300px"
                handleClick={launchModal}
                data-test="meal-find"
              />
              <ModalContainer
                open={open}
                context={{
                  variant: 'admin',
                }}
                initialScreen="Meal Search"
                handleClose={closeModal}
                addRecipe={handleAddItem}
                addFood={handleAddItem}
                {...props}
              />
            </>
          )}
          {planType === 'fitnessPlan' && (
            <>
              <Button
                buttonText="Find Fitness Items"
                buttonSize="large"
                width="300px"
                handleClick={launchModal}
                data-test="fitness-find"
              />
              <ModalContainer
                open={open}
                context={{
                  variant: 'admin',
                }}
                initialScreen="Exercise Search"
                handleClose={closeModal}
                addWorkout={handleAddItem}
                addActivity={handleAddItem}
                {...props}
              />
            </>
          )}
        </LeftContainer>
        <RightContainer flexDirection="column">
          <h3>Item To Add</h3>
          {!isEmpty(item) && (
            <ItemRow alignItems="center" data-test="item-row">
              <Thumbnail
                image={imageURL(item.imageUrl, 1)}
                image2x={imageURL(item.imageUrl, 2)}
              />
              <ItemName data-test="item-name">{item.name}</ItemName>
            </ItemRow>
          )}
        </RightContainer>
      </Container>
    );
  } else if (actionType === 'showItems') {
    return (
      <Container>
        <LeftContainer flexDirection="column">
          <RadioContainer flexDirection="column">
            <RadioInput
              checked={itemsType === 'recipes'}
              value="recipes"
              label="Recipes"
              onChange={handleItemsTypeChange}
              name="itemsType"
              data-test="exploreBanner-itemsType-recipes"
            />
            <RadioInput
              checked={itemsType === 'workouts'}
              value="workouts"
              label="Workouts"
              onChange={handleItemsTypeChange}
              name="itemsType"
              data-test="exploreBanner-itemsType-workouts"
            />
          </RadioContainer>
          {itemsType === 'recipes' && (
            <>
              <Button
                buttonText="Find Recipes"
                buttonSize="large"
                width="300px"
                handleClick={launchModal}
                data-test="recipe-find"
              />
              <ModalContainer
                open={open}
                context={{
                  variant: 'admin',
                }}
                initialScreen="Meal Search"
                handleClose={closeModal}
                addRecipe={handleAddItemsToShow}
                {...props}
              />
            </>
          )}
          {itemsType === 'workouts' && (
            <>
              <Button
                buttonText="Find Workouts"
                buttonSize="large"
                width="300px"
                handleClick={launchModal}
                data-test="workout-find"
              />
              <ModalContainer
                open={open}
                context={{
                  variant: 'admin',
                  showOnly: 'workout',
                }}
                initialScreen="Exercise Search"
                handleClose={closeModal}
                addWorkout={handleAddItemsToShow}
                {...props}
              />
            </>
          )}
          {itemsType === 'activities' && (
            <>
              <Button
                buttonText="Find Activities"
                buttonSize="large"
                width="300px"
                handleClick={launchModal}
                data-test="activity-find"
              />
              <ModalContainer
                open={open}
                context={{
                  variant: 'admin',
                }}
                initialScreen="Exercise Search"
                handleClose={closeModal}
                addActivity={handleAddItemsToShow}
                {...props}
              />
            </>
          )}
          <ImageLabel>Modal Header Image</ImageLabel>
          <AddImage src={modalImageUrl} onClick={handleModalImage} />
          <input
            hidden
            ref={modalHeaderImageFile}
            type="file"
            name="modalImage"
            onChange={handleChangeModalImage}
          />
          <TextInput
            id="modalHeaderTitle"
            name="modalHeaderTitle"
            size="30"
            onChange={handleChange}
            value={values.modalHeaderTitle || ''}
            label="Modal Header Title"
            data-test="exploreBanner-showItems-modalHeader"
          />
        </LeftContainer>
        <RightContainer flexDirection="column">
          <h3>Items To Show</h3>
          {!isEmpty(itemsToShow) && mappedItems}
        </RightContainer>
      </Container>
    );
  }
  // If no action type selected, show nothing
  return <div></div>;
};

const Container = styled(FlexContainer)`
  width: 100%;
  gap: 42px;
`;

const LeftContainer = styled(FlexContainer)`
  padding: 16px 0;
  gap: 16px;
`;

const RightContainer = styled(FlexContainer)`
  gap: 16px;
  padding: 16px 0;
`;

const RadioContainer = styled(FlexContainer)``;

const ItemRow = styled(FlexContainer)`
  gap: 16px;
`;

const ImageLabel = styled.h4`
  color: ${colors.secondary600};
`;

const Thumbnail = styled(FlexContainer)`
  width: 110px;
  height: 80px;
  min-width: 110px;
  min-height: 80px;
  border-radius: 4px;
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const ItemName = styled.h5`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${colors.primary900};
`;

export default ActionTypeSection;
