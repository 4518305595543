import React, { useState, useEffect } from 'react';
import ExploreSection from './ExploreSection';
let Parser = require('rss-parser');

const BlogFeed = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadBlogs = async () => {
    try {
      setLoading(true);
      let parser = new Parser();
      let url = 'https://nutriology.io/feed';

      let feed = await parser.parseURL(url);
      const items = feed.items;
      const updatedItems = items
        .filter(
          item =>
            !item.categories.includes('Recipes') &&
            !item.categories.includes('Workouts')
        )
        .map(item => {
          const imageUrl = item['content:encoded'].match(
            /<featured_image>(.*?)<\/featured_image>/
          )[1];

          return { ...item, imageUrl: imageUrl };
        });
      setBlogs(updatedItems);
      setLoading(false);
    } catch (err) {
      // console.error(err);
      setBlogs([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadBlogs();
  }, []);

  const blogPosts = blogs.map(blog => {
    return {
      name: blog.title,
      imageUrl: blog.imageUrl,
      link: blog.link,
    };
  });

  const handleLink = item => {
    console.log(item.link);
    window.open(item.link, '_blank').focus();
    return;
  };

  return (
    <ExploreSection
      sectionName="Explore The Nutriology Blog"
      items={blogPosts}
      loading={loading}
      handleClick={handleLink}
      data-test="explore-blog"
    />
  );
};

export default BlogFeed;
