import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';

const TrackProgression = ({
  label,
  track,
  trackDirection,
  loadTrack,
  small,
  ...props
}) => {
  return (
    <TR>
      <TD>
        <Label>{label}</Label>
      </TD>
      <TD>
        {track && track.name && (
          <Track
            deleted={track.isDeleted}
            data-test={`${trackDirection}-name`}
            small={small}
          >
            {`${track.name} (${track.level}-${track.locationType}-${track.gender})`}
          </Track>
        )}
      </TD>
    </TR>
  );
};

const Track = styled.h4`
  color: ${props => (props.deleted ? 'red' : `${colors.hlitetwo400}`)};
  font-weight: ${props => (props.small ? '400' : '700')};
  font-size: ${props => (props.small ? '13px' : 'inherit')};
`;

const TR = styled.tr``;

const TD = styled.td`
  padding-right: 16px;
  padding-bottom: 8px;
`;

const Label = styled.label`
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary600};
  margin-bottom: 8px;
`;

export default TrackProgression;
