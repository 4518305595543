import { apiCall } from './api';

const fitnessPlanPopulate = [
  {
    path: 'workouts.workout',
    populate: { path: 'exercises.exercise' },
  },
  {
    path: 'activities.activity',
  },
];

export function getFitnessPlan(id, params = {}) {
  params.populate = fitnessPlanPopulate;
  return apiCall('get', `/fitnessPlan/${id}`, params);
}

export function getFitnessPlans(params) {
  params.populate = fitnessPlanPopulate;
  return apiCall('get', `/fitnessPlan`, params);
}

export function searchFitnessPlans(params) {
  return apiCall('get', `/fitnessPlan/search`, params);
}

export function addFitnessPlan(params) {
  return apiCall('post', `/fitnessplan`, params);
}

export function editFitnessPlan(id, params) {
  return apiCall('put', `/fitnessplan/${id}`, params);
}

// deletes fitness plans for the given user in a specific time slot
export function deleteUserFitnessPlans(user, startDate) {
  if (!user || !startDate) {
    return;
  }
  const params = {
    query: {
      user: user,
      startDate: startDate,
    },
  };

  return apiCall('delete', `/fitnessplanuser`, params);
}

export function createUserFitnessPlan(id, params) {
  return apiCall('post', `/fitnessplan/assign/${id}`, params);
}

export function autoAssignFitnessPlan(user, params) {
  return apiCall('post', `/fitnessplan/assign_one/${user}`, params);
}

export function getFitnessPlanReferences(id, params = {}) {
  params.query = { 'fitnessPlans.fitnessPlan': id };
  return apiCall('get', `/track`, params);
}

export function replaceFitnessPlansOnTrack(
  track,
  oldFitnessPlan,
  newFitnessPlan
) {
  return apiCall(
    'put',
    `/track/${track}/replace_fitnessplan/${oldFitnessPlan}/${newFitnessPlan}`
  );
}
