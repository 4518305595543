import { useMemo, useState } from 'react';

const useAnchoredDropdown = props => {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(e) {
    e.stopPropagation();
    setAnchorEl(anchorEl ? null : e.currentTarget);
  }

  function handleClose(e) {
    setAnchorEl(null);
  }

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  return {
    open,
    anchorEl,
    handleClick,
    handleClose,
  };
};

export default useAnchoredDropdown;
