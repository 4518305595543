import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripePaymentForm from './StripePaymentForm';

const PaymentInfo = ({ paymentPlan }) => {
  const [stripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
  );
  return (
    <PaymentInfoContainer>
      <Header>Payment Info</Header>
      <Elements stripe={stripePromise}>
        <StripePaymentForm
          price={paymentPlan}
          submitText="Unlock my plan"
          updatePayment={false}
        />
      </Elements>
    </PaymentInfoContainer>
  );
};

const Header = styled.h2`
  color: ${colors.primary800};
  margin-bottom: 24px;
`;

const PaymentInfoContainer = styled.div`
  width: 100%;
`;
export default PaymentInfo;
