import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';
import RadioInput from '../../elements/RadioInput';
import CardsRow from '../CardsRow';
import StaticSearchBar from '../../elements/StaticSearchBar';
import Filters from '../../sharedModals/web/Filters';

const SearchWeb = ({
  searchType,
  searchTerm,
  searchQuery,
  setSearchTerm,
  handleSearch,
  handleChange,
  showOnly,
  context,
  clearAllFilters,
  setScreen,
  exercises,
  getExercises,
  setExercise,
  pageSizeExercises,
  activities,
  getActivities,
  pageSizeActivities,
  filteredWorkouts,
  handleToggle,
  toggleValues,
  collapseToggleValues,
  handleCollapseToggle,
  workouts,
  getWorkouts,
  fitnessPlans,
  setFitnessPlan,
  getFitnessPlans,
  tracks,
  getTracks,
  hasMoreExercises,
  hasMoreActivities,
  hasMoreWorkouts,
  hasMoreFitnessPlans,
  hasMoreTracks,
  ...props
}) => {
  return (
    <Container flexDirection="column">
      <SearchArea flexDirection="column">
        <Title data-test="fitness-title">
          Search{' '}
          {searchType === 'activity'
            ? 'Activities'
            : searchType === 'workout'
            ? 'Workouts'
            : searchType === 'fitnessPlan'
            ? 'Fitness Plans'
            : searchType === 'track'
            ? 'Tracks'
            : 'Exercises'}
        </Title>
        <StaticSearchBar
          handleSearch={handleSearch}
          placeholder={
            searchType === 'exercise'
              ? 'Try Push Ups, etc'
              : searchType === 'workout'
              ? 'Try HIIT Circuit, etc'
              : searchType === 'activity'
              ? 'Try Running, etc'
              : ''
          }
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          data-test="fitness-search"
        />
        <FilterContainer flexDirection="row" justify="space-around">
          {showOnly === 'exercise' && (
            <RadioInput
              checked={searchType === 'exercise'}
              value="exercise"
              label="Exercise"
              onChange={handleChange}
              name="searchType"
              data-test="fitness-searchType-exercise"
            />
          )}
          {(!showOnly || showOnly === 'activity') && (
            <RadioInput
              checked={searchType === 'activity'}
              value="activity"
              label="Activity"
              onChange={handleChange}
              name="searchType"
              data-test="fitness-searchType-activity"
            />
          )}
          {(!showOnly || showOnly === 'workout') && (
            <RadioInput
              checked={searchType === 'workout'}
              value="workout"
              label="Workout"
              onChange={handleChange}
              name="searchType"
              data-test="fitness-searchType-workout"
            />
          )}
        </FilterContainer>
      </SearchArea>
      <FlexContainer>
        {searchType === 'workout' && (
          <Filters
            itemType="fitness"
            toggleValues={toggleValues}
            handleToggle={handleToggle}
            clearAllFilters={clearAllFilters}
            handleCollapseToggle={handleCollapseToggle}
            collapseToggleValues={collapseToggleValues}
          />
        )}
        <FlexContainer flexDirection="column">
          {exercises.length > 0 && ['exercise', 'both'].includes(searchType) && (
            <RecommendedContainer flexDirection="column">
              <Header>Exercises</Header>
              <RowsContainer wrap="wrap">
                <ExerciseRows flexDirection="column">
                  <CardsRow
                    key={`exercises-${searchQuery}-${searchType}`}
                    title="Exercises"
                    items={exercises}
                    setScreen={setScreen}
                    setItem={setExercise}
                    wrap="wrap"
                    type="Exercise"
                    pageSize={pageSizeExercises}
                    loadMoreItems={getExercises}
                    scrollParent={props.scrollParent}
                    context={context}
                    hasMore={hasMoreExercises}
                  />
                </ExerciseRows>
              </RowsContainer>
            </RecommendedContainer>
          )}
          {activities.length > 0 && ['activity', 'both'].includes(searchType) && (
            <RecommendedContainer flexDirection="column">
              <Header>Activities</Header>
              <RowsContainer wrap="wrap">
                <ExerciseRows flexDirection="column">
                  <CardsRow
                    key={`activities-${searchQuery}-${searchType}`}
                    title="Activities"
                    items={activities}
                    setScreen={setScreen}
                    wrap="wrap"
                    type="Activity"
                    pageSize={pageSizeActivities}
                    loadMoreItems={getActivities}
                    scrollParent={props.scrollParent}
                    context={context}
                    hasMore={hasMoreActivities}
                    {...props}
                  />
                </ExerciseRows>
              </RowsContainer>
            </RecommendedContainer>
          )}
          {workouts.length > 0 && ['workout', 'both'].includes(searchType) && (
            <RecommendedContainer flexDirection="column">
              <Header>Workouts</Header>
              <RowsContainer wrap="wrap">
                <ExerciseRows flexDirection="column">
                  <CardsRow
                    key={`workouts-${searchQuery}-${searchType}`}
                    title="Workouts"
                    items={filteredWorkouts}
                    unfilteredLength={workouts?.length}
                    setScreen={setScreen}
                    wrap="wrap"
                    type="Workout"
                    pageSize={pageSizeActivities}
                    loadMoreItems={getWorkouts}
                    scrollParent={props.scrollParent}
                    context={context}
                    hasMore={hasMoreWorkouts}
                    {...props}
                  />
                </ExerciseRows>
              </RowsContainer>
            </RecommendedContainer>
          )}
        </FlexContainer>
        {fitnessPlans.length > 0 && (
          <RecommendedContainer flexDirection="column">
            <Header>Fitness Plans</Header>
            <RowsContainer wrap="wrap">
              <ExerciseRows flexDirection="column">
                {['fitnessPlan', 'both'].includes(searchType) && (
                  <CardsRow
                    key={`fitnessPlans-${searchQuery}-${searchType}`}
                    title="Fitness Plans"
                    items={fitnessPlans}
                    setScreen={setScreen}
                    setItem={setFitnessPlan}
                    wrap="wrap"
                    type="Fitness Plan"
                    pageSize={pageSizeActivities}
                    loadMoreItems={getFitnessPlans}
                    scrollParent={props.scrollParent}
                    context={context}
                    hasMore={hasMoreFitnessPlans}
                    {...props}
                  />
                )}
              </ExerciseRows>
            </RowsContainer>
          </RecommendedContainer>
        )}
        {tracks.length > 0 && (
          <RecommendedContainer flexDirection="column">
            <Header>Tracks</Header>
            <RowsContainer wrap="wrap">
              <ExerciseRows flexDirection="column">
                {['track'].includes(searchType) && (
                  <CardsRow
                    key={`tracks-${searchQuery}-${searchType}`}
                    title="Tracks"
                    items={tracks}
                    wrap="wrap"
                    type="Track"
                    pageSize={pageSizeActivities}
                    loadMoreItems={getTracks}
                    scrollParent={props.scrollParent}
                    context={context}
                    hasMore={hasMoreTracks}
                    {...props}
                  />
                )}
              </ExerciseRows>
            </RowsContainer>
          </RecommendedContainer>
        )}
      </FlexContainer>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  ${'' /* width: 100%;
  height: 100%; */}
`;

const FilterContainer = styled(FlexContainer)`
  margin-top: 15px;
`;

const Title = styled.h5`
  color: ${colors.primary700};
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 16px;
`;

const SearchArea = styled(FlexContainer)`
  width: fit-content;
  margin-bottom: 64px;
`;

const RecommendedContainer = styled(FlexContainer)``;
const RowsContainer = styled(FlexContainer)`
  margin-top: 24px;
`;

const ExerciseRows = styled(FlexContainer)`
  margin-right: 64px;
  > * {
    margin-bottom: 24px;
  }
`;

const Header = styled.h2`
  color: ${colors.primary800};
`;

export default SearchWeb;
