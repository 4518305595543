import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';

const MobileBackIcon = ({ handleClick, ...props }) => (
  <FlexContainer alignItems="center" onClick={handleClick} {...props}>
    <Icon
      viewBox="0 0 60 60"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Homepage-V2" transform="translate(-129.000000, -3191.000000)">
          <g
            id="Arrow-Copy"
            transform="translate(159.000000, 3215.000000) scale(-1, 1) rotate(-270.000000) translate(-159.000000, -3219.000000) translate(135.000000, 3195.000000)"
          >
            <g id="Arrow" transform="translate(12.000000, 15.000000)">
              <rect
                id="Rectangle"
                transform="translate(20.000000, 5.500000) rotate(-315.000000) translate(-17.000000, -10.50000) "
                x="9"
                y="6"
                width="24"
                height="3"
                rx="1.5"
              ></rect>
              <rect
                id="Rectangle-Copy"
                transform="translate(7.703110, 9.500000) scale(1, -1) rotate(-315.000000) translate(-7.703110, -6.000000) "
                x="-0.296889876"
                y="6"
                width="24"
                height="3"
                rx="1.5"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </Icon>
    <Label>Back</Label>
  </FlexContainer>
);

const Icon = styled.svg`
  width: 24px;
  height: 24px;

  & rect {
    fill: ${colors.hlitetwo300};
    stroke: ${colors.hlitetwo300};
  }
`;

const Label = styled.p`
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
  color: ${colors.hlitetwo300};
  margin-left: -4px;
`;

export default MobileBackIcon;
