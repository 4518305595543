import React, { useState } from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import WaterButton from '../elements/WaterButton';
import { useSelector } from 'react-redux';

const TodaysSummaryFitness = ({
  caloriesBurned,
  workoutGoal,
  steps,
  stepsGoal,
  water,
  recordWater,
  ...props
}) => {
  const gender = useSelector(state => state.currentUser.user.gender);
  const recommendedOz = gender === 'Male' ? 104 : 72;
  const [selected, setSelected] = useState('plus');
  return (
    <Container alignItems="center">
      <FitnessSection id="fitness-data" justify="space-between">
        <StatsContainer flexDirection="column">
          <Header>Workout Calories</Header>
          <Stat data-test="dashboard-workoutCalories">
            {caloriesBurned} calories
          </Stat>
          <Goal data-test="dashboard-workoutGoal">
            Goal: {workoutGoal.toLocaleString()} calories
          </Goal>
        </StatsContainer>
        <WaterTracker flexDirection="column" justify="space-between">
          <FlexContainer flexDirection="column" style={{ gap: 8 }}>
            <Header>Water Intake</Header>
            <StatsText data-test="dashboard-waterDrank">{`${water.amount} oz`}</StatsText>
            <Goal data-test="dashboard-waterRecommended">
              Goal: {recommendedOz} oz
            </Goal>
          </FlexContainer>
          <StyledProgress
            value={water.amount}
            max={recommendedOz}
            color={colors.secondary500}
            data-test="water-progress-bar"
          />
          <IncDecContainer alignSelf="flex-end">
            <CircleButton
              justify="center"
              alignItems="center"
              name="plus"
              selected={selected === 'plus'}
              onClick={() => setSelected('plus')}
              data-test="water-increment"
            >
              <Plus selected={selected === 'plus'}>+</Plus>
            </CircleButton>
            <CircleButton
              justify="center"
              alignItems="center"
              name="minus"
              selected={selected === 'minus'}
              onClick={() => setSelected('minus')}
              data-test="water-decrement"
            >
              <Minus selected={selected === 'minus'}>-</Minus>
            </CircleButton>
          </IncDecContainer>
          <ButtonContainer>
            <WaterButton
              water={water}
              increment={selected === 'minus' ? -8 : 8}
              unit="oz"
              color={colors.secondary500}
              recordWater={recordWater}
            />
            <WaterButton
              water={water}
              increment={selected === 'minus' ? -12 : 12}
              unit="oz"
              color={colors.secondary500}
              recordWater={recordWater}
            />
          </ButtonContainer>
        </WaterTracker>
        {/* TODO: Post-Beta feature
        https://app.clickup.com/t/8ethkc
        <StatsContainer flexDirection="column">
          <Header>Daily Steps</Header>
          <Stat>{steps.toLocaleString() || 0} steps</Stat>
          <Goal>Goal: {stepsGoal.toLocaleString()}</Goal>
        </StatsContainer> */}
      </FitnessSection>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  position: relative;
  width: 100%;

  > :not(:last-child) {
    border-right: 1px solid ${colors.primary300};
  }
`;

const Section = styled(FlexContainer)`
  height: 100%;
`;

const FitnessSection = styled(Section)`
  width: 100%;
`;

const Header = styled.h6`
  color: ${colors.primary800};
  text-transform: uppercase;
`;

const StatsContainer = styled(FlexContainer)`
  width: 40%;
  height: 100%;
  max-width: 240px;
  border-right: 1px solid ${colors.primary300};
  gap: 8px;
  padding-right: 28px;
`;

const Stat = styled.h2`
  color: ${colors.secondary500};
`;
const Goal = styled.p`
  color: ${colors.primary500};
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
`;

const WaterTracker = styled(FlexContainer)`
  width: 60%;
  gap: 14px;
  padding-left: 28px;
`;

const StatsText = styled.h5`
  color: ${colors.secondary500};
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
`;

const ButtonContainer = styled(FlexContainer)`
  gap: 12px;
`;

const StyledProgress = styled.progress`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  border: none;
  border-radius: 3px;

  ::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 3px;
  }
  ::-webkit-progress-value {
    background-color: ${props => props.color};
    border-radius: 3px;
  }
  ::-moz-progress-bar {
    background-color: ${props => props.color};
    border-radius: 3px;
  }
`;

const IncDecContainer = styled(FlexContainer)`
  top: 28px;
  position: absolute;
`;

const Plus = styled.p`
  font-size: 20px;
  color: ${props => (props.selected ? '#fff' : '#000')};
  line-height: normal;
  font-weight: 500;
`;

const Minus = styled.p`
  font-size: 24px;
  color: ${props => (props.selected ? '#fff' : '#000')};
  line-height: 24px;
  height: 26px;
`;

const CircleButton = styled(FlexContainer)`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${props => (props.selected ? colors.secondary500 : '#fff')};
  border: ${props => (props.selected ? 'none' : '1px solid #BDEFE3')};

  &:hover {
    cursor: pointer;
  }
`;

// const ReportText = styled.h6`
//   color: ${colors.secondary600};
//   margin-left: 16px;
// `;

export default TodaysSummaryFitness;
