import React, { useState } from 'react';
import { isMobile } from '../../helpers/utils';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { addUserFood } from '../../services/api/food';
import useFormValues from '../../hooks/useFormValues';
import useFormSubmit from '../../hooks/useFormSubmit';
import CustomFoodMobile from './CustomFoodMobile';
import CustomFoodWeb from './CustomFoodWeb';
import { trackEvent } from '../../integrations/analytics';

const CustomFood = ({ setFood, setScreen, userId, ...props }) => {
  const initialValues = {
    name: '',
    verboseName: '',
    type: '',
    amount: 1,
    measureUnit: '',
    gramWeight: 0,
    calories: 0,
    proteins: 0,
    carbohydrates: 0,
    fiber: 0,
    totalSugar: 0,
    addedSugar: 0,
    fat: 0,
    saturatedFat: 0,
    transFat: 0,
    cholesterol_mg: 0,
    vitaminD_mcg: 0,
    calcium_mg: 0,
    potassium_mg: 0,
    sodium_mg: 0,
    kind: 'User',
  };

  const { values, setValues, handleChange, handleValueChanged } = useFormValues(
    initialValues
  );

  const [status, setStatus] = useState();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const submitData = () => {
    trackEvent('Custom Food Created');
    let payload = cloneDeep(values);

    payload.user = userId;
    payload.verboseName = values.name;

    setLoading(true);

    addUserFood(payload) // create a new custom FoodUser
      .then(result => {
        result ? setStatus('success') : setStatus('error');
        setMessage(`Food ${result._id} was created successfully!`);
        setFood(result);
        setLoading(false);
        setScreen('Food Page');
      })
      .catch(err => {
        console.error(err);
        setStatus('error');
        if (err.errors && err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
        setLoading(false);
      });
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin'
  );

  return isMobile() ? (
    <CustomFoodMobile
      values={values}
      handleChange={handleChange}
      handleValueChanged={handleValueChanged}
      handleSubmit={handleSubmit}
      status={status}
      message={message}
      loading={loading}
    />
  ) : (
    <CustomFoodWeb
      values={values}
      handleChange={handleChange}
      handleValueChanged={handleValueChanged}
      handleSubmit={handleSubmit}
      status={status}
      message={message}
      loading={loading}
    />
  );
};

function mapStateToProps(state) {
  const { currentUser } = state;

  return { userId: currentUser.user.id };
}

export default connect(mapStateToProps)(CustomFood);
