import React from 'react';
import styled from 'styled-components';
import Collapsible from 'react-collapsible';
import CollapseIconCircle from '../icons/CollapseIconCircle';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';

const CollapsibleHeader = ({ title, open, onClick, children, name }) => (
  <CollapsibleContainer flexDirection="column">
    <CollapsibleTitleContainer
      justify="space-between"
      alignItems="center"
      open={open}
      onClick={onClick}
      name={name}
      data-test={`collapsible-trigger-${title.replace(/\s+/g, '')}`}
    >
      <CollapsibleTitle>{title}</CollapsibleTitle>
      <CollapseIconCircle
        onClick={onClick}
        open={open}
        name={title}
        data-test="collapsible-trigger"
      />
    </CollapsibleTitleContainer>
    {open && <InformationContainer>{children}</InformationContainer>}
  </CollapsibleContainer>
);

const ProfileCollapsible = ({ name, title, open, onClick, children }) => (
  <Collapsible
    open={open}
    triggerStyle={triggerStyle}
    triggerDisabled={true}
    trigger={
      <CollapsibleHeader
        onClick={onClick}
        name={name}
        open={open}
        title={title}
        children={children}
      />
    }
  />
);

const CollapsibleContainer = styled(FlexContainer)`
  box-sizing: border-box;
  background: #fff;
  border: 1px solid ${colors.primary100};
  border-radius: 4px;
`;

const CollapsibleTitleContainer = styled(FlexContainer)`
  background: ${colors.primary050};
  border-bottom: ${props =>
    props.open ? `1px solid ${colors.primary100}` : 'none'};
  border-radius: 4px 4px 0px 0px;
  padding: 16px;
`;

const CollapsibleTitle = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  color: ${colors.primary800};
`;

const InformationContainer = styled.div`
  padding: 16px;
`;

const triggerStyle = {
  width: '100%',
  position: 'initial',
};

export default ProfileCollapsible;
