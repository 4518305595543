import React from 'react';
import PlanCard from './PlanCard';
import ModalContainer from '../../sharedModals/ModalContainer';
import useModal from '../../hooks/useModal';

const PlanSlot = ({ track, primary, loop, trackType, swapTrack, ...props }) => {
  const { open, launchModal, closeModal } = useModal(false);
  return (
    <>
      <PlanCard
        itemId={track._id}
        name={track.name}
        track={track}
        handleSwap={launchModal}
        swap={true}
        showProgression={true}
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          showOnly: 'track',
          primary: primary,
          loop: loop,
          trackType: trackType,
          currentTrack: track,
        }}
        initialScreen="Exercise Search"
        handleClose={closeModal}
        addTrack={swapTrack}
        {...props}
      />
    </>
  );
};

export default PlanSlot;
