import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';
import Avatar from '@material-ui/core/Avatar';

const GenericModalCard = ({ title, icon, name, handleClick, ...props }) => {
  return (
    <CardContainer
      flexDirection="column"
      alignItems="center"
      justify="space-evenly"
      name={name}
      onClick={handleClick}
    >
      <StyledAvatar src={icon} />
      <Title>{title}</Title>
      <Button
        buttonText="+ Add"
        pink="true"
        width="120px"
        data-test={props['data-test']}
      />
    </CardContainer>
  );
};

const CardContainer = styled(FlexContainer)`
  height: 300px;
  width: 240px;
  border: 1.5px solid ${colors.primary200};
  border-radius: 4px;
  padding: 32px 0 24px 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
`;

const StyledAvatar = styled(Avatar)`
  width: 144px;
  height: 144px;
  background-color: ${colors.primary050};
`;

const Title = styled.h2`
  color: ${colors.primary800};
`;

export default GenericModalCard;
