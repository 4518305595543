import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import PrivateRoute from './PrivateRoute';
import { connect } from 'react-redux';
import {
  getActiveUserMealPlan,
  getDefaultPlaceholder,
} from '../store/actions/mealplan';
import { getShoppingLists } from '../store/actions/shoppinglist';
import { getCircleSlug } from '../store/actions/general';
import {
  getActiveUserFitnessPlan,
  getUserTrack,
} from '../store/actions/fitnessplan';

const mapState = state => ({
  isVerified: state.currentUser.user.isVerified,
  isAuthenticated: state.currentUser.isAuthenticated,
  intakeComplete: state.currentUser.user.intakeComplete,
  isSubscribed: state.currentUser.user.isSubscribed,
  isAdmin: state.currentUser.user.isAdmin,
});

const AppContainerRoute = props => {
  const {
    isVerified,
    intakeComplete,
    isAuthenticated,
    isSubscribed,
    isAdmin,
  } = useSelector(mapState);
  const { location } = useReactRouter();

  // ensure active/current meal and fitness plans set on reload
  useEffect(() => {
    if (isAuthenticated && isVerified && (intakeComplete || isAdmin)) {
      props.getActiveUserMealPlan(props.currentUser.user._id);
      props.getActiveUserFitnessPlan(props.currentUser.user._id);
      if (!['/payment', '/app/shoppinglist'].includes(location.pathname)) {
        props.getShoppingLists();
      }
      if (props.currentUser.user.fitnessTrack) {
        props.getUserTrack(props.currentUser.user);
      }
      props.getDefaultPlaceholder();
      props.getCircleSlug();
    }
  }, []);

  return isAuthenticated && intakeComplete && isSubscribed && !isVerified ? (
    <Redirect to="/please-verify" />
  ) : isAuthenticated && isSubscribed === false ? (
    <Redirect to="/payment" />
  ) : intakeComplete === false ? (
    <Redirect to="/questionnaire" />
  ) : (
    <PrivateRoute {...props} />
  );
};

function mapStateToProps(state) {
  const { currentUser } = state;
  return {
    currentUser,
  };
}

export default connect(
  mapStateToProps,
  {
    getActiveUserMealPlan,
    getActiveUserFitnessPlan,
    getUserTrack,
    getShoppingLists,
    getDefaultPlaceholder,
    getCircleSlug,
  }
)(AppContainerRoute);
