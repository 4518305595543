import React, { useEffect, useState } from 'react';
import { pick, cloneDeep, camelCase } from 'lodash';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import DateInput from '../elements/DateInput';
import { colors } from '../styleConstants';
import Button from '../elements/Button';
import useFormValues from '../hooks/useFormValues';
import useFormSubmit from '../hooks/useFormSubmit';
import { connect } from 'react-redux';
import { updateUser } from '../store/actions/general';
import { isMobile } from '../helpers/utils';
import DetailCard from './DetailCard';
import CustomSlider from '../elements/Slider';
import MobileDateInput from '../elements/MobileDateInput';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertStack from '../elements/AlertStack';

const MyDetailsForm = props => {
  const genderOptions = ['Male', 'Female', 'Other'];
  // const timeZones = [
  //   'Eastern Time (ET)',
  //   'Central Time (CT)',
  //   'Mountain Time (MT)',
  //   'Pacific Time (PT)',
  // ]; // TODO populate these from Moment or similar library
  // https://app.clickup.com/t/gmuqhc

  const goalOptions = ['Lose body fat', 'Gain muscle', 'Get fit and toned'];

  const activityLevel = {
    Novice: 'I never or rarely workout.',
    Competent:
      'I workout on a regular basis and train with weights using easy simple movements.',
    Proficient:
      'I workout and train with weights on a regular basis and feel comfortable with a variety of movements.',
    Expert:
      'I workout and train with weights on a frequent basis with a variety of movements.',
  };

  const { values, setValues, handleChange, handleValueChanged } = useFormValues(
    props.currentUser
  );

  useEffect(() => {
    setValues(props.currentUser);
  }, [props.currentUser]);

  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const submitData = () => {
    setLoading(true);
    props
      .updateUser(values)
      .then(result => {
        setLoading(false);
        setStatus('success');
        setMessage('Your profile has been successfully updated!');
      })
      .catch(err => {
        setLoading(false);
        setStatus('error');
        setMessage('There was a problem updating your profile.');
        console.err(err);
      });
  };

  const { errors, handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'details'
  );

  const genderCards = genderOptions.map(gender => (
    <DetailCard
      key={gender}
      imgSrc={`${process.env.PUBLIC_URL}/icons/Gender/${gender}.png`}
      title={gender}
      isSelected={values.gender === gender}
      name="gender"
      extraInfo={
        gender === 'Other'
          ? "Other nonbinary gender identity that doesn't fit into existing gender schemas or constructs."
          : ''
      }
      handleSelect={() => handleValueChanged('gender', gender, 'string')}
      data-test={`profile-gender-${camelCase(gender)}`}
    />
  ));

  const nutriologyGoalCards = goalOptions.map(goal => (
    <DetailCard
      key={goal}
      imgSrc={`${process.env.PUBLIC_URL}/icons/NutriologyGoals/${goal}.png`}
      title={goal}
      isSelected={values.detailPreferences.nutriologyGoal === goal}
      name="detailPreferences.nutriologyGoal"
      handleSelect={() =>
        handleValueChanged('detailPreferences.nutriologyGoal', goal, 'string')
      }
      data-test={`profile-goal-${camelCase(goal)}`}
    />
  ));

  const activityLevelCards = Object.entries(activityLevel).map(
    ([activeness, label]) => (
      <DetailCard
        key={activeness}
        imgSrc={`${process.env.PUBLIC_URL}/icons/ActivityLevel/${activeness}.png`}
        title={activeness}
        isSelected={values.detailPreferences.activityLevel === activeness}
        name="detailPreferences.activityLevel"
        handleSelect={() =>
          handleValueChanged(
            'detailPreferences.activityLevel',
            activeness,
            'string'
          )
        }
        extraInfo={label}
        data-test={`profile-activityLevel-${camelCase(activeness)}`}
      />
    )
  );

  if (isMobile()) {
    // Mobile View
    return (
      <MobileFormContainer>
        <Title>Select your Gender</Title>
        <CardsContainer wrap="wrap">{genderCards}</CardsContainer>
        <MobileDateInput
          name="birthDate"
          label="Date of birth"
          handleValueChanged={handleValueChanged}
          value={values.birthDate}
          data-test="profile-birthDate"
        />
        <BoxContainer flexDirection="column">
          <Title>Select your current weight</Title>
          {/* <PillContainer> // TODO: Enable switching between metric and imperial units
          https://app.clickup.com/t/8etkvw
            <Pill
              title="Kg"
              isSelected={false}
              justify="center"
              alignItems="center"
            >
              Kg
            </Pill>

            <Pill
              title="lbs"
              isSelected={true}
              justify="center"
              alignItems="center"
            >
              lbs
            </Pill>
          </PillContainer> */}
          <CustomSlider
            unit="lbs"
            name="weight"
            min={80}
            max={400}
            value={values.weight}
            handleValueChanged={handleValueChanged}
            data-test="profile-currentWeight"
          />
        </BoxContainer>
        <BoxContainer flexDirection="column">
          <Title>Select your goal weight</Title>

          {/* // TODO: Enable switching between metric and imperial units
          https://app.clickup.com/t/8etkvw
          <PillContainer>
            <Pill
              title="Kg"
              isSelected={false}
              justify="center"
              alignItems="center"
            >
              Kg
            </Pill>
            <Pill
              title="lbs"
              isSelected={true}
              justify="center"
              alignItems="center"
            >
              lbs
            </Pill>
          </PillContainer> */}
          <CustomSlider
            unit="lbs"
            name="detailPreferences.weightGoal"
            min={80}
            max={400}
            value={values.detailPreferences.weightGoal}
            handleValueChanged={handleValueChanged}
            data-test="profile-goalWeight"
          />
        </BoxContainer>
        <BoxContainer flexDirection="column">
          <Title>What is your height?</Title>
          {/* <PillContainer> // TODO: Enable switching between metric and imperial units
          https://app.clickup.com/t/8etkvw
            <Pill
              title="Kg"
              isSelected={false}
              justify="center"
              alignItems="center"
            >
              Meters
            </Pill>

            <Pill
              title="lbs"
              isSelected={true}
              justify="center"
              alignItems="center"
            >
              Feet
            </Pill>
          </PillContainer> */}
          <CustomSlider
            name="height.feet"
            unit="ft"
            unitColor="grey"
            value={values.height.feet}
            min={3}
            max={8}
            handleValueChanged={handleValueChanged}
            data-test="profile-heightFeet"
          />
          <CustomSlider
            name="height.inches"
            unit="in"
            unitColor="grey"
            value={values.height.inches}
            min={0}
            max={11}
            handleValueChanged={handleValueChanged}
            data-test="profile-heightInches"
          />
        </BoxContainer>
        <Title style={{ marginTop: 32 }}>I'm using Nutriology to:</Title>
        <CardsContainer wrap="wrap">{nutriologyGoalCards}</CardsContainer>
        <Title style={{ marginTop: 16 }}>Activity level:</Title>
        <CardsContainer wrap="wrap">{activityLevelCards}</CardsContainer>
        {/* TODO: Figure out what to do with this
        <FieldLabel>Time Zone</FieldLabel>
        <Dropdown
          options={timeZones}
          defaultValue={timeZones[0]}
          data-test="profile-timeZone"
        /> */}
        <StyledButton
          type="submit"
          buttonText="Save Changes"
          data-test="profile-details-submit"
          onClick={handleSubmit}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
        />
        {status === 'error' && (
          <AlertStack
            messages={message}
            type="error"
            variant="filled"
            open={status === 'error'}
            handleClose={() => setStatus('hidden')}
            autoHideDuration={10000}
            data-test="profile-details-error"
          />
        )}
        {status === 'success' && (
          <AlertStack
            messages={message}
            type="success"
            variant="filled"
            open={status === 'success'}
            handleClose={() => setStatus('hidden')}
            autoHideDuration={10000}
            data-test="profile-details-success"
          />
        )}
      </MobileFormContainer>
    );
  }
  // Web View
  return (
    <>
      <FormContainer flexDirection="column">
        <RowContainer wrap="wrap" alignItems="center">
          <FieldContainer flexDirection="column">
            <Title>Select your Gender</Title>
            <CardsContainer wrap="wrap">{genderCards}</CardsContainer>
          </FieldContainer>
          <FieldContainer flexDirection="column">
            <Title>Date of Birth</Title>
            <DateInput
              name="birthDate"
              width="200px"
              value={values.birthDate}
              onValueChanged={handleValueChanged}
              onChange={handleChange}
              showMonthYearDropdown
              error={errors.birthDate}
              data-test="profile-birthDate"
            />
          </FieldContainer>
        </RowContainer>
        <RowContainer wrap="wrap">
          <FieldContainer flexDirection="column">
            <BoxContainer flexDirection="column">
              <Title>Select your current weight</Title>
              {/* <PillContainer> // TODO: Enable switching between metric and imperial units
          https://app.clickup.com/t/8etkvw
            <Pill
              title="Kg"
              isSelected={false}
              justify="center"
              alignItems="center"
            >
              Kg
            </Pill>

            <Pill
              title="lbs"
              isSelected={true}
              justify="center"
              alignItems="center"
            >
              lbs
            </Pill>
          </PillContainer> */}
              <CustomSlider
                unit="lbs"
                name="weight"
                min={30}
                max={400}
                value={values.weight}
                handleValueChanged={handleValueChanged}
                data-test="profile-currentWeight"
              />
            </BoxContainer>
            <BoxContainer flexDirection="column">
              <Title>Select your goal weight</Title>

              {/* // TODO: Enable switching between metric and imperial units
          https://app.clickup.com/t/8etkvw
          <PillContainer>
            <Pill
              title="Kg"
              isSelected={false}
              justify="center"
              alignItems="center"
            >
              Kg
            </Pill>
            <Pill
              title="lbs"
              isSelected={true}
              justify="center"
              alignItems="center"
            >
              lbs
            </Pill>
          </PillContainer> */}
              <CustomSlider
                unit="lbs"
                name="detailPreferences.weightGoal"
                min={30}
                max={400}
                value={values.detailPreferences.weightGoal}
                handleValueChanged={handleValueChanged}
                data-test="profile-goalWeight"
              />
            </BoxContainer>
          </FieldContainer>
          <BoxContainer flexDirection="column">
            <Title>What is your height?</Title>
            {/* <PillContainer> // TODO: Enable switching between metric and imperial units
          https://app.clickup.com/t/8etkvw
            <Pill
              title="Kg"
              isSelected={false}
              justify="center"
              alignItems="center"
            >
              Meters
            </Pill>

            <Pill
              title="lbs"
              isSelected={true}
              justify="center"
              alignItems="center"
            >
              Feet
            </Pill>
          </PillContainer> */}
            <CustomSlider
              name="height.feet"
              unit="ft"
              unitColor="grey"
              value={values.height.feet}
              min={3}
              max={8}
              handleValueChanged={handleValueChanged}
              data-test="profile-heightFeet"
            />
            <CustomSlider
              name="height.inches"
              unit="in"
              unitColor="grey"
              value={values.height.inches}
              min={0}
              max={11}
              handleValueChanged={handleValueChanged}
              data-test="profile-heightInches"
            />
          </BoxContainer>
        </RowContainer>
        <RowContainer wrap="wrap">
          <FieldContainer flexDirection="column">
            <Title>I'm using Nutriology to:</Title>
            <CardsContainer wrap="wrap">{nutriologyGoalCards}</CardsContainer>
          </FieldContainer>

          <FieldContainer flexDirection="column">
            <Title>Activity level:</Title>
            <CardsContainer wrap="wrap">{activityLevelCards}</CardsContainer>
          </FieldContainer>
          {/* TODO: Figure out what to do with this
        <FieldContainer flexDirection="column">
        <FieldLabel>Time Zone</FieldLabel>
        <Dropdown
          options={timeZones}
          defaultValue={timeZones[0]}
          width="348px"
          data-test="profile-timeZone"
          />
          </FieldContainer> */}
        </RowContainer>
      </FormContainer>
      <FlexContainer
        alignSelf="center"
        flexDirection="column"
        justify="center"
        alignItems="center"
      >
        <StyledButton
          type="submit"
          onClick={handleSubmit}
          buttonText="Save My Details"
          data-test="profile-details-submit"
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
        />
        {status === 'error' && (
          <AlertStack
            messages={message}
            type="error"
            variant="filled"
            open={status === 'error'}
            handleClose={() => setStatus('hidden')}
            autoHideDuration={10000}
            data-test="profile-details-error"
          />
        )}
        {status === 'success' && (
          <AlertStack
            messages={message}
            type="success"
            variant="filled"
            open={status === 'success'}
            handleClose={() => setStatus('hidden')}
            autoHideDuration={10000}
            data-test="profile-details-success"
          />
        )}
      </FlexContainer>
    </>
  );
};

const FormContainer = styled(FlexContainer)`
  flex-grow: 1;
`;

const RowContainer = styled(FlexContainer)`
  margin-bottom: 24px;
  > * {
    margin-right: 24px;
  }
`;

const FieldContainer = styled(FlexContainer)``;

/* Mobile styles */

const MobileFormContainer = styled.div`
  overflow: hidden;
`;

const Title = styled.h4`
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.primary800};
`;

const CardsContainer = styled(FlexContainer)`
  > * {
    margin-bottom: 16px;
  }
  > :not(:last-child) {
    margin-right: 12px;
  }
`;

const BoxContainer = styled(FlexContainer)`
  padding: 16px;
  background: ${colors.primary050};
  border-radius: 6px;
  margin-top: ${props => isMobile() && '16px'};
  width: ${props => !isMobile() && '316px'};
`;

// const PillContainer = styled(FlexContainer)`
//   margin: 16px 0;
//   > * {
//     margin-right: 8px;
//   }
// `;

// const Pill = styled(FlexContainer)`
//   width: 82px;
//   padding: 8px;
//   background: ${props => (props.isSelected ? '#16979A' : '#ffffff')};
//   border: 1px solid #16979a;
//   border-radius: 6px;
//   color: ${props => (props.isSelected ? '#fff' : ${colors.primary800};)};
// `;

const StyledButton = styled(Button)`
  width: ${props => (isMobile() ? '100%' : '230px')};
  height: 40px;
  background: ${colors.secondary600};
  border-radius: 2px;
  text-transform: none;
`;

function mapStateToProps(state) {
  const { currentUser } = state;
  const fields = [
    'id',
    'weight',
    'detailPreferences',
    'gender',
    'birthDate',
    'height',
  ];
  const user = pick(currentUser.user, fields);
  return { currentUser: cloneDeep(user) };
}

export default connect(
  mapStateToProps,
  { updateUser }
)(MyDetailsForm);
