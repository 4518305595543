import React from 'react';

const InfoIcon = ({ onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      onClick={onClick}
      onBlur={() => console.log('blue')}
    >
      <path
        fill="#16B8BA"
        d="M10 3.333A6.676 6.676 0 003.333 10 6.675 6.675 0 0010 16.666 6.675 6.675 0 0016.667 10 6.676 6.676 0 0010 3.333zm.833 10a.833.833 0 01-1.666 0V10a.833.833 0 011.666 0v3.333zm0-6.667a.833.833 0 11-1.666 0 .833.833 0 011.666 0z"
        opacity="0.3"
      ></path>
      <path
        fill="#16979A"
        d="M9.167 6.667a.833.833 0 111.666 0 .833.833 0 01-1.666 0zm0 3.333a.833.833 0 011.666 0v3.334a.833.833 0 01-1.666 0V10zM10 1.667A8.336 8.336 0 001.667 10c0 4.6 3.733 8.334 8.333 8.334S18.333 14.6 18.333 10 14.6 1.667 10 1.667zm0 15A6.676 6.676 0 013.333 10 6.676 6.676 0 0110 3.334 6.675 6.675 0 0116.667 10 6.675 6.675 0 0110 16.667z"
      ></path>
    </svg>
  );
};

export default InfoIcon;
