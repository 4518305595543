import React from 'react';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { colors } from '../styleConstants';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography data-test="mobile-tab-header" component={'span'}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const MobileTabs = ({
  tabs,
  children,
  value,
  handleChange,
  handleChangeIndex,
}) => {
  const theme = useTheme();

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        variant="fullWidth"
        aria-label="full width tabs example"
        data-test="mobile-tab-panel"
      >
        {tabs.map((tab, index) => (
          <CustomTab
            key={index}
            label={tab}
            name={tab.toLowerCase()}
            data-test="mobile-tab"
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
        {children.map((child, index) => (
          <TabPanel
            key={index}
            value={value}
            index={index}
            dir={theme.direction}
          >
            {child}
          </TabPanel>
        ))}
      </SwipeableViews>
    </>
  );
};
const CustomTab = styled(Tab)`
  text-transform: capitalize;
  font-family: 'Work Sans';
  font-size: 15px;
  letter-spacing: 0;
  text-align: center;
  color: ${colors.primary500};
  border-bottom: 4px solid ${colors.primary200};
`;
export default MobileTabs;
