import React, { useMemo } from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';
import Collapsible from 'react-collapsible';
import CollapseIcon from '../../icons/CollapseIcon';
import { recipeTags } from '../../helpers/recipeTags';
import { fitnessTags } from '../../helpers/fitnessTags';
import CheckBox from '../../elements/CheckBox';

const Filters = ({
  itemType,
  toggleValues,
  handleToggle,
  clearAllFilters,
  handleCollapseToggle,
  collapseToggleValues,
}) => {
  const itemTags = itemType === 'fitness' ? fitnessTags : recipeTags;

  const showClearBtn = useMemo(() => {
    for (let value of Object.values(toggleValues)) {
      for (let vKey of Object.keys(value)) {
        if (value[vKey]) {
          return true;
        }
      }
    }
    return false;
  }, [toggleValues]);

  const triggerStyle = {
    position: 'absolute',
    top: 10,
    right: 0,
  };

  return (
    <FiltersContainer flexDirection="column">
      <FlexContainer justify="space-between" alignItems="center">
        <FilterTitle>Filter by tags</FilterTitle>
        {showClearBtn && (
          <FilterClear
            onClick={clearAllFilters}
            data-test={`${itemType}Search-clearAllFilters`}
          >
            Clear all
          </FilterClear>
        )}
      </FlexContainer>
      <TagFilterContainer>
        {itemTags.map((item, index) => (
          <TagContainer
            key={index.toString()}
            flexDirection="column"
            flexShrink="0"
          >
            <Tag name={item.name} onClick={handleCollapseToggle()}>
              {item.label}
            </Tag>
            <Collapsible
              overflowWhenOpen="visible"
              triggerStyle={triggerStyle}
              contentOuterClassName="contentOuter__no_padding"
              open={collapseToggleValues[`${item.name}`]}
              trigger={
                <CollapseIcon
                  small
                  onClick={handleCollapseToggle()}
                  open={collapseToggleValues[`${item.name}`]}
                  name={item.name}
                  data-test={`collapse-${item.name}`}
                  width={14}
                  height={8}
                />
              }
            >
              <CheckContainer flexDirection="column">
                {item.tags.map((tag, tIndex) => (
                  <CheckBox
                    key={tIndex.toString()}
                    checked={toggleValues[item.name][tag.name] || ''}
                    label={tag.label}
                    onChange={handleToggle(item.name)}
                    name={tag.name}
                    size="sm"
                    data-test={`${itemType}Search-${item.name}-${tag.name}`}
                  />
                ))}
              </CheckContainer>
            </Collapsible>
          </TagContainer>
        ))}
      </TagFilterContainer>
    </FiltersContainer>
  );
};

const FiltersContainer = styled(FlexContainer)`
  margin-right: 64px;
`;

const TagFilterContainer = styled(FlexContainer)`
  flex-direction: column;
`;

const TagContainer = styled(FlexContainer)`
  position: relative;
`;

const Tag = styled.h6`
  color: ${colors.secondary500};
  margin: 10px 0;
  cursor: pointer;
  font-size: 14px;
`;

const Title = styled.h5`
  color: ${colors.primary700};
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 16px;
`;

const FilterTitle = styled(Title)`
  color: ${colors.primary800};
  line-height: 32px;
  margin-bottom: 0;
`;

const FilterClear = styled.p`
  font-size: 13px;
  color: ${colors.primary600};
  cursor: pointer;
`;

const CheckContainer = styled(FlexContainer)`
  flex-wrap: wrap;
  margin-left: 5px;

  > * {
    margin-right: 5px;
    margin-bottom: 5px;
  }
`;

export default Filters;
