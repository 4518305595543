import { apiCall } from './api';

export function getAllUsers(params) {
  return apiCall('get', `/users/all`, params);
}

export function getUser(id, params) {
  return apiCall('get', `/users/${id}`, params);
}

export function searchUsers(params) {
  return apiCall('get', `/users/search`, params);
}

export function designateAdmin(id, params = {}) {
  params.isAdmin = true; // Only allow promoting admins from interface, not demoting
  return apiCall('put', `/admins/set/${id}`, params);
}
