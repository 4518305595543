import React from 'react';
import FlexContainer from '../elements/FlexContainer';

function FilterIcon({ containerStyle, hideCircle, color }) {
  return (
    <FlexContainer
      flexDirection="column"
      alignItems="center"
      style={containerStyle}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g fill={color ? color : '#000'} transform="translate(-265 -302)">
            <g transform="translate(260 298)">
              <g transform="translate(5 4)">
                {!hideCircle && (
                  <path d="M12 22.8c5.965 0 10.8-4.835 10.8-10.8 0-5.965-4.835-10.8-10.8-10.8C6.035 1.2 1.2 6.035 1.2 12c0 5.965 4.835 10.8 10.8 10.8zm0 1.2c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"></path>
                )}
                <path d="M19 7.5c0 .276-.261.5-.583.5H5.583C5.261 8 5 7.776 5 7.5s.261-.5.583-.5h12.834c.322 0 .583.224.583.5zM17 12.5c0 .276-.28.5-.625.5h-8.75C7.28 13 7 12.776 7 12.5s.28-.5.625-.5h8.75c.345 0 .625.224.625.5zM15 17.5c0 .276-.28.5-.625.5h-3.75c-.345 0-.625-.224-.625-.5s.28-.5.625-.5h3.75c.345 0 .625.224.625.5z"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </FlexContainer>
  );
}

export default FilterIcon;
