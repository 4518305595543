import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import useReactRouter from 'use-react-router';
import { getImageURL } from '../services/api/api';
import { formatDate } from '../helpers/date';
import UpgradeBanner from '../elements/UpgradeBanner';
import Button from '../elements/Button';
const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

const OnDeck = ({
  meals,
  mealPlan,
  fitnessPlan,
  subscriptionActive,
  ...props
}) => {
  const { history } = useReactRouter();

  const currentHour = new Date().getHours();
  const [currentMeal, setCurrentMeal] = useState(
    currentHour < 12 ? meals[0] : currentHour < 15 ? meals[1] : meals[2]
  );
  const tomorrow = moment().add(1, 'day');
  const [dateValue, setDateValue] = useState(new Date());

  const [nextMeal, setNextMeal] = useState({});
  const [nextFitness, setNextFitness] = useState({});

  const getNextMeal = () => {
    let recipes = mealPlan.recipes.filter(
      recipe =>
        formatDate(moment()) === formatDate(recipe.date) &&
        recipe.mealType === currentMeal.meal &&
        !recipe.eaten
    );

    let foods = mealPlan.foods.filter(
      food =>
        formatDate(moment()) === formatDate(food.date) &&
        food.mealType === currentMeal.meal &&
        !food.eaten
    );
    // If there are no more uneaten meals left today, show the first item for tomorrow
    if (!recipes.length && !foods.length) {
      setCurrentMeal(meals[0]); // Start with tomorrow's breakfast
      setDateValue(formatDate(tomorrow));
      recipes = mealPlan.recipes.filter(
        recipe =>
          formatDate(tomorrow) === formatDate(recipe.date) && !recipe.eaten
      );
      foods = mealPlan.foods.filter(
        food => formatDate(tomorrow) === formatDate(food.date) && !food.eaten
      );
    }
    const items = [...recipes, ...foods];
    setNextMeal(items[0]);
  };

  const getNextFitness = () => {
    let workouts = fitnessPlan.workouts.filter(
      workout =>
        formatDate(moment()) === formatDate(workout.date) &&
        !workout.allComplete
    );

    let activities = fitnessPlan.activities.filter(
      activity =>
        formatDate(moment()) === formatDate(activity.date) && !activity.logged
    );
    // If there are no more unlogged items left today, show the first item for tomorrow
    if (!workouts.length && !activities.length) {
      setDateValue(formatDate(tomorrow));
      workouts = fitnessPlan.workouts.filter(
        workout =>
          formatDate(tomorrow) === formatDate(workout.date) &&
          !workout.allComplete
      );
      activities = fitnessPlan.activities.filter(
        activity =>
          formatDate(tomorrow) === formatDate(activity.date) && !activity.logged
      );
    }
    const items = [...workouts, ...activities];
    setNextFitness(items[0]);
  };

  useEffect(() => {
    if (mealPlan?.recipes.length) {
      getNextMeal();
    }
  }, [mealPlan]);

  useEffect(() => {
    if (fitnessPlan?.workouts.length || fitnessPlan?.activities.length) {
      getNextFitness();
    }
  }, [fitnessPlan]);

  const nextMealImage = nextMeal?.recipe
    ? nextMeal.recipe?.imageUrl
    : `${fileStorage}/Nutriology_Symbol_467x334.png`;
  const nextMealName = nextMeal?.recipe
    ? nextMeal.recipe?.name
    : nextMeal?.food
    ? nextMeal.food?.verboseName
    : '';

  const nextFitnessImage = nextFitness?.workout?.imageUrl
    ? nextFitness?.workout?.imageUrl
    : nextFitness?.workout?.exercises
    ? nextFitness?.workout?.exercises[0].exercise.thumbnailUrl
    : nextFitness?.activity
    ? nextFitness?.activity?.imageUrl
    : `${fileStorage}/Add%20Activity-Image-01.png`;
  const nextFitnessName = nextFitness?.workout
    ? nextFitness?.workout?.name
    : nextFitness?.activity
    ? nextFitness?.activity?.name
    : '';

  const imageURL = (image, ratio = 1) => {
    const height = 89 * ratio;
    return getImageURL(image, `height:${height}`);
  };

  return (
    <OnDeckContainer flexDirection="column">
      <Subhead>Here's What's on Deck For Today</Subhead>
      {subscriptionActive ? (
        <FlexContainer style={{ gap: 16 }} justify="space-between">
          <CardHighlight
            flexDirection="column"
            justify="space-between"
            onClick={() =>
              history.push('/app/mealplan', {
                meal: currentMeal.meal,
                date: formatDate(dateValue),
              })
            }
            data-test="explore-onDeck-mealCard"
          >
            <CardHighlightInfo flexDirection="column">
              <InfoHeader>View Meals</InfoHeader>
              <ItemName data-test="explore-onDeck-nextMeal">
                {nextMealName}
              </ItemName>
            </CardHighlightInfo>
            <CardHighlightImage
              image={imageURL(nextMealImage, 1)}
              image2x={imageURL(nextMealImage, 2)}
            />
          </CardHighlight>
          <CardHighlight
            flexDirection="column"
            onClick={() =>
              history.push('/app/fitnessplan', { date: formatDate(dateValue) })
            }
            data-test="explore-onDeck-fitnessCard"
          >
            <CardHighlightInfo flexDirection="column">
              <InfoHeader>View Fitness</InfoHeader>
              <ItemName data-test="explore-onDeck-nextFitness">
                {nextFitnessName}
              </ItemName>
            </CardHighlightInfo>
            <CardHighlightImage
              image={imageURL(nextFitnessImage, 1)}
              image2x={imageURL(nextFitnessImage, 2)}
            />
          </CardHighlight>
        </FlexContainer>
      ) : (
        <UpgradeBanner width="101%">
          <UpgradeContent flexDirection="column" justify="center">
            <TextContainer flexDirection="column">
              {/*
                "Please subscribe" is used as a flag to confirm the user subscribed properly
                If you update the copy, please update the "subscribe to the default plan" test
              */}
              <UpgradeTitle>Please subscribe to view the content</UpgradeTitle>
              <UpgradeText>
                Customized plans are only available to subscribed users. Upgrade
                now to get the full app experience!{' '}
              </UpgradeText>
            </TextContainer>
            <Button
              buttonText="Subscribe Now"
              buttonSize="small"
              variant="outlined"
              style={{ backgroundColor: '#fff', border: 'none' }}
              pink="true"
              fontcase="titleCase"
              width="150px"
              handleClick={() => history.push('/payment')}
              data-test="explore-subscribe"
            />
          </UpgradeContent>
        </UpgradeBanner>
      )}
    </OnDeckContainer>
  );
};

const OnDeckContainer = styled(FlexContainer)`
  width: 100%;
  gap: 16px;
`;

const Subhead = styled.h5`
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: ${colors.primary800};
`;

const CardHighlight = styled(FlexContainer)`
  width: calc(50% - 8px);
  height: 190px;
  border: 1px solid ${colors.primary050};
  border-bottom: none;
  /* Shadows */
  box-shadow: 0px 8px 20px rgba(29, 29, 29, 0.1);
  border-radius: 6px;
`;

const CardHighlightInfo = styled(FlexContainer)`
  padding: 11px 20px;
  flex-grow: 1;
  overflow-y: hidden;
  gap: 4px;
`;

const CardHighlightImage = styled(FlexContainer)`
  width: 100%;
  height: 117px;
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-size: cover;
  background-position: center;
  border-radius: 4px;
`;

const InfoHeader = styled.h6`
  color: ${colors.secondary500};
  text-transform: uppercase;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
`;

const ItemName = styled.h6`
  color: ${colors.primary800};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

const UpgradeContent = styled(FlexContainer)`
  position: absolute;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  padding: 16px;
  gap: 16px;
`;

const TextContainer = styled(FlexContainer)`
  gap: 8px;
`;

const UpgradeTitle = styled.h3`
  color: #fff;
  font-weight: 700;

  @media screen and (max-width: 420px) {
    font-size: 16px;
  }
`;

const UpgradeText = styled.p`
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  @media screen and (max-width: 375px) {
    font-size: 12px;
  }
`;

export default OnDeck;
