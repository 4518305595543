import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import ProgressBadge from '../universal/ProgressBadge';
import MacroTable from '../mealPlan/MacroTable';

const TodaysSummaryMeals = ({
  plannedCalories,
  currentCalories,
  mealPlan,
  caloriesBurned,
  workoutGoal,
  steps,
  stepsGoal,
  userName,
  ...props
}) => {
  const recommendedCalories = mealPlan.recommendedCalories;
  let over = false;
  if (plannedCalories > recommendedCalories) {
    over = true;
  }

  return (
    <Container alignItems="center" justify="center">
      <MacrosSection id="meal-data" justify="space-between" wrap="wrap">
        <LeftContainer
          flexDirection="column"
          alignItems="flex-start"
          justify="space-between"
        >
          <Header>Meal Calories</Header>
          <ProgressBadge
            color="teal"
            size="small"
            current={currentCalories}
            total={plannedCalories}
          />
        </LeftContainer>
        <RightContainer
          flexDirection="column"
          alignItems="flex-start"
          wrap="wrap"
          justify="space-between"
        >
          <PlannedRecommendedContainer alignItems="flex-start">
            <PlannedRecommended flexDirection="column">
              <Label>Total Planned: </Label>
              <Calories over={over} data-test="dashboard-plannedCalories">
                {plannedCalories} calories
              </Calories>
            </PlannedRecommended>
            <PlannedRecommended flexDirection="column">
              <Label>Total Recommended: </Label>
              <Calories data-test="dashboard-recommendedCalories">
                {recommendedCalories} calories
              </Calories>
            </PlannedRecommended>
          </PlannedRecommendedContainer>
          <FlexContainer style={{ maxWidth: '278px' }}>
            <MacroTable color="teal" mealPlan={mealPlan} {...props} />
          </FlexContainer>
        </RightContainer>
      </MacrosSection>
    </Container>
  );
};

const Container = styled(FlexContainer)`
  position: relative;
  width: 100%;
`;

const Section = styled(FlexContainer)`
  height: 100%;
`;

const MacrosSection = styled(Section)`
  width: 100%;
  height: 100%;
  min-width: 452px;
  gap: 30px;
`;

const LeftContainer = styled(FlexContainer)`
  width: 144px;
`;

const RightContainer = styled(FlexContainer)`
  flex-grow: 1;

  @media (max-width: 1322px) {
    flex-grow: 0.6;
  }
`;

const PlannedRecommendedContainer = styled(FlexContainer)`
  > :first-child {
    margin-right: 24px;
  }

  & :first-child {
    margin-bottom: 4px;
  }
`;

const PlannedRecommended = styled(FlexContainer)``;

const Label = styled.p`
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary500};
`;

const Calories = styled.h5`
  font-size: 13px;
  line-height: 15px;
  color: ${props =>
    props.over
      ? colors.error
      : props.over === false
      ? colors.secondary500
      : colors.primary500};
`;

const Header = styled.h6`
  color: ${colors.primary800};
  text-transform: uppercase;
`;

export default TodaysSummaryMeals;
