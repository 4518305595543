import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { colors, screenSize } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import Button from '../elements/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactGA from 'react-ga';
import {
  getActiveUserFitnessPlan,
  getUserTrack,
} from '../store/actions/fitnessplan';
import { getActiveUserMealPlan } from '../store/actions/mealplan';
import { autoAssignMealPlan } from '../services/api/mealplan';
import { autoAssignFitnessPlan } from '../services/api/fitnessPlan';
import { autoAssignTrack } from '../services/api/track';
import { addShoppingList } from '../services/api/shoppingList';
import { getShoppingLists } from '../store/actions/shoppinglist';
import { updateUser } from '../store/actions/general';
import { useDispatch, useSelector } from 'react-redux';
import AlertStack from '../elements/AlertStack';

const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

const FinalStep = ({ error, loading, setLoading, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.currentUser.user);
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState('');

  const handleSubmit = async () => {
    setLoading(true);
    if (process.env.REACT_APP_ENV === 'production') {
      ReactGA.set({ page: '/app' }, ['marketing-tracker']);
      ReactGA.pageview('/app', ['marketing-tracker']);
    }
    await assignPlans();
    await createShoppingList();
    history.push('/app', {
      from: '/questionnaire',
    });
  };

  const assignPlans = async () => {
    try {
      const userId = currentUser._id;
      const updatedUser = await autoAssignTrack(userId);
      await autoAssignMealPlan(userId, {
        timezoneOffset: new Date().getTimezoneOffset(),
      });
      await autoAssignFitnessPlan(userId, {
        timezoneOffset: new Date().getTimezoneOffset(),
      });
      const values = props.values;
      values.intakeComplete = true;
      dispatch(updateUser(values));
      dispatch(getActiveUserMealPlan(userId));
      dispatch(getActiveUserFitnessPlan(userId));
      dispatch(getUserTrack(updatedUser));
    } catch (err) {
      console.error(err);
      setLoading(false);
      setStatus('error');
      setMessage(err.message);
    }
  };

  const createShoppingList = async () => {
    try {
      await addShoppingList();
      dispatch(getShoppingLists());
    } catch (err) {
      try {
        if (err.code === 50001) {
          // User already has shopping list assigned
          await getShoppingLists();
        }
      } catch (err) {
        console.error(err);
        setLoading(false);
        setStatus('error');
        setMessage(err.message);
      }
    }
  };

  return (
    <FinalStepContainer
      alignItems="center"
      justify="center"
      padding="81px 0 0 0"
    >
      <Container flexDirection="column">
        <Title>All Set!</Title>
        <SubTitle>
          Time to check out your Nutriology Transformation Plan.
        </SubTitle>
        {/* TODO: Post-Beta feature
        https://app.clickup.com/t/2nga02e
          <Green>DISCLAIMER</Green>
          <Disclaimer>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Disclaimer>

        */}
      </Container>

      <RightContainer flexDirection="column" alignItems="flex-end">
        {error && <Error>{error}</Error>}
        {loading ? (
          <LoadingContainer
            flexDirection="column"
            alignItems="center"
            data-test="questionnaire-loading"
          >
            <CircularProgress size={48} color="inherit" />
            <LoadText>Preparing Your Plan...</LoadText>
          </LoadingContainer>
        ) : (
          <Button
            style={{
              textTransform: 'none',
            }}
            width="250px"
            onClick={handleSubmit}
            buttonSize="large"
            buttonText="Go To Plans"
            pink="true"
            data-test="questionnaire-button-to-dashboard"
          />
        )}

        <SVG icon={`${fileStorage}/Desktop.png`}></SVG>
      </RightContainer>
      {status === 'error' && (
        <AlertStack
          messages={message}
          type="error"
          variant="filled"
          open={status === 'error'}
          handleClose={() => setStatus(null)}
          autoHideDuration={20000}
          data-test="questionnaire-submit-error"
        />
      )}
    </FinalStepContainer>
  );
};

const FinalStepContainer = styled(FlexContainer)`
  @media (max-width: ${screenSize.mobileL}) {
    flex-direction: column;
  }
`;

const Title = styled.h1`
  color: ${colors.primary800};
  margin-bottom: 10px;

  @media (max-width: ${screenSize.mobileL}) {
    text-align: center;
  }
`;

const SVG = styled.div`
  background-image: url(${props => props.icon});
  background-size: contain;
  height: 242px;
  width: 320px;
  margin-top: 78px;
`;

// const Green = styled.h3`
//   color: ${colors.secondary500};
//   font-size: 15px;
//   font-weight: 700;
// `;

// const Disclaimer = styled.p`
//   color: ${colors.primary500};
// `;

const SubTitle = styled.span`
  font-size: 17px;
  color: ${colors.primary600};
  margin-bottom: 64px;

  @media (max-width: ${screenSize.mobileL}) {
    text-align: center;
  }
`;

const Container = styled(FlexContainer)`
  width: 480px;

  @media (max-width: ${screenSize.mobileL}) {
    width: 80%;
  }
`;

const RightContainer = styled(Container)`
  @media (max-width: ${screenSize.mobileL}) {
    align-items: center;
  }
`;

const LoadingContainer = styled(FlexContainer)`
  color: ${colors.primary400};
  > * {
    margin-bottom: 12px;
  }
`;

const LoadText = styled.h3`
  color: ${colors.primary400};
`;

const Error = styled.p`
  color: ${colors.error};
  margin-bottom: 16px;
`;

export default FinalStep;
