export const fitnessTags = [
  {
    label: 'Level',
    name: 'level',
    tags: [
      { label: 'Beginner', name: 'beginner' },
      { label: 'Intermediate', name: 'intermediate' },
    ],
  },
  {
    label: 'Location',
    name: 'location',
    tags: [
      { label: 'Gym', name: 'gym' },
      { label: 'At Home', name: 'home' },
      { label: 'No Equipment', name: 'noEquipment' },
    ],
  },
  {
    label: 'Specialty Focus',
    name: 'specialtyFocus',
    tags: [
      { label: 'Upper Body', name: 'upperBody' },
      { label: 'Lower Body', name: 'lowerBody' },
      { label: 'Total Body', name: 'totalBody' },
      { label: 'Abs and Core', name: 'absCore' },
      { label: 'Cardio and HIIT', name: 'cardioHIIT' },
      { label: 'Total Body Mobility', name: 'bodyMobility' },
      { label: 'Flexibility and Strech Flow', name: 'flexibilityStretch' },
      { label: 'Yoga', name: 'yoga' },
      { label: 'Pilates', name: 'pilates' },
      { label: 'Treadmill', name: 'treadmill' },
      { label: 'Cycling and Spinning', name: 'cyclingSpinning' },
      { label: 'Form and Technique', name: 'formTechnique' },
      { label: 'Plyometrics', name: 'plyometrics' },
      { label: 'Low Impact', name: 'lowImpact' },
    ],
  },
];
