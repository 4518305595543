import React, { useEffect } from 'react';
import styled from 'styled-components';
import AuthPageWeb from './AuthPageWeb';
import AuthPageMobile from './AuthPageMobile';
import { useLocation } from 'react-router-dom';
import { isMobile } from '../helpers/utils';
import { removeError } from '../store/actions/general';
import useFormValues from '../hooks/useFormValues';
import useToggles from '../hooks/useToggles';
import { useDispatch } from 'react-redux';

const AuthPage = ({ resetToken, ...props }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!location?.state?.message) {
      dispatch(removeError());
    }
  }, [location]);

  const initialToggleValues = {
    accepted: false,
  };
  const { values, setValues, handleChange } = useFormValues();
  const { toggleValues, handleToggle } = useToggles(initialToggleValues);

  return (
    <ContentContainer>
      <ScrollContainer>
        {isMobile() ? (
          <AuthPageMobile
            values={values}
            setValues={setValues}
            handleChange={handleChange}
            toggleValues={toggleValues}
            handleToggle={handleToggle}
            resetToken={resetToken}
          />
        ) : (
          <AuthPageWeb
            values={values}
            setValues={setValues}
            handleChange={handleChange}
            toggleValues={toggleValues}
            handleToggle={handleToggle}
            resetToken={resetToken}
          />
        )}
      </ScrollContainer>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  height: 100%; /*allows both columns to span the full height of the browser window*/
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const ScrollContainer = styled.div`
  flex: 1;
`;

export default AuthPage;
