import zxcvbn from 'zxcvbn';
import dot from 'dot-object';

const validatePassword = (values, errors) => {
  let score;
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password !== values?.password2) {
    errors.password = 'Passwords must match';
  } else if (values?.currentPassword === values.password) {
    errors.password = 'New password cannot be the same as old password';
  } else if (values.password2) {
    const passStrength = zxcvbn(values.password2);
    score = passStrength.score;
    if (score < 2) {
      errors.password = 'Please choose a stronger password';
    }
  }
};

const validateEmail = (values, errors) => {
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (
    /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test( /* eslint-disable-line */
      values.email
    ) === false
  ) {
    errors.email = 'Email address is invalid';
  }
};

const validateRequired = (values, errors, requiredFields) => {
  requiredFields.forEach(required => {
    if (!dot.pick(required, values)) {
      errors[required] = 'Field is required';
    }
  });
  dot.object(errors);
};

const formValidation = (values, formType = '') => {
  let errors = {};

  if (formType === 'register') {
    validateRequired(values, errors, ['firstName', 'lastName']);
  }

  if (formType === 'register' || formType === 'reset') {
    validatePassword(values, errors);
  }

  if (formType === 'register' || formType === 'login') {
    validateEmail(values, errors);
    if (!values.password) {
      errors.password = 'Password is required';
    }
  }

  if (formType === 'update') {
    validateEmail(values, errors);
    if (!values.fullName) {
      errors.fullName = 'Full name is required';
    }
  }

  if (formType === 'update+password') {
    validatePassword(values, errors);
    validateEmail(values, errors);
    if (!values.fullName) {
      errors.fullName = 'Full name is required';
    }
  }

  if (formType === 'details') {
    validateRequired(values, errors, [
      'weight',
      'gender',
      'detailPreferences.activityLevel',
      'detailPreferences.nutriologyGoal',
      'detailPreferences.weightGoal',
      'birthDate',
    ]);
  }
  return errors;
};

export default formValidation;
