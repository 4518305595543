import {
  SET_ACTIVE_FITNESS_PLAN,
  SET_SELECTED_FITNESS_PLAN,
  SET_TRACK,
} from '../actionTypes';

const DEFAULT_STATE_FITNESS_PLAN = {
  id: null,
  planRef: 'B1-1',
  active: true,
  type: 'Weight Loss',
  level: 'Beginner',
  locationType: 'Gym',
  gender: 'All',
  planLength: 7,
  activities: [],
  workouts: [],
};

const DEFAULT_STATE_USER_TRACK = {
  id: null,
  name: 'Default',
  level: 'Beginner',
  locationType: 'Home',
  gender: 'All',
  fitnessPlans: [],
  backTrack: '33aa433927361803a11a1712',
  remainTrack: '33aa433927361803a11a1718',
  nextTrack: '33aa433927361803a11a1723',
  nextLevel: '33aa433927361803a11a1723',
  active: true,
  primary: true,
  loop: false,
};

export const activeFitnessPlan = (
  state = DEFAULT_STATE_FITNESS_PLAN,
  action
) => {
  switch (action.type) {
    case SET_ACTIVE_FITNESS_PLAN:
      return {
        ...state,
        ...action.fitnessPlan,
      };

    default:
      return state;
  }
};
// Selected fitness plan allows us to distinguish when a user is updating a fitness plan in the future,
// e.g. next week, from the current fitness plan, which should not change.
export const selectedFitnessPlan = (
  state = DEFAULT_STATE_FITNESS_PLAN,
  action
) => {
  switch (action.type) {
    case SET_SELECTED_FITNESS_PLAN:
      return {
        ...state,
        ...action.fitnessPlan,
      };

    default:
      return state;
  }
};

export const userTrack = (state = DEFAULT_STATE_USER_TRACK, action) => {
  switch (action.type) {
    case SET_TRACK:
      return {
        ...state,
        ...action.track,
      };

    default:
      return state;
  }
};
