import React from 'react';
import styled from 'styled-components';

const FitnessLogSwipe = ({
  logged,
  toggleSelect,
  dateLogged,
  hideLabel,
  small,
  ...props
}) => (
  <Icon onClick={toggleSelect} logged={logged} data-test="setRow-log">
    <TextStyle logged={logged}>Log</TextStyle>
  </Icon>
);

const Icon = styled.div`
  height: 75px;
  width: 120px;
  background-color: #16b8ba;
`;
const TextStyle = styled.div`
  height: 18px;
  width: 49px;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  padding-top: 28px;
  padding-left: 36px;
`;
export default FitnessLogSwipe;
