import React, { useState, useMemo, useEffect } from 'react';
import { capitalize, uniqWith, isEqual, filter, debounce } from 'lodash';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InputBase from '@material-ui/core/InputBase';
import Button from '../elements/Button';
import Chip from '../elements/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import throttle from 'lodash/throttle';

const SearchModalReturnObject = ({
  open,
  fetchService,
  handleClose,
  value,
  setValue,
  ...props
}) => {
  const [modalValue, setModalValue] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const handleChangeInput = event => {
    setInputValue(event.target.value);
  };

  const handleChange = (event, newValue) => {
    if (options.length > 0) {
      setModalValue(uniqWith(newValue, isEqual));
    }
  };

  const fetch = useMemo(
    () =>
      throttle((params, callback) => {
        fetchService(params).then(callback);
      }, 1000),
    [fetchService]
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    if (fetchService) {
      const params = {
        query: inputValue,
        limit: 200,
      };
      fetch(params, results => {
        if (active) {
          setOptions(results);
        }
      });
    }

    return () => {
      active = false;
    };
  }, [inputValue, fetch, fetchService]);

  const handleDelete = item => {
    setModalValue(filter(modalValue, v => v.id !== item.id));
  };

  const handleAccept = () => {
    setValue(modalValue); // replaces previous values
    // the idea behind this is the modal only cares about the values in
    // its current session. The page that calls the modal can manage
    // separate state to drive its UI elements.
    setModalValue([]);
    handleClose();
  };

  const handleCloseModal = () => {
    setModalValue([]);
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle disableTypography>
        {handleClose ? (
          <TitleButton aria-label="close" onClick={handleCloseModal}>
            <CloseIcon />
          </TitleButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Title>Search Foods</Title>
        <AutocompleteStyled
          multiple
          autoComplete
          freeSolo
          includeInputInList
          filterOptions={x => x}
          options={options}
          getOptionLabel={
            props.getOptionLabel || (option => capitalize(option.name))
          }
          value={modalValue}
          onChange={debounce(handleChange, 500)}
          renderInput={params => (
            <InputBase
              ref={params.InputProps.ref}
              inputProps={{
                ...params.inputProps,
                onChange(event) {
                  handleChangeInput(event);
                  return params.inputProps.onChange(event);
                },
              }}
              placeholder={props.placeholder || ''}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="search" edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              data-test="searchModal-ingSearch"
            />
          )}
        />
        {modalValue.map((option, index) => (
          <ChipStyled
            key={index}
            label={props.getOptionLabel(option)}
            handleDelete={() => handleDelete(option)}
            data-test="searchModal-ingChip"
          />
        ))}
      </DialogContent>
      <DialogActions>
        <AddButton
          buttonText="ADD"
          onClick={handleAccept}
          data-test="searchModal-add"
        />
      </DialogActions>
    </Dialog>
  );
};

const TitleButton = styled(IconButton)`
  top: 4px;
  color: #9e9e9e;
  right: 8px;
  position: absolute;
`;

const AddButton = styled(Button)`
  margin: 16px;
  height: 40px;
  width: 89px;
  border-radius: 4px;
  font-size: 19px;
  font-weight: 600;
  line-height: 23px;
`;

const Title = styled.h5`
  color: ${colors.primary700};
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const ChipStyled = styled(Chip)`
  margin-right: 10px;
  margin-bottom: 10px;
`;

const AutocompleteStyled = styled(Autocomplete)`
  width: 552px;
  &.Mui-focused > div {
    border: ${props =>
      props.error ? '1px solid #ff7c0e' : `1px solid ${colors.secondary500}`};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
  > div {
    box-sizing: border-box;
    height: 41px;
    width: 100%;
    border: ${props =>
      props.error ? '1px solid #ff7c0e' : `1px solid ${colors.primary400}`};
    background-color: #ffffff;
    margin: 20px 0;
    padding: 0 12px;
    font-size: ${props => (props.small ? '15px' : '17px')};
    line-height: ${props => (props.small ? '18px' : '20px')};
    border-radius: 2px;

    &input::placeholder {
      color: ${colors.primary400};
    }
  }
`;
export default SearchModalReturnObject;
