import React from 'react';
import styled from 'styled-components';
import { screenSize } from '../styleConstants';
import { isMobile } from '../helpers/utils';
import { camelCase } from 'lodash';
import FlexContainer from '../elements/FlexContainer';
import DetailCard from '../profile/DetailCard';
import { StepNumber, StepTitle } from './StepComponents';

const StepFour = ({ values, handleValueChanged }) => {
  const styles = [
    { value: 'At the gym', name: 'gym' },
    { value: 'At home', name: 'home' },
  ];

  const cards = styles.map(({ value, name }) => (
    <DetailCard
      key={name}
      imgSrc={`${process.env.PUBLIC_URL}/icons/ExercisePrefs/${name}.png`}
      title={value}
      isSelected={values.fitnessPreferences.fitnessStyle === value}
      name="fitnessPreferences.fitnessStyle"
      handleSelect={() =>
        handleValueChanged('fitnessPreferences.fitnessStyle', value, 'string')
      }
      size={!isMobile() && 'large'}
      data-test={`intake-fitnessStyle-${camelCase(name)}`}
    />
  ));

  return (
    <FlexContainer
      flexDirection="column"
      alignItems="center"
      justify="space-between"
    >
      {!isMobile() && <StepNumber>4</StepNumber>}
      <StepTitle>Where do you prefer to work out most of the time?</StepTitle>
      <CardsContainer wrap="wrap">{cards}</CardsContainer>
    </FlexContainer>
  );
};

const CardsContainer = styled(FlexContainer)`
  gap: 15px;

  @media (max-width: ${screenSize.mobileL}) {
    align-items: center;
    justify-content: center;
  }
`;

export default StepFour;
