import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';

const UserInfo = ({ user }) => {
  const boolMap = {
    isAdmin: user?.isAdmin ? 'True' : 'False',
    isVerified: user?.isVerified ? 'True' : 'False',
    intakeComplete: user?.intakeComplete ? 'True' : 'False',
  };

  return (
    <InfoContainer flexDirection="column" data-test="admin-userInfo">
      <Header>User Info</Header>
      <InfoRow data-test="admin-userInfo-id">
        <InfoLabel>ID: </InfoLabel>
        <Info>{user?._id}</Info>
      </InfoRow>
      <InfoRow data-test="admin-userInfo-name">
        <InfoLabel>Name: </InfoLabel>
        <Info>{user?.fullName}</Info>
      </InfoRow>
      <InfoRow data-test="admin-userInfo-email">
        <InfoLabel>Email: </InfoLabel>
        <Info>{user?.email}</Info>
      </InfoRow>
      <InfoRow data-test="admin-userInfo-isAdmin">
        <InfoLabel>Admin?: </InfoLabel>
        <Info>{boolMap.isAdmin}</Info>
      </InfoRow>
      <InfoRow data-test="admin-userInfo-isVerified">
        <InfoLabel>Verified?: </InfoLabel>
        <Info>{boolMap.isVerified}</Info>
      </InfoRow>
      <InfoRow data-test="admin-userInfo-intakeComplete">
        <InfoLabel>Intake Complete?: </InfoLabel>
        <Info>{boolMap.intakeComplete}</Info>
      </InfoRow>
      <InfoRow data-test="admin-userInfo-goal">
        <InfoLabel>Goal: </InfoLabel>
        <Info>{user?.detailPreferences?.nutriologyGoal}</Info>
      </InfoRow>
      <InfoRow data-test="admin-userInfo-activityLevel">
        <InfoLabel>Activity Level: </InfoLabel>
        <Info>{user?.detailPreferences?.activityLevel}</Info>
      </InfoRow>
      <InfoRow data-test="admin-userInfo-recCalories">
        <InfoLabel>Recommended Calories: </InfoLabel>
        <Info>{user?.recommendedMealPlanCalories}</Info>
      </InfoRow>
      <InfoRow data-test="admin-userInfo-dietType">
        <InfoLabel>Diet Type: </InfoLabel>
        <Info>{user?.foodPreferences?.eatingPreference}</Info>
      </InfoRow>
      <InfoRow data-test="admin-userInfo-fitnessTrack">
        <InfoLabel>Fitness Track: </InfoLabel>
        <Info>{user?.fitnessTrack}</Info>
      </InfoRow>
      <InfoRow data-test="admin-userInfo-fitnessStyle">
        <InfoLabel>Fitness Style: </InfoLabel>
        <Info>{user?.fitnessPreferences?.fitnessStyle}</Info>
      </InfoRow>
      <InfoRow data-test="admin-userInfo-dateCreated">
        <InfoLabel>Date Created: </InfoLabel>
        <Info>{user?.createdAt}</Info>
      </InfoRow>
    </InfoContainer>
  );
};

const InfoContainer = styled(FlexContainer)`
  margin-left: 12px;
  > * {
    margin-bottom: 12px;
  }
`;

const Header = styled.h2`
  margin: 16px 0;
  color: ${colors.primary800};
`;

const InfoRow = styled(FlexContainer)``;

const InfoLabel = styled.h4`
  color: ${colors.primary800};
  margin-right: 12px;
`;

const Info = styled.h4`
  color: ${colors.secondary600};
`;

export default UserInfo;
