import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';

const ChipDeleteIcon = ({ style, ...props }) => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={style}
    {...props}
  >
    <g
      id="Style-Guide"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Style-Guide---Elements"
        transform="translate(-1308.000000, -2752.000000)"
        fill={colors.secondary600}
      >
        <path
          d="M1321.10121,2764 L1324.79221,2760.30901 C1325.0851,2760.01611 1325.0851,2759.54124 1324.79221,2759.24835 L1324.75165,2759.20779 C1324.45876,2758.9149 1323.98389,2758.9149 1323.69099,2759.20779 L1320,2762.89879 L1316.30901,2759.20779 C1316.01611,2758.9149 1315.54124,2758.9149 1315.24835,2759.20779 L1315.20779,2759.24835 C1314.9149,2759.54124 1314.9149,2760.01611 1315.20779,2760.30901 L1318.89879,2764 L1315.20779,2767.69099 C1314.9149,2767.98389 1314.9149,2768.45876 1315.20779,2768.75165 L1315.24835,2768.79221 C1315.54124,2769.0851 1316.01611,2769.0851 1316.30901,2768.79221 L1320,2765.10121 L1323.69099,2768.79221 C1323.98389,2769.0851 1324.45876,2769.0851 1324.75165,2768.79221 L1324.79221,2768.75165 C1325.0851,2768.45876 1325.0851,2767.98389 1324.79221,2767.69099 L1321.10121,2764 Z M1320,2776 C1313.37258,2776 1308,2770.62742 1308,2764 C1308,2757.37258 1313.37258,2752 1320,2752 C1326.62742,2752 1332,2757.37258 1332,2764 C1332,2770.62742 1326.62742,2776 1320,2776 Z"
          id="X-icon-chip"
        ></path>
      </g>
    </g>
  </Icon>
);

const Icon = styled.svg`
  width: ${props => props?.style?.width || '24px'};
  height: ${props => props?.style?.height || '24px'};
`;

export default ChipDeleteIcon;
