import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import { getImageURL } from '../services/api/api';
const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

const WorkoutCard = ({
  workout,
  setWorkout,
  setScreen,
  isMobile,
  ...props
}) => {
  const handleClick = e => {
    setWorkout(workout);
    setScreen('Workout Page');
  };

  const workoutImgUrl = workout => {
    if (workout.imageUrl) {
      return getImageURL(workout.imageUrl, 'height:152');
    } else {
      return getImageURL(
        `${fileStorage}/Add%20Activity-Image-01.png`,
        'height:152'
      );
    }
  };

  if (isMobile) {
    // mobile version
    return (
      <MobileCardContainer
        alignItems="center"
        onClick={handleClick}
        data-test="fitnessModal-workoutCard"
      >
        <ImageContainer image={workoutImgUrl(workout)}></ImageContainer>
        <WorkoutNameContainer>{workout.name}</WorkoutNameContainer>
      </MobileCardContainer>
    );
  } else {
    // web version
    return (
      <CardContainer
        flexDirection="column"
        alignItems="center"
        justify="center"
        onClick={handleClick}
        data-test="fitnessModal-workoutCard"
        isMobile={isMobile}
      >
        <TitleContainer>
          <WorkoutTitle>{workout.name}</WorkoutTitle>
        </TitleContainer>
        {!workout.active && (
          <InactiveContainer flexDirection="column">
            <Info data-test="workoutCard-inactive">Inactive</Info>
          </InactiveContainer>
        )}
        {props?.context?.variant === 'admin' && (
          <InfoContainer flexDirection="column">
            <Info data-test="workoutCard-gender">{workout.gender}</Info>
            <Info data-test="workoutCard-locationType">
              {workout.locationType}
            </Info>
          </InfoContainer>
        )}
      </CardContainer>
    );
  }
};

const CardContainer = styled(FlexContainer)`
  height: 124px;
  width: ${props => (props.isMobile ? '100%' : '192px')};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1.5px solid ${colors.primary200};
  border-radius: 4px;
  position: relative;

  :hover {
    cursor: pointer;
    opacity: 0.8;
  }

  :active {
    opacity: 0.7;
  }
`;

const InfoContainer = styled(FlexContainer)`
  position: absolute;
  bottom: 5px;
  right: 5px;
`;

const InactiveContainer = styled(FlexContainer)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const Info = styled.p`
  color: ${colors.primary500};
  font-size: 13px;
  line-height: 8px;
  margin: 4px;
`;

const TitleContainer = styled(FlexContainer)`
  padding: 0 16px;
  text-align: center;
`;

const WorkoutTitle = styled.h4`
  color: ${colors.primary800};
`;

const MobileCardContainer = styled(FlexContainer)`
  box-sizing: border-box;
  width: 100%;
  border: 1.5px solid ${colors.primary200};
  border-radius: 4px;
`;

const ImageContainer = styled(FlexContainer)`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  height: 90px;
  width: 40%;
  min-width: 40%;
`;

const WorkoutNameContainer = styled.div`
  color: ${colors.primary800};
  font-family: 'Work Sans';
  font-size: 17px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 0 20px;
  width: auto;
`;

export default WorkoutCard;
