export const recipeTags = [
  {
    label: 'Diet Type',
    name: 'dietType',
    tags: [
      { label: 'Lacto Vegetarian', name: 'lactoVegetarian' },
      { label: 'Ovo Vegetarian', name: 'ovoVegetarian' },
      { label: 'Paleo', name: 'paleo' },
      { label: 'Pescetarian', name: 'pescatarian' },
      { label: 'Vegan', name: 'vegan' },
      { label: 'Vegetarian', name: 'vegetarian' },
      { label: 'Ketogenic', name: 'ketogenic' },
      { label: 'Whole 30', name: 'whole30' },
      { label: 'Low Sodium', name: 'lowSodium' },
    ],
  },
  {
    label: 'Intolerances',
    name: 'intolerances',
    tags: [
      { label: 'None', name: 'none' },
      { label: 'Dairy', name: 'dairy' },
      { label: 'Egg', name: 'egg' },
      { label: 'Gluten', name: 'gluten' },
      { label: 'Grains', name: 'grains' },
      { label: 'Peanut', name: 'peanut' },
      { label: 'Fish', name: 'fish' },
      { label: 'Sesame', name: 'sesame' },
      { label: 'Shellfish', name: 'shellfish' },
      { label: 'Soy', name: 'soy' },
      { label: 'Sulfite', name: 'sulfite' },
      { label: 'Tree Nut', name: 'treeNut' },
      { label: 'Wheat', name: 'wheat' },
    ],
  },
  {
    label: 'Meal Type',
    name: 'mealType',
    tags: [
      { label: 'Any', name: 'anyMeal' },
      { label: 'Beverage', name: 'beverage' },
      { label: 'Breakfast', name: 'breakfast' },
      { label: 'Lunch', name: 'lunch' },
      { label: 'Dinner', name: 'dinner' },
      { label: 'Dessert', name: 'dessert' },
      { label: 'Side Dish', name: 'sideDish' },
      { label: 'Snack', name: 'snack' },
    ],
  },
  {
    label: 'Cooking Style',
    name: 'cookingStyle',
    tags: [
      { label: 'Slow Cooker', name: 'slowCooker' },
      { label: 'BBQ & Grilling', name: 'bbqGrilling' },
      { label: 'Cooking For Kids', name: 'cookingForKids' },
      { label: 'Cooking For Two', name: 'cookingForTwo' },
      { label: 'Prep Ahead of Time', name: 'prepAhead' },
      { label: 'Take on the Go', name: 'toGo' },
      { label: 'Five ingredients or fewer', name: 'fiveFewer' },
      { label: 'Prep and Freeze', name: 'prepFreeze' },
      { label: 'Salad', name: 'salad' },
    ],
  },
  {
    label: 'Primary Ingredient',
    name: 'primaryIngredient',
    tags: [
      { label: 'Beef', name: 'beef' },
      { label: 'Beans & Legumes', name: 'beansLegumes' },
      { label: 'Chicken', name: 'chicken' },
      { label: 'Chocolate', name: 'chocolate' },
      { label: 'Egg', name: 'egg' },
      { label: 'Fruit', name: 'fruit' },
      { label: 'Game Meats', name: 'gameMeats' },
      { label: 'Grains', name: 'grain' },
      { label: 'Mushrooms', name: 'mushrooms' },
      { label: 'Pasta', name: 'pasta' },
      { label: 'Pork', name: 'pork' },
      { label: 'Potatoes', name: 'potatoes' },
      { label: 'Poultry', name: 'poultry' },
      { label: 'Rice', name: 'rice' },
      { label: 'Fish', name: 'fish' },
      { label: 'Seafood', name: 'seafood' },
      { label: 'Shrimp', name: 'shrimp' },
      { label: 'Tofu & Tempeh', name: 'tofuTempeh' },
      { label: 'Turkey', name: 'turkey' },
      { label: 'Vegetable Recipes', name: 'vegRecipes' },
    ],
  },
  {
    label: 'Prep Time',
    name: 'prepTime',
    tags: [
      { label: '30 mins or less', name: 'min30' },
      { label: '15 mins or less', name: 'min15' },
      { label: 'No Cook', name: 'noCook' },
    ],
  },
];
