import React, { useRef } from 'react';
import Papa from 'papaparse';
import Fraction from 'fraction.js';
import { searchFoodsByEsha } from '../../services/api/food';
import Button from '../../elements/Button';
import { convertNutrients } from '../../helpers/units';
import { getMonthFromString } from '../../helpers/date';

const UploadCSV = ({
  setValues,
  setInstructionsValue,
  setIngredientsValue,
  setFailedImports,
  setFoods,
  setUploadError,
}) => {
  const inputFile = useRef(null);

  const handleCSV = () => {
    inputFile.current.click();
  };

  const handleChangeFile = e => {
    Papa.parse(e.target.files[0], {
      header: true,
      encoding: 'ISO-8859-1',
      complete: res => {
        populateValuesFromCSV(res.data);
      },
    });
  };

  const populateValuesFromCSV = async data => {
    setFoods([]);

    const firstItem = data[0];
    let version;
    if (firstItem['Name'].split(' - ')[1]) {
      version = firstItem['Name'].split(' - ')[1][0];
    }
    setValues(prev => ({
      ...prev,
      name: firstItem['Name'],
      ...(version ? { version } : {}),
      prepTime: parseInt(firstItem['Preparation Time']),
      cookTime: parseInt(firstItem['Cooking Time']),
      yield: parseInt(firstItem['Serving Size Quantity']),
    }));

    const tmpInstructions = firstItem['Preparation Instructions'].split(
      /(\d)\. /
    );
    tmpInstructions.splice(0, 1);
    const instructions = tmpInstructions.filter((_, index) => index % 2 === 1);
    setInstructionsValue(
      instructions.map((item, index) => ({ num: index, step: item }))
    );

    const eshas = data.map(item => item['Item ESHA Code']);
    const ingredients = await searchFoodsByEsha({
      eshas,
    });

    const ingObj = ingredients.reduce(
      (cur, val) => ({
        ...cur,
        [val.esha]: val,
      }),
      {}
    );
    const eshaObj = data.reduce(
      (cur, val) => ({
        ...cur,
        [val['Item ESHA Code']]: val,
      }),
      {}
    );

    // separate successful and failed imports
    const success = [],
      failure = [];

    try {
      eshas.forEach(esha => {
        if (ingObj[esha]) {
          const ingredient = ingObj[esha];
          const convertedNutrients = convertNutrients(
            ingredient.nutrientsPerUnit,
            ingredient.gramWeightPerUnit,
            eshaObj[esha]['Item Measure'],
            ingredient.measureUnit,
            ingredient.name
          );
          let quantity = eshaObj[esha]['Item Quantity'];
          const qObj = quantity.split('-');
          if (qObj.length > 1) {
            quantity = `${getMonthFromString(qObj[1])}/${qObj[0]}`;
          }
          success.push({
            ...ingredient,
            amount: new Fraction(quantity).valueOf(),
            measureUnit: eshaObj[esha]['Item Measure'],
            nutrientsPerUnit: convertedNutrients,
          });
        } else {
          failure.push({
            name: eshaObj[esha]['Item Name'],
            esha,
          });
        }
      });
      setIngredientsValue([...success]);
      setFailedImports([...failure]);
    } catch (err) {
      console.error(err);
      setUploadError(err.toString());
    }
  };

  return (
    <>
      <Button
        buttonText="Upload CSV"
        buttonSize="large"
        width="244px"
        handleClick={handleCSV}
      />
      <input
        hidden
        ref={inputFile}
        type="file"
        accept=".csv"
        data-test="recipe-upload-csv"
        onChange={handleChangeFile}
      />
    </>
  );
};

export default UploadCSV;
