import convert from 'convert-units';
import { capitalize } from 'lodash';

export const allUnits = [
  'Appetizer',
  'Bag',
  'Bottle',
  'Bowl',
  'Box',
  'Can',
  'Capsule',
  'Container',
  'Cube',
  'Cubic Inch',
  'Cup',
  'Dash',
  'Drop',
  'Dry Serving',
  'Each',
  'Entree',
  'Extra Large',
  'Fluid ounce',
  'Gallon',
  'Gram',
  'Jar',
  'Large',
  'Liter',
  'Meal',
  'Medium',
  'Milliliter',
  'Mini',
  'Order',
  'Ounce',
  'Package',
  'Packet',
  'Piece',
  'Pinch',
  'Pint',
  'Portion Cup',
  'Pouch',
  'Pound',
  'Quart',
  'Regular',
  'Scoop',
  'Serving',
  'Sheet',
  'Side',
  'Slice',
  'Small',
  'Stick',
  'Tablespoon',
  'Tablet',
  'Teaspoon',
  'Thin Slice',
  'Topping',
  'Whole',
];

const volumeUnits = [
  'cup',
  'drop',
  'fluid ounce',
  'fl-oz',
  'gallon',
  'gal',
  'litre',
  'liter',
  'l',
  'milliliter',
  'ml',
  'pint',
  'pnt',
  'quart',
  'qt',
  'tablespoon',
  'tbsp',
  'tbs',
  'teaspoon',
  'tsp',
];

export const getUnitList = initialUnit => {
  const unitList = [];

  const lUnit = initialUnit.toLowerCase();

  if (volumeUnits.includes(lUnit)) {
    unitList.push(
      'Cup',
      'Fluid ounce',
      'Milliliter',
      'Tablespoon',
      'Teaspoon',
      'Quart',
      'Pint',
      'Gallon',
      'Milliliter',
      'Liter'
    );
  } else {
    unitList.push('Ounce', 'Pound', 'Gram');
  }

  return unitList;
};

export const unitMap = {
  cup: 'cup',
  drop: 'drop',
  'fluid ounce': 'fl-oz',
  'fl-oz': 'fl-oz',
  gallon: 'gal',
  gal: 'gal',
  gram: 'g',
  g: 'g',
  litre: 'l',
  liter: 'l',
  l: 'l',
  milliliter: 'ml',
  ml: 'ml',
  'ounce-weight': 'oz',
  ounce: 'oz',
  oz: 'oz',
  pint: 'pnt',
  pnt: 'pnt',
  pound: 'lb',
  quart: 'qt',
  qt: 'qt',
  lb: 'lb',
  tablespoon: 'Tbs',
  tbsp: 'Tbs',
  tbs: 'Tbs',
  teaspoon: 'tsp',
  tsp: 'tsp',
};

export const evalApprovedUnits = unit => {
  const lUnit = unit.toLowerCase();
  if (Object.keys(unitMap).includes(lUnit)) {
    return true;
  }
  return false;
};

const mapUnits = oldUnit => {
  const lUnit = oldUnit.toLowerCase();
  let newUnit = unitMap[lUnit];
  if (!newUnit) {
    newUnit = 'g';
  }
  return newUnit;
};

// TODO: eventually we will need to include region and ensure metric units are included
// https://app.clickup.com/t/2mujacg
export const standardizeUnits = (value, nonStdUnit, gramWeightPerUnit) => {
  try {
    let stdUnit;
    if (volumeUnits.includes(nonStdUnit.toLowerCase())) {
      stdUnit = 'fl-oz';
    } else {
      stdUnit = 'oz';
    }

    let newValue = convertQuantity(
      value,
      gramWeightPerUnit,
      stdUnit,
      nonStdUnit
    );

    // adjust units based on size of converted value
    if (stdUnit === 'fl-oz') {
      if (newValue < 0.5) {
        stdUnit = 'tsp';
        newValue = convert(newValue)
          .from('fl-oz')
          .to(stdUnit);
      } else if (newValue < 1) {
        stdUnit = 'Tbs';
        newValue = convert(newValue)
          .from('fl-oz')
          .to(stdUnit);
      } else if (newValue >= 16 && newValue < 64) {
        stdUnit = 'cup';
        newValue = convert(newValue)
          .from('fl-oz')
          .to(stdUnit);
      } else if (newValue >= 64 && newValue < 128) {
        stdUnit = 'qt';
        newValue = convert(newValue)
          .from('fl-oz')
          .to(stdUnit);
      } else {
        stdUnit = 'gal';
        newValue = convert(newValue)
          .from('fl-oz')
          .to(stdUnit);
      }
    } else if (stdUnit === 'oz') {
      if (newValue > 16) {
        stdUnit = 'lb';
        newValue = convert(newValue)
          .from('oz')
          .to(stdUnit);
      }
    }

    stdUnit = capitalize(stdUnit);

    return {
      newValue,
      stdUnit,
    };
  } catch (err) {
    console.error('There was an error while standardizing the units');
  }
};

export const convertNutrients = (
  nutrients,
  gramWeightPerUnit,
  newUnits,
  originalUnits,
  ingName = '' // optional
) => {
  try {
    // if the original units and the new units are the same, do nothing
    if (newUnits === originalUnits) {
      return nutrients;
    }
    const converted = {};

    originalUnits = mapUnits(originalUnits);
    newUnits = mapUnits(newUnits);

    for (const nutrient in nutrients) {
      if (originalUnits === 'g') {
        // need to convert most ESHA weights to be per gram
        nutrients[nutrient] = nutrients[nutrient] / gramWeightPerUnit;
      }
      if (originalUnits === 'drop') {
        // drop does not exist in convert-units
        nutrients[nutrient] = nutrients[nutrient] / 60; // 60 drops per tsp
        originalUnits = 'tsp';
      }
      const conversionFactor = convert(1)
        .from(originalUnits)
        .to(newUnits);
      converted[nutrient] = nutrients[nutrient] / conversionFactor;
    }
    return converted;
  } catch (err) {
    if (
      err
        .toString()
        .includes('Cannot convert incompatible measures of mass and volume')
    ) {
      throw new Error(
        `${ingName} - Cannot convert between mass and volume. Attempted to convert ${originalUnits} to ${newUnits}`
      );
    } else {
      throw new Error('There was an error while converting ingredients');
    }
  }
};

export const convertQuantity = (
  quantity,
  gramWeightPerUnit,
  newUnits,
  originalUnits
) => {
  try {
    let negative = quantity < 0 ? true : false;
    let updatedQuantity = Math.abs(quantity);
    let mappedOriginalUnits = mapUnits(originalUnits);
    const mappedNewUnits = mapUnits(newUnits);
    if (mappedOriginalUnits === 'g') {
      // need to convert most ESHA weights to grams
      updatedQuantity = updatedQuantity * gramWeightPerUnit;
    }
    if (mappedOriginalUnits === 'drop') {
      // drop does not exist in convert-units
      updatedQuantity = updatedQuantity / 60; // 60 drops per tsp
      mappedOriginalUnits = 'tsp';
    }
    let converted = convert(updatedQuantity)
      .from(mappedOriginalUnits)
      .to(mappedNewUnits);
    if (negative) {
      converted = -Math.abs(converted);
    }
    return converted;
  } catch (err) {
    console.error('Conversion error: Please check the units being converted.');
  }
};

// Used for shopping list feature
// Given a quantity and measure unit for a food, we first evaluate if the units are
// on the approved list, and if not we convert the quantity and units to an approved unit.
// Then, if we provide a comparison food, we determine if the units match, and if they don't
// we need to convert the quantity and units to those of the comparison food.
export const evalAndConvertUnits = (
  foodQuantity,
  foodUnit,
  gramWeightPerUnit,
  comparisonFood
) => {
  let newQuantity = foodQuantity;
  let newUnit = foodUnit;
  if (!evalApprovedUnits(foodUnit)) {
    const { newValue, stdUnit } = standardizeUnits(
      foodQuantity,
      foodUnit,
      gramWeightPerUnit
    );
    newQuantity = newValue;
    newUnit = stdUnit;
  }

  if (comparisonFood && foodUnit !== comparisonFood.measureUnit) {
    newQuantity = convertQuantity(
      foodQuantity,
      gramWeightPerUnit,
      comparisonFood.measureUnit,
      foodUnit
    );
    newUnit = comparisonFood.measureUnit;
  }
  return { newQuantity, newUnit };
};
