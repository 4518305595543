import React, { useState } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '../../elements/Button';
import UserSearch from './UserSearch';
import UserInfo from './UserInfo';
import AlertStack from '../../elements/AlertStack';
import { designateAdmin } from '../../services/api/user';

const DesignateAdmin = () => {
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();

  const convertUserToAdmin = () => {
    setLoading(true);

    designateAdmin(user._id)
      .then(result => {
        setStatus('success');
        setMessage(`User ${user.fullName} has been converted to an admin`);
        setUser(null);
      })
      .catch(err => {
        setStatus('error');
        if (err.errors && err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container wrap="wrap" flexDirection="column">
      <SearchRow>
        <UserSearch setUser={setUser} />
        {user && (
          <InfoContainer>
            <UserInfo user={user} />
          </InfoContainer>
        )}
      </SearchRow>
      {user && (
        <Button
          type="submit"
          buttonText="Convert To Admin"
          buttonSize="large"
          onClick={convertUserToAdmin}
          startIcon={loading && <CircularProgress size={24} color="inherit" />}
          data-test="user-convert-admin"
        />
      )}
      {status === 'error' ? (
        <AlertStack
          messages={message}
          type="error"
          variant="filled"
          open={status === 'error'}
          handleClose={() => setStatus(null)}
          autoHideDuration={20000}
          data-test="user-message-error"
        />
      ) : status === 'success' ? (
        <AlertStack
          messages={message}
          type="success"
          variant="filled"
          open={status === 'success'}
          handleClose={() => setStatus('hidden')}
          autoHideDuration={20000}
          data-test="user-message-success"
        />
      ) : null}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;

  > * {
    margin-bottom: 24px;
  }
`;

const SearchRow = styled(FlexContainer)``;

const InfoContainer = styled.div`
  margin-top: 48px;
`;

export default DesignateAdmin;
