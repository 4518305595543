import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import { default as MaterialChip } from '@material-ui/core/Chip';
import { isMobile } from '../helpers/utils';

const Chip = ({
  selected,
  label,
  handleDelete,
  toggleSelect,
  name,
  ...props
}) => (
  <StyledChip
    selected={selected}
    label={label}
    clickable={toggleSelect ? true : false}
    onDelete={handleDelete}
    onClick={toggleSelect}
    name={name || 'chip'}
    hovercolor={props.hovercolor}
    iconhovercolor={props.iconhovercolor}
    {...props}
  />
);

const StyledChip = styled(MaterialChip)`
  display: inline-flex;
  position: relative;
  background-color: ${props =>
    props.bgcolor
      ? props.bgcolor
      : props.selected
      ? colors.secondary400
      : colors.primary200};
  color: ${props => (props.selected ? '#fff' : colors.primary800)};
  min-width: ${props => (props.small ? 'auto' : '50px')};
  max-width: 400px;
  height: ${props => (props.small ? '25px' : undefined)};

  &:hover {
    background-color: ${props =>
      props.hovercolor
        ? props.hovercolor
        : props.selected
        ? colors.secondary300
        : colors.primary400};
  }

  &:focus {
    background-color: ${props =>
      props.selected
        ? props.bgcolor
          ? props.bgcolor
          : colors.secondary400
        : colors.primary200};
    ${isMobile()
      ? ''
      : `&:hover {
      background-color: ${props =>
        props.selected ? colors.secondary300 : colors.primary400};
    }`}
  }

  & .MuiChip-deleteIcon {
    color: ${props =>
      props.selected
        ? props.iconcolor
          ? props.iconcolor
          : colors.secondary600
        : colors.primary800};
    height: ${props => (props.small ? '15px' : '28px')};
    width: ${props => (props.small ? '15px' : '28px')};
    margin: ${props => (props.small ? '4px 4px 4px 0' : '0 4px 0 0')};
  }

  & .MuiChip-deleteIcon:hover {
    cursor: pointer;
    color: ${props =>
      props.iconhovercolor ? props.iconhovercolor : colors.primary600};
  }

  & .MuiChip-label {
    font-size: ${props => (props.small ? '13px' : '15px')};
    font-family: 'Work Sans';
    padding: ${props => (props.small ? '4px 5px 4px 7px' : '0 14px')};
  }

  &:-moz-focusring {
    outline: none;
  }

  &:disabled {
    background-color: ${colors.primary100};
    color: ${colors.primary400};

    & .MuiChip-deleteIcon {
      color: ${colors.primary300};
    }
  }
`;

export default Chip;
