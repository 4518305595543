import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { DragDropContext } from 'react-beautiful-dnd';
import { cloneDeep } from 'lodash';
import {
  isToday,
  format,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
} from 'date-fns';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import Button from '../elements/Button';
import { formatCalories } from '../helpers/nutrition';
import { formatDate, isPastDate } from '../helpers/date';
import Dropdown from '../elements/Dropdown';
import Workout from './Workout';
import MealList from './MealList';
import ModalFood from './ModalFood';
import ModalRecipe from './ModalRecipe';
import ModalWorkout from './ModalWorkout';
import ModalActivity from './ModalActivity';
import ModalContainer from '../sharedModals/ModalContainer';
import useModal from '../hooks/useModal';
import { updateUser } from '../store/actions/general';
import { trackEvent } from '../integrations/analytics';
import {
  updateUserMealPlan,
  swapMealPlanItem,
  getUserMealPlanByDate,
  setMealPlanRecipeProperty,
  setMealPlanFoodProperty,
  addFoodToMealPlan,
  addRecipeToMealPlan,
} from '../store/actions/mealplan';
import {
  getUserFitnessPlanByDate,
  updateUserFitnessPlan,
  setFitnessPlanActivityProperty,
  setFitnessPlanWorkoutProperty,
  addWorkoutToFitnessPlan,
  addActivityToFitnessPlan,
} from '../store/actions/fitnessplan';

import { toggleAllComplete } from '../services/api/workout';
import ErrorBoundary from '../services/ErrorBoundary';
import { IconButton } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const UserPlanner = ({ mealPlan, fitnessPlan, error, ...props }) => {
  const [localFitnessPlan, setLocalFitnessPlan] = useState(fitnessPlan);
  const [localMealPlan, setLocalMealPlan] = useState(mealPlan);
  const [modalOpen, setModalOpen] = useState(false);
  const [item, setItem] = useState(null);
  const [focusedIndex, setFocusedIndex] = useState(1);
  const { open, launchModal, closeModal, context } = useModal(false);

  // This is necessary because drag and drop requires onDropEnd to be synchronous
  useEffect(() => {
    setLocalMealPlan(mealPlan);
  }, [mealPlan]);

  useEffect(() => {
    setLocalFitnessPlan(fitnessPlan);
  }, [fitnessPlan]);

  const openModal = item => {
    setItem(item);
    setModalOpen(true);
  };

  const closeModalItem = () => {
    setModalOpen(false);
    setItem(null);
  };

  // TODO: Post-Beta feature
  const filters = ['View: All', 'View: Workout'];
  const today = new Date();
  const days = [
    today.getTime() - 7 * 24 * 60 * 60 * 1000, // the day before one week from today
    today,
    today.getTime() + 7 * 24 * 60 * 60 * 1000, // the day after one week from today
  ];
  const currentWeekDays = eachDayOfInterval({
    start: startOfWeek(days[focusedIndex], { weekStartsOn: 1 }),
    end: endOfWeek(days[focusedIndex], { weekStartsOn: 1 }),
  });

  // Load current meal and fitness plans and set in Redux
  useEffect(() => {
    props.getUserFitnessPlanByDate(props.userId, today);
    props.getUserMealPlanByDate(props.userId, today);
  }, [props.userId]);

  // If plans change, need to update current item
  // this ensures we capture changes to items like "logged" or "eaten"
  useEffect(() => {
    let newItem;
    if (item && item.food) {
      newItem = mealPlan.foods.find(food => food._id === item._id);
      setItem(newItem);
    } else if (item && item.recipe) {
      newItem = mealPlan.recipes.find(recipe => recipe._id === item._id);
      setItem(newItem);
    }
  }, [mealPlan]);

  useEffect(() => {
    let newItem;
    if (item && item.workout) {
      newItem = fitnessPlan.workouts.find(workout => workout._id === item._id);
      setItem(newItem);
    } else if (item && item.activity) {
      newItem = fitnessPlan.activities.find(
        activity => activity._id === item._id
      );
      setItem(newItem);
    }
  }, [fitnessPlan]);

  const toggleDone = async () => {
    try {
      if (item.workout) {
        const params = {
          allComplete: item.allComplete,
        };

        await toggleAllComplete(item.workout._id, params);
        trackEvent('Logged Workout All Complete (Planner)', {
          workout: item,
          logged: !item.allComplete,
        });
        props.setFitnessPlanWorkoutProperty(
          fitnessPlan,
          item,
          'allComplete',
          !item.allComplete
        );
      } else {
        trackEvent('Logged Activity (Planner)', {
          activity: item,
          logged: !item.logged,
        });
        props.setFitnessPlanActivityProperty(
          fitnessPlan,
          item,
          'logged',
          !item.logged,
          true
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleEaten = async () => {
    if (item.recipe) {
      trackEvent('Logged Recipe (Planner)', {
        recipe: item,
        eaten: !item.eaten,
      });
      props.setMealPlanRecipeProperty(mealPlan, item, 'eaten', !item.eaten);
    } else {
      trackEvent('Logged Food (Planner)', { food: item, eaten: !item.eaten });
      props.setMealPlanFoodProperty(mealPlan, item, 'eaten', !item.eaten);
    }
  };

  const toggleFavorite = () => {
    let userData = {};
    if (item.recipe) {
      let newRecipes = [...props.favoriteRecipes];

      // In most cases the recipe ID to add to the favorite is going to be the recipe admin ID
      // But sometimes, there won't be any (if it's a custom recipe for example)
      const favoriteRecipe = item.recipe.recipeAdmin
        ? item.recipe.recipeAdmin
        : item.recipe._id;

      if (props.favoriteRecipes.includes(favoriteRecipe)) {
        // The recipe is already present, we remove it.
        trackEvent('Unfavorite Recipe (Planner)', {
          recipeId: favoriteRecipe,
          recipeName: item.recipe.name,
        });
        newRecipes.splice(newRecipes.indexOf(favoriteRecipe), 1);
      } else {
        // The recipe is not present we add it to the list.
        trackEvent('Favorite Recipe (Planner)', {
          recipeId: favoriteRecipe,
          recipeName: item.recipe.name,
        });
        newRecipes.push(favoriteRecipe);
      }

      userData = {
        id: props.userId,
        favoriteRecipes: newRecipes,
      };
      props.updateUser(userData);
    } else {
      let newFoods = [...props.favoriteFoods];

      // In most cases the food ID to add to the favorite is going to be the food admin ID
      // But sometimes, there won't be any (if it's a custom food for example)
      const favoriteFood = item.food.foodAdmin
        ? item.food.foodAdmin
        : item.food._id;

      if (props.favoriteFoods.includes(favoriteFood)) {
        // The food is already present, we remove it.
        trackEvent('Unfavorite Food (Planner)', {
          foodId: favoriteFood,
          foodName: item.food.verboseName,
        });
        newFoods.splice(newFoods.indexOf(favoriteFood), 1);
      } else {
        // The food is not present we add it to the list.
        trackEvent('Favorite Food (Planner)', {
          foodId: favoriteFood,
          foodName: item.food.verboseName,
        });
        newFoods.push(favoriteFood);
      }

      userData = {
        id: props.userId,
        favoriteFoods: newFoods,
      };
      props.updateUser(userData);
    }
  };

  const isFavorite = () => {
    if (item.recipe) {
      const favoriteRecipe = item.recipe.recipeAdmin
        ? item.recipe.recipeAdmin
        : item.recipe._id;
      return props.favoriteRecipes.includes(favoriteRecipe);
    } else {
      const favoriteFood = item.food.foodAdmin
        ? item.food.foodAdmin
        : item.food._id;
      return props.favoriteFoods.includes(favoriteFood);
    }
  };

  const swapItemsHandler = async params => {
    const newItem = params.recipe ? params.recipe : params.food;
    const quantity = params.quantity ? params.quantity : 1;
    const destId = item._id;
    closeModalItem();
    trackEvent('Swapped Item (Planner)', { oldItem: item, newItem: newItem });
    props.swapMealPlanItem(mealPlan.id, newItem, destId, quantity);
  };

  const totalCalories = day => {
    const recipeCalories = getRecipes(day).reduce((total, recipe) => {
      return total + formatCalories(recipe.recipe?.nutrients?.calories);
    }, 0);
    const foodCalories = getFoods(day).reduce((total, food) => {
      const calories = formatCalories(
        food.quantity * food.food.nutrientsPerUnit.calories
      );
      return total + calories;
    }, 0);
    return recipeCalories + foodCalories;
  };

  const totalWorkoutCalories = day => {
    const workoutCalories = getWorkouts(day).reduce((total, workout) => {
      return total + workout.workout.caloriesBurned;
    }, 0);
    const activityCalories = getActivities(day).reduce((total, activity) => {
      return total + activity.activity.caloriesBurned;
    }, 0);
    return workoutCalories + activityCalories;
  };

  const getRecipes = day =>
    localMealPlan.recipes.filter(r => {
      return formatDate(r.date) === formatDate(day);
    });

  const getFoods = day =>
    localMealPlan.foods.filter(f => formatDate(f.date) === formatDate(day));

  const getWorkouts = day =>
    localFitnessPlan.workouts.filter(
      w => formatDate(w.date) === formatDate(day)
    );

  const getActivities = day =>
    localFitnessPlan.activities.filter(
      a => formatDate(a.date) === formatDate(day)
    );

  const handleDragEnd = result => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }
    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    if (result.type === 'MEAL') {
      handleDragEndMeal(result);
    } else if (result.type === 'FITNESS') {
      handleDragEndFitness(result);
    }
  };

  const handleDragEndFitness = result => {
    const source = result.source;
    const destination = result.destination;

    const [sourceType, sourceId] = result.draggableId.split('|');
    const sourceDate = source.droppableId;
    const destDate = destination.droppableId;

    let item;
    if (sourceType === 'workout') {
      item = getWorkouts(sourceDate).filter(r => r._id === sourceId)[0];
    } else {
      item = getActivities(sourceDate).filter(f => f._id === sourceId)[0];
    }

    // update item
    item.date = destDate;
    item.index = destination.index;

    // re calculate new indexes at destination
    const destItems = getWorkouts(destDate) // get workouts of dest
      .concat(getActivities(destDate)) // concat activities of dest
      .filter(i => i._id !== item._id) // filter out item
      .sort((a, b) => a.index - b.index); // sort by index prop
    destItems.splice(item.index, 0, item); // add new item at the dest index
    destItems.forEach((i, index) => (i.index = index)); // update index prop from array

    // re calculate new indexes at source
    getWorkouts(sourceDate) // get workouts of source
      .concat(getActivities(sourceDate)) // concat activities of source
      .sort((a, b) => a.index - b.index) // sort by index prop
      .forEach((i, index) => (i.index = index)); // update index prop from array

    // update user fitness plan
    const updatedFitnessPlan = cloneDeep(fitnessPlan);
    setLocalFitnessPlan(updatedFitnessPlan);
    props.updateUserFitnessPlan(fitnessPlan.id, updatedFitnessPlan);
  };

  const handleDragEndMeal = result => {
    const source = result.source;
    const destination = result.destination;

    const [sourceType, sourceId] = result.draggableId.split('|');
    const [sourceDate, sourceMealType] = source.droppableId.split('|');
    const [destDate, destMealType] = destination.droppableId.split('|');

    let item;
    if (sourceType === 'recipe') {
      item = getRecipes(sourceDate).filter(r => r._id === sourceId)[0];
    } else {
      item = getFoods(sourceDate).filter(f => f._id === sourceId)[0];
    }
    // update item
    item.mealType = destMealType;
    item.date = destDate;
    item.index = destination.index;
    // re calculate new indexes at destination
    const destItems = getRecipes(destDate) // get recipes of dest
      .concat(getFoods(destDate)) // concat foods of dest
      .filter(i => i.mealType === destMealType) // get only dest meal type
      .filter(i => i._id !== item._id) // filter out item
      .sort((a, b) => a.index - b.index); // sort by index prop
    destItems.splice(item.index, 0, item); // add new item at the dest index
    destItems.forEach((i, index) => (i.index = index)); // update index prop from array
    // re calculate new indexes at source
    getRecipes(sourceDate) // get recipes of source
      .concat(getFoods(sourceDate)) // concat foods of source
      .filter(i => i.mealType === sourceMealType) // get only source meal type
      .sort((a, b) => a.index - b.index) // sort by index prop
      .forEach((i, index) => (i.index = index)); // update index prop from array
    // update user meal plan
    const updatedMealPlan = cloneDeep(mealPlan);
    setLocalMealPlan(updatedMealPlan);
    props.updateUserMealPlan(mealPlan.id, updatedMealPlan);
  };

  useEffect(() => {
    // Designed to handle case where updateUserMealPlan() fails during drag and drop
    // Because Redux thunks cannot be chained with a then, we do not have a way to reset the local
    // state in the event of an error, so to the user it will appear their plan updated correctly
    // but on refresh (or any other change), the chip will go back to its previous position.
    // This ensures if we throw an error during update, we realign the local state with the Redux state
    if (error) {
      setLocalMealPlan(mealPlan);
      setLocalFitnessPlan(fitnessPlan);
    }
  }, [error]);

  const adjustQuantity = async value => {
    if (item.food) {
      // No quantity adjust for recipes
      props.setMealPlanFoodProperty(mealPlan, item, 'quantity', value, true);
    }
  };

  async function addWorkoutToUserFitnessPlan(params) {
    await props.addWorkoutToFitnessPlan(fitnessPlan._id, params);
  }

  async function addActivityToUserFitnessPlan(params) {
    await props.addActivityToFitnessPlan(fitnessPlan._id, params);
  }

  async function addFoodToUserMealPlan(params) {
    await props.addFoodToMealPlan(mealPlan._id, params);
  }

  async function addRecipeToUserMealPlan(params) {
    await props.addRecipeToMealPlan(mealPlan._id, params);
  }

  const removeItem = async () => {
    if (item.recipe || item.food) {
      await removeItemMealPlan();
    } else {
      await removeItemFitnessPlan();
    }
  };

  const removeItemMealPlan = async () => {
    const updatedMealPlan = cloneDeep(mealPlan);
    if (item.recipe) {
      trackEvent('Removed Recipe (Planner)', {
        recipe: item,
      });
      updatedMealPlan.recipes = updatedMealPlan.recipes.filter(
        recipe => recipe._id !== item._id
      );
    } else {
      trackEvent('Removed Food (Planner)', {
        food: item,
      });
      updatedMealPlan.foods = updatedMealPlan.foods.filter(
        food => food._id !== item._id
      );
    }
    await props.updateUserMealPlan(mealPlan._id, updatedMealPlan);
    closeModalItem();
  };

  const removeItemFitnessPlan = async () => {
    const updatedFitnessPlan = cloneDeep(fitnessPlan);
    if (item.workout) {
      trackEvent('Removed Workout (Planner)', {
        workout: item,
      });
      updatedFitnessPlan.workouts = updatedFitnessPlan.workouts.filter(
        i => i._id !== item._id
      );
    } else {
      trackEvent('Removed Activity (Planner)', {
        activity: item,
      });
      updatedFitnessPlan.activities = updatedFitnessPlan.activities.filter(
        i => i._id !== item._id
      );
    }
    await props.updateUserFitnessPlan(fitnessPlan._id, updatedFitnessPlan);
    closeModalItem();
  };

  const [date, setDate] = useState(new Date());

  const showPrevWeek = () => {
    const prevWeekDate = new Date(date);
    prevWeekDate.setDate(prevWeekDate.getDate() - 7);
    setDate(prevWeekDate);
    props.getUserFitnessPlanByDate(props.userId, prevWeekDate);
    props.getUserMealPlanByDate(props.userId, prevWeekDate);
    setFocusedIndex(focusedIndex - 1);
  };

  const showNextWeek = () => {
    const nextWeekDate = new Date(date);
    nextWeekDate.setDate(nextWeekDate.getDate() + 7);
    setDate(nextWeekDate);
    props.getUserFitnessPlanByDate(props.userId, nextWeekDate);
    props.getUserMealPlanByDate(props.userId, nextWeekDate);
    setFocusedIndex(focusedIndex + 1);
  };

  // need to look at the day with the maximum number of items to calculate the height of the workout section
  let workoutHeight = 154;
  if (fitnessPlan) {
    const lengths = currentWeekDays.map(
      day => getWorkouts(day).length + getActivities(day).length
    );
    const numItems = Math.max(...lengths);
    workoutHeight = 40 * numItems + 17 + 27; // chip height + padding height + heading height
  }

  const daysHeader = currentWeekDays.map((day, index) => (
    <Day key={formatDate(day, false)} flexDirection="column">
      {index === 0 && focusedIndex > 0 && (
        <WeekButton left={0}>
          <IconButton data-test="planner-prev-week" onClick={showPrevWeek}>
            <NavigateBeforeIcon />
          </IconButton>
        </WeekButton>
      )}
      <WeekDay data-test={`planner-${format(day, 'EEE')}-dayheader`}>
        {format(day, 'EEE')}
      </WeekDay>
      <DayNumber
        isToday={isToday(day)}
        data-test={`planner-${format(day, 'EEE')}-dayNum`}
      >
        {format(day, 'dd')}
      </DayNumber>
      {index === 6 && focusedIndex < 2 && (
        <WeekButton right={0}>
          <IconButton data-test="planner-next-week" onClick={showNextWeek}>
            <NavigateNextIcon />
          </IconButton>
        </WeekButton>
      )}
    </Day>
  ));

  const daysPlan = currentWeekDays.map(day => (
    <DayPlan
      disabled={isPastDate(day)}
      key={day.toISOString()}
      flexDirection="column"
      data-test="planner-day"
    >
      <Workout
        date={formatDate(day, false)}
        workouts={getWorkouts(day)}
        activities={getActivities(day)}
        workoutHeight={workoutHeight}
        openModal={openModal}
        addWorkout={addWorkoutToUserFitnessPlan}
        addActivity={addActivityToUserFitnessPlan}
        editable={!isPastDate(day)}
      />

      <MealList
        date={formatDate(day, false)}
        recipes={getRecipes(day)}
        foods={getFoods(day)}
        openModal={openModal}
        workoutHeight={workoutHeight}
        editable={!isPastDate(day)}
        addRecipe={addRecipeToUserMealPlan}
        addFood={addFoodToUserMealPlan}
      />

      <TotalContainer flexDirection="column">
        <TotalCaloriesContainer justify="center" alignItems="center">
          <TotalCaloriesLabel>Meal Calories </TotalCaloriesLabel>
          <TotalCaloriesNumber
            data-test={`planner-${day.toLocaleDateString('en-US', {
              weekday: 'long',
            })}-totalCalories`}
          >
            {totalCalories(day)}
          </TotalCaloriesNumber>
        </TotalCaloriesContainer>
        <TotalCaloriesContainer justify="center" alignItems="center">
          <TotalCaloriesLabel>Workout Calories </TotalCaloriesLabel>
          <TotalCaloriesWorkoutNumber
            data-test={`planner-${day.toLocaleDateString('en-US', {
              weekday: 'long',
            })}-totalWorkoutCalories`}
          >
            {totalWorkoutCalories(day)}
          </TotalCaloriesWorkoutNumber>
        </TotalCaloriesContainer>
      </TotalContainer>
    </DayPlan>
  ));

  return (
    <ErrorBoundary>
      <PageContainer flexDirection="column">
        <Header alignItems="center" justify="space-between">
          <Button
            buttonText="+ Add"
            pink="true"
            onClick={launchModal}
            data-test="planner-add-general"
          />
          <HeaderTitle data-test="planner-month">
            {format(days[focusedIndex], 'MMMM yyyy')}
          </HeaderTitle>
          {/* TODO: Post-Beta feature */}
          <Dropdown
            width="160px"
            options={filters}
            defaultValue={filters[0]}
            style={{ visibility: 'hidden' }}
          />
        </Header>

        <FlexContainer>
          <LeftBar>
            <WorkoutBar workoutHeight={workoutHeight} />
            <MealsBar workoutHeight={workoutHeight} />
            <CaloriesBar workoutHeight={workoutHeight} />
          </LeftBar>
          <Planner flexDirection="column">
            <FlexContainer>{daysHeader}</FlexContainer>
            <DragDropContext onDragEnd={handleDragEnd}>
              <FlexContainer>{daysPlan}</FlexContainer>
            </DragDropContext>
          </Planner>
          <RightBar />
        </FlexContainer>

        {item && item.recipe && (
          <ModalRecipe
            toggleFavorite={toggleFavorite}
            isFavorite={isFavorite}
            mealPlan={mealPlan}
            open={modalOpen}
            handleClose={closeModalItem}
            item={item}
            toggleEaten={toggleEaten}
            swap={launchModal}
            remove={removeItem}
          />
        )}

        {item && item.food && (
          <ModalFood
            mealPlanId={mealPlan._id}
            open={modalOpen}
            handleClose={closeModalItem}
            item={item}
            toggleEaten={toggleEaten}
            toggleFavorite={toggleFavorite}
            isFavorite={isFavorite}
            swap={launchModal}
            remove={removeItem}
            adjustQuantity={adjustQuantity}
          />
        )}

        {item && item.workout && (
          <ModalWorkout
            open={modalOpen}
            handleClose={closeModalItem}
            item={item}
            toggleDone={toggleDone}
            remove={removeItem}
          />
        )}

        {item && item.activity && (
          <ModalActivity
            open={modalOpen}
            handleClose={closeModalItem}
            item={item}
            toggleDone={toggleDone}
            remove={removeItem}
          />
        )}

        <ModalContainer
          addRecipe={
            context.type === 'swap' ? swapItemsHandler : addRecipeToUserMealPlan
          }
          addFood={
            context.type === 'swap' ? swapItemsHandler : addFoodToUserMealPlan
          }
          addWorkout={addWorkoutToUserFitnessPlan}
          addActivity={addActivityToUserFitnessPlan}
          open={open}
          context={context}
          handleClose={closeModal}
          initialScreen={context.type === 'swap' ? 'Add Meal' : 'Generic Modal'}
        />
      </PageContainer>
    </ErrorBoundary>
  );
};

const PageContainer = styled(FlexContainer)`
  padding: 0 55px;
  position: relative;
  padding-bottom: 100px;
`;

const Header = styled(FlexContainer)`
  height: 77px;
`;

const LeftBar = styled.div`
  flex-basis: 9px;
  min-width: 9px;
  max-width: 9px;
  margin-top: 76px;
  border-right: 1px solid ${colors.primary500};
`;

const RightBar = styled.div`
  flex-basis: 1px;
  margin-top: 76px;
  background-color: ${colors.primary500};
`;

const WorkoutBar = styled.div`
  height: ${props => props.workoutHeight}px;
  background-color: ${colors.hlitetwo400};
`;

const MealsBar = styled.div`
  min-height: calc(100% - ${props => props.workoutHeight}px - 83px);
  background-color: ${colors.secondary600};
`;

const CaloriesBar = styled.div`
  height: 83px;
  background-color: ${colors.primary600};
`;

const Planner = styled(FlexContainer)`
  flex-grow: 1;
  overflow: auto;
  text-align: center;
`;

const HeaderTitle = styled.h4`
  color: ${colors.primary700};
`;

const Day = styled(FlexContainer)`
  position: relative;
  flex: 1 0 211px;
`;

const DayPlan = styled(Day)`
  border: 1px solid ${colors.primary500};
  border-right: 0;
  background-color: #fcfcfc;
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  flex: 1 0 210px;

  &:first-child {
    border-left-color: transparent;
  }
`;

const WeekDay = styled.p`
  font-size: 13px;
  color: ${colors.primary700};
  text-transform: uppercase;
`;
const DayNumber = styled.h2`
  margin: auto;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: ${props => (props.isToday ? colors.primary500 : 'white')};
  color: ${props => (props.isToday ? 'white' : colors.primary600)};
  border-radius: 100%;
  margin-bottom: 12px;
`;

const TotalContainer = styled(FlexContainer)`
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
`;

const TotalCaloriesContainer = styled(FlexContainer)`
  border-top: 1px solid ${colors.primary500};
  padding: 5px 10px;
  height: 30px;
`;

const TotalCaloriesLabel = styled.p`
  color: ${colors.primary500};
`;

const TotalCaloriesNumber = styled.h3`
  color: ${colors.secondary500};
  flex-grow: 1;
  text-align: right;
`;

const TotalCaloriesWorkoutNumber = styled(TotalCaloriesNumber)`
  color: ${colors.hlitetwo400};
`;

const WeekButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${props => props.left};
  right: ${props => props.right};
`;

function mapStateToProps(state) {
  const { errors, currentUser, selectedMealPlan, selectedFitnessPlan } = state;
  return {
    error: errors,
    userId: currentUser.user.id,
    favoriteRecipes: currentUser.user.favoriteRecipes,
    favoriteFoods: currentUser.user.favoriteFoods,
    mealPlan: selectedMealPlan,
    fitnessPlan: selectedFitnessPlan,
  };
}

export default connect(
  mapStateToProps,
  {
    updateUser,
    swapMealPlanItem,
    updateUserMealPlan,
    getUserMealPlanByDate,
    setMealPlanRecipeProperty,
    setMealPlanFoodProperty,
    addFoodToMealPlan,
    addRecipeToMealPlan,
    getUserFitnessPlanByDate,
    updateUserFitnessPlan,
    setFitnessPlanActivityProperty,
    setFitnessPlanWorkoutProperty,
    addWorkoutToFitnessPlan,
    addActivityToFitnessPlan,
  }
)(UserPlanner);
