import React, { useRef } from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from './FlexContainer';
import moment from 'moment';
import Select from 'react-select';
import CalendarTodayTwoToneIcon from '@material-ui/icons/CalendarTodayTwoTone';

const MobileDateInput = ({
  name,
  label,
  value,
  handleValueChanged,
  ...props
}) => {
  const inputField = useRef(null);
  const handleDateChange = e => {
    e.stopPropagation();
    const date = `${e.target.value}T00:00:00`;
    handleValueChanged(
      name,
      new Date(date).toLocaleDateString('en-US'),
      'date'
    );
  };
  const handleClick = e => {
    e.stopPropagation();
    inputField.current.focus();
  };
  return (
    <div style={{ width: '100%' }} onClick={handleClick}>
      <Container flexDirection="column" onClick={handleClick}>
        {label && <Label>{label}</Label>}
        <StyledMobileSelect
          value={moment(value).format('MM / DD / YYYY')}
          options={[]}
          classNamePrefix="react-dropdown"
          className="react-dropdown-container"
          placeholder={moment(value).format('MM / DD / YYYY')}
          small={props.small}
          isPlaceholder={true}
          isDisabled={true}
          openMenuOnClick={false}
          components={{
            DropdownIndicator: () => (
              <CalendarTodayTwoToneIcon
                style={{ color: colors.secondary600 }}
              />
            ),
            IndicatorSeparator: null,
          }}
        />
        <StyledInput
          type="date"
          name="date"
          ref={inputField}
          onChange={handleDateChange}
          value={moment(value).format('YYYY-MM-DD')}
          data-test={props['data-test']}
        />
      </Container>
    </div>
  );
};

const Container = styled(FlexContainer)`
  width: ${props => (props.width ? props.width : props.small ? '50%' : '100%')};
  height: ${props => (props.height ? props.height : '100%')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0px')};
  margin-bottom: ${props => (props.marginBottom ? props.marginLBottom : '0px')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0px')};
  position: relative;
`;

const StyledMobileSelect = styled(Select)`
  input::-webkit-contacts-auto-fill-button,
  input::-webkit-credentials-auto-fill-button {
    display: none !important;
  }
  overflow: visible;

  & .react-dropdown__control {
    border: 1px solid ${colors.primary400};
    border-radius: 8px;
    height: ${props => (props.small ? '29px' : '32px')};
    width: 100%;
    box-shadow: none;
    background-color: #fff;
    min-height: ${props => (props.height ? '20px' : '38px')};
  }

  & .react-dropdown__indicatorContainer {
    &:hover {
      cursor: pointer;
    }
  }

  & .react-dropdown__placeholder {
    font-weight: 400;
    font-size: ${props => (props.small ? '15px' : '17px')};
    line-height: ${props => (props.small ? '18px' : '20px')};
    color: ${colors.primary800};
  }

  & .react-dropdown__value-container {
    padding: 0px 12px;
  }

  & .react-dropdown__indicators {
    padding-right: 10px;
  }

  & .react-dropdown__value {
    font-weight: 400;
    font-size: ${props => (props.small ? '15px' : '17px')};
    line-height: ${props => (props.small ? '18px' : '20px')};
    color: ${colors.primary800};
  }

  & .react-dropdown__menu {
    border-radius: 2px;
    border: 1px solid ${colors.primary200};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
`;

const Label = styled.label`
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: ${colors.primary600};
  margin-bottom: 8px;
`;

const StyledInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 5;
`;

export default MobileDateInput;
