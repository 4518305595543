import moment from 'moment';
import { formatDate } from '../../helpers/date';
import { apiCall } from '../../services/api/api';
import {
  SET_ACTIVE_MEAL_PLAN,
  SET_SELECTED_MEAL_PLAN,
  SET_DEFAULT_PLACEHOLDER,
} from '../actionTypes';
import { handleError, removeError } from './general';
import { getShoppingLists } from './shoppinglist';
import { cloneDeep } from 'lodash';

// Meal Plan Actions
export function setActiveMealPlan(mealPlan) {
  return {
    type: SET_ACTIVE_MEAL_PLAN,
    mealPlan,
  };
}

export function setSelectedMealPlan(mealPlan) {
  return {
    type: SET_SELECTED_MEAL_PLAN,
    mealPlan,
  };
}

export function setDefaultPlaceholder(placeholder) {
  return {
    type: SET_DEFAULT_PLACEHOLDER,
    placeholder,
  };
}

const mealPlanPopulate = [
  {
    path: 'recipes.recipe',
    populate: { path: 'foods.food' },
  },
  {
    path: 'foods.food',
  },
];

export function getActiveUserMealPlan(userId) {
  const date = new Date();
  const params = {
    query: {
      user: userId,
      startDate: formatDate(moment(date).startOf('isoWeek')),
      endDate: formatDate(moment(date).endOf('isoWeek')),
    },
    populate: mealPlanPopulate,
  };
  return dispatch => {
    return apiCall('get', `/mealplanuser`, params)
      .then(result => {
        const mealPlan = result[0];
        if (mealPlan) {
          // the user has a meal plan already assigned for the given week
          dispatch(setActiveMealPlan(mealPlan));
          dispatch(removeError('mealplan'));
        } else {
          // the user does not have an assigned plan and needs a blank plan created
          dispatch(addUserMealPlan(userId, date));
        }
      })
      .catch(err => {
        handleError(dispatch, err, 'mealplan');
      });
  };
}

export function getUserMealPlanByDate(userId, date = new Date()) {
  const params = {
    query: {
      user: userId,
      startDate: formatDate(moment(date).startOf('isoWeek')),
      endDate: formatDate(moment(date).endOf('isoWeek')),
    },
    populate: mealPlanPopulate,
  };
  return dispatch => {
    return apiCall('get', `/mealplanuser`, params)
      .then(result => {
        const mealPlan = result[0];
        if (mealPlan) {
          // the user has a meal plan already assigned for the given week
          dispatch(setSelectedMealPlan(mealPlan));
          dispatch(removeError('mealplan'));
        } else {
          // the user does not have an assigned plan and needs a blank plan created
          dispatch(addUserMealPlan(userId, date));
        }
        dispatch(removeError('mealplan'));
      })
      .catch(err => {
        handleError(dispatch, err, 'mealplan');
      });
  };
}

export function getUserMealPlanById(id, params = {}) {
  params.populate = mealPlanPopulate;
  return dispatch => {
    return apiCall('get', `/mealplanuser/${id}`, params)
      .then(mealPlan => {
        dispatch(setSelectedMealPlan(mealPlan));
        dispatch(removeError('mealplan'));
      })
      .catch(err => {
        handleError(dispatch, err, 'mealplan');
      });
  };
}

export function addUserMealPlan(userId, date) {
  const payload = {
    startDate: formatDate(
      moment(date)
        .utc()
        .startOf('isoWeek')
    ),
    endDate: formatDate(
      moment(date)
        .utc()
        .endOf('isoWeek')
    ),
    planLength: 7,
    type: 'Weight Loss',
    recommendedCalories: 2000,
  };
  return dispatch => {
    return apiCall('post', `/mealplanuser`, payload)
      .then(mealPlan => {
        dispatch(setSelectedMealPlan(mealPlan));
        if (
          mealPlan.startDate ===
          formatDate(moment(new Date()).startOf('isoWeek'))
        ) {
          dispatch(setActiveMealPlan(mealPlan));
        }
        dispatch(removeError('mealplan'));
      })
      .catch(err => {
        handleError(dispatch, err, 'mealplan');
        throw err;
      });
  };
}

export function addRecipeToMealPlan(mealPlanId, params) {
  params.populate = mealPlanPopulate;
  return async dispatch => {
    return await apiCall(
      'put',
      `/mealplanuser/add_recipe/${mealPlanId}`,
      params
    )
      .then(mealPlan => {
        dispatch(setSelectedMealPlan(mealPlan));
        if (
          mealPlan.startDate ===
          formatDate(moment(new Date()).startOf('isoWeek'))
        ) {
          dispatch(setActiveMealPlan(mealPlan));
        }
        dispatch(getShoppingLists());
        dispatch(removeError('mealplan'));
      })
      .catch(err => {
        handleError(dispatch, err, 'mealplan');
      });
  };
}

export function addMultipleRecipesToMealPlan(mealPlanId, params) {
  params.populate = mealPlanPopulate;
  return async dispatch => {
    return await apiCall('put', `/mealplanuser/add_recipes/${mealPlanId}`, {
      recipes: params,
    })
      .then(mealPlan => {
        dispatch(setSelectedMealPlan(mealPlan));
        if (
          mealPlan.startDate ===
          formatDate(moment(new Date()).startOf('isoWeek'))
        ) {
          dispatch(setActiveMealPlan(mealPlan));
        }
        dispatch(getShoppingLists());
        dispatch(removeError('mealplan'));
      })
      .catch(err => {
        handleError(dispatch, err, 'mealplan');
      });
  };
}

export function addFoodToMealPlan(mealPlanId, params) {
  params.populate = mealPlanPopulate;
  return dispatch => {
    return apiCall('put', `/mealplanuser/add_food/${mealPlanId}`, params)
      .then(mealPlan => {
        dispatch(setSelectedMealPlan(mealPlan));
        if (
          mealPlan.startDate ===
          formatDate(moment(new Date()).startOf('isoWeek'))
        ) {
          dispatch(setActiveMealPlan(mealPlan));
        }
        dispatch(getShoppingLists());
        dispatch(removeError('mealplan'));
      })
      .catch(err => {
        handleError(dispatch, err, 'mealplan');
      });
  };
}

export function updateUserMealPlan(mealPlanId, params) {
  params.populate = mealPlanPopulate;
  return dispatch => {
    return apiCall('put', `/mealplanuser/${mealPlanId}`, params)
      .then(mealPlan => {
        dispatch(setSelectedMealPlan(mealPlan));
        if (
          mealPlan.startDate ===
          formatDate(moment(new Date()).startOf('isoWeek'))
        ) {
          dispatch(setActiveMealPlan(mealPlan));
        }
        dispatch(getShoppingLists());
        dispatch(removeError('mealplan'));
      })
      .catch(err => {
        // Don't show an error if user tries to decrement water value below 0
        if (!err.message.includes('Validation failed: water')) {
          handleError(dispatch, err, 'mealplan');
        }
      });
  };
}

export function swapMealPlanItem(
  mealPlanId,
  itemSourceId,
  itemDestId,
  quantity
) {
  let params = {};
  console.info(`Swapping mealPlan item ${itemSourceId} for item ${itemDestId}`);
  params.itemSource = itemSourceId;
  params.itemDestination = itemDestId;
  params.quantity = quantity;
  params.populate = mealPlanPopulate;
  return dispatch => {
    return apiCall('put', `/mealplanuser/swap_item/${mealPlanId}`, params)
      .then(mealPlan => {
        dispatch(setSelectedMealPlan(mealPlan));
        if (
          mealPlan.startDate ===
          formatDate(moment(new Date()).startOf('isoWeek'))
        ) {
          dispatch(setActiveMealPlan(mealPlan));
        }
        dispatch(getShoppingLists());
        dispatch(removeError('mealplan'));
      })
      .catch(err => {
        handleError(dispatch, err, 'mealplan');
      });
  };
}

export function setMealPlanRecipeProperty(
  mealPlan,
  recipe,
  property,
  value,
  populate = false
) {
  console.info(
    `Setting recipe property ${property} to ${value} for recipe: `,
    recipe
  );

  // Find the recipe in the mealPlan
  mealPlan.recipes.some(currentRecipe => {
    if (currentRecipe._id === recipe._id) {
      switch (property) {
        case 'mealType':
          currentRecipe.mealType = value;
          break;
        case 'eaten':
          currentRecipe.eaten = value ? new Date() : null;
          break;
        case 'yield':
          currentRecipe.yield = value;
          break;
        default:
          throw new Error(`Property ${property} not supported.`);
      }
      return true;
    }
    return false;
  });

  if (populate) {
    mealPlan.populate = mealPlanPopulate;
  }
  return updateUserMealPlan(mealPlan._id, mealPlan);
}

export function setMealPlanFoodProperty(
  mealPlan,
  food,
  property,
  value,
  populate = false
) {
  console.info(
    `Setting food property ${property} to ${value} for food: `,
    food
  );
  // Find the food in the mealPlan
  mealPlan.foods.some(currentFood => {
    if (currentFood._id === food._id) {
      switch (property) {
        case 'quantity':
          currentFood.quantity = value;
          break;
        case 'mealType':
          currentFood.mealType = value;
          break;
        case 'eaten':
          currentFood.eaten = value ? new Date() : null;
          break;
        default:
          throw new Error(`Property ${property} not supported.`);
      }
      return true;
    }
    return false;
  });

  if (populate) {
    mealPlan.populate = mealPlanPopulate;
  }
  return updateUserMealPlan(mealPlan._id, mealPlan);
}

export function setUserMealPlanAllEaten(mealPlan, dateValue, populate = false) {
  const dateSelected = moment(dateValue).format('YYYY-MM-DD');
  mealPlan.recipes.forEach(recipe => {
    const recipeDate = moment(recipe.date)
      .utc()
      .format('YYYY-MM-DD');
    if (dateSelected === recipeDate && !recipe.eaten) {
      recipe.eaten = new Date();
    }
  });
  mealPlan.foods.forEach(food => {
    const foodDate = moment(food.date)
      .utc()
      .format('YYYY-MM-DD');
    if (dateSelected === foodDate && !food.eaten) {
      food.eaten = new Date();
    }
  });

  mealPlan.populate = mealPlanPopulate;
  return updateUserMealPlan(mealPlan._id, mealPlan);
}

export function updatePlaceholderRecipe(
  mealPlan,
  recipe,
  updates,
  userId,
  populate = false
) {
  if (!recipe.recipe.placeholder || recipe.recipe.user !== userId) {
    throw new Error(`Only the user's own placeholder recipes can be edited.`);
  }

  const updatedRecipe = cloneDeep(recipe.recipe);
  for (const [key, value] of Object.entries(updates)) {
    console.debug(`${key}: ${value}`);
    if (key === 'name' && value) {
      updatedRecipe[key] = value;
    } else if (value) {
      updatedRecipe.nutrients[key] = value;
    }
  }

  return dispatch => {
    if (updates.image && !updates.imageUrl) {
      const recipeImage = new FormData();
      recipeImage.append('image', updates.image);
      return apiCall('post', '/users/upload', recipeImage).then(result => {
        updatedRecipe.imageUrl = result.file;
        return apiCall('put', `/recipeuser/${recipe.recipe._id}`, updatedRecipe)
          .then(recipe => {
            dispatch(getUserMealPlanById(mealPlan._id));
            dispatch(removeError('mealplan'));
          })
          .catch(err => {
            handleError(dispatch, err, 'mealplan');
          });
      });
    } else {
      return apiCall('put', `/recipeuser/${recipe.recipe._id}`, updatedRecipe)
        .then(recipe => {
          dispatch(getUserMealPlanById(mealPlan._id));
          dispatch(removeError('mealplan'));
        })
        .catch(err => {
          handleError(dispatch, err, 'mealplan');
        });
    }
  };
}

export function getDefaultPlaceholder() {
  return dispatch => {
    return apiCall('get', `/recipe`, { query: { placeholderDefault: true } })
      .then(recipe => {
        dispatch(setDefaultPlaceholder(recipe[0]));
        dispatch(removeError('mealplan'));
      })
      .catch(err => {
        handleError(dispatch, err, 'mealplan');
      });
  };
}
