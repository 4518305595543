import React from 'react';
import styled from 'styled-components';
import { isMobile } from '../helpers/utils';
import { screenSize } from '../styleConstants';
import { camelCase } from 'lodash';
import FlexContainer from '../elements/FlexContainer';
import { StepNumber, StepTitle } from './StepComponents';
import { eatingPrefsMap } from '../helpers/profile';
import DetailCard from '../profile/DetailCard';

const StepThree = ({ values, handleValueChanged }) => {
  const eatingPrefsCards = eatingPrefsMap.map(
    ({ label, value, iconSrc, extraInfo }) => (
      <DetailCard
        key={label}
        imgSrc={iconSrc}
        title={label}
        isSelected={values.foodPreferences.eatingPreference === value}
        name="foodPreferences.eatingPreference"
        handleSelect={() =>
          handleValueChanged(
            'foodPreferences.eatingPreference',
            value,
            'string'
          )
        }
        extraInfo={extraInfo}
        size={!isMobile() && 'large'}
        data-test={`prefs-eatingPreference-${camelCase(label)}`}
      />
    )
  );

  return (
    <FlexContainer
      flexDirection="column"
      alignItems="center"
      justify="space-between"
    >
      {!isMobile() && <StepNumber>3</StepNumber>}
      <StepTitle>What is your eating preference?</StepTitle>
      <Container wrap="wrap">{eatingPrefsCards}</Container>
    </FlexContainer>
  );
};

const Container = styled(FlexContainer)`
  width: 700px;
  gap: 15px;

  @media (max-width: ${screenSize.mobileL}) {
    width: unset;
    align-items: center;
    justify-content: center;
  }
`;

export default StepThree;
