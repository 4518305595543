import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import SectionItem from './SectionItem';
import { colors } from '../styleConstants';
import { Skeleton } from '@material-ui/lab';

const ExploreSection = ({
  sectionName,
  items = [],
  loading,
  handleClick,
  ...props
}) => {
  const sectionItems = items.map((item, index) => (
    <SectionItem
      key={index}
      itemName={item.name}
      itemImage={item.imageUrl}
      itemLink={item.link}
      handleClick={() => handleClick(item)}
    />
  ));

  const loadingItems = [{}, {}, {}].map((item, index) => {
    return (
      <Skeleton
        key={index}
        variant="circle"
        style={{ minWidth: 133, borderRadius: 6 }}
        width={133}
        height={140}
      />
    );
  });

  return (
    <ExploreSectionContainer
      flexDirection="column"
      data-test={props['data-test']}
    >
      <SectionTitleContainer justify="space-between" alignItems="center">
        <SectionTitle>{sectionName}</SectionTitle>
      </SectionTitleContainer>
      <SectionRow>{loading ? loadingItems : sectionItems}</SectionRow>
    </ExploreSectionContainer>
  );
};

const ExploreSectionContainer = styled(FlexContainer)`
  box-sizing: border-box;
  background: #fff;
  border: 1px solid ${colors.primary100};
  border-radius: 4px;
`;

const SectionTitleContainer = styled(FlexContainer)`
  background: ${colors.primary050};
  border-bottom: 1px solid ${colors.primary100};
  border-radius: 4px 4px 0px 0px;
  padding: 16px;
`;

const SectionTitle = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  color: ${colors.primary800};
`;

const SectionRow = styled(FlexContainer)`
  padding: 16px;
  gap: 16px;
  max-width: 100%;
  overflow-x: scroll;
`;

export default ExploreSection;
