// /* eslint no-extend-native: 0 */
import { isNumberBetween } from './utils';

export const determineRecipeVersion = (recCalories, meal) => {
  let version;
  if (meal) {
    switch (meal) {
      case 'Breakfast':
        if (recCalories <= 1700) {
          version = 'A';
        } else if (isNumberBetween(recCalories, 1700, 2300)) {
          version = 'B';
        } else {
          version = 'C';
        }
        break;
      case 'Lunch':
        if (recCalories <= 2100) {
          version = 'A';
        } else if (isNumberBetween(recCalories, 2100, 2700)) {
          version = 'B';
        } else {
          version = 'C';
        }
        break;
      case 'Dinner':
        if (recCalories <= 1900) {
          version = 'A';
        } else if (isNumberBetween(recCalories, 1900, 2500)) {
          version = 'B';
        } else {
          version = 'C';
        }
        break;
      case 'Snack':
        version = 'A';
        break;
      default:
        version = 'A';
    }
  } else {
    if (recCalories <= 1900) {
      version = 'A';
    } else if (isNumberBetween(recCalories, 1900, 2500)) {
      version = 'B';
    } else {
      version = 'C';
    }
  }

  return version;
};
