import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';

const IntensityIcon = props => (
  <Icon
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={props.filled ? 'Intensity-Icon-Filled' : 'Intensity-Icon-Empty'}
    {...props}
  >
    <g clipPath="url(#clip0_2742_44779)">
      <path
        opacity="0.3"
        d="M11.5316 13.8143C11.3483 14.6576 10.67 15.6109 9.34998 15.9318C12.0083 18.1043 14.52 16.1151 14.4466 13.8051C14.4466 11.9259 11.7425 10.8626 11.4491 9.14844C10.6516 11.2201 11.825 12.5034 11.5316 13.8143Z"
        fill="#16979A"
      />
      <path
        d="M17.8566 11.3209C16.4175 7.58088 11.2933 7.37921 12.5308 1.94338C12.6225 1.54005 12.1916 1.22838 11.8433 1.43921C8.51579 3.40088 6.12329 7.33338 8.13079 12.485C8.29579 12.9067 7.80079 13.3009 7.44329 13.0259C5.78413 11.77 5.60996 9.96421 5.75663 8.67171C5.81163 8.19505 5.18829 7.96588 4.92246 8.36005C4.29913 9.31338 3.66663 10.8534 3.66663 13.1725C4.01496 18.3059 8.35079 19.8825 9.90913 20.0842C12.1366 20.3684 14.5475 19.9559 16.28 18.37C18.1866 16.6009 18.8833 13.7775 17.8566 11.3209ZM9.34996 15.9317C10.67 15.6109 11.3483 14.6575 11.5316 13.8142C11.8341 12.5034 10.6516 11.22 11.4491 9.14838C11.7516 10.8625 14.4466 11.935 14.4466 13.805C14.52 16.1242 12.0083 18.1134 9.34996 15.9317Z"
        fill="#16979A"
      />
    </g>
    <defs>
      <clipPath id="clip0_2742_44779">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

const Icon = styled.svg`
  width: ${props => (props.width ? props.width : '18px')};
  height: ${props => (props.height ? props.height : '18px')};

  & path {
    fill: ${props =>
      props.filled && props.color
        ? props.color
        : props.filled
        ? colors.primary700
        : colors.primary200};
  }
`;

export default IntensityIcon;
