import React, { useEffect } from 'react';
import styled from 'styled-components';
import TextInput from '../elements/TextInput';
import { colors } from '../styleConstants';
import useFormValues from '../hooks/useFormValues';

const BillingDetails = ({ onChange, disabled, user, ...props }) => {
  const { values, handleChange } = useFormValues({
    billingName: user.fullName,
    billingEmail: user.email,
    billingPostcode: '',
  });

  useEffect(() => {
    // Replicate the Stripe event signature
    // so handleChange in StripePaymentForm.js can handle it properly
    onChange({
      elementType: 'billingName',
      error: undefined,
      value: values.billingName,
      empty: values.billingName === undefined || values.billingName === '',
      complete: values.billingName?.length > 2,
    });
  }, [values.billingName]);

  useEffect(() => {
    // Replicate the Stripe event signature
    // so handleChange in StripePaymentForm.js can handle it properly
    // TODO: Move this to a util function
    // https://app.clickup.com/t/2ngaqhp
    const validEmail = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(/* eslint-disable-line */
      values.billingEmail
    );
    onChange({
      elementType: 'billingEmail',
      error: undefined,
      value: values.billingEmail,
      empty: values.billingEmail === undefined || values.billingEmail === '',
      complete: validEmail,
    });
  }, [values.billingEmail]);

  useEffect(() => {
    // Replicate the Stripe event signature
    // so handleChange in StripePaymentForm.js can handle it properly
    onChange({
      elementType: 'billingPostcode',
      error: undefined,
      value: values.billingPostcode,
      empty:
        values.billingPostcode === undefined || values.billingPostcode === '',
      // TODO : could be improve by running proper US postcode validation
      // https://app.clickup.com/t/2ngaqkz
      // However this will be used to validate against the payment detail,
      // so if it's wrong, stipe will likely decline the payment.
      complete: values.billingPostcode?.length > 2,
    });
  }, [values.billingPostcode]);

  return (
    <BillingDetailsContainer>
      <TextInput
        id="billingName"
        name="billingName"
        onChange={handleChange}
        value={values.billingName || ''}
        placeholder="Full Name"
        onFocus={e => (e.target.placeholder = '')}
        onBlur={e => (e.target.placeholder = 'Full Name')}
        border="none"
        disabled={disabled}
        style={{
          padding: '0',
        }}
      />
      <HR />
      <TextInput
        id="billingEmail"
        name="billingEmail"
        type="email"
        onChange={handleChange}
        value={values.billingEmail || ''}
        placeholder="Billing Email"
        onFocus={e => (e.target.placeholder = '')}
        onBlur={e => (e.target.placeholder = 'Billing Email')}
        border="none"
        disabled={disabled}
        style={{
          padding: '0',
        }}
      />
      <HR />
      <TextInput
        id="billingPostcode"
        name="billingPostcode"
        onChange={handleChange}
        value={values.billingPostcode || ''}
        placeholder="Billing Zip Code"
        onFocus={e => (e.target.placeholder = '')}
        onBlur={e => (e.target.placeholder = 'Billing Zip Code')}
        border="none"
        disabled={disabled}
        style={{
          padding: '0',
        }}
      />
    </BillingDetailsContainer>
  );
};

const BillingDetailsContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  border: 1px solid ${colors.primary200};
  border-radius: 8px;
  background-color: #ffffff;
  margin: 0 0 15px 0;
  padding: 15px 20px;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
`;

const HR = styled.hr`
  margin: 0;
  color: ${colors.primary200};
  border-top: 1px solid ${colors.primary200};
  border-bottom: none;
  border-left: none;
  border-right: none;
`;

export default BillingDetails;
