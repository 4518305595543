import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { formatDate } from '../helpers/date';

import TextLink from '../elements/TextLink';
import StatsBox from '../universal/StatsBox';

// import WeeklyReportBox from '../universal/WeeklyReportBox'; TODO: Post-Beta feature
import WorkoutCard from './WorkoutCard';
import FitnessStats from './FitnessStats';
import CurrentPlanCard from './CurrentPlanCard';
import EmptyStateCard from './EmptyStateCard';
import Activity from './activity/Activity';

import { calculateWorkoutStats } from './calculateWorkoutStats';

import MobileTopNav from '../universal/MobileTopNav';
import { isMobile } from '../helpers/utils';
import { toggleAllComplete } from '../services/api/workout';
import { trackEvent } from '../integrations/analytics';

import {
  getUserFitnessPlanByDate,
  addWorkoutToFitnessPlan,
  addActivityToFitnessPlan,
  updateUserFitnessPlan,
} from '../store/actions/fitnessplan';

import { addError } from '../store/actions/general';

import { editUserWorkout } from '../services/api/workout';
import ErrorBoundary from '../services/ErrorBoundary';
import DateNavigator from '../elements/DateNavigator';

const UserFitnessPlan = ({ fitnessPlan, ...props }) => {
  const [dateValue, setDateValue] = useState(new Date());

  const {
    workouts,
    activities,
    totalCaloriesBurned,
    totalCaloriesWorkout,
  } = calculateWorkoutStats(fitnessPlan, dateValue);

  function addWorkoutToUserFitnessPlan(params) {
    trackEvent(
      `Add Workout (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`,
      { workout: params.workout }
    );
    props.addWorkoutToFitnessPlan(fitnessPlan._id, params);
  }

  function removeWorkoutFromFitnessPlan(workoutId) {
    trackEvent(
      `Remove Workout (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`,
      { workout: workoutId }
    );
    let updatedFitnessPlan = cloneDeep(fitnessPlan);
    updatedFitnessPlan.workouts = updatedFitnessPlan.workouts.filter(
      workout => workout._id !== workoutId
    );
    props.updateUserFitnessPlan(fitnessPlan._id, updatedFitnessPlan);
  }

  function addActivityToUserFitnessPlan(params) {
    trackEvent(
      `Add Activity (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`,
      { activity: params.activity }
    );
    props.addActivityToFitnessPlan(fitnessPlan._id, params);
  }

  function removeActivityFromFitnessPlan(activityId) {
    trackEvent(
      `Remove Activity (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`,
      { activity: activityId }
    );
    let updatedFitnessPlan = cloneDeep(fitnessPlan);
    updatedFitnessPlan.activities = updatedFitnessPlan.activities.filter(
      activity => activity._id !== activityId
    );
    props.updateUserFitnessPlan(fitnessPlan._id, updatedFitnessPlan);
  }

  async function addExerciseToUserWorkout(params) {
    try {
      trackEvent(
        `Add Exercise (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`,
        { exercise: params.exercise }
      );
      const workout = fitnessPlan.workouts.find(
        workout => workout.workout._id === params.workout
      ).workout;
      const exercise = {
        workoutSequence: params.workoutSequence,
        exercise: params.exercise,
        sets: [],
        date: dateValue,
      };
      const updatedWorkout = cloneDeep(workout);
      updatedWorkout.exercises = [...workout.exercises, exercise];
      await editUserWorkout(workout._id, updatedWorkout);
      props.getUserFitnessPlanByDate(props.userId, dateValue);
    } catch (err) {
      props.addError(
        'Error while updating your fitness plan, please try again.'
      );
      console.error(err);
    }
  }
  async function removeExerciseFromWorkout(workout, exerciseId) {
    try {
      trackEvent(
        `Remove Exercise (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`,
        { exercise: exerciseId }
      );
      const updatedWorkout = cloneDeep(workout);
      updatedWorkout.exercises = updatedWorkout.exercises.filter(
        exercise => exercise._id !== exerciseId
      );
      await editUserWorkout(workout._id, updatedWorkout);
      props.getUserFitnessPlanByDate(props.userId, dateValue);
    } catch (err) {
      props.addError(
        'Error while updating your fitness plan, please try again.'
      );
      console.error(err);
    }
  }

  async function logAllComplete() {
    trackEvent(
      `Toggle All Complete (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`
    );
    const updatedFitnessPlan = cloneDeep(fitnessPlan);
    const dateSelected = moment(dateValue).format('YYYY-MM-DD');
    for (const activity of updatedFitnessPlan.activities) {
      const activityDate = moment(activity.date)
        .utc()
        .format('YYYY-MM-DD');
      if (dateSelected === activityDate && !activity.logged) {
        activity.logged = new Date();
      }
    }
    for (const workout of updatedFitnessPlan.workouts) {
      const workoutDate = moment(workout.date)
        .utc()
        .format('YYYY-MM-DD');
      if (dateSelected === workoutDate && !workout.allComplete) {
        const params = {
          allComplete: workout.allComplete,
        };
        await toggleAllComplete(workout.workout._id, params);
        workout.allComplete = new Date();
      }
    }
    props.updateUserFitnessPlan(fitnessPlan._id, updatedFitnessPlan);
  }

  async function updateSets(workout, exerciseId, sets) {
    try {
      const updatedWorkout = cloneDeep(workout);
      const exerciseIndex = updatedWorkout.exercises.findIndex(
        exercise => exerciseId === exercise._id
      );
      const exerciseTmp = [...updatedWorkout.exercises];
      exerciseTmp[exerciseIndex].sets = sets;
      updatedWorkout.exercises = exerciseTmp;
      await editUserWorkout(workout._id, updatedWorkout);
      props.getUserFitnessPlanByDate(props.userId, dateValue);
    } catch (err) {
      props.addError(
        'There was an error while updating your sets, please try again'
      );
      console.error(err);
    }
  }

  const activityList = activities.map(activity => (
    <Activity
      key={activity._id}
      id={activity._id}
      activity={activity}
      fitnessPlan={fitnessPlan}
      removeActivity={removeActivityFromFitnessPlan}
    />
  ));

  const workoutList = workouts.map(workout => (
    <WorkoutCard
      key={workout._id}
      workout={workout}
      removeWorkout={removeWorkoutFromFitnessPlan}
      fitnessPlan={fitnessPlan}
      addExercise={addExerciseToUserWorkout}
      updateSets={updateSets}
      removeExercise={removeExerciseFromWorkout}
      date={formatDate(dateValue)}
    />
  ));

  const momentDateValue = moment(dateValue);
  let dateText = '';
  // check for today
  if (momentDateValue.isSame(moment(), 'day')) dateText = 'Today ';
  // check for tomorrow;
  if (momentDateValue.isSame(moment().add(1, 'd'), 'day'))
    dateText = 'Tomorrow ';
  if (momentDateValue.isSame(moment().subtract(1, 'd'), 'day'))
    dateText = 'Yesterday, ';

  useEffect(() => {
    const fromExplore = localStorage.getItem('from_explore');
    // If we are arriving here from the Explore tab, we need to default to the correct date
    if (fromExplore === 'yes' || isMobile()) {
      const dValue = localStorage.getItem('fitnessplan_dateValue');
      if (dValue) {
        // adjust for time zone
        let newDate = new Date(JSON.parse(dValue));
        newDate.setDate(newDate.getUTCDate());
        setDateValue(newDate);
        localStorage.removeItem('fitnessplan_dateValue');
      }
    }
  }, []);

  if (!isMobile()) {
    // Web version
    return (
      <PageContainer justify="space-between">
        <LeftContainer flexDirection="column">
          <ErrorBoundary>
            <>
              <DateContainer alignItems="center" justify="space-between">
                <DateDisplayContainer>
                  <DateDisplay data-test="fitnessPlan-date-display">
                    {dateText}
                    <DateToday>{momentDateValue.format('MMMM Do')}</DateToday>
                  </DateDisplay>
                </DateDisplayContainer>
                <TextLink
                  linkText="Log All As Complete"
                  size="small"
                  onClick={logAllComplete}
                  data-test="fitnessPlan-logAll"
                />
              </DateContainer>
              <ItemsContainer flexDirection="column">
                {workouts.length > 0 && workoutList}
                {activities.length > 0 && activityList}
                <EmptyStateContainer
                  flexDirection={
                    !workouts.length > 0 && activities.length > 0
                      ? 'row'
                      : 'column'
                  }
                >
                  {workouts.length === 0 && (
                    <EmptyStateCard
                      type="Workout"
                      half={activities.length > 0 ? 'true' : 'false'}
                      addWorkout={addWorkoutToUserFitnessPlan}
                      addActivity={addActivityToUserFitnessPlan}
                      date={formatDate(dateValue)}
                    />
                  )}
                  <EmptyStateCard
                    type="Activity"
                    medium={activityList.length > 0 ? 'true' : 'false'}
                    half={
                      workouts.length === 0 && activityList.length > 0
                        ? 'true'
                        : 'false'
                    }
                    addWorkout={addWorkoutToUserFitnessPlan}
                    addActivity={addActivityToUserFitnessPlan}
                    date={formatDate(dateValue)}
                  />
                </EmptyStateContainer>
              </ItemsContainer>
            </>
          </ErrorBoundary>
        </LeftContainer>
        <RightContainer flexDirection="column">
          <DateContainer alignItems="center" justify="flex-end">
            <FlexContainer>
              <DateNavigator date={dateValue} setDate={setDateValue} />
            </FlexContainer>
          </DateContainer>
          <StatsBox>
            <FitnessStats
              caloriesBurned={totalCaloriesBurned || 0}
              workoutGoal={totalCaloriesWorkout || 1}
              steps={4965} // TODO: Post-Beta feature - remove hard coded values
              stepsGoal={10000} // TODO: https://app.clickup.com/t/8ethkc
            />
          </StatsBox>
          <StatsBox>
            <CurrentPlanCard />
          </StatsBox>
          {/* TODO: Post-Beta feature
          https://app.clickup.com/t/8etjmm
        <StatsBox>
          <WeeklyReportBox type="fitness" />
        </StatsBox> */}
        </RightContainer>
      </PageContainer>
    );
  } else {
    // Mobile version
    return (
      <>
        <MobileTopNav
          dateText={dateText}
          dateValue={dateValue}
          setDateValue={setDateValue}
        ></MobileTopNav>
        <MobileContainer>
          <FitnessContainer justify="center" alignItems="center">
            {workouts.length === 0 && activities.length === 0 && (
              <StateDisplay>Nothing planned yet for today!</StateDisplay>
            )}
          </FitnessContainer>
          <MobileItemsContainer flexDirection="column">
            {workouts.length > 0 && workoutList}
            {activities.length > 0 && activityList}
            <MobileEmptyStateContainer
              flexDirection="column"
              alignItems="center"
            >
              {workouts.length === 0 && (
                <EmptyStateCard
                  type="Workout"
                  medium={activities.length > 0 ? 'true' : 'false'}
                  addWorkout={addWorkoutToUserFitnessPlan}
                  addActivity={addActivityToUserFitnessPlan}
                  date={formatDate(dateValue)}
                />
              )}
              <EmptyStateCard
                type="Activity"
                medium={
                  workouts.length > 0 || activities.length > 0
                    ? 'true'
                    : 'false'
                }
                addWorkout={addWorkoutToUserFitnessPlan}
                addActivity={addActivityToUserFitnessPlan}
                date={formatDate(dateValue)}
              />
            </MobileEmptyStateContainer>
          </MobileItemsContainer>
          <FitnessStats
            caloriesBurned={totalCaloriesBurned || 0}
            workoutGoal={totalCaloriesWorkout || 1}
            steps={4965} // TODO: Post-Beta feature - remove hard coded value
            stepsGoal={10000} // TODO: https://app.clickup.com/t/8ethkc
          />
        </MobileContainer>
      </>
    );
  }
};

/** Web **/

const PageContainer = styled(FlexContainer)`
  padding: 0 55px 55px 55px;
  position: relative;
`;

const DateContainer = styled(FlexContainer)`
  height: 77px;
`;

const DateDisplayContainer = styled(FlexContainer)``;

const DateToday = styled.span`
  color: ${colors.primary600};
  font-weight: 500;
`;

const DateDisplay = styled.p`
  color: ${colors.primary800};
  text-transform: uppercase;
  font-weight: bold;
`;

const EmptyStateContainer = styled(FlexContainer)`
  > :not(:last-child) {
    margin-right: ${props => (props.flexDirection === 'column' ? '0' : '12px')};
    margin-bottom: ${props =>
      props.flexDirection === 'column' ? '12px' : '0'};
  }
`;

const LeftContainer = styled(FlexContainer)`
  flex-basis: 50%;
  margin-right: 14px;
`;

const RightContainer = styled(FlexContainer)`
  flex-basis: 330px;
  min-width: 320px;
  margin-left: 14px;
`;

const ItemsContainer = styled(FlexContainer)`
  > * {
    margin-bottom: 16px;
  }
`;

/**
 * Mobile
 */

const MobileContainer = styled.div`
  margin-top: 38px;
`;

const FitnessContainer = styled(FlexContainer)`
  margin-bottom: 8px;
`;

const StateDisplay = styled.h6`
  height: 36px;
  width: 157px;
  color: ${colors.secondary600};
  text-align: center;
  margin-top: 30px;
`;

const MobileItemsContainer = styled(FlexContainer)`
  overflow-x: hidden;
`;

const MobileEmptyStateContainer = styled(FlexContainer)`
  > :not(:last-child) {
    margin-right: ${props => (props.flexDirection === 'column' ? '0' : '12px')};
    margin-bottom: 0px;
  }
`;

function mapStateToProps(state) {
  const { currentUser, selectedFitnessPlan } = state;
  return {
    userId: currentUser.user.id,
    fitnessPlan: selectedFitnessPlan,
  };
}

export default connect(
  mapStateToProps,
  {
    getUserFitnessPlanByDate,
    addWorkoutToFitnessPlan,
    addActivityToFitnessPlan,
    updateUserFitnessPlan,
    addError,
  }
)(UserFitnessPlan);
