import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import AppBar from '@material-ui/core/AppBar';
import MobileBackIcon from '../icons/MobileBackIcon';
import { isMobile } from '../helpers/utils';

const TopNav = ({ history, handleBack }) => {
  if (isMobile()) {
    return (
      <NavContainer position="fixed" color="default" id="mobile-top-nav">
        <HeaderContainer alignItems="center" justify="center">
          <MobBackIconContainer>
            <MobileBackIcon handleClick={handleBack} data-test="mobile-back" />
          </MobBackIconContainer>
          <NutriologyNameLogo onClick={() => history.push('/signup')}>
            Nutriology
          </NutriologyNameLogo>
        </HeaderContainer>
      </NavContainer>
    );
  } else {
    return (
      <Nav>
        <NutriologyNameLogo onClick={() => history.push('/signup')}>
          Nutriology
        </NutriologyNameLogo>
      </Nav>
    );
  }
};

const NavContainer = styled(AppBar)`
  z-index: 1;
  top: 0;
  bottom: auto;
  background-color: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const HeaderContainer = styled(FlexContainer)`
  height: 44px;
  padding: 0 12px;
  min-width: 55%;
  max-width: 75%;
  align-self: center;
`;

const MobBackIconContainer = styled(FlexContainer)`
  position: absolute;
  left: 10px;
`;

const NutriologyNameLogo = styled.div`
  text-transform: uppercase;
  color: ${colors.secondary600};
  font-weight: 700;
  font-size: ${props => (isMobile() ? '18px' : '28px')};

  &:hover {
    cursor: pointer;
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  padding: 0 55px;
  border-bottom: 1px solid ${colors.primary200};
  position: relative;
  z-index: 5;
  flex-shrink: 0;
  background-color: white;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.08);
`;

export default TopNav;
