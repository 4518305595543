import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from './store';
import { StylesProvider } from '@material-ui/styles';
import ReactGA from 'react-ga';
import { logAppInfo } from './utils/appUtils';

import App from './App';
import { initializeAnalytics } from './integrations/analytics';

// Display troubleshooting info in the console
logAppInfo();

const store = configureStore();

if (process.env.REACT_APP_ENV === 'production') {
  // Initialize Userpilot
  initializeAnalytics();

  ReactGA.initialize(
    [
      {
        trackingId: 'UA-145311058-1',
        gaOptions: {
          name: 'marketingTracker',
          allowLinker: true,
        },
      },
      {
        trackingId: 'UA-145311058-2',
        gaOptions: { name: 'appTracker' },
      },
    ],
    { alwaysSendToDefaultTracker: false }
  );
  ReactGA.ga('marketingTracker.require', 'linker');
  ReactGA.ga('marketingTracker.linker:autoLink', ['nutriology.io']);

  ReactGA.pageview(window.location.pathname + window.location.search, [
    'marketing-tracker',
    'app-tracker',
  ]);
}

const Nutriology = (
  <Provider store={store}>
    <StylesProvider injectFirst>
      <Router>
        <App />
      </Router>
    </StylesProvider>
  </Provider>
);

ReactDOM.render(Nutriology, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
