import { useState } from 'react';
import dot from 'dot-object';

const useFormValues = (initialValue = {}) => {
  const [values, setValues] = useState(initialValue);

  const handleChange = e => {
    e.persist();
    let tmp = { ...values };
    let value;
    switch (e.target.type) {
      case 'number':
        value = parseInt(e.target.value);
        break;
      case 'file':
        value = e.target.files[0];
        break;
      default:
        value = e.target.value;
    }
    dot.str(e.target.name, value, tmp);
    setValues(tmp);
  };

  const handleValueChanged = (name, value, type = 'number') => {
    let tmp = { ...values };
    dot.str(name, type === 'number' ? parseFloat(value) : value, tmp);
    setValues(tmp);
  };

  /*
  This function is used for Date dropdowns to ensure the dropdown
  options are in a user-friendly format, while ensuring the selected value
  is saved as a Date object.
  */
  const handleDateChanged = (name, value, label) => {
    let tmp = { ...values };
    dot.str(name, { value: value, label: label }, tmp);
    setValues(tmp);
  };

  const handleContentEditable = e => {
    e.persist();
    let tmp = { ...values };
    let name = e.target.dataset.name;
    let type = e.target.dataset.type;
    let value = e.target.innerHTML.replace(
      /(<br[^>]>|<br>|&nbsp;|\n|\r|\t)+/gi,
      ''
    );
    if (type === 'numeric') {
      if (isNaN(String.fromCharCode(e.nativeEvent.which))) {
        e.preventDefault();
      }
      value = e.target.innerHTML.replace(/\D/g, '');
    }
    dot.str(name, value, tmp);
    setValues(tmp);
  };

  return {
    handleChange,
    handleValueChanged,
    handleDateChanged,
    values,
    setValues,
    handleContentEditable,
  };
};

export default useFormValues;
