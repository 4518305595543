import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { colors } from '../styleConstants';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import StaticSearchBar from '../elements/StaticSearchBar';
import { getImageURL } from '../services/api/api';
import { determineRecipeVersion } from '../helpers/recipeVersion';
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getRecipes as getAllRecipes } from '../services/api/recipe';
import { getWorkouts as getAllWorkouts } from '../services/api/workout';

// This component has been modeled on the Search.js components from the fitness and meal modals folder
// It is modified and customized to the specific scenarios required for the explore page

const ExploreModal = ({
  open,
  handleClose,
  category = '',
  setScreen,
  setRecipe,
  setWorkout,
  context = {},
  ...props
}) => {
  const user = useSelector(state => state.currentUser.user);
  const recCalories = user.recommendedMealPlanCalories || 0;

  const [recipes, setRecipes] = useState([]);
  const [workouts, setWorkouts] = useState([]);
  const [hasMoreRecipes, setHasMoreRecipes] = useState(false);
  const [hasMoreWorkouts, setHasMoreWorkouts] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = term => {
    setSearchTerm(term);
  };

  // Map our recipe tags to the dietType field on the User
  const tagMap = {
    Any: undefined,
    Flexitarian: undefined,
    Pescatarian: [
      'pescatarian',
      'vegetarian',
      'lactoVegetarian',
      'ovoVegetarian',
      'vegan',
    ],
    Vegetarian: ['vegetarian', 'lactoVegetarian', 'ovoVegetarian', 'vegan'],
    Vegan: ['vegan'],
  };

  const handleClick = item => {
    if (context?.type === 'meal' || context?.itemType === 'recipe') {
      setRecipe(item);
      setScreen('Recipe Page');
    } else if (context?.type === 'fitness' || context?.itemType === 'workout') {
      setWorkout(item);
      setScreen('Workout Page');
    }
  };

  const getRecipes = page => {
    const version = determineRecipeVersion(recCalories);
    const dietType = user.foodPreferences?.eatingPreference ?? 'any';
    const pageSizeRecipes = 20;
    let params = {
      limit: pageSizeRecipes,
      skip: pageSizeRecipes * page,
      version: version,
      sort: 'name',
      populate: { path: 'foods.food' },
    };
    if (context?.action === 'showItems') {
      // In this scenario, we have a predefined list of items to display,
      // so we will query the backend for recipes that match the provided IDs
      const items = context?.items.map(item => item.itemId);
      params.query = {
        $and: [
          { _id: { $in: items } },
          { version: { $in: [version, 'N/A', null, ''] } },
        ],
      };
    } else if (searchTerm === '') {
      // This scenario and the next cover the meal category section, where recipes will be loaded
      // based on the provided logic for each category (usually based on tags and diet type)
      params.query = {
        $and: [
          category.logic,
          { version: { $in: [version, 'N/A', null, ''] } },
          { 'tags.dietType': tagMap[dietType] },
        ],
      };
    } else {
      params.query = {
        $and: [
          category.logic,
          { version: { $in: [version, 'N/A', null, ''] } },
          { 'tags.dietType': tagMap[dietType] },
          { $text: { $search: searchTerm } },
        ],
      };
    }

    getAllRecipes(params)
      .then(results => {
        if (results.length === 0 || results.length < pageSizeRecipes) {
          setHasMoreRecipes(false);
        } else {
          setHasMoreRecipes(true);
        }

        const filtered = results.filter(item => item.active);
        if (page === 0) {
          setRecipes(filtered);
        } else {
          setRecipes(recipes.concat(filtered));
        }
      })
      .catch(err => {
        console.error(err);
        setRecipes([]);
        setHasMoreRecipes(false);
      });
  };

  const getWorkouts = page => {
    const pageSizeWorkouts = 20;
    let params = {
      limit: pageSizeWorkouts,
      skip: pageSizeWorkouts * page,
      sort: 'name',
    };
    if (context?.action === 'showItems') {
      // In this scenario, we have a predefined list of items to display,
      // so we will query the backend for recipes that match the provided IDs
      const items = context?.items.map(item => item.itemId);
      params.query = { _id: { $in: items } };
    } else if (searchTerm === '') {
      // This scenario and the next cover the fitness category section, where workouts will be loaded
      // based on the provided logic for each category (usually based on tags)
      params.query = category.logic;
    } else {
      params.query = {
        $and: [category.logic, { $text: { $search: searchTerm } }],
      };
    }
    getAllWorkouts(params)
      .then(results => {
        if (results.length === 0 || results.length < pageSizeWorkouts) {
          setHasMoreWorkouts(false);
        } else {
          setHasMoreWorkouts(true);
        }
        const filtered = results.filter(item => item.active);
        if (page === 0) {
          setWorkouts(filtered);
        } else {
          setWorkouts(workouts.concat(filtered));
        }
      })
      .catch(err => {
        setWorkouts([]);
        setHasMoreWorkouts(false);
      });
  };

  // Load items on open
  useEffect(() => {
    if (open) {
      if (context?.type === 'meal' || context?.itemType === 'recipe') {
        getRecipes(0);
      } else if (
        context?.type === 'fitness' ||
        context?.itemType === 'workout'
      ) {
        getWorkouts(0);
      }
    } else {
      setSearchTerm('');
      setRecipes([]);
      setWorkouts([]);
    }
  }, [open]);

  useEffect(() => {
    if (open && category) {
      if (context?.type === 'meal') {
        // Reset search results:
        setRecipes([]);
        // Get new recipes
        getRecipes(0);
      }
      if (context?.type === 'fitness') {
        // Reset search results:
        setWorkouts([]);
        // Get new workouts
        getWorkouts(0);
      }
    }
  }, [searchTerm]);

  const loadMore =
    context?.type === 'meal' || context?.itemType === 'recipe'
      ? getRecipes
      : getWorkouts;
  const hasMore =
    context?.type === 'meal' || context?.itemType === 'recipe'
      ? hasMoreRecipes
      : hasMoreWorkouts;

  // Manage the header image
  const imageURL = (image, ratio = 1) => {
    const height = 214 * ratio;
    if (context?.type === 'fitness' && !image) {
      return `${process.env.PUBLIC_URL}/icons/WorkoutDefault${ratio}x.png`;
    }
    return getImageURL(image, `height:${height}`);
  };

  const headerTitle = category ? category?.name : context?.title;
  const headerImage1x = category
    ? imageURL(category.imageUrl, 1)
    : context?.image
    ? imageURL(context.image, 1)
    : context?.itemType === 'recipe'
    ? `${process.env.PUBLIC_URL}/explore/quickBreakfast.jpg`
    : `${process.env.PUBLIC_URL}/explore/weights.jpg`;

  const headerImage2x = category
    ? imageURL(category.imageUrl, 2)
    : context?.image
    ? imageURL(context.image, 2)
    : context?.itemType === 'recipe'
    ? `${process.env.PUBLIC_URL}/explore/quickBreakfast.jpg`
    : `${process.env.PUBLIC_URL}/explore/weights.jpg`;

  // Render the list of recipes or workouts
  const recipeList = recipes.map(recipe => {
    return (
      <ItemRow
        key={recipe._id}
        alignItems="center"
        onClick={() => handleClick(recipe)}
        data-test="exploreModal-recipeRow"
      >
        <Thumbnail
          image={imageURL(recipe.imageUrl, 1)}
          image2x={imageURL(recipe.imageUrl, 2)}
        />
        <ItemName data-test="exploreModal-recipeName">{recipe.name}</ItemName>
      </ItemRow>
    );
  });

  const workoutList = workouts.map(workout => {
    return (
      <ItemRow
        key={workout._id}
        alignItems="center"
        onClick={() => handleClick(workout)}
        data-test="exploreModal-workoutRow"
      >
        <Thumbnail
          image={imageURL(workout.imageUrl, 1)}
          image2x={imageURL(workout.imageUrl, 2)}
        />
        <ItemName data-test="exploreModal-workoutName">{workout.name}</ItemName>
      </ItemRow>
    );
  });

  const loader = (
    <Loader key={0} justify="center">
      <CircularProgress size={24} color="inherit" />
    </Loader>
  );

  return (
    <>
      <HeaderImage
        image={headerImage1x}
        image2x={headerImage2x}
        alignItems="center"
        justify="center"
      >
        <HeaderContainer alignItems="center" justify="center">
          <HeaderText data-test="explore-modalTitle">{headerTitle}</HeaderText>
        </HeaderContainer>
      </HeaderImage>
      <ContentContainer flexDirection="column">
        {context?.action !== 'showItems' && (
          <StaticSearchBar
            handleSearch={handleSearch}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            autoFocus={false}
            data-test="explore-search"
          />
        )}
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={hasMore}
          loader={loader}
          useWindow={false}
          getScrollParent={() => props.scrollParent.current}
          initialLoad={false}
        >
          {recipes.length > 0 && (
            <FlexContainer
              flexDirection="column"
              data-test="exploreModal-recipeList"
            >
              {recipeList}
            </FlexContainer>
          )}
          {workouts.length > 0 && (
            <FlexContainer
              flexDirection="column"
              data-test="exploreModal-workoutList"
            >
              {workoutList}
            </FlexContainer>
          )}
        </InfiniteScroll>
      </ContentContainer>
    </>
  );
};

const HeaderContainer = styled(FlexContainer)`
  text-align: center;
`;

const HeaderText = styled.h1`
  color: #fffcf5;
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  border-top: 1px solid #fffcf5;
  border-bottom: 1px solid #fffcf5;
  padding: 8px 0;
`;

const ContentContainer = styled(FlexContainer)`
  padding: 16px;
  gap: 16px;
`;

const HeaderImage = styled(FlexContainer)`
  width: 100%;
  min-height: 214px;
  background: ${props =>
    `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, rgba(0, 0, 0, 0) 11.5%, rgba(0, 0, 0, 0.53) 103.93%), image-set(url(${props.image}) 1x, url(${props.image2x}) 2x)`};
  background-image: ${props => `
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, rgba(0, 0, 0, 0) 11.5%, rgba(0, 0, 0, 0.53) 103.93%), -webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x)
  `};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const ItemRow = styled(FlexContainer)`
  padding: 8px;
  border-bottom: 1px solid ${colors.primary200};
  gap: 16px;
`;

const Thumbnail = styled(FlexContainer)`
  width: 110px;
  height: 80px;
  min-width: 110px;
  min-height: 80px;
  border-radius: 4px;
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const ItemName = styled.h5`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.primary900};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

const Loader = styled(FlexContainer)`
  color: ${colors.secondary500};
`;

export default ExploreModal;
