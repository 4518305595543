import React from 'react';

const CollapseIconCircle = ({ toggleSelect, ...props }) => {
  return (
    <svg
      onClick={toggleSelect}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <circle cx="16" cy="16" r="16" fill="#E8E8E8"></circle>
      <path
        fill="#484848"
        d={
          props.open
            ? 'M12.4703 17.8033C12.7298 18.0628 13.1505 18.063 13.4103 17.8038L15.2937 15.9245C15.684 15.535 16.316 15.535 16.7063 15.9245L18.5897 17.8038C18.8495 18.063 19.2702 18.0628 19.5297 17.8033C19.7895 17.5435 19.7895 17.1225 19.5297 16.8628L16.7071 14.0401C16.3166 13.6496 15.6834 13.6496 15.2929 14.0401L12.4703 16.8628C12.2105 17.1225 12.2105 17.5435 12.4703 17.8033Z'
            : 'M12.47 14.196c.26-.26.68-.26.94 0l1.884 1.88a1 1 0 001.412 0l1.884-1.88a.665.665 0 01.94.94l-2.823 2.823a1 1 0 01-1.414 0l-2.823-2.822a.665.665 0 010-.94z'
        }
      ></path>
    </svg>
  );
};

export default CollapseIconCircle;
