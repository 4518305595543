import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import CloseIcon from '../../icons/CloseIcon';
import BackIcon from '../../icons/BackIcon';
import RecordIcon from '../../icons/RecordIcon';
import FavoriteIcon from '../../icons/FavoriteIcon';
import SwapIcon from '../../icons/SwapIcon';
import RemoveIcon from '../../icons/RemoveIcon';
import AddImage from '../../elements/AddImage';
import AddRecipeImageMobile from '../../elements/AddRecipeImageMobile';
import useReactRouter from 'use-react-router';

import RecipeDetails from './RecipeDetails';
import RecipeOptions from './RecipeOptions';
import RecipeNutritionContainer from './RecipeNutritionContainer';
import PlaceholderStats from './PlaceholderStats';
import ExpertTip from '../../universal/ExpertTip';
import Button from '../../elements/Button';
import { connect } from 'react-redux';
import { pick, cloneDeep } from 'lodash';
import { updateUser } from '../../store/actions/general';
import {
  updateUserMealPlan,
  swapMealPlanItem,
  setMealPlanRecipeProperty,
  getUserMealPlanById,
  updatePlaceholderRecipe,
} from '../../store/actions/mealplan';
import useModal from '../../hooks/useModal';
import useFormValues from '../../hooks/useFormValues';
import ModalContainer from '../../sharedModals/ModalContainer';
import LogIcon from '../../icons/LogIcon';
import moment from 'moment';
import { getImageURL } from '../../services/api/api';
import { isMobile, formatAttrLink } from '../../helpers/utils';
import MobileTopNav from '../../universal/MobileTopNav';
import { trackEvent } from '../../integrations/analytics';
import { Info } from '@material-ui/icons';

const RecipePage = ({
  swapMealPlanItem,
  setMealPlanRecipeProperty,
  updateUserMealPlan,
  mealPlan,
  getUserMealPlanById,
  updatePlaceholderRecipe,
  ...props
}) => {
  const { history } = useReactRouter();
  const { mealPlanId, recipeId } = useParams();
  const { open, launchModal, closeModal } = useModal(false);
  const ref = useRef(null);

  useEffect(() => {
    getUserMealPlanById(mealPlanId);
  }, [props.currentUser.id]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView();
    }
  }, [ref]);

  // get recipe from url recipe ID
  const recipe = mealPlan.recipes.find(recipe => {
    return recipe._id === recipeId;
  });

  const [editable, setEditable] = useState(false);
  const {
    values,
    handleContentEditable,
    handleChange,
    handleValueChanged,
  } = useFormValues({
    name: '',
    proteins: 0,
    carbohydrates: 0,
    fat: 0,
    calories: 0,
  });

  const handleRecord = () => {
    setEditable(!editable);
  };

  // Recipe Image
  const [imageUrl, setImageUrl] = useState();
  const inputFile = useRef(null);

  const handleChangeImage = e => {
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    handleChange(e);
  };

  const handleImage = () => {
    inputFile.current.click();
  };

  const handleSubmit = () => {
    updatePlaceholderRecipe(mealPlan, recipe, values, props.currentUser.id);
    setEditable(false);
  };

  const [display, setDisplay] = useState(true);

  const toggleDisplay = () => {
    setDisplay(!display);
  };

  if (!recipe) {
    return null;
  }

  const handleRecipePropertyChanged = (field, value) => {
    setMealPlanRecipeProperty(mealPlan, recipe, field, value, true);
  };

  const toggleEaten = () => {
    trackEvent(
      `Logged Recipe (Recipe Page - ${isMobile() ? 'Mobile' : 'Web'})`,
      { recipe: recipe, eaten: !recipe.eaten }
    );
    setMealPlanRecipeProperty(mealPlan, recipe, 'eaten', !recipe.eaten, true);
  };

  const toggleFavorite = e => {
    let newRecipes = [...props.currentUser.favoriteRecipes];

    // In most cases the recipe ID to add to the favorite is going to be the recipe admin ID
    // But sometimes, there won't be any (if it's a custom recipe for example)
    const favoriteRecipe = recipe.recipe.recipeAdmin
      ? recipe.recipe.recipeAdmin
      : recipe.recipe._id;

    if (props.currentUser.favoriteRecipes.includes(favoriteRecipe)) {
      // The recipe is already present, we remove it.
      newRecipes.splice(newRecipes.indexOf(favoriteRecipe), 1);
      trackEvent(
        `Unfavorite Recipe (Recipe Page - ${isMobile() ? 'Mobile' : 'Web'})`,
        {
          recipeId: favoriteRecipe,
          recipeName: recipe.recipe.name,
        }
      );
    } else {
      // The recipe is not present we add it to the list.
      newRecipes.push(favoriteRecipe);
      trackEvent(
        `Favorite Recipe (Recipe Page - ${isMobile() ? 'Mobile' : 'Web'})`,
        {
          recipeId: favoriteRecipe,
          recipeName: recipe.recipe.name,
        }
      );
    }

    const userData = {
      id: props.currentUser.id,
      favoriteRecipes: newRecipes,
    };
    props.updateUser(userData);
  };

  const swapItemsHandler = async params => {
    try {
      const newItem = params.recipe ? params.recipe : params.food;
      const quantity = params.quantity ? params.quantity : 1;
      trackEvent(
        `Swapped Recipe (Recipe Page - ${isMobile() ? 'Mobile' : 'Web'})`,
        { oldItem: recipe, newItem: newItem }
      );
      await swapMealPlanItem(mealPlan._id, newItem, recipeId, quantity);
      handleBack();
    } catch (error) {
      console.error('Error swapping: ', error);
    }
  };

  async function removeRecipeFromMealPlan() {
    try {
      trackEvent(
        `Swapped Recipe (Recipe Page - ${isMobile() ? 'Mobile' : 'Web'})`,
        { recipe: recipe }
      );
      const updatedMealPlan = cloneDeep(mealPlan);
      updatedMealPlan.recipes = updatedMealPlan.recipes.filter(
        recipe => recipe._id !== recipeId
      );
      await updateUserMealPlan(mealPlan._id, updatedMealPlan);
      handleBack();
    } catch (err) {
      // setError('Error removing recipe, please contact support@nutriology.io.');
      console.error(err);
    }
  }

  const recipeImageUrl = (ratio = 1) => {
    const height = 500 * ratio;
    const width = 550 * ratio;
    return getImageURL(
      recipe.recipe.imageUrl,
      `resizing_type:fill/height:${height}/width:${width}`
    );
  };

  const recipeYield = recipe?.yield || recipe.recipe.yield;

  const handleBack = () => {
    localStorage.setItem('mealplan_goBack', 'yes');
    history.goBack();
  };

  if (!isMobile())
    // Web view
    return (
      <div ref={ref}>
        <TopContainer>
          <Column flexDirection="column">
            {editable ? (
              <>
                <input
                  hidden
                  ref={inputFile}
                  type="file"
                  name="image"
                  onChange={handleChangeImage}
                />
                <AddImage
                  src={imageUrl}
                  onClick={handleImage}
                  size={{ width: '550px', height: '500px' }}
                />
              </>
            ) : (
              <RecipeImage image={recipeImageUrl()} image2x={recipeImageUrl(2)}>
                <BackIconContainer>
                  <BackIcon
                    handleClick={handleBack}
                    backgroundcolor="#fff"
                    width="40px"
                    height="40px"
                    borderradius="20px"
                    data-test="recipePage-back"
                  />
                </BackIconContainer>
              </RecipeImage>
            )}
          </Column>
          <Column flexDirection="column" style={{ maxWidth: 550 }}>
            <RecipeInfoContainer flexDirection="column">
              {recipe?.leftover && (
                <LeftoverLabelContainer>
                  <LeftoverLabel
                    justify="center"
                    alignItems="center"
                    data-test="leftover-banner"
                  >
                    <Img
                      src={`${process.env.PUBLIC_URL}/icons/Microwave.svg`}
                    />
                    <LeftoverLabelText>Leftover</LeftoverLabelText>
                  </LeftoverLabel>
                </LeftoverLabelContainer>
              )}
              {recipe?.recipe?.placeholder && (
                <PlaceholderInfoBox
                  gap="18px"
                  display={display}
                  data-test="info-box"
                >
                  <StyledCloseIcon
                    handleClose={toggleDisplay}
                    size="24px"
                    showCircle="false"
                    data-test="close-info"
                  />
                  <Info
                    style={{ fill: colors.secondary600 }}
                    width="32px"
                    height="32px"
                  />
                  <p>
                    Feel free to use this placeholder for a meal out based on
                    your target calories and macros. Or to input your own
                    calories and macros, press record and update below. To log
                    the meal, simply press log. Or press swap to search for a
                    recipe in the Nutriology database.
                  </p>
                </PlaceholderInfoBox>
              )}
              <RecipeName
                data-test="recipePage-recipeName"
                length={recipe.recipe.name.length}
                contentEditable={editable}
                onInput={handleContentEditable}
                data-name="name"
                suppressContentEditableWarning={true}
                spellCheck={false}
              >
                {recipe.recipe.name}
              </RecipeName>
              <IconsContainer alignItems="center">
                {recipe.recipe.placeholder ? (
                  <RecordIcon
                    toggleSelect={handleRecord}
                    width="40px"
                    height="40px"
                    shrinkIcon={true}
                    data-test="recipePage-record"
                  />
                ) : (
                  <FavoriteIcon
                    toggleSelect={toggleFavorite}
                    selected={
                      props.currentUser.favoriteRecipes.includes(
                        recipe.recipe._id
                      ) ||
                      props.currentUser.favoriteRecipes.includes(
                        recipe.recipe.recipeAdmin
                      )
                    }
                    color="purple"
                    width="40px"
                    height="40px"
                    shrinkIcon={true}
                    data-test="recipePage-favorite"
                  />
                )}

                <SwapIcon
                  toggleSelect={launchModal}
                  width="40px"
                  height="40px"
                  color="teal"
                  shrinkIcon={true}
                  data-test="recipePage-swap"
                />
                <RemoveIcon
                  toggleSelect={removeRecipeFromMealPlan}
                  width="40px"
                  height="40px"
                  shrinkIcon={true}
                  data-test="recipePage-remove"
                />
                <LogIcon
                  logged={recipe.eaten}
                  toggleSelect={toggleEaten}
                  dateLogged={moment(recipe.eaten).format('MMMM Do')}
                  width="40px"
                  height="40px"
                  data-test="recipePage-logIcon"
                />
                {editable && (
                  <Button
                    buttonText="Save Changes"
                    buttonSize="small"
                    handleClick={handleSubmit}
                    data-test="save-changes"
                  />
                )}
              </IconsContainer>
              <RecipeOptions
                recipe={recipe}
                recipeYield={recipeYield}
                handleRecipePropertyChanged={handleRecipePropertyChanged}
              />
              {recipe.recipe.placeholder && (
                <FlexContainer
                  flexDirection="column"
                  gap="22px"
                  style={{ position: 'relative' }}
                >
                  <PlaceholderRecHeader>
                    Recommended Nutrition
                  </PlaceholderRecHeader>
                  <PlaceholderStats
                    recipe={recipe.recipe}
                    editable={editable}
                    values={values}
                    handleValueChanged={handleValueChanged}
                    handleContentEditable={handleContentEditable}
                  />
                </FlexContainer>
              )}
            </RecipeInfoContainer>
          </Column>
        </TopContainer>
        {!recipe.recipe.placeholder && (
          <BottomContainer flexDirection="column">
            <RecipeDetails recipe={recipe.recipe} recipeYield={recipeYield} />
            <RecipeNutritionContainer
              nutrients={recipe.recipe.nutrients}
              totalCalories={recipe.recipe?.nutrients?.calories}
            />
            <ExpertTip tipText={recipe.recipe.expertTip} />
            {recipe.recipe.source && (
              <Attribution href={formatAttrLink(recipe?.recipe?.attrLink)}>
                Recipe by {recipe.recipe.source}
              </Attribution>
            )}
          </BottomContainer>
        )}
        <ModalContainer
          addRecipe={swapItemsHandler}
          addFood={swapItemsHandler}
          open={open}
          context={{
            type: 'swap',
          }}
          handleClose={closeModal}
          initialScreen="Add Meal"
          {...props}
        />
      </div>
    );
  else
    return (
      // Mobile view
      <div ref={ref}>
        <MobileTopNav arrowsDisabled />
        <RecipeContainer>
          <MobileContainer flexDirection="column">
            {editable ? (
              <>
                <PlaceholderImageContainer>
                  <input
                    hidden
                    ref={inputFile}
                    type="file"
                    name="image"
                    onChange={handleChangeImage}
                  />
                  <AddRecipeImageMobile
                    src={imageUrl}
                    onClick={handleImage}
                    height="242px"
                  />
                </PlaceholderImageContainer>
              </>
            ) : (
              <ImageContainer>
                <RecipeMobileImage
                  image={recipeImageUrl()}
                  image2x={recipeImageUrl(2)}
                >
                  {recipe?.leftover && (
                    <LeftoverLabel
                      justify="center"
                      alignItems="center"
                      data-test="leftover-banner"
                    >
                      <Img
                        src={`${process.env.PUBLIC_URL}/icons/Microwave.svg`}
                      />
                      <LeftoverLabelText>Leftover</LeftoverLabelText>
                    </LeftoverLabel>
                  )}
                </RecipeMobileImage>
              </ImageContainer>
            )}

            {recipe?.recipe?.placeholder && (
              <PlaceholderInfoBox
                gap="18px"
                display={display}
                data-test="info-box"
              >
                <StyledCloseIcon
                  handleClose={toggleDisplay}
                  hideCircle
                  showCircle="false"
                  size="24px"
                  data-test="close-info"
                />
                <Info
                  style={{ fill: colors.secondary600 }}
                  color={colors.secondary600}
                  width="32px"
                  height="32px"
                />
                <p style={{ fontSize: 13 }}>
                  This meal is a placeholder for a meal out. To log with the
                  recommended nutrition values below, simply press Log. To
                  document exact stats, press Record and update the values
                  below! You can also use the Swap feature to search the ESHA
                  database for a similar meal.
                </p>
              </PlaceholderInfoBox>
            )}
            <RecipeMName
              contentEditable={editable}
              onInput={handleContentEditable}
              data-name="name"
              data-value={values.name || recipe.recipe.name}
              suppressContentEditableWarning={true}
              spellCheck={false}
              data-test="recipePage-recipeName"
            >
              {recipe.recipe.name}
            </RecipeMName>
            <MobileIconContainer justify="center" alignItems="center">
              {recipe.recipe.placeholder ? (
                <RecordIcon
                  toggleSelect={handleRecord}
                  width="40px"
                  height="40px"
                  shrinkIcon={true}
                  data-test="recipePage-record"
                />
              ) : (
                <FavoriteIcon
                  toggleSelect={toggleFavorite}
                  selected={
                    props.currentUser.favoriteRecipes.includes(
                      recipe.recipe._id
                    ) ||
                    props.currentUser.favoriteRecipes.includes(
                      recipe.recipe.recipeAdmin
                    )
                  }
                  color="purple"
                  width="40px"
                  height="40px"
                  shrinkIcon={true}
                  data-test="recipePage-favorite"
                />
              )}
              <SwapIcon
                toggleSelect={launchModal}
                width="40px"
                height="40px"
                color="teal"
                shrinkIcon={true}
                data-test="recipePage-swap"
              />
              <RemoveIcon
                toggleSelect={removeRecipeFromMealPlan}
                width="40px"
                height="40px"
                shrinkIcon={true}
                data-test="recipePage-remove"
              />
              <LogIcon
                logged={recipe.eaten}
                toggleSelect={toggleEaten}
                dateLogged={moment(recipe.eaten).format('MMMM Do')}
                width="40px"
                height="40px"
                data-test="recipePage-logIcon"
              />
            </MobileIconContainer>
            {editable && (
              <Button
                buttonText="Save Changes"
                buttonSize="small"
                width="60%"
                handleClick={handleSubmit}
                style={{ alignSelf: 'center' }}
                data-test="save-changes"
              />
            )}
            <RecipeOptions
              recipe={recipe}
              recipeYield={recipeYield}
              handleRecipePropertyChanged={handleRecipePropertyChanged}
            />
            {recipe.recipe.placeholder && (
              <FlexContainer flexDirection="column" gap="22px">
                <PlaceholderRecHeader>
                  Recommended Nutrition
                </PlaceholderRecHeader>
                <PlaceholderStats
                  recipe={recipe.recipe}
                  editable={editable}
                  values={values}
                  handleValueChanged={handleValueChanged}
                  handleContentEditable={handleContentEditable}
                />
              </FlexContainer>
            )}
          </MobileContainer>
        </RecipeContainer>
        {!recipe.recipe.placeholder && (
          <>
            <RecipeDetails
              recipe={recipe.recipe}
              recipeYield={recipe?.yield || recipe.recipe.yield}
            />
            {recipe.recipe.source && (
              <Attribution href={formatAttrLink(recipe?.recipe?.attrLink)}>
                Recipe by {recipe.recipe.source}
              </Attribution>
            )}
            <NutrientContainer>
              <RecipeNutritionContainer
                nutrients={recipe.recipe.nutrients}
                totalCalories={recipe.recipe?.nutrients?.calories}
              />
            </NutrientContainer>
          </>
        )}
        <ModalContainer
          addRecipe={swapItemsHandler}
          addFood={swapItemsHandler}
          open={open}
          context={{
            type: 'swap',
          }}
          handleClose={closeModal}
          initialScreen="Add Meal Mobile"
          {...props}
        />
      </div>
    );
};

/****
 *
 * Web case
 *
 */

const RecipeImage = styled.div`
  height: 500px; // Update image dimensions accordingly.
  width: 550px; // Update image dimensions accordingly.
  border-radius: 4px;
  margin-right: 24px;
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
  position: relative;
`;

const Attribution = styled.a`
  color: ${colors.primary400};
  margin-top: 44px;
  margin-left: 30px;
  text-decoration: none;
`;

const BackIconContainer = styled(FlexContainer)`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const TopContainer = styled(FlexContainer)`
  padding: 24px;
`;
const BottomContainer = styled(FlexContainer)`
  padding: 0 24px 24px 24px;
  gap: 40px;
`;
const Column = styled(FlexContainer)`
  flex-basis: 50%;
`;

const RecipeInfoContainer = styled(FlexContainer)`
  gap: 28px;
`;

const RecipeName = styled.h1`
  color: ${props =>
    props.contentEditable ? colors.primary400 : colors.primary800};
  font-weight: 700;
  font-size: ${props => (props.length > 16 ? '52px' : '64px')};
  line-height: ${props => (props.length > 16 ? '58px' : '72px')};
  outline: 0px solid transparent;
  overflow-wrap: anywhere;

  &:focus {
    outline: 0px solid transparent;
  }
`;

const IconsContainer = styled(FlexContainer)`
  gap: 36px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: ${() => (isMobile() ? '4px' : '8px')};
  right: ${() => (isMobile() ? '4px' : '8px')};
`;

const LeftoverLabelContainer = styled(FlexContainer)`
  width: 100%;
  margin-top: ${props => (isMobile() ? '58px' : '0px')};
  border-bottom: 1px solid ${colors.secondary600};
  position: relative;
`;

const LeftoverLabel = styled(FlexContainer)`
  position: ${props => (isMobile() ? 'absolute' : 'relative')};
  top: ${props => (isMobile() ? '14px' : '0')};
  right: ${props => (isMobile() ? '14px' : '0')};
  border-radius: ${props => (isMobile() ? '4px' : '4px 4px 0px 0px')};
  width: 130px;
  height: 34px;
  background-color: ${colors.secondary600};
`;

const LeftoverLabelText = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #fff;
`;

const Img = styled.img`
  margin-right: 8px;
`;

const PlaceholderRecHeader = styled.h4`
  color: ${colors.primary800};
  font-size: 24px;
  font-weight: 700;
  margin-top: 18px;
  margin-left: ${() => (isMobile() ? '16px' : 'initial')};
`;

const PlaceholderInfoBox = styled(FlexContainer)`
  position: relative;
  display: ${props => (props.display ? 'flex' : 'none')};
  padding: 16px;
  width: fit-content;
  background-color: ${colors.primary050};
  border-radius: 4px;
  margin: ${props => (isMobile() ? '0 16px' : 'initial')};
`;

/***
 *
 * Mobile
 *
 */

const MobileContainer = styled(FlexContainer)`
  width: 100%;
  gap: 28px;
`;
const RecipeMobileImage = styled.div`
  height: 242px;
  width: 100%;
  margin-top: 47px;
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  justify-content: center;
  text-align: center;
  position: relative;
`;

const RecipeContainer = styled(FlexContainer)`
  justify-content: center;
  margin-bottom: 32px;
`;
const ImageContainer = styled.div`
  position: relative;
`;

const PlaceholderImageContainer = styled.div`
  height: 242px;
  width: 100%;
  margin-top: 47px;
`;

const RecipeMName = styled.h1`
  margin: 0px 30px;
  font-weight: 700;
  color: ${props =>
    props.contentEditable ? colors.primary400 : colors.primary800};
  line-height: 40px;
  text-align: center;
  outline: 0px solid transparent;
  overflow-wrap: anywhere;

  &:focus {
    outline: 0px solid transparent;
  }
`;

const MobileIconContainer = styled(FlexContainer)`
  /* margin-top: 24px; */
  gap: 36px;
`;

const NutrientContainer = styled.div`
  width: 100%;
  padding: 32px 0 104px 0;
`;

function mapStateToProps(state) {
  const { currentUser, selectedMealPlan } = state;
  const fields = ['id', 'favoriteRecipes'];
  const user = pick(currentUser.user, fields);
  return {
    currentUser: user,
    mealPlan: selectedMealPlan,
  };
}

export default connect(
  mapStateToProps,
  {
    updateUser,
    getUserMealPlanById,
    swapMealPlanItem,
    setMealPlanRecipeProperty,
    updateUserMealPlan,
    updatePlaceholderRecipe,
  }
)(RecipePage);
