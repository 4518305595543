import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '../../icons/CloseIcon';
import Button from '../../elements/Button';

import { colors } from '../../styleConstants';
import DialogContent from '@material-ui/core/DialogContent';

const ConfirmDeleteModal = ({
  open,
  handleClose,
  handleDelete,
  mealPlans,
  type,
}) => {
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <StyledDialogContent>
          <Container justify="center" flexDirection="column">
            <NavBar justify="space-between" alignItems="center">
              <CloseRight>
                <CloseIcon handleClose={handleClose} />
              </CloseRight>
            </NavBar>
            <ModalBody
              justify="space-evenly"
              alignItems="center"
              flexDirection="column"
            >
              <FlexContainer flexDirection="column" alignItems="center">
                <Header>Delete Confirmation</Header>
                <Description>
                  Current user has {type} assigned for the selected week. Check
                  assigned {type} id below.
                </Description>
                {mealPlans.map(mealPlan => (
                  <MealPlanId key={mealPlan.id}>{mealPlan.id}</MealPlanId>
                ))}
                <Description>
                  Do you want to delete current {type} before assigning new one?
                </Description>
              </FlexContainer>
              <ButtonContainer flexDirection="row" alignItems="center">
                <CancelButton
                  buttonText="Cancel"
                  buttonSize="large"
                  onClick={handleClose}
                />
                <Button
                  buttonText="Delete"
                  buttonSize="large"
                  pink="true"
                  onClick={handleDelete}
                />
              </ButtonContainer>
            </ModalBody>
          </Container>
        </StyledDialogContent>
      </Modal>
    </div>
  );
};

const StyledDialogContent = styled(DialogContent)`
  width: 433px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const Container = styled(FlexContainer)`
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: white;
  border-radius: 4px;
  outline: 0;
`;
const ModalBody = styled(FlexContainer)`
  height: 100%;
  padding: 0 48px;
`;

const NavBar = styled(FlexContainer)`
  height: 58px;
  flex-shrink: 0;
  padding: 0 16px;
`;

const CloseRight = styled(FlexContainer)`
  right: 12px;
  position: absolute;
`;

const Header = styled.h2`
  color: ${colors.primary800};
  text-align: center;
`;

const Description = styled.p`
  color: ${colors.primary800};
  text-align: center;
  margin: 8px 0;
`;

const MealPlanId = styled.p`
  color: ${colors.secondary500};
  text-align: center;
`;

const ButtonContainer = styled(FlexContainer)`
  width: 100%;
  margin: 50px 0;
`;

const CancelButton = styled(Button)`
  background: none;
  color: ${colors.primary800};
  margin-right: 10px;

  &:hover {
    background: none;
  }
`;

export default ConfirmDeleteModal;
