import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { isEmpty, cloneDeep, pickBy, keys } from 'lodash';

import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';
import useFormValues from '../../hooks/useFormValues';
import useFormSubmit from '../../hooks/useFormSubmit';
import useToggles from '../../hooks/useToggles';
import useModal from '../../hooks/useModal';
import ModalContainer from '../../sharedModals/ModalContainer';

import { getFood, editFood } from '../../services/api/food';
import FoodForm from './FoodForm';

const EditFoodAdmin = props => {
  const initialToggleValues = {
    categories: {},
  };

  const { toggleValues, handleToggle, setToggleValues } = useToggles(
    initialToggleValues
  );

  const initialValues = {
    name: '',
    verboseName: '',
    type: '',
    variety: '',
    amount: 1,
    measureUnit: '',
    gramWeight: 0,
    calories: 0,
    proteins: 0,
    carbohydrates: 0,
    fiber: 0,
    totalSugar: 0,
    addedSugar: 0,
    fat: 0,
    saturatedFat: 0,
    transFat: 0,
    cholesterol_mg: 0,
    vitaminD_mcg: 0,
    calcium_mg: 0,
    potassium_mg: 0,
    sodium_mg: 0,
    kind: 'Admin',
  };

  const { values, setValues, handleChange, handleValueChanged } = useFormValues(
    initialValues
  );
  const [status, setStatus] = useState();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { open, launchModal, closeModal } = useModal(false);
  const [food, setFood] = useState({});

  async function loadFood(params) {
    try {
      const foodId = params.food;
      const result = await getFood(foodId);
      if (result) {
        setFood(result);
      } else {
        throw new Error('Food could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded food
  useEffect(() => {
    if (!isEmpty(food)) {
      const categories = {};
      const categoriesTmp = food.categories;

      categoriesTmp.forEach(category => (categories[category] = true));
      setToggleValues({ categories });

      setValues({
        name: food.name,
        verboseName: food.verboseName,
        type: food.type,
        variety: food.variety,
        amount: food.amount,
        measureUnit: food.measureUnit,
        gramWeight: food.gramWeight,
        calories: food.calories,
        proteins: food.proteins,
        carbohydrates: food.carbohydrates,
        fiber: food.fiber,
        totalSugar: food.totalSugar,
        addedSugar: food.addedSugar,
        fat: food.fat,
        saturatedFat: food.saturatedFat,
        transFat: food.transFat,
        cholesterol_mg: food.cholesterol_mg,
        vitaminD_mcg: food.vitaminD_mcg,
        calcium_mg: food.calcium_mg,
        potassium_mg: food.potassium_mg,
        sodium_mg: food.sodium_mg,
      });
    }
  }, [food]);

  const submitData = () => {
    const foodId = food._id;
    let payload = cloneDeep(values);
    payload.categories = keys(pickBy(toggleValues.categories, Boolean));

    setLoading(true);

    editFood(foodId, payload)
      .then(result => {
        setStatus('success');
        setMessage(
          `Custom Food "${result.verboseName}" was modified successfully!`
        );
        setValues(initialValues);
        setToggleValues(initialToggleValues);
      })
      .catch(err => {
        console.error(err);
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin'
  );

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Food"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
        data-test="food-find"
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          hide: 'recipe',
          adminFoodOnly: true,
        }}
        initialScreen="Meal Search"
        handleClose={closeModal}
        addFood={loadFood}
        {...props}
      />
      <FoodForm
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        status={status}
        setStatus={setStatus}
        message={message}
        loading={loading}
        handleSubmit={handleSubmit}
        viewOnly={isEmpty(food)}
        buttonText="Edit Food"
        formType="edit"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default EditFoodAdmin;
