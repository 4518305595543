import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { isMobile } from '../helpers/utils';
import Modal from '@material-ui/core/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '../icons/CloseIcon';
import MobileDialog from '../sharedModals/mobile/MobileDialog';
import { useSelector } from 'react-redux';

const CircleModal = ({ open, handleClose }) => {
  const circleSlug = useSelector(state => state.circleSlug);

  if (isMobile()) {
    return (
      <MobileDialog open={open} onClose={handleClose} data-test="circle-modal">
        <StyledDialogContent>
          <Container
            justify="center"
            alignItems="center"
            flexDirection="column"
          >
            <TopBar justify="flex-end" alignItems="center">
              <CloseIcon handleClose={handleClose} />
            </TopBar>
            <iframe
              title="circle-community-mobile"
              style={{
                border: 0,
                height: '100%',
                width: '100%',
                backgroundColor: '#fff',
                outline: 'none',
              }}
              src={`https://community.nutriology.io/c/${circleSlug}?iframe=true&automatic_login=true`}
            />
          </Container>
        </StyledDialogContent>
      </MobileDialog>
    );
  }
  return (
    <Modal open={open} onClose={handleClose} data-test="circle-modal">
      <StyledDialogContent>
        <Container justify="center" alignItems="center" flexDirection="column">
          <TopBar justify="flex-end" alignItems="center">
            <CloseIcon handleClose={handleClose} />
          </TopBar>
          <iframe
            title="circle-community"
            style={{
              border: 0,
              height: '100%',
              width: '100%',
              backgroundColor: '#fff',
            }}
            src={`https://community.nutriology.io/c/nutriology-transformation-feb-12-2024?iframe=true&automatic_login=true`}
          />
        </Container>{' '}
      </StyledDialogContent>
    </Modal>
  );
};

const Container = styled(FlexContainer)`
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
`;

const TopBar = styled(FlexContainer)`
  width: 100%;
  height: ${() => (!isMobile() ? '48px' : 'initial')};
  padding-right: 12px;
  background-color: #fff;
`;

const StyledDialogContent = styled(DialogContent)`
  height: 93%;
  width: 93%;
  margin: 1.5% auto;
  padding: 0;
  overflow: hidden;
  border-radius: 8px;

  & .MuiDialogContent-root {
    padding: 0;
  }
`;

export default CircleModal;
