import React from 'react';

function FoodLikeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g stroke="#16979A" clipPath="url(#clip0_2443_5303)">
        <path
          fill="#16979A"
          strokeWidth="0.6"
          d="M20.277 13.613a.444.444 0 00.572-.257l.74-1.943a.444.444 0 00-.187-.539c-.61-.367-.977-.87-.871-1.194l1.399-4.3a.443.443 0 10-.844-.274l-1.399 4.3c-.216.665.152 1.432.947 2.02l-.614 1.614a.444.444 0 00.257.573h0z"
        ></path>
        <path
          fill="#16979A"
          strokeWidth="0.6"
          d="M21.881 9.636s0 0 0 0a.327.327 0 01-.073-.017.443.443 0 01-.285-.56l1.118-3.436a.443.443 0 11.844.274l-1.119 3.437a.444.444 0 01-.485.303s0 0 0 0zM23.376 10.13s0 0 0 0a.443.443 0 00.56-.285l1.096-3.373a.443.443 0 10-.843-.275L23.09 9.57a.443.443 0 00.285.559s0 0 0 0zM15.364 24.573c-1.359 0-2.624-.8-3.465-1.331-.072-.046-.14-.09-.205-.13a8.387 8.387 0 00-.248-.15.444.444 0 01.44-.769c.143.082.306.185.48.295l.015.01c.765.482 1.914 1.209 3.027 1.187l5.424-.095c.456-.009.74-.298.748-.757a.842.842 0 00-.18-.443 8.441 8.441 0 00-.21-.079c-.041-.014-.078-.027-.111-.04a1.154 1.154 0 01-.212-.095.443.443 0 01.137-.81l.017-.004a8.21 8.21 0 01.253-.022l.092-.005.031-.002c.098-.005.24-.012.308-.02a.844.844 0 00.457-.808v-.002a.835.835 0 00-.233-.519l-.037-.011a6.972 6.972 0 00-.122-.037l-.007-.002c-.169-.05-.253-.075-.33-.122a.445.445 0 01.135-.812c.074-.016.158-.02.314-.024h.003l.044-.002.04-.001.077-.004h.023l.029-.003a.843.843 0 00.454-.81c-.005-.077-.077-.197-.187-.267l-.464-.294a.443.443 0 01.276-.817l.116.01c.069.002.306-.018.376-.03.42-.126.653-.62.6-.99-.066-.466-.54-.562-.926-.559l-6.278.045a.443.443 0 01-.391-.66c.081-.147.163-.292.244-.435l.024-.043h0c.986-1.75 1.699-3.015.929-5.04-.072-.19-.455-.369-.76-.355a.619.619 0 00-.05.005l-.014.002c-.006 0-.012.002-.017.003a.382.382 0 00-.046.011c-.036.012-.063.028-.069.049-.778 2.861-1.316 3.433-2.754 4.963l-.04.043c-.322.342-.685.73-1.11 1.206a.443.443 0 11-.662-.591c.45-.505.83-.91 1.166-1.266 1.401-1.49 1.824-1.94 2.544-4.588.116-.428.494-.698 1.01-.723.618-.031 1.397.307 1.632.926.885 2.329.096 3.868-.878 5.6l5.514-.04h.022c1.18 0 1.699.681 1.79 1.322.107.742-.3 1.506-.937 1.845.13.18.211.39.227.601a1.733 1.733 0 01-.683 1.508c.168.247.27.533.293.836v.003a1.732 1.732 0 01-.816 1.599 1.73 1.73 0 01.236.78c0 .946-.681 1.645-1.62 1.661l-5.423.096a.626.626 0 01-.036 0h-.026z"
        ></path>
        <path
          fill="#16979A"
          fillRule="evenodd"
          strokeWidth="0.6"
          d="M9.568 24.306H6.62A1.023 1.023 0 015.6 23.284v-8.09c0-.563.458-1.021 1.021-1.021h2.947c.564 0 1.022.458 1.022 1.022v8.089c0 .563-.459 1.022-1.022 1.022h0zM6.62 15.06a.136.136 0 00-.134.134v8.09c0 .072.062.134.134.134h2.947a.136.136 0 00.135-.134v-8.09a.136.136 0 00-.135-.134H6.621z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#16979A"
          strokeWidth="0.6"
          d="M22.344 13.917a.44.44 0 00.496-.308l.409-1.328c.994-.005 1.749-.409 1.966-1.077l1.4-4.3a.444.444 0 10-.845-.274l-1.399 4.3c-.105.324-.697.515-1.407.452a.444.444 0 00-.463.312l-.508 1.655a.444.444 0 00.351.568h0zM17.389 27.555a1.646 1.646 0 01-.3-.027c-.754-.14-1.52-.869-1.419-2.143a.444.444 0 01.884.07c-.057.73.308 1.129.696 1.2.47.087 1.077-.275 1.372-1.278a.444.444 0 11.851.25c-.398 1.36-1.287 1.928-2.084 1.928h0z"
        ></path>
        <circle cx="16" cy="16" r="15.7"></circle>
      </g>
      <defs>
        <clipPath id="clip0_2443_5303">
          <path fill="#fff" d="M0 0H32V32H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default FoodLikeIcon;
