import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import moment from 'moment';
import { ArrowBack, Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Button from '../elements/Button';
import FlexContainer from '../elements/FlexContainer';
import useReactRouter from 'use-react-router';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FractionInput from '../elements/FractionInput';
import RecordIcon from '../icons/RecordIcon';
import FavoriteIcon from '../icons/FavoriteIcon';
import SwapIcon from '../icons/SwapIcon';
import RemoveIcon from '../icons/RemoveIcon';
import LogIcon from '../icons/LogIcon';
import useFormValues from '../hooks/useFormValues';
import {
  formatCalories,
  formatProtein,
  formatTotalCarbohydrate,
  formatTotalFat,
} from '../helpers/nutrition';
import { updatePlaceholderRecipe } from '../store/actions/mealplan';
import { getImageURL } from '../services/api/api';

const Modal = ({
  mealPlan,
  open,
  handleClose,
  item,
  toggleEaten,
  toggleFavorite,
  isFavorite,
  swap,
  remove,
}) => {
  const calories = formatCalories(item.recipe?.nutrients?.calories);
  const protein = formatProtein(item.recipe.nutrients.proteins, false);
  const carbs = formatTotalCarbohydrate(
    item.recipe.nutrients.carbohydrates,
    false
  );
  const fat = formatTotalFat(item.recipe.nutrients.fat, false);

  const recipeImageURL = (ratio = 1) => {
    const height = 144 * ratio;
    const width = 232 * ratio;
    return getImageURL(
      item.recipe.imageUrl,
      `resizing_type:fill/height:${height}/width:${width}`
    );
  };

  // Record placeholder
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.currentUser.user);
  const [editable, setEditable] = useState(false);
  const { values, handleContentEditable, handleValueChanged } = useFormValues({
    name: '',
    proteins: 0,
    carbohydrates: 0,
    fat: 0,
    calories: 0,
  });

  const handleRecord = () => {
    setEditable(!editable);
  };

  const handleSubmit = () => {
    dispatch(updatePlaceholderRecipe(mealPlan, item, values, currentUser.id));
    setEditable(false);
  };

  const { history } = useReactRouter();

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      data-test="planner-recipeModal"
    >
      <DialogTitle disableTypography style={{ paddingBottom: 0 }}>
        {/* Action buttons */}
        <FlexContainer>
          <Tooltip title="Back">
            <IconButton
              aria-label="back"
              onClick={handleClose}
              data-test="planner-recipeModal-back"
            >
              <ArrowBack />
            </IconButton>
          </Tooltip>

          {/* Align right */}
          <FlexContainer justify="flex-end" flexGrow="1">
            <Tooltip title="Close">
              <IconButton
                aria-label="close"
                onClick={handleClose}
                data-test="planner-recipeModal-close"
              >
                <Close />
              </IconButton>
            </Tooltip>
          </FlexContainer>
        </FlexContainer>
      </DialogTitle>

      <DialogContent>
        <Content>
          {/* Left column */}
          <FlexContainer
            flexBasis="50%"
            flexDirection="column"
            margin="0 10px 0 0"
          >
            <RecipeImage
              image={recipeImageURL()}
              image2x={recipeImageURL(2)}
              data-test="planner-recipeModal-image"
            />
          </FlexContainer>

          {/* Right column */}
          <FlexContainer
            flexBasis="50%"
            flexDirection="column"
            margin="0 0 0 10px"
            gap="16px"
          >
            <MealTitle data-test="planner-recipeModal-mealTitle">
              {item.mealType}
            </MealTitle>
            <RecipeName
              contentEditable={editable}
              onInput={handleContentEditable}
              data-name="name"
              data-test="planner-recipeModal-name"
              suppressContentEditableWarning={true}
              spellCheck={false}
            >
              {item.recipe.name}
            </RecipeName>
            {editable ? (
              <Button
                buttonText="Save Changes"
                buttonSize="small"
                width="150px"
                handleClick={handleSubmit}
                data-test="save-changes"
              />
            ) : (
              <Button
                buttonText="View Recipe"
                buttonSize="small"
                width="150px"
                pink="true"
                variant="outlined"
                handleClick={() =>
                  history.push(
                    `/app/mealplan/${mealPlan._id}/recipe/${item._id}`
                  )
                }
                data-test="planner-recipeModal-viewRecipe"
              />
            )}
            <IconsContainer alignItems="center">
              {item.recipe.placeholder ? (
                <RecordIcon
                  toggleSelect={handleRecord}
                  width="32px"
                  height="32px"
                  shrinkIcon={true}
                  hideLabel={true}
                  data-test="planner-recipeModal-record"
                />
              ) : (
                <FavoriteIcon
                  toggleSelect={toggleFavorite}
                  selected={
                    currentUser.favoriteRecipes.includes(item.recipe._id) ||
                    currentUser.favoriteRecipes.includes(
                      item.recipe.recipeAdmin
                    )
                  }
                  color="purple"
                  width="32px"
                  height="32px"
                  shrinkIcon={true}
                  hideLabel={true}
                  data-test="planner-recipeModal-favorite"
                />
              )}

              <SwapIcon
                toggleSelect={swap}
                width="32px"
                height="32px"
                color="teal"
                shrinkIcon={true}
                hideLabel={true}
                data-type="swap"
                data-test="planner-recipeModal-swap"
              />
              <RemoveIcon
                toggleSelect={remove}
                width="32px"
                height="32px"
                shrinkIcon={true}
                hideLabel={true}
                data-test="planner-recipeModal-delete"
              />
              <LogIcon
                logged={item.eaten}
                toggleSelect={toggleEaten}
                dateLogged={moment(item.eaten).format('MMMM Do')}
                width="32px"
                height="32px"
                hideLabel={true}
                data-test="planner-recipeModal-log"
              />
            </IconsContainer>
          </FlexContainer>
        </Content>
        <Content style={{ marginTop: 24, paddingBottom: 32 }}>
          <StatsBox alignItems="center" justify="space-between">
            <FlexContainer gap="4px" alignItems="center">
              {editable ? (
                <FractionInput
                  id="calories"
                  name="calories"
                  onValueChanged={handleValueChanged}
                  placeholder={calories}
                  value={values.calories || calories}
                  minValue="0"
                  width="55px"
                  small={true}
                  data-test="calories-edit"
                />
              ) : (
                <Calories data-test="planner-recipeModal-calories">
                  {calories}
                </Calories>
              )}
              <Calories>Calories</Calories>
            </FlexContainer>
            <FlexContainer gap={editable ? '16px' : '24px'}>
              <FlexContainer gap="4px" alignItems="center">
                {editable ? (
                  <FractionInput
                    id="proteins"
                    name="proteins"
                    onValueChanged={handleValueChanged}
                    placeholder={protein}
                    value={values.proteins || protein}
                    minValue="0"
                    width="50px"
                    small={true}
                    data-test="planner-recipeModal-protein-edit"
                  />
                ) : (
                  <MacroNum data-test="planner-recipeModal-protein">
                    {protein}g
                  </MacroNum>
                )}
                <MacroLabel>Protein</MacroLabel>
              </FlexContainer>
              <FlexContainer gap="4px" alignItems="center">
                {editable ? (
                  <FractionInput
                    id="carbohydrates"
                    name="carbohydrates"
                    onValueChanged={handleValueChanged}
                    placeholder={carbs}
                    value={values.carbohydrates || carbs}
                    minValue="0"
                    width="50px"
                    small={true}
                    data-test="planner-recipeModal-carbs-edit"
                  />
                ) : (
                  <MacroNum data-test="planner-recipeModal-carbs">
                    {carbs}g
                  </MacroNum>
                )}
                <MacroLabel>Carbs</MacroLabel>
              </FlexContainer>
              <FlexContainer gap="4px" alignItems="center">
                {editable ? (
                  <FractionInput
                    id="fat"
                    name="fat"
                    onValueChanged={handleValueChanged}
                    placeholder={fat}
                    value={values.fat || fat}
                    minValue="0"
                    width="50px"
                    small={true}
                    data-test="planner-recipeModal-fat-edit"
                  />
                ) : (
                  <MacroNum data-test="planner-recipeModal-fat">
                    {fat}g
                  </MacroNum>
                )}
                <MacroLabel>Fat</MacroLabel>
              </FlexContainer>
            </FlexContainer>
          </StatsBox>
        </Content>
      </DialogContent>
    </Dialog>
  );
};

const Content = styled(FlexContainer)`
  padding: 0 28px;
`;

const RecipeName = styled.h2`
  font-size: 24px;
  font-weight: 700;
  color: ${props =>
    props.contentEditable ? colors.primary400 : colors.primary800};
  text-transform: capitalize;
  outline: 0px solid transparent;
  overflow-wrap: anywhere;

  &:focus {
    outline: 0px solid transparent;
  }
`;

const RecipeImage = styled.div`
  height: 200px; // Update image dimensions accordingly.
  width: 235px; // Update image dimensions accordingly.
  border-radius: 6px;
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const MealTitle = styled.h4`
  color: ${colors.secondary600};
  font-size: 12px;
  font-weight: 700;
  margin-bottom: -12px;
`;

const StatsBox = styled(FlexContainer)`
  height: 64px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${colors.primary200};
  padding: 0 20px;
`;

const Calories = styled.p`
  color: ${colors.primary900};
  font-size: 14px;
  font-weight: 700;
`;

const MacroNum = styled.p`
  color: ${colors.primary900};
  font-size: 12px;
  font-weight: 700;
`;

const MacroLabel = styled.p`
  color: ${colors.primary900};
  font-size: 12px;
  font-weight: 400;
`;

const IconsContainer = styled(FlexContainer)`
  gap: 22px;
  margin-top: 8px;
`;

export default Modal;
