import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import Button from '../../elements/Button';

import { cloneDeep, isEmpty, omit, keys, pickBy } from 'lodash';
import useFormValues from '../../hooks/useFormValues';
import useFormSubmit from '../../hooks/useFormSubmit';
import useToggles from '../../hooks/useToggles';
import useModal from '../../hooks/useModal';
import ModalContainer from '../../sharedModals/ModalContainer';

import WorkoutForm from './WorkoutForm';
import { addWorkout, getWorkout } from '../../services/api/workout';

const DuplicateWorkout = props => {
  const initialValues = {
    restBetweenSets: {
      warmUp: 1,
      core: 1,
      strength: 1,
      circuit: 1,
      conditioning: 1,
      coolDown: 1,
    },
    locationType: 'Home',
    gender: 'All',
    numberOfWeeks: 1,
  };
  const initialWorkoutInfo = {
    intensity: 3,
    duration: 30,
    caloriesBurned: 100,
    week: 1,
  };

  const { values, setValues, handleChange, handleValueChanged } = useFormValues(
    initialValues
  );

  const initialToggleValues = {
    active: false,
    level: {},
    location: {},
    specialtyFocus: {},
  };

  const { toggleValues, handleToggle, setToggleValues } = useToggles(
    initialToggleValues
  );

  const { open, launchModal, closeModal } = useModal(false);

  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [exercises, setExercises] = useState([]);
  const [workout, setWorkout] = useState({});
  const [workoutInfo, setWorkoutInfo] = useState([{ ...initialWorkoutInfo }]);
  const [imageUrl, setImageUrl] = useState();

  async function loadWorkout(params) {
    try {
      setMessage('');
      const workoutId = params.workout;
      const result = await getWorkout(workoutId);
      if (result) {
        setWorkout(result);
      } else {
        throw new Error('Workout could not be found');
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Populate form values from loaded workout
  useEffect(() => {
    if (!isEmpty(workout)) {
      setValues({
        name: workout.name,
        locationType: workout.locationType,
        gender: workout.gender,
        restBetweenSets: workout.restBetweenSets,
        numberOfWeeks: workout.numberOfWeeks,
        source: workout.source,
        attrLink: workout.attrLink,
        imageUrl: workout.imageUrl,
      });
      const toggleTmp = {
        active: workout.active,
        level: {},
        location: {},
        specialtyFocus: {},
      };

      const tags = workout.tags;
      for (let tagType in tags) {
        tags[tagType].forEach(tagVal => {
          toggleTmp[tagType][tagVal] = true;
        });
      }
      setToggleValues(toggleTmp);
      setWorkoutInfo(workout.workoutInfo);
      setExercises([...workout.exercises]);
      setImageUrl(workout.imageUrl);
    }
  }, [workout]);

  const getDeletedRecordNames = () => {
    if (exercises.some(exercise => exercise.isDeleted)) {
      const deletedExercises = exercises
        .filter(exercise => exercise.isDeleted)
        .map(exercise => exercise.name);
      return deletedExercises.join(', ');
    }
    return false;
  };

  const submitData = () => {
    setLoading(true);
    // Prevent saving if any exercises have been deleted
    const deletedRecordNames = getDeletedRecordNames();
    if (deletedRecordNames) {
      setStatus('error');
      setMessage(
        `The following items are deleted and must be removed or replaced before saving: ${deletedRecordNames}`
      );
      setLoading(false);
      return;
    }
    let payload = cloneDeep(values);
    payload.workoutInfo = workoutInfo;
    payload.active = toggleValues.active;
    payload.exercises = exercises.map(exercise => omit(exercise, 'id'));
    payload.tags = {};
    payload.tags.level = keys(pickBy(toggleValues.level, Boolean));
    payload.tags.location = keys(pickBy(toggleValues.location, Boolean));
    payload.tags.specialtyFocus = keys(
      pickBy(toggleValues.specialtyFocus, Boolean)
    );

    addWorkout(payload)
      .then(result => {
        setExercises([]);
        setWorkoutInfo([{ ...initialWorkoutInfo }]);
        setToggleValues(initialToggleValues);
        setStatus('success');
        setMessage(`Workout ${result.name} was created successfully!`);
        setValues(initialValues);
        setImageUrl(null);
      })
      .catch(err => {
        console.error(err);
        if (payload.imageUrl) {
          values.imageUrl = payload.imageUrl;
        }
        setStatus('error');
        if (err.error && err.error.message) {
          setMessage(err.error.message);
        } else if (err.message) {
          setMessage(err.message);
        } else if (typeof err === 'string') {
          setMessage(err);
        } else {
          setMessage('Error encountered');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { handleSubmit } = useFormSubmit(
    submitData,
    values,
    setValues,
    'admin'
  );

  return (
    <Container flexDirection="column">
      <Button
        buttonText="Find Workout"
        buttonSize="large"
        width="244px"
        handleClick={launchModal}
        data-test="workout-find"
      />
      <ModalContainer
        open={open}
        context={{
          variant: 'admin',
          showOnly: 'workout',
        }}
        initialScreen="Exercise Search"
        handleClose={closeModal}
        addWorkout={loadWorkout}
        {...props}
      />
      <WorkoutForm
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        handleChange={handleChange}
        values={values}
        handleValueChanged={handleValueChanged}
        toggleValues={toggleValues}
        handleToggle={handleToggle}
        exercises={exercises}
        setExercises={setExercises}
        workoutInfo={workoutInfo}
        setWorkoutInfo={setWorkoutInfo}
        status={status}
        setStatus={setStatus}
        message={message}
        loading={loading}
        handleSubmit={handleSubmit}
        buttonText="Create Workout"
        viewOnly={isEmpty(workout)}
        formType="create"
      />
    </Container>
  );
};

const Container = styled(FlexContainer)`
  padding: 30px 0;
`;

export default DuplicateWorkout;
