import React, { useState } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { debounce, without } from 'lodash';
import { colors } from '../../styleConstants';

import Collapsible from 'react-collapsible';
import CollapseIconMedium from '../../icons/CollapseIconMedium';
import RemoveXIcon from '../../icons/RemoveXIcon';
import ExerciseModal from './ExerciseModal';
import SetRow from './SetRow';
import Button from '../../elements/Button';
import { trackEvent } from '../../integrations/analytics';

import { getFormattedUnits, getDisplayWeight } from '../../helpers/exercise';
import { getImageURL } from '../../services/api/api';
import { Grid } from '@material-ui/core';
import { isMobile } from '../../helpers/utils';

import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';

const Exercise = ({
  exercise,
  sets,
  exerciseId,
  workout,
  date,
  addSet,
  handleRemove,
  updateSets,
  exerciseIndex,
  exercisesLength,
  previousSet,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const handleToggle = e => {
    setOpen(!open);
  };

  let displayBottomLine = exerciseIndex + 1 === exercisesLength;

  const [modalOpen, setModalOpen] = useState(false);
  const [img, setImg] = useState({});

  const openModal = exerciseImg => {
    setImg(exerciseImg);
    setModalOpen(true);
  };

  const closeModalItem = () => {
    setModalOpen(false);
    setImg({});
  };

  // Manage Sets
  const handleSetUpdate = async (name, index, value) => {
    const updatedSets = [...sets];
    updatedSets[index][name] = parseFloat(value);
    await updateSets(workout, exerciseId, updatedSets);
  };

  const removeSet = async setToRemove => {
    trackEvent(`Removed Set (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`);
    const updatedSets = without(sets, setToRemove);
    await updateSets(workout, exerciseId, updatedSets);
  };

  const toggleLogged = async set => {
    trackEvent(`Logged Set (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`);
    set.logged = !set.logged ? new Date() : null;
    const updatedSets = [...sets];
    await updateSets(workout, exerciseId, updatedSets);
  };

  const handleAdd = async () => {
    trackEvent(`Added Set (Fitness Plan - ${isMobile() ? 'Mobile' : 'Web'})`);
    let newSet;
    if (sets.length > 0) {
      newSet = {
        ...sets[sets.length - 1],
        date: date,
        logged: null,
        setNum: sets[sets.length - 1].setNum + 1,
      };
    } else {
      newSet = {
        repsNumber: 0,
        weightInLbs: 0,
        date: date,
        logged: null,
        setNum: 1,
      };
    }

    const updatedSets = [...sets, newSet];
    await updateSets(workout, exerciseId, updatedSets);
  };

  const triggerStyle = {
    position: 'absolute',
    top: '45px',
    right: '36px',
  };

  const mobileTriggerStyle = {
    position: 'absolute',
    top: '22px',
    right: '15px',
  };

  const displayWeight = getDisplayWeight(exercise);
  const formattedUnits = getFormattedUnits(exercise.repUnits);
  const previous =
    previousSet?.repsNumber && previousSet?.weightInLbs
      ? `${previousSet.repsNumber} ${formattedUnits} x ${previousSet.weightInLbs}lbs`
      : previousSet?.repsNumber
      ? `${previousSet.repsNumber} ${formattedUnits}`
      : '';

  const setRows = sets.map((set, i) => (
    <SetRow
      key={i}
      handleSetUpdate={debounce(handleSetUpdate, 500)}
      num={i}
      previous={previous}
      reps={sets[i].repsNumber || 10}
      repUnits={formattedUnits}
      weight={sets[i].weightInLbs || 100}
      set={set}
      displayWeight={displayWeight}
      removeSet={removeSet}
      logged={set.logged}
      toggleLogged={toggleLogged}
      isExpanded={open}
    />
  ));

  const exerciseURL = (ratio = 1) => {
    const width = 96 * ratio;
    const widthMobile = 120 * ratio;
    const height = 64 * ratio;
    const heightMobile = 84 * ratio;
    return isMobile()
      ? getImageURL(
          exercise.thumbnailUrl,
          `resizing_type:fill/height:${heightMobile}/width:${widthMobile}`
        )
      : getImageURL(
          exercise.thumbnailUrl,
          `resizing_type:fill/height:${height}/width:${width}`
        );
  };

  const trailingActions = () => (
    <TrailingActions>
      <SwipeAction onClick={() => handleRemove(exerciseId)}>
        <ActionContent>
          <MobRemoveXIcon data-index={'num'}>
            <TextStyle>Delete</TextStyle>
          </MobRemoveXIcon>
        </ActionContent>
      </SwipeAction>
    </TrailingActions>
  );

  if (!isMobile()) {
    // Web version
    return (
      <FlexContainer
        flexDirection="column"
        style={{ position: 'relative' }}
        data-test="exerciseRow"
      >
        <HR />
        <ExerciseInfoContainer justify="space-between">
          <LeftContainer alignItems="center">
            <Thumbnail
              image={exerciseURL()}
              image2x={exerciseURL(2)}
              onClick={() => openModal(exercise.imageUrl)}
              data-test="exerciseRow-thumbnail"
            />
            <ExerciseName
              onClick={() => openModal(exercise.imageUrl)}
              data-test="exerciseRow-name"
            >
              {exercise.name}
            </ExerciseName>
          </LeftContainer>
          <RightContainer alignItems="center">
            <StyledRemoveXIcon
              onClick={() => handleRemove(exerciseId)}
              data-test="exerciseRow-remove"
            />
          </RightContainer>
        </ExerciseInfoContainer>
        <Collapsible
          overflowWhenOpen="visible"
          triggerStyle={triggerStyle}
          contentOuterClassName="contentOuter__no_padding"
          open={open}
          trigger={
            <CollapseIconMedium
              small
              onClick={handleToggle}
              open={open}
              name={exercise.name}
              data-test="exerciseRow-trigger"
            />
          }
        >
          <SetsContainer flexDirection="column">
            <Table>
              <tbody>
                <TR>
                  <TH>Sets</TH>
                  <TH>Previous</TH>
                  <TH>
                    {exercise.repUnits === 'Secs' ||
                    exercise.repUnits === 'Mins' ||
                    exercise.repUnits === 'Secs Per Side' ||
                    exercise.repUnits === 'Mins Per Side'
                      ? 'Duration'
                      : exercise.repUnits === 'Reps Per Side'
                      ? 'Reps/Side'
                      : 'Reps'}
                  </TH>
                  <TH>Weight</TH>
                </TR>
                {setRows}
              </tbody>
            </Table>
            <ButtonContainer justify="center">
              <Button
                buttonText="+ Add Set"
                pink="true"
                onClick={handleAdd}
                data-test="exerciseRow-addSet"
              />
            </ButtonContainer>
          </SetsContainer>
        </Collapsible>
        <BottomHR displayBottomLine={displayBottomLine} />
        <ExerciseModal
          open={modalOpen}
          img={img}
          name={exercise.name}
          handleClose={closeModalItem}
        />
      </FlexContainer>
    );
  } else {
    // Mobile version
    return (
      <div>
        <SwipeableList fullSwipe className={`list-${exerciseId}`}>
          <SwipeableListItem
            trailingActions={trailingActions()}
            blockSwipe={open}
            data-test="swipeable-list-item"
          >
            <ExerciseRow
              flexDirection="column"
              style={{ position: 'relative', width: '100%' }}
              data-test="exerciseRow"
            >
              <ExerciseInfoContainer justify="space-between">
                <LeftContainer alignItems="center">
                  <MobileThumbnail
                    image={exerciseURL()}
                    image2x={exerciseURL(2)}
                    onClick={() => openModal(exercise.imageUrl)}
                    data-test="exerciseRow-thumbnail"
                  />
                  <MobileExerciseName
                    onClick={() => openModal(exercise.imageUrl)}
                    data-test="exerciseRow-name"
                  >
                    {exercise.name}
                  </MobileExerciseName>
                </LeftContainer>
                {open && (
                  <RemoveContainer alignItems="center">
                    <RemoveXIcon
                      small={true}
                      onClick={() => handleRemove(exerciseId)}
                      color={colors.primary500}
                      data-test="mobile-exercise-remove"
                    />
                  </RemoveContainer>
                )}
              </ExerciseInfoContainer>
              <Collapsible
                overflowWhenOpen="visible"
                triggerStyle={mobileTriggerStyle}
                contentOuterClassName="contentOuter__no_padding"
                open={open}
                trigger={
                  <CollapseIconMedium
                    small
                    onClick={handleToggle}
                    open={open}
                    name={exercise.name}
                    data-test="exerciseRow-trigger"
                  />
                }
              >
                <SetsContainer flexDirection="column">
                  <MobileStyledGrid container spacing={2}>
                    <MobileStyledGrid item xs={3}>
                      <MobileTextStyle>Sets</MobileTextStyle>
                    </MobileStyledGrid>
                    <MobileStyledGrid item xs={4}>
                      <MobileTextStyle>
                        {exercise.repUnits === 'Secs' ||
                        exercise.repUnits === 'Mins' ||
                        exercise.repUnits === 'Secs Per Side' ||
                        exercise.repUnits === 'Mins Per Side'
                          ? `Duration (${exercise.repUnits})`
                          : exercise.repUnits === 'Reps Per Side'
                          ? 'Reps/Side'
                          : 'Reps'}
                      </MobileTextStyle>
                    </MobileStyledGrid>
                    <MobileStyledGrid item xs={4}>
                      <MobileTextStyle>Weight (lbs)</MobileTextStyle>
                    </MobileStyledGrid>
                  </MobileStyledGrid>
                  {setRows}{' '}
                  <MobileButtonContainer justify="center">
                    <Button
                      buttonText="+ Add Set"
                      pink="true"
                      onClick={handleAdd}
                      data-test="exerciseRow-addSet"
                    />
                  </MobileButtonContainer>
                </SetsContainer>
              </Collapsible>
              <ExerciseModal
                open={modalOpen}
                img={img}
                handleClose={closeModalItem}
              />
            </ExerciseRow>
          </SwipeableListItem>
        </SwipeableList>
      </div>
    );
  }
};

/**
 * Web
 */

const ExerciseInfoContainer = styled(FlexContainer)`
  position: relative;
  margin-left: ${() => (isMobile() ? '30px' : '40px')};
`;

const HR = styled.hr`
  border: none;
  height: 1px;
  width: 575px;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: ${colors.primary200};
`;

const BottomHR = styled.hr`
  border: none;
  height: 1px;
  width: 575px;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: ${colors.primary200};
  display: ${props => (props.displayBottomLine ? 'block' : 'none')};
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;
const TH = styled.th`
  padding: 0 20px;
  text-transform: uppercase;
  border: none;
  text-align: left;
  font-size: 10pt;
  font-weight: 400;
  line-height: 14pt;
  color: ${colors.primary600};
`;

const TR = styled.tr`
  > :first-child {
    padding-left: 40px;
  }
`;

const StyledRemoveXIcon = styled(RemoveXIcon)`
  visibility: hidden;
  ${ExerciseInfoContainer}:hover & {
    visibility: visible;
  }
`;

const ButtonContainer = styled(FlexContainer)`
  width: 128px;
  align-self: center;
  padding: 12px 0 16px 0;
`;

// add thumbnail field to exercise?
const Thumbnail = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  width: 96px; // Update image dimensions accordingly.
  height: 64px; // Update image dimensions accordingly.

  &:hover {
    cursor: pointer;
  }
`;

const ExerciseName = styled.h3`
  color: ${colors.primary700};

  &:hover {
    cursor: pointer;
    color: ${colors.primary500};
  }
`;

const LeftContainer = styled(FlexContainer)`
  gap: 18px;
`;
const RightContainer = styled(FlexContainer)`
  padding: 8px;
`;

const SetsContainer = styled(FlexContainer)`
  padding-top: 32px;
`;

/**
 * Mobile
 */

const ExerciseRow = styled(FlexContainer)`
  position: relative;
  width: 100%;
`;

const MobileTextStyle = styled.div`
  text-align: center;
  color: ${colors.primary600};
  margin-left: 30px;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
`;

const MobileButtonContainer = styled(FlexContainer)`
  width: 128px;
  align-self: center;
  margin-top: 24px;
  padding: 12px 0 16px 0;
`;

const MobileThumbnail = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-image: ${props =>
    `image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-image: ${props =>
    `-webkit-image-set(url(${props.image}) 1x, url(${props.image2x}) 2x )`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  width: 120px; // Update image dimensions accordingly.
  height: 84px; // Update image dimensions accordingly.
  min-width: 120px;

  &:hover {
    cursor: pointer;
  }
`;

const MobileExerciseName = styled.h3`
  color: ${colors.primary700};
  padding-right: 48px;
  font-size: 16px;

  &:hover {
    cursor: pointer;
    color: ${colors.primary500};
  }
`;

const MobileStyledGrid = styled(Grid)`
  &.MuiGrid-container {
    background-color: ${colors.primary050};
  }
  &.MuiGrid-spacing-xs-2 {
    width: calc(100% + 8px);
  }
`;

const RemoveContainer = styled(FlexContainer)`
  position: absolute;
  left: -25px;
  top: 32px;
`;

const ActionContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  user-select: none;
`;

const MobRemoveXIcon = styled.div`
  height: 75px;
  width: 120px;
  background-color: #ec0a0a;
  transition: 0.3s;
`;

const TextStyle = styled.div`
  height: 18px;
  width: 49px;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  padding-top: 28px;
  padding-left: 36px;
`;

export default Exercise;
