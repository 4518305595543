import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FlexContainer from '../../elements/FlexContainer';
import { colors } from '../../styleConstants';
import useFormValues from '../../hooks/useFormValues';

import Avatar from '@material-ui/core/Avatar';
import IntensitySet from '../../elements/IntensitySet';
import Dropdown from '../../elements/Dropdown';
import TrashIcon from '../../icons/TrashIcon';

const fileStorage = process.env.REACT_APP_REMOTE_FS_URL;

const FitnessCard = ({
  itemToRender,
  handleRemove,
  updateWeek,
  variant,
  ...props
}) => {
  let item, itemType;
  let isDeleted = false;

  // top-level; will be different for every item, even two instances of the same item
  let uniqueId = itemToRender._id ? itemToRender._id : itemToRender.id;

  if (itemToRender.workout) {
    item = itemToRender.workout;
    itemType = 'workout';
    isDeleted = itemToRender.workout.isDeleted;
  }
  if (itemToRender.activity) {
    item = itemToRender.activity;
    itemType = 'activity';
    isDeleted = itemToRender.activity.isDeleted;
  }

  const { values, setValues, handleValueChanged } = useFormValues({
    week: itemToRender.week || null,
  });

  let genderIdentifier = '';
  if (itemType === 'workout' && item.gender && item.gender === 'Women') {
    genderIdentifier = 'F';
  } else if (itemType === 'workout' && item.gender && item.gender === 'Men') {
    genderIdentifier = 'M';
  } else {
    genderIdentifier = 'All';
  }

  let weekOptions = [];
  if (itemType === 'workout') {
    const numWeeks = parseInt(item.numberOfWeeks);
    for (let i = 0; i < numWeeks; i++) {
      weekOptions.push(i + 1);
    }
  }
  const [itemInfo, setItemInfo] = useState({
    duration: '',
    intensity: '',
    caloriesBurned: '',
  });
  useEffect(() => {
    if (itemType === 'workout' && variant !== 'viewOnly') {
      updateWeek(uniqueId, values.week);
      const info = item.workoutInfo.find(info => info.week === values.week);
      setItemInfo({
        duration: info.duration,
        intensity: info.intensity,
        caloriesBurned: info.caloriesBurned,
      });
    } else {
      setItemInfo({
        duration: item.duration,
        intensity: item.intensity,
        caloriesBurned: item.caloriesBurned,
      });
    }
  }, [values.week]);

  useEffect(() => {
    setValues({ week: itemToRender.week || null });
  }, [itemToRender.week]);

  return (
    <CardContainer
      flexDirection="column"
      deleted={isDeleted}
      data-test="admin-fitnessCard"
    >
      <InnerContainer alignItems="center" justify="flex-start">
        <LeftContainer alignItems="center">
          <StyledAvatar
            src={
              itemType === 'activity'
                ? item.imageUrl
                : `${fileStorage}/WorkoutIcon.png`
            }
          />
          <FitnessInfoContainer flexDirection="column">
            <FitnessHeader>{item.name}</FitnessHeader>
            <FitnessInfoRow alignItems="center">
              <FitnessInfo>{itemInfo.caloriesBurned} calories</FitnessInfo>
              <FitnessTime>{itemInfo.duration} mins</FitnessTime>
              {itemType === 'workout' && (
                <FitnessGenLoc>{`${genderIdentifier}/${item.locationType}`}</FitnessGenLoc>
              )}
            </FitnessInfoRow>
            <IntensityContainer>
              <FitnessInfo>Intensity: </FitnessInfo>
              <IntensitySet intensity={itemInfo.intensity} />
            </IntensityContainer>
          </FitnessInfoContainer>
        </LeftContainer>

        <CenterContainer>
          {itemType === 'workout' && variant !== 'viewOnly' && (
            <Dropdown
              width="75px"
              marginLeft="15px"
              options={weekOptions}
              label="Week"
              value={{
                value: values.week,
                label: values.week,
              }}
              onChange={value =>
                handleValueChanged('week', value.value, 'string')
              }
            />
          )}
        </CenterContainer>
        <RightContainer justify="flex-end">
          {variant !== 'viewOnly' && (
            <DeleteContainer justify="flex-end">
              <TrashIcon
                data-name={uniqueId || ''}
                data-type={itemType}
                onClick={handleRemove}
              />
            </DeleteContainer>
          )}
        </RightContainer>
      </InnerContainer>
    </CardContainer>
  );
};

const CardContainer = styled(FlexContainer)`
  border: 1px solid ${props => (props.deleted ? 'red' : colors.primary200)};
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  min-height: 100px;
  position: relative;
  width: 100% !important;
`;

const InnerContainer = styled(FlexContainer)`
  padding: 16px 16px 16px 20px;
  border-bottom: 0.5px solid ${colors.primary400};
`;

const LeftContainer = styled(FlexContainer)`
  height: 100%;
  flex-basis: 70%;
  max-width: 70%;
`;

const CenterContainer = styled(FlexContainer)`
  height: 100%;
  flex-basis: 20%;
`;

const RightContainer = styled(FlexContainer)`
  height: 100%;
  flex-basis: 10%;
`;

const StyledAvatar = styled(Avatar)`
  height: 75px;
  width: 75px;
`;

const FitnessInfoContainer = styled(FlexContainer)`
  margin-left: 32px;
  width: 100%;
  > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const FitnessHeader = styled.h3`
  color: ${colors.primary700};
`;

const FitnessInfoRow = styled(FlexContainer)`
  > * {
    margin-right: 24px;
  }
`;

const IntensityContainer = styled(FlexContainer)`
  > :first-child {
    margin-right: 8px;
  }
`;

const FitnessInfo = styled.h6`
  color: ${colors.primary600};
`;

const FitnessTime = styled.h6`
  font-weight: 400;
  color: ${colors.primary500};
`;

const FitnessGenLoc = styled.h6`
  font-weight: 400;
  color: ${colors.primary500};
`;

const DeleteContainer = styled(FlexContainer)``;

export default FitnessCard;
