import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styleConstants';
import FlexContainer from '../../elements/FlexContainer';

const ModalMenuCard = ({ title, icon, name, handleClick, ...props }) => {
  return (
    <CardContainer
      flexDirection="column"
      alignItems="center"
      justify="space-between"
      name={name}
      onClick={handleClick}
      {...props}
    >
      <Title>{title}</Title>
      <SVG icon={icon}></SVG>
    </CardContainer>
  );
};

const CardContainer = styled(FlexContainer)`
  height: 148px;
  width: 192px;
  border: ${props =>
    props.placeholder
      ? `1.5px dashed ${colors.primary600}`
      : `1.5px solid ${colors.primary200}`};
  border-radius: 4px;
  padding: 32px 0 24px 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
`;

const SVG = styled.div`
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 96px;
  width: 128px;
`;

const Title = styled.h3`
  color: ${colors.primary800};
`;

export default ModalMenuCard;
